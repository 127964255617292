import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Header from "../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../components/Copyright';
import userdp from '../assets/user-dp.svg';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import { TermsAndConditions } from '../utils/CommonHelper';

const SignupStep5 = (props) => {
    const navigate = useNavigate();
	const user = useUserState();
	const userData = store.getState().user 
	let duplicateentry = sessionStorage.getItem('duplicateentry');
	const signIn = async () => {
		let social_media_login = false;
		if (typeof userData.user !== 'undefined' && (userData.user.login_mode_id == 2 || userData.user.login_mode_id ==3)) {
			social_media_login = true;
		}
		if(!social_media_login){
			localStorage.removeItem('registerPassword');
			navigate(pageURLs.root);
		} else {
			navigate(pageURLs.logout);
		}
    };

	return (
		<>
			<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container request-wrap success-wrap">
					<div className="dp-outer">
                        <div className="dp-block"><img src={userdp} alt="KO"/></div>
                        <div className="alert warning"></div>
                    </div>
					<div className="title-sec">
                        {!duplicateentry && <h4>Your identity has not been verified.</h4>}
						{duplicateentry && <h4>Duplicate Account Found.</h4>}
                        <p>All users must be verified in order to participate in contests on Fantasy Sports Knockout.</p>
                    </div>
					
					<div className="account-create">
						<Button variant="primary" onClick={signIn}>View Lobby</Button>
                        <Link to={pageURLs.signupstep2}>Try Again</Link>
					</div>
                    <div className="terms-note">
                        <p>Please note that in order to play in a paid contest, you must be physically located in an eligible state. You can find the list of our eligible states in our {TermsAndConditions()}.</p>
                    </div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default SignupStep5;
