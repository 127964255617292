import React from "react";


const PlusIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
  <path id="add" d="M5.624,15V9.374H0V5.624H5.624V0h3.75V5.624H15v3.75H9.374V15Z" transform="translate(0 0)" fill="#fff"/>
</svg>



    );

}

export default PlusIcon;