import React from "react";


const PlayerIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.363" height="16.742" viewBox="0 0 16.363 16.742" className="player-icon">
            <path id="Union_4" data-name="Union 4" d="M-1500.514-3886.268a1.063,1.063,0,0,1-.911-1.2l.489-3.589-1.56-1.594-2.053,2.191h-4.386a1.064,1.064,0,0,1-1.065-1.062,1.064,1.064,0,0,1,1.065-1.063h3.462l2.081-2.221a1.061,1.061,0,0,1,.129-.151c.024-.023.049-.045.075-.067l2.208-2.356-1.608-.33-1.822,1.923a1.065,1.065,0,0,1-1.505.042,1.062,1.062,0,0,1-.042-1.5l2.229-2.354a1.07,1.07,0,0,1,.988-.312l3.083.633a1.057,1.057,0,0,1,.517.269l2.1,1.98,1.572-1.633a1.066,1.066,0,0,1,1.505-.03,1.062,1.062,0,0,1,.03,1.5l-2.3,2.394a1.066,1.066,0,0,1-.768.327,1.059,1.059,0,0,1-.731-.292l-1.475-1.392-1.825,1.948,2.354,2.4-.63,4.62a1.065,1.065,0,0,1-1.055.92A1.136,1.136,0,0,1-1500.514-3886.268Zm.622-14.6a2.129,2.129,0,0,1,2.13-2.127,2.129,2.129,0,0,1,2.13,2.127,2.128,2.128,0,0,1-2.13,2.127A2.128,2.128,0,0,1-1499.893-3900.873Z" fill="#fff" transform="translate(1510 3903.001)"/>
        </svg>
    );

}

export default PlayerIcon;