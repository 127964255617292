import React, { useState , useEffect} from "react";
import { Button, Tab, Tabs, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import { pageURLs } from "../appData/pageURLs";
import KOSelectBox from "../components/KOSelectBox";
import "../styles/payment.scss";
import * as userController from "../controller/userController"
import TESVOPayment from "../components/TsevoPayment";
import * as transactionController from "../controller/transactionController"
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import * as commonService from "../services/commonService";
import KOInput from "../components/KOInput";
import {  useNavigate } from 'react-router-dom'
import TsevoPaymentWithdrawal from "../components/TsevoPaymentWithdrawal";
import * as userService from "../services/userService";
import useGeoLocation from "../utils/useGeoLocation";
import { checkIpFormat } from '../utils/CommonHelper';

const Withdrawfund = (props) => {

    const userData = store.getState().user
    const user = useUserState();
    const location = useGeoLocation();
    let firstName = userData.user.first_name;
    let lastName = userData.user.last_name; 
    let email = userData.user.email;
    let userFullName = userData.user.user_full_name;
    let dob = userData.user.dob;
    let stateCode = userData.user.state_code;
    let user_status = userData.user.user_status_id;
    let countryCode = userData.user.country_code;
    let city = userData.user.city;
    let address = userData.user.address;
    let zipCode = userData.user.zip_code;
    let tsevo_customer_id = userData.user.tsevo_customer_id;
    let user_id = userData.user.user_id;
    let wallet_amount  = userData.user.wallet_amount;
    const navigate = useNavigate();
    let newAmoutDataArray = [];
    for (var i = 100; i <= 1000; i += 100) {
        newAmoutDataArray.push(i)
    }

    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [paymentSession, setPaymentSession] = useState("");
    const [stepState, setStepState] = useState("");
    const [amountData, setAmountData] = useState(newAmoutDataArray);
    const [paymentAmount, SetPaymentAmount] = useState(100);
    const [paymentProcessFlag, SetPaymentProcessFlag] = useState(false);
    const [showLoader, SetShowLoader] = useState(false);
    const [paymentError, SetPaymentError] = useState('');
    const [withdrawProcessFlag, SetWithdrawProcessFlag] = useState(false);
    const [amountToWithdraw, SetAmountToWithdraw] = useState("");
    const [withDrawalError, SetWithDrawalError] = useState('');
    const [selfExclusionProcessed, SetSelfExclusionProcessed] = useState(false);
    const [selfExclusionConfirmScreen, SetSelfExclusionConfirmScreen] = useState(false);
    const [isGeolocationAvailable, SetGeolocationAvailable] = useState(false);
    const [isGeolocationEnabled, SetGeolocationEnabled] = useState(false);
    const [coords, setcoords] = useState({});
    const [showVerificationProcessModal, setshowVerificationProcessModal] = useState(false);
    const [notverifiedMessage, setnotverifiedMessage] = useState("Complete verification to withdraw cash");
    const [verificationBtnTitle, setverificationBtnTitle] = useState("Continue with Verification");
    const [paymentSessionURL, setPaymentSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);

    const paymentprocess = "PaymentProcess";
    const withdrawamount = "withdrAwamount";

    //For payment purpose we need to access geo location
    // const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp } =
    //     useGeolocated({
    //         positionOptions: {
    //             enableHighAccuracy: false,
    //         },
    //         userDecisionTimeout: 5000,
    // });

    const getSideMenuClasses = () => {
        let result = "koblock-right content-block-rht Payment-info";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const handleAmountchange = (e) => {
        SetPaymentAmount(e.target.value);
    };

    const handleWithdrawalchange = (e) => {
        SetWithDrawalError('')
        if(Number(e.target.value)) {
            SetAmountToWithdraw(e.target.value);
        } else {
            SetAmountToWithdraw('');
            SetWithDrawalError("Enter valid amount");
        }
    };
    
    //Deposit amount
    const depositeAmount = async () => {
        let isValid = validate();
        if(user_status != 1) {
            SetPaymentError("Your identity is not verified.");
            return false;
        }
        if (isValid) {
            SetPaymentProcessFlag(true)
            SetShowLoader(true)
            await userController.getUserIp()
                .then(async function(ipdata) {
                    let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                    await transactionController.createTransactionRecord(paymentAmount, 'deposit', customerIp, coords)
                        .then(async function(orderData) {
                        if(orderData.errorCode == 200) {
                            let sessionID = orderData.data.sessionID;
                            let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                            let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                            if (sessionID !== null) {
                                setPaymentSession(sessionID);
                                setStepState(paymentprocess);
                                SetShowLoader(false);
                                let isIpv4 = checkIpFormat(customerIp);
                                setisIpisV4(isIpv4)
                                setPaymentSessionURL(orderData?.data?.sessionUrl)
                                await user.action.setPaymentProcessedStatus(true);
                            } else {
                                SetPaymentProcessFlag(false)
                                SetShowLoader(false)
                                SetSelfExclusionProcessed(false)
                            }
                        } else {
                            let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                            let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                            SetPaymentProcessFlag(false)
                            SetShowLoader(false)
                            SetPaymentError(orderData.message);
                            SetSelfExclusionProcessed(false)
                        }
                    })
            })
        }
    }

    const getamountOptions = () => {
        return amountData.map((item) => (
            { value: item, label: item }
        ));
    };

    const validate = () => {
        let isValid = true;
        if (paymentAmount == 0) {
            isValid = false;
            SetPaymentError("Select amount");
        }

        return isValid;
    }


    const withdrawAmountProcess = async () => {
        let isValid = validate();
        if(user_status != 1) {
            SetWithDrawalError("Your identity is not verified.");
            return false;
        }
        if(!amountToWithdraw) {
            isValid = false;
            SetWithDrawalError("Enter a valid amount");
            return false;
        }
        if(isNaN(amountToWithdraw)) {
            isValid = false;
            SetWithDrawalError("Enter a valid amount");
            return false;
        }
        if(amountToWithdraw <= 0) { //https://3.basecamp.com/4513320/buckets/25004187/messages/6181097964
            isValid = false;
            SetWithDrawalError("Minimum withdrawal amount should be more than $0.");
            return false;
        }
        if (isValid) {
            SetWithdrawProcessFlag(true)
            SetShowLoader(true)
            await userController.getUserIp()
                .then(async function(ipdata) {
                    let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                    if(customerIp) {
                        await transactionController.createTransactionRecord(amountToWithdraw, 'withdrawal', customerIp,coords)
                            .then(async function(orderData) {
                            if(orderData.errorCode == 200) {
                                let sessionID = orderData.data.sessionID;
                                let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                                let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                                if (sessionID !== null) {
                                    setPaymentSession(sessionID);
                                    setPaymentSessionURL(orderData?.data?.sessionUrl)

                                    setStepState(withdrawamount);
                                    SetShowLoader(false)
                                    await user.action.setPaymentProcessedStatus(true);
                                    console.log("Self exlsuion status", orderData.data.isSelfExclusionProcessed)
                                    if(orderData.data.isSelfExclusionProcessed){
                                        SetSelfExclusionProcessed(true)
                                    } else {
                                        SetSelfExclusionProcessed(false)
                                    }
                                } else {
                                    SetWithdrawProcessFlag(false)
                                    SetShowLoader(false)
                                    SetSelfExclusionProcessed(false)
                                }
                            } else {
                                let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                                let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                                SetWithdrawProcessFlag(false)
                                SetShowLoader(false)
                                SetWithDrawalError(orderData.message);
                                SetSelfExclusionProcessed(false)
                            }
                        })
                    } else {
                        SetWithdrawProcessFlag(false)
                        SetShowLoader(false)
                        SetWithDrawalError("Could not process the request. Please try again later.");
                        SetSelfExclusionProcessed(false)
                    }
            })
        }
    }

    const changeTabs = async (tab) => {
        if(tab == 'withdrawcash') {
            navigate(pageURLs.withdrawfund)
        } else {
            navigate(pageURLs.payment)
        }
    }

    const showSelfExlusionProcessModal = () => {
        SetSelfExclusionConfirmScreen(true);
    }
    const hideSelfExlusionProcessModal = () => SetSelfExclusionConfirmScreen(false);

    const selfExclusion = async() => {
        //setSelfExclusionLoader(true)
        await userService.userSelfBlocking()
        .then(async function(response){
            //setSelfExclusionLoader(false)
            if(response.errorCode == 200) {
                if(response.data.refundRequired) {
                    navigate(pageURLs.withdrawfund);
                } else {
                    await user.action.logout();
                    sessionStorage.removeItem('contestId');
                    sessionStorage.removeItem('lineupsArray');
                    window.location.href = pageURLs.root;
                }
            } else{
                alert(response.message);
            }
        })
    }
    useEffect(() => {
        if(location.loaded && !location.errorFlag) {
            SetGeolocationAvailable(true)
            SetGeolocationEnabled(true)
            setcoords(location.coordinates)
        } else  if(location.loaded && location.errorFlag) {
           
            SetGeolocationAvailable(false)
            SetGeolocationEnabled(false)
            setcoords({})
        }
    }, [location]);

    const continueVerification = (e) => {
        navigate(pageURLs.Verificationstart);
    }

    const closeVerification = (e) => {
        setshowVerificationProcessModal(false);
    }

    useEffect(async () => {
     
        if(user_status != 1) { //Not verified users
            if(user_status == 4 && (user.data.user.tsevo_status_id == 1 || user.data.user.tsevo_status_id == 2)) { //Verification failed or not started the verification
                setnotverifiedMessage('Complete verification to add cash')
            }  else if((user_status == 4 || user_status == 5)  && user.data.user.tsevo_status_id == 3) { //Verification failed or not started the verification
                setnotverifiedMessage('Identity verification was unsuccessful. Please try again. If you continue to encounter difficulties, please reach out to a Knockout representative at admin@fantasysportsknockout.com for assistance.')
                
            } else if(user_status == 5) {
                setnotverifiedMessage("Your identity verification is still in progress. Please be patient - in rare cases, verification can take up to 24 hours. If it has been 24 hours or longer, please contact Knockout customer care at admin@fantasysportsknockout.com.");
                setverificationBtnTitle("Retry verification")
            }
            setshowVerificationProcessModal(true)
            return false;
        }
    }, [user_status]);  
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.payment} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft Payment-container">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        <Tabs
                            defaultActiveKey="withdrawcash"
                            transition={false}
                            id="payment-tab"
                            className="payment-info-tab"
                            onSelect={(k) => changeTabs(k)}
                        >
                            <Tab eventKey="addcash" title="Add Cash">

                                {!paymentProcessFlag && <div className="payment-data">
                                    <p className="note">Please note: eCheck/ACH deposits can take up to 3-5 business days dependent upon your bank.</p>
                                    <div className="amount-selector">
                                        <div className="amount-title">
                                            <h5>Select Amount (USD)</h5>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Money that you have added can be used to join contests and cannot be withdrawn.</Tooltip>
                                                }
                                            >
                                                <i className="ic-question"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <KOSelectBox
                                            id="amount"
                                            name="amount"
                                            type="text"
                                            options={getamountOptions()}
                                            onChange={(e) => { handleAmountchange(e); }}
                                            errorMessage={paymentError}
                                            value={paymentAmount}
                                        />
                                        <p>Minimum deposit amount: $100</p>

                                       {(user_status == 1 && isGeolocationAvailable && isGeolocationEnabled) && <div className="btn-sec"><Button variant="primary" className="btn-main" onClick={depositeAmount}>Deposit</Button></div> }
                                       {/* {!isGeolocationAvailable && <div style={{color: "red"}}>Your browser does not support Geolocation</div>} */}
                                    {!isGeolocationEnabled && <div style={{color: "red"}}>Geolocation is required for receiving the payment</div>}
                                    </div>
                                </div>}
                                {paymentProcessFlag &&
                                    <div className="payment-data">
                                        <div className="tsevo-container">
                                            {(stepState === paymentprocess) &&
                                                <>
                                                    <TESVOPayment sessionID={paymentSession} tsevoSessionURL = {paymentSessionURL} isIpv4= {isIpisV4}/>
                                                </>
                                            }
                                            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                        </div>
                                    </div>}

                                    {selfExclusionProcessed && <Button variant="light" onClick={showSelfExlusionProcessModal} id="selfProcessesproceeds" className="hide"></Button>}


                            </Tab>
                            <Tab eventKey="withdrawcash" title="Withdraw Cash">
                                {!withdrawProcessFlag &&<div className="payment-data">
                                    <div className="amount-selector">
                                        <div className="amount-title">
                                            <h5>Enter amount</h5>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Money that you have added can be withdrawn or re-used to join any contests</Tooltip>
                                                }
                                                
                                            >
                                                <i className="ic-question"></i>
                                            </OverlayTrigger>
                                        </div>

                                        <KOInput
                                            id="amount"
                                            placeholder="Enter amount"
                                            label="Current Email"
                                            name="currentmail"
                                            type="text"
                                            value ={amountToWithdraw}
                                            onChange={(e) => { handleWithdrawalchange(e); }}
                                            errorMessage={withDrawalError}
                                        />
                                       
                                       {(user_status == 1 && isGeolocationAvailable && isGeolocationEnabled) ? <div className="btn-sec"><Button variant="primary" className="btn-main" onClick={() => { withdrawAmountProcess() }}>Withdraw</Button></div> : ''}
                                       {!isGeolocationEnabled && <div style={{color: "red"}}>Geolocation is required for receiving the payment</div>}

                                    </div>
                                </div> }

                                {withdrawProcessFlag &&
                                    <div className="payment-data">
                                        <div className="tsevo-container">
                                            {(stepState === withdrawamount) &&
                                                <>
                                                    <TsevoPaymentWithdrawal sessionID={paymentSession} tsevoSessionURL = {paymentSessionURL} isIpv4= {isIpisV4}/>
                                                </>
                                            }
                                            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                        </div>
                                    </div>}
                            </Tab>
                        </Tabs>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        <h6>Current Balance</h6>
                        <h2>{commonService.toCurrency(wallet_amount)}</h2>
                    </div>

                    <Modal show={selfExclusionConfirmScreen} onHide={hideSelfExlusionProcessModal} className="modal-coupon" >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                        <h4 className="modal-title">Self Exclusion</h4>
                            <p className="info">Are you sure you want to continue the Self-Exclusion process?</p>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={selfExclusion}>Yes</Button>
                            <Button variant="link" onClick={hideSelfExlusionProcessModal}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showVerificationProcessModal} onHide={closeVerification} className="modal-coupon">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <h4 className="modal-title">Verification</h4>

                            <p>{notverifiedMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={continueVerification}>{verificationBtnTitle}</Button>
                            <Button variant="link" onClick={closeVerification}>Maybe later</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default Withdrawfund;
