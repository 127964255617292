const baseUrl = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : `https://prodapi.fantasysportsknockout.com/`
 //const baseUrl = `https://ecsapi.fantasysportsknockout.com/`;
 //const baseUrl = `https://dev-fanspokoapi.zcodemo.com/`;
// const baseUrl = `https://betaapi.fantasysportsknockout.com/`;
 export const REFRESH_TOKEN_URL = `${baseUrl}auth/refreshtoken`

export const GOOGLE_LOGIN_API_URL = `${baseUrl}users/googleLogin`
export const LOGIN_API_URL = `${baseUrl}users/v2/login`
export const SIGNUP_API_URL = `${baseUrl}users/register`
export const ADD_ADDRESS_API_URL = `${baseUrl}users/saveaddress`
export const STATE_LIST_API_URL = `${baseUrl}users/getStates`
export const CONTEST_API_URL = `${baseUrl}contests/listcontests`
export const FORGET_PASSWORD_OTP_API_URL = `${baseUrl}users/forgotPassword`
export const RESET_PASSWORD_API_URL = `${baseUrl}users/resetpassword`
export const FB_LOGIN_API_URL = `${baseUrl}users/fbLogin`
export const EMAIL_VERIFICATION_API_URL = `${baseUrl}users/verifyemail`
export const RESEND_OPT_API_URL = `${baseUrl}users/resendotp`
export const LOBBY_LIST_CONTESTS_API_URL = `${baseUrl}contests/listcontests`
export const USER_IDENTITY_CONFIRM_API_URL = `${baseUrl}users/identityverification`
export const JOIN_CONTEST_ADD_LINEUPS = `${baseUrl}contests/addLineup`
export const JOIN_CONTEST_ADD_LINEUPS_FOR_USERGAME = `${baseUrl}league/addLineup`
export const SEASON_STANDINGS_RESPONSE = `${baseUrl}standings`
export const SEASON_LEADERBOARD = `${baseUrl}standings`

export const JOIN_CONTEST_ADD_TIEBREAKER_SCORE = `${baseUrl}contests/joinContest`
export const JOIN_USER_CONTEST = `${baseUrl}league/joinContest`

export const JOIN_CONTEST_DELETE_LINEUP = `${baseUrl}contests/deletelineup`
export const JOIN_CONTEST_PLAYERS_LIST = `${baseUrl}contests/listPlayers`
export const JOIN_CONTEST_GET_PLAYERS_BY_ID = `${baseUrl}contests/getPlayersById`
export const JOIN_CONTEST_ADD_LINEUP_CARDPLAYER = `${baseUrl}contests/playersCard`
export const JOIN_CONTEST_ADD_LINEUP_CARDPLAYER_FOR_USERGAME = `${baseUrl}leagueplayers/playersCard`
 
export const WINNERS_RESPONSE = `${baseUrl}winners/contest`

export const JOIN_CONTEST_GET_PLAYERS_POSITION = `${baseUrl}contests/playersByPosition`
export const JOIN_CONTEST_GET_LINEUP_PLAYERS_LIST = `${baseUrl}contests/displayPlayersCard`
export const JOIN_CONTEST_GET_LINEUP_PLAYERS_LIST_FOR_USERGAME = `${baseUrl}league/displayPlayersCard`
export const GET_SEASON_STATS = `${baseUrl}players/seasonstatus`

export const JOIN_CONTEST_GET_LINEUP_TEAMLISTING = `${baseUrl}contests/teamsListing`
export const JOIN_CONTEST_REMOVE_LINEUP = `${baseUrl}contests/removeLineup`
export const JOIN_CONTEST_REMOVE_LINEUP_FOR_USERGAME = `${baseUrl}league/removeLineup`
export const CONTEST_DETAILS_API_URL = `${baseUrl}contests/details`
export const USER_GAME_CONTEST_DETAILS_API_URL = `${baseUrl}league/userContestDetails`
export const USER_DETAIS_BY_EMAIL = `${baseUrl}users/getuserbyemail`
export const TSEVO_WEBHOOK_URL = `${baseUrl}users/tsevowebhook`
export const TSEVO_SESSION_SAVE_URL = `${baseUrl}users/tsevodatasave`
export const TSEVO_IDENTITY_VERIFY_CALLBACK_URL = `${baseUrl}users/tsevocallback`
export const UPDATE_ADDRESS_API_URL = `${baseUrl}users/updateaddress`
export const UPDATE_USERNAME_API_URL = `${baseUrl}users/updateusername`
export const INVITE_FRIENDS_API_URL = `${baseUrl}users/invitefriends`
export const UPDATE_PASSWORD_API_URL = `${baseUrl}users/updatepassword`
export const VERIFY_USER_EMAIL_API_URL = `${baseUrl}users/verifyuseremail`
export const VERIFY_USER_EMAIL_OTP_API_URL = `${baseUrl}users/verifyemailchangeotp`
export const UPDATE_USER_EMAIL_API_URL = `${baseUrl}users/updateuseremail`
export const GET_USER_DETAILS_BY_USERID_API_URL = `${baseUrl}users/profile`
export const UPDATE_USER_PROFILE_PIC_API_URL = `${baseUrl}users/updateprofileimage`
export const UPDATE_USER_PREFERENCES_API_URL = `${baseUrl}users/updatepreferences`
export const GET_USER_PREFERENCES_API_URL = `${baseUrl}users/fetchpreferences`
export const GET_USER_CONTEST_SUMMARY_API_URL = `${baseUrl}contests/contestsummary`
export const GET_USER_CONTEST_LINEUP_NUMBER_API_URL = `${baseUrl}contests/lineupsPerWeek`
export const GET_LINEUP_NUMBER_FOR_USERGAME_API_URL = `${baseUrl}league/lineupsPerWeek`
export const REMOVE_LINE_UP_PLAYERS = `${baseUrl}contests/removePlayers`
export const REMOVE_USERGAME_LINE_UP_PLAYERS = `${baseUrl}leagueplayers/removePlayers`
export const FETCH_S3_USER_PROFILE_IMAGE_PRESIGNED_URL = `${baseUrl}users/get-presigned-url`
export const USER_LEAVE_CONTEST_API_URL = `${baseUrl}contests/leavecontest`
export const TSEVO_PAYMENT_CREATION_URL = `${baseUrl}transaction/depositpayment`
export const TSEVO_PAYMENT_VERIFY_CALLBACK_URL = `${baseUrl}transaction/tsevopaymentcallback`
export const CREATE_USER_SITE_GAMES = `${baseUrl}league/createleague`
export const FETCH_STANDARD_LINEUP_PLAN = `${baseUrl}common/fetchlineupstandards`
export const FETCH_STANDARD_SCORING_RULES = `${baseUrl}common/fetchstandardscorings`
export const FETCH_USER_IDENTIVERIFICATION_SESSION = `${baseUrl}users/get-user-identity-session`
export const FETCH_USER_IDENTIVERIFICATION_STATUS = `${baseUrl}users/check-user-profile-status`
export const FETCH_USER_LOCATION_STATUS = `${baseUrl}users/identifylocation`
export const DELETE_USER_SITE_GAMES = `${baseUrl}league/deleteleague`
export const FETCH_WEEK_DURATION = `${baseUrl}common/getContestWeekList`
export const TSEVO_PAYMENT_COMPLETION_URL = `${baseUrl}transaction/complete-payment`



export const GET_ALL_WEEKLY_CONTESTANTS_STANDING = `${baseUrl}standings/usersLeaderboard`
export const GET_ALL_SEASON_CONTESTANTS_STANDING = `${baseUrl}`
export const WEEKLY_STANDINGS_CONTESTANTS_PLAYERS_LINEUP = `${baseUrl}`
export const GET_WEEKLY_STANDINGS = `${baseUrl}standings/weeklyStandings`
export const TRANSACTION_HISTORY = `${baseUrl}transaction/history`
export const MANAGE_LEAGUE_URL = `${baseUrl}manage/leaguelist`
export const LEAGUE_DETAILS_FOR_EDIT = `${baseUrl}manage/leagueDetails`
export const UPDATE_USER_SITE_GAMES = `${baseUrl}league/updateleague`
export const USER_GAME_INVITEE_LIST = `${baseUrl}manage/leagueInviteeList`
export const USER_GAME_PARTICIPANTS_LIST = `${baseUrl}manage/leagueParticipantsList`
export const FETCH_USERGAME_LINEUP_STRATEGY = `${baseUrl}league/getlineupstrategy`
export const TSEVO_PAYMENT_WITHDRAW_URL = `${baseUrl}transaction/withdrawamount`
export const TIEBREAKER_CHECK_URL = `${baseUrl}tie/usedScores`
export const USERS_LINEUP_API_URL = `${baseUrl}standings/otherUserLineups`
export const PLAYERS_STATUS_API_URL = `${baseUrl}standings/playerlist`
export const USER_GAME_INVITEED_EMAIL_LIST = `${baseUrl}league/getinvitedEmails`
export const USER_GAME_SEND_MESSAGE = `${baseUrl}manage/leagueSendMessage`

export const GET_RECIEVED_MESSAGES = `${baseUrl}manage/listReceivedMessages`
export const READ_LEAUGE_MESSAGE = `${baseUrl}manage/updateMsgReadOnStatus`
export const GET_SENT_MESSAGES = `${baseUrl}manage/listsentMessages`
export const SEND_LEAUGE_MESSAGE = `${baseUrl}manage/leagueSendMessage`
export const GET_ALL_NOTIFICATIONS = `${baseUrl}notification/list`
export const NOTIFICATION_MARK_AS_READ = `${baseUrl}notification/marasread`
export const NOTIFICATION_MARK_ALL_AS_READ = `${baseUrl}notification/markallasread`
export const FETCHUSERS_DETAILS_BY_IDS = `${baseUrl}users/user-details`
export const USER_GAME_CREATE_PAYMENT = `${baseUrl}transaction/makeusergamepayment`
export const GET_UNREAD_MESSAGE = `${baseUrl}manage/check-unread-msgs`
export const USER_GAMES_RULES_AND_SCORING = `${baseUrl}contests/scoring-rules`
export const CLEAR_LINEUP = `${baseUrl}contests/clear-lineup`
export const COPY_LINEUP = `${baseUrl}contests/copy-lineup`
export const INJURY_REPORT_API = `${baseUrl}injury/report`
export const NEWS_LISTING_API = `${baseUrl}news/listing`
export const INJURY_REPORT_BYPLAYER_API = `${baseUrl}injury/playerreport`
export const INJURY_REPORT_BYPTEAM_API = `${baseUrl}injury/teaminuries`
export const PLAYER_SEASON_STATS_API = `${baseUrl}players/playerStats`
export const TEAM_SEASON_STATS_API = `${baseUrl}players/teamStats`
export const DEPTH_CHART_API = `${baseUrl}depthchart/list`

export const REFERRAL_COUPON_CHECK_API = `${baseUrl}users/referralcouponcheck`
export const FUNDADD_COUPON_CHECK_API = `${baseUrl}users/couponcheck`
export const WALLET_SPLITUP_FETCH_API = `${baseUrl}users/walletsplitup`
export const CONTEST_REFUND_CHECK_API_URL = `${baseUrl}contests/checkRefundStatus`
export const CONTEST_WEEKLY_RANK_API_URL = `${baseUrl}standings/weeklyrankings`
export const CONTEST_WEEKLY_LINEUP_API_URL = `${baseUrl}standings/weeklylineups`
export const SELF_EXCLUSION_API_URL = `${baseUrl}users/selfblocking`

export const SET_CONTEST_LIMIT_API_URL = `${baseUrl}users/updatecontestlimit`
export const GET_CONTEST_LIMIT_API_URL = `${baseUrl}users/contestlimit`
export const USER_LEAGUE_LEAVE_API_URL = `${baseUrl}league/leaveContest`
export const USER_LOGOUT_API_URL = `${baseUrl}users/logout`
export const FETCH_TIE_BREAKER_IN_RANGE = `${baseUrl}tie/fetchUsedScores`
export const CONTEST_DETAILS__BEFORE_LOGIN_API_URL = `${baseUrl}contests/contestdata`
export const USER_EXIST_CHECK_API_URL = `${baseUrl}users/checkuser`
export const SITE_CONTEST_LINEUP_PLAYERS_SAVE = `${baseUrl}contests/v2/save-lineup-players`
export const USER_LEAGUE_LINEUP_PLAYERS_SAVE = `${baseUrl}contests/v2/save-league-lineup-players`
export const SAVE_USER_DETAILS = `${baseUrl}users/v2/updateaddress`
export const LOBBY_LIST_CONTESTS_API_URL_V2 = `${baseUrl}contests/v2/listcontests`
export const POSTER_LIST_API = `${baseUrl}contests/posters`
export const TOP_PLAYER_LIST_API = `${baseUrl}players/top-players-list`








