import React from "react";

const PlayerNews = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" className="ico-playernews">
            <g id="Group_16606" data-name="Group 16606" transform="translate(-493 -439)">
                <circle id="Ellipse_75" data-name="Ellipse 75" cx="13" cy="13" r="13" transform="translate(493 439)" fill="#0c8d6a" />
                <g id="Group_16574" data-name="Group 16574">
                    <g id="file-text" transform="translate(496 442)">
                        <path id="Path_10450" data-name="Path 10450" d="M11.5,2h-6A1.5,1.5,0,0,0,4,3.5v12A1.5,1.5,0,0,0,5.5,17h9A1.5,1.5,0,0,0,16,15.5v-9Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <path id="Path_10451" data-name="Path 10451" d="M14,2V6.572h4.888" transform="translate(-2.888)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <line id="Line_101" data-name="Line 101" x1="6" transform="translate(7 10.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        <line id="Line_102" data-name="Line 102" x1="6" transform="translate(7 13.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                    <rect id="Rectangle_1827" data-name="Rectangle 1827" width="3" height="3" rx="1" transform="translate(502 447)" fill="#fff" />
                </g>
            </g>
        </svg>

    );

}

export default PlayerNews;