import React, { useState, useEffect } from "react";
import { Button, ToastContainer, Toast, Tab, Tabs, Accordion } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import LineupButtonGroup from "../components/LineupButtonGroup";
import LiveTimer from "../components/LiveTimer";
import TickIcon from "../assets/icons/tickicon";
import LineupSetup from "../components/LineupSetup";
import SeasonStats from "../components/SeasonStats";
import StandingsSetup from "../components/StandingsSetup";
import SeasonStatsContestantStanding from "../components/SeasonStatsContestantStanding";
import WinnersList from "../components/WinnersList";
import InnerPageWrap from "../components/InnerPageWrap";
import TopPlayers from "../components/TopPlayers";
import "../styles/createlineup.scss";
import "../styles/newlineup.scss";
import { pageURLs } from "../appData/pageURLs";
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import * as contestService from "../services/contestService";
import { store } from '../appState/store';
import useUserState from "../appState/userState/useUserState";

import * as commonService from "../services/commonService";
import { useParams, useNavigate } from 'react-router-dom';
import * as apiService from "../services/apiService";
import RulesAndScoring from "../components/RulesAndScoring";
import EliminatedWeekly from "../components/EliminatedWeekly";
import moment from "moment"

const CreateLineup = (props) => {
    const user = useUserState();
    const userData = store.getState().user

    let { contestid } = useParams()
    let saveButtonShow;

    let savetoast = false;
    const siteState = useSiteState();
    const navigate = useNavigate();
    let joinContestAction = sessionStorage.getItem('contestJoinedAction');
    
    //localStorage.removeItem('changeInLineup')

    if (siteState.data.Lobby.getAllPlayersList.length === 0) {
        if (siteState.data.Lobby.positionPlayerCardView.length <= 9) {
            saveButtonShow = false;
        } else if (siteState.data.Lobby.positionPlayerCardView.length > 9) {
            saveButtonShow = true;
        }
    } else {
        if (siteState.data.Lobby.getAllPlayersList.length <= 9) {
            saveButtonShow = false;
        } else if (siteState.data.Lobby.getAllPlayersList.length > 9) {
            saveButtonShow = true;
        }
    }

    let default_debit_toast_show = false;
    let default_join_toast_show = false;

    const [showjointoast, setShowJoinToast] = useState(default_join_toast_show);
    const [showlineuptoast, setShowLineupToast] = useState(false);
    const [showdebittoast, setShowDebitToast] = useState(default_debit_toast_show);
    const [showcredittoast, setShowCreditToast] = useState(false);
    const [showcsavetoast, setShowSaveToast] = useState(false);
    const [selectedTab, setSelectedTab] = useState("details");
    const [showTieBreaker, setShowTieBreaker] = useState(false);
    const [contestData, setContestData] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [defaultAccordion, setDefaultAccordion] = useState("0");
    const [lineupWeekNumber, setLineupWeekNumber] = useState(0);
    const [showOwnLineup, setShowOwnLineup] = useState(true);
    const [leaderUserId, setLeaderUserId] = useState(0);
    const [leaderUserLineUpNumber, setLeaderUserLineUpNumber] = useState(0);
    const [standingsUserId, setStandingsUserId] = useState(0);
    const [selectedLIneup, setSelectedLIneup] = useState(0);
    const [currentSeasonData, setCurrentSeasonData] = useState([]);
    const [weeklineupUniqueData, setNewWeeklineupUniqueData] = useState('');
    const [seasonlineupUniqueData, setSeasonlineupUniqueData] = useState('');
    const [leaveButtonShow, setleaveButtonStatus] = useState(false);
    const { monthDate, time } = useDate();
    const [contestStatusId, setContestStatusId] = useState('');
    let ctime = Math.floor(Date.now() / 1000);
    const [currentTime, setCurrentTime] = useState(ctime);
    const [enableLineupButton, setEnableLineupButton] = useState(false);


    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [flagLineUpNotice, setFlagLineUpNotice] = useState(true);
    const [liveWeekStandings, setLiveWeekStandings] = useState([]);

    const [apiLineUpNumber, setApiLineUpNumber] = useState(0);
    const [apiLineUpWeek, setApiLineUpWeek] = useState(0);
    const [apiCurrentLineupId, setApiCurrentLineupId] = useState(0);
    const [apiPlayerScoreData, setApiPlayerScoreData] = useState([]);
    const [lineupPlayers, setLineupPlayers] = useState([]);
    const [liveScorePlayerId, setLiveScorePlayerId] = useState(null);
    const [entryFee, setEntryFee] = useState(0);
    const [lineupSaveAction, setLineupSaveAction] = useState(false);
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [toastSuccessMessage, setToastSuccessMessage] = useState(null);

    const getPlayerScoresOfNewLineUp = (lineUpNumber, lineUpWeek, currentLineupId) => {
        setApiLineUpNumber(lineUpNumber)
        setApiLineUpWeek(lineUpWeek)
        setApiCurrentLineupId(currentLineupId)
    }

    useEffect(() => {
        if (joinContestAction && entryFee >= 0) {
            setShowJoinToast(true)
            setShowDebitToast(true)
        }
    }, [joinContestAction, entryFee])


/*
    let default_debit_toast_show = false;
    let default_join_toast_show = false;
    if (joinContestAction) {
        default_debit_toast_show = true;
        default_join_toast_show = true;
    }
*/
  

    const getSideMenuClasses = () => {
        let result = "koblock-right lineup-notification-sec";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const getLineUpNoticeClasses = () => {
        let result = "option-toast";
        if (flagLineUpNotice) {
            result += " visible";
        } else {
            result += " hide";
        }
        return result;
    }

    const [Flag_ShowCollapse, setFlag_ShowCollapse] = useState(false);
    const getCollapseClasses = () => {
        let result = "contest-info-sec";
        if (Flag_ShowCollapse) {
            result += " collapsed";
        } else {
            result += " collapsable";
        }
        return result;
    }

    let id = parseInt(contestid);
    if (!id) {
        navigate(pageURLs.lobby);
    }

    sessionStorage.setItem('contestId', id);

    let user_id = parseInt(userData.user.user_id);
    const [seasonStatsUser, setSeasonStatsUser] = useState('');
    const [seasonStatsUserLineUp, setSeasonStatsUserLineUp] = useState('');
    const [seasonStatsUserUniqueId, setSeasonStatsUserUniqueId] = useState('');
    const [totalContestLineUp, setTotalContestLineUp] = useState(0);
    const [checkRefundStatus, setcheckRefundStatus] = useState(true);

    useEffect(async (e) => {
        setShowLoader(true)
        if(selectedTab == 'details') {
            await siteState.action.updateSelectedPlayerCard(0);
            await contestService.getSiteContest(id)
                .then(response => {
                    if (response.errorCode == 200) {
                        if (response.data.contestDetails[0].contest_type_id != 2) {
                            setDefaultAccordion("1")
                        }
                        siteState.data.Lobby. currentContestData=response.data.contestDetails[0];
                        if(response.data.contestDetails[0].entry_fee) {
                            setcheckRefundStatus(true)
                        } else {
                            setcheckRefundStatus(false)
                        }
                        setCurrentSeasonData(response.data.seasonDetails);
                        setActiveMenu(response.data)


                        if (response.data.contestDetails[0].contest_end_week < response.data.seasonDetails.current_week) { //If contest end date is less than current season date we will set it as active week for that contest
                            setLineupWeekNumber(response.data.contestDetails[0].contest_end_week);
                        } else if (response.data.contestDetails[0].contest_start_week > response.data.seasonDetails.current_week) {
                            setLineupWeekNumber(response.data.contestDetails[0].contest_start_week)
                        } else {
                            setLineupWeekNumber(response.data.seasonDetails.current_week);
                        }
                        setTotalContestLineUp(response.data.contest_total_line_up);
                        setContestStatusId(response.data.contestDetails[0].contest_status_id)
                        setContestData(response.data);
                        setShowLoader(false);
                        sessionStorage.removeItem('contestJoinedAction');

                        if (response.data.contestDetails[0].contest_status_id == 3) {
                            setleaveButtonStatus(true)
                        } else {
                            setleaveButtonStatus(false)
                        }
                    } else {
                        setShowLoader(false)
                    }
                })
                .catch(err => {
                    console.log("Error", err);
                    setShowLoader(false);
                });
            }

    }, [selectedTab]);

    const addLineup = () => {
        setShowLineupToast(true);
        if(entryFee > 0) {
            setShowDebitToast(true);
        }
        user.action.getUserData();
    }

    const removeLineup = () => {
        setShowCreditToast(true);
    }

    const getDivTieBreaker = () => {

        return (
            <>
                <div className="tiebreaker-score contest-eliminate" >
                    <h5>Final Tiebreaker: 100.01</h5>
                    <p>Final Tiebreaker for the season.</p>
                </div>
            </>
        )
    }

    const saveLineUpButton = () => {
        setShowSaveToast(true)
    }

    if (contestData && (contestData.contestUserData.length == 0 || contestData.contestUserData[0].contest_user_status_id == 3)) {
        navigate(pageURLs.lobby);
    }

    const setActiveMenu = async (contestData) => {
        if (contestData && contestData.contestDetails[0].contest_status_id === 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (contestData && contestData.contestDetails[0].contest_status_id === 2) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (contestData && (contestData.contestDetails[0].contest_status_id === 4 || contestData.contestDetails[0].contest_status_id === 5)) {
            siteState.action.setLeftSideMenu('lobby');
        }
    }
    const initialSetSeasonStatsPosition = "";
    const [seasonStatsPosition, setSeasonStatsPosition] = useState('initialSetSeasonStatsPosition');
    const initialSeasonStatsParams = {
        contest_id: contestid,
        isUserGame: false,
        player_position: ""
    };
    const initialSeasonStandingsParams = {
        contest_id: contestid,
        lineup_week: lineupWeekNumber,
        //lineup_number: 1
    };
    const [seasonStandingsParams, setSeasonStandingsParams] = useState(initialSeasonStandingsParams);
    const [seasonStandingsResponse, setSeasonStandingsResponse] = useState("");
    const [seasonStandingsLeaderBoradResponse, setSeasonStandingsLeaderBoradResponse] = useState("");

    const [seasonStatsParams, setSeasonStatsParams] = useState(initialSeasonStatsParams);
    const [seasonStatsResponse, setSeasonStatsResponse] = useState({});
    const [winnersResponse, setWinnersResponse] = useState({});


    useEffect(() => {
        setSeasonStatsParams(initialSeasonStatsParams);

        if (selectedTab == 'seasonstats') {            
            setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: 'QB' }))
        } else if (selectedTab == 'winners') {
            siteState.action.getWinnersResponse({ isUserGame: 1, contestid: contestid });
        }
    }, [selectedTab])

    // --------------season stats start ----------------
    useEffect(() => {
        if (selectedTab == 'seasonstats' && seasonStatsParams.player_position != "") {
            let staus_user_id = 0;
            if (seasonStatsUser && user_id != seasonStatsUser) {
                staus_user_id = seasonStatsUser;
            }
          //  console.log(seasonStatsParams.player_position)
            siteState.action.getSeasonStatsResponse(seasonStatsParams, staus_user_id);
        }
    }, [seasonStatsParams])

    useEffect(() => {
        if (seasonStatsParams.player_position != "") {
            let staus_user_id = 0;
            if (seasonStatsUser && user_id != seasonStatsUser) {
                staus_user_id = seasonStatsUser;
            }
            if (seasonStatsUserLineUp) {
                seasonStatsParams.lineup_number = seasonStatsUserLineUp;
                siteState.action.getSeasonStatsResponse(seasonStatsParams, staus_user_id);
            }
        }
    }, [seasonStatsUser, seasonStatsUserLineUp])


    useEffect(() => {
        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data)
            setSeasonStatsResponse(siteState.data.Lobby.seasonStatsResponse.data)

        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data)
            setSeasonStandingsLeaderBoradResponse(siteState.data.Lobby.seasonStatsResponse)

    }, [siteState.data.Lobby.seasonStatsResponse]);


    const changePlayerPositionClick = (Position) => {
       //  if (Position != 'QB' && Position!="") { 
            setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: Position }))
       // }
    }


    useEffect(() => {
        if (siteState.data.Lobby && siteState.data.Lobby.winnersResponse) {
            setWinnersResponse(siteState.data.Lobby.winnersResponse.data)
        }
    }, [siteState.data.Lobby.winnersResponse])


    useEffect(() => {
        
        if (siteState.data.Lobby.seasonStandingsResponse && siteState.data.Lobby.seasonStandingsResponse.data) {
            setSeasonStandingsResponse(siteState.data.Lobby.seasonStandingsResponse.data)
        }
    }, [siteState.data.Lobby.seasonStandingsResponse])
    // --------------winners end ----------------

    const setWeeklyNumber = (week) => {
        setLineupWeekNumber(week);
        setSeasonStandingsParams(seasonStandingsParams => ({ ...seasonStandingsParams, lineup_week: week }))
    }

    

    const updateSelectedTab = (tabName) => {
        // reset states on tab change start
        setSeasonStandingsResponse("");
        setSeasonStandingsLeaderBoradResponse("");
        setSeasonStatsResponse("");
        setWinnersResponse("");
        //  setCurrentSeasonData([]);
        // reset states on tab change end

        setSelectedTab(tabName);
        setNewWeeklineupUniqueData(0)
        setSeasonlineupUniqueData('')
        if (contestData.contestDetails[0].contest_end_week < currentSeasonData.current_week) {
            setLineupWeekNumber(contestData.contestDetails[0].contest_end_week)
        } else if (contestData.contestDetails[0].contest_start_week > currentSeasonData.current_week) {
            setLineupWeekNumber(contestData.contestDetails[0].contest_start_week)
        } else {
            setLineupWeekNumber(currentSeasonData.current_week)
        }
       // if (tabName != 'weeklystanding' && tabName != 'seasonstanding') {
            setShowOwnLineup(true);
            setLeaderUserId(0)
       // }
    }

    const updateSelectedTabConfirm = (tabName) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                updateSelectedTab(tabName)
            }
        } else {
            updateSelectedTab(tabName)
        }

    }

    
    /*
     * Calculate the weekly halfs 
     */
    const getWeeklyHalves = (totalWeeks) =>  {
        let halfs = (totalWeeks > 1) ? (totalWeeks/2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf" : firstHalf,
            "secondHalf" : secondHalf
        }
    } 
    const getNumberOfEliminationPerWeek = (totalWeeks , totalContestants ) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf/halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1)? firstWeekSubEliminatorCalculator: 0;
        return {
            'playersAliveSecondHalf' : numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf' : numPlayersEliminatedFirsHalf,
            'firstWeekElimination' : firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek' : firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks' : halves.firstHalf,
            'secondHalfWeeks' : halves.secondHalf,
            'minimumUsersRequired' : totalWeeks+1
        }
    }  

    //Weekly elimination formula

    const calculate = (totalContestants , totalWeeks ) => {
        if (totalContestants === 0 || totalWeeks === 0) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }


    
    let contest_start_week = contestData ? contestData.contestDetails[0].contest_start_week : 0;
    let max_lineup_per_user = contestData ? contestData.contestDetails[0].max_lineup_per_user : 0;
    let contest_end_week = contestData ? contestData.contestDetails[0].contest_end_week : 0;
    let totalWeeks = contestData ? contestData.contestDetails[0].duration_in_weeks : 0;
   // let totalWeeks = 18;//contestData ? contestData.contestDetails[0].duration_in_weeks : 0;
   // let maxParticipants = 100 ;//contestData ? contestData.contestDetails[0].max_users : 0;
    let maxParticipants = contestData ? contestData.contestDetails[0].max_users : 0;

    const weeklyEliminationSplit = calculate( maxParticipants,totalWeeks);


    let weekDivident = contestData ? totalWeeks / 2 : 0;
    let firstHalfWeekCount = Math.floor(weekDivident);//First half weeks number
    let secondHalfWeekCount = Math.ceil(weekDivident);//Second half weeks number
    let secondPartWeekStartFrom = 2;
    let thirdPartWeekStartFrom =  parseInt(firstHalfWeekCount) + 1  
    thirdPartWeekStartFrom = contest_start_week == 1 ?thirdPartWeekStartFrom : (thirdPartWeekStartFrom+contest_start_week-1);
    let secondHalfParticipants = contestData ? secondHalfWeekCount + 1 : 0;
    let secondHalfEliminatorCount = contestData ? secondHalfWeekCount : 0;

     
    let firstHalfParticipants = contestData ? parseInt(maxParticipants) - parseInt(secondHalfParticipants) : 0;
    let firsthalfWeeklyElimintorCount = contestData ? Math.floor(parseInt(firstHalfParticipants) / parseInt(firstHalfWeekCount)) : 0;
  
    


    let balanceElimintorInFirstWeek = contestData ? parseInt(firsthalfWeeklyElimintorCount) * parseInt(firstHalfWeekCount) : 0;
    let balanceElimintorCount = contestData ? parseInt(firstHalfParticipants) - parseInt(balanceElimintorInFirstWeek) : 0;
    let firstWeekEliminatorCount = contestData ? parseInt(firsthalfWeeklyElimintorCount) + parseInt(balanceElimintorCount) : 0;
    let firsthalfSecondSetWeeklyElimintorCount = contestData ? parseInt(firsthalfWeeklyElimintorCount) * (parseInt(firstHalfWeekCount) - 1) : 0;

    

    if (totalWeeks == 1) {
        firstWeekEliminatorCount = parseInt(maxParticipants) - 1;
    }
 
    const locale = 'en-US';
    const contestStartDate = contestData ? commonService.convertUTCDateToLocalDate(new Date(contestData.contestDetails[0].contest_start_time)) : null;

    // const contestDate = contestData ? contestStartDate.getDate() : null;
    // const contestMonth = contestData ? contestStartDate.toLocaleDateString(locale, { month: 'long', timeZone: "America/New_York" }) : null;
    // const contesttime = contestData ? contestStartDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: "America/New_York" }) : null;
   // const countDownDate = contestData ? commonService.getTimeDifference(contestStartDate) : null;
    let contestDate;
    if(contestData) {
        contestDate = moment.utc(contestData.contestDetails[0].contest_start_time).format('MMMM DD | h:mm A');
    }
    let dtCurrent = contestData ? new Date(contestData.contestDetails[0].contest_start_time) : null;
    let estDate = contestData ? dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' }) : null;
    let dtEst = contestData ? new Date(estDate) : null;
    const countDownDate = contestData ? commonService.getTimeDifference(dtEst) : null;

 

    useEffect(() =>{
        if(contestData && contestData.contestDetails && contestData.contestDetails[0]){
            setEntryFee(contestData.contestDetails[0].entry_fee ? contestData.contestDetails[0].entry_fee : 0)
        }
    }, [contestData])


    const updateSeasonStatsUser = (user_id, lineup_number) => {
        setSeasonStatsUser(user_id)
        setSeasonStatsUserLineUp(lineup_number)
    }


    const updateLineupPlayers = (lineupData) => {
        setLineupPlayers(lineupData);
    }


    useEffect(async () => {
       // fetchLiveScore();
    }, [lineupPlayers, currentTime, apiLineUpWeek, apiCurrentLineupId])


    const [contestCategory, setContestCategory] = useState("")
    const [contestCategoryClass, setContestCategoryClass] = useState("")
    useEffect(() => {
        if (contestData && contestData.contestDetails && contestData.contestDetails[0]) {
            if (contestData.contestDetails[0].lup_entrylevel_category == 'M') {
                setContestCategory("M");
                setContestCategoryClass("badge bg-m");
            }
            else if (contestData.contestDetails[0].lup_entrylevel_category == 'S') {
                setContestCategory("S");
                setContestCategoryClass("badge bg-m");
            }
        }
    }, [contestData])

    useEffect(() => {
        if (weeklyEliminationSplit && firstHalfWeekCount && contest_start_week && contest_end_week) {
            siteState.data.Lobby.WeeklyEliminationData = {

                firstWeek: contest_start_week,
                firstWeekCount: weeklyEliminationSplit.data.firstWeekElimination,

                secondWeekRangeStart: contest_start_week ? parseInt(contest_start_week) + 1 : 2,
                secondWeekRangeEnd: firstHalfWeekCount == 2 ? 0 : contest_start_week == 1 ? firstHalfWeekCount : (thirdPartWeekStartFrom - 1),
                secondWeekRangeCount: weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek,

                thirdWeekRangeStart: thirdPartWeekStartFrom,
                thirdWeekRangeEnd: thirdPartWeekStartFrom == contest_end_week ? 0 : contest_end_week,
                thirdWeekRangeCount: 1
            }
        }
    }, [weeklyEliminationSplit, contest_start_week, firstHalfWeekCount, thirdPartWeekStartFrom, contest_end_week])

    //Trigger lineup save action
    const lineupSave = async (status) => {
        setLineupSaveAction(status)
    }
    //Lineup save button enable
    const updateSaveLinupStatus = async (status) => {
        setEnableLineupButton(status)
    }

    const updateTostStatus = async (status, message) => {
        setShowToastSuccess(status)
        setToastSuccessMessage(message)
    }   

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.createlineup} /></div>
                <div className="kodata-content lineup-content">
                    <div className={getCollapseClasses()}>
                        <Button className="toggle-arrow" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}><i className="ic-chevron"></i></Button>
                        <div className="contest-title-info">
                            <div className="title-sec">
                                <h4>{contestData && contestData.contestDetails[0].contest_name}</h4>
                                <p className="gamelive" style={{ display: "none" }}><span className="ripple"></span>GAME LIVE</p>
                            </div>
                            <div className="info-group">
                            <LineupButtonGroup saveButtonShow={saveButtonShow} selectedTab={selectedTab} leaveButtonShow={leaveButtonShow} saveLineUpButton={saveLineUpButton} userId={userData.user.user_id} contestid={id} contestStatusId={contestStatusId} checkRefundStatus ={checkRefundStatus} userGame = {false} lineupSave = {lineupSave} enableLineupButton ={enableLineupButton}/>
                                {(contestData && contestData.contestDetails[0].contest_type_id === 1) && <span className="bg-label bg-standard">Standard</span>}
                                {(contestData && contestData.contestDetails[0].contest_type_id === 2) && <span className="bg-label bg-knockout">Knockout Plus</span>}
                                <div className={contestData && (contestData.contestDetails[0].contest_status_id === 2 || contestData.contestDetails[0].contest_status_id === 5 || contestData.contestDetails[0].contest_status_id === 7) ? "timer-sec live":"timer-sec"}>
                                    <h5 style={{ display: "none" }}>Starts on: Week 3</h5>
                                    <LiveTimer Status={contestData && contestData.contestDetails[0].contest_status_id} countDownDate={countDownDate} />
                                    <Button variant="primary" className="toggle-btn" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}></Button>
                                </div>
                            </div>
                        </div>
                        <div className="contest-details">
                            <div className="general-info">
                                <div className="info-data">
                                    <div className="form-group">
                                        <label>Entry Fee</label>
                                        <p>{contestData && commonService.toCurrency(contestData.contestDetails[0].entry_fee)}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Prize</label>
                                        <p>{contestData && commonService.toCurrency(contestData.contestDetails[0].total_prize_amt)? commonService.toCurrency(contestData.contestDetails[0].total_prize_amt) : '$0' }</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Entries</label>
                                        <p>{//contestData && contestData.contestDetails[0].total_lineups
                                        }
                                        {contestData && contestData.contestDetails[0].total_lineups}/{contestData && contestData.contestDetails[0].max_users}</p>
                                    </div>
                                   
                                    <div className="form-group">
                                        <label>  Duration</label>
                                        <p>Week {contestData && contest_start_week}    {contestData && contest_start_week != contest_end_week  && ' - '+contest_end_week}</p>
                                    </div>

                                    <div className="form-group">
                                        <label>  Max Lineups</label>
                                        <p>{contestData && contestData.contestDetails[0].max_lineup_per_user}</p>
                                    </div>


                                    {(contestData && contestData.contestDetails[0].total_weekly_winners > 0 &&  contestData.contestDetails[0].contest_type_id === 2) && <div className="form-group">
                                        <label>Weekly Winners</label>
                                        <p>{contestData.contestDetails[0].total_weekly_winners}</p>
                                    </div>
                                    }
                                    {(contestData && contestData.contestDetails[0].total_season_winners > 0 &&  contestData.contestDetails[0].contest_type_id === 2) && <div className="form-group">
                                        <label>Season Sc. Winners</label>
                                        <p>{contestData && contestData.contestDetails[0].total_season_winners}</p>
                                    </div> }
                                </div>
                                <div className="note">
                                    <p>Eliminated Weekly*:</p>

                                    <ul>


                                      <EliminatedWeekly lineupWeekNumber='4' />
                                    </ul>

                                </div>
                            </div>
                            <div className="time-info">
                                {contestDate ? <p>{contestDate} EST</p> : ''}
                            </div>
                        </div>
                    </div>

                    {toastSuccessMessage ? <ToastContainer position="top-end">
                        <Toast className="toast-success" onClose={() => setShowToastSuccess(false)} show={showToastSuccess} delay={3000} autohide>
                            <Toast.Body>
                                <TickIcon /><h5>{toastSuccessMessage}</h5>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer> : '' }

                    <ToastContainer position="top-end">
                            <Toast className="toast-success" onClose={() => setShowJoinToast(false)} show={showjointoast} delay={3000} autohide>
                                <Toast.Body>
                                    <TickIcon /><h5>You have joined this contest successfully.</h5>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>

                        <ToastContainer position="top-end">
                            <Toast className="toast-success" onClose={() => setShowLineupToast(false)} show={showlineuptoast} delay={3000} autohide>
                                <Toast.Body>
                                    <TickIcon />
                                    <div>
                                        <h6>Lineup Added</h6>
                                        <p>An additional lineup has been added to this contest successfully.</p>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>

                        {entryFee > 0 && <ToastContainer position="top-end" className="debit-toast">
                            <Toast className="toast-primary" onClose={() => setShowDebitToast(false)} show={showdebittoast} delay={5000} autohide>
                                <Toast.Body>
                                    <TickIcon />
                                    <div>
                                        <h6>Amount Debited</h6>
                                        <p><span>${entryFee}</span> has been debited from your wallet.</p>
                                    </div>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer> }

                        <ToastContainer position="top-end">
                            <Toast className="toast-success" onClose={() => setShowCreditToast(false)} show={showcredittoast} delay={5000} autohide>
                                <Toast.Body>
                                    <TickIcon />
                                    <div>
                                        <h6>Amount Credited</h6>
                                        <p><span>${entryFee}</span> will be credited back to your wallet.</p>
                                    </div>
                                    </Toast.Body>
                            </Toast>
                        </ToastContainer>

                        <ToastContainer position="top-end">
                            <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                <Toast.Body>
                                    <TickIcon />
                                    <h5>The lineup has been saved successfully.</h5>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>

                    <div className="lineup-container">
                        <div className="content-block-lft lineup-create-wrap">
                            <Tabs
                                transition={false}
                                id="lineup-tab"
                                className="lineup-create-tab"
                                activeKey={selectedTab}
                                onSelect={(k) => updateSelectedTabConfirm(k)}
                            >
                                <Tab eventKey="details" title="My Lineup">
                                    <div className="tab-details-data">{contestData && selectedTab == 'details' && 
                                    <LineupSetup 
                                        getPlayerScoresOfNewLineUp={getPlayerScoresOfNewLineUp} 
                                        apiPlayerScoreData={apiPlayerScoreData} 
                                        selectedTab={selectedTab} 
                                        currentSeasonData={currentSeasonData} 
                                        contestData={contestData} 
                                        addLineup={addLineup} 
                                        removeLineup={removeLineup} 
                                        totalWeek={totalWeeks} 
                                        entryFee={entryFee} 
                                        contestStartWeek={contest_start_week} 
                                        contestEndWeek={contest_end_week} 
                                        max_lineup_per_user={max_lineup_per_user} 
                                        updateLineupPlayers={updateLineupPlayers} 
                                        totalContestLineUp = {totalContestLineUp} 
                                        lineupSaveAction = {lineupSaveAction}
                                        lineupSave = {lineupSave}
                                        updateSaveLinupStatus = {updateSaveLinupStatus}
                                        updateTostStatus = {updateTostStatus}
                                    />}

                                        {(!contestData || showLoader) && <div className="col-md-12"><div className="playercard" style={{ background: "#fff" }}><div className="loader-wrap"><div className="loader"></div></div></div></div>}
                                    </div>
                                </Tab>
                                <Tab eventKey="standings" title="Standings">
                                    {selectedTab == 'standings' && <div className="tab-standing tab-weekly-data"><StandingsSetup contestData={contestData} isuserGame={false} currentSeasonData = {currentSeasonData}></StandingsSetup></div> }
                                </Tab>

                                <Tab eventKey="seasonstats" title="Stats">
                                    {selectedTab == 'seasonstats' && <SeasonStats changePlayerPositionClick={changePlayerPositionClick} seasonStatsResponse={seasonStatsResponse} position={seasonStatsParams.player_position} />}
                                </Tab>
                                <Tab eventKey="rules" title="Lineup and Scoring">
                                    {contestData && selectedTab == 'rules' && <RulesAndScoring contestData={contestData} defaultAccordion={defaultAccordion} />}
                                </Tab>
                                <Tab eventKey="topplayers" title="Top Players">
                                    {contestData && selectedTab == 'topplayers' &&  <TopPlayers contestData={contestData} isuserGame={false}/> }
                                </Tab>
                                {contestData && contestData.contestDetails[0].contest_type_id == 2 ? <Tab eventKey="winners" title="Winners">
                                    {contestData && selectedTab == 'winners' && <WinnersList winnersResponse={winnersResponse} isuserGame={false}  contest_type_id = {contestData.contestDetails[0].contest_type_id } />}
                                </Tab>  :''}
                            </Tabs>
                        </div>
                        <div className={getSideMenuClasses()} style={{display: "none"}}>

                            <div className={getLineUpNoticeClasses()}>
                                <Button className="btn-close" onClick={() => { setFlagLineUpNotice(false); setFlag_ShowSideMenu(false); }}></Button>
                                <p>You have the option to set lineups for future weeks.</p>
                            </div>

                            <LineupButtonGroup saveButtonShow={saveButtonShow} saveLineUpButton={saveLineUpButton} userId={userData.user.user_id} contestid={id} leaveButtonShow={leaveButtonShow} lineupPlayers={lineupPlayers} contestStatusId={contestStatusId} checkRefundStatus ={checkRefundStatus}/>

                            {showTieBreaker ? getDivTieBreaker() : ''}

                            {(selectedTab === "seasonstats") && <SeasonStatsContestantStanding seasonStandingsLeaderBoradResponse={seasonStandingsLeaderBoradResponse} updateSeasonStatsUser={updateSeasonStatsUser} seasonStatsUser={seasonStatsUser} />}

                        </div>
                        
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default CreateLineup;
