import * as userController from "../controller/userController";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export function toCurrency(value) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    let checkValue = Number(value);
    if (checkValue < 0) {
        checkValue = 0;
    }
    return formatter.format(checkValue);
}

export function emailValidation(value) {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let result = pattern.test(value)
    return result;
}

export function passwordValidation(value) {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;
    const noSpaceRegExp = /^\S*$/;

    const passwordLength =      value.length;
    const uppercasePassword =   uppercaseRegExp.test(value);
    const lowercasePassword =   lowercaseRegExp.test(value);
    const specialCharPassword = specialCharRegExp.test(value);
    const minLengthPassword =   minLengthRegExp.test(value);
    const space = noSpaceRegExp.test(value);

    let errMsg = "";
    if (passwordLength === 0) {
        errMsg = "Enter Password.";
    }else if (!minLengthPassword) {
        errMsg = "Enter a minimum of 8 characters with at least one uppercase letter, one lowercase letter, one number and one special character.";
    } else if (!uppercasePassword) {
        errMsg = "Include at least one capital letter in the password.";
    } else if (!lowercasePassword) {
        errMsg = "Include at least one lowercase letter in the password.";
    } else if (!specialCharPassword) {
        errMsg = "Include at least one special character in the password.";
    } else if (!space) {
        errMsg = "Password should not contain space";
    } else if (/\d/.test(value) == false) {
        errMsg = "Include at least one number in the password.";
    } else {
        errMsg = "";
    }
    return errMsg;
}

export function usernameValidation(value) {
    const startingWithNumberRegExp   = /^[^0-9]/;
    const lettersAndNumbersRegExp  = /^[A-Za-z0-9]*$/;
    const noSpaceRegExp = /^\S*$/;
    const specialCharRegExp = /(?=.*?[_#?!@$%^&*-/()<>,'":;])/;

    const usernameLength = value.length;
    const startingWithNumber = startingWithNumberRegExp.test(value);
    const lettersAndNumbers = lettersAndNumbersRegExp.test(value);
    const space = noSpaceRegExp.test(value);
    const specialChar = specialCharRegExp.test(value);

    let errMsg = "";
    if (usernameLength === 0) {
        errMsg = "Username should not be empty.";
    } else if (!space) {
        errMsg = "Username should not contain space";
    } else if(specialChar) {
        errMsg = "Username should not contain special character";
    } else if (!lettersAndNumbers) {
        errMsg = "Username should contain letters and numbers only";
    } else if (!startingWithNumber) {
        errMsg = "Username should not start with a number.";
    } else {
        errMsg = "";
    }
    return errMsg;
}

export function isNumber(val) {
    let valtmp ='';
    if(val.toString().length != parseFloat(val).toString().length) {
         valtmp = Number.parseFloat(val).toFixed(2)
    } else {
         valtmp = Number.parseFloat(val);
    }
    if (Number.isNaN(Number.parseFloat(val))) {
        return false;
    } else if (valtmp.toString().length === val.toString().length) {
        return true;
    } else {
        return false;
    }
}

export function onlyNumberCheck(val){
    const numberRegexpression = /^[0-9\b]+$/;
    if (numberRegexpression.test(val)) {
        return true;
    } else {
        return false;
    }
}

export function convertUTCDateToLocalDate(date) {
    //var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    date.setHours(hours - offset);
    return date;   
}

export function getTimeDifference(countDownDate){
    let dtCurrent = new Date();
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'America/New_York' });
    let dtEst = new Date(estDate);
    var now            = dtEst.getTime();
    var timeDifference = countDownDate - now;
    var oneDay         = 1000 * 60 * 60 * 24;
    var daysinhours           = Math.floor(timeDifference / (oneDay))*24;
    var hours          = Math.floor((timeDifference % (oneDay)) / (1000 * 60 * 60))+daysinhours;
    var minutes        = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds        = Math.floor((timeDifference % (1000 * 60)) / 1000);
    let result = {
        'hours' : hours,
        'minutes' : minutes,
        'seconds' : seconds,
    };
    return result;
}

export function validateInputData(str){
    var regular_expression = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;
    return str.match(regular_expression);
}

export function validateImageTypes(file){
    const validExtensions = ['png','jpeg','jpg','PNG','JPG','JPEG']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
}

export function validateSpace(value){
    const noSpaceRegExp = /^\S*$/;
    const space = noSpaceRegExp.test(value);
    let errMsg = "";
    if (!space) {
        errMsg = "Error";
    } else {
        errMsg = "";
    }
    return errMsg;
}

export function isFloat(n) {
    return parseFloat(n.match(/^-?\d*(\.\d+)?$/))>0;
}

export function setDecimalPlace(num){
    var value = Number(num);      
    var res = num.split(".");     
    if(res.length == 1 && res[1].length < 2) { 
        value = value.toFixed(2);
    }
    return value;
}

export async  function checkUserLocations(locationData){
    return await userController.getUserIp()
        .then(async function(ipdata) {
        let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
        return  await userController.checkLocationFromTsevo(customerIp, locationData);
    })
}

export function maxpointOptions(startfrom){
    const maxImumValue = (process.env.REACT_LEAGUE_CREATION_MAXIMUM_POINT) ? process.env.REACT_LEAGUE_CREATION_MAXIMUM_POINT : 99;
    let maximumOptions = []
    for (var i = startfrom; i <= maxImumValue; i ++) {
        if(i == 39 || i == 49 || i == 99) {
            let newArray = {value: i, label: i+' max'};
            maximumOptions.push(newArray)
        }
    }
    return maximumOptions;
}

export function formatDate(date) {
    let date_array = date.split('T');
    let new_date_array = [];
    if(date_array.length > 1) {
    new_date_array = date_array[0];
    } else {
    new_date_array = date;
    }
    let dateArray = new_date_array.split('-');
    return (dateArray.length) ? [dateArray[1], dateArray[2], dateArray[0]].join('-') : null;
}

export function setRanks(result_data) {
    let currentCount = -1, currentRank = 0,
    stack = 1; // consecutive user with same rating
    let result = [];
    for (let i = 0; i < result_data.length; i++) {       
        if (currentCount !== result_data[i]['Score']) {
            currentRank += stack;
            stack = 1;
        } else {
            stack++;
        }
        result_data[i]['rank'] = currentRank;
        result.push(result_data[i]);
        currentCount = result_data[i]['Score'];
    }
    return result;
}

export async function getInjuryReports(player_id = 0) {
    return (player_id) ? await apiService.apiCall(endPoints.INJURY_REPORT_BYPLAYER_API +'/'+player_id, 'GET',{ })
    : await apiService.apiCall(endPoints.INJURY_REPORT_API , 'GET',{ });    
}

export async function newsList(page, player_id = 0, is_team =0 , team_key = '') {
    let options = {
       "page" : page,
       "player_id" : player_id,
       "is_team" : is_team,
       "team_key" : team_key  
    }
    return await apiService.apiCall(endPoints.NEWS_LISTING_API , 'POST',options);    
}

export async function getTeamInjuryReports(team_key = '') {
    return await apiService.apiCall(endPoints.INJURY_REPORT_BYPTEAM_API +'/1/'+team_key, 'GET',{ })
}
export async function getTeamDepthChart(week) {
    return await apiService.apiCall(endPoints.DEPTH_CHART_API, 'GET',{ })
}

export async function numberrange(start, stop, step) {
    var a = [start], b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return a;
}

export async function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}