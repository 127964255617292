import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import UsergameContestDetails from "../components/UsergameContestDetails";
import InnerPageWrap from "../components/InnerPageWrap";
import "../styles/joincontest.scss";
import { pageURLs } from "../appData/pageURLs";
import { useParams, useNavigate } from 'react-router-dom'
import * as contestService from "../services/contestService";
import {store} from '../appState/store';
import useSiteState from '../appState/siteState/useSiteState';

const UsergameJoinContest = (props) => {
    const userData = store.getState().user
    const siteState = useSiteState();
    const [contestData, setContestData] = useState(null);
    const [defaultAccordion, setDefaultAccordion] = useState("0");
    const [rulesAndScoringData, setRulesAndScoringData] = useState('');
    const navigate = useNavigate();
    let { id} = useParams()
    id = parseInt(id);    
    useEffect( async() => {
        await siteState.action.removeAllLineUpPlayers();
        sessionStorage.removeItem('redirectUserTo')
        await siteState.action.rulesAndScoring(id);
        //get user game contest details
        contestService.getUsergameContest(id)
            .then(response => {
                if (response.errorCode == 200) {
                    if(!response.data.contestDetails.contest_type_id) {
                        navigate(pageURLs.lobby);
                    }
                    if(response.data.contestDetails.contest_type_id != 2) {
                        setDefaultAccordion("1")
                    }                 
                    setContestData(response.data);
                } else {
                    navigate(pageURLs.lobby);
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);

    useEffect(async () => {
        if (siteState.data.Lobby.rulesAndScoringData && siteState.data.Lobby.rulesAndScoringData.data) {
            setRulesAndScoringData(siteState.data.Lobby.rulesAndScoringData.data)
        }
    }, [siteState.data.Lobby.rulesAndScoringData]);

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.UsergameJoinContest} /></div>
                <div className="kodata-content">
                    <div className="joincontest-container">
                        <div className="contest-detail-wrap">
                            {contestData && <UsergameContestDetails contestData={contestData} />}
                        </div>
                        <div className="contest-info-wrap">

                        {contestData && <Accordion defaultActiveKey={defaultAccordion} className="rules-accordion">
                        {contestData && contestData.contestDetails.contest_type_id == 2 &&  <Accordion.Item eventKey="0">
                                    <Accordion.Header>Knockout Plus Variant</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Just like in our standard contests, there is only one winner in the elimination contest. That winner is the Champion of the Knockout Plus. However, additional prizes are awarded to:</p>
                                        <p>Weekly High Scores (criteria varies by contest)</p>
                                        <p>Season Long Points Totals (number of winners varies by contest)</p>
                                        <div className="play-basics">
                                            <h5>Fantasy Sports Knockout uses the following standard scoring:</h5>
                                            <div className="scoring-data">
                                                <div className="player-position">
                                                    <h5>OFFENSE</h5>
                                                    <h6>Each Yard:</h6>
                                                    <p>Passing: 0.04 points</p>
                                                    <p>Receiving/Rushing: 0.1 points</p>
                                                    <h6>Touchdowns:</h6>
                                                    <p>Passing: 4 points</p>
                                                    <p>ALL other TDs scored by a player (including receiving, rushing, special teams and turnover recoveries): 6 points</p>
                                                    <p>All Turnovers (fumbles lost, interceptions thrown): -2 points</p>
                                                    <p>Receptions: 1 point each</p>
                                                    <p>2-point conversion (all types): 2 points</p>
                                                </div>
                                                <div className="player-position special">
                                                    <h5>DST</h5>
                                                    <p>All Touchdowns: 6 points</p>
                                                    <p>All Turnovers (fumbles recovered, interceptions): 2 points</p>
                                                    <p>Sacks: 1 point</p>
                                                    <p>Safety: 2 points</p>
                                                    <p>1-point Safety: 1 point</p>
                                                </div>
                                                <div className="player-position">
                                                    <h5>KICKER</h5>
                                                    <p>Extra Points: 1 point</p>
                                                    <p>Field Goal 0-39: 3 points</p>
                                                    <p>Field Goal 40-49: 4 points</p>
                                                    <p>Field Goal 50+: 5 points</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Weekly Elimination Formula</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Example Only</p>
                                        <div className="tags-sec">
                                            <span className="tag">100 player contest with 13 weeks</span><span className="tag">1st Half: 6 weeks - 2nd Half: 7 weeks</span>
                                            <span className="tag">Alive for 2nd half: 8</span><span className="tag">Eliminated 1st half: 92</span><span className="tag">92 / 6 =15.3, rounded down to 15</span>
                                            <span className="tag">15 (per week) x 6 (weeks) = 90</span><span className="tag">92 - 90 = 2</span>
                                            <span className="tag">Two (2) additional eliminated in week 1 (17)</span>
                                        </div>
                                        <p>For the final half of the contest, we will eliminate one (1) user per week. For the first half of the contest, we will eliminate all other users. We determine the number of weeks in each half by dividing the number of weeks in the contest by 2.
                                            If the contest contains an odd number of weeks, the second half will contain the higher number of weeks. Subtract the number of weeks in the second half and add one to determine how many users will be alive for the second half.
                                            Subtract the number from the previous example from the total number of users in the contest to determine how many will be eliminated in the first half. Divide the sum of users that will be eliminated in the first half equally
                                            amongst the weeks in the first half. In most cases, this will not be a round number. Therefore, round the calculated result down, and eliminate the remainder in Week 1.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* Scoring rules */}                              
                                {rulesAndScoringData && <Accordion.Item eventKey="2">
                                    <Accordion.Header>Scoring Rules</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="scoring-data rules-card-data">
                                            <div className="player-position">
                                                <h4>OFFENSIVE SCORE</h4>
                                                <div className="info-block">
                                                    <h5>Passing Scoring rules</h5>
                                                    <p>For each passing yard: {rulesAndScoringData.off_yard_pass_pts} {rulesAndScoringData.off_yard_pass_pts > 1 ? "points" : "point"}</p>
                                                    <p>For each passing TD: {rulesAndScoringData.off_td_pass_pts} {rulesAndScoringData.off_td_pass_pts > 1 ? "points" : "point"}</p>
                                                </div>
                                                <div className="info-block">
                                                    <h5>Rushing Scoring rule</h5>
                                                    <p>For each rushing yard: {rulesAndScoringData.off_yard_receive_pts} {rulesAndScoringData.off_yard_receive_pts > 1 ? "points" : "point"}</p>
                                                </div>
                                                <div className="info-block">
                                                    <h5>Reception Scoring rules</h5>
                                                    <p>For each receiving yard: {rulesAndScoringData.off_yard_receive_pts} {rulesAndScoringData.off_yard_receive_pts > 1 ? "points" : "point"}</p>
                                                    <p>For each reception: {rulesAndScoringData.off_td_reception_pts} {rulesAndScoringData.off_td_reception_pts > 1 ? "points" : "point"}</p>
                                                </div>
                                                <div className="info-block">
                                                    <h5>All other TDS (rushing, reception, turn over recoveries ): {rulesAndScoringData.off_td_all_other_pts} {rulesAndScoringData.off_td_all_other_pts > 1 ? "points" : "point"}</h5>
                                                    <h5>Two point conversion: {rulesAndScoringData.off_td_2pt_conv_pts} {rulesAndScoringData.off_td_2pt_conv_pts > 1 ? "points" : "point"} </h5><h5>All turnovers: {rulesAndScoringData.off_td_all_turnover_pts} {rulesAndScoringData.off_td_all_turnover_pts > 1 ? "points" : "point"}</h5>
                                                </div>
                                            </div>
                                            <div className="player-position kicker-card"><h4>KICKER</h4><p>Extra points: {rulesAndScoringData.kic_extra_pts} {rulesAndScoringData.kic_extra_pts > 1 ? "points" : "point"}</p>
                                                {rulesAndScoringData.is_field_goal_on === 1 && <p>Field Goal 0-39: {rulesAndScoringData.field_goal_0_39} {rulesAndScoringData.field_goal_0_39 > 1 ? "points" : "point"}</p>}
                                                {rulesAndScoringData.is_field_goal_on === 1 && <p>Field Goal 40-49: {rulesAndScoringData.field_goal_40_49} {rulesAndScoringData.field_goal_40_49 > 1 ? "points" : "point"}</p>}
                                                {rulesAndScoringData.is_field_goal_on === 1 && <p>Field Goal 50+:{rulesAndScoringData.field_goal_50_99} {rulesAndScoringData.field_goal_50_99 > 1 ? "points" : "point"}</p>}
                                            </div>
                                            <div className="player-position "><h4>DST</h4>
                                                <p>All TDS: {rulesAndScoringData.def_td_all_pts} {rulesAndScoringData.def_td_all_pts > 1 ? "points" : "point"}</p>
                                                <p>All turnovers: {rulesAndScoringData.def_td_all_turnover_pts} {rulesAndScoringData.def_td_all_turnover_pts > 1 ? "points" : "point"}</p>
                                                <p>Sacks: {rulesAndScoringData.def_sack_pts} {rulesAndScoringData.def_sack_pts > 1 ? "points" : "point"}</p><p>Safety: {rulesAndScoringData.def_safety_pts} {rulesAndScoringData.def_safety_pts > 1 ? "points" : "point"}</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>}

                                {contestData.contestDetails && <Accordion.Item eventKey="3">
                                    <Accordion.Header>Lineup Strategy</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            {contestData.contestDetails.total_qb ? ' QB - ' + contestData.contestDetails.total_qb +',': " "}
                                            {contestData.contestDetails.total_wr ? ' WR - ' + contestData.contestDetails.total_wr +',': " "}
                                            {contestData.contestDetails.total_rb ? ' RB - ' + contestData.contestDetails.total_rb +',': " "}
                                            {contestData.contestDetails.total_fl ? ' FLEX - ' + contestData.contestDetails.total_fl +',': " "}
                                            {contestData.contestDetails.total_ki ? ' K - ' + contestData.contestDetails.total_ki +',': " "}
                                            {contestData.contestDetails.total_te ? ' TE - ' + contestData.contestDetails.total_te +',': " "}
                                            {contestData.contestDetails.total_df ? ' DST - ' + contestData.contestDetails.total_df : " "}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>}

                            </Accordion>}
                        </div>
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default UsergameJoinContest;
