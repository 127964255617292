import React, { useState, useEffect, useRef } from "react";
import {Badge} from "react-bootstrap";
import { Link } from 'react-router-dom';
import eliminatebadge from '../assets/badge-eliminated.svg';
import * as contestService from "../services/contestService";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import userpic from '../assets/user-default.png';
import Paginate from "../components/Paginate";
import useUserState from "../appState/userState/useUserState";

const WeeklyRankings = (props) => {
    const userState = useUserState();

    const [weeklyRankings, setWeeklyRankings] = useState([]);
    const [showELiminationZone, setELiminationZone] = useState(false);
    const [eliminationZoneBorder, setEliminationZoneBorder] = useState(0);
    const [weeklyOwnRankings, setWeeklyOwnRankings] = useState([]);
    const [contestSelectedWeek, setSelectedWeek] = useState(props.contestSelectedWeek);
    const [rankingPageData, setRankingPageData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [totalLineupCount, setTotalLineupCount] = useState(0);
    const initialPageParams = {
        page : 1
    }
    const [rankPageParams, setRankPageParams] = useState(initialPageParams);
    let dtCurrent = new Date();
    const [standingCurrentTime, setStandingCurrentTime] = useState(dtCurrent);
    const [showLoader, setLoader] = useState(false);
    const constest_id = props.constest_id;
    const isuserGame = props.isuserGame;
    const constest_status = props.constest_status;
    const loggedInUser = userState.data.user.user_id;

    const handleShow = async(lineup_number, user_id) => {
        props.handleShow(lineup_number, user_id)
    };
    //Fetching the weekly ranks
    useEffect(async () => {
        fetchWeeklyRankings(props.contestSelectedWeek, rankPageParams.page, totalLineupCount)
    }, [props.contestSelectedWeek, rankPageParams]);

    const fetchWeeklyRankings = async (week, pageNumber, totalLineups) => {
        setLoader(true)
        let request = {
            "contest_id" : constest_id,
            "lineup_week" : week,
            "isUserGame" : isuserGame,
            "page" : pageNumber,
            "userTotalLineUpCount" : totalLineups
        }
        await contestService.fetchWeeklyRankings(request)
        .then(function (response) {
            setLoader(false)
            if(response.errorCode == 200) {
                setWeeklyRankings(response.data.usersLeaderBoard)
                setELiminationZone(response.data.showEliminationZone)
                setEliminationZoneBorder(Number(response.data.eliminationZonePointFrom))
                setWeeklyOwnRankings(response.data.currentUserRanks)
                setRankingPageData({
                    perPage: response.data.perPage ? response.data.perPage : 24,
                    totalPages: response.data.totalPages ? response.data.totalPages : 0,
                    total: response.data.total ? response.data.total : 0,
                    currentPage: response.data.currentPage ? response.data.currentPage : 0,
                    errorCode: response.errorCode ? response.errorCode : 0,
                });
                let totalCurrentUserCnt = (response.data.currentUserRanks) ? response.data.currentUserRanks.length : 0;
                let totalRanksCnt = (response.data.usersLeaderBoard) ? response.data.usersLeaderBoard.length : 0;
                if(!totalCurrentUserCnt) totalCurrentUserCnt = 0;
                if(!totalRanksCnt) totalRanksCnt = 0;
                let totolResultsCount = Number(totalCurrentUserCnt) + Number(totalRanksCnt)
                setTotalResults(totolResultsCount)
                setTotalLineupCount(response.data.userTotalLineUpCount)
            } else {
                setELiminationZone(false)
                setEliminationZoneBorder(0)
                setWeeklyRankings([]);
                setWeeklyOwnRankings([])
                setRankingPageData([])
                setTotalResults(0)
                setTotalLineupCount(0)
            }
        })
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>Elimination Zone</Tooltip>
    );
    
    const cardClass = (item, isOwnCard, showEliminationZone, rank_user = 0) => { 
        let className = 'contestant-card';
        let eliminated = false;
        if(isOwnCard) {
            className += ' my-card';
        }
        if(item.eliminated && !showEliminationZone) {
            eliminated = true;
            className += ' contest-eliminate';
        }

        if(showEliminationZone) {
            className += ' contest-eliminate';
        }

        if(!eliminated && rank_user == loggedInUser) {
            className += ' my-card-selected';
        }
        return className;
    }

    const pageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setRankPageParams(pageparams);
    }

    useEffect(() => {
        const intervalId = setInterval(async() => {
            let currentDate = new Date();
            let diffTime = Math.abs(currentDate - standingCurrentTime);
           if(diffTime > 600000) {
            let ctime = Math.floor(Date.now() / 1000);
            setStandingCurrentTime(currentDate);
           }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };

    },[standingCurrentTime])

    useEffect(async () => { //Fetch rankings each 10 minutes
         if(standingCurrentTime != dtCurrent && props.contestSelectedWeek == props.curentSeasonWeek && constest_status == 2) {
            fetchWeeklyRankings(contestSelectedWeek, 1, totalLineupCount)
        }
    },[standingCurrentTime])

    const showUserRankings = (ranks, isOwnCard, showELiminationZone, eliminationZoneBorder) => {
        return ranks.map((items, index) => (
            (!items.eliminated && showELiminationZone && Number(items.total_points) <= eliminationZoneBorder ) ? <> 
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                > 
                <div className={cardClass(items, isOwnCard, true, items.user_id)}>
                    <div className="contestant-info">
                        <div className="profile-img">
                            <figure ><img src={items.profile_image ? items.profile_image : userpic} /></figure>
                            {(loggedInUser == items.user_id) ? <span className="you-label">You</span> : ''}
                        </div>
                        <div className="user-info">
                            <div className='name'>{(items.user_full_name) ? items.user_full_name : items.user_name} {items.experience_level==2 ? <span className="level-badge experienced">E</span> : ''} {items.experience_level==1 ? <span className="level-badge beginners">B</span> : ''}</div>
                            <div className='lineup'><Link to = "#" onClick={() => handleShow(items.lineup_number, items.user_id)} >Lineup {items.lineup_number}</Link></div>
                            <div className="tiebreaker">Tiebreaker <span>{items.predicted_tie_score}</span></div>
                        </div>
                    </div>
                    <div className="point-rank">
                        <div className='point'><p><span>Pts</span>{items.total_points}</p></div>
                        {(items.in_progress) ? <Badge bg="success">IN PROGRESS</Badge> : ''}
                        <div className="rank-holder">
                            <span>RANK</span>
                            <div className='rank'>{items.week_user_rank}</div>
                        </div>
                    </div>
                </div></OverlayTrigger>  
            </> : 
            <>
                <div className={cardClass(items, isOwnCard, false, items.user_id)}>
                    <div className="contestant-info">
                        <div className="profile-img">
                            <figure ><img src={items.profile_image ? items.profile_image : userpic} /></figure>
                            {(loggedInUser == items.user_id) ? <span className="you-label">You</span> : ''}
                        </div>
                        <div className="user-info">
                            <div className='name'>{(items.user_full_name) ? items.user_full_name : items.user_name} {items.experience_level==2 ? <span className="level-badge experienced">E</span> : ''} {items.experience_level==1 ? <span className="level-badge beginners">B</span> : ''}</div>
                            <div className='lineup'><Link to = "#" onClick={() => handleShow(items.lineup_number, items.user_id)} >Lineup {items.lineup_number}</Link></div>
                            <div className="tiebreaker">Tiebreaker <span>{items.predicted_tie_score}</span></div>
                        </div>
                    </div>
                    <div className="point-rank">
                        <div className='point'><p><span>Pts</span>{items.total_points}</p></div>
                        {(items.in_progress) ? <Badge bg="success">IN PROGRESS</Badge> : ''}
                        {items.eliminated ? <div className="eliminate-stamp"><img src={eliminatebadge} /></div> : ''}
                        <div className="rank-holder">
                            <span>RANK</span>
                            <div className='rank'>{items.week_user_rank}</div>
                        </div>
                    </div>
                </div>
            </>
        ));
    }


    return (
        <>
            <div className="contestant-standings">
                {(weeklyOwnRankings.length) ? showUserRankings(weeklyOwnRankings, true, showELiminationZone, eliminationZoneBorder) : ''}   
                {(weeklyRankings.length) ? showUserRankings(weeklyRankings, false, showELiminationZone, eliminationZoneBorder) : ''}   
                {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                
            </div>
            {(!weeklyOwnRankings.length && !weeklyRankings.length && !showLoader) ? <div class="vertical-center-position"><div class="row"><span style={{ color: "red" }}>No records to display.</span></div></div> : ""}
            {rankingPageData ? <Paginate paginationData={rankingPageData} pageRows={totalResults} onClick={pageNumberChange} /> : ""}
        </>
    );
};

export default WeeklyRankings;
