import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useUserState from "../appState/userState/useUserState";

function ProtectedRoute() {
    let user = useUserState();
    let isAuthenticated = user.data.IsLoggedIn;
    
    useEffect(() =>{
        user.action.getUserData();
    }, [])
    
    // TODO remove above code
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoute;