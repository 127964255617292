import React, { useState, useEffect, useRef } from "react";
import { Button, Offcanvas, Tab, Tabs, Table, Form, ToastContainer, Toast, Modal, Accordion } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import userpic from '../assets/user-default.png';
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import KOSelectBox from "../components/KOSelectBox";
import KOInput from "../components/KOInput";
import KOCheckbox from "../components/KOCheckbox";
import KOTextarea from "../components/KOTextarea";
import KOPasswordinputgroup from "../components/KOPasswordinputgroup";
import TickIcon from "../assets/icons/tickicon";
import InnerPageWrap from "../components/InnerPageWrap";
import PaginateV2 from "../components/PaginateV2";
import "../styles/myaccount.scss";
import { pageURLs } from "../appData/pageURLs";
import ChangeMailStepsPopup from "./ChangeMailStepsPopup";
import * as commonService from "../services/commonService";
import * as stateService from "../services/stateService";
import * as userService from "../services/userService";
import { store } from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import * as userController from "../controller/userController";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useSiteState from '../appState/siteState/useSiteState';
import TESVOIdentity from "../components/TESVOIdentity";
import * as transactionController from "../controller/transactionController";
import Moment from 'react-moment';
import { checkIpFormat } from '../utils/CommonHelper';

const MyAccountInfo = (props) => {

    const userData = store.getState().user
    const user = useUserState();
    const siteState = useSiteState();
    const [stateData, setStateData] = useState([]);
    let firstName = "";
    let lastName = "";
    let email = "";
    let address = "";
    let city = "";
    let userFullName = "";
    let zipCode = "";
    let dob = "";
    let countryCode = "";
    let stateCode = "";
    let userName = "";
    let userImage = null;
    let countryName = "";
    let stateName = "";
    let user_status = "";
    let login_mode_id = '';
    let is_state_legal = '';
    let wallet_amount = '';
    let experience_level = ''
    firstName = userData.user.first_name;
    lastName = userData.user.last_name;
    email = userData.user.email;
    userFullName = userData.user.user_full_name;
    dob = userData.user.dob;
    stateCode = userData.user.state_code;
    stateName = userData.user.state_code;
    user_status = userData.user.user_status_id;
    login_mode_id = userData.user.login_mode_id;
    is_state_legal = userData.user.is_state_legal;
    wallet_amount = userData.user.wallet_amount;
    const navigate = useNavigate();
    let { tab } = useParams();
    let defaulttab = (tab ) ? tab : 'myaccount'
    if (stateCode) {
        stateData.map((item) => {
            if (item.state_code === stateCode) {
                stateName = item.state_name;
            }
        });
    }
    countryCode = userData.user.country_code;
    countryName = countryCode;
    if (countryCode == 'US') {
        countryName = "United States"
    }
    city = userData.user.city;
    address = userData.user.address;
    zipCode = userData.user.zip_code;
    userName = userData.user.user_name;
    userImage = userData.user.profile_img_path;
    experience_level = userData.user.experience_level
    if (user.data.user.email) {
        email = user.data.user.email
    }

    const initialTransHistoryPageParams = {
        perPage: "",
        page: 1
    }

    const [firstnameValue, setFirstnameValue] = useState(firstName);
    const [lastnameValue, setLastnameValue] = useState(lastName);
    const [emailValue, setEmailValue] = useState(email);

    const [userFullNameValue, setUserFullNameValue] = useState(userFullName);
    const [dobValue, setDOBValue] = useState(dob);
    const [stateCodeValue, setStateCodeValue] = useState(stateCode);
    const [countryCodeValue, setCountryCodeValue] = useState(countryCode);
    const [cityValue, setCityValue] = useState(city);
    const [addressValue, setAddressValue] = useState(address);
    const [zipCodeValue, setZipCodeValue] = useState(zipCode);
    const [userNameValue, setUserNameValue] = useState(userName);
    const [profileImageValue, setProfileImageValue] = useState(userImage);

    const [showusernamesec, setUsernamesec] = useState(false);
    const [showpasswordsec, setPasswordsec] = useState(false);
    const [showemailsec, setEmailsec] = useState(false);
    const [showaddresssec, setAddresssec] = useState(false);

    const [oldPasswordValue, setOldpasswordValue] = useState("");
    const [newPasswordValue, SetNewPaswordValue] = useState("");
    const [confirmPasswordValue, SetConfirmPaswordValue] = useState("");

    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");


    const [updateAddressValue, setUpdateAddressValue] = useState(address);
    const [updateCityValue, setUpdateCityValue] = useState(city);
    const [updateStateValue, setUpdateStateValue] = useState(stateCode);
    const [updateZipcodeValue, setUpdateZipcodeValue] = useState(zipCode);
    const [updateCountryValue, setUpdateCountryValue] = useState(countryCode);

    const [contestLimitValue, setContestLimitValue] = useState({
        perweek: "",
        permonth: "",
        perseason:""
    });

    const [monetaryLimitValue, setMonetaryLimitValue] = useState({
        perweek: "",
        permonth: "",
        perseason:""
    });
    const [contestLimitError, setContestLimitError] = useState({
        perweek: "",
        permonth: "",
        perseason:""
    });

    const [monetaryLimitError, setMonetaryLimitError] = useState({
        perweek: "",
        permonth: "",
        perseason:""
    });

    //Prefernces
    const [generalNotificationPreference, setGeneralNotificationPreference] = useState('');
    const [loginNotificationPreference, setLoginNotificationPreference] = useState('');
    const [contestNotificationPreference, setContestNotificationPreference] = useState('');
    const [joinLeaveContestNotificationPreference, setJoinLeaveContestNotificationPreference] = useState('');
    const [addRemoveLineupNotificationPreference, setAddRemoveLineupNotificationPreference] = useState('');
    const [contestInviteNotificationPreference, setContestInviteNotificationPreference] = useState('');
    const [contestAchievementsNotificationPreference, setContestAchievementsNotificationPreference] = useState('');
    const [userLeagueNotificationPreference, setUserLeagueNotificationPreference] = useState('');
    const [createDeleteLeagueNotificationPreference, setCreateDeleteLeagueNotificationPreference] = useState('');
    const [leagueMessagesNotificationPreference, setLeagueMessagesNotificationPreference] = useState('');
    const [contestEndNotificationPreference, setContestEndNotificationPreference] = useState('');
    const [userNameChangeError, setUserNameChangeError] = useState('');
    const [showcsavetoast, setShowSaveToast] = useState(false);
    const [profilePicError, setProfilePicError] = useState("");
    const [profilePicUpload, setProfilePicUpload] = useState(false);
    const [saveProcessProgressing, setsaveProcessProgressing] = useState(false);
    const [identitySession, setIdentitySession] = useState("");
    const [showTsevoProcess, setshowTsevoProcess] = useState(false);

    const [inviteEmail, setInviteEmail] = useState("");
    const [inviteEmailAdd, setInviteEmailAdd] = useState([]);
    const [inviteEmailError, setInviteEmailError] = useState("");
    const [showDeleteEmailConfirmModal, setDeleteEmailConfirmModal] = useState(false);
    const [deleteEmailIndexValue, setDeleteEmailIndexValue] = useState('');
    const [defaultSelectedTab, setDefaultSelectedTab] = useState(defaulttab);
    const [showLimitLoaderModal, setShowLimitLoaderModal] = useState(false);


    const [transHistoryPageParams, setTransHistoryPageParams] = useState(initialTransHistoryPageParams);
    const [tsevoSessionURL, setTsevoSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);

    const refInviteEmail = useRef(null);


    const handleCloseUsernamesec = () => setUsernamesec(false);
    const handleShowUsernamesec = () => setUsernamesec(true);
    const handleClosePasswordsec = () => setPasswordsec(false);
    const handleShowPasswordsec = () => setPasswordsec(true);
    const handleCloseEmailsec = () => {
        setEmailsec(false);
        email = user.data.user.email;
    }
    const handleShowEmailsec = () => setEmailsec(true);
    const handleCloseAddresssec = () => setAddresssec(false);
    const handleShowAddresssec = () => setAddresssec(true);

    const deleteEmailConfirmModalClose = () => setDeleteEmailConfirmModal(false);
    const deleteEmailConfirmModalShow = () => setDeleteEmailConfirmModal(true);

    const [showtoast, setShowToast] = useState(false);
    const [changeUsernameValue, setChangeUsernameValue] = useState("");
    const [changeUsernameError, setChangeUsernameError] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [addressError, setAddressError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");

    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [showTransactionHistoryLoader, setTransactionHistoryLoader] = useState(false);
    const [showPreferencesLoader, setPreferencesLoader] = useState(false);
    const handleCloseTsevoProcess = () => { setshowTsevoProcess(false); setIdentitySession('') }
    const [walletSplitUpData, setwalletSplitUpData] = useState({
		'depositedAmount' : 0,
		'prizeMoney' : 0,
		'promoFund' : 0,
		'withdrawalBalance' : userData.user.fund_for_withdrawal ? userData.user.fund_for_withdrawal : 0,
		'withdrawanFunds' : 0
	});
    const [showSplitUpLoader, setSplitUpLoader] = useState(false);
    const [activeTab, setActiveTab] = useState('general');
    const [showSelfExclusionLoader, setSelfExclusionLoader] = useState(false);

    const [pushnotificationData, setPushnotificationData] = useState({
        loginPushNotification : 0,
        joinLeavePushNotification : 0,
        addRemoveLineupPushNotification : 0,
        contestInvitePushNotification : 0,
        contestStartEndPushNotification : 0,
        contestAchievementsPushNotification : 0,
        createDeleteLeaguePushNotification : 0,
        leagueMessagesPushNotification : 0
    });


    const getSideMenuClasses = () => {
        let result = "koblock-right content-block-rht balance-info";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const handleChangeUsername = (e) => {
        setChangeUsernameError("");
        setChangeUsernameValue(e.target.value);
        setUserNameChangeError('')
    };

    const handleOldPasswordChange = (e) => {
        setOldPasswordError("");
        setOldpasswordValue(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPasswordError("");
        SetNewPaswordValue(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPasswordError("");
        SetConfirmPaswordValue(e.target.value);
    };

    const handleUpdateAddress = (e) => {
        setAddressError("");
        setUpdateAddressValue(e.target.value);
    };
    const handleUpdateCity = (e) => {
        setCityError("");
        setUpdateCityValue(e.target.value);
    };
    const handleUpdateState = (e) => {
        setStateError("");
        setUpdateStateValue(e.target.value);
    };
    const handleUpdateZipcode = (e) => {
        setZipCodeError("");
        setUpdateZipcodeValue(e.target.value);
    };
    const handleUpdateCountry = (e) => {
        setUpdateCountryValue(e.target.value);
    };

    const handleInviteFriendsEmailChange = (e) => {
        setInviteEmailError("");
        setInviteEmail(e.target.value.toLowerCase())
    };

    const handleContestLimitChange = (e) => {
        if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
        setContestLimitValue((contestLimitValue) => ({
            ...contestLimitValue,
            [e.target.name]: e.target.value,
          }));
        setContestLimitError((contestLimitError) => ({
            ...contestLimitError,
            [e.target.name]: "",
          }));
        }
    };

    const handleMonetaryLimitChange = (e) => {
        if(/^[0-9]+$/.test(e.target.value) || e.target.value === ""){
        setMonetaryLimitValue((monetaryLimitValue) => ({
            ...monetaryLimitValue,
            [e.target.name]: e.target.value,
          }));
        setMonetaryLimitError((monetaryLimitError) => ({
            ...monetaryLimitError,
            [e.target.name]: "",
          }));
        }
    };


    const changeUsername = async () => {
        setsaveProcessProgressing(true);
        let isValid = true;
        let userDetails = store.getState().user;
        setChangeUsernameError("");
        if (changeUsernameValue === "") {
            isValid = false;
            setChangeUsernameError("Please enter username.");
        } else if (commonService.usernameValidation(changeUsernameValue) !== "") {
            isValid = false;
            setChangeUsernameError(commonService.usernameValidation(changeUsernameValue));
        } else if (userDetails.user.user_name === changeUsernameValue) {
            isValid = false;
            setChangeUsernameError("Please enter another username.");
        }
        const requestPayload = {
            //userId :userDetails.user.user_id,
            username: changeUsernameValue,
        }
        let updateUsername
        if (isValid) {
            updateUsername = store.getState().user;
            let response = await userController.updateUsername(requestPayload)
            setsaveProcessProgressing(false);
            if (response.errorCode === 200) {
                let userResponse = await user.action.setUserData(requestPayload);
                handleCloseUsernamesec();
                setShowSaveToast();
                setSaveSuccessMessage(response.message);
                setChangeUsernameValue('');
                setUserNameChangeError('')
            } else {
                setUserNameChangeError(response.message)
            }
        } else {
            setsaveProcessProgressing(false);
        }
    };
    const enterKeySubmitUsernameChange = (e) => {
        if (e.keyCode === 13) {
            changeUsername();
        }
    };
    const enterKeySubmitPasswordChange = (e) => {
        if (e.keyCode === 13) {
            changePassword();
        }
    };
    const enterKeySubmitAddressChange = (e) => {
        if (e.keyCode === 13) {
            changeAddress();
        }
    };

    const changePassword = async () => {
        let isValid = true;
        let userDetails = store.getState().user;
        setOldPasswordError("");
        setNewPasswordError("");
        setConfirmPasswordError("");
        let passwordErrorMsg = commonService.passwordValidation(newPasswordValue);
        const requestPayload = {
            oldpassword: oldPasswordValue,
            password: confirmPasswordValue,
            confirmpassword: confirmPasswordValue,
        }
        if (oldPasswordValue === "") {
            isValid = false;
            setOldPasswordError("Enter Password");
        } if (newPasswordValue === "") {
            isValid = false;
            setNewPasswordError("Enter New Password");
        } if (confirmPasswordValue === "") {
            isValid = false;
            setConfirmPasswordError("Confirm Password");
        } else if (passwordErrorMsg !== "") {
            isValid = false;
            setNewPasswordError(passwordErrorMsg);
        } else if (newPasswordValue !== confirmPasswordValue) {
            isValid = false;
            setConfirmPasswordError("New password and Confirm password does not match.");
        }
        let updatePassword
        if (isValid) {
            updatePassword = store.getState().user;
            let response = await userController.updatePassword(requestPayload)
            if (response.errorCode === 200) {
                setOldpasswordValue("")
                SetNewPaswordValue("")
                SetConfirmPaswordValue("")
                handleClosePasswordsec();
                setShowSaveToast();
                setSaveSuccessMessage(response.message);
            } else setOldPasswordError(response.message)
        }
    };

    const changeAddress = async () => {
        setsaveProcessProgressing(true);
        let isValid = true;
        let userDetails = store.getState().user;
        let requestPayload = {
            address: updateAddressValue,
            city: updateCityValue,
            state: updateStateValue,
            zipcode: updateZipcodeValue,
            country: updateCountryValue,
            customerIp: "",
            is_state_legal: ""
        }
        isValid = validateAddress();
        let updateAddress
        if (isValid) {
            await userController.getUserIp()
                .then(async function (ipdata) {
                    let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                    requestPayload.customerIp = customerIp;
                    updateAddress = store.getState().user;
                    let response = await userController.updateAddress(requestPayload)

                    if (response.errorCode === 200) {
                        handleCloseAddresssec();
                        setShowSaveToast();
                        setSaveSuccessMessage(response.message);
                        requestPayload.is_state_legal = response.data.is_state_legal;
                        let userResponse = await user.action.setUserData(requestPayload);
                        if (response.data.processAddressVerification == 1) {
                            setshowTsevoProcess(true)
                            if (response.data.sessionID !== null) {
                                setIdentitySession(response.data.sessionID);
                                let isIpv4 = checkIpFormat(customerIp);
                                setisIpisV4(isIpv4)
                                setTsevoSessionURL(response?.data?.sessionUrl)
                            }
                        }
                        setsaveProcessProgressing(false);
                    } else {
                        alert(response.message);
                        setsaveProcessProgressing(false);
                    }
                })
        } else {
            setsaveProcessProgressing(false);
        }
    };

    const inviteFriendsEmail = async () => {
        const requestPayload = {
            inviteEmailAdd
        }
        if (inviteEmailAdd.length !== 0) {
            setInviteEmailError('');
            let response = await userController.inviteFriends(requestPayload);
            if (response.errorCode === 200) {
                setInviteEmailError('');
                setShowSaveToast();
                setSaveSuccessMessage(response.message);
                setInviteEmailAdd([]);
            } else {
                setInviteEmailError(response.message)
            }
        } else setInviteEmailError("At least one email address is required to send an invite!")

    };

    const [transactionData, setTransactionData] = useState([]);
    const [transactionPageData, setTransactionPageData] = useState([]);

    useEffect(() => {
        stateService.getStateList()
            .then(response => {
                if (response.success) {
                    setStateData(response.data);
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
        siteState.action.setLeftSideMenu('');
    }, []);

    const getStateOptions = () => {
        return stateData.map((item) => (
            { value: item.state_code, label: item.state_name }
        ));
    };

    const getCountryOptions = () => {
        return [
            { value: 'US', label: 'United States' }
        ];
    };

    const getTransactionSortOptions = () => {
        return [
            { value: 'All', label: 'All' }
        ];
    };


    const uploadProfilePic = async (e) => {
        setProfilePicUpload(true)
        setProfilePicError('');
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            let isValid = commonService.validateImageTypes(img);
            if (isValid) {

                let request_params = {
                    fileName: img.name
                }
                let response = await userService.fetchuserProfilePresignedUrl(request_params)
                if (response.errorCode === 200) {
                    let uploadURL = response.data.imageuploadUlr
                    if (uploadURL) {
                        // PUT request: upload file to S3
                        const result = await fetch(uploadURL, {
                            method: "PUT",
                            body: img,
                        });
                        if (result.status == 200) {
                            let profile_image = result.url.split("?")[0];

                            let post_data = {
                                profile_image: profile_image
                            }
                            await userService.updateUserProfile(post_data)
                                .then(async (response) => {
                                    setProfileImageValue(profile_image);
                                    await user.action.SetTemporaryProfilePic(profile_image);
                                    setProfilePicUpload(false)
                                })
                                .catch(err => {
                                    console.log("Error", err);
                                });;

                        } else {
                            setProfilePicUpload(false)
                        }
                    }
                    await user.action.getUserData(userData.user.user_id);
                } else {
                    setProfilePicError(response.message)
                }
            } else {
                setProfilePicError("Upload image files with .png or .jpeg extensions only.")
                setProfilePicUpload(false)
            }
        }
    }

    const updatePreferences = async () => {
        setPreferencesLoader(true)
        const requestPayload = {
            //userId : userData.user.user_id,
            generalNotification: generalNotificationPreference,
            loginNotification: loginNotificationPreference,
            contestNotification: contestNotificationPreference,
            joinLeaveContest: joinLeaveContestNotificationPreference,
            addRemoveLineup: addRemoveLineupNotificationPreference,
            contestInvite: contestInviteNotificationPreference,
            contestEnd: contestEndNotificationPreference,
            contestAchievements: contestAchievementsNotificationPreference,
            userLeagueNotification: userLeagueNotificationPreference,
            createDeleteLeague: createDeleteLeagueNotificationPreference,
            leagueMessages: leagueMessagesNotificationPreference,
            loginPushNotification : pushnotificationData.loginPushNotification,
            joinLeavePushNotification : pushnotificationData.joinLeavePushNotification,
            addRemoveLineupPushNotification : pushnotificationData.addRemoveLineupPushNotification, 
            contestInvitePushNotification : pushnotificationData.contestInvitePushNotification,
            contestStartEndPushNotification : pushnotificationData.contestStartEndPushNotification ,
            contestAchievementsPushNotification : pushnotificationData.contestAchievementsPushNotification, 
            createDeleteLeaguePushNotification : pushnotificationData.createDeleteLeaguePushNotification, 
            leagueMessagesPushNotification : pushnotificationData.leagueMessagesPushNotification,
        }
        let response = await userController.updateUserPreferences(requestPayload);
        setPreferencesLoader(false)
        if (response.errorCode === 200) {
            setShowSaveToast();
            setSaveSuccessMessage(response.message);
        }
    }

    const setFormData = async (e) => {
        switch (e.target.name) {
            case 'generalNotification':
                setGeneralNotificationPreference(e.target.checked);
                if (e.target.checked === false) {
                    setLoginNotificationPreference(0);
                }
                break;
            case 'loginNotification':
                setLoginNotificationPreference(e.target.checked);
                break;
            case 'contestNotification':
                setContestNotificationPreference(e.target.checked);
                if (e.target.checked === false) {
                    setJoinLeaveContestNotificationPreference(0);
                    setAddRemoveLineupNotificationPreference(0);
                    setContestInviteNotificationPreference(0);
                    setContestEndNotificationPreference(0);
                    setContestAchievementsNotificationPreference(0);
                }
                break;
            case 'joinLeaveContest':
                setJoinLeaveContestNotificationPreference(e.target.checked);
                break;
            case 'addRemoveLineup':
                setAddRemoveLineupNotificationPreference(e.target.checked);
                break;
            case 'contestInvite':
                setContestInviteNotificationPreference(e.target.checked);
                break;
            case 'contestEnd':
                setContestEndNotificationPreference(e.target.checked);
                break;
            case 'contestAchievements':
                setContestAchievementsNotificationPreference(e.target.checked);
                break;
            case 'userLeagueNotification':
                setUserLeagueNotificationPreference(e.target.checked);
                if (e.target.checked === false) {
                    setCreateDeleteLeagueNotificationPreference(0);
                    setLeagueMessagesNotificationPreference(0);
                }
                break;
            case 'createDeleteLeague':
                setCreateDeleteLeagueNotificationPreference(e.target.checked);
                break;
            case 'leagueMessages':
                setLeagueMessagesNotificationPreference(e.target.checked);
                break;
            default:
                break;

        }
    }

    const validateAddress = () => {
        let isValid = true;
        let focusSet = false;

        setAddressError("");
        if (updateAddressValue.trim() === "") {
            isValid = false;
            setAddressError("Enter Address.");
        }

        setCityError("");
        if (updateCityValue.trim() === "") {
            isValid = false;
            setCityError("Enter City.");
        }
        setStateError("");
        if (updateStateValue === "") {
            isValid = false;
            setStateError("Select State.");
        }
        setZipCodeError("");
        let zipcode_space_error = (zipCodeValue) ? commonService.validateSpace(updateZipcodeValue.trim()) : '';
        if (updateZipcodeValue.trim() === "") {
            isValid = false;
            setZipCodeError("Enter Zip Code.");
        } else if (zipcode_space_error) {
            isValid = false;
            setZipCodeError("Zip Code should not contain space.");
        }
        return isValid;
    };

    const addInviteMailQueue = async () => {
        let isValid = true;
        setInviteEmailError("");
        if (inviteEmail === "") {
            isValid = false;
            setInviteEmailError("Please enter email.");
            refInviteEmail.current.focus();
        } else if (!commonService.emailValidation(inviteEmail)) {
            isValid = false;
            setInviteEmailError("Invalid email address")
            refInviteEmail.current.focus();
        } else if (inviteEmailAdd.length >= process.env.REACT_APP_INVITE_FRIENDS_EMAIL_LIMIT) {
            isValid = false;
            setInviteEmailError(`Only ${process.env.REACT_APP_INVITE_FRIENDS_EMAIL_LIMIT} invites can be send at a time.`);
            refInviteEmail.current.focus();
        } else if (inviteEmailAdd.indexOf(inviteEmail) !== -1) {
            isValid = false;
            setInviteEmailError("This email address is already added");
            refInviteEmail.current.focus();
        }
        if (isValid) {
            setInviteEmailAdd(prevState => [...prevState, inviteEmail])
            setInviteEmail("")
        }
        return isValid;
    };

    const deleteInviteEmail = async (index) => {
        console.log("INDEX VALUE FROM STATE",index);
        let newInvitedArray = [...inviteEmailAdd]
        newInvitedArray.splice(index, 1);
        setInviteEmailAdd(newInvitedArray);
        deleteEmailConfirmModalClose();
    };

    const loadTransactionHistory = () => {
        setTransactionHistoryLoader(true);
        transactionController.getTransactionRecord(transHistoryPageParams)
            .then(response => {
                setTransactionHistoryLoader(false);
                if (response.success) {
                    setTransactionData(response.data.result);
                    setTransactionPageData({
                        perPage: response.data.perPage,
                        currentPage: response.data.currentPage,
                        totalPages: response.data.totalPages,
                        total: response.data.total
                    });
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }

    const changeTabs = async (selectedTab) => {
        setShowSaveToast(false)
        setActiveTab(selectedTab)
        if (selectedTab == 'transactionhistory') {
            loadTransactionHistory();
        } else if (selectedTab == 'preferences') {
            setPreferencesLoader(true)
            userService.getUserPreferences()
                .then(response => {
                    if (response.success) {
                        setGeneralNotificationPreference(response.data.generalNotification);
                        setLoginNotificationPreference(response.data.loginNotification);
                        setContestNotificationPreference(response.data.contestNotification);
                        setJoinLeaveContestNotificationPreference(response.data.joinLeaveContest);
                        setAddRemoveLineupNotificationPreference(response.data.addRemoveLineup);
                        setContestInviteNotificationPreference(response.data.contestInvite);
                        setContestAchievementsNotificationPreference(response.data.contestAchievements);
                        setContestEndNotificationPreference(response.data.contestEnd);
                        setUserLeagueNotificationPreference(response.data.userLeagueNotification);
                        setCreateDeleteLeagueNotificationPreference(response.data.createDeleteLeague);
                        setLeagueMessagesNotificationPreference(response.data.leagueMessages);
                        setPushnotificationData((pushnotificationData) => ({
                            ...pushnotificationData,
                            loginPushNotification: response.data.loginPushNotification,
                            joinLeavePushNotification: response.data.joinLeavePushNotification,
                            addRemoveLineupPushNotification: response.data.addRemoveLineupPushNotification,
                            contestInvitePushNotification: response.data.contestInvitePushNotification,
                            contestStartEndPushNotification: response.data.contestStartEndPushNotification,
                            contestAchievementsPushNotification: response.data.contestAchievementsPushNotification,
                            createDeleteLeaguePushNotification: response.data.createDeleteLeaguePushNotification,
                            leagueMessagesPushNotification: response.data.leagueMessagesPushNotification
                        }));
                    }
                    setPreferencesLoader(false)
                })
                .catch(err => {
                    console.log("Error", err);
                });
        }
    }

    const pageChange = (changeValue) => {
        setTransHistoryPageParams(transHistoryPageParams => ({ ...transHistoryPageParams, page: changeValue }))
    };

    const pageItemChange = (event) => {
        setTransHistoryPageParams(transHistoryPageParams =>({ ...transHistoryPageParams, page: 1, perPage: event.target.value }))
    };

    useEffect(() => {
        if(activeTab == 'transactionhistory') {
            loadTransactionHistory();
        }
    }, [transHistoryPageParams])

    const showWalletSplitup = async (accState) => {
        if(accState !== null) { //Accoridon open
            setSplitUpLoader(true)
            await userController.fetchWalletSplitup()
            .then(function(response){
                if(response.errorCode == 200) {
                    setwalletSplitUpData((walletSplitUpData) => ({
                        ...walletSplitUpData,
                        depositedAmount: response.data.deposited_amount,
                        prizeMoney: response.data.prize_amount,
                        promoFund: response.data.promo_fund,
                        withdrawalBalance: response.data.fund_for_withdrawal,
                        withdrawanFunds: response.data.withdrawan_fund
                    }));
                    wallet_amount = response.data.wallet_amount;
                }
                setSplitUpLoader(false)
            })
            
        }
	}
    const [showSelfExclusionModal, setSelfExclusionModal] = useState(false);
    const SelfExclusionModalClose = () => setSelfExclusionModal(false);
    const SelfExclusionModalShow = () => setSelfExclusionModal(true);

    const [showSetLimitModal, setSetLimitModal] = useState(false);
    const SetLimitModalClose = () => {
        setSetLimitModal(false)
        getSetLimit() 
        setMonetaryLimitError((monetaryLimitError) => ({
            ...monetaryLimitError,
            perweek: "",permonth:"",perseason:""
          }));
        setContestLimitError((contestLimitError) => ({
            ...contestLimitError,
            perweek: "",permonth:"",perseason:""
          }));
    };
    const SetLimitModalShow = () => {
        setShowLimitLoaderModal(true)
        setSetLimitModal(true)
        getSetLimit()
    }



    const selfExclusionConfirmation = async() => {
        setSelfExclusionLoader(true)
        await userService.userSelfBlocking()
        .then(async function(response){
            setSelfExclusionLoader(false)
            if(response.errorCode == 200) {
                if(response.data.refundRequired) {
                    navigate(pageURLs.withdrawfund);
                } else {
                    await user.action.logout();
                    sessionStorage.removeItem('contestId');
                    sessionStorage.removeItem('lineupsArray');
                    window.location.href = pageURLs.root;
                }
            } else{
                alert(response.message);
            }
        })
    }

    const saveSetLimit = async() =>{
        let isValid = true;
        if (contestLimitValue.permonth !== "" && parseInt(contestLimitValue.perweek) > parseInt(contestLimitValue.permonth)) {
            isValid = false;
            setContestLimitError((contestLimitError) => ({
                ...contestLimitError,
                permonth: "Contest Limit Per Month should be greater than or equal to Contest Limit Per Week.",
              }));
        } 
        if (contestLimitValue.perseason !== "" && parseInt(contestLimitValue.permonth) > parseInt(contestLimitValue.perseason)) {
            isValid = false;
            setContestLimitError((contestLimitError) => ({
                ...contestLimitError,
                perseason: "Contest Limit Per Season should be greater than or equal to Contest Limit Per Month.",
            }));
        } 
        if (contestLimitValue.perseason !== "" && parseInt(contestLimitValue.perweek) > parseInt(contestLimitValue.perseason)) {
            isValid = false;
            setContestLimitError((contestLimitError) => ({
                ...contestLimitError,
                perseason: "Contest Limit Per Season should be greater than or equal to Contest Limit Per Week.",
            }));
        } 
        if (monetaryLimitValue.permonth !== "" && parseInt(monetaryLimitValue.perweek) > parseInt(monetaryLimitValue.permonth)) {
            isValid = false;
            setMonetaryLimitError((monetaryLimitError) => ({
                ...monetaryLimitError,
                permonth: "Monetary Limit Per Month should be greater than or equal to Monetary Limit Per Week.",
            }));
        }  
        if (monetaryLimitValue.perseason !== "" && parseInt(monetaryLimitValue.permonth) > parseInt(monetaryLimitValue.perseason) ) {
            isValid = false;
            setMonetaryLimitError((monetaryLimitError) => ({
                ...monetaryLimitError,
                perseason: "Monetary Limit Per Season should be greater than or equal to Monetary Limit Per Month.",
            }));
        } 
        if (monetaryLimitValue.perseason !== "" && parseInt(monetaryLimitValue.perweek) > parseInt(monetaryLimitValue.perseason) ) {
            isValid = false;
            setMonetaryLimitError((monetaryLimitError) => ({
                ...monetaryLimitError,
                perseason: "Monetary Limit Per Season should be greater than or equal to Monetary Limit Per Week.",
            }));
        } 
        const requestPayload = {
            weekly_joinlimit : contestLimitValue.perweek,
            monthly_joinlimit : contestLimitValue.permonth,
            season_joinlimit : contestLimitValue.perseason,
            weekly_spentlimit : monetaryLimitValue.perweek,
            monthly_spentlimit : monetaryLimitValue.permonth,
            season_spentlimit : monetaryLimitValue.perseason
        }
        if(isValid){
            let response = await userService.setContestLimits(requestPayload);
            if (response.errorCode === 200) {
                setShowSaveToast();
                setSaveSuccessMessage(response.message);
                SetLimitModalClose()
                getSetLimit()
            }
        }
    }
    
    const getSetLimit = async() => {
        await userService.getContestLimits()
            .then(response => {
                if (response.success) {
                    setShowLimitLoaderModal(false)
                    setContestLimitValue({
                        perweek: parseInt(response.data.weekly_joinlimit) === 0 ? "" : parseInt(response.data.weekly_joinlimit),
                        permonth: parseInt(response.data.monthly_joinlimit) === 0 ? "" : parseInt(response.data.monthly_joinlimit),
                        perseason: parseInt(response.data?.season_joinlimit) === 0 ? "" : parseInt(response.data.season_joinlimit) ,
                    });
                    setMonetaryLimitValue({
                        perweek: parseInt(response.data.weekly_spentlimit) === 0 ? "" : parseInt(response.data.weekly_spentlimit),
                        permonth: parseInt(response.data.monthly_spentlimit) === 0 ? "" : parseInt(response.data.monthly_spentlimit),
                        perseason: parseInt(response.data.season_spentlimit) === 0 ? "" : parseInt(response.data.season_spentlimit),
                    });
                }
            })
            .catch(err => {
                setShowLimitLoaderModal(false)
                console.log("Error", err);
            });
    }

    useEffect(() => {
        if(defaulttab == 'transactionhistory') {
            loadTransactionHistory();
        }
    }, [defaulttab]);

    const redirectToUserLeague = async () => {
        siteState.data.IsUserGame = true;
        sessionStorage.setItem('IsUserGame', true)
        navigate(pageURLs.alluserleague)
    }

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.myaccountinfo} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft myaccount-wrapper">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        <Tabs
                            defaultActiveKey={defaultSelectedTab}
                            transition={false}
                            id="accountinfo-tab"
                            className=""
                            onSelect={(k) => changeTabs(k)}
                        >
                            <Tab eventKey="myaccount" title="My Account">
                                <div className="myaccount-details">
                                {(saveSuccessMessage) ? <ToastContainer position="top-end" className="mt-3">
                                    <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                        <Toast.Body><TickIcon />{saveSuccessMessage}</Toast.Body>
                                    </Toast>
                                </ToastContainer> : ''}
                                    <div className="profile-info">
                                        <div className="profile-pic">
                                            {(user_status != 1 || is_state_legal == 0) && <span className="dot"></span>}
                                            <figure>  <img src={(profileImageValue) ? profileImageValue : userpic} alt="KO" /></figure>

                                            {login_mode_id == 1 ? <div className="file-upload">
                                                <label className="upload-btn" htmlFor="upload-img"><i className="ic-camera"></i></label>
                                                <input
                                                    className="custtom-browse"
                                                    type="file"
                                                    accept='image/*'
                                                    id='upload-img'
                                                    name='upload-img'
                                                    value={props.value}
                                                    onChange={uploadProfilePic}
                                                />
                                            </div> : ''}

                                            {profilePicUpload && <div className="loader-wrap"><div className="loader"></div></div>}
                                        </div>
                                        <div className="profile-name">
                                            { experience_level === 1 ?
                                                <h4>{userFullName} <span class="level-badge beginners">B</span></h4>:
                                             experience_level === 2 ?
                                                <h4>{userFullName} <span class="level-badge experienced">E</span> </h4>:
                                                <h4>{userFullName}</h4>
                                            }
                                            <p>{email}</p>
                                            {user_status === 1 && <span className="verified"><i className="ic-verify"></i>Your identity was verified successfully</span>}
                                            {user_status != 1 && <span className="verified">Your identity not verified</span>}
                                            {profilePicError && (
                                                <p style={{ color: "red" }}>{profilePicError}</p>
                                            )}
                                        </div>
                                    </div>
                                    {login_mode_id == 1 && <div className="account-info">
                                        <div className="title">
                                            <h5>Account Information</h5>
                                        </div>

                                        <div className="info-data">
                                            <div className="form-group">
                                                <label>Username</label>
                                                <p>{userName}</p>
                                            </div>
                                            {login_mode_id == 1 ? <div className="form-group">
                                                {/* <Button variant="outline-primary" onClick={handleShowUsernamesec}>Change Username</Button> */}
                                                <Button variant="outline-primary" onClick={handleShowPasswordsec}>Change Password</Button>
                                                <Button variant="outline-primary" onClick={handleShowEmailsec}>Change Email</Button>
                                                <Offcanvas show={showusernamesec} onHide={handleCloseUsernamesec} placement="end" className="custom-sidebar change-username">
                                                    <Offcanvas.Header closeButton></Offcanvas.Header>
                                                    <Offcanvas.Body>
                                                        <h5>Change Username</h5>
                                                        {userNameChangeError && (
                                                            <span style={{ color: "red" }}>{userNameChangeError}</span>
                                                        )}
                                                        <div className="sidebar-body">
                                                            <KOInput
                                                                id="username"
                                                                label="Enter username"
                                                                name="username"
                                                                type="text"
                                                                onChange={(e) => { handleChangeUsername(e); }}
                                                                value={changeUsernameValue}
                                                                placeholder="Enter new username."
                                                                maxLength="50"
                                                                errorMessage={changeUsernameError}
                                                                onKeyDown={(e) => enterKeySubmitUsernameChange(e)}

                                                            />
                                                            <div className="btn-sec"><Button variant="primary" onClick={changeUsername}>Submit</Button> </div>
                                                        </div>
                                                        <ToastContainer position="top-end">
                                                            <Toast className="toast-success" onClose={() => setShowToast(false)} show={showtoast} delay={3000} autohide>
                                                                <Toast.Header>
                                                                    <p>Message Sent</p>
                                                                    <TickIcon />
                                                                </Toast.Header>
                                                                <Toast.Body>Your message has been sent to all entries successfully.</Toast.Body>
                                                            </Toast>
                                                        </ToastContainer>
                                                    </Offcanvas.Body>
                                                </Offcanvas>

                                                <Offcanvas show={showpasswordsec} onHide={handleClosePasswordsec} placement="end" className="custom-sidebar">
                                                    <Offcanvas.Header closeButton></Offcanvas.Header>
                                                    <Offcanvas.Body>
                                                        <h5>Change Password</h5>
                                                        <div className="sidebar-body">
                                                            <KOPasswordinputgroup
                                                                id="oldpassword"
                                                                placeholder="Enter old password"
                                                                label="Old password"
                                                                value={oldPasswordValue}
                                                                onChange={(e) => { handleOldPasswordChange(e); }}
                                                                name="oldpassword"
                                                                type="password"
                                                                errorMessage={oldPasswordError}
                                                                onKeyDown={(e) => enterKeySubmitPasswordChange(e)}
                                                            />
                                                            <KOPasswordinputgroup
                                                                id="newpassword"
                                                                placeholder="Enter new password"
                                                                label="New password"
                                                                value={newPasswordValue}
                                                                onChange={(e) => { handleNewPasswordChange(e); }}
                                                                name="newpassword"
                                                                type="password"
                                                                errorMessage={newPasswordError}
                                                                onKeyDown={(e) => enterKeySubmitPasswordChange(e)}
                                                            />
                                                            <KOPasswordinputgroup
                                                                id="confirmpassword"
                                                                placeholder="Confirm password"
                                                                label="Confirm password"
                                                                value={confirmPasswordValue}
                                                                onChange={(e) => { handleConfirmPasswordChange(e); }}
                                                                name="confirmpassword"
                                                                type="password"
                                                                errorMessage={confirmPasswordError}
                                                                onKeyDown={(e) => enterKeySubmitPasswordChange(e)}
                                                            />
                                                            <div className="btn-sec">
                                                                <Button variant="primary" onClick={changePassword}>Update Password</Button>
                                                            </div>
                                                        </div>
                                                    </Offcanvas.Body>
                                                </Offcanvas>

                                                <Offcanvas show={showemailsec} onHide={handleCloseEmailsec} placement="end" className="custom-sidebar change-username">
                                                    <Offcanvas.Header closeButton></Offcanvas.Header>
                                                    <Offcanvas.Body>
                                                        <h5>Change Email</h5>
                                                        <ChangeMailStepsPopup handleCloseEmailsec={handleCloseEmailsec} />
                                                    </Offcanvas.Body>
                                                </Offcanvas>
                                            </div> : '' }
                                        </div>
                                    </div>}
                                    <div className="account-info">
                                        <div className="title">
                                            <h5>Personal Info</h5>
                                        </div>
                                        <div className="info-data">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <p>{(firstnameValue) ? firstnameValue : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <p>{(lastnameValue) ? lastnameValue : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Date of Birth</label>
                                                <p>{(dob) ? commonService.formatDate(dob) : "NA"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {saveProcessProgressing && <div className="loader-wrap"><div className="loader"></div></div>}
                                    <div className="account-info">
                                        <div className="title">
                                            <h5>Address</h5><Button variant="outline-primary" onClick={handleShowAddresssec}>Edit</Button>
                                            <Offcanvas show={showaddresssec} onHide={handleCloseAddresssec} placement="end" className="custom-sidebar">
                                                <Offcanvas.Header closeButton></Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <h5>Edit Address</h5>
                                                    <div className="sidebar-body">
                                                        <KOTextarea
                                                            id="address"
                                                            placeholder="Enter your address"
                                                            label="Address"
                                                            name="address"
                                                            type="text"
                                                            maxLength="500"
                                                            value={updateAddressValue}
                                                            onChange={(e) => { handleUpdateAddress(e); }}
                                                            errorMessage={addressError}
                                                        />
                                                        <KOInput
                                                            id="city"
                                                            placeholder="Enter your city"
                                                            label="City"
                                                            name="city"
                                                            type="text"
                                                            value={updateCityValue}
                                                            onChange={(e) => { handleUpdateCity(e); }}
                                                            onKeyDown={(e) => enterKeySubmitAddressChange(e)}
                                                            errorMessage={cityError}
                                                        />
                                                        <KOSelectBox
                                                            id="state"
                                                            name="state"
                                                            label="State"
                                                            options={getStateOptions()}
                                                            value={updateStateValue}
                                                            type="text"
                                                            onChange={(e) => { handleUpdateState(e); }}
                                                            errorMessage={stateError}
                                                        />
                                                        <KOInput
                                                            id="zipcode"
                                                            placeholder="Enter your zip code"
                                                            label="ZIP Code"
                                                            name="zipcode"
                                                            type="text"
                                                            maxLength="6"
                                                            value={updateZipcodeValue}
                                                            onChange={(e) => { handleUpdateZipcode(e); }}
                                                            onKeyDown={(e) => enterKeySubmitAddressChange(e)}
                                                            errorMessage={zipCodeError}
                                                        />
                                                        <KOSelectBox
                                                            id="country"
                                                            name="country"
                                                            label="Country"
                                                            options={getCountryOptions()}
                                                            value={updateCountryValue}
                                                            type="text"
                                                            onChange={(e) => { handleUpdateCountry(e); }}
                                                        />
                                                        <div className="btn-sec">
                                                            <Button variant="primary" onClick={changeAddress}>Submit</Button>
                                                        </div>
                                                    </div>
                                                </Offcanvas.Body>
                                            </Offcanvas>




                                            <Offcanvas show={showTsevoProcess} onHide={handleCloseTsevoProcess} placement="end" className="custom-sidebar">
                                                <Offcanvas.Header closeButton></Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <h5>Verifying Address</h5>
                                                    <div className="sidebar-body">
                                                        <div className="tsevo-container">
                                                            {(identitySession) &&
                                                                <>
                                                                    <TESVOIdentity sessionID={identitySession} addressEdit={true} tsevoSessionURL = {tsevoSessionURL} isIpv4= {isIpisV4}/>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Offcanvas.Body>
                                            </Offcanvas>
                                        </div>
                                        <div className="info-data">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <p>{(addressValue) ? address : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>City</label>
                                                <p>{(city) ? city : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>State/Province/Area</label>
                                                <p>{(stateName) ? stateName : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <p>{(zipCode) ? zipCode : "NA"}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Country</label>
                                                <p>{(countryName) ? countryName : "NA"}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {(user_status != 1 || is_state_legal == 0) && <div className="alert-box">
                                        <i className="ic-alert"></i>
                                        <p>You are a resident of a state which is not eligible to play in contests that have entry fees or prizes. Please see our terms and
                                            conditions for further details. Consider creating or joining a user contest instead! <a href="javascript:void(0)" onClick={redirectToUserLeague}>View User Contest</a></p>
                                    </div>}

                                </div>
                            </Tab>

                            <Tab eventKey="responsiblegaming" title="Responsible Gaming">
                            {(saveSuccessMessage) ? <ToastContainer position="top-end" className="mt-3">
                                    <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                        <Toast.Body><TickIcon />{saveSuccessMessage}</Toast.Body>
                                    </Toast>
                                </ToastContainer> : ''}
                                <div className="responsible-gaming">
                                    <h5>Fantasy Sports Knockout  provides you with options to  protect yourself from problem gambling. Following are the Responsible Gaming options.</h5>
                                    <ul>
                                        <li>Monetary Limit setting - At any time, you can limit the amount you can spend  in a day, week or month by setting monetary limits.</li>
                                        <li>Contest Limit setting - At any time, you can limit the number of contests in which you allow yourself to participate in a day, week or month.</li>
                                        <li>Self-Exclusion - Provides users the option to temporarily close their account for the remainder of the current season. All funds must be withdrawn in order to self exclude.</li>
                                    </ul>
                                    <h5>During the self-exclusion period:</h5>
                                    <div className="block">
                                        <ul>
                                            <li>You can't log into your account</li>
                                            <li>You can't make any deposits.</li>
                                            <li>You can't enter contests.</li>
                                        </ul>
                                    </div>
                                    <p className="note"><span>Note:</span> Once excluded the action will be irrevocable and after the exclusion period your account will be auto re-instated.</p>
                                    <h5>During Contest Limit & Monetary Limit setting :</h5>
                                    <div className="block">
                                        <ul>
                                            <li>You can't join a contest once your limits are reached.</li>
                                            <li>You will be alerted when the limits are approaching.</li>
                                            <li>You will have the option to change the Contest limits set.</li>
                                        </ul>
                                    </div>

                                    <div className="btn-action-sec">
                                        <Button variant="outline-primary" onClick={SelfExclusionModalShow}>Self-Exclusion</Button>
                                        <Button variant="primary" onClick={SetLimitModalShow}>Set Limits</Button>
                                    </div>
                                    <Modal show={showSelfExclusionModal} onHide={SelfExclusionModalClose} className="selfexclusion-modal" backdrop="static">
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <h4 className="modal-title">Self-Exclusion</h4>
                                            {showSelfExclusionLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                            
                                            <p>By clicking "Apply" below, you acknowledge your understanding that you are
                                                temporarily closing your account for the remainder of the NFL season and that
                                                you will be unable to reverse this process. You further acknowledge that you are
                                                aware that you will be unable to log into your account for any reason. If you are
                                                looking to limit yourself, consider using the Contests Limits option instead.
                                            </p>
                                            <p>If you have funds in your wallet, clicking "Apply" will redirect you to your wallet to
                                                withdraw all funds. Your self-exclusion request will not be completed until all
                                                funds have been withdrawn.</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={selfExclusionConfirmation}>Apply</Button>
                                            <Button variant="link" onClick={SelfExclusionModalClose}>Cancel</Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={showSetLimitModal} onHide={SetLimitModalClose} className="setlimit-modal" backdrop="static">
                                    {showLimitLoaderModal ? <div className="loader-wrap"><div className="loader"></div></div>:
                                    <>
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                        
                                            <h4 className="modal-title">Set Limits</h4>
                                            <div className="limits-container">
                                                <div className="limit-header">Contest Limits</div>
                                                <div className="limit-content">
                                                    <h6>Limit the number of contests you wish to join:</h6>
                                                    <div className="set-field">
                                                        <KOInput
                                                            id="perweek"
                                                            label="Per Week"
                                                            name="perweek"
                                                            type="text"
                                                            value={contestLimitValue.perweek}
                                                            onChange={(e) => { handleContestLimitChange(e); }}
                                                            errorMessage={contestLimitError.perweek}
                                                        />
                                                        <KOInput
                                                            id="permonth"
                                                            label="Per Month"
                                                            name="permonth"
                                                            type="text"
                                                            value={contestLimitValue.permonth}
                                                            onChange={(e) => { handleContestLimitChange(e); }}
                                                            errorMessage={contestLimitError.permonth}
                                                        />
                                                        <KOInput
                                                            id="perseason"
                                                            label="Per Season"
                                                            name="perseason"
                                                            type="text"
                                                            value={contestLimitValue.perseason}
                                                            onChange={(e) => { handleContestLimitChange(e); }}
                                                            errorMessage={contestLimitError.perseason}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="limit-footer">You will be notified when you have joined 75% of your contest limit.</div>
                                            </div>
                                            <div className="limits-container">
                                                <div className="limit-header">Monetary Limits</div>
                                                <div className="limit-content">
                                                    <h6>Limit what you spend:</h6>
                                                    <div className="set-field">
                                                        <KOInput
                                                            id="perweek"
                                                            label="Per Week $"
                                                            placeholder="$"
                                                            name="perweek"
                                                            type="text"
                                                            value={monetaryLimitValue.perweek}
                                                            onChange={(e) => { handleMonetaryLimitChange(e); }}
                                                            errorMessage={monetaryLimitError.perweek}
                                                        />
                                                        <KOInput
                                                            id="permonth"
                                                            label="Per Month $"
                                                            placeholder="$"
                                                            name="permonth"
                                                            type="text"
                                                            value={monetaryLimitValue.permonth}
                                                            onChange={(e) => { handleMonetaryLimitChange(e); }}
                                                            errorMessage={monetaryLimitError.permonth}
                                                        />
                                                        <KOInput
                                                            id="perseason"
                                                            label="Per Season $"
                                                            placeholder="$"
                                                            name="perseason"
                                                            type="text"
                                                            value={monetaryLimitValue.perseason}
                                                            onChange={(e) => { handleMonetaryLimitChange(e); }}
                                                            errorMessage={monetaryLimitError.perseason}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="limit-footer">You will be notified when you have spent 75% of your limit.</div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={saveSetLimit}>Apply</Button>
                                            <Button variant="link" onClick={SetLimitModalClose}>Cancel</Button>
                                        </Modal.Footer>
                                        </>
                                    }
                                    </Modal>
                                </div>
                            </Tab>

                            <Tab eventKey="transactionhistory" title="Transaction History">
                                {showTransactionHistoryLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                {(!showTransactionHistoryLoader && transactionData.length === 0) ? <div class="vertical-center-position"><div class="row"><p>No records to display.</p></div></div> : <div className="transaction-details">
                                    {/* <div className="history-sort">
                                        <KOSelectBox
                                            id="historysort"
                                            name="historysort"
                                            options={getTransactionSortOptions()}
                                            value={props.historysortId}
                                            type="text"
                                            selectedValue={getTransactionSortOptions()}
                                        />
                                    </div> */}
                                    <div className="table-sec">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                    <th>Comment</th>
                                                    <th className="right">Amount($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactionData.map((item) => (
                                                    <tr>
                                                        <td><Moment format='MM/DD/YYYY | h:mm a'>{item.transaction_date}</Moment></td>

                                                        {
                                                            item.transaction_action_id === 1 ?
                                                                <td>Added Money</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 2 ?
                                                                <td>Withdraw Money</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 3 ?
                                                                <td>Won the contest</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 4 ?
                                                                <td>Joined Contest</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 5 ?
                                                                <td>Added lineups</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 6 ?
                                                                <td>Removed Line Ups</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 7 ?
                                                                <td>Created League</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 8 ?
                                                                <td>Left the Contest</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 9 ?
                                                                <td>League Cancelled</td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 10 ?
                                                                <td>Refund
                                                                </td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 11 ?
                                                                <td>Promo Deposit
                                                                </td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 12 ?
                                                                <td>Promo Refund
                                                                </td> : ""
                                                        }
                                                        {
                                                            item.transaction_action_id === 13 ?
                                                                <td>Admin Deposit
                                                                </td> : ""
                                                        }
                                                        <td>{item.transaction_comments}</td>
                                                        {[2,4,5,7].includes(item.transaction_action_id) ?
                                                            <td className="amount debit"> - {commonService.toCurrency(item.transaction_amount)}</td>
                                                            :
                                                            <td className="amount credit">+{commonService.toCurrency(item.transaction_amount)}</td>
                                                        }
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </Table>


                                        {transactionData.length >= 1 ? <PaginateV2 paginationData={transactionPageData} pageRows={transactionData.length} onClick={pageChange} onChange={pageItemChange} /> : ""}
                                    </div>
                                </div>}
                            </Tab>
                            <Tab eventKey="preferences" title="Preferences">
                                {showPreferencesLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                {(saveSuccessMessage) ? <ToastContainer position="top-end" className="mt-3">
                                    <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                        <Toast.Body><TickIcon />{saveSuccessMessage}</Toast.Body>
                                    </Toast>
                                </ToastContainer> : ''}
                                <div className="preference-details">
                                    <h5>Email Notification</h5>
                                    <div className="notification-block">
                                        <div className="title">
                                            <h5>General</h5>
                                            <Form.Check
                                                type="switch"
                                                id="generalNotification"
                                                name="generalNotification"
                                                onChange={(e) => { setFormData(e); }}
                                                checked={(generalNotificationPreference) ? true : false}
                                            />
                                        </div>
                                        <KOCheckbox
                                            name={"loginNotification"}
                                            label={(<><p>Login Notifications</p><span>For added security, receive a notification when you login to your account.</span></>)}
                                            id={"loginNotification"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(loginNotificationPreference) ? true : false}
                                            disabled={(!generalNotificationPreference) ? true : false}
                                        />
                                    </div>
                                    <div className="notification-block">
                                        <div className="title">
                                            <h5>Contests</h5>
                                            <Form.Check
                                                type="switch"
                                                id="contestNotification"
                                                name={"contestNotification"}
                                                onChange={(e) => { setFormData(e); }}
                                                checked={(contestNotificationPreference) ? true : false}
                                            />
                                        </div>
                                        <KOCheckbox
                                            label={(<><p>Join/Leave a contest</p><span>Receive a notification when you join or leave a contest.</span></>)}
                                            id="joinLeaveContest"
                                            name={"joinLeaveContest"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(joinLeaveContestNotificationPreference) ? true : false}
                                            disabled={(!contestNotificationPreference) ? true : false}
                                        />
                                        <KOCheckbox
                                            label={(<><p>Add/Remove lineup</p><span>Receive a notification when you create or remove a lineup.</span></>)}
                                            id="addRemoveLineup"
                                            name={"addRemoveLineup"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(addRemoveLineupNotificationPreference) ? true : false}
                                            disabled={(!contestNotificationPreference) ? true : false}
                                        />
                                        <KOCheckbox
                                            label={(<><p>Contest Invite</p><span>Receive a notification when you are invited to a contest.</span></>)}
                                            id="contestInvite"
                                            name={"contestInvite"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(contestInviteNotificationPreference) ? true : false}
                                            disabled={(!contestNotificationPreference) ? true : false}
                                        />
                                        <KOCheckbox
                                            label={(<><p>Contest Ends</p><span>Receive a notification when a contest you joined ends.</span></>)}
                                            id="contestEnd"
                                            name={"contestEnd"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(contestEndNotificationPreference) ? true : false}
                                            disabled={(!contestNotificationPreference) ? true : false}
                                        />
                                        <KOCheckbox
                                            label={(<><p>Achievements</p><span>Receive a notification when you win a prize.</span></>)}
                                            id="contestAchievements"
                                            name={"contestAchievements"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(contestAchievementsNotificationPreference) ? true : false}
                                            disabled={(!contestNotificationPreference) ? true : false}
                                        />
                                    </div>
                                    <div className="notification-block">
                                        <div className="title">
                                            <h5>User Leagues</h5>
                                            <Form.Check
                                                type="switch"
                                                id="userLeagueNotification"
                                                name={"userLeagueNotification"}
                                                onChange={(e) => { setFormData(e); }}
                                                checked={(userLeagueNotificationPreference) ? true : false}
                                            />
                                        </div>
                                        <KOCheckbox
                                            label={(<><p>Create/Delete a League</p><span>Receive a notification when you create/delete a league.</span></>)}
                                            id="createDeleteLeague"
                                            name={"createDeleteLeague"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(createDeleteLeagueNotificationPreference) ? true : false}
                                            disabled={(!userLeagueNotificationPreference) ? true : false}
                                        />
                                        <KOCheckbox
                                            label={(<><p>Messages</p><span>Receive a notification when you receive a message from the league creator.</span></>)}
                                            id="leagueMessages"
                                            name={"leagueMessages"}
                                            onChange={(e) => { setFormData(e); }}
                                            checked={(leagueMessagesNotificationPreference) ? true : false}
                                            disabled={(!userLeagueNotificationPreference) ? true : false}
                                        />
                                    </div>
                                    <div className="btn-sec"><Button variant="primary" onClick={updatePreferences}>Submit</Button> </div>
                                </div>
                            </Tab>
                            <Tab eventKey="invitefriends" title="Invite Friends">
                            {(saveSuccessMessage) ? <ToastContainer position="top-end" className="mt-3">
                                    <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                        <Toast.Body><TickIcon />{saveSuccessMessage}</Toast.Body>
                                    </Toast>
                                </ToastContainer> : ''}
                                <div className="invite-details">
                                    <h4>Invite your friends to Fantasy Sports Knockout </h4>
                                    <div className="email-invite">
                                        <label>Email address</label>
                                        <div className="email-group" >
                                            <KOInput
                                                id="email"
                                                placeholder="Enter the email address"
                                                onChange={(e) => { handleInviteFriendsEmailChange(e); }}
                                                value={inviteEmail}
                                                txtRef={refInviteEmail}
                                                name="email"
                                                type="text"
                                                errorMessage={inviteEmailError}
                                            />
                                            <Button variant="primary" onClick={addInviteMailQueue} id="addInvite">Add</Button>
                                        </div>
                                    </div>
                                    <h5>Invitation Queue <span className="count">({inviteEmailAdd.length})</span></h5>
                                    <div className="invite-queue">
                                        {inviteEmailAdd.length > 0 &&
                                            inviteEmailAdd.map((items, index) => (
                                                <div className="member-mail" id="deleteEmail_div" key={items}>
                                                    <p >{items}</p>
                                                    <Button variant="light" onClick={()=>(deleteEmailConfirmModalShow(),setDeleteEmailIndexValue(index))} id="removeInvite"><i className="ic-delete"></i></Button>
                                                    <Modal show={showDeleteEmailConfirmModal} onHide={deleteEmailConfirmModalClose} className="modal-predict">
                                                        <Modal.Header closeButton></Modal.Header>
                                                        <Modal.Body>
                                                            <h4 className="modal-title">Do you want to remove the email address from the queue?</h4>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="primary" onClick={() => deleteInviteEmail(deleteEmailIndexValue)}>Yes</Button>
                                                            <Button variant="link" onClick={deleteEmailConfirmModalClose}>Cancel</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            ))}
                                        <Button variant="primary" onClick={inviteFriendsEmail}>Send Invite</Button>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        <div className="wallet-balance-sec">
                            <h5>Your Wallet Balance</h5>
                            <h3>{commonService.toCurrency(wallet_amount)}</h3>
                        </div>
                        <div className="withdrawal-balance-sec">
                            <h5>Your Withdrawal Balance</h5>
                            <h3>{commonService.toCurrency(walletSplitUpData.withdrawalBalance)}</h3>
                            <Accordion onSelect={(e) => { showWalletSplitup(e); }}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Split up</Accordion.Header>
                                    <Accordion.Body>
                                    {showSplitUpLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                    <p>Deposited By You - <span>{commonService.toCurrency(walletSplitUpData.depositedAmount)}</span></p>
                                    <p>Your Winnings - <span>{commonService.toCurrency(walletSplitUpData.prizeMoney)}</span></p>
                                    <p>Knockout Bucks - <span>{commonService.toCurrency(walletSplitUpData.promoFund)}</span></p>
                                    {/* <p>Withdrawan Funds - <span>{commonService.toCurrency(walletSplitUpData.withdrawanFunds)}</span></p> */}
                                        <div className="available-balance">
                                            <p className="title">Withdrawable:</p>
                                            <p className="amount">{commonService.toCurrency(walletSplitUpData.withdrawalBalance)}</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Button variant="primary" className="wallet-btn" onClick={() => { navigate(pageURLs.payment); }}>DEPOSIT</Button>
                            <Button variant="outline-primary" className="wallet-btn" onClick={() => { navigate(pageURLs.withdrawfund); }}>WITHDRAW</Button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default MyAccountInfo;
