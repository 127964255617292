import React from "react";
import { Button } from "react-bootstrap";

const ChangeMailStep4 = (props) => {
    
    const changeMailStep4 = async () => {
        props.modalCloseHandle();
    };

	return (
		<>
        <div className="change-mail-process success-mail">
            <div className="success-alert"><i className="ic-tick"></i></div>
            <h5>Your email address has been successfully updated.</h5>
            <div className="btn-sec"><Button variant="primary" onClick= {changeMailStep4} >Done</Button> </div>
        </div>
		</>
	);

};

export default ChangeMailStep4;
