import React, { useState , useEffect, useRef} from "react";
import { Button  } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import { pageURLs } from "../appData/pageURLs";
import '../styles/signin.scss';
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import KOInput from "../components/KOInput";
import KOCheckbox from "../components/KOCheckbox";
import useGeoLocation from "../utils/useGeoLocation";
import { TermsAndConditionsIdentity  } from '../utils/CommonHelper';
import { useNavigate } from 'react-router-dom';
import * as userController  from "../controller/userController"
import * as stateService from "../services/stateService";
import KOSelectBox from "../components/KOSelectBox";
import KOTextarea from "../components/KOTextarea";
import * as commonService from "../services/commonService";

const Verificationstart = (props) => {

    const userData = store.getState().user
    const userState = useUserState();
    const user = useUserState();
    const location = useGeoLocation();
    let firstName = (userData.user.first_name) ? userData.user.first_name : '';
    let lastName = (userData.user.last_name) ? userData.user.last_name : ''; 
    let address = (userData.user.address) ? userData.user.address : ''; 
    let city = (userData.user.city) ? userData.user.city : ''; 
    let state_code = (userData.user.state_code) ? userData.user.state_code : ''; 
    let zip_code = (userData.user.zip_code) ? userData.user.zip_code : ''; 

    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [firstnameValue, setFirstnameValue] = useState(firstName);
    const [firstnameError, setFirstnameError] = useState("");
    const [lastnameValue, setLastnameValue] = useState(lastName);
    const [lastnameError, setLastnameError] = useState("");
   
    const [paymentAmount, SetPaymentAmount] = useState('');
    const [paymentError, SetPaymentError] = useState('');
    const [isGeolocationAvailable, SetGeolocationAvailable] = useState(false);
    const [geoLocationEnabled, SetGeolocationEnabled] = useState(false);
    const [coords, setcoords] = useState({});
    const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState(false);
    const [termsAndConditionsCheckboxError, setTermsAndConditionsCheckboxError] = useState("");
    const [updateAddressError, setupdateAddressError] = useState('');
    const [stateData, setStateData] = useState([]);
    const [addressText, setAddressText] = useState(address);
    const [addressError, setAddressError] = useState("");
    const [cityValue, setCityValue] = useState(city);
    const [cityError, setCityError] = useState("");
    const [stateValue, setStateValue] = useState(state_code);
    const [stateError, setStateError] = useState("");
    const [zipCodeValue, setZipCodeValue] = useState(zip_code);
    const [zipCodeError, setZipCodeError] = useState("");
    const navigate = useNavigate();
    let user_email = userState.data.user.email;

    const refFirstname = useRef(null);
    const refLastname = useRef(null);
    const refAddress = useRef(null);

    const handleAddresschange = (e) => {
        setAddressError("");
        setAddressText(e.target.value);
    };

    const handleCityChange = (e) => {
        setCityError("");
        setCityValue(e.target.value);
    };

    const handleStateChange = (e) => {
        setStateError("");
        setStateValue(e.target.value);
    };

    const handleZipChange = (e) => {
        setZipCodeError("");
        setZipCodeValue(e.target.value);
    };

    useEffect( async () => {
        if(userData.user.user_status_id === 1) {
            navigate(pageURLs.lobby);
        }
        sessionStorage.removeItem('duplicateentry');
        refAddress.current.focus();
        await stateService.getStateList()
            .then(response => {
                if (response.success) {
                    setStateData(response.data);
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);



    const updateAddress = async () => {
        let isValid = validate();
        const requestPayload ={
            first_name :firstnameValue,
            last_name : lastnameValue,
            email :user_email,
            address : addressText,
            city : cityValue,
            state : stateValue,
            zip_code : zipCodeValue,
        }
        let registeredUser
        if(isValid){
            await userController.updateUserNames(requestPayload)
            registeredUser = store.getState().user;
            if (registeredUser.success) {
                
                navigate(pageURLs.verifyIdentity);
            }
            setupdateAddressError(registeredUser.statusMessage)
        }
    };

    const validate = () => {
        let isValid = true;
        let focusSet = false;
        setFirstnameError("");
        if (firstnameValue === "" || firstnameValue === null || firstnameValue.trim() === "") {
            isValid = false;
            setFirstnameError("Enter First Name.");
            if (!focusSet) {
                refFirstname.current.focus();
                focusSet = true;
            }

        }
        setLastnameError("");
        if (lastnameValue === "" || lastnameValue === null || lastnameValue.trim() === "") {
            isValid = false;
            setLastnameError("Enter Last Name.");
            if (!focusSet) {
                refLastname.current.focus();
                focusSet = true;
            }
        }

        setAddressError("");
        if (addressText.trim() === "") {
            isValid = false;
            setAddressError("Enter Address.");
        }

        setCityError("");
        if (cityValue.trim() === "") {
            isValid = false;
            setCityError("Enter City.");
        }
        setStateError("");
        if (stateValue.trim() === "") {
            isValid = false;
            setStateError("Select State.");
        }
        setZipCodeError("");
        let zipcode_space_error = (zipCodeValue) ? commonService.validateSpace(zipCodeValue.trim()) : '';
        if (zipCodeValue.trim() === "") {
            isValid = false;
            setZipCodeError("Enter Zip Code.");
        } else if(zipcode_space_error) {
            isValid = false;
            setZipCodeError("Zip Code should not contain space.");
        }

        setTermsAndConditionsCheckboxError("")
        if(termsAndConditionsCheckbox === false) {
            isValid = false;
            setTermsAndConditionsCheckboxError("Agree to the Terms and Conditions to continue.")
        }

        return isValid;
    };

    useEffect(() => {
        if(location.loaded && !location.errorFlag) {
            SetGeolocationAvailable(true)
            SetGeolocationEnabled(true)
            setcoords(location.coordinates)
        } else  if(location.loaded && location.errorFlag) {
            SetGeolocationAvailable(false)
            SetGeolocationEnabled(false)
            setcoords({})
        }
    }, [location]);

    const handleFirstnameChange = (e) => {
        setFirstnameError("");
        setFirstnameValue(e.target.value);
    };

    const handleLastnameChange = (e) => {
        setLastnameError("");
        setLastnameValue(e.target.value);
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
          updateAddress();
        }
    };

    const handleTermsAndConditionsCheckbox = (e) => {
        setTermsAndConditionsCheckboxError("")
        setTermsAndConditionsCheckbox(e.target.checked);
    };

    const getStateOptions = () => {
        return stateData.map((item) => (
            { value: item.state_code, label: item.state_name }
        ));
    };

    const skipVerification = () => {
        navigate(pageURLs.lobby);
    };
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.verificationstart} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft verification-container">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>

                        <h5>Let's Get Started!</h5>
                        {updateAddressError && (
                        <span style={{color: "red"}}>{updateAddressError}</span>
                        )}
                        <div className="form-block">
                            <KOInput
                                i id="firstname"
                                label="First Name"
                                name="firstname"
                                type="text"
                                onChange={(e) => { handleFirstnameChange(e); }}
                                value={firstnameValue}
                                placeholder="Enter your first name"
                                maxLength="60"
                                txtRef={refFirstname}
                                onKeyDown={(e) => enterKeySubmit(e)}
                                errorMessage={firstnameError}
                            />
                            <KOInput
                                 id="lastname"
                                 label="Last Name"
                                 name="lastname"
                                 type="text"
                                 onChange={(e) => { handleLastnameChange(e); }}
                                 value={lastnameValue}
                                 placeholder="Enter your last name"
                                 maxLength="60"
                                 txtRef={refLastname}
                                 onKeyDown={(e) => enterKeySubmit(e)}
                                 errorMessage={lastnameError}
                            />
                        </div>
                        <div className="form-block">
                        <KOTextarea
                            id="address"
                            label="Address"
                            name="address"
                            type="text"
                            placeholder="Enter your street address"
                            maxLength="50"
                            onChange={(e) => { handleAddresschange(e); }}
                            value={addressText}
                            errorMessage={addressError}
                            txtRef={refAddress}
                            showCount = {false}
                        />
                        <KOInput
							id="city"
							label="City"
							name="city"
							type="text"
                            placeholder="Enter your city"
                            maxLength="50"
                            onChange={(e) => { handleCityChange(e); }}
                            value={cityValue}
                            errorMessage={cityError}
						/>
                         </div>
                        <div className="form-block">
                        <KOSelectBox
                            id="state"
                            name="state"
                            label="State"
                            options={getStateOptions()}
                            type="text"
                            onChange={(e) => { handleStateChange(e); }}
                            errorMessage={stateError}
                            value={stateValue}
                            
                        />
                        <KOInput
							id="zipcode"
							label="ZIP Code"
							name="zipcode"
							type="text"
                            placeholder="Enter your zipcode"
                            maxLength="6"
                            onKeyDown={(e) => enterKeySubmit(e)}
                            onChange={(e) => { handleZipChange(e); }}
                            value={zipCodeValue}
                            errorMessage={zipCodeError}
						/>
                        </div>
                        
                        <div className="checkbox-group d-flex align-items-center">
                            <KOCheckbox
                                name={"termsofservices"}
                                label=""
                                id={"termsofservices"}
                                onKeyDown={(e) => enterKeySubmit(e)}
                                    onChange={(e)=>{handleTermsAndConditionsCheckbox(e)}}
                            />
                            <p>I agree to the {TermsAndConditionsIdentity()}</p>
                            { termsAndConditionsCheckboxError && termsAndConditionsCheckboxError !== '' &&
                                    <p><div className="form-group">
                                        <span className='error-message'>{termsAndConditionsCheckboxError}</span>
                                    </div></p>
                                }
                        </div>
                        <div className="btn-block d-flex align-items-center">
                        {(isGeolocationAvailable && geoLocationEnabled) && <Button variant="primary" className="btn-main" onClick={updateAddress}>Verify Account Information</Button> }
                            <a href="javascript:void(0)" onClick={skipVerification}>Skip Verification</a>
                            {!geoLocationEnabled && <div style={{color: "red"}}>Geolocation is required for updating the information</div>} 
                        </div>
                    </div>
                   
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default Verificationstart;
