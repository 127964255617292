import React, { useState , useEffect} from "react";
import { Button, Tab, Tabs, OverlayTrigger, Tooltip, Modal, Popover  } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import { pageURLs } from "../appData/pageURLs";
import "../styles/payment.scss";
import * as userController from "../controller/userController"
import TESVOPayment from "../components/TsevoPayment";
import * as transactionController from "../controller/transactionController"
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import * as commonService from "../services/commonService";
import KOInput from "../components/KOInput";
import {  useNavigate } from 'react-router-dom'
import { fundAddCouponCheck} from "../services/userService";
import useGeoLocation from "../utils/useGeoLocation";
import KORadiobutton from "../components/KORadiobutton";
import { checkIpFormat } from '../utils/CommonHelper';

const Payment = (props) => {

    const userData = store.getState().user
    const user = useUserState();
    const location = useGeoLocation();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let paystatus = params.get('paystatus');
    let MerchantTransactionID = params.get('MerchantTransactionID') ? params.get('MerchantTransactionID') : '';
    let firstName = userData.user.first_name;
    let lastName = userData.user.last_name; 
    let email = userData.user.email;
    let userFullName = userData.user.user_full_name;
    let dob = userData.user.dob;
    let stateCode = userData.user.state_code;
    let user_status = userData.user.user_status_id;
    let countryCode = userData.user.country_code;
    let city = userData.user.city;
    let address = userData.user.address;
    let zipCode = userData.user.zip_code;
    let tsevo_customer_id = userData.user.tsevo_customer_id;
    let user_id = userData.user.user_id;
    let wallet_amount  = userData.user.wallet_amount;
    const navigate = useNavigate();
    let newAmoutDataArray = [];
    for (var i = 100; i <= 1000; i += 100) {
        newAmoutDataArray.push(i)
    }
    let showPaymentProcessAlert = (paystatus == 'complete') ? true : false;
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [paymentSession, setPaymentSession] = useState("");
    const [stepState, setStepState] = useState("");
    const [amountData, setAmountData] = useState(newAmoutDataArray);
    const [paymentAmount, SetPaymentAmount] = useState('');
    const [paymentProcessFlag, SetPaymentProcessFlag] = useState(showPaymentProcessAlert);
    const [showLoader, SetShowLoader] = useState(false);
    const [paymentError, SetPaymentError] = useState('');
    const [withdrawProcessFlag, SetWithdrawProcessFlag] = useState(false);
    const [amountToWithdraw, SetAmountToWithdraw] = useState("");
    const [withDrawalError, SetWithDrawalError] = useState('');
    const [showPaymentProcessScreen, SetPaymentProcessScreen] = useState(showPaymentProcessAlert);
    const [showCouponRedeemBtn, SetshowCouponRedeemBtn] = useState(showPaymentProcessAlert);
    const [showcouponModal, SetCouponModal] = useState(false);
    const [couponCode, SetcouponCode] = useState('');
    const [couponCodeError, SetcouponCodeError] = useState(false);
    const [showCouponSuccessModal, setShowCouponSuccessModal] = useState(false);
    const [couponSuccessMessage, setcouponSuccessMessage] = useState('');
    const [transactionId, setTransactionId] = useState(MerchantTransactionID);
    const [showCouponCheckLoader, SetCouponCheckLoader] = useState(false);
    const [isGeolocationAvailable, SetGeolocationAvailable] = useState(false);
    const [isGeolocationEnabled, SetGeolocationEnabled] = useState(false);
    const [showVerificationProcessModal, setshowVerificationProcessModal] = useState(false);
    const [notverifiedMessage, setnotverifiedMessage] = useState("Complete verification to add cash");
    const [verificationBtnTitle, setverificationBtnTitle] = useState("Continue with Verification");
    const [paymentTypeValue, setpaymentTypeValue] = useState("CC");
    const [showPaymentRedirectionModal, setPaymentRedirectionModal] = useState(false);
    const [achActumPaymentLink, setachActumPaymentLink] = useState('');
    const [paymentSessionURL, setPaymentSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);

    const [coords, setcoords] = useState({});
    const paymentprocess = "PaymentProcess";
    const withdrawamount = "withdrAwamount";

    //For payment purpose we need to access geo location
    // const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp } =
    //     useGeolocated({
    //         positionOptions: {
    //             enableHighAccuracy: false,
    //         },
    //         userDecisionTimeout: 5000,
    // });


    const getSideMenuClasses = () => {
        let result = "koblock-right content-block-rht Payment-info";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const handleAmountchange = (e) => {
        SetPaymentError('')
        if(Number(e.target.value)) {
            SetPaymentAmount(e.target.value);
        } else {
            SetPaymentAmount('');
            SetPaymentError("Enter valid amount");
        }
    };

    const handleWithdrawalchange = (e) => {
        SetAmountToWithdraw(e.target.value);
    };


    const showPaymentProcessModal = () => {
        SetPaymentProcessScreen(true);
        SetshowCouponRedeemBtn(true)
    }
    const hidePaymentProcessModal = () => SetPaymentProcessScreen(false);

    
    //Deposit amount
    const depositeAmount = async () => {
        let isValid = validate();
        if(user_status != 1) {
            SetPaymentError("Your identity is not verified.");
            return false;
        } else if (paymentAmount < 20 ) {
            SetPaymentError("The minimum amount allowed is $20.");
            return false;
        }
        if (isValid) {
            SetPaymentProcessFlag(true)
            SetShowLoader(true)
            await userController.getUserIp()
                .then(async function(ipdata) {
                    let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                    if(customerIp) {
                        await transactionController.createTransactionRecord(paymentAmount, 'deposit', customerIp, coords, paymentTypeValue)
                            .then(async function(orderData) {
                            if(orderData.errorCode == 200) {
                                let sessionID = orderData?.data?.sessionID;
                                
                                let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                                let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                                if (sessionID !== null) {
                                    setTransactionId(orderData.data.transaction_id)
                                    await user.action.setPaymentProcessedStatus(true);
                                    if(paymentTypeValue === 'ACTUM') {
                                        await transactionController.completePayment(paymentAmount, 'deposit', orderData.data.transaction_id) 
                                        .then(async function(completePaymentData) {
                                            if(completePaymentData.errorCode === 200 && completePaymentData.data.actionUrl) {
                                                SetPaymentProcessFlag(false)
                                                SetShowLoader(false)
                                            
                                                //window.open(completePaymentData.data.actionUrl, '_blank').focus();
                                                setachActumPaymentLink(completePaymentData.data.actionUrl)
                                                setPaymentRedirectionModal(true)

                                            } else {
                                                let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                                                let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                                                SetPaymentProcessFlag(false)
                                                SetShowLoader(false)
                                                SetPaymentError(completePaymentData.message);
                                            }

                                        })
                                    } else {
                                    //   let sessionURL = decodeURIComponent(orderData?.data?.sessionURL)
                                        // if(sessionURL) {
                                        //     sessionURL = sessionURL.replace('/+/g', ' ');
                                        // }
                                        let isIpv4 = checkIpFormat(customerIp);
                                        setisIpisV4(isIpv4)
                                        setPaymentSession(sessionID);
                                        setPaymentSessionURL(orderData?.data?.sessionUrl)
                                        setStepState(paymentprocess);
                                        SetShowLoader(false)
                                    }
                                } else {
                                    SetPaymentProcessFlag(false)
                                    SetShowLoader(false)
                                    setTransactionId('')
                                }
                            } else {
                                let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                                let userResponse = await user.action.SetUserStateLeagalData(is_state_legal);
                                SetPaymentProcessFlag(false)
                                SetShowLoader(false)
                                SetPaymentError(orderData.message);
                            }
                        })
                    } else {
                        SetPaymentProcessFlag(false)
                        SetShowLoader(false)
                        SetPaymentError("Could not process the request. Please try again later.");
                    }
            })
        }
    }

    const getamountOptions = () => {
        return amountData.map((item) => (
            { value: item, label: item }
        ));
    };

    const validate = () => {
        let isValid = true;
        if (paymentAmount == 0) {
            isValid = false;
            SetPaymentError("Enter amount");
        } else if(isNaN(paymentAmount)) {
            isValid = false;
            SetPaymentError("Enter valid amount");
        } else if(Number(paymentAmount) < 20) {
            isValid = false;
            SetPaymentError("The withdrawal amount should be more than $20.");
            return false;
        }

        return isValid;
    }

    const changeTabs = async (tab) => {
        if(tab == 'withdrawcash') {
            navigate(pageURLs.withdrawfund)
        } else {
            navigate(pageURLs.payment)
        }
    }

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));
    
        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract' ||  e.code === 'NumpadAdd' ||  e.code === 'Equal') {
            e.preventDefault();
        }
    };

    const continueVerification = (e) => {
        navigate(pageURLs.Verificationstart);
    }

    const closeVerification = (e) => {
        setshowVerificationProcessModal(false);
    }

    useEffect(async () => {
     
        if(user_status != 1) { //Not verified users
            if(user_status == 4 && (user.data.user.tsevo_status_id == 1 || user.data.user.tsevo_status_id == 2)) { //Verification failed or not started the verification
                setnotverifiedMessage('Complete verification to add cash')
            }  else if((user_status == 4 || user_status == 5) && user.data.user.tsevo_status_id == 3) { //Verification failed or not started the verification
                setnotverifiedMessage('Identity verification was unsuccessful. Please try again. If you continue to encounter difficulties, please reach out to a Knockout representative at admin@fantasysportsknockout.com for assistance.')
                
            } else if(user_status == 5) {
                setnotverifiedMessage("Your identity verification is still in progress. Please be patient - in rare cases, verification can take up to 24 hours. If it has been 24 hours or longer, please contact Knockout customer care at admin@fantasysportsknockout.com.");
                setverificationBtnTitle("Retry verification")
            }
            setshowVerificationProcessModal(true)
            return false;
        }
    }, [user_status]);   

    const popover = (
        <Popover id="popover-info">
          <Popover.Body>
            Money that you have added can be used to join contests.
          </Popover.Body>
        </Popover>
    );

    const cancelCouponModal = () => {
        SetcouponCodeError("");
        SetcouponCode("")
        SetCouponModal(false);
        if(showPaymentProcessAlert) {
            closePaymentProcessScreen()
            window.location.href = pageURLs.payment
        }
    };

    const showCouponModal = () => {
        SetCouponModal(true);
    };

    const handleCouponCodeChange = (e) => {
        SetcouponCodeError("");
        SetcouponCode(e.target.value);
    };

    const couponCheck = async () => {
        let isValid = validateCouponCheck();
        const requestPayload ={
            couponCode : couponCode,
            transaction_id : transactionId
        }
        if(isValid){
            SetCouponCheckLoader(true)
            await fundAddCouponCheck(requestPayload)
            .then(function (response){
                if(response.errorCode == 200) {
                    
                    if(response.data.validCoupon === true) {
                        setShowCouponSuccessModal(true);
                        setcouponSuccessMessage(response.data.message)
                        SetshowCouponRedeemBtn(false)
                        setTransactionId('')
                        cancelCouponModal()
                        if(showPaymentProcessAlert) {
                            window.location.href = pageURLs.payment
                        }
                    } else{
                        SetcouponCodeError(response.data.message)
                    }
                    SetCouponCheckLoader(false)
                } else {
                    SetcouponCodeError(response.message)
                    SetCouponCheckLoader(false)
                }
            })
        }
    };

    const validateCouponCheck = () => {
        let isValid = true;
        let focusSet = false;
        SetcouponCodeError("");
        if (couponCode.trim() === "") {
            isValid = false;
            SetcouponCodeError("Enter coupon code.");
           
        }
        return isValid;
    };

    const closeCouponSuccessModal = () => {
        SetcouponCodeError("");
        setShowCouponSuccessModal(false);
        if(showPaymentProcessAlert) {
            window.location.href = pageURLs.payment
        }
    }

    useEffect(() => {
        if(location.loaded && !location.errorFlag) {
            SetGeolocationAvailable(true)
            SetGeolocationEnabled(true)
            setcoords(location.coordinates)
        } else  if(location.loaded && location.errorFlag) {
            SetGeolocationAvailable(false)
            SetGeolocationEnabled(false)
            setcoords({})
        }
    }, [location]);

    const handlePaymentTypeChange = (e) => {
        const { name, value } = e.target;
        setpaymentTypeValue(value)
       
    }

    const closePaymentProcessScreen = (e) => {
        setPaymentRedirectionModal(false);
        setachActumPaymentLink(null)
        setpaymentTypeValue('CC')
        SetPaymentAmount('')
    }

    const processPayment = (e) => {
        setPaymentRedirectionModal(false);
        setpaymentTypeValue('CC')
        SetPaymentAmount('')
        window.open(achActumPaymentLink, '_blank').focus();
    }


    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.payment} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft Payment-container">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        <Tabs
                            defaultActiveKey="addcash"
                            transition={false}
                            id="payment-tab"
                            className="payment-info-tab"
                            onSelect={(k) => changeTabs(k)}
                        >
                            <Tab eventKey="addcash" title="Add Cash">

                                {(!paymentProcessFlag && paystatus != 'complete') ? <div className="payment-data">
                                    <p className="note">Please note: eCheck/ACH deposits can take up to 3-5 business days dependent upon your bank.</p>
                                    <div className="amount-selector">
                                        <div className="multiple-select-box">
                                            <div className="form-group radio-field d-flex align-items-center">
                                                <KORadiobutton
                                                    name="cardType"
                                                    label="Credit Card"
                                                    id="creditcard"
                                                    value="CC"
                                                    checked={paymentTypeValue == 'CC'}
                                                    onChange={handlePaymentTypeChange}
                                                />
                                                <KORadiobutton
                                                    name="cardType"
                                                    label="Bank Account"
                                                    id="Authentecheck"
                                                    value="ACTUM"
                                                    checked={paymentTypeValue == 'ACTUM'}
                                                    onChange={handlePaymentTypeChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="amount-title">
                                            <h5>Enter Amount <span>(USD)</span></h5>
                                            {/* <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 100, hide: 25000 }}
                                                overlay={
                                                    <Tooltip>Money that you have added can be used to join contests and cannot be withdrawn.</Tooltip>
                                                }
                                            >
                                                <i className="ic-question"></i>
                                            </OverlayTrigger> */}
                                            <OverlayTrigger trigger="hover" placement="top" overlay={
                                                    <Tooltip>Money that you have added can be used to join contests.</Tooltip>
                                                }>
                                                <i className="ic-question"></i>
                                            </OverlayTrigger>
                                        </div>
                                        <KOInput
                                            id="amount"
                                            label="Amount"
                                            name="amount"
                                            type="text"
                                            minValue = "20"
                                            onChange={(e) => { handleAmountchange(e); }}
                                            value={paymentAmount}
                                            errorMessage={paymentError}
                                            autocapitalize="none"
                                            onKeyDown={preventMinus}
                                            onPaste={preventPasteNegative}
						                />

                                        {<p>Minimum deposit amount: $20</p> }
                                        

                                    {(user_status == 1 && isGeolocationAvailable && isGeolocationEnabled) && <div className="btn-sec"><Button variant="primary" className="btn-main" onClick={depositeAmount}>Deposit</Button></div> }
                                    {/* {!isGeolocationAvailable && <div style={{color: "red"}}>Your browser does not support Geolocation</div>} */}
                                    {!isGeolocationEnabled && <div style={{color: "red"}}>Geolocation is required for making the payment</div>}
                                    </div>
                                </div> : ''}
                                {paymentProcessFlag &&
                                    <div className="payment-data">
                                        <div className="tsevo-container">
                                            {(stepState === paymentprocess) &&
                                                <>
                                                    <TESVOPayment sessionID={paymentSession}  tsevoSessionURL = {paymentSessionURL} isIpv4= {isIpisV4}/>
                                                </>
                                            }
                                            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                        </div>
                                        {showCouponRedeemBtn && <div className="redeem-info">If you have a deposit associated Coupon to redeem, </div>}
                                        {showCouponRedeemBtn && <Button variant="primary" className="btn-main" onClick={showCouponModal}>Click to Redeem now</Button>}
                                    </div>}

                                    <Button variant="light" onClick={showPaymentProcessModal} id="paymentSuccessMessageAlert" className="hide"></Button>
                                    
                                    <Modal show={showPaymentProcessScreen} onHide={hidePaymentProcessModal} className="modal-coupon">
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <h4 className="modal-title">Your payment is being processed. It will be reflected soon in your wallet.</h4>
                                        </Modal.Body>
                                    </Modal>

                                    <Modal show={showcouponModal} onHide={cancelCouponModal} className="modal-coupon" >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                        <h4 className="modal-title">Coupon Code</h4>
                                            <p className="info">Your wallet will be credited with a bonus % of fund deposited.</p>
                                            <p className="info">The % factor depends on the coupon redeemed.</p>
                                            <div className="score-selector">
                                                <div className="inputgroup">
                                                    <KOInput
                                                        id="coupon_code"
                                                        placeholder="Enter coupon code"
                                                        name="coupon_code"
                                                        type="text"
                                                        maxLength={8}
                                                        value={couponCode}
                                                        errorMessage={couponCodeError}
                                                        onChange={(e) => { handleCouponCodeChange(e); }}
                                                    />
                                                    {showCouponCheckLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                                    
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={couponCheck}>Redeem</Button>
                                            <Button variant="link" onClick={cancelCouponModal}>Cancel</Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={showCouponSuccessModal} onHide={closeCouponSuccessModal} className="modal-coupon">
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                        <h4 className="modal-title">{couponSuccessMessage}</h4>
                                        </Modal.Body>
                                    </Modal>

                                    <Modal show={showVerificationProcessModal} onHide={closeVerification} className="modal-coupon">
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <h4 className="modal-title">Verification</h4>

                                            <p>{notverifiedMessage}</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={continueVerification}>{verificationBtnTitle}</Button>
                                            <Button variant="link" onClick={closeVerification}>Maybe later</Button>
                                        </Modal.Footer>
                                    </Modal>


                                    <Modal show={showPaymentRedirectionModal} onHide={closePaymentProcessScreen} className="modal-predict">
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <h4 className="modal-title">Payment</h4>

                                            <p>You are leaving the site now and will be redirected to our secure payment gateway to complete your purchase. </p>
                                            <br/><p>Click "Continue" to proceed to the payment page.</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={processPayment}>Continue</Button>
                                            <Button variant="link" onClick={closePaymentProcessScreen}>Cancel</Button>
                                        </Modal.Footer>
                                    </Modal>
                            </Tab>

                            <Tab eventKey="withdrawcash" title="Withdraw Cash">
                                

                                
                            </Tab>
        
                        </Tabs>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        <h6 className="current-account-balance">Current Balance</h6>
                        <h2>{commonService.toCurrency(wallet_amount)}</h2>
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default Payment;
