import * as downloadService from "./downloadService";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function getNotifications(userId) {
    // return await downloadService.downloadData('/data/dummyData/notifications.json');
    return await apiService.apiCall(endPoints.GET_ALL_NOTIFICATIONS, 'GET');
}

export async function markAsRead(notificationId) {
    // return downloadService.successResponse();
    return await apiService.apiCall(endPoints.NOTIFICATION_MARK_AS_READ, 'PUT',{
        notification_id:notificationId
    });
}

export async function markAllAsRead() {
    // return downloadService.successResponse();
    return await apiService.apiCall(endPoints.NOTIFICATION_MARK_ALL_AS_READ, 'PUT');
}
