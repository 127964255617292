import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { store } from '../appState/store';
import useSiteState from '../appState/siteState/useSiteState';
import { useDate } from '../components/DateDisplay';
import useUserState from "../appState/userState/useUserState";
import { useParams, Link } from 'react-router-dom';
import * as contestService from "../services/contestService";
import PlayerInjured from '../assets/icons/playerinjured';
import PlayerNews from "../assets/icons/playernews";
import PlayerGameStatus from '../components/PlayerGameStatus';
import PlayerStats from '../components/PlayerStats';

const KOLineupCard = props => {
    const userState = useUserState();
    let { contestid } = useParams()
    let contestId = parseInt(contestid);
    const siteData = store.getState().site
    const siteState = useSiteState();

    let showCard = true;
    const userId = userState.data.user.user_id;
    const [showconfirmmodal, setConfirmModal] = useState(false);
    const [selectedPlayerData, setSelectedPlayerData] = useState([]);
    const handleConfirmModalClose = () => setConfirmModal(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [lineUpWeekInfo, setLineUpWeekInfo] = useState([]);
    const [lineUpIsActive, setLineUpIsActive] = useState(props.lineUpIsActive);
    const [showplayermodal, setPlayerModal] = useState(false);

    useEffect(() => {
        if (props.lineUps) {
            setLineUpWeekInfo(props.lineUps)
        } 
    }, [props.lineUps]);




     useEffect(() => {
        if(siteState.data.Lobby.LineupsPerWeek){
            setLineUpWeekInfo(siteState.data.Lobby.LineupsPerWeek)
        }
     }, [siteState.data.Lobby.LineupsPerWeek]);


    const handleConfirmModalShow = () => {
        setConfirmModal(true);
        setSelectedPlayerData(props)
    }

    const playerList = siteState.data.Lobby.PlayerList;
    const { year } = useDate();
 

    if (props.playersInLineup.length >= 1) {
        showCard = false;
    } else {
        showCard = true;
    }

    let card_type = (props.type) ? props.type : 'lineupSetup';

    if (card_type !== 'lineupSetup') {
        showCard = false;
    }

    const handlePlayerList = async () => {
        
        siteState.action.setSearchText("");
        siteState.action.isShowPositionList(false);
        siteState.data.Lobby.PlayerSearchSortOptions.SortBy = 'name';
        siteState.data.Lobby.SearchData = '';
        setConfirmModal(false);

        let position = "";
        let searchData = "";
        position = props.position;
        let id = "";
        id = props.dataIndex;
        let lineup_week = props.lineup_week;
        let lineup_number = props.lineup_number;
        siteState.action.showPlusDeleteIcon(true);
        props.updateSelectedCardIndex(id)
        await siteState.action.updateSelectedPlayerCard(id);
        siteState.action.playerPositionUpdate(position);
        if (position === "Quarterback" && id === 0) {
            let position = "QB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "RUNNING BACK" && id === 1) {
            position = "RB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "RUNNING BACK" && id === 2) {
            position = "RB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 3) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 4) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 5) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "TIGHT END" && id === 6) {
            position = "TE";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "FLEX PLAYER" && id === 7) {
            position = "FL";
            await siteState.action.lineUpPlayerList(year, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "KICKER" && id === 8) {
            position = "K";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else {
            if (id === 9)
                position = "DF";
            await siteState.action.lineUpTeamPlayerList(year, siteState.data.Lobby.SearchData, id, 1, contestId, lineup_week, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        }
       
    }

    const removeSelectedPlayer = async () => {
        let playerDataArray = {
            player_position: selectedPlayerData.fsk_position_abr,
            lineup_number: selectedPlayerData.lineup_number,
            lineup_week: selectedPlayerData.lineup_week,
            player_card_id: selectedPlayerData.player_card_id,
            si_contest_id: selectedPlayerData.si_contest_id,
            player_id: selectedPlayerData.player_id,
            user_id: userId
        }
        setConfirmModal(false);
        let result = await props.removePlayersFromLineup(playerDataArray);

        if (result) {
            await siteState.action.updateSelectedPlayerCard(selectedPlayerData.player_card_id);
            handlePlayerList();
        }
    }

    const noAction = async () => {
    }




    const [playerSelected, setPlayerSelected] = useState("");
    const [showPointerState, setShowPointerState] = useState("");
    let defaultClassName = 'lineupcard';
    const [cardClassName, setCardClassName] = useState(defaultClassName);
    const [deleteIconShow, setDeleteIconShow] = useState(false)

    useEffect(() => {
        if (props.dataIndex == props.SelectedPlayerCardIndex && props.playername && props.type === "lineupSetup" && props.isLocked == 0 && (props.contest_status_id == 3 || props.contest_status_id == 2) )
            setDeleteIconShow(true)
        else
            setDeleteIconShow(false)
    }, [props.dataIndex, props.SelectedPlayerCardIndex, props.playername, props.type, props.isLocked])

    useEffect(() => {
       
        let selectedPlayer = (props.SelectedPlayerCardIndex == props.dataIndex && props.type == 'lineupSetup') ? "selected" : '';
        setPlayerSelected(selectedPlayer);

    }, [props.SelectedPlayerCardIndex, props.dataIndex, props.type])

    useEffect(() => {
        let shwointer = (playerSelected == '' && !props.eliminatedLineUp && props.type == 'lineupSetup' && !props.isWeekCompleted) ? ' pl-selector ' : "";
        setShowPointerState(shwointer);

    }, [playerSelected, props.eliminatedLineUp, props.type, props.isWeekCompleted])


    useEffect(() => {
        let classes = defaultClassName + " " + props.className
        if (lineUpIsActive) {

            classes += showPointerState + " " + " " + playerSelected + " ";
            classes += props.isLocked == 1 ? "locked" : '';

            setCardClassName(classes);
        } else {
            classes += ' deactive ';
            setCardClassName(classes);
        }

    }, [showPointerState, props.className, playerSelected, props.isLocked, lineUpIsActive])


    const getShowDefaultLineUp = () => {
        return (
            <>
                <div key={'linep' + props.dataIndex} onClick={handlePlayerList} className={cardClassName} id={props.dataIndex} name={props.placingPosition} >
                    <figure></figure>
                    <div className='name'></div>
                    <p></p>
                    <div className='score'></div>
                    <div className="status-info-sec">
                        <div className='position' onClick={handlePlayerList}  >{props.position}</div>
                    </div>
                </div>
            </>
        )
    }

    const getShowLineUpCardPlayer = () => {
        let gameData = {
            "away_score" : props.away_score,
            "away_team" : props.away_team,
            "game_key" : props.game_key,
            "has_started" : props.has_started,
            "home_score" : props.home_score,
            "home_team" : props.home_team,
            "is_inprogress" : props.is_inprogress,
            "is_over" : props.is_over,
            "is_overtime" : props.is_overtime,
            "quater" : props.quater,
            "schedule_date" : props.schedule_date,
            "time_remaining" : props.time_remaining,
            "team_key" : props.team_key,
            "playername" : props.playername,
            "quater_desc" : props.quater_desc,
            "byeweek" : props.byeweek,
            "lineup_week" : props.lineup_week
        }

        const handlePlayerModalClose = () => {
            setPlayerModal(false);
        }
        
        const handlePlayerModalShow = () => {
            setPlayerModal(true);
        }
        return (
            <>
                <div key={'divlinep' + props.dataIndex} onClick={((playerSelected == '' && props.type === 'lineupSetup' && !props.eliminatedLineUp && !props.isWeekCompleted && (props.contest_status_id == 3 || props.contest_status_id == 2)) ? handlePlayerList : noAction)} className={cardClassName} id={props.dataIndex} name={props.placingPosition}>

                    {props.figure ? <figure  onClick={handlePlayerModalShow}>{props.figure && <img src={props.figure} />}</figure> : <figure ></figure>}

                    {deleteIconShow ? <Link to="#" className="remove-item" title="Remove Player " onClick={handleConfirmModalShow} ><i className="ic-trash"></i></Link> : ""}

                    <div className='name'>{props.playername}</div>
                   
                    <div className='score'>{props.score}</div>
                    {(props.is_player_injured) ? <PlayerInjured injuryStatus = {props.is_player_injured}/> : ''}
                    {(props.is_player_news_avail) ? <span onClick={handlePlayerModalShow} className="news-icon"><PlayerNews onClick={handlePlayerModalShow}/></span> : ''}
                    <PlayerGameStatus position = {props.position} type = {props.type} gameData ={gameData} />
 
                   

                    {(showplayermodal) && <PlayerStats showplayermodal = {showplayermodal} handlePlayerModalClose = {handlePlayerModalClose} playerData ={props}/>}

                    <Modal show={showconfirmmodal} onHide={handleConfirmModalClose} className="modal-predict">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <h4 className="modal-title">Are you sure you want to remove Player?</h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={removeSelectedPlayer}>Yes</Button>
                            <Button variant="link" onClick={handleConfirmModalClose}>Cancel</Button>
                           
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
    return (
        <>
            {showCard ? getShowDefaultLineUp() : getShowLineUpCardPlayer()}
        </>

    );
}
export default (KOLineupCard);
