import React, { useState, useEffect, useRef } from "react";
import { Form, Button ,Modal } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import KOInput from "../components/KOInput";
import Copyright from '../components/Copyright';
import googleicon from '../assets/icon-google.svg';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";
import {store} from '../appState/store';
import GoogleLogin from 'react-google-login';
import * as userController from "../controller/userController"

const UserCheck = (props) => {
    localStorage.removeItem("loginCheckUser");
    const refEmail = useRef(null);
    const [emailValue, setEmailValue] = useState("");
    const [emailError, setEmailError] = useState("");
    const [loginError, setloginError] = useState('');
    const [googleLoginError, setgoogleloginError] = useState('');
    const navigate = useNavigate();
    const user = useUserState();
    const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    localStorage.removeItem('registerPassword');

    useEffect(() => {
        // TODO Remove after versions 0.1.0
        refEmail.current.focus();
        localStorage.removeItem("persist:root");
       // console.log("Local storage cleaned");

      //  console.log("App version", appUIVersion);
        if (user.data.IsLoggedIn) {
            checkUserContestInvitedBeforeRedirect();
           // navigate(pageURLs.lobby);
        } else
        sessionStorage.setItem('IsUserGame', 'false' )
        
    }, []);

    const checkUserContestInvitedBeforeRedirect = () => {
        if (sessionStorage.getItem('redirectUserTo') && sessionStorage.getItem('redirectUserTo') != '') {
            sessionStorage.setItem('IsUserGame', 'true')
            navigate( sessionStorage.getItem('redirectUserTo'));
        } if (sessionStorage.getItem('loginContestId') && sessionStorage.getItem('loginContestId') > 0) {
            let loginContestId = sessionStorage.getItem('loginContestId');
            sessionStorage.setItem('loginContestId', 0);
            navigate(pageURLs.joincontest+'/'+loginContestId);
        } else {
            navigate(pageURLs.lobby);
        }
    }


    const validate = () => {
        let isValid = true;
        let focusSet = false;

        setEmailError("");
        if (emailValue === "") {
            isValid = false;
            setEmailError("Enter Email or Username.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }

        return isValid;
    };

    const handleEmailChange = (e) => {
        setEmailError("");
        setEmailValue(e.target.value);
    }

    const userCheck = async () => {
        let isValid = validate();
        let userDetails
        const requestPayload ={
            user_name :emailValue
        }
        if(isValid){
            userDetails = await userController.userCheck(requestPayload);
        }
        if (userDetails.success) {
            localStorage.setItem("loginCheckUser",emailValue)
            if(userDetails.data.user_exist && !userDetails.data.social_media_account) {
                navigate(pageURLs.login);
            } else if(!userDetails.data.user_exist && !userDetails.data.social_media_account) {
                navigate(pageURLs.signup);
            } else if(userDetails.data.user_exist && userDetails.data.social_media_account) {
                setloginError("This email address is already linked to a Google/Apple account. Please use the respective login options to proceed.");
            } else {
                navigate(pageURLs.login);
            }
        }  else {
            setloginError(userDetails.statusMessage);
        }
        //setloginError(userDetails.statusMessage)
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            userCheck();
        }
    };

    const responseGoogle = async(response) => {
        let googleuserDetails;
        await user.action.googleLogin(response.profileObj.email, response.accessToken,  1, response.tokenId, response.profileObj.googleId);
        googleuserDetails = store.getState().user

        if (googleuserDetails.statusCode === 200 && googleuserDetails.user.user_status_id !== 2 && googleuserDetails.user.user_status_id !== 3 ) {
            checkUserContestInvitedBeforeRedirect (); 
        } else {
            setloginError(googleuserDetails.statusMessage)
        }
    };

    const responseGoogleFailure = (response) => {
        console.log(response);
    }
    

	return (
		<>
		<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container">
					<h4>Sign In Or Create an Account </h4>
                    {loginError && (
                        <span style={{color: "red"}}>{loginError}</span>
                        )}
					
						<KOInput
                            id="email"
                            label="Email or Username"
                            name="email"
                            type="text"
                            maxLength="80"
                            onChange={(e) => { handleEmailChange(e); }}
                            value={emailValue}
                            txtRef={refEmail}
                            errorMessage={emailError}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            autocapitalize="none"
						/>
						
					
					<div className="account-create d-flex align-items-center justify-content-between">
                        <Button variant="primary" onClick={userCheck}>Continue</Button>
						<p>Don't have an account? <Link to={pageURLs.signup} title="Sign Up" >Sign Up</Link></p>
					</div>
					<div className="forgot-pwd-sec"><Link to={pageURLs.forgotpassword} title="Forgot Password">Forgot Password</Link></div>
					<div className="social-signin">
						<h6>Or Sign In with</h6>
						<div className="btn-sec">
                            <GoogleLogin
                                clientId={GOOGLE_APP_ID}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogleFailure}
                                cookiePolicy={'single_host_origin'}
                                jsSrc="https://apis.google.com/js/api.js"
                                scope="profile email"
                                errorMessage={googleLoginError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick} ><img src={googleicon} alt="KO"/>Google</Button>
                                )} 
                            />
							{/* <FacebookLogin
                                appId={FB_APP_ID}
                                autoLoad={false}
                                fields="id, email, first_name, last_name, name, picture"
                                scope="public_profile,email"
                                auth_type='reauthorize'
                                callback={fbLogin}
                                errorMessage={fbLoginError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick}><img src={fbicon} alt="KO"/>Facebook</Button>
                                )}
                            /> */}
						</div>
					</div>
				</div>
			</div>

            
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default UserCheck;
