import React, { useState, useEffect, useRef } from "react";
import { Form, Button ,Modal } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import KOInputButtonGroup from "../components/KOInputButtonGroup";
import Copyright from '../components/Copyright';
import googleicon from '../assets/icon-google.svg';
import KOPasswordinputgroup from "../components/KOPasswordinputgroup";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";
import {store} from '../appState/store';
import GoogleLogin from 'react-google-login';


const Login = (props) => {
    const navigate = useNavigate();

    let userEmail = localStorage.getItem("loginCheckUser") ? localStorage.getItem("loginCheckUser") : '';
    let userNameFieldDisabledStatus = (userEmail) ? true : false;
    if(!userNameFieldDisabledStatus) {
        navigate(pageURLs.root);
    }
    
    const refEmail = useRef(null);
    const refPassword = useRef(null);
    const [emailValue, setEmailValue] = useState(userEmail);
    const [emailError, setEmailError] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loginError, setloginError] = useState('');
    const [googleLoginError, setgoogleloginError] = useState('');
    const [fbLoginError, setfbloginError] = useState('');
    const [showInvalidLoginPopup, setshowInvalidLoginPopup] = useState(false);
   // const [hideInvalidLoginModal, sethideInvalidLoginModal] = useState(false);
    const [tsevoReconfirmationMessage, settsevoReconfirmationMessage] = useState('');
    const [userNameFieldDisabled, setuserNameFieldDisabled] = useState(userNameFieldDisabledStatus);
    const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
    const userState = store.getState().user;

    
    const user = useUserState();
    const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    localStorage.removeItem('registerPassword');
   

    useEffect(() => {
        // TODO Remove after versions 0.1.0
        refEmail.current.focus();
        localStorage.removeItem("persist:root");
       // console.log("Local storage cleaned");

      //  console.log("App version", appUIVersion);
        if (user.data.IsLoggedIn) {
            checkUserContestInvitedBeforeRedirect();
           // navigate(pageURLs.lobby);
        } else
        sessionStorage.setItem('IsUserGame', 'false' )
        
    }, []);

    const checkUserContestInvitedBeforeRedirect = () => {
        if (sessionStorage.getItem('redirectUserTo') && sessionStorage.getItem('redirectUserTo') != '') {
            sessionStorage.setItem('IsUserGame', 'true')
            navigate( sessionStorage.getItem('redirectUserTo'));
        } if (sessionStorage.getItem('loginContestId') && sessionStorage.getItem('loginContestId') > 0) {
            let loginContestId = sessionStorage.getItem('loginContestId');
            sessionStorage.setItem('loginContestId', 0);
            navigate(pageURLs.joincontest+'/'+loginContestId);
        } else {
            navigate(pageURLs.lobby);
        }
    }


    const validate = () => {
        let isValid = true;
        let focusSet = false;

        setEmailError("");
        if (emailValue === "") {
            isValid = false;
            setEmailError("Enter Username.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }

        setPasswordError("");
        if (passwordValue === "") {
            isValid = false;
            setPasswordError("Enter Password.");
            if (!focusSet) {
                refPassword.current.focus();
                focusSet = true;
            }
        }

        return isValid;
    };

    const handleEmailChange = (e) => {
        setEmailError("");
        setEmailValue(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPasswordError("");
        setPasswordValue(e.target.value);
    }

    const signIn = async () => {
        let isValid = validate();
        let userDetails
        if(isValid){
            await user.action.login(emailValue, passwordValue);
            userDetails = store.getState().user
        }
        //console.log(userDetails)
        if (userDetails.success) {
            localStorage.removeItem("loginCheckUser")
            checkUserContestInvitedBeforeRedirect ();            
        }  else {
            setloginError(userDetails.statusMessage);
        }
        //setloginError(userDetails.statusMessage)
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            signIn();
        }
    };

    const resetForm = () => {
        userState.first_name = "";
        userState.last_name = "";
        userState.user_name = "";
        userState.email = "";
    };
    
	const handleChange =()=>{
		setEmailError(false)
		setPasswordError(false)
		if(emailValue === ""){
			setEmailError("true") ;
		}
		if( passwordValue===""){
			setPasswordError("true") ;
		}
	};

    const responseGoogle = async(response) => {
        let googleuserDetails;
        await user.action.googleLogin(response.profileObj.email, response.accessToken,  1, response.tokenId, response.profileObj.googleId);
        googleuserDetails = store.getState().user

        if (googleuserDetails.statusCode === 200 && googleuserDetails.user.user_status_id !== 2 && googleuserDetails.user.user_status_id !== 3 ) {
            localStorage.removeItem("loginCheckUser")
            checkUserContestInvitedBeforeRedirect (); 
        } else {
            setloginError(googleuserDetails.statusMessage)
        }
    };
    
    const responseGoogleFailure = (response) => {
        console.log(response);
    }
    
    const fbLogin = async (response) => {
        if(response){
            await user.action.fbLogin(response.email, response.accessToken, 1, response.id);
            let userData = store.getState().user;

            if(userData.statusCode === 200 && userData.user.email && userData.user.user_status_id !== 2 && userData.user.user_status_id !== 3) {
                checkUserContestInvitedBeforeRedirect (); 
                //navigate(pageURLs.lobby)
            
            } else if(userData.statusCode !== 200) {
                setloginError(userData.statusMessage);
            }
        }
        else {
            setloginError("Could not login. Please try again later.");
        }
    }

    const hideInvalidLoginModal = () => {
        setshowInvalidLoginPopup(false)
    }
	
    const reverificationProceed = () => {
        navigate(pageURLs.verifyaddress);
    }
    const editUsername = () => {
        setuserNameFieldDisabled(false)
    }
	return (
		<>
		<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container">
					<h4>Welcome Back!</h4>
                    {loginError && (
                        <span style={{color: "red"}}>{loginError}</span>
                        )}
					<Form className="">
						<KOInputButtonGroup
                            id="email"
                            label="Email or Username"
                            name="email"
                            type="text"
                            maxLength="80"
                            onChange={(e) => { handleEmailChange(e); }}
                            value={emailValue}
                            txtRef={refEmail}
                            errorMessage={emailError}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            autocapitalize="none"
                            showRemoveBtn = {false}
                            showBtn = {false}
						/>
						<KOPasswordinputgroup
                            id="password"
                            label="Password"
                            name="password"
                            maxLength="60"
                            onChange={(e) => { handlePasswordChange(e); }}
                            value={passwordValue}
                            txtRef={refPassword}
                            errorMessage={passwordError}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            autocapitalize="none"
                            
                        />
					</Form>
					<div className="account-create d-flex align-items-center justify-content-between">
                        <Button variant="primary" onClick={signIn}>Sign In</Button>
						<p>Don't have an account? <Link to={pageURLs.signup} onClick ={resetForm} title="Sign Up" >Sign Up</Link></p>
					</div>
					<div className="forgot-pwd-sec"><Link to={pageURLs.forgotpassword} title="Forgot Password">Forgot Password</Link></div>
					<div className="social-signin">
						<h6>Or Sign In with</h6>
						<div className="btn-sec">
                            <GoogleLogin
                                clientId={GOOGLE_APP_ID}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogleFailure}
                                cookiePolicy={'single_host_origin'}
                                jsSrc="https://apis.google.com/js/api.js"
                                scope="profile email"
                                errorMessage={googleLoginError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick} ><img src={googleicon} alt="KO"/>Google</Button>
                                )} 
                            />
							{/* <FacebookLogin
                                appId={FB_APP_ID}
                                autoLoad={false}
                                fields="id, email, first_name, last_name, name, picture"
                                scope="public_profile,email"
                                auth_type='reauthorize'
                                callback={fbLogin}
                                errorMessage={fbLoginError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick}><img src={fbicon} alt="KO"/>Facebook</Button>
                                )}
                            /> */}
						</div>
					</div>
				</div>
			</div>

            <Modal show={showInvalidLoginPopup} onHide={hideInvalidLoginModal} className="modal-predict tsevo-flow">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">{tsevoReconfirmationMessage}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={hideInvalidLoginModal}>Return Later</Button>
                        <Button variant="primary" onClick={reverificationProceed}>Proceed</Button>
                    </Modal.Footer>
                </Modal>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default Login;
