import * as transactionService from "../services/transactionService";

export const createTransactionRecord = async (payment_amount, transaction_action, customerIp, coords, paymentTypeValue) => {
  const response = await transactionService.createTransactionRecord(payment_amount, transaction_action, customerIp, coords, paymentTypeValue);
  return response;
}

export const getTransactionRecord = async (transHistoryPageParams) => {
  const response = await transactionService.getTransactionRecord(transHistoryPageParams);
  return response;
}

export const makeUserGamePayment = async (customerIp, coords, contest_id) => {
  const response = await transactionService.makeUserGamePayment(customerIp, coords, contest_id);
  return response;
}

export const completePayment = async (payment_amount, transaction_action, orderId) => {
  const response = await transactionService.completePayment(payment_amount, transaction_action, orderId);
  return response;
}
