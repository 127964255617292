import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";


const ByeWeek = props => {
    
    return (
      <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip" {...props}>{'Bye Week'}</Tooltip>}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" className="player-injury">
          <g id="Group_17608" data-name="Group 17608" transform="translate(-375 -1208)">
            <g id="Group_16585" data-name="Group 16585" transform="translate(11 518)">
              <rect id="Rectangle_1828" data-name="Rectangle 1828" width="28" height="14" rx="7" transform="translate(364 690)" fill="#ffa518" />
            </g>
            <path id="Path_10574" data-name="Path 10574" d="M5.115-3.938a1.726,1.726,0,0,1,1.067.649A1.9,1.9,0,0,1,6.6-2.079,1.95,1.95,0,0,1,6.3-1a2.014,2.014,0,0,1-.875.732A3.2,3.2,0,0,1,4.07,0H.759V-7.678H3.927a3.285,3.285,0,0,1,1.348.253,1.919,1.919,0,0,1,.858.7A1.843,1.843,0,0,1,6.424-5.7a1.75,1.75,0,0,1-.358,1.122A1.913,1.913,0,0,1,5.115-3.938ZM2.3-4.51H3.707a1.289,1.289,0,0,0,.847-.247.871.871,0,0,0,.3-.709.888.888,0,0,0-.3-.715,1.27,1.27,0,0,0-.847-.253H2.3ZM3.85-1.254a1.318,1.318,0,0,0,.874-.264.924.924,0,0,0,.314-.748.963.963,0,0,0-.33-.775,1.331,1.331,0,0,0-.891-.281H2.3v2.068Zm10.2-6.424-2.6,5V0H9.911V-2.673l-2.607-5H9.042l1.65,3.509,1.639-3.509Zm2.541,1.243V-4.51h2.585v1.221H16.588v2.035H19.5V0H15.048V-7.689H19.5v1.254Z" transform="translate(379.242 1218.689)" fill="#fff" />
          </g>
        </svg>


    </OverlayTrigger>
      

        
    );

}

export default ByeWeek;