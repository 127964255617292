import React, { useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import * as contestController from "../controller/contestController";
import { useNavigate } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";

const LineupButtonGroup = (props) => {
  const navigate = useNavigate();

  const user = useUserState();
  const statusId = props.contestStatusId;
  const [contestStatusId, setContestStatusId] = useState(statusId);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [totalPlayersInLineup, setTotalPlayersInLineup] = useState(0);
  const [showLoaderModal, setLoaderModal] = useState(false);
  const [leaveContestConfirmMsg, setLeaveContestConfirmMsg] = useState('Please confirm that you wish to leave the contest');
  const [showLeaveErrorModal, setLeaveErrorModal] = useState(false);
  const [leaveContestErrorMsg, setLeaveContestErrorMsg] = useState();


  let id = props.contestid;
  let user_id = props.userId;
  let isusergame = (props.userGame) ? true : false;

  useEffect(() => {
    if (props.saveButtonShow && (contestStatusId != 5))
      setShowButton(true);
    else
      setShowButton(false);
  }, [props.saveButtonShow, contestStatusId])


  useEffect(() => {
    if (props.lineupPlayers)
      setTotalPlayersInLineup(props.lineupPlayers.length)
   }, [props.lineupPlayers])

  const saveAddLineUpPlayers = () => {
    props.saveLineUpButton();
  }

  const leaveContest = async() => {
    if(props.checkRefundStatus) {
      setLoaderModal(true);
      let contestData = {
        'si_contest_id': id,
        'user_id': user_id,
        'action' : 'leftcontest'
      }
      await contestController.checkRefundStatus(contestData)
      .then(function(response){
        setLoaderModal(false)
        setLoader(false)
        if(response.errorCode == 200) {
          setLeaveContestConfirmMsg(response.message)
          setConfirmModal(true)
        } else {
          setConfirmModal(false)
        }
      })
    } else {
      setConfirmModal(true)
      setLoaderModal(false)
      setLoader(false)
    }
  }
  const cancelLeaveAction = () => {
    setConfirmModal(false)
  }

  const hideLoderModal = () => {
    setLoaderModal(false)
  }

  const confirmLeaveAction = async () => {
    setLoader(true);
    let contestData;
    if(isusergame) {
      contestData = {
        'lg_contest_id': id
      }
    } else {
      contestData = {
        'si_contest_id': id
      }
    }

    let response = await contestController.leaveTheContest(contestData, isusergame);
    if (response && response.errorCode == 200) {
      navigate(pageURLs.lobby);
      user.action.getUserData();
    } else {
      setLoaderModal(false)
      setConfirmModal(false)
      setLeaveContestErrorMsg(response.message)
      setLeaveErrorModal(true)
    }
  }
  const hideLeaveErrorMessage = () => {
    setLeaveErrorModal(false)
  }

  const saveLineup = () => {
    props.lineupSave(true)
  }
  return (
    <>
      {props.enableLineupButton == true ? <Button variant="success" className='btn-green' onClick={saveLineup} >Save Lineup</Button>  : <Button variant="success" className='btn-green' disabled={true} >Save Lineup</Button>}
      {props.leaveButtonShow ? <Button variant="light" className='btn-leave' onClick={leaveContest} >Leave Contest</Button> : ''}

      {/* {showButton ? <Button variant="light" className='btn-save'   onClick={saveAddLineUpPlayers} >Save Lineup</Button> : <Button variant="light" className='btn-save' disabled={totalPlayersInLineup>=10? 'disabled' : ""}   >Save Lineup</Button>} */}

      <Modal show={showConfirmModal} onHide={cancelLeaveAction} className="modal-predict">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="modal-title">{leaveContestConfirmMsg}</h4>
          {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={confirmLeaveAction}>Yes</Button>
          <Button variant="link" onClick={cancelLeaveAction}>No</Button>
          
        </Modal.Footer>
      </Modal>

      <Modal show={showLoaderModal} onHide={hideLoderModal} className="modal-predict">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="modal-title">Checking refund status</h4>
          <div className="loader-wrap"><div className="loader"></div></div>
        </Modal.Body>
      </Modal>

      <Modal show={showLeaveErrorModal} onHide={hideLeaveErrorMessage} className="modal-predict">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="modal-title">{leaveContestErrorMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={hideLeaveErrorMessage}>OK</Button>
        </Modal.Footer>
      </Modal>
      </>
    );
}

export default (LineupButtonGroup);