import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import KOCheckbox from "./KOCheckbox";
import KOSelectBox from "./KOSelectBox";
import KOSearchInput from "./KOSearchInput";
import validator from 'validator';
import useSiteState from '../appState/siteState/useSiteState';
import { lobbyFilterOptions } from "../appData/lobbyFilterOptions";

const LobbyFilter = (props) => {

    const siteState = useSiteState();
    //const filterState = props.value;
    const [searchBoxText, setSearchBoxText] = useState('');
    const [searchTextChanged, setSearchTextChanged] = useState(false);
    const [filterState, setFilterState] = useState(props.value);
    const [filterChanged, setFilterChanged] = useState(0);

    useEffect(()=>{
        setSearchBoxText('')
        setSearchTextChanged(false)
    }, [props.pagePathRefreshed])


    const feeAmountOptions = [
        { value: -1, label: 'Select' },
        { value: 0, label: 'FREE' },
        { value: 1, label: '$1' },
        { value: 2, label: '$2' },
        { value: 3, label: '$3' },
        { value: 5, label: '$5' },
        { value: 10, label: '$10' },
        { value: 15, label: '$15' },
        { value: 20, label: '$20' },
        { value: 25, label: '$25' },
        { value: 50, label: '$50' },
        { value: 100, label: '$100' },
        { value: 250, label: '$250' },
        { value: 500, label: '$500' },
        { value: 1000, label: '$1,000' },
        { value: 2000, label: '$2,000' },
        { value: 5000, label: '$5,000' },
        { value: 12500, label: '$12,500' }
    ];

    let contestStatusOptions = [
        { value: 'All', label: 'All' },
        { value: 'Upcoming', label: 'Upcoming' },
        { value: 'Live', label: 'Live' },
        { value: 'Completed', label: 'Past' }
    ];
    if(props.manageLeague) {
        contestStatusOptions.push( { value: 'Unpublished', label: 'Unpublished' })
    }

    const clearFilter = () => {
        setSearchBoxText('');
        let existingFilterState = filterState
        existingFilterState.searchText = "";
        existingFilterState.isStandard = false;
        existingFilterState.isKOPlus = false;
        existingFilterState.isGuaranteed = false;
        existingFilterState.isMultipleEntry = false;
        existingFilterState.isFree = false;
        existingFilterState.joinFeeFrom = "";
        existingFilterState.joinFeeTo = "";
        existingFilterState.startOn = "";
        existingFilterState.isFree = false;
        existingFilterState.filter_by = 'All';
        setFilterState(existingFilterState)
        onSearch(existingFilterState);
    }

    const onSearch = () => {
      //  if (props.onChange) {
              //    props.onChange({ filterOptions: values, lobbyFilterOption: siteState.data.Lobby.FilterOption });
            switch(filterState.filter_by) {
                case "All" :
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.All;
                    break;
                case "Upcoming" :
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.Upcoming;
                    break;
                case "Completed" :
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.Completed;
                    break;
                case "Live" :
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.Live;
                    break;
                case "Unpublished" :
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.Unpublished;
                    break;
                default:
                    siteState.data.Lobby.FilterOption = lobbyFilterOptions.All;
                    break;
            }
            siteState.action.changeLobbyContestFilter(
                { filterOptions: filterState, lobbyFilterOption: siteState.data.Lobby.FilterOption },
                siteState.data.Lobby.IsJoinedOnly,
                siteState.data.Lobby.ContestSortOptions,
                siteState.data.IsUserGame,
                props.isManageGames);
      //  }
    }

    const validateDate = (value) => {
        if (validator.isDate(value)) {
            //pass
        } else {
            alert('Enter a valid Date.')
        }
    }

    useEffect(() => {
        if (searchBoxText != "") {
            setSearchTextChanged(true);
        }

        if (searchTextChanged) {
            filterState.searchText = searchBoxText;
          //  onSearch(filterState);
        } 

    }, [searchBoxText, searchTextChanged])

    const searchByText = (e) => {
        setSearchBoxText(e.target.value != '' ? e.target.value : '');
    }

    useEffect(()=>{
        setSearchBoxText('');

        let existingFilterState = filterState
        existingFilterState.searchText = "";
        existingFilterState.isStandard = false;
        existingFilterState.isKOPlus = false;
        existingFilterState.isGuaranteed = false;
        existingFilterState.isMultipleEntry = false;
        existingFilterState.isFree = false;
        existingFilterState.joinFeeFrom = "";
        existingFilterState.joinFeeTo = "";
        existingFilterState.startOn = "";
        existingFilterState.isFree = false;
        existingFilterState.filter_by = 'All';
        setFilterState(existingFilterState)
    }, [siteState.data.IsUserGame])

    const filterContest = (value, field) => {
        let existingFilterState = filterState;
        if (field == 'standard') {
            existingFilterState.isStandard = value;
        } else if (field == 'isKOPlus') {
            existingFilterState.isKOPlus = value;
        } else if (field == 'isGuaranteed') {
            existingFilterState.isGuaranteed = value;
        }  else if (field == 'isMultipleEntry') {
            existingFilterState.isMultipleEntry = value;
        } else if (field == 'isFree') {
            existingFilterState.isFree = value;
        } else if (field == 'joinFeeFrom') {
            existingFilterState.joinFeeFrom = value;
        } else if (field == 'joinFeeTo') {
            existingFilterState.joinFeeTo = value;
        } else if (field == 'startOn') {
            existingFilterState.startOn = value;
        } else if (field == 'filter_by') {
            existingFilterState.filter_by = value;
        }
        
        setFilterState(existingFilterState)
        setFilterChanged(Math.random())
    }

    useEffect(() => {
        let existingFilterState = filterState;
        setFilterState(existingFilterState)
    }, [filterChanged])

    return (
        <div className="filter-block">
            <KOSearchInput
                id="searchfield"
                placeholder="Search"
                name="search"
                type="text"
                value={searchBoxText}
                onChange={(e) => { searchByText(e);  }}
                containerId="contestSearchBox"
            />
            <div className="checklist-group" id="contestTypeFilter">
                <div className="checkgroup">
                    <KOCheckbox
                        name={"standard"}
                        label={"Standard Knockout"}
                        id={"standardcheck"}
                        checked={filterState.isStandard}
                        onChange={(e) => { filterContest(e.target.checked, 'standard'); }}
                    />
                </div>
                {props.KnockoutPlusEnabled === 1  ? <div className="checkgroup">
                    <KOCheckbox
                        name={"knockoutPlus"}
                        label={"Knockout Plus"}
                        id={"knockoutPlusCheck"}
                        checked={filterState.isKOPlus}
                        onChange={(e) => { filterContest(e.target.checked, 'isKOPlus'); }}
                    />
                </div>: ''} 
                {!siteState.data.IsUserGame ? <div className="checkgroup">
                    <KOCheckbox
                        name={"guarantee"}
                        label={"Guaranteed Contests"}
                        id={"guaranteecheck"}
                        checked={filterState.isGuaranteed}
                        onChange={(e) => { filterContest(e.target.checked, 'isGuaranteed'); }}
                    />
                    <span className="badge bg-g">G</span>
                </div> : ""}
                <div className="checkgroup">
                    <KOCheckbox
                        name={"multiple"}
                        label={"Multiple Entry Contests"}
                        id={"multiplecheck"}
                        checked={filterState.isMultipleEntry}
                        onChange={(e) => { filterContest(e.target.checked, 'isMultipleEntry'); }}
                    />
                    <span className="badge bg-m">M</span>
                </div>
                {!siteState.data.IsUserGame ? <div className="checkgroup">
                    <KOCheckbox
                        name={"free"}
                        label={"Free Contest"}
                        id={"freecheck"}
                        checked={filterState.isFree}
                        onChange={(e) => { filterContest(e.target.checked, 'isFree'); }}
                    />
                    <span className="badge bg-f">F</span>
                </div> : ""}
            </div>
            {!siteState.data.IsUserGame ? <div className="select-group" id="contestEntryFeeFilter">
                <p>Entry Fee</p>
                <div className="multibox">
                    <KOSelectBox
                        id="feeamount1"
                        name="feeamount"
                        options={feeAmountOptions}
                        type="text"
                        value={filterState.joinFeeFrom}
                        onChange={(e) => { filterContest(e.target.value, 'joinFeeFrom'); }}
                    />
                    <span>to</span>
                    <KOSelectBox
                        id="feeamount2"
                        name="feeamount"
                        options={feeAmountOptions}
                        type="text"
                        value={filterState.joinFeeTo}
                        onChange={(e) => { filterContest(e.target.value, 'joinFeeTo'); }}
                    />
                </div>
            </div> : ""}
            <div className="select-group" id="contestDateFilter">
                <p>Starts on</p>
                <Form.Control
                    type="date"
                    name='starts_on'
                    value={filterState.startOn}
                    onKeyDown={() => { return false; }}
                    onChange={(e) => { filterContest(e.target.value, 'startOn'); validateDate(e.target.value)  }}
                />
            </div>
            <div className="select-group">
                <p>Status</p>
                <KOSelectBox
                    id="statusselect"
                    name="statusselect"
                    type="text"
                    options={contestStatusOptions}
                    value={filterState.filter_by}
                    onChange={(e) => { filterContest(e.target.value, 'filter_by'); }}
                />
            </div>
            <Button variant="primary" className="m-0" onClick={onSearch}>APPLY</Button>
            <Button variant="outline-primary" onClick={clearFilter} id="clearContestSearch">Clear Filter</Button>
        </div>
    );
}

export default (LobbyFilter);
