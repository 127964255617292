
export const Type = Object.freeze({
    LogIn: 'User_LogIn',
    LogOut: 'User_LogOut',
    RefreshToken: 'Refresh_Token',
    registration: 'User_Registration',
    FbLogIn: 'Fb_User_LogIn',
    GoogleLogIn: 'Google_User_LogIn',
    GetUserData: 'Get_User_Data',
    SetTemporaryProfilePic: 'SetTemporaryProfilePic',
    SetUserData: 'SetUserData',
    SetPaymentProcessedStatus: 'SetPaymentProcessedData',
    SetUserStateLeagalData: 'SetUserStateLeagalData',
    SetTsevoReverification: 'SetTsevoReverification',
});

export const LogIn = (userData) => {
    return {
        type: Type.LogIn,
        payload: userData
    };
}

export const LogOut = () => {
    return {
        type: Type.LogOut
    };
}
export const RefreshToken = (userData) => {
    return {
        type: Type.RefreshToken,
        payload: userData
    };
}
export const userRegistration = (userData) => {
    return {
        type: Type.registration,
        payload: userData
    };
}

export const fbLogIn = (userData) => {
    return {
        type: Type.FbLogIn,
        payload: userData
    };
}

export const googleLogIn = (userData) => {
    return {
        type: Type.GoogleLogIn,
        payload: userData
    };
}

export const getUserData = (userData) => {
    return {
        type: Type.GetUserData,
        payload: userData
    };
}

export const SetTemporaryProfilePic = (profilePic) => {
    return {
        type: Type.SetTemporaryProfilePic,
        payload: profilePic
    };
}

export const setUserData = (payLoad) => {
    return {
        type: Type.SetUserData,
        payload: payLoad
    };
}

export const setPaymentProcessedStatus = (status) => {
    return {
        type: Type.SetPaymentProcessedStatus,
        payload: status
    };
}
export const SetUserStateLeagalData = (payLoad) => {
    return {
        type: Type.SetUserStateLeagalData,
        payload: payLoad
    };
}

export const SetTsevoReverificationData = (payLoad) => {
    return {
        type: Type.SetTsevoReverification,
        payload: payLoad
    };
}



