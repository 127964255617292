import React, { useState, useEffect, useRef, Component } from "react";
import { Button } from "react-bootstrap";
import KOOTPInput from "./KOOTPInput";
import {store} from '../appState/store';
import * as userController from "../controller/userController";
const ChangeMailStep2 = (props) => {
    const refOtp = useRef(null);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    let userData = store.getState().user;
   

    const handleOtpChange = (val) => {
        setOtpError("");
        setOtpValue(val);
    };

    const validateOTP = () => {
        let isValid = true;
        let focusSet = false;
        setOtpError("");
        if (otpValue.replace(/,/g, "").length !== 6 ) {
            isValid = false;
            setOtpError("Enter a valid OTP");
            if (!focusSet) {
                focusSet = true;
            }
        }

        return isValid;
    };

    const changeMailStep2 = async () => {
        let isValid = validateOTP();
        let userDetails = store.getState().user
        const requestPayload ={
            //userId :userDetails.user.user_id,
            otp : otpValue.replace(/,/g, ""),
        }
        if (isValid) {
            let response = await userController.verifyEmailChangeOTP(requestPayload);
            if (response.errorCode === 200) {
                props.sendDataToParent();
                props.setOtpForEmailChange(otpValue.replace(/,/g, ""))
            } else {
                setOtpError(response.message);
            }
        }
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            changeMailStep2();
        }
    };
	return (
		<>
        <div className="change-mail-process">
            <h5>Enter your OTP</h5>
            <p>We have sent a code to <span>{userData.user.email}</span>. Please check your inbox and enter the 6-digit code found in the email below to verify your email address.</p>
            <div className="otp-verify"><KOOTPInput onChange={handleOtpChange} errorMessage={otpError}  onKeyDown={(e) => enterKeySubmit(e)} /></div>
            <div className="btn-sec"><Button variant="primary" onClick={changeMailStep2} >Verify Email</Button> </div>
        </div>
		</>
	);

};

export default ChangeMailStep2;