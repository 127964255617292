import React, { useState, useEffect, useRef } from "react";
import { Button} from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import CreateLeagueStep1 from "../components/CreateLeagueStep1";
import CreateLeagueStep2 from "../components/CreateLeagueStep2";
import CreateLeagueStep3 from "../components/CreateLeagueStep3";
import CreateLeagueStep4 from "../components/CreateLeagueStep4";
import CreateLeagueStepPayment from "../components/CreateLeagueStepPayment";
import contesticon from '../assets/contest-star.svg';


const CreateLeagueStepper = (props) => {
   // console.log(props.isJoinedOtherUsers)
    let isJoinedOtherUsers = (props.isJoinedOtherUsers) ? props.isJoinedOtherUsers : false;
    let totalJoinedCount = (props.totalJoinedCount) ? props.totalJoinedCount : 0;
    const [showPaymentStep, setShowPaymentStep] = useState(props.paymentStatus);  
    let gameCreateSteps = [{ label: 'League Details' }, { label: 'Lineup Strategy' }, { label: 'League Scoring' }, { label: 'Invite Friends' }];
    if(!showPaymentStep) {
        gameCreateSteps = [{ label: 'League Details' }, { label: 'Lineup Strategy' }, { label: 'League Scoring' } , { label: 'Payment' }, { label: 'Invite Friends' }];
    }
    const [activeStep, setActiveStep] = useState(0);  
    const [steps, setSteps] = useState(gameCreateSteps);  
	const [show4, setShow4] = useState(true);
    
    const contest_id = props.contest_id; 
    let UserSiteGameData = props.UserSiteGameData;
    const editAction = props.editAction ? props.editAction : false;
	
    const changeActiveStepValue = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    const gotopreviousPage = (step) => {
        if(activeStep > 0) {
           setActiveStep(step);
        }
    }
   
    const sendInviteSuccess = () => {
        props.handleShowLeagueSuccess();
        setShow4(false);
    }

    const showLeagueTypeName = (type, weeklyleaders, seasonleaders) => {
        props.showLeagueTypeName(type, weeklyleaders, seasonleaders);
    }

    const showLeagueDuration = (weekFrom, WeekEnd) => {
        props.showLeagueDuration(weekFrom, WeekEnd);
    }
    const setLeagueCategory = (categoryType, lineUpNumber) => {
        props.setLeagueCategory(categoryType, lineUpNumber);
    }
    const setEntriesData = (maxEntry) => {
        props.setEntriesData(maxEntry);
    }

    const setLineUpStrategy = (type, positionData) => {
        props.setLineUpStrategy(type, positionData);
    }

    const updateLeagueContestId = (lg_contest_id) => {
        props.updateLeagueContestId(lg_contest_id);
    }
    
    const setScoringRuleType = (socringrule) => {
        props.setScoringRuleType(socringrule);
    }

    const updateUserLeaguename = (leaguename) => {
        props.updateUserLeaguename(leaguename);
    }

    const updateFullUserGameData = (payLoad, step) => {
        props.updateFullUserGameData(payLoad, step);
    }

    const resetSteps = () => {
        setActiveStep(0);
    }

    const setPaymentDone = (data) => {
        setShowPaymentStep(data);
        setActiveStep(3);
    }
  // console.log(showPaymentStep) 
if(showPaymentStep) {
    switch(activeStep){
        case 0:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step label="League Details" />
                        <Step label="Lineup Strategy" />
                        <Step label="League Scoring" />
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep1 changeActiveStepValue = {changeActiveStepValue} showLeagueTypeName = {showLeagueTypeName} showLeagueDuration = {showLeagueDuration} setLeagueCategory = {setLeagueCategory} setEntriesData = {setEntriesData} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} updateUserLeaguename = {updateUserLeaguename} editAction = {editAction} resetSteps = {resetSteps} isJoinedOtherUsers = {isJoinedOtherUsers} totalJoinedCount ={totalJoinedCount} showPaymentStep = {true}/>
                    </div>
                </>
            );
        case 1:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step label="Lineup Strategy" />
                        <Step  label="League Scoring" />
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep2 changeActiveStepValue = {changeActiveStepValue} setLineUpStrategy = {setLineUpStrategy} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step onClick={(e) => gotopreviousPage(1)} label="Lineup Strategy" />
                        <Step label="League Scoring" />
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep3 changeActiveStepValue = {changeActiveStepValue}  updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} setScoringRuleType = {setScoringRuleType} gotopreviousPage = {gotopreviousPage} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper" style={{ display: show4 ? "block" : "none" }} >
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step onClick={(e) => gotopreviousPage(1)} label="Lineup Strategy" />
                        <Step onClick={(e) => gotopreviousPage(2)} label="League Scoring" />
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep4 sendInviteSuccess = {sendInviteSuccess} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );

    }
} else {
    switch(activeStep){
        case 0:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step label="League Details" />
                        <Step label="Lineup Strategy" />
                        <Step label="League Scoring" />
                        {!props.paymentStatus == 1 && <Step label="Payment" /> }
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep1 changeActiveStepValue = {changeActiveStepValue} showLeagueTypeName = {showLeagueTypeName} showLeagueDuration = {showLeagueDuration} setLeagueCategory = {setLeagueCategory} setEntriesData = {setEntriesData} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} updateUserLeaguename = {updateUserLeaguename} editAction = {editAction} resetSteps = {resetSteps} isJoinedOtherUsers = {isJoinedOtherUsers} totalJoinedCount ={totalJoinedCount}/>
                    </div>
                </>
            );
        case 1:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step label="Lineup Strategy" />
                        <Step  label="League Scoring" />
                        {!props.paymentStatus == 1 && <Step label="Payment" /> }
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep2 changeActiveStepValue = {changeActiveStepValue} setLineUpStrategy = {setLineUpStrategy} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper">
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step onClick={(e) => gotopreviousPage(1)} label="Lineup Strategy" />
                        <Step label="League Scoring" />
                        {!props.paymentStatus == 1 && <Step label="Payment" /> }
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep3 changeActiveStepValue = {changeActiveStepValue}  updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} setScoringRuleType = {setScoringRuleType} gotopreviousPage = {gotopreviousPage} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper" style={{ display: show4 ? "block" : "none" }} >
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step onClick={(e) => gotopreviousPage(1)} label="Lineup Strategy" />
                        <Step onClick={(e) => gotopreviousPage(2)} label="League Scoring" />
                        {!props.paymentStatus == 1 && <Step label="Payment" /> }
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStepPayment changeActiveStepValue = {changeActiveStepValue} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} setPaymentDone = {setPaymentDone} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );

        case 4:
            return (
                <>
                    <div className="create-step-container" >
                        <div className="stepper" style={{ display: show4 ? "block" : "none" }} >
                        <Stepper
                            activeStep={activeStep} 
                        >
                        <Step onClick={(e) => gotopreviousPage(0)} label="League Details" />
                        <Step onClick={(e) => gotopreviousPage(1)} label="Lineup Strategy" />
                        <Step onClick={(e) => gotopreviousPage(2)} label="League Scoring" />
                        <Step label="Payment" />
                        <Step label="Invite Friends" />
                        </Stepper>
                        </div>
                        <CreateLeagueStep4 sendInviteSuccess = {sendInviteSuccess} updateLeagueContestId = {updateLeagueContestId} contest_id = {contest_id} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {editAction} isJoinedOtherUsers = {isJoinedOtherUsers}/>
                    </div>
                </>
            );

    }
}

};

export default CreateLeagueStepper;