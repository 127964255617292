import React, { useState, useEffect } from "react";
import { Button, Form , Modal} from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import { pageURLs } from "../appData/pageURLs";
import contesticon from '../assets/contest-star.svg';
import "../styles/createleague.scss"
import CreateLeagueStepper from "./CreateLeagueStepper";
import { useNavigate } from "react-router-dom";
import * as userGamesController from "../controller/userGamesController";
import * as contestService from "../services/contestService";
import { useParams } from 'react-router-dom'

const EditLeague = (props) => {
    const [showLeagueSuccess, setShowLeagueSuccess] = useState(false);
    const handleShowLeagueSuccess = () => setShowLeagueSuccess(true);
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [leagueTypeName, setLeagueTypeName] = useState('-');
    const [leagueWeekFrom, setLeagueWeekFrom] = useState(0);
    const [leagueWeekTo, setLeagueWeekTo] = useState(0);
    const [leagueType, setLeagueType] = useState('');
    const [entryType, setEntryType] = useState('S');
    const [weeklyLeadersCount, setWeeklyLeadersCount] = useState(0);
    const [seasonLeadersCount, setSeasonLeadersCount] = useState(0);
    const [lineUpNumber, setLineUpNumber] = useState(0);
    const [showDeleteLeagueOption, setDeleteLeagueOption] = useState(false);
    const [maxEntries, setMaxEntries] = useState(0);
    const [joinedCount, setJoinedCount] = useState(0);
    const [leagueStrategy, setLeagueStrategy] = useState('--');
    const [qrPositionPlayersCount, setQRPositionPlayersCount] = useState('--');
    const [rbPositionPlayersCount, setRBPositionPlayersCount] = useState('--');
    const [wrPositionPlayersCount, setWRPositionPlayersCount] = useState('--');
    const [flexPositionPlayersCount, setFLEXPositionPlayersCount] = useState('--');
    const [kPositionPlayersCount, setKPositionPlayersCount] = useState('--');
    const [defPositionPlayersCount, setDEFPositionPlayersCount] = useState('--');
    const [tePositionPlayersCount, setTEPositionPlayersCount] = useState('--');
    const [leagueContestId, setContestId] = useState(0);
    const [leagueScoringType, setLeagueScoringType] = useState('--');
    const [userleagueName, setUserleagueName] = useState("");
    const [UserSiteGameData, setUserSiteGameData] = useState([]);
    const [showLeagueDeleteConfirmModal, setShowLeagueDeleteConfirmModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [publishedStatus, setPublishedStatus] = useState('Unpublished');
    const [paymentStatus, setPaymentStatus] = useState(0);
    const deleteConfirmModalClose = () => setShowLeagueDeleteConfirmModal(false);
    const [isJoinedOtherUsers, setisJoinedOtherUsers] = useState(false);
    const [totalJoinedCount, setTotalJoinedCount] = useState(0);

    let { id } = useParams()
    id = parseInt(id);

    useEffect(() => {
       
        contestService.getDetailsForEdit(id)
            .then(response => {
                if(response.errorCode == 200) {
                    if(response.data.contestDetails.contest_status_id == 2 || response.data.contestDetails.contest_status_id == 4 || response.data.contestDetails.contest_status_id == 5 || response.data.contestDetails.contest_status_id == 7) {
                        navigate(pageURLs.manageleague);
                        
                    } else {
                       // console.log(response.data.contestDetails.total_joined)
                        if(response.data.contestDetails.total_lineups > 1 || response.data.isPlayersAdded) {
                            setisJoinedOtherUsers(true)
                        }
                        setTotalJoinedCount(response.data.contestDetails.total_lineups)
                        setInitalData(response.data.contestDetails, response.data.scoringRulesDetails)
                    }
                } else {
                    navigate(pageURLs.manageleague)
                }
                
                
            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);



    const getSideMenuClasses = () => {
        let result = "koblock-right league-details";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const handleDeleteLeauge = () => {
        setShowLeagueDeleteConfirmModal(true)
    };
    const navigate = useNavigate();
    const removeLeague = async() => {
        if(parseInt(leagueContestId)) {
            setShowLoader(true)
            let result = await userGamesController.removeUserGames(leagueContestId);
            if(result.errorCode == 200) {
                navigate(pageURLs.manageleague)
            } else {
                setShowLoader(false);
                alert(result.message)
            }
        }
    }

    const setInitalData = (fullFormData, scoringRulesDetails) => {
        let formExistingFormArray = [];
        formExistingFormArray[0] = {};
        formExistingFormArray[1] = {};
        formExistingFormArray[2] = {};

        formExistingFormArray[0].contest_name = fullFormData.contest_name;
        formExistingFormArray[0].contest_type = fullFormData.contest_type_id == 1 ? 'S' : 'K';
        formExistingFormArray[0].contest_start_week = fullFormData.contest_start_week;
        formExistingFormArray[0].contest_end_week = fullFormData.contest_end_week;
        formExistingFormArray[0].min_users = fullFormData.min_users;
        formExistingFormArray[0].max_users = fullFormData.max_users;
        formExistingFormArray[0].total_weekly_winners = fullFormData.total_weekly_winners;
        formExistingFormArray[0].total_season_winners = fullFormData.total_season_winners;
        formExistingFormArray[0].max_lineup_per_user = fullFormData.max_lineup_per_user;
        formExistingFormArray[0].lup_entrylevel_category = (fullFormData.lup_entrylevel_category === 'M' ) ? fullFormData.lup_entrylevel_category : 'S';
        let lineup_plan_contest_id = fullFormData.lineup_plan_contest_id ? fullFormData.lineup_plan_contest_id : 0;
        let lineup_score_rule_contest_id = fullFormData.lineup_plan_contest_id ? fullFormData.lineup_score_rule_contest_id : 0;
        let isPublished = (fullFormData.contest_status_id != 8 ) ? setPublishedStatus('Published') : false;
        let paid_status = (fullFormData.transaction_status_id == 1 ) ? 1 : 0;
        setPaymentStatus(paid_status);
        
        if(lineup_plan_contest_id) {
            formExistingFormArray[1].is_custom_position = fullFormData.is_custom_position;
            let total_qb = (fullFormData.total_qb) ? fullFormData.total_qb : 0;
            let total_rb = (fullFormData.total_rb) ? fullFormData.total_rb : 0;
            let total_wr = (fullFormData.total_wr) ? fullFormData.total_wr : 0;
            let total_te = (fullFormData.total_te) ? fullFormData.total_te : 0;
            let total_fl = (fullFormData.total_fl) ? fullFormData.total_fl : 0;
            let total_ki = (fullFormData.total_ki) ? fullFormData.total_ki : 0;
            let total_df = (fullFormData.total_df) ? fullFormData.total_df : 0;

            var selectedPositionArray = [];
            var default_non_selected_items = [];
            let quarterbackrow = {title: 'Quarterback', key : 'quarterback', value : 0};
            let runningbackrow = {title: 'Running Back', key : 'runningback', value : 0};
            let widereceiverrow = {title: 'Wide Receivers', key : 'widereceiver', value : 0};
            let tightendrow = {title: 'Tight End', key : 'tightend', value : 0};
            let flexrow = {title: 'Flex (RB/WR/TE)', key : 'flex', value : 0};
            let kickerrow = {title: 'Kicker', key : 'kicker', value : 0};
            let defenserow = {title: 'Defense/Special', key : 'defense', value : 0};
            if(total_qb > 0) {
                quarterbackrow.value = total_qb;
                selectedPositionArray.push(quarterbackrow)
            } else {
                default_non_selected_items.push(quarterbackrow) 
            }

            if(total_rb > 0) {
                runningbackrow.value = total_rb;
                selectedPositionArray.push(runningbackrow)
            } else {
                default_non_selected_items.push(runningbackrow) 
            }

            if(total_wr > 0) {
                widereceiverrow.value = total_wr;
                selectedPositionArray.push(widereceiverrow)
            } else {
                default_non_selected_items.push(widereceiverrow) 
            }

            if(total_te > 0) {
                tightendrow.value = total_te;
                selectedPositionArray.push(tightendrow)
            } else {
                default_non_selected_items.push(tightendrow) 
            }

            if(total_fl > 0) {
                flexrow.value = total_fl;
                selectedPositionArray.push(flexrow)
            } else {
                default_non_selected_items.push(flexrow) 
            }

            if(total_ki > 0) {
                kickerrow.value = total_ki;
                selectedPositionArray.push(kickerrow)
            } else {
                default_non_selected_items.push(kickerrow) 
            }

            if(total_df > 0) {
                defenserow.value = total_df;
                selectedPositionArray.push(defenserow)
            } else {
                default_non_selected_items.push(defenserow) 
            }

            formExistingFormArray[1].selectedPositionArray = selectedPositionArray;
            formExistingFormArray[1].NonSelectedPositionArray = default_non_selected_items;
            let formatType = (fullFormData.is_custom_position) ? 'customposition' : 'standardposition'
            setLineUpStrategy(formatType, selectedPositionArray);
        }

        if(lineup_score_rule_contest_id) {
            formExistingFormArray[2].is_custom_score_rule = fullFormData.is_custom_score_rule;
            formExistingFormArray[2].off_yard_pass_pts = fullFormData.off_yard_pass_pts;
            formExistingFormArray[2].off_yard_receive_pts = fullFormData.off_yard_receive_pts;
            formExistingFormArray[2].off_td_pass_pts = fullFormData.off_td_pass_pts;
            formExistingFormArray[2].off_td_all_other_pts = fullFormData.off_td_all_other_pts;
            formExistingFormArray[2].off_td_all_turnover_pts = fullFormData.off_td_all_turnover_pts;
            formExistingFormArray[2].off_td_reception_pts = fullFormData.off_td_reception_pts;
            formExistingFormArray[2].off_td_2pt_conv_pts = fullFormData.off_td_2pt_conv_pts;
            formExistingFormArray[2].def_td_all_pts = fullFormData.def_td_all_pts;
            formExistingFormArray[2].def_td_all_turnover_pts = fullFormData.def_td_all_turnover_pts;
            formExistingFormArray[2].def_sack_pts = fullFormData.def_sack_pts;
            formExistingFormArray[2].def_safety_pts = fullFormData.def_safety_pts;
            formExistingFormArray[2].def_1pt_safety_pts = fullFormData.def_1pt_safety_pts;
            formExistingFormArray[2].kic_extra_pts = fullFormData.kic_extra_pts;
            formExistingFormArray[2].is_points_against_on = fullFormData.is_points_against_on;
            formExistingFormArray[2].is_yards_against_on = fullFormData.is_yards_against_on;
            formExistingFormArray[2].is_field_goal_on = (fullFormData.is_field_goal_on) ? true : false;

            let scoringRule = (fullFormData.is_custom_score_rule) ? 'customscorerule' : 'standardscorerule'
            setScoringRuleType(scoringRule);
        }

        if(scoringRulesDetails.length) {
            let pointAgainstPointArray = [];
            let yardAgainstPointArray = [];
            let kickerFieldGoalPointArray = [];
            let pointAgainstIndex = 0;
            let yardAgainstIndex = 0;
            let kickerFieldIndex = 0;
            for(let lp =0 ; lp < scoringRulesDetails.length ; lp++) {
                if(scoringRulesDetails[lp].defense_point_type_id == 1) {
                    let pointAgainstRow = {index : pointAgainstIndex, minValue : scoringRulesDetails[lp].min_value, maxValue : scoringRulesDetails[lp].max_value, pointValue : scoringRulesDetails[lp].points_assigned , maximumOptions : []};
                    pointAgainstPointArray.push(pointAgainstRow);
                    pointAgainstIndex++;
                } else if(scoringRulesDetails[lp].defense_point_type_id == 2) {
                    let yardAgainstRow = {index : yardAgainstIndex, minValue : scoringRulesDetails[lp].min_value, maxValue : scoringRulesDetails[lp].max_value, pointValue : scoringRulesDetails[lp].points_assigned , maximumOptions : []};
                    yardAgainstPointArray.push(yardAgainstRow);
                    yardAgainstIndex++;
                } else if(scoringRulesDetails[lp].defense_point_type_id == 3) {
                    let kickerFieldRow = {index : kickerFieldIndex, minValue : scoringRulesDetails[lp].min_value, maxValue : scoringRulesDetails[lp].max_value, pointValue : scoringRulesDetails[lp].points_assigned , maximumOptions : []};
                    kickerFieldGoalPointArray.push(kickerFieldRow);
                    kickerFieldIndex++;
                }
            }

            formExistingFormArray[2].pointAgainstPointArray = pointAgainstPointArray;
            formExistingFormArray[2].yardAgainstPointArray = yardAgainstPointArray;
            formExistingFormArray[2].kickerFieldGoalPointArray = kickerFieldGoalPointArray;
        }
        
      
        setContestId(fullFormData.lg_contest_id);
        setUserSiteGameData(formExistingFormArray);
        showLeagueTypeName(formExistingFormArray[0].contest_type, formExistingFormArray[0].total_weekly_winners , formExistingFormArray[0].total_season_winners);
        showLeagueDuration(formExistingFormArray[0].contest_start_week, formExistingFormArray[0].contest_end_week);
        setLeagueCategory(formExistingFormArray[0].lup_entrylevel_category, formExistingFormArray[0].max_lineup_per_user);
        setEntriesData(formExistingFormArray[0].max_users, fullFormData.total_joined);
        
        setDeleteLeagueOption(true);

    }

    const showLeagueTypeName = (type, weeklyleaders, seasonleaders) => {
        let leagueTypeName = '-';
        if (type == 'K') {
            leagueTypeName = 'Knockout Plus';
        } else if (type == 'S') {
            leagueTypeName = 'Standard Knockout';
        }
        setLeagueTypeName(leagueTypeName);
        setLeagueType(type);
        setWeeklyLeadersCount(weeklyleaders);
        setSeasonLeadersCount(seasonleaders);
    }

    const showLeagueDuration = (weekFrom, WeekEnd) => {
        setLeagueWeekFrom(weekFrom);
        setLeagueWeekTo(WeekEnd);
    }

    const leadersPreviewDivClass = () => {
        let divClassName = 'muliple-info';
        if (leagueType != 'K') {
            divClassName = 'hide';
        }
        return divClassName;
    }

    const setLeagueCategory = (categoryType, lineUpNumberVal) => {
        setLineUpNumber(lineUpNumberVal);
        setEntryType(categoryType);
    }

    const setEntriesData = (maxEntry, joinedUsersCount = 0) => {
        setMaxEntries(maxEntry);
        setJoinedCount(joinedUsersCount);
    }

    const setLineUpStrategy = (type, positionData) => {
        let strategyTypeName = '-';
        if (type == 'standardposition') {
            strategyTypeName = 'Standard Positions';
        } else {
            strategyTypeName = 'Custom Positions';
        }
        setLeagueStrategy(strategyTypeName);
        setQRPositionPlayersCount(0);
        setRBPositionPlayersCount(0);
        setWRPositionPlayersCount(0);
        setTEPositionPlayersCount(0);
        setFLEXPositionPlayersCount(0);
        setKPositionPlayersCount(0);
        setDEFPositionPlayersCount(0);

        positionData.map((item, index) => {
            switch (item.key) {
                case 'quarterback':
                    setQRPositionPlayersCount(item.value);
                    break;
                case 'runningback':
                    setRBPositionPlayersCount(item.value);
                    break;
                case 'widereceiver':
                    setWRPositionPlayersCount(item.value);
                    break;
                case 'tightend':
                    setTEPositionPlayersCount(item.value);
                    break;
                case 'flex':
                    setFLEXPositionPlayersCount(item.value);
                    break;
                case 'kicker':
                    setKPositionPlayersCount(item.value);
                    break;
                case 'defense':
                    setDEFPositionPlayersCount(item.value);
                    break;
            }
        });
    }

    const updateLeagueContestId = (contest_id) => {
        setContestId(contest_id);
        if (parseInt(contest_id)) {
            setDeleteLeagueOption(true);
        } else {
            setDeleteLeagueOption(false);
        }
    }

    const setScoringRuleType = (rule_type) => {
        let scoringTypeName = '--';
        if (rule_type == 'standardscorerule') {
            scoringTypeName = 'Standard Scoring Rules';
        } else {
            scoringTypeName = 'Custom Scoring Rules';
        }
        setLeagueScoringType(scoringTypeName);
    }

    const updateUserLeaguename = (leaguename) => {
        setUserleagueName(leaguename);
    }

    const updateFullUserGameData = (fullFormData, step) =>{
        let formExistingFormArray = [...UserSiteGameData];
        formExistingFormArray[step] = fullFormData;
        if(step == 3) {
            setUserSiteGameData([]);
            setContestId(0)
        } else {
            setUserSiteGameData(formExistingFormArray);
        }
    }
    
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.editleague} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft create-league">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        {showLoader && <div className="position-select-field"><div className="loader-wrap"><div className="loader"></div></div></div>}
                       {UserSiteGameData.length > 0 && <CreateLeagueStepper handleShowLeagueSuccess={handleShowLeagueSuccess} showLeagueTypeName={showLeagueTypeName} showLeagueDuration={showLeagueDuration} setLeagueCategory={setLeagueCategory} setEntriesData={setEntriesData} setLineUpStrategy={setLineUpStrategy} updateLeagueContestId={updateLeagueContestId} contest_id={leagueContestId} setScoringRuleType={setScoringRuleType} userleagueName={userleagueName} updateUserLeaguename={updateUserLeaguename} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} editAction = {true} paymentStatus = {paymentStatus} isJoinedOtherUsers = {isJoinedOtherUsers} totalJoinedCount = {totalJoinedCount}/> }
                        <div className="create-success" style={{ display: showLeagueSuccess ? "block" : "none" }}>
                            <div className="contest-success">
                                <div className="contest-icon"><img src={contesticon} alt="KO" /></div>
                                <p>User contest</p>
                                <h2>{userleagueName}</h2>
                                <p>has been updated and your invites have been sent</p>
                                <Button variant="primary" onClick={() => { navigate(pageURLs.manageleague); }}>Manage League</Button>
                            </div>
                        </div>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        {showDeleteLeagueOption && <div className="btn-sec"><Button variant="light" className='btn-delete' onClick={handleDeleteLeauge}>  Delete League</Button></div>}
                        <div className="content-block-rht">
                            <div className="detail-info-data">
                                <h6>Preview of the League</h6>
                                <p>Status: {publishedStatus}</p>
                            </div>
                            <div className="detail-info-data">
                                <label>League Type</label>
                                <h6>{leagueTypeName}</h6>
                            </div>
                            <div className={leadersPreviewDivClass()}>
                                <div className="detail-info-data">
                                    <label>Weekly Leaders</label>
                                    <h3>{weeklyLeadersCount}</h3>
                                </div>
                                <div className="detail-info-data">
                                    <label>Season Sc. Leaders</label>
                                    <h3>{seasonLeadersCount}</h3>
                                </div>
                            </div>
                            <div className="muliple-info">
                                <div className="detail-info-data">
                                    <label>League Duration</label>
                                    <h6>Week {leagueWeekFrom} - Week {leagueWeekTo}</h6>
                                </div>
                                <div className="detail-info-data">
                                    <label>Entries</label>
                                    <h6>{joinedCount}/{maxEntries}</h6>
                                </div>
                            </div>
                            <div className="detail-info-data">
                                <label>League Category</label>
                                <h6><span className="badge bg-m">{entryType}</span>{entryType == 'M' && ` (up to ${lineUpNumber} Lineups)`}</h6>
                            </div>
                            <div className="detail-info-data">
                                <label>Format</label>
                                <h6>{leagueStrategy}</h6>
                                <div className="game-formation">
                                    {qrPositionPlayersCount > 0 && <div className="game-position"><p><span>{qrPositionPlayersCount}</span>QB</p></div>}
                                    {rbPositionPlayersCount > 0 && <div className="game-position"><p><span>{rbPositionPlayersCount}</span>RB</p></div>}
                                    {wrPositionPlayersCount > 0 && <div className="game-position"><p><span>{wrPositionPlayersCount}</span>WR</p></div>}
                                    {tePositionPlayersCount > 0 && <div className="game-position"><p><span>{tePositionPlayersCount}</span>TE</p></div>}
                                    {flexPositionPlayersCount > 0 && <div className="game-position"><p><span>{flexPositionPlayersCount}</span>FLEX</p></div>}
                                    {kPositionPlayersCount > 0 && <div className="game-position"><p><span>{kPositionPlayersCount}</span>K</p></div>}
                                    {defPositionPlayersCount > 0 && <div className="game-position"><p><span>{defPositionPlayersCount}</span>DST</p></div>}
                                </div>
                            </div>
                            <div className="detail-info-data">
                                <label>League Scoring</label>
                                <h6>{leagueScoringType}</h6>
                            </div>
                        </div>
                    </div>

                    <Modal show={showLeagueDeleteConfirmModal} onHide={deleteConfirmModalClose} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Do you want to delete this league?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={deleteConfirmModalClose}>Cancel</Button>
                        <Button variant="primary" onClick={() => removeLeague()} >Yes</Button>
                    </Modal.Footer>
                </Modal>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default EditLeague;
