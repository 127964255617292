import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import { store } from '../appState/store';
import * as userController from "../controller/userController"
import useUserState from "../appState/userState/useUserState";

const CheckAddressVerificationStatus = (props) => {
    const navigate = useNavigate();
    const userState = useUserState();
    const userDetails = store.getState().user;
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    let email = '';

    useEffect(async () => {
        await userController.checkUserProfileStausTsevo(userState.data.user.email)
            .then(async function (response) {
                sessionStorage.removeItem('duplicateentry');
                if(response.data && response.data.verified == 1) {
                    navigate(pageURLs.lobby);
                } else {
                    if(response.data && response.data.duplicateEntry == 1) {
                        sessionStorage.setItem('duplicateentry',1);
                    } else {
                        sessionStorage.removeItem('duplicateentry');
                    }
                    sessionStorage.setItem('tsevoFailureMessage',response.data.tsevoMessage);
                    navigate(pageURLs.verificationfailed); 
                }
            })
    })

    return (
        <>
            <InnerPageWrap>
                <div className="koinner-sec">
                    <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.payment} /></div>
                    <div className="kodata-content">
                        <div className="koblock-left content-block-lft verification-container">
                            <div className="sidebar-toggler">
                                <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                    <ChevrontoggleIcon />
                                </Button>
                            </div>

                            <h5>Let's Get Started!</h5>
                            <div className="form-block">
                                <div className="loader-wrap"><div className="loader"></div></div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </InnerPageWrap>
        </>
    );

};

export default CheckAddressVerificationStatus;
