import * as downloadService from "./downloadService";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function getSiteContests(options) {    
    
    return await apiService.apiCall(endPoints.LOBBY_LIST_CONTESTS_API_URL_V2, 'POST',options);     
}

export async function getSiteContest(contestId) {
    let options = {        
        "contest_id" : contestId
    };
    return await apiService.apiCall(endPoints.CONTEST_DETAILS_API_URL, 'POST',options);
}

export async function getUsergameContest(contestId) {
    let options = { };
    return await apiService.apiCall(endPoints.USER_GAME_CONTEST_DETAILS_API_URL+"/"+contestId, 'GET', options);
}

export async function getUserContests(options) {    
    return await apiService.apiCall(endPoints.LOBBY_LIST_CONTESTS_API_URL_V2, 'POST', options);
   
}

export async function getUserContest(userId, contestId) {    
    let selectedItem = downloadService.successResponse();
    let response = getUserContests(userId);
    if (response.success) {
        selectedItem.data = response.data.contestList.filter(function(item) {  
            return item.Id === contestId;
        })[0];
    }
    return selectedItem;
}

export async function getSitePlayerList(sessionYear, searchData, positionId, page, lineup_week = null, contestId = 0, isUserGame = false, lineup_number =1, sortBy = 'name') {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_PLAYERS_LIST, 'POST',{
        nfl_season:sessionYear,
        search_key:searchData,
        player_card_id:positionId,
        page : page,
        week : lineup_week,
        si_contest_id : contestId,
        isUserGame : isUserGame,
        lineup_number : lineup_number,
        sortBy : sortBy
    });
    
}

export async function getSeasonStandingsResponse(params, isUserLeage) {
    return await apiService.apiCall(endPoints.SEASON_STANDINGS_RESPONSE +'/'+isUserLeage+'/seasonStandings' , 'POST', params);    
}

export async function getSeasonLeaderboardService(params, isUserLegue) {
    return await apiService.apiCall(endPoints.SEASON_LEADERBOARD +'/'+isUserLegue+'/seasonLeaderboard'  , 'POST', params);    
}

export async function getSeasonStatsResponse(params,staus_user_id = 0) {
    return await apiService.apiCall(endPoints.GET_SEASON_STATS, 'POST',params,staus_user_id);
}

export async function getSiteLineUpPlayerListByPosition(year, position, searchData, positionId, page, lineup_week = null , contestId = 0, isUserGame = false, lineup_number = 1, sortBy = '') {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_GET_PLAYERS_POSITION, 'POST',{
        nfl_season:year,
        player_position:position,
        search_key:searchData,
        player_card_id:positionId,
        page : page,
        week : lineup_week,
        si_contest_id : contestId,
        isUserGame : isUserGame,
        lineup_number : lineup_number,
        sortBy : sortBy
    });
    
}

export async function getWinnersResponse(params) {
    return await apiService.apiCall(endPoints.WINNERS_RESPONSE +'/'+params.isUserGame+'/'+params.contestid, 'GET',{ });    
}

export async function postAddLineUps(tiebreakerScore, contestId, year, lineUpWeek) {
    
    return await apiService.apiCall(endPoints.JOIN_CONTEST_ADD_LINEUPS, 'POST',{
         predicted_tie_score:tiebreakerScore,
         si_contest_id:contestId,         
         nfl_season: year,
         lineup_week:lineUpWeek

    });
}

export async function postTieBreakerScore(tiebreakerScore, joinContestId, year, lineUpWk) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_ADD_TIEBREAKER_SCORE, 'POST',{
        predicted_tie_score:tiebreakerScore,
        si_contest_id:joinContestId,        
        nfl_season:year,
        lineup_week:lineUpWk
    });
}    

export async function JoinUserContest(predictedTieScore, joinContestId, year, lineUpWk) {
    return await apiService.apiCall(endPoints.JOIN_USER_CONTEST, 'POST',{
        predicted_tie_score:predictedTieScore,
        lg_contest_id:joinContestId,        
        nfl_season:year,
        lineup_week:lineUpWk
    });    
}

export async function getJoinContestById(contestId) {
    return await apiService.apiCall(endPoints.CONTEST_DETAILS_API_URL, 'POST',{    
        contest_id:contestId,
    });
}

export async function removeLineup(contestId, lineUpNumber, lineUpWeek) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_REMOVE_LINEUP, 'POST',{
        lineup_number:lineUpNumber,
        lineup_week:lineUpWeek,
        si_contest_id:contestId,        
    });
}

export async function postLineUpPositionPlayerCardView(teamId, lineUpNumber, contestId, playerPosition, playerId, lineUpWeek, isteam, placingPosition, year, sortBy = '') {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_ADD_LINEUP_CARDPLAYER, 'POST',{
        team_id:teamId,
        lineup_number:lineUpNumber,
        player_id:playerId,
        player_position:playerPosition,        
        si_contest_id:contestId,
        lineup_week:lineUpWeek,
        is_team:isteam,
        player_card_id:placingPosition,
        nfl_season:year,
        sortBy : sortBy
    });
    
}

export async function getAllPlayersList(contestId, lineUpNumber, lineUpWk) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_GET_LINEUP_PLAYERS_LIST, 'POST',{
        lineup_number:lineUpNumber,        
        si_contest_id:contestId,
        lineup_week:lineUpWk
    });
    
}


export async function lineUpPlayerListById(playerId) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_GET_PLAYERS_BY_ID, 'POST',{
        player_id:playerId
    });
}

export async function lineUpTeamPlayerList(sessionYear, searchData, id, isUserGame,contest_id, lineup_week = null, lineup_number, sortBy = 'name') {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_GET_LINEUP_TEAMLISTING+'/'+isUserGame, 'POST',{
        season:sessionYear,
        search_key:searchData,
        player_card_id:id,
        si_contest_id :contest_id,
        lineup_number : lineup_number,
        week : lineup_week,
        sortBy : sortBy
    });
}

export async function getUserContestSummary(userId) {
    let options = {
    };
    return await apiService.apiCall(endPoints.GET_USER_CONTEST_SUMMARY_API_URL, 'POST',options);
}

export async function getWeeklyLineUps(si_contest_id,lineup_week) {
    return await apiService.apiCall(endPoints.GET_USER_CONTEST_LINEUP_NUMBER_API_URL, 'POST',{        
        si_contest_id:si_contest_id,
        lineup_week:lineup_week
    });
}

export async function removeLineupPlayers(payLoad) {
    return await apiService.apiCall(endPoints.REMOVE_LINE_UP_PLAYERS, 'POST',payLoad);
}


export async function StandardLineUpPlan() {
    return await apiService.apiCall(endPoints.FETCH_STANDARD_LINEUP_PLAN, 'GET');
}
export async function getStandardScoringRules() {
    return await apiService.apiCall(endPoints.FETCH_STANDARD_SCORING_RULES, 'GET');
}
export async function postAddLineUpsDetailsForUsergame(contestId, year, predictedTieScore, lineUpWeek) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_ADD_LINEUPS_FOR_USERGAME, 'POST',{        
         lg_contest_id:contestId,         
         nfl_season: year,
         predicted_tie_score:predictedTieScore,
         lineup_week:lineUpWeek

    });
}

export async function removeUsergameLineup(contestId, lineUpNumber, lineUpWeek) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_REMOVE_LINEUP_FOR_USERGAME, 'POST',{        
        lg_contest_id:contestId,
        lineup_number:lineUpNumber,
        lineup_week:lineUpWeek
        
    });
}

export async function getAllPlayersListForUsergame(lineUpNumber, contestId, lineUpWk) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_GET_LINEUP_PLAYERS_LIST_FOR_USERGAME, 'POST',{        
        lineup_number:lineUpNumber, 
        lg_contest_id:contestId,       
        lineup_week:lineUpWk
    });
    
}

export async function saveUsergameLineUpPositionPlayerCardView(teamId, playerId, lineUpNumber, playerPosition, contestId, lineUpWeek, isteam, playerCardId, year) {
    return await apiService.apiCall(endPoints.JOIN_CONTEST_ADD_LINEUP_CARDPLAYER_FOR_USERGAME, 'POST',{
        team_id:teamId,        
        player_id:playerId,
        lineup_number:lineUpNumber,
        player_position:playerPosition,        
        lg_contest_id:contestId,
        lineup_week:lineUpWeek,
        is_team:isteam,
        player_card_id:playerCardId,
        nfl_season:year
    });    
}

export async function removeUsergameLineupPlayers(payLoad) {
    return await apiService.apiCall(endPoints.REMOVE_USERGAME_LINE_UP_PLAYERS, 'POST',payLoad);
}

export async function getWeeklyLineUpsForUsergame(lg_contest_id, lineup_week) {
    return await apiService.apiCall(endPoints.GET_LINEUP_NUMBER_FOR_USERGAME_API_URL, 'POST',{        
        lg_contest_id:lg_contest_id,
        lineup_week:lineup_week
    });
}
export async function leaveTheContest(payLoad, isUserGame = false) {
    let apiUrl = endPoints.USER_LEAVE_CONTEST_API_URL;
    if(isUserGame === true) {
        apiUrl = endPoints.USER_LEAGUE_LEAVE_API_URL;
    }
    return await apiService.apiCall(apiUrl, 'POST',payLoad);

}
export async function getWeekDuration() {
    return await apiService.apiCall(endPoints.FETCH_WEEK_DURATION, 'GET');
}


export async function getAllWeeklyContestantStanding(contestId, lineUpWeek, isUserLeage) {
    
     return await apiService.apiCall(endPoints.GET_ALL_WEEKLY_CONTESTANTS_STANDING, 'POST', {

        si_contest_id: contestId,
        lineup_week: lineUpWeek,
        isUserGame:isUserLeage
    });
}

export async function getAllSeasonContestantStanding(payLoad) {
    return await apiService.apiCall(endPoints.GET_ALL_SEASON_CONTESTANTS_STANDING, 'POST', payLoad);
}

export async function getWeeklyStandingsPlayerLineup(contestId, lineUpWeek,lineUpNumber,firstTimeLoaded, isUserLeage) {
    return await apiService.apiCall(endPoints.GET_WEEKLY_STANDINGS, 'POST', {
        si_contest_id: contestId,
        lineup_week: lineUpWeek,
        lineup_number:lineUpNumber,
        initialLoad: firstTimeLoaded,
        isUserGame: isUserLeage
     });
}
export async function getMyCreatedContests(options) { 
    return await apiService.apiCall(endPoints.MANAGE_LEAGUE_URL, 'GET',options);     
}

export async function getDetailsForEdit(lg_contest_id) { 
    return await apiService.apiCall(endPoints.LEAGUE_DETAILS_FOR_EDIT+"/"+lg_contest_id, 'GET', {});     
}

export async function getUsergameInviteeList(lg_contest_id,options = null) { 
    return await apiService.apiCall(endPoints.USER_GAME_INVITEE_LIST+"/"+lg_contest_id, 'GET', options);     
}

export async function getUsergameParticipantsList(lg_contest_id,options = null) { 
    return await apiService.apiCall(endPoints.USER_GAME_PARTICIPANTS_LIST+"/"+lg_contest_id, 'GET', options);     
}

export async function sendNotificationMessage(notificationParams) { 
    return await apiService.apiCall(endPoints.USER_GAME_SEND_MESSAGE, 'POST', notificationParams);     
}

export async function getCurrentTieBreakerPoints(lg_contest_id, contest_type = 1, options = null) { 
    let resultData = await apiService.apiCall(endPoints.TIEBREAKER_CHECK_URL+"/"+contest_type+"/"+lg_contest_id, 'GET', options);  
    let results = [];
    if(resultData.errorCode == 200) {
        for(let i= 0; i < resultData.data.result.length ; i++) {
            results.push(resultData.data.result[i].predicted_tie_score);
        }
    }
    
    return results;
}

export async function getWeeklyStandingsOtherUsersLineup(contestId, lineUpWeek,lineUpNumber, user_id, isUserGame = false) {
    return await apiService.apiCall(endPoints.USERS_LINEUP_API_URL, 'POST', {
        si_contest_id: contestId,
        lineup_week: lineUpWeek,
        lineup_number:lineUpNumber,
        isUserGame : isUserGame
       //user_id : user_id
    },user_id);
}

export async function getWeeklyPlayersStatus(contestId, lineUpWeek,lineUpNumber, userId = null, isUserLeage=false) {
    return await apiService.apiCall(endPoints.PLAYERS_STATUS_API_URL, 'GET', {
        si_contest_id: contestId,
        week: lineUpWeek,
        lineup_number:lineUpNumber,
        isUserGame:isUserLeage
    } , userId);
}

export async function fetchUserDetailsById(userIds) {
    let userIdSTring = userIds.join(',');
    return await apiService.apiCall(endPoints.FETCHUSERS_DETAILS_BY_IDS, 'GET', {
    } , userIdSTring);
}

export async function clearLineUp(payLoad) {
    return await apiService.apiCall(endPoints.CLEAR_LINEUP, 'POST',payLoad);
}

export async function copyLineUp(payLoad) {
    return await apiService.apiCall(endPoints.COPY_LINEUP, 'POST',payLoad);
}

export async function checkRefundStatus(payLoad) {
    return await apiService.apiCall(endPoints.CONTEST_REFUND_CHECK_API_URL, 'POST',payLoad);
}

export async function fetchWeeklyRankings(payLoad) {
    return await apiService.apiCall(endPoints.CONTEST_WEEKLY_RANK_API_URL, 'POST',payLoad);
}

export async function fetchWeeklyLineUp(payLoad, user_id) {
    return await apiService.apiCall(endPoints.CONTEST_WEEKLY_LINEUP_API_URL, 'POST',payLoad, user_id);
}

export async function getCurrentTieBreakerPointsInRange(contest_id, contest_type = 1, score = 0) { 
    let payload = {
        "contest_id" : contest_id,
        "contest_type" : contest_type,
        "tieScore" : score
    }
    let resultData = await apiService.apiCall(endPoints.FETCH_TIE_BREAKER_IN_RANGE, 'POST', payload);  
    let results = [];
    if(resultData.errorCode == 200) {
        for(let i= 0; i < resultData.data.result.length ; i++) {
            results.push(resultData.data.result[i].predicted_tie_score);
        }
    }
    return results;
}

export async function getSiteContestDetailsBeforeLogin(contestId) {
    let options = {        
        "contest_id" : contestId
    };
    return await apiService.apiCall(endPoints.CONTEST_DETAILS__BEFORE_LOGIN_API_URL, 'POST',options);
}

export async function saveSiteContestLineupPlayers(request) {
    return await apiService.apiCall(endPoints.SITE_CONTEST_LINEUP_PLAYERS_SAVE, 'POST',request);
}

export async function saveUserLeagueLineupPlayers(request) {
    return await apiService.apiCall(endPoints.USER_LEAGUE_LINEUP_PLAYERS_SAVE, 'POST',request);
}

export async function getPosters() {    
    return await apiService.apiCall(endPoints.POSTER_LIST_API, 'GET', {});
}

export async function getTopPlayers() {    
    return await apiService.apiCall(endPoints.TOP_PLAYER_LIST_API, 'GET', {});
}