import React, { useState, useEffect } from "react";
import { Button, ToastContainer, Toast, Tab, Tabs } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import LineupButtonGroup from "../components/LineupButtonGroup";
import LiveTimer from "../components/LiveTimer";
import TickIcon from "../assets/icons/tickicon";
import UserLineupSetup from "../components/UserLineupSetup";
import StandingsSetup from "../components/StandingsSetup";
import UserSeasonStats from "../components/UserSeasonStats";
import UserSeasonStandingLineupSetup from "../components/UserSeasonStandingLineupSetup";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import UserWeeklyStandings from "../components/UserWeeklyStandings";
import WinnersList from "../components/WinnersList";
import InnerPageWrap from "../components/InnerPageWrap";
import "../styles/createlineup.scss";
import { pageURLs } from "../appData/pageURLs";
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import * as contestService from "../services/contestService";
import { store } from '../appState/store';
import useUserState from "../appState/userState/useUserState";

import * as commonService from "../services/commonService";
import { useParams, useNavigate } from 'react-router-dom';
import UserSeasonStandings from "../components/UserSeasonStandings";
import UserSeasonStatsContestantStanding from "../components/UserSeasonStatsContestantStanding";
import UserRulesAndScoring from "../components/UserRulesAndScoring";
import EliminatedWeekly from "../components/EliminatedWeekly";

import * as apiService from "../services/apiService";
import moment from "moment"
import TopPlayers from "../components/TopPlayers";


const UsergameCreateLineup = (props) => {
    const user = useUserState();
    const userData = store.getState().user

    let { contestid } = useParams();
    // let { userType } = useParams();
    let { uId, tab } = useParams();

    let saveButtonShow;
    let savetoast = false;
    const siteState = useSiteState();
    const navigate = useNavigate();
    let joinContestAction = sessionStorage.getItem('contestJoinedAction');

    let default_join_toast_show = false;
    if (joinContestAction) {
        default_join_toast_show = true;
    }
    tab = (['details','standings']).includes(tab) ? tab : 'details'
    // for view leaders page checking... same page will be loaded for the create lineups start 
    const pagePathName = window.location.pathname;
    const isViewLeadersPage = (pagePathName.indexOf('viewleaders/') === 1) ? true : false;
    const [pageIsViewLeaders, setPageIsViewLeaders] = useState(isViewLeadersPage);
    const isStandingsPage = (pagePathName.indexOf('standings/') === 1) ? true : false;
    const [pageIsStandingsPage, setPageIsStandingsPage] = useState(isStandingsPage);
    const [hideDetailsAndChangeWinners, setHideDetailsAndChangeWinners] = useState(false);
    // for view leaders page checking... same page will be loaded for the create lineups end

    const [showjointoast, setShowJoinToast] = useState(default_join_toast_show);
    const [showlineuptoast, setShowLineupToast] = useState(false);
    const [showdebittoast, setShowDebitToast] = useState(false);
    const [showcredittoast, setShowCreditToast] = useState(false);
    const [showcsavetoast, setShowSaveToast] = useState(false);
    const [selectedTab, setSelectedTab] = useState(tab);
    const [showTieBreaker, setShowTieBreaker] = useState(false);
    const [contestData, setContestData] = useState(null);
    const { monthDate, time } = useDate();
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [flagLineUpNotice, setFlagLineUpNotice] = useState(true);
    const [maximumCardNumber, setMaximumCardNumber] = useState(10);
    const [lineupWeekNumber, setLineupWeekNumber] = useState(0);
    const [currentSeasonData, setCurrentSeasonData] = useState([]);
    const [contestStatusId, setContestStatusId] = useState('');
    const [weeklineupUniqueData, setNewWeeklineupUniqueData] = useState('');
    const [seasonlineupUniqueData, setSeasonlineupUniqueData] = useState('');
    const [showOwnLineup, setShowOwnLineup] = useState(true);
    const [leaderUserId, setLeaderUserId] = useState(0);
    const [leaderUserLineUpNumber, setLeaderUserLineUpNumber] = useState(0);
    const [standingsUserId, setStandingsUserId] = useState(0);
    const [selectedLIneup, setSelectedLIneup] = useState(0);
    const [lineupStrategy, setLineupStrategy] = useState([]);
    const [firstLineUpPosition, setFirstLineUpPosition] = useState('');
    const [totalContestLineUp, setTotalContestLineUp] = useState(0);
    const [leagueCardList, setLeagueCardList] = useState(null);
    const [leaveButtonShow, setleaveButtonStatus] = useState(false);
    const [lineupSaveAction, setLineupSaveAction] = useState(false);
    const [enableLineupButton, setEnableLineupButton] = useState(false);
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [toastSuccessMessage, setToastSuccessMessage] = useState(null);

    let id = parseInt(contestid);
    if (!id) {
        navigate(pageURLs.lobby);
    }
    sessionStorage.setItem('contestId', id);

    const [seasonStatsUserLineUp, setSeasonStatsUserLineUp] = useState('');

    const initialSetSeasonStatsPosition = "";
    const [seasonStatsPosition, setSeasonStatsPosition] = useState('initialSetSeasonStatsPosition');
    const initialSeasonStatsParams = {
        contest_id: contestid,
        isUserGame: true,
        player_position: ""
    };

    const [seasonStatsParams, setSeasonStatsParams] = useState(initialSeasonStatsParams);
    const [seasonStatsResponse, setSeasonStatsResponse] = useState({});
    const [winnersResponse, setWinnersResponse] = useState({});

    const initialSeasonStandingsParams = {
        contest_id: parseInt(contestid),
        lineup_week: lineupWeekNumber,
       // lineup_number: 1
    };
    const [seasonStandingsParams, setSeasonStandingsParams] = useState(initialSeasonStandingsParams);
    const [seasonStandingsResponse, setSeasonStandingsResponse] = useState("");
    const [seasonStandingsLeaderBoradResponse, setSeasonStandingsLeaderBoradResponse] = useState("");
    const [seasonStatsUser, setSeasonStatsUser] = useState(0);

    const [apiPlayerScoreData, setApiPlayerScoreData] = useState([]);
    const [liveWeekStandings, setLiveWeekStandings] = useState([]);
    const [lineupPlayers, setLineupPlayers] = useState([]);
    const [liveScorePlayerId, setLiveScorePlayerId] = useState(null);
    let ctime = Math.floor(Date.now() / 1000);
    const [currentTime, setCurrentTime] = useState(ctime);

    // -----------------------
    const [standingsUser, setStandingsUser] = useState(0);
    const [manageLeageUid, setManageLeageUid] = useState(uId);
    useEffect(() => {
        if (manageLeageUid == userData.user.user_id) {
            setSeasonStatsUser(0)
            setStandingsUser(0)
        }


    }, [])


    useEffect(() => {
        setSeasonStandingsParams(seasonStandingsParams => ({ ...seasonStandingsParams, lineup_week: lineupWeekNumber }));
    }, [lineupWeekNumber])


    useEffect(() => {
        if (pageIsViewLeaders) {
            setSelectedTab('winners')
        } else if (pageIsStandingsPage) {

            setSelectedTab('seasonstats');
            if (manageLeageUid != userData.user.user_id) {
                setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: 'QB' }))
                setSeasonStatsUser(manageLeageUid)
                setStandingsUser(manageLeageUid)
            }
        }
        if (pageIsViewLeaders || pageIsStandingsPage) {
            setHideDetailsAndChangeWinners(true)
        }
        setPageIsViewLeaders(false);
        setPageIsStandingsPage(false);
    }, [pageIsViewLeaders, pageIsStandingsPage, seasonStatsUser, manageLeageUid])
 
    useEffect(() => {

        if (standingsUser) {
            siteState.action.getSeasonStatsResponse(seasonStatsParams, standingsUser);
        }
    }, [seasonStatsUser, standingsUser])

    // useEffect(() => {
    //     if (selectedTab == 'seasonstandings') {
           
    //      //   siteState.action.getSeasonStandingsResponse(seasonStandingsParams, 2);
    //     }

    // }, [seasonStandingsParams])

    useEffect(() => {
        if (siteState.data.Lobby.SeasonLeaderboardService)
            setSeasonStandingsLeaderBoradResponse(siteState.data.Lobby.SeasonLeaderboardService
            )
    }, [siteState.data.Lobby.SeasonLeaderboardService]);

    useEffect(() => {
        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data)
            setSeasonStatsResponse(siteState.data.Lobby.seasonStatsResponse.data)

        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data)
            setSeasonStandingsLeaderBoradResponse(siteState.data.Lobby.seasonStatsResponse)

    }, [siteState.data.Lobby.seasonStatsResponse]);

    useEffect(() => {

        if (selectedTab == 'seasonstats') {
            setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: 'QB' }))
        } else if (selectedTab == 'seasonstandings') {
            setSeasonStandingsParams(seasonStandingsParams => ({ ...seasonStandingsParams, lineup_week: lineupWeekNumber }));
           // siteState.action.getSeasonLeaderboard({ si_contest_id: contestid }, 2);
        }



    }, [selectedTab]);

    const [apiLineUpNumber, setApiLineUpNumber] = useState(0);
    const [apiLineUpWeek, setApiLineUpWeek] = useState(0);
    const [apiCurrentLineupId, setApiCurrentLineupId] = useState(0);

    const getPlayerScoresOfNewLineUp = (lineUpNumber, lineUpWeek, currentLineupId) => {
        setApiLineUpNumber(lineUpNumber)
        setApiLineUpWeek(lineUpWeek)
        setApiCurrentLineupId(currentLineupId)
    }

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (selectedTab == 'details' && userData.user.user_id && contestid && apiLineUpWeek && apiCurrentLineupId) {
    //             let liveScoreFetchingUrl = (process.env.REACT_APP_LIVE_SCORE_FETCH_URL) ? process.env.REACT_APP_LIVE_SCORE_FETCH_URL  : 'https://52ewvbdfci.execute-api.us-east-1.amazonaws.com/dev';
    //             fetch(liveScoreFetchingUrl, {
    //                 method: "POST",
    //                 headers: {
    //                     'accept': "application/json",
    //                 }, body: JSON.stringify({
    //                     "userId": userData.user.user_id, "contestId": contestid, "lineupId": apiCurrentLineupId, "week": apiLineUpWeek
    //                 })
    //             }).then((response) => response.json())
    //                 .then((data) => {
    //                     if (data.statusCode == 200 && data.scoresData && data.scoresData.total>0) {
    //                         setApiPlayerScoreData(data.scoresData);
    //                     }
    //                 });
    //         }
    //     }, 6000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [selectedTab, apiLineUpWeek, apiCurrentLineupId])


    useEffect(() => {
        // contestService.getUsergameContest(id)
        //     .then(response => {
        //         if (response.success && response.data && response.data.contestDetails) {
        //             setContestData(response.data)
        //             if (response.data.contestDetails.contest_end_week < response.data.contestDetails.contest_current_week) { //If contest end date is less than current season date we will set it as active week for that contest
        //                 setLineupWeekNumber(response.data.contestDetails.contest_end_week);
        //             } else if (response.data.contestDetails.contest_start_week > response.data.contestDetails.contest_current_week) {
        //                 setLineupWeekNumber(response.data.contestDetails.contest_start_week)
        //             } else {
        //                 setLineupWeekNumber(response.data.contestDetails.contest_current_week);
        //             }
        //         }
        //     })
        //     .catch(err => {
        //         console.log("Error", err);
        //     });


        if (selectedTab == 'seasonstats') {
            let default_player_card = JSON.parse(sessionStorage.getItem('default_player_card'));
            let position = (default_player_card && default_player_card.length) ? default_player_card[0].positionshortname : 'QB';
            setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: position }))

        } else if (selectedTab == 'winners') {
            siteState.action.getWinnersResponse({ isUserGame: 2, contestid: contestid });
        } else if (selectedTab == 'seasonstanding' || selectedTab == 'seasonstandings') {
            siteState.action.getSeasonStandingsResponse(seasonStandingsParams, 2);
            siteState.action.getSeasonLeaderboard({ si_contest_id: contestid }, 2);
        }
    }, [selectedTab])

    // --------------season stats start ----------------
    useEffect(() => {


        if (selectedTab == 'seasonstats' && seasonStatsParams.player_position != "") {
            let staus_user_id = 0;
            if (seasonStatsUser && user_id != seasonStatsUser) {
                staus_user_id = seasonStatsUser;
            }
            siteState.action.getSeasonStatsResponse(seasonStatsParams, staus_user_id);
        }
    }, [seasonStatsParams])

    useEffect(() => {

        if (seasonStatsParams.player_position != "") {
            let staus_user_id = 0;
            if (seasonStatsUser && user_id != seasonStatsUser) {
                staus_user_id = seasonStatsUser;
            }
            if (seasonStatsUserLineUp) {
                seasonStatsParams.lineup_number = seasonStatsUserLineUp;
                setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, lineup_number: seasonStatsUserLineUp }))

                siteState.action.getSeasonStatsResponse(seasonStatsParams, staus_user_id);
            }
        }
    }, [seasonStatsUser, seasonStatsUserLineUp])


    useEffect(() => {
        if (siteState.data.Lobby.SeasonLeaderboardService) {
            setSeasonStandingsLeaderBoradResponse(siteState.data.Lobby.SeasonLeaderboardService)
        }
    }, [siteState.data.Lobby.SeasonLeaderboardService]);

    useEffect(() => {
        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data) {
            setSeasonStatsResponse(siteState.data.Lobby.seasonStatsResponse.data)
        }

        if (siteState.data.Lobby.seasonStatsResponse && siteState.data.Lobby.seasonStatsResponse.data) {
            setSeasonStandingsLeaderBoradResponse(siteState.data.Lobby.seasonStatsResponse)
        }

    }, [siteState.data.Lobby.seasonStatsResponse]);


    const changePlayerPositionClick = (Position) => {
       // if (Position != 'QB' && Position != "") {
            setSeasonStatsParams(seasonStatsParams => ({ ...seasonStatsParams, player_position: Position }))
      //  }
    }

    useEffect(() => {
        if (siteState.data.Lobby && siteState.data.Lobby.winnersResponse) {
            setWinnersResponse(siteState.data.Lobby.winnersResponse.data)
        }
    }, [siteState.data.Lobby.winnersResponse])

    if (siteState.data.Lobby.positionPlayerCardView.length >= maximumCardNumber) {
        saveButtonShow = true;
    } else {
        saveButtonShow = false;
    }

    useEffect(() => {
        if (siteState.data.Lobby.seasonStandingsResponse && siteState.data.Lobby.seasonStandingsResponse.data) {
            setSeasonStandingsResponse(siteState.data.Lobby.seasonStandingsResponse.data)
        }
    }, [siteState.data.Lobby.seasonStandingsResponse])

    const updateSelectedTabConfirm = (tabName) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                updateSelectedTab(tabName)
            }
        } else {
            updateSelectedTab(tabName)
        }

    }

    const updateSelectedTab = (tabName) => {
        setSelectedTab(tabName);
        setNewWeeklineupUniqueData(0)
        setSeasonlineupUniqueData('')

        //--------
        setSeasonStandingsResponse("");
        setSeasonStandingsLeaderBoradResponse("");
        setSeasonStatsResponse("");
        setWinnersResponse("");
        // setCurrentSeasonData([]);
        setSeasonStatsUserLineUp("")
        setSeasonStatsUser(0);
        //--------------

        //if (tabName != 'weeklystanding' && tabName != 'seasonstanding' && tabName != 'seasonstandings') {
            setShowOwnLineup(true);
            setLeaderUserId(0)
       // }
    }

    const setWeeklyNumber = (week) => {
        setLineupWeekNumber(week);
        setSeasonStandingsParams(seasonStandingsParams => ({ ...seasonStandingsParams, lineup_week: week }))
    }

    const updateMaximumCardNumbers = (maximumNumber) => {
        setMaximumCardNumber(maximumNumber);
    }
    

    const getSideMenuClasses = () => {
        let result = "koblock-right lineup-notification-sec";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    const getLineUpNoticeClasses = () => {
        let result = "option-toast";
        if (flagLineUpNotice) {
            result += " visible";
        } else {
            result += " hide";
        }
        return result;
    }

    const [Flag_ShowCollapse, setFlag_ShowCollapse] = useState(false);
    const getCollapseClasses = () => {
        let result = "contest-info-sec";
        if (Flag_ShowCollapse) {
            result += " collapsed";
        } else {
            result += " collapsable";
        }
        return result;
    }

    
    
    /*
     * Calculate the weekly halfs 
     */
    const getWeeklyHalves = (totalWeeks) =>  {
        let halfs = (totalWeeks > 1) ? (totalWeeks/2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf" : firstHalf,
            "secondHalf" : secondHalf
        }
    } 
    const getNumberOfEliminationPerWeek = (totalWeeks , totalContestants ) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf/halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1)? firstWeekSubEliminatorCalculator: 0;
        return {
            'playersAliveSecondHalf' : numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf' : numPlayersEliminatedFirsHalf,
            'firstWeekElimination' : firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek' : firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks' : halves.firstHalf,
            'secondHalfWeeks' : halves.secondHalf,
            'minimumUsersRequired' : totalWeeks+1
        }
    }  

    //Weekly elimination formula

    const calculate = (totalContestants , totalWeeks ) => {
        if (totalContestants === 0 || totalWeeks === 0) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }

    let user_id = parseInt(userData.user.user_id);
    useEffect(async (e) => {
        await siteState.action.updateSelectedPlayerCardForUsergame(0);
        contestService.getUsergameContest(id)
            .then(async response => {
                if (response.success) {
                    
                    await updatePlayersCardDisplay(response.data.lineuStrategy);
                    setCurrentSeasonData(response.data.seasonDetails);
                    setLineupStrategy(response.data.lineuStrategy);
                    let firstPosition = 'QB';
                    if (response.data.lineuStrategy && response.data.lineuStrategy.total_qb) {
                        firstPosition = 'QB';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_rb) {
                        firstPosition = 'RB';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_wr) {
                        firstPosition = 'WR';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_te) {
                        firstPosition = 'TE';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_fl) {
                        firstPosition = 'FL';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_ki) {
                        firstPosition = 'K';
                    } else if (response.data.lineuStrategy && response.data.lineuStrategy.total_df) {
                        firstPosition = 'DF';
                    }
                    setFirstLineUpPosition(firstPosition);

                    if (response && response.data && response.data.contestDetails && response.data.contestDetails.contest_end_week) {
                        if (response.data.contestDetails.contest_end_week < response.data.contestDetails.contest_current_week) { //If contest end date is less than current season date we will set it as active week for that contest
                            setLineupWeekNumber(response.data.contestDetails.contest_end_week);
                        } else if (response.data.contestDetails.contest_start_week > response.data.contestDetails.contest_current_week) {
                            setLineupWeekNumber(response.data.contestDetails.contest_start_week)
                        } else {
                            setLineupWeekNumber(response.data.contestDetails.contest_current_week);
                        }
                    }

                    setTotalContestLineUp(response.data.contest_total_line_up);
                    setContestData(response.data);
                    setActiveMenu(response.data)
                    sessionStorage.removeItem('contestJoinedAction');
                    if (response.data.contestDetails.contest_status_id == 3 && response.data.contestDetails.owner_id != userData.user.user_id) {
                        setleaveButtonStatus(true)
                    } else {
                        setleaveButtonStatus(false)
                    }
                    siteState.data.Lobby.currentContestData = response.data.contestDetails
                    ;
                }
            })
            .catch(err => {
                console.log("Error", err);
            });

    }, []);

    const addLineup = () => {
        setShowLineupToast(true);
        setShowDebitToast(true);
    }

    const removeLineup = () => {
        setShowCreditToast(true);
    }

    const getDivTieBreaker = () => {

        return (
            <>
                <div className="tiebreaker-score contest-eliminate" >
                    <h5>Final Tiebreaker: 100.01</h5>
                    <p>Final Tiebreaker for the season.</p>
                </div>
            </>
        )
    }



    if (contestData && (contestData.contestUserJoinData.length == 0 || contestData.contestUserJoinData[0].contest_user_status_id == 3)) {
        navigate(pageURLs.lobby);
    }

    const setActiveMenu = async (contestData) => {
        if (contestData && contestData.contestDetails.contest_status_id === 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (contestData && contestData.contestDetails.contest_status_id === 2) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (contestData && (contestData.contestDetails.contest_status_id === 4 || contestData.contestDetails.contest_status_id === 5)) {
            siteState.action.setLeftSideMenu('lobby');
        }else  siteState.action.setLeftSideMenu('lobby');
    }

    //Weekly elimination formula

    let contest_start_week = contestData ? contestData.contestDetails.contest_start_week : 0;
    let max_lineup_per_user = contestData ? contestData.contestDetails.max_lineup_per_user : 0;
    let contest_end_week = contestData ? contestData.contestDetails.contest_end_week : 0;
    let totalWeeks = contestData ? contestData.contestDetails.duration_in_weeks : 0;
    let maxParticipants = contestData ? contestData.contestDetails.max_users : 0;
    let weekDivident = contestData ? totalWeeks / 2 : 0;
    let firstHalfWeekCount = Math.floor(weekDivident);//First half weeks number
    let secondHalfWeekCount = Math.ceil(weekDivident);//Second half weeks number
    let secondPartWeekStartFrom = 2;
    let thirdPartWeekStartFrom = parseInt(firstHalfWeekCount) + 1;
    thirdPartWeekStartFrom = contest_start_week == 1 ?thirdPartWeekStartFrom : (thirdPartWeekStartFrom+contest_start_week-1);
    
    let secondHalfParticipants = contestData ? secondHalfWeekCount + 1 : 0;
    let secondHalfEliminatorCount = contestData ? secondHalfWeekCount : 0;

    const weeklyEliminationSplit = calculate( maxParticipants,totalWeeks);


    let firstHalfParticipants = contestData ? parseInt(maxParticipants) - parseInt(secondHalfParticipants) : 0;
    let firsthalfWeeklyElimintorCount = contestData ? Math.floor(parseInt(firstHalfParticipants) / parseInt(firstHalfWeekCount)) : 0;
    let balanceElimintorInFirstWeek = contestData ? parseInt(firsthalfWeeklyElimintorCount) * parseInt(firstHalfWeekCount) : 0;
    let balanceElimintorCount = contestData ? parseInt(firstHalfParticipants) - parseInt(balanceElimintorInFirstWeek) : 0;
    let firstWeekEliminatorCount = contestData ? parseInt(firsthalfWeeklyElimintorCount) + parseInt(balanceElimintorCount) : 0;
    let firsthalfSecondSetWeeklyElimintorCount = contestData ? parseInt(firsthalfWeeklyElimintorCount) * (parseInt(firstHalfWeekCount) - 1) : 0;
    if (totalWeeks == 1) {
        firstWeekEliminatorCount = parseInt(maxParticipants) - 1;
    }

    const locale = 'en-US';
    // const contestStartDate = contestData ? commonService.convertUTCDateToLocalDate(new Date(contestData.contestDetails.contest_start_time)) : null;


    // const contestDate = contestData ? contestStartDate.getDate() : null;
    // const contestMonth = contestData ? contestStartDate.toLocaleDateString(locale, { month: 'long', timeZone: "America/New_York" }) : null;
    // const contesttime = contestData ? contestStartDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: "America/New_York" }) : null;
    //const countDownDate = contestData ? commonService.getTimeDifference(contestStartDate) : null;
    let contestDate;
    if(contestData) {
        contestDate = moment.utc(contestData.contestDetails.contest_start_time).format('MMMM DD | h:mm A');
    }
    const entryFee = contestData ? contestData.contestDetails.register_fee : 0;
    let dtCurrent = contestData ? new Date(contestData.contestDetails.contest_start_time) : null;
    let estDate = contestData ? dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' }) : null;
    let dtEst = contestData ? new Date(estDate) : null;
    const countDownDate = contestData ? commonService.getTimeDifference(dtEst) : null;

    const updateSeasonStatsUser = (user_id, lineup_number) => {
        setManageLeageUid(user_id)
        setSeasonStatsUser(user_id)
        setSeasonStatsUserLineUp(lineup_number)

    }

    const updateLiveWeeklyStandings = (WeeklyStandings) => {
        if (WeeklyStandings.standingDetails) {
            setLiveWeekStandings(WeeklyStandings)
        } else {
            setLiveWeekStandings([])
        }
    }

    const updatePlayersCardDisplay = async (lineupStrategy) => {
        let playersCard = [];
        let index = 1;
        let maximumLineUpCard = 0;
        if (lineupStrategy) {

            let total_qb = parseInt(lineupStrategy.total_qb);
            let total_rb = parseInt(lineupStrategy.total_rb);
            let total_wr = parseInt(lineupStrategy.total_wr);
            let total_te = parseInt(lineupStrategy.total_te);
            let total_fl = parseInt(lineupStrategy.total_fl);
            let total_ki = parseInt(lineupStrategy.total_ki);
            let total_df = parseInt(lineupStrategy.total_df);

            maximumLineUpCard = total_qb + total_rb + total_wr + total_te + total_fl + total_ki + total_df;
            for (let i = 0; i < total_qb; i++) {

                let qbList = { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: index, placePosition: "quarterback" + index, positionshortname : 'QB' }
                index++;
                playersCard.push(qbList);
            }

            for (let i = 0; i < total_rb; i++) {

                let rbList = { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: index, placePosition: "runningback" + index, positionshortname : 'RB' }
                index++;
                playersCard.push(rbList);
            }

            for (let i = 0; i < total_wr; i++) {
                let wrList = { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: index, placePosition: "widereceiver" + index, positionshortname : 'WR' }
                index++;
                playersCard.push(wrList);
            }

            for (let i = 0; i < total_te; i++) {
                let teList = { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: index, placePosition: "tightend" + index, positionshortname : 'TE' }
                index++;
                playersCard.push(teList);
            }

            for (let i = 0; i < total_fl; i++) {
                let flList = { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: index, placePosition: "flexplayer" + index, positionshortname : 'FL' }
                index++;
                playersCard.push(flList);
            }

            for (let i = 0; i < total_ki; i++) {
                let kiList = { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: index, placePosition: "kicker" + index, positionshortname : 'K' }
                index++;
                playersCard.push(kiList);
            }

            for (let i = 0; i < total_df; i++) {
                let dfList = { className: "defence", playername: "", figure: "", score: "", position: "DST", dataIndex: index, placePosition: "defence" + index, positionshortname : 'DF' }
                index++;
                playersCard.push(dfList);
            }
            sessionStorage.setItem('default_player_card', JSON.stringify(playersCard));
            setLeagueCardList(playersCard);
        } else {
            maximumLineUpCard = 10;
            playersCard = [
                { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: "1", placePosition: "quarterback1" },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "2", placePosition: "runningback1" },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "3", placePosition: "runningback2" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "4", placePosition: "widereceiver1" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "5", placePosition: "widereceiver2" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "6", placePosition: "widereceiver3" },
                { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: "7", placePosition: "tightend1" },
                { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: "8", placePosition: "flexplayer1" },
                { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: "9", placePosition: "kicker1" },
                { className: "defence", playername: "", figure: "", score: "", position: " DST", dataIndex: "10", placePosition: "defence1" }
            ];
            setLeagueCardList(playersCard);
            sessionStorage.setItem('default_player_card', JSON.stringify(playersCard));
            return playersCard;
        }

    }
    let default_player_card = JSON.parse(sessionStorage.getItem('default_player_card'));

    const updateLiveWeeklyPlayerScores = (scoresData) => {
        setApiPlayerScoreData(scoresData);
    }


    const updateLineupPlayers = (lineupData) => {
        setLineupPlayers(lineupData);
    }

    
    useEffect(() => {
        if (contestData &&weeklyEliminationSplit && firstHalfWeekCount && contest_start_week && contest_end_week) {
            siteState.data.Lobby.WeeklyEliminationData = {

                firstWeek: contest_start_week,
                firstWeekCount: weeklyEliminationSplit.data.firstWeekElimination,

                secondWeekRangeStart: contest_start_week ? parseInt(contest_start_week) + 1 : 2,
                secondWeekRangeEnd: firstHalfWeekCount == 2 ? 0 :
                contest_start_week == 1 ? firstHalfWeekCount : (thirdPartWeekStartFrom - 1),
                secondWeekRangeCount: weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek,

                thirdWeekRangeStart: thirdPartWeekStartFrom,
                thirdWeekRangeEnd: thirdPartWeekStartFrom == contest_end_week ? 0 : contest_end_week,
                thirdWeekRangeCount: 1
            }
        }
    }, [contestData && weeklyEliminationSplit, contest_start_week, firstHalfWeekCount, thirdPartWeekStartFrom, contest_end_week])

     //Trigger lineup save action
     const lineupSave = async (status) => {
        setLineupSaveAction(status)
    }
    //Lineup save button enable
    const updateSaveLinupStatus = async (status) => {
        setEnableLineupButton(status)
    }

    const updateTostStatus = async (status, message) => {
        setShowToastSuccess(status)
        setToastSuccessMessage(message)
    }   
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.joincontest} /></div>
                <div className="kodata-content lineup-content">
                    <div className={getCollapseClasses()}>
                        <Button className="toggle-arrow" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}><i className="ic-chevron"></i></Button>
                        <div className="contest-title-info">
                            <div className="title-sec">
                                <h4>{contestData && contestData.contestDetails.contest_name}</h4>
                                <p className="gamelive" style={{ display: "none" }}><span className="ripple"></span>GAME LIVE</p>
                            </div>
                            <div className="info-group">
                            <LineupButtonGroup saveButtonShow={false} selectedTab={selectedTab} leaveButtonShow={leaveButtonShow}  userId={userData.user.user_id} contestid={id} contestStatusId={contestStatusId} checkRefundStatus ={false} userGame = {true} lineupSave = {lineupSave} enableLineupButton ={enableLineupButton}/>
                                {(contestData && contestData.contestDetails.contest_type_id === 1) && <span className="bg-label bg-standard">Standard</span>}
                                {(contestData && contestData.contestDetails.contest_type_id === 2) && <span className="bg-label bg-knockout">Knockout Plus</span>}
                                <div className={contestData && (contestData.contestDetails.contest_status_id === 2 || contestData.contestDetails.contest_status_id === 5) ? "timer-sec live":"timer-sec"}>
                                    <h5 style={{ display: "none" }}>Starts on: Week 3</h5>
                                    <LiveTimer Status={contestData && contestData.contestDetails.contest_status_id} countDownDate={countDownDate} />
                                    <Button variant="primary" className="toggle-btn" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}></Button>
                                </div>
                            </div>
                        </div>
                        <div className="contest-details">
                            <div className="general-info">
                                <div className="info-data">

                                    <div className="form-group">
                                        <label>Entries</label>
                                        <p>{contestData && contestData.contestDetails.total_lineups}/{contestData && contestData.contestDetails.max_users}</p>
                                    </div>


                                    {

                                   /* <div className="form-group">
                                        <label>Categories</label>
                                        {contestData && contestData.contestDetails.lup_entrylevel_category == 'M' && <span className="badge bg-m">M</span>}
                                        {contestData && contestData.contestDetails.guarantee_category == 'G' && <span className="badge bg-g">G</span>}
                                        {contestData && contestData.contestDetails.lup_entrylevel_category != 'M' && <span className="badge bg-m">S</span>}
                                    </div>
                                   */}
                                    <div className="form-group">
                                        <label>  Duration</label>
                                        <p>Week {contestData && contest_start_week}  - {contestData && contest_end_week}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>   Max Lineups</label>
                                        <p>{contestData && contestData.contestDetails.max_lineup_per_user}  </p>
                                    </div>

                                    {(contestData && contestData.contestDetails.total_weekly_winners > 0 && contestData.contestDetails.contest_type_id === 2) && <div className="form-group">
                                        <label>Weekly Winners</label>
                                        <p>{contestData.contestDetails.total_weekly_winners}</p>
                                    </div>
                                    }
                                    {(contestData && contestData.contestDetails.total_season_winners > 0 && contestData.contestDetails.contest_type_id === 2) && <div className="form-group">
                                        <label>Season Sc. Winners</label>
                                        <p>{contestData && contestData.contestDetails.total_season_winners}</p>
                                    </div>}
                                </div>
                                <div className="note">
                                    <p>Eliminated Weekly*:</p>
                                    <ul>

                                        {
                                            /*
                                           <li>Week {contest_start_week}: {weeklyEliminationSplit.data.firstWeekElimination}
    
                                            {weeklyEliminationSplit.data.firstWeekElimination > 1 ? ' Users' : ' User'}</li>
    
                                            {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 0 && <li>Week {contest_start_week? parseInt(contest_start_week) + 1 :2}  {firstHalfWeekCount == 2 ? "" : ' - ' + (thirdPartWeekStartFrom-1) }: {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek} {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 1 ? ' Users' : ' User'}</li>}
    
                                            {weeklyEliminationSplit.data.secondHalfWeeks > 0 && <li>Week {thirdPartWeekStartFrom}  {thirdPartWeekStartFrom == contest_end_week ? "" : ' - ' + contest_end_week}: 1 User</li>}
    */
                                        }

                                        <EliminatedWeekly lineupWeekNumber='0' />

                                    </ul>
                                </div>
                            </div>
                            <div className="time-info">
                            {contestDate ? <p>{contestDate} EST</p> : ''}
                            </div>
                        </div>
                    </div>
                    {/* <div className="btn-group-lineup">
                        <LineupButtonGroup saveButtonShow={saveButtonShow} />
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); setFlagLineUpNotice(true); }}><ChevrontoggleIcon /></Button>
                        </div>
                    </div> */}

{toastSuccessMessage ? <ToastContainer position="top-end">
                <Toast className="toast-success" onClose={() => setShowToastSuccess(false)} show={showToastSuccess} delay={3000} autohide>
                    <Toast.Body><TickIcon />{toastSuccessMessage}</Toast.Body>
                </Toast>
            </ToastContainer> : '' }

            <ToastContainer >
                            <Toast className="toast-success" onClose={() => setShowJoinToast(false)} show={showjointoast} delay={3000} autohide>
                                <Toast.Body><TickIcon />You have joined this contest successfully.</Toast.Body>
                            </Toast>
                        </ToastContainer>

                        <ToastContainer>
                            <Toast className="toast-success" onClose={() => setShowLineupToast(false)} show={showlineuptoast} delay={3000} autohide>
                                <Toast.Body><TickIcon />An additional lineup has been added to this contest successfully.</Toast.Body>
                            </Toast>
                        </ToastContainer>

                        <ToastContainer >
                            <Toast className="toast-success" onClose={() => setShowSaveToast(false)} show={showcsavetoast} delay={3000} autohide>
                                <Toast.Body><TickIcon />The lineup has been saved successfully.</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    <div className="lineup-container">
                        <div className="content-block-lft lineup-create-wrap">
                            <Tabs
                                transition={false}
                                id="lineup-tab"
                                className="lineup-create-tab"
                                activeKey={selectedTab}
                                onSelect={(k) => updateSelectedTabConfirm(k)}
                            >
                                { <Tab eventKey="details" title="My Lineup">

                                    <div className="tab-details-data">{contestData && selectedTab == 'details' && 
                                    <UserLineupSetup 
                                    getPlayerScoresOfNewLineUp={getPlayerScoresOfNewLineUp}
                                    apiPlayerScoreData={apiPlayerScoreData} 
                                    selectedTab={selectedTab} 
                                    contestData={contestData} 
                                    addLineup={addLineup} 
                                    removeLineup={removeLineup} 
                                    totalWeek={totalWeeks} 
                                    entryFee={entryFee} 
                                    contestStartWeek={contest_start_week} 
                                    contestEndWeek={contest_end_week} 
                                    max_lineup_per_user={max_lineup_per_user} 
                                    updateMaximumCardNumbers={updateMaximumCardNumbers} 
                                    currentSeasonData={currentSeasonData} 
                                    lineupStrategy={lineupStrategy} 
                                    firstLineUpPosition={firstLineUpPosition} 
                                    totalContestLineUp={totalContestLineUp} 
                                    updateLineupPlayers={updateLineupPlayers} 
                                    lineupSaveAction = {lineupSaveAction}
                                    lineupSave = {lineupSave}
                                    updateSaveLinupStatus = {updateSaveLinupStatus}
                                    updateTostStatus = {updateTostStatus}
                                    />}

                                        {!contestData && <div className="col-md-12"><div className="playercard" style={{ background: "#fff" }}><div className="loader-wrap"><div className="loader"></div></div></div></div>}
                                    </div>
                                </Tab>
                                }

                                <Tab eventKey="standings" title="Standings">
                                    {(selectedTab == 'standings' && contestData) && <div className="tab-standing tab-weekly-data"><StandingsSetup contestData={contestData} isuserGame={true} currentSeasonData = {currentSeasonData} ></StandingsSetup></div> }
                                </Tab>

                                {/* <Tab eventKey="seasonstandings" title="Season Standings">

                                    <div className="tab-standing tab-season-data">{contestData && (selectedTab == 'seasonstandings' || selectedTab == 'seasonstanding') && <UserSeasonStandingLineupSetup currentSeasonData={currentSeasonData} contestData={contestData} totalWeek={totalWeeks} entryFee={entryFee} contestStartWeek={contest_start_week} contestEndWeek={contest_end_week} max_lineup_per_user={max_lineup_per_user} updateMaximumCardNumbers={updateMaximumCardNumbers} lineupWeekNumber={lineupWeekNumber} seasonStandingsResponse={seasonStandingsResponse} seasonStandingsLeaderBoradResponse={seasonStandingsLeaderBoradResponse} showUserLineUpByUser={showUserLineUpByUser} leaderUserId={leaderUserId} leaderUserLineUpNumber={leaderUserLineUpNumber} seasonlineupUniqueData={seasonlineupUniqueData} showOwnLineup={showOwnLineup} lineupStrategy={lineupStrategy} />}</div>
                                </Tab> */}

                                <Tab eventKey="seasonstats" title="Stats">
                                    {selectedTab == 'seasonstats' && <UserSeasonStats changePlayerPositionClick={changePlayerPositionClick} seasonStatsResponse={seasonStatsResponse} position={seasonStatsParams.player_position} />}
                                </Tab>

                                <Tab eventKey="rules" title="Lineup and Scoring">
                                    {contestData && selectedTab == 'rules' && <UserRulesAndScoring contestData={contestData} contestId={contestid} />}
                                </Tab>

                                <Tab eventKey="topplayers" title="Top Players">
                                    {contestData && selectedTab == 'topplayers' &&  <TopPlayers contestData={contestData} isuserGame={true}/> }
                                </Tab>

                                {(contestData && contestData.contestDetails.contest_type_id == 2) ? <Tab eventKey="winners" title={hideDetailsAndChangeWinners ? "Leaders" : "Winners"}>
                                    {contestData && selectedTab == 'winners' && <WinnersList winnersResponse={winnersResponse} isuserGame={true} contest_type_id = {contestData.contestDetails.contest_type_id} />}
                                </Tab> : ''}

                            </Tabs >
                        </div >
                        <div className={getSideMenuClasses()} style={{display: "none"}}>

                            {!pageIsViewLeaders && <div className={getLineUpNoticeClasses()}>
                                <Button className="btn-close" onClick={() => { setFlagLineUpNotice(false); setFlag_ShowSideMenu(false); }}></Button>
                                <p>You have the option to set lineups for future weeks.</p>
                            </div>
                            }

                            {/* {!pageIsViewLeaders && <LineupButtonGroup saveButtonShow={saveButtonShow} saveLineUpButton={saveLineUpButton} />} */}

                            {showTieBreaker ? getDivTieBreaker() : ''}

                            {/* {(selectedTab === "weeklystanding" && contestData) && <UserWeeklyStandings lineupWeekNumber={lineupWeekNumber} showUserLineUpByUser={showUserLineUpByUser} currentSeasonData={currentSeasonData} liveWeekStandings={liveWeekStandings} />}

                            {(selectedTab === "seasonstandings" || selectedTab == 'seasonstanding') && <UserSeasonStandings lineupWeekNumber={lineupWeekNumber} showUserLineUpByUser={showUserLineUpByUserSeason} seasonStandingsResponse={seasonStandingsResponse} seasonStandingsLeaderBoradResponse={seasonStandingsLeaderBoradResponse} />} */}

                            {(selectedTab === "seasonstats") && <UserSeasonStatsContestantStanding seasonStandingsLeaderBoradResponse={seasonStandingsLeaderBoradResponse} updateSeasonStatsUser={updateSeasonStatsUser} seasonStatsUser={seasonStatsUser} selectedLIneupUiD={manageLeageUid} />}
                        </div>
                        
                    </div >
                </div >
            </div >
        </InnerPageWrap >
    );
};

export default UsergameCreateLineup;
