import React, { useState, useEffect} from 'react';
import { Accordion, Table } from "react-bootstrap";
import Moment from 'react-moment';
import * as commonService from "../services/commonService";

const InjuryReport = props => {
    const [injuryReports, setinjuryReports] = useState([]);
    const [showLoader, setLoader] = useState(false);
    useEffect( async () => {
        setLoader(true)
        await commonService.getInjuryReports()
        .then(async function (response) {
            setLoader(false)
            if(response.errorCode == 200) {
                setinjuryReports(response.data.inuryData);
            } else {
                setinjuryReports([]);
            }
        })
    }, [])

    const inuryTabs = (injuryDetails) => {
        const distinctTeams = [...new Set(injuryDetails.map(item => item.team_full_name))];
        let injuryReportRow = [];
        for (let k = 0; k < distinctTeams.length; k++) {
            injuryReportRow.push(
                <Accordion.Item eventKey={"team_"+k}>
                    <Accordion.Header>{distinctTeams[k]}</Accordion.Header>
                    <Accordion.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>POS</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Injury Type</th>
                                    <th>Side</th>
                                    <th>Return Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        injuryDetails.map((injury) => { 
                                            return (
                                                injury.team_full_name == distinctTeams[k] ?<tr>
                                                    <td>{injury.player_full_name}</td>
                                                    <td>{injury.player_position}</td>
                                                    <td><Moment format='DD-MMMM'>{injury.imported_date}</Moment></td>
                                                    <td>{injury.player_injury_status}</td>
                                                    <td>{injury.player_injury_type}</td>
                                                    <td>{injury.player_injury_side}</td>
                                                    <td>{injury.player_return_date ? <Moment format='MM-DD-YYYY'>{injury.player_return_date}</Moment> : '-'}</td>
                                                </tr> : ''
                                            )
                                        })
                                    }
                                </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            );
        }
        return (injuryReportRow)
    }
    return (
        <>
            <Accordion defaultActiveKey="team_0" className="report-accordion">
                {(injuryReports.length) ? inuryTabs(injuryReports) : ''}
                {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
            </Accordion> 
            
            {(!injuryReports.length && !showLoader) ? <div className="playercard"><span style={{ color: "red" }}>No injury reports to display.</span></div> : ''}
        </>
    );
  
}

export default (InjuryReport);
