import React from "react";


const FiltertoggleIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17">
            <g id="Group_17868" data-name="Group 17868" transform="translate(-11 -12.5)">
                <line id="Line_81" data-name="Line 81" y2="15" transform="translate(14.5 13.5)" fill="none" stroke="#1abcff" stroke-linecap="round" stroke-width="2" />
                <line id="Line_82" data-name="Line 82" y2="15" transform="translate(23.5 13.5)" fill="none" stroke="#1abcff" stroke-linecap="round" stroke-width="2" />
                <line id="Line_83" data-name="Line 83" y2="15" transform="translate(32.5 13.5)" fill="none" stroke="#27aae2" stroke-linecap="round" stroke-width="2" />
                <g id="Ellipse_61" data-name="Ellipse 61" transform="translate(11 18)" fill="#001a38" stroke="#1abcff" stroke-width="2">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                    <circle cx="3.5" cy="3.5" r="2.5" fill="none" />
                </g>
                <g id="Ellipse_62" data-name="Ellipse 62" transform="translate(20 16)" fill="#001a38" stroke="#1abcff" stroke-width="2">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                    <circle cx="3.5" cy="3.5" r="2.5" fill="none" />
                </g>
                <g id="Ellipse_63" data-name="Ellipse 63" transform="translate(29 20)" fill="#001a38" stroke="#27aae2" stroke-width="2">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                    <circle cx="3.5" cy="3.5" r="2.5" fill="none" />
                </g>
            </g>
        </svg>

    );

}

export default FiltertoggleIcon;