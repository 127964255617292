import React from "react";


const PasswordactiveIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.404" height="14" viewBox="0 0 22.404 14">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1687" data-name="Rectangle 1687" width="22.404" height="14" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </clipPath>
            </defs>
            <g id="Group_15959" data-name="Group 15959" clip-path="url(#clip-path)">
                <path id="Path_10335" data-name="Path 10335" d="M1.2,7s4-6,10-6,10,6,10,6-4,6-10,6S1.2,7,1.2,7Z" fill="none" stroke="#555" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
                <circle id="Ellipse_37" data-name="Ellipse 37" cx="4" cy="4" r="4" transform="translate(7.202 1)" fill="none" stroke="#555" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
            </g>
        </svg>





    );

}

export default PasswordactiveIcon;