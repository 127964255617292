import React from 'react';

const KOInputPts = props => {
  return (
    <div className="form-group">
        <div className="inputgroup">
            <label>{props.label}</label>
            <input
                className={props.class ? `form-control ${props.class}` : "form-control"}
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                error={props.error}
                autoComplete={props.autoComplete}
                onChange={props.onChange}
                onBlur={props.onBlur}
                minLength={props.minLength}
                maxLength={props.maxLength}
                disabled={props.disabled}
                background-color={props.bgColor}
                ref={props.txtRef}
                step = {props.step}
            />
            <span className='pts'>Pts</span>
        </div>
      {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
    </div>
  );
}

export default (KOInputPts);
