import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import Header from "../components/Header";
import KOInput from "../components/KOInput";
import Copyright from '../components/Copyright';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";

const Emailrequest = (props) => {
    const navigate = useNavigate();


	return (
		<>
			<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container request-wrap email-wrap">
					<div className="title-sec">
                        <h4>Provide your email address</h4>
                        <p>Oops, that didn't work! We were unable to authenticate you through Facebook. Please use your email address instead.</p>
                    </div>
					<Form className="">
						<KOInput
							id="email"
							label="Email"
							name="email"
							type="text"
                            placeholder="Enter your email"
                            maxLength="80"
						/>
					</Form>
					<div className="account-create">
						<Button variant="primary" onClick={() => { navigate(pageURLs.otpverification); }}>Continue</Button>
						
					</div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default Emailrequest;
