import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectCounterValue from '../appState/counterState/counterSelector';
import * as counterAction from '../appState/counterState/counterAction';
import useUserState from "../appState/userState/useUserState";

export function CounterNew() {
    const count = useSelector(selectCounterValue);
    const dispatch = useDispatch();

    const user = useUserState();
    const userName = user.data.FirstName;

    return (
        <div>
            <div>
                <button aria-label="Increment value" onClick={() => dispatch(counterAction.Increment())}>+</button>
                <span>{count}</span>
                <button aria-label="Decrement value" onClick={() => dispatch(counterAction.Decrement())}>-</button>
            </div>

            <div><p>{userName}</p></div>

            <div>
                <button aria-label="Login" onClick={() => user.action.login('User name', 'Password')}>Login</button>
                <button aria-label="Logout" onClick={() => user.action.logout()}>Logout</button>
            </div>
        </div>
    );
}

export default CounterNew;