import { counterActionType } from "./counterActionType";

const initialState = { value: 0 }

function counterReducer(state = initialState, action) {
    switch (action.type) {
        case counterActionType.Increment:
            return { ...state, value: state.value + 1 };
        case counterActionType.Decrement:
            return { ...state, value: state.value - 1 };
        default:
            return state;
    }
}

export default counterReducer;