import React, { useState, useEffect, useRef, useMemo , useCallback} from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import LiveTimer from "./LiveTimer";

import * as commonService from "../services/commonService";
import { pageURLs } from "../appData/pageURLs";
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import useUserState from "../appState/userState/useUserState";
import { store } from '../appState/store';
import EliminatedWeekly from "./EliminatedWeekly";
import moment from "moment"

const ContestDetailsBeforeJoin = (props) => {

    const user = useUserState();

    let { id } = useParams()
    let contestId = parseInt(id);

    const [enableJoinContest, setEnableJoinContest] = useState(true);

    if (!contestId) {
        navigate(pageURLs.root);
    }

    const navigate = useNavigate();
    const siteState = useSiteState();
    const userState = useUserState();
    const userId = userState.data.user.user_id;
    //if user is already logged in we need to redirect to default details page
    if (userId) {
        navigate(pageURLs.joincontest+'/'+contestId);
    }

    localStorage.removeItem("lineupsArray");


   

    const [Flag_ShowCollapse, setFlag_ShowCollapse] = useState(false);

    const getCollapseClasses = () => {
        let result = "contest-details";
        if (Flag_ShowCollapse) {
            result += " collapsed";
        } else {
            result += " collapsable";
        }
        return result;
    }

    //Weekly elimination formula

    const [stateContestData, setStateContestData] = useState([]);
    useEffect(() => {
        if (props.contestData && props.contestData.contestDetails && props.contestData.contestDetails[0]) {
            siteState.data.Lobby.currentContestData = props.contestData.contestDetails[0];

            setStateContestData(props.contestData.contestDetails[0])

        } else {
            setStateContestData([]);
        }
    }, [props.contestData]);


    /*
     * Calculate the weekly half
     */
    const getWeeklyHalves = (totalWeeks) =>  {
        let halfs = (totalWeeks > 1) ? (totalWeeks/2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf" : firstHalf,
            "secondHalf" : secondHalf
        }
    }

    const getNumberOfEliminationPerWeek = (totalWeeks , totalContestants ) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf/halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1)? firstWeekSubEliminatorCalculator: 0;
        return {
            'playersAliveSecondHalf' : numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf' : numPlayersEliminatedFirsHalf,
            'firstWeekElimination' : firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek' : firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks' : halves.firstHalf,
            'secondHalfWeeks' : halves.secondHalf,
            'minimumUsersRequired' : totalWeeks+1
        }
    }   

    const calculate = (totalContestants , totalWeeks ) => {
        if (totalContestants === 0 || totalWeeks === 0) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }

    const weeklyEliminationSplit = calculate( stateContestData.max_users,stateContestData.duration_in_weeks);
    //console.log("X", x);


    // let totalWeeks = 15;// stateContestData.duration_in_weeks;
    let totalWeeks = stateContestData.duration_in_weeks;
    let maxParticipants = stateContestData.max_users;
    //let maxParticipants =  2211;
    let weekDivident = totalWeeks / 2;
    let firstHalfWeekCount = Math.floor(weekDivident);//First half weeks number
    let secondHalfWeekCount = Math.ceil(weekDivident);//Second half weeks number
    let secondPartWeekStartFrom = 2;
    let thirdPartWeekStartFrom = parseInt(firstHalfWeekCount) + 1;
    thirdPartWeekStartFrom = stateContestData.contest_start_week == 1 ?thirdPartWeekStartFrom : (thirdPartWeekStartFrom+stateContestData.contest_start_week-1);
    let secondHalfParticipants = secondHalfWeekCount + 1;
    let secondHalfEliminatorCount = secondHalfWeekCount;
    let firstHalfParticipants = parseInt(maxParticipants) - parseInt(secondHalfParticipants);
    let firsthalfWeeklyElimintorCount = Math.floor(parseInt(firstHalfParticipants) / parseInt(firstHalfWeekCount));
    let balanceElimintorInFirstWeek = parseInt(firsthalfWeeklyElimintorCount) * parseInt(firstHalfWeekCount);
    let balanceElimintorCount = parseInt(firstHalfParticipants) - parseInt(balanceElimintorInFirstWeek);
    let firstWeekEliminatorCount = parseInt(firsthalfWeeklyElimintorCount) + parseInt(balanceElimintorCount);
    let firsthalfSecondSetWeeklyElimintorCount = parseInt(firsthalfWeeklyElimintorCount) * (parseInt(firstHalfWeekCount) - 1);
    if (totalWeeks == 1) {
        firstWeekEliminatorCount = parseInt(maxParticipants) - 1;
    }
    const locale = 'en-US';
    let dateCurrent = new Date(stateContestData.contest_start_time);
    let estNewDate = dateCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    // const contestStartDate = commonService.convertUTCDateToLocalDate(new Date(estNewDate));
    // const contestDate = contestStartDate.getDate();
    // const contestMonth = contestStartDate.toLocaleDateString(locale, { month: 'long', timeZone: "UTC" });
    // const contesttime = contestStartDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: "UTC" });
    let contestDate;
    if(stateContestData) {
        contestDate = moment.utc(stateContestData.contest_start_time).format('MMMM DD | h:mm A');
    }
    let dtCurrent = new Date(stateContestData.contest_start_time);
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    let dtEst = new Date(estDate);
    const countDownDate = commonService.getTimeDifference(dtEst);


    const [stateContestUserData, setStateContestUserData] = useState([]);
    useEffect(() => {
        if (props.contestData && props.contestData.contestUserData && props.contestData.contestUserData[0]) {
            setStateContestUserData(props.contestData.contestUserData[0])
        } else {
            setStateContestUserData([]);
        }
    }, [props.contestData]);



    const joinContest = (e) => {
        sessionStorage.setItem('loginContestId',contestId)
        navigate(pageURLs.root);
    }
    const contestStatusChanged = (contestDisplayStatus) => {
        if (contestDisplayStatus == 2)
            setEnableJoinContest(false)
        else
            setEnableJoinContest(true)
    }

    const [totalContestLineUp, setTotalContestLineUp] = useState(0)
    useEffect(() => {
      setTotalContestLineUp(stateContestData.total_lineups);
    }, [props.totalContestLineUp, stateContestData])

    useEffect(() => {
        if (enableJoinContest && stateContestData.total_lineups == stateContestData.max_users)
            setEnableJoinContest(false)
    }, [stateContestData, enableJoinContest])





    return (
        <div className={getCollapseClasses()}>
            <Button className="toggle-arrow m-show" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}><i className="ic-chevron"></i></Button>
            <div className="general-info">
                <div className="title-sec">
                    <h3>{stateContestData.contest_name}</h3>
                    <div className="title-group">
                        {(stateContestData.contest_type_id === 1) && <span className="bg-label bg-standard">Standard</span>}
                        {(stateContestData.contest_type_id === 2) && <span className="bg-label bg-knockout">Knockout Plus</span>}
                        <div className="m-show"><LiveTimer contestStatusChanged={contestStatusChanged} Status={stateContestData.contest_status_id} countDownDate={countDownDate} /></div>
                    </div>
                </div>
                <div className="info-data">
                    <div className="form-group">
                        <label>Entry Fee</label>
                        <p>{commonService.toCurrency(stateContestData.entry_fee)}</p>
                    </div>
                    <div className="form-group">
                        <label>Prize</label>
                        <p>{commonService.toCurrency(stateContestData.total_prize_amt)}</p>
                    </div>
                    <div className="form-group">
                        <label>Entries</label>
                        <p>{stateContestData.total_lineups}/{stateContestData.max_users}</p>
                    </div>

                    <div className="form-group">
                        <label>  Duration</label>
                        <p>Week {stateContestData.contest_start_week}     {stateContestData.contest_start_week != stateContestData.contest_end_week? ' - '+stateContestData.contest_end_week : ""}</p>
                    </div>
                    <div className="form-group">
                        <label>  Max Lineups</label>
                        <p>{stateContestData.max_lineup_per_user}</p>
                    </div>
                    {(stateContestData.total_weekly_winners > 0 && stateContestData.contest_type_id === 2) && <div className="form-group">
                        <label>Weekly Winners</label>
                        <p>{stateContestData.total_weekly_winners}</p>
                    </div>}
                    {(stateContestData.total_season_winners > 0 && stateContestData.contest_type_id === 2) && <div className="form-group">
                        <label>Season Sc. Winners</label>
                        <p>{stateContestData.total_season_winners}</p>
                    </div>}
                </div>
                <div className="info-data game-info-data">
                    <div className="form-group">
                        <label>League Scoring</label>
                        <p>Custom Scoring Rules</p>
                    </div>
                    <div className="form-group">
                        <label>League Strategy</label>
                        <p>Custom Positions</p>
                    </div>
                    <div className="game-formation">
                        <div className="game-position"><p><span>--</span>QB</p></div>
                        <div className="game-position"><p><span>--</span>RB</p></div>
                        <div className="game-position"><p><span>--</span>WR</p></div>
                        <div className="game-position"><p><span>--</span>FLEX</p></div>
                        <div className="game-position"><p><span>--</span>K</p></div>
                        <div className="game-position"><p><span>--</span>DST</p></div>
                    </div>
                </div>
                <div className="note">
                    <p>Eliminated Weekly*:</p>
                    <ul>

                        
                        <EliminatedWeekly lineupWeekNumber='4' />
                    </ul>

                </div>
            </div>

            <div className="time-info">
                <div className="d-show">
                    <LiveTimer contestStatusChanged={contestStatusChanged} Status={stateContestData.contest_status_id} countDownDate={countDownDate} /></div>
                <p>{contestDate} EST</p>


                {((stateContestData.contest_status_id === 3 && (!stateContestUserData || !stateContestUserData.si_contest_user_id || stateContestUserData.contest_user_status_id == 3 || !stateContestUserData.contest_user_status_id) && parseInt(stateContestData.max_users) > totalContestLineUp) ) ?
                    <Button variant="success" onClick={joinContest} >Join Contest ({commonService.toCurrency(stateContestData.entry_fee)})</Button> : null
                }
            </div>
        </div>
    );
};

export default ContestDetailsBeforeJoin;
