import * as userGamesService from "../services/userGamesService";

export const saveUserGames = async (payLoad) => {
  const response = await userGamesService.saveUserGames(payLoad);
  return response;
}

export const removeUserGames = async (leagueContestId) => {
  const response = await userGamesService.removeUserGames(leagueContestId);
  return response;
}

export const fetchLineUpStrategy = async (leagueContestId) => {
  const response = await userGamesService.fetchLineUpStrategy(leagueContestId);
  return response;
}

export const fetchInvitedEmails = async (leagueContestId) => {
  const response = await userGamesService.fetchInvitedEmails(leagueContestId);
  return response;
}
export const getRecievedMessages = async (messageListPageParams) => {
  const response = await userGamesService.getRecievedMessages(messageListPageParams);
  return response;
}
export const readLeagueMessage = async (contestId,readOn) => {
  const response = await userGamesService.readLeagueMessage(contestId,readOn);
  return response;
}
export const getSendMessages = async (messageListPageParams) => {
  const response = await userGamesService.getSendMessages(messageListPageParams);
  return response;
}
export const sendLeaugeMessage = async (contestId,message) => {
  const response = await userGamesService.sendLeaugeMessage(contestId,message);
  return response;
}
