import React, { useState, useEffect, useRef } from "react";
import {Badge} from "react-bootstrap";
import * as contestService from "../services/contestService";

const TopPlayers = (props) => {
    let start_week = (props.isuserGame) ?  props.contestData.contestDetails.contest_start_week : props.contestData.contestDetails[0].contest_start_week;
    let end_week = (props.isuserGame) ? props.contestData.contestDetails.contest_end_week  : props.contestData.contestDetails[0].contest_end_week;
    const [topPlayers, setTopPlayers] = useState([]);
    const [topPlayersLoaded, setTopPlayersLoaded] = useState(false);
    const [showLoader, setLoader] = useState(false);
    const sortList = ["QB", "RB", "WR", "TE", "K","DF"];
    useEffect(async () => {
        fetchTopPlayers();
    }, [])
    
    const fetchTopPlayers = async () => {
        setLoader(true)
        await contestService.getTopPlayers()
        .then(
            posterResponse => {
            if (posterResponse.errorCode == 200) {
                topPlayerDataPopulate(posterResponse.data.playerData);
                setTopPlayersLoaded(true)
                setLoader(false)
            } else {
                setTopPlayers([]);
                setLoader(false)
            }
        });
    }

    const topPlayerDataPopulate = async (topPlayerData) => {
        
        let topPlayerDataArray = [];
        const topPlayers = topPlayerData.sort((a, b) => {
            return (
                sortList.indexOf(a.Position) - sortList.indexOf(b.Position)
            );
        });
        console.log(topPlayers)
        for (let k = 0; k < topPlayers.length; k++) {
            let week = topPlayers[k].Week;
            if (!topPlayerDataArray[week]){ 
                topPlayerDataArray[week] = []; 
            }
            
            
            topPlayerDataArray[week].push(topPlayers[k])
        }
       
        setTopPlayers(topPlayerDataArray);
    }

    let classNames = {
        'QB' : "quarterback",
        'RB' : "rightback",
        'WR' : "widereceiver",
        'TE' : "tightend",
        'K' : "kicker",
        'DF' : "defence"
    }

    const displayTopPlayers = (playerData) => {
        let playerDataRow = [];
        playerData.map((item, index) => (
           
            playerDataRow.push(
                <div className="week-list">
                    <h6>Week {index}</h6>
                    <div className="position-wrap">
                        <h5>Top Scorers by Position</h5>
                        {item.map((sub_item, sub_index) => (
                            <div className="player-info">
                                <div className="name"><Badge bg="light" className={classNames[sub_item.Position]}>{sub_item.Position}</Badge><p>{(sub_item.is_team) ? sub_item.TeamName : sub_item.PlayerName}</p></div>
                                <div className="point">{sub_item.WeeklyScore}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        ))

        return playerDataRow;
    }


    return (
        
        <div className="top-player-wrap">
            {showLoader && <div className="playercard" style={{ textAlign: "center" }}><div className="loader-wrap"><div className="loader"></div></div></div>}
            {topPlayers.length ?  <div className="week-player-list">
                {displayTopPlayers(topPlayers)}
            </div> :  ''}
       
            {(!topPlayers.length && topPlayersLoaded) ? <div className="playercard" style={{ textAlign: "center" }}><span style={{ color: "red" }}>No data to display.</span></div> : ''}
        </div>
    )
}

export default TopPlayers;
