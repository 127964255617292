import React, { useState } from "react";
import { Button, Form , Modal} from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import { pageURLs } from "../appData/pageURLs";
import contesticon from '../assets/contest-star.svg';
import "../styles/createleague.scss"
import CreateLeagueStepper from "./CreateLeagueStepper";
import { useNavigate } from "react-router-dom";
import * as userGamesController from "../controller/userGamesController";

const CreateLeague = (props) => {
    const [showLeagueSuccess, setShowLeagueSuccess] = useState(false);
    const handleShowLeagueSuccess = () => setShowLeagueSuccess(true);
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [leagueTypeName, setLeagueTypeName] = useState('Standard Knockout');
    const [leagueWeekFrom, setLeagueWeekFrom] = useState(0);
    const [leagueWeekTo, setLeagueWeekTo] = useState(0);
    const [leagueType, setLeagueType] = useState('');
    const [entryType, setEntryType] = useState('S');
    const [weeklyLeadersCount, setWeeklyLeadersCount] = useState(0);
    const [seasonLeadersCount, setSeasonLeadersCount] = useState(0);
    const [lineUpNumber, setLineUpNumber] = useState(0);
    const [showDeleteLeagueOption, setDeleteLeagueOption] = useState(false);
    const [maxEntries, setMaxEntries] = useState(0);
    const [joinedCount, setJoinedCount] = useState(0);
    const [leagueStrategy, setLeagueStrategy] = useState('--');
    const [qrPositionPlayersCount, setQRPositionPlayersCount] = useState('--');
    const [rbPositionPlayersCount, setRBPositionPlayersCount] = useState('--');
    const [wrPositionPlayersCount, setWRPositionPlayersCount] = useState('--');
    const [flexPositionPlayersCount, setFLEXPositionPlayersCount] = useState('--');
    const [kPositionPlayersCount, setKPositionPlayersCount] = useState('--');
    const [defPositionPlayersCount, setDEFPositionPlayersCount] = useState('--');
    const [tePositionPlayersCount, setTEPositionPlayersCount] = useState('--');
    const [leagueContestId, setContestId] = useState(0);
    const [leagueScoringType, setLeagueScoringType] = useState('--');
    const [userleagueName, setUserleagueName] = useState("");
    const [UserSiteGameData, setUserSiteGameData] = useState([]);
    const [showLeagueDeleteConfirmModal, setShowLeagueDeleteConfirmModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const deleteConfirmModalClose = () => setShowLeagueDeleteConfirmModal(false);
    const navigate = useNavigate();

    const getSideMenuClasses = () => {
        let result = "koblock-right league-details";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    //Show remove league confirmation popup
    const handleDeleteLeauge = () => {
        setShowLeagueDeleteConfirmModal(true)
    };
    //Remove league
    const removeLeague = async() => {
        if(parseInt(leagueContestId)) {
            setShowLoader(true)
            let result = await userGamesController.removeUserGames(leagueContestId);
            if(result.errorCode == 200) {
                navigate(pageURLs.manageleague)
            } else {
                setShowLoader(false);
                alert(result.message)
            }
        }
    }

    const showLeagueTypeName = (type, weeklyleaders, seasonleaders) => {
        let leagueTypeName = '-';
        if (type == 'K') {
            leagueTypeName = 'Knockout Plus';
        } else if (type == 'S') {
            leagueTypeName = 'Standard Knockout';
        }
        setLeagueTypeName(leagueTypeName);
        setLeagueType(type);
        setWeeklyLeadersCount(weeklyleaders);
        setSeasonLeadersCount(seasonleaders);
    }

    const showLeagueDuration = (weekFrom, WeekEnd) => {
        setLeagueWeekFrom(weekFrom);
        setLeagueWeekTo(WeekEnd);
    }

    const leadersPreviewDivClass = () => {
        let divClassName = 'muliple-info';
        if (leagueType != 'K') {
            divClassName = 'hide';
        }
        return divClassName;
    }

    const setLeagueCategory = (categoryType, lineUpNumberVal) => {
        setLineUpNumber(lineUpNumberVal);
        setEntryType(categoryType);
    }

    const setEntriesData = (maxEntry) => {
        setMaxEntries(maxEntry);
    }

    const setLineUpStrategy = (type, positionData) => {
        let strategyTypeName = '-';
        if (type == 'standardposition') {
            strategyTypeName = 'Standard Positions';
        } else {
            strategyTypeName = 'Custom Positions';
        }
        setLeagueStrategy(strategyTypeName);
        setQRPositionPlayersCount(0);
        setRBPositionPlayersCount(0);
        setWRPositionPlayersCount(0);
        setTEPositionPlayersCount(0);
        setFLEXPositionPlayersCount(0);
        setKPositionPlayersCount(0);
        setDEFPositionPlayersCount(0);

        positionData.map((item, index) => {
            switch (item.key) {
                case 'quarterback':
                    setQRPositionPlayersCount(item.value);
                    break;
                case 'runningback':
                    setRBPositionPlayersCount(item.value);
                    break;
                case 'widereceiver':
                    setWRPositionPlayersCount(item.value);
                    break;
                case 'tightend':
                    setTEPositionPlayersCount(item.value);
                    break;
                case 'flex':
                    setFLEXPositionPlayersCount(item.value);
                    break;
                case 'kicker':
                    setKPositionPlayersCount(item.value);
                    break;
                case 'defense':
                    setDEFPositionPlayersCount(item.value);
                    break;
            }
        });
    }

    const updateLeagueContestId = (contest_id) => {
        setContestId(contest_id);
        if (parseInt(contest_id)) {
            setDeleteLeagueOption(true);
        } else {
            setDeleteLeagueOption(false);
        }
    }

    const setScoringRuleType = (rule_type) => {
        let scoringTypeName = '--';
        if (rule_type == 'standardscorerule') {
            scoringTypeName = 'Standard Scoring Rules';
        } else {
            scoringTypeName = 'Custom Scoring Rules';
        }
        setLeagueScoringType(scoringTypeName);
    }

    const updateUserLeaguename = (leaguename) => {
        setUserleagueName(leaguename);
    }

    const updateFullUserGameData = (fullFormData, step) =>{
        let formExistingFormArray = [...UserSiteGameData];
        formExistingFormArray[step] = fullFormData;
        if(step == 3) {
            setUserSiteGameData([]);
            setContestId(0)
        } else {
            setUserSiteGameData(formExistingFormArray);
        }
    }

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.createleague} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft create-league">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        {showLoader && <div className="position-select-field"><div className="loader-wrap"><div className="loader"></div></div></div>}
                        <CreateLeagueStepper handleShowLeagueSuccess={handleShowLeagueSuccess} showLeagueTypeName={showLeagueTypeName} showLeagueDuration={showLeagueDuration} setLeagueCategory={setLeagueCategory} setEntriesData={setEntriesData} setLineUpStrategy={setLineUpStrategy} updateLeagueContestId={updateLeagueContestId} contest_id={leagueContestId} setScoringRuleType={setScoringRuleType} userleagueName={userleagueName} updateUserLeaguename={updateUserLeaguename} updateFullUserGameData = {updateFullUserGameData} UserSiteGameData = {UserSiteGameData} paymentStatus = {false} isJoinedOtherUsers = {false} totalJoinedCount = {0}/>
                        <div className="create-success" style={{ display: showLeagueSuccess ? "block" : "none" }}>
                            <div className="contest-success">
                                <div className="contest-icon"><img src={contesticon} alt="KO" /></div>
                                <p>User contest</p>
                                <h2>{userleagueName}</h2>
                                <p>has been created and your invites have been sent</p>
                                <Button variant="primary" onClick={() => { navigate(pageURLs.manageleague); }}>Manage League</Button>
                            </div>
                        </div>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        {showDeleteLeagueOption && <div className="btn-sec"><Button variant="light" className='btn-delete' onClick={handleDeleteLeauge}>  Delete League</Button></div>}
                        <div className="content-block-rht">
                            <div className="detail-info-data">
                                <h6>Preview of the League</h6>
                                <p>Status: Unpublished</p>
                            </div>
                            <div className="detail-info-data">
                                <label>League Type</label>
                                <h6>{leagueTypeName}</h6>
                            </div>
                            <div className={leadersPreviewDivClass()}>
                                <div className="detail-info-data">
                                    <label>Weekly Leaders</label>
                                    <h3>{weeklyLeadersCount}</h3>
                                </div>
                                <div className="detail-info-data">
                                    <label>Season Sc. Leaders</label>
                                    <h3>{seasonLeadersCount}</h3>
                                </div>
                            </div>
                            <div className="muliple-info">
                                <div className="detail-info-data">
                                    <label>League Duration</label>
                                    <h6>Week {leagueWeekFrom} - Week {leagueWeekTo}</h6>
                                </div>
                                <div className="detail-info-data">
                                    <label>Entries</label>
                                    <h6>{joinedCount}/{maxEntries}</h6>
                                </div>
                            </div>
                            <div className="detail-info-data">
                                <label>League Category</label>
                                <h6><span className="badge bg-m">{entryType}</span>{entryType == 'M' && ` (up to ${lineUpNumber} Lineups)`}</h6>
                            </div>
                            <div className="detail-info-data">
                                <label>Format</label>
                                <h6>{leagueStrategy}</h6>
                                <div className="game-formation">
                                    <div className="game-position"><p><span>{qrPositionPlayersCount}</span>QB</p></div>
                                    <div className="game-position"><p><span>{rbPositionPlayersCount}</span>RB</p></div>
                                    <div className="game-position"><p><span>{wrPositionPlayersCount}</span>WR</p></div>
                                    <div className="game-position"><p><span>{tePositionPlayersCount}</span>TE</p></div>
                                    <div className="game-position"><p><span>{flexPositionPlayersCount}</span>FLEX</p></div>
                                    <div className="game-position"><p><span>{kPositionPlayersCount}</span>K</p></div>
                                    <div className="game-position"><p><span>{defPositionPlayersCount}</span>DST</p></div>
                                </div>
                            </div>
                            <div className="detail-info-data">
                                <label>League Scoring</label>
                                <h6>{leagueScoringType}</h6>
                            </div>
                        </div>
                    </div>

                    <Modal show={showLeagueDeleteConfirmModal} onHide={deleteConfirmModalClose} className="modal-predict">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <h4 className="modal-title">Do you want to delete this league?</h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={deleteConfirmModalClose}>Cancel</Button>
                            <Button variant="primary" onClick={() => removeLeague()} >Yes</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default CreateLeague;
