import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Viewbtn from "../components/Viewbtn";
import Paginate from "../components/Paginate";
import "../components/contestgrid.scss";
import TrophyIcon from "../assets/icons/trophy";
import * as commonService from "../services/commonService";
import useCountdown from "../appState/counterState/useCountdown";
import { NavLink } from "react-router-dom";
import { pageURLs } from "../appData/pageURLs";
import useSiteState from '../appState/siteState/useSiteState';

const ContestGrid = (props) => {
    const siteState = useSiteState();

    const getContests = (contestList) => {
        if (contestList.length > 0) {
            return contestList.map((item, inx) => (
                <Col md={4} key={'inx' + inx}>
                    <ContestGridItem key={item.Id} item={item} isUserGame={props.isUserGame} showManage={props.showManage} />
                </Col>
            ));
        }
        else {
            return (<>
                <p>{siteState.data.Lobby.showNoRecordsFound ? 'No records to display.' : ""}</p>
            </>
            );
        }
    };
    const pageNumberChange = (page) => {
        props.pageChange(page);
    }

    return (
        <>
            <Row>
                {getContests(props.contestData)}
            </Row>
            {props.paginationData ? <Paginate paginationData={props.paginationData} pageRows={props.contestData.length} onClick={pageNumberChange} /> : ""}
        </>
    );

};

const ContestGridItem = (props) => {
    let grid_container_class = 'contest-grid-block';
    //let contestStartDate = commonService.convertUTCDateToLocalDate(new Date(props.item.StartTime));
    let dtCurrent = new Date(props.item.StartTime);
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    let dtEst = new Date(estDate);
    let countDownDate = commonService.getTimeDifference(dtEst);
    if (props.item.ContestStatus === 4) {
        grid_container_class += " disabled";
    }
    if (props.item.contest_user_status_id != 3 && props.item.si_contest_user_id > 0) {
        grid_container_class += " grid-join";
    }
    var details_link = "";
    if (props.isUserGame) {
        details_link = pageURLs.usergamejoincontest + '/' + props.item.Id;
    }
    else {
        details_link = pageURLs.joincontest + '/' + props.item.Id;
    }

    if (props.item.contest_user_status_id != 3 && props.item.si_contest_user_id > 0 && !props.isUserGame) {
        details_link = pageURLs.createlineup + '/' + props.item.Id;
    }
    else if (props.item.contest_user_status_id != 3 && props.item.si_contest_user_id > 0 && props.isUserGame) {
        details_link = pageURLs.usergamecreatelineup + '/' + props.item.Id;
    }

    if (props.isUserGame && props.showManage) {
        details_link = pageURLs.leaguedetails + '/' + props.item.Id;
        if (props.item.EntryCount == 1 && !props.item.IsPublished) {
            details_link = pageURLs.editleague + '/' + props.item.Id;
        }
    }

    return (
        <div className={grid_container_class}>
            
            {/* {props.item.contest_user_status_id == 1 && props.item.si_contest_user_id > 0 && <span className="joined">joined</span>} */}
            {props.item.contest_user_status_id == 2 && props.item.si_contest_user_id > 0 && <span className="eliminated-label">eliminated</span>}

            {props.item.ContestStatus == 2 && <span className="ripple"></span>}
            <div className="title">
                {props.isUserGame ? <NavLink to={{ pathname: `${pageURLs.usergamejoincontest}/${props.item.Id}` }} >
                    {props.item.Name}
                </NavLink>
                    : <NavLink to={{ pathname: `${pageURLs.joincontest}/${props.item.Id}` }} >
                        {props.item.Name}
                    </NavLink>
                }

                {/* <p className="status-info">Invited by <span>Robert Jakes</span></p> */}
                {/* <p className="status-info publish">Status <span>Published</span></p> */}
                {/* <p className="status-info unpublish">Status <span>Unpublished</span></p> */}
            </div>
            <div className="contest-info">
                <div className="group-badge">
                    {props.item.IsWinner && <i className="trophy-icon"></i>}
                    {(props.item.Type === "Standard") && <span className="bg-label bg-standard">Standard</span>}
                    {(props.item.Type === "Knockoutplus") && <span className="bg-label bg-knockout">Knockout Plus</span>}
                </div>
                <div className="activity">
                    {(props.item.ContestStatus === 2) && <p className="clr-active">Live Contest</p>}
                    {(props.item.ContestStatus === 3) && <><span>Live in</span><p> <UpcomingContestTimer hour={(countDownDate.hours) ? countDownDate.hours : 0} minute={(countDownDate.minutes) ? countDownDate.minutes : 0} second={(countDownDate.seconds) ? countDownDate.seconds : 0} /></p></>}
                    {(props.item.ContestStatus === 4 || props.item.ContestStatus === 7) && <p className="clr-expire">Expired</p>}
                    {(props.item.ContestStatus === 5) && <p className="clr-complete">Past</p>}
                    {(props.item.ContestStatus === 8 && props.showManage) && <td align="right" className="clr-complete"><div className="con-status">Unpublished</div></td>}

                </div>
            </div>
            <div className="contest-data">

                <div className="data-detail">
                    <label>Categories</label>
                    {props.item.IsMultiple && <span className="badge bg-m">M</span>}
                    {!props.item.IsMultiple && <span className="badge bg-m">S</span>}
                    {props.item.IsGuaranteed && <span className="badge bg-g">G</span>}
                    {props.item && props.item.EntryFeeStatus == 'F' && <span className="badge bg-f">F</span>}
                </div>
                <div className="data-detail">
                    <label>Entries</label>
                    <p>{props.item.EntryCount}/{props.item.EntryCountMax}</p>
                </div>
                {props.isUserGame ? <div className="data-detail text-end">
                    <NavLink
                        to={{
                            pathname: `${details_link}`
                        }}
                    >
                        <Viewbtn />
                    </NavLink>
                </div> : ""}
            </div>
            <div className="contest-data justify-content-between">
                {!props.isUserGame ? <div className="data-detail">
                    <label>Price</label>
                    <p>{commonService.toCurrency(props.item.Prize)}</p>
                </div> : ""}
                {!props.isUserGame ? <div className="data-detail">
                    <label>Entry Fee</label>
                    <p>{commonService.toCurrency(props.item.EntryFee)}</p>

                </div> : ""}
                {!props.isUserGame ? <div className="data-detail text-end">
                    <NavLink
                        to={{
                            pathname: `${details_link}`
                        }}
                    >
                        <Viewbtn />
                    </NavLink>
                    {/* <Button variant="outline-primary" className="manage-btn" onClick={() => { alert("Not Implemented."); }}>Manage</Button> */}
                </div> : ""}
            </div>
        </div>
    );
};

const UpcomingContestTimer = (props) => {
    let countDownHours = (props.hour > 0) ? props.hour : 0;
    let countDownMinutes = (props.hour > 0) ? props.minute : 0;
    let countDownSeconds = (props.hour > 0) ? props.second : 0;
    const [hour, setHour] = useState(countDownHours);
    const [minute, setMinute] = useState(countDownMinutes);
    const [second, setSecond] = useState(countDownSeconds);

    const getTime = () => {
        return { hour: countDownHours, minute: countDownMinutes, second: countDownSeconds };
    }

    useCountdown(() => {
        setHour(countDownHours);
        setMinute(countDownMinutes);
        setSecond(countDownSeconds);
    }, getTime()
    );

    if(hour > 24){
        const  days = Math.floor(hour / (24));
        const remainingHours = hour % 24; 
        return <>{days}d {remainingHours}h {minute}m {second}s</>;  
     } else {
         return <>{hour}h {minute}m {second}s</>;
     }
};

export default ContestGrid;
