import { useState, useEffect } from "react";
import { useGeolocated } from "react-geolocated";

const useGeoLocation = () => {
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: { latitude: "", longitude: "", altitude : "", accuracy : "" },
    });

    const onSuccess = (location) => {
        //console.log(location)
        setLocation({
            loaded: true,
            errorFlag : false,
            coordinates: {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                altitude: location.coords.accuracy,
                accuracy: location.coords.altitude,
            },
        });
    };

    const onError = (error) => {
        setLocation({
            loaded: true,
            errorFlag : true,
            error: {
                code: error.code,
                message: error.message,
            },
        });
    };

    const {isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
            watchLocationPermissionChange : true,
            onSuccess, 
            onError
    });

    useEffect(() => {
        if(location.loaded && location.errorFlag && isGeolocationEnabled) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        }
    }, [location]);
    
    return location;
};

export default useGeoLocation;