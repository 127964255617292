import { useDispatch, useSelector } from "react-redux";
import * as userReducer from "./userReducer";

function useUserState() {
    let dispatch = useDispatch();

    let login = async (userName, password) => {
        return dispatch(userReducer.userLogin(userName, password));
    }

    let logout = async () => {
        return dispatch(userReducer.userLogout());
    }
    let registration = async (payload) => {
        return dispatch(userReducer.userRegistration(payload));
    }
    let fbLogin = async (email, accessToken, deviceType, fbId, isMailOpted = 0) => {
        return dispatch(userReducer.fbUserLogin(email, accessToken, deviceType, fbId, isMailOpted));
    }
    let googleLogin = async (email, accessToken, deviceType, idToken, googleId, isMailOpted = 0) => {
        return dispatch(userReducer.googleUserLogin(email, accessToken, deviceType, idToken, googleId, isMailOpted));
    }
    let getUserData = async (userId) => {
        return dispatch(userReducer.getUserData(userId));
    }

    let SetTemporaryProfilePic = async (profileImage) => {
        return dispatch(userReducer.SetTemporaryProfilePic(profileImage));
    }

    let setUserData = async (payLoad) => {
        return dispatch(userReducer.setUserData(payLoad));
    }

    let setPaymentProcessedStatus = async (status) => {
        return dispatch(userReducer.setPaymentProcessedStatus(status));
    }

    let SetUserStateLeagalData = async (payLoad) => {
        return dispatch(userReducer.SetUserStateLeagalData(payLoad));
    }

    let SetTsevoReverificationData = async (payLoad) => {
        return dispatch(userReducer.SetTsevoReverificationData(payLoad));
    }

    let data = useSelector((state) => state.user);
    let action = { login, logout, registration, fbLogin, googleLogin, getUserData, SetTemporaryProfilePic, setUserData, setPaymentProcessedStatus, SetUserStateLeagalData, SetTsevoReverificationData};

    return { data, action };
}

export default useUserState;




