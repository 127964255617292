import React, { useState, useEffect, useRef, Component } from "react";
import { Button } from "react-bootstrap";
import KOInput from "../components/KOInput";
import {store} from '../appState/store';
import * as userController from "../controller/userController";
const ChangeMailStep1 = (props) => {
    const userData = store.getState().user
    const refCurrentEmail = useRef(null);
    const [currentemail, setCurrentEmail] = useState(userData.user?.email);
    const [currentemailError, setCurrentEmailError] = useState("");

    const handleCurrentEmailChange = (e) => {
        setCurrentEmailError("");
        setCurrentEmail(e.target.value)
       
    }
    const validationEmail = (email) => {
        const result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return result.test(String(email).toLowerCase());
    }
    const validateCurrentEmail = () => {
        let isValid = true;
        let focusSet = false;
        setCurrentEmailError("");
            if(currentemail === ""){
                isValid = false;
                setCurrentEmailError("Enter Email.");
            }else{
                if(!validationEmail(currentemail)){
                    isValid = false;
                    setCurrentEmailError("Enter a valid Email address.");
                }
            } 
            if (!focusSet) {
                refCurrentEmail.current.focus();
                focusSet = true;
            }

        return isValid;
    };
    const changeMailStep1 = async () => {
        let isValid = validateCurrentEmail();
        let userDetails = store.getState().user
        const requestPayload ={
            //userId :userDetails.user.user_id,
            email : currentemail.toLowerCase(),
        }
        if (isValid) {
            let response = await userController.verifyUserEmail(requestPayload)
        if (response.errorCode === 200) {
            props.sendDataToParent();
        } else {
            setCurrentEmailError(response.message);
        }
    }
    };

    useEffect(() => {
        refCurrentEmail.current.focus();
    }, []);

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            changeMailStep1();
        }
    };

	return (
		<>
        <div className="change-mail-process">
            <KOInput
                id="currentmail"
                placeholder="Enter your current email"
                label="Current Email"
                name="currentmail"
                type="text"
                value ={currentemail}
                onChange={handleCurrentEmailChange}
                txtRef={refCurrentEmail}
                errorMessage={currentemailError}
                onKeyDown={(e) => enterKeySubmit(e)}
                readonly
            />
            <div className="btn-sec"><Button variant="primary" onClick={changeMailStep1}>Submit</Button> </div>
        </div>
		</>
	);

};

export default ChangeMailStep1;
