import React from "react";


const TickIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33.288" height="24.937" viewBox="0 0 33.288 24.937">
        <path id="Path_10329" data-name="Path 10329" d="M6897.309,1808.344l10.458,10.449,18.589-18.574" transform="translate(-6895.188 -1798.098)" fill="none" stroke="#50e540" stroke-width="6"/>
        </svg>


    );

}

export default TickIcon;