import React, { useState, useEffect, useRef, Component } from "react";
import { Button } from "react-bootstrap";
import KOInput from "../components/KOInput";
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import * as userController from "../controller/userController";
const ChangeMailStep3 = (props) => {

    const user = useUserState();
    const refNewEmail = useRef(null);
    const refConfirmEmail = useRef(null);
    const [newemail, setNewEmail] = useState("");
    const [confirmemail, setConfirmEmail] = useState("");
    const [newemailError, setNewEmailError] = useState("");
    const [confirmemailError, setConfirmEmailError] = useState("");
    const [emailOTP, setEmailOTP] = useState(props.emailChangeOTP);
    const handleNewEmailChange = (e) => {
        setNewEmailError("");
        setNewEmail(e.target.value)
    }

    const handleConfirmEmailChange = (e) => {
        setConfirmEmailError("");
        setConfirmEmail(e.target.value)
    }
    const validationEmail = (email) => {
        const result = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return result.test(String(email).toLowerCase());
    }

    const validateEmail = () => {
        let isValid = true;
        let focusSet = false;
        let userDetails = store.getState().user
        setNewEmailError("");
            if(newemail === ""){
                isValid = false;
                setNewEmailError("Enter Email.");
            }else{
                if(!validationEmail(newemail)){
                    isValid = false;
                    setNewEmailError("Enter a valid Email address.");
                }
                if(userDetails.user.email === newemail) {
                    isValid =false;
                    setNewEmailError("This email id already exist")
                }
            } 
            if (!focusSet) {
                refNewEmail.current.focus();
                focusSet = true;
            }

            setConfirmEmailError("");
            if(confirmemail === ""){
                isValid = false;
                setConfirmEmailError("Enter Email.");
            }else{
                if(!validationEmail(confirmemail)){
                    isValid = false;
                    setConfirmEmailError("Enter a valid Email address.");
                }else if(confirmemail.toLowerCase() != newemail.toLowerCase()){
                    isValid = false;
                    setConfirmEmailError("New Email and Confirm Email entries must be the same.");
                }
            } 
            if (!focusSet) {
                refConfirmEmail.current.focus();
                focusSet = true;
            }

        return isValid;
    };
    const changeMailStep3 = async () => {
        let isValid = validateEmail();
        let userDetails = store.getState().user
        const requestPayload ={
            //userId :userDetails.user.user_id,
            email : newemail.toLowerCase(),
            otp : props.emailChangeOTP
        }
        let verifyEmail
        if (isValid) {
          //  await userController.updateUserEmail(requestPayload);
            let response=await userController.updateUserEmail(requestPayload)
        if (response.errorCode === 200) {
            props.sendDataToParent();
            let userResponse = await user.action.setUserData(requestPayload);
        } else {
            setConfirmEmailError(response.message);
        }
    }
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            changeMailStep3();
        }
    };

	return (
		<>
        <div className="change-mail-process">
            <KOInput
                id="newmail"
                placeholder="Enter your new email address"
                label="New Email"
                name="newmail"
                type="text"
                value ={newemail}
                onChange={handleNewEmailChange}
                txtRef={refNewEmail}
                errorMessage={newemailError}
            />
            <KOInput
                id="confirmmail"
                placeholder="Confirm your email address"
                label="Confirm Email"
                name="confirmmail"
                type="text"
                value ={confirmemail}
                onChange={handleConfirmEmailChange}
                txtRef={refConfirmEmail}
                errorMessage={confirmemailError}
                onKeyDown={(e) => enterKeySubmit(e)}
                
            />
            <div className="btn-sec"><Button variant="primary" onClick={changeMailStep3}>Submit</Button> </div>
        </div>
		</>
	);

};

export default ChangeMailStep3;
