import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import InnerHeader from "../components/InnerHeader";
import userpic from '../assets/profile-pic.png';
import brandlogo from '../assets/fantasy-sport-logo.svg';
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import "../styles/mycontest.scss";
import { pageURLs } from "../appData/pageURLs";
import {store} from '../appState/store';
import * as contestService from "../services/contestService";
import {toCurrency} from '../services/commonService'

const MyContests = (props) => {

    const userData = store.getState().user 
    let userFullName = "";
    let userImage = null;
    userFullName = userData.user.user_full_name;
    userImage = userData.user.profile_img_path;
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [profileImageValue,  setProfileImageValue] = useState(userImage);
    const [totalJoinedContest,  setTotalJoinedContest] = useState(0);
    const [totalJoinedLeague,  setTotalJoinedLeague] = useState(0);
    const [totalContestWon,  setTotalContestWon] = useState(0);
    const [totalUserGameWon,  setTotalUserGameWon] = useState(0);
    const [totalCreatedLeague,  setTotalCreatedLeague] = useState(0);
    const [totalUpcomingContest,  setTotalUpcomingContest] = useState(0);
    const [totalLiveContest,  setTotalLiveContest] = useState(0);
    const [totalCompletedContest,  setTotalCompletedContest] = useState(0);
    const [totalPublishedLeague,  setTotalPublishedLeague] = useState(0);
    const [totalUnPublishedLeague,  setTotalUnPublishedLeague] = useState(0);
    const [totalUpcomingJoinedLeague,  setTotalUpcomingJoinedLeague] = useState(0);
    const [totalLiveJoinedLeague,  setTotalLiveJoinedLeague] = useState(0);
    const [totalCompletedJoinedLeague,  setTotalCompletedJoinedLeague] = useState(0);
    const [totalStdWinner,  setTotalStdWinner] = useState(0);
    const [totalKnockoutPlusWinner,  setTotalKnockoutPlusWinner] = useState(0);
    const [totalWeeklyWinner,  setTotalWeeklyWinner] = useState(0);
    const [totalAmountEarned,  setTotalAmountEarned] = useState(0);
    useEffect(() => {
        contestService.getUserContestSummary(userData.user.user_id)
            .then(response => {
                if (response.success) {
                    let upcomingContestTotal = parseInt(response.data.upcomingContestTotal);
                    let completedContestTotal = parseInt(response.data.completedContestTotal);
                    let liveContestTotal = parseInt(response.data.liveContestTotal);
                    let totalSiteContest = upcomingContestTotal+completedContestTotal+liveContestTotal;

                    let completedLeagueContestTotal = parseInt(response.data.completedLeagueContestTotal);
                    let liveLeagueContestTotal = parseInt(response.data.liveLeagueContestTotal);
                    let upcomingLeagueContestTotal = parseInt(response.data.upcomingLeagueContestTotal);
                    let totalLeagueContest = completedLeagueContestTotal+upcomingLeagueContestTotal+liveLeagueContestTotal;
                    
                    let publishedLeagueContestTotal = parseInt(response.data.publishedLeagueContestTotal);
                    let unPublishedLeagueContestTotal = parseInt(response.data.unPublishedLeagueContestTotal);
                    let totalCreatedLeague = publishedLeagueContestTotal+unPublishedLeagueContestTotal;

                    let siteContestWinCount = parseInt(response.data.siteContestWinCount);
                    let leagueContestWinCount = parseInt(response.data.leagueContestWinCount);

                    setTotalJoinedContest(totalSiteContest);
                    setTotalJoinedLeague(totalLeagueContest);
                    setTotalContestWon(siteContestWinCount);
                    setTotalUserGameWon(leagueContestWinCount);
                    setTotalUpcomingContest(upcomingContestTotal);
                    setTotalLiveContest(liveContestTotal);
                    setTotalCompletedContest(completedContestTotal);
                    setTotalUpcomingJoinedLeague(upcomingLeagueContestTotal);
                    setTotalLiveJoinedLeague(liveLeagueContestTotal);
                    setTotalCompletedJoinedLeague(completedLeagueContestTotal);
                    setTotalPublishedLeague(publishedLeagueContestTotal);
                    setTotalUnPublishedLeague(unPublishedLeagueContestTotal);
                    setTotalCreatedLeague(totalCreatedLeague);
                    setTotalStdWinner(parseInt(response.data.total_std_winner));
                    setTotalKnockoutPlusWinner(parseInt(response.data.total_cog_winner));
                    setTotalWeeklyWinner(parseInt(response.data.total_week_winner));
                    setTotalAmountEarned(parseFloat(response.data.total_rewards_earned));

                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);

    const getSideMenuClasses = () => {
        let result = "koblock-right content-block-rht profile-activity";
        if (Flag_ShowSideMenu) {
            result += " sidebarvisible";
        } else {
            result += " sidebarhidden";
        }
        return result;
    }

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.mycontests} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft profile-dashboard">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>
                        <div className="dashboard-wrap">
                            <div className="db-title-sec">
                                <div className="title-block">
                                    <h1>{totalJoinedContest}</h1>
                                    <p>Joined Contests</p>
                                </div>
                                <div className="title-block">
                                    <h1 className="clr-active">{totalCreatedLeague}</h1>
                                    <p>Created Leagues</p>
                                </div>
                            </div>
                            <div className="activity-list">
                                <div className="competition-data">
                                    <div className="point-board board1">
                                        <h3>{totalJoinedContest}</h3>
                                        <h4>Contests from Site Games</h4>
                                        <h5>{totalContestWon}/{totalJoinedContest} Contests Won</h5>
                                    </div>
                                    <div className="participation-list">
                                        <div className="list-value">
                                            <p>Upcoming Contests</p><span>{totalUpcomingContest}</span>
                                        </div>
                                        <div className="list-value">
                                            <p>Live Contests</p><span>{totalLiveContest}</span>
                                        </div>
                                        <div className="list-value">
                                            <p>Completed Contests</p><span>{totalCompletedContest}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="competition-data">
                                    <div className="point-board board2">
                                        <h3>{totalJoinedLeague}</h3>
                                        <h4>Contests from User Games</h4>
                                        <h5>{totalUserGameWon} Contests Won</h5>
                                    </div>
                                    <div className="participation-list">
                                        <div className="list-value">
                                            <p>Upcoming Contests</p><span>{totalUpcomingJoinedLeague}</span>
                                        </div>
                                        <div className="list-value">
                                            <p>Live Contests</p><span>{totalLiveJoinedLeague}</span>
                                        </div>
                                        <div className="list-value">
                                            <p>Completed Contests</p><span>{totalCompletedJoinedLeague}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="competition-data">
                                    <div className="point-board board3">
                                        <h3>{totalCreatedLeague}</h3>
                                        <h4>Leagues Created for User Games</h4>
                                    </div>
                                    <div className="participation-list">
                                        <div className="list-value">
                                            <p>Published Leagues</p><span>{totalPublishedLeague}</span>
                                        </div>
                                        <div className="list-value">
                                            <p>Unpublished Leagues</p><span>{totalUnPublishedLeague}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={getSideMenuClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowSideMenu(false); }}></Button>
                        <figure><img src={(profileImageValue)? profileImageValue : userpic } alt="KO" /></figure>
                        <h4>{userFullName}</h4>
                        <div className="achievement-sec">
                            <p className="title">Achievements</p>
                            <div className="sec-value"><p>Contest Winner:</p><span>{totalStdWinner}</span></div>
                            <div className="sec-value"><p>Knockout Plus Winner:</p><span>{totalKnockoutPlusWinner}</span></div>
                            <div className="sec-value"><p>Weekly Winner:</p><span>{totalWeeklyWinner}</span></div>
                        </div>
                        <div className="reward-sec">
                            <p>REWARDS EARNED</p>
                            <h4>{toCurrency(totalAmountEarned)}</h4>
                        </div>
                        <div className="company">
                            <img src={brandlogo} alt="KO" />
                            <Link to={pageURLs.lobby}>www.fantasysportsknockout.com</Link>
                        </div>
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default MyContests;
