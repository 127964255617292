import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import * as contestService from "../services/contestService";
import KOLineupCard from "./KOLineupCard";
import useUserState from "../appState/userState/useUserState";
import userpic from '../assets/user-default.png';

const WinnersList = props => {
    const userState = useUserState();
    const user_id = userState && userState.data && userState.data.user ? userState.data.user.user_id : 0;
    let contest_type_id = props.contest_type_id ? props.contest_type_id : 1;
    const [winners, setWinners] = useState(props.winnersResponse);
    const [lineupPlayers, setLineupPlayers] = useState([]);
    const [lineUpScores, setLineUpScores] = useState([]);
    const [totalLineUpScore, SetTotalLineUpScore] = useState(0);

    const [standingUserId, setStandingUserId] = useState(0);
    const [standingLineUpWeek, setStandingLineUpWeek] = useState(0);
    const [standingLineupNumber, setStandingLineupNumber] = useState(0);
    
    let defaultPlayerList = [];
    defaultPlayerList = [
        { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback" },
        { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK" },
        { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK" },
        { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END" },
        { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER" },
        { className: "kicker", playername: "", figure: "", score: "", position: "KICKER" },
        { className: "defence", playername: "", figure: "", score: "", position: " DST" }
    ];
    // setting the response to state
    useEffect(() => {
        setWinners(props.winnersResponse)
    }, [props.winnersResponse])



    const getWinnersStandings = async (contestId, lineupNumber, lineUpWeek, leaderUserId) => {
        if (leaderUserId != standingUserId) {

            let result = await contestService.getWeeklyStandingsOtherUsersLineup(contestId, lineUpWeek, lineupNumber, leaderUserId, props.isuserGame);

            setStandingUserId(leaderUserId);
            setStandingLineUpWeek(lineUpWeek)
            setStandingLineupNumber(lineupNumber)

            if (result && result.errorCode == 200) {
                let lineUpData = [];
                let scoresData = [];
                let totalLineupScore = 0;
                lineUpData = (result.data.lineUpPlayers) ? result.data.lineUpPlayers : [];
                scoresData = (result.data.playerScoreData) ? result.data.playerScoreData : [];
                scoresData = scoresData[0] ? scoresData[0] : scoresData;

                totalLineupScore = (result.data.totalLineupScore) ? result.data.totalLineupScore : 0;
                setLineupPlayers(lineUpData);
                setLineUpScores(scoresData);
            }
        } else {
            setStandingUserId(0);
        }


    }

    const getLineUpPlayers = () => {
        const updatedAreas = [...defaultPlayerList];

        updatedAreas && updatedAreas.map((item, index) => {
            let arrayIndex = index;

            let lineupPlayersData = (lineupPlayers.length) ? [...lineupPlayers] : [];
            let lineupPlayersScores = (lineUpScores) ? lineUpScores : [];

            lineupPlayersData.map((playeritem, index) => {

                let playerIndex = index;
                let playername;
                if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "QB") {
                    item.className = "quarterback"
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "RB") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "WR") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "FL") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "K") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "DF") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.team_key] ? parseFloat(lineupPlayersScores[playeritem.team_key]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.team_key] || playeritem.is_locked) ? 1 : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "TE") {
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = lineupPlayersScores[playeritem.player_id] ? parseFloat(lineupPlayersScores[playeritem.player_id]).toFixed(2) : 0;
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.isLocked = (lineupPlayersScores[playeritem.player_id] || playeritem.is_locked) ? 1 : 0;
                }
            });
        });



        return updatedAreas?updatedAreas.map((item, index) =>
            <KOLineupCard
                className={item.className}
                type="weeklyStandings"
                figure={item.figure}
                playername={item.playername}
                score={item.score}
                position={item.position}
                dataIndex={index}
                placingPosition={item.className + index}
                uniqueId={item.uniqueId}
                player_position={(item.player_position) ? item.player_position : null}
                lineup_number={(item.lineup_number) ? item.lineup_number : null}
                lineup_week={(item.lineup_week) ? item.lineup_week : null}
                player_card_id={(item.player_card_id) ? item.player_card_id : 0}
                si_contest_id={(item.si_contest_id) ? item.si_contest_id : null}
                player_id={(item.player_id) ? item.player_id : null}
                isLocked={(item.isLocked) ? item.isLocked : 0}

            />
        ):[]
    }


    const getPlayerInfo = (playerInfo, blockType, listType) => {
        return (
            <>
                <div key={'keyy' + playerInfo.user_id + user_id} className={"winner-detail-card " + (listType == 'KnockoutPlus' || listType == 'SeasonScWinners' ? 'winner' : "")}>
                    <div className="winner-info">
                        <div className="score">
                            <p> {(blockType == 'YTD') ? 'YTD Score' : 'Weekly Score'}</p>
                            <h5>{playerInfo.week_user_total_score ? playerInfo.week_user_total_score : 0}</h5>
                        </div>
                        <div className="contestant">

                            <figure>{playerInfo.profile_pic ? <img src={playerInfo.profile_pic} alt="KO" /> : <img src={userpic} alt="KO" />}</figure>
                            <div className="name">
                                {user_id == playerInfo.user_id ? <span className="label">You</span> : ""}
                                <p>{playerInfo.user_full_name}<span>(Lineup {playerInfo.lineup_number})</span> {playerInfo.experience_level==1 ? <span className="level-badge beginners">B</span> : ''} {playerInfo.experience_level==2 ? <span className="level-badge experienced">E</span> : ''}</p>
                            </div>
                        </div>
                    </div>

                    {/* {user_id != playerInfo.user_id ? <Button variant="primary" className={"btn-standing " + ((standingUserId == playerInfo.user_id  && standingLineUpWeek == playerInfo.lineup_week && standingLineupNumber == playerInfo.lineup_number) ? "btn-selected" : "")} onClick={() => { getWinnersStandings(playerInfo.contest_id, playerInfo.lineup_number, playerInfo.lineup_week, playerInfo.user_id) }}>Standings</Button> : ""} */}
                </div>


                {(standingUserId == playerInfo.user_id && standingLineUpWeek == playerInfo.lineup_week && standingLineupNumber == playerInfo.lineup_number) ?
                    <div className="lineup-main-list">
                        <div className="player-card-info">
                            {lineupPlayers && lineupPlayers.length > 0 ? getLineUpPlayers() : "No records to display."}
                        </div>
                    </div> : ""}
            </>
        )
    }


    const weeksAndPlayers = (weeksAndPlayerlist, blockType, listType) => {
        const distinctWeeks = [
            ...new Set(weeksAndPlayerlist.map((item) => item.lineup_week)),
        ];
        let returnRow = [];

        for (let i of distinctWeeks) {

            if (listType == 'WeeklyWinners')
                returnRow.push(<h6>Week {i}</h6>)

            weeksAndPlayerlist.forEach(row => {
                if (i == row.lineup_week)
                    returnRow.push(getPlayerInfo(row, blockType, listType))
            });
        }
        return returnRow;
    };

    return (
        <div className="winners-list">
             {winners.SeasonWinners && winners.SeasonWinners.length > 0 ?
                <div className="participant-list">
                    <h4>{contest_type_id == 2 ? 'Knockout Plus Winner' : 'Contest Winner'}</h4>
                    {weeksAndPlayers(winners.SeasonWinners, 'YTD', 'SeasonScWinners')}
                </div> : ""}

            {winners.knowkoutPlusWinners && winners.knowkoutPlusWinners.length > 0 ?
                <div className="participant-list">
                    <h4>{contest_type_id == 2 ? 'Season Sc. Winners' : 'Contest Winner'}</h4>
                    {weeksAndPlayers(winners.knowkoutPlusWinners, 'YTD', 'KnockoutPlus')}
                </div> : ""}

            {winners.weeklyWinners && winners.weeklyWinners.length > 0 ?
                <div className="participant-list">
                    <h4>Weekly Winners</h4>
                    {weeksAndPlayers(winners.weeklyWinners, 'WeeklyScore', 'WeeklyWinners')}
                </div> : ""}

            {/* {winners.weeklyEliminatedUsers && winners.weeklyEliminatedUsers.length > 0 ?
                <div className="participant-list">
                    <h4>Eliminated Contestants</h4>
                    {weeksAndPlayers(winners.weeklyEliminatedUsers, 'WeeklyScore', 'EliminatedContestants')}
                </div> : ""} */}
            {
                winners.knowkoutPlusWinners && winners.knowkoutPlusWinners.length == 0 && winners.SeasonWinners && winners.SeasonWinners.length == 0 && winners.weeklyWinners && winners.weeklyWinners.length == 0  ? "No records to display." : ""
            }
        </div>
    );
}

export default (WinnersList);

