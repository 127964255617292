
import React from 'react';

const KOTextarea = props => {
    const getLength = () => {
        let length = 0;
        if (props.value) {
            length = props.value.length;
        }
        return length;
    }
    let show_count = true;
    if(props.showCount !== undefined && props.showCount === false) {
        show_count = false
    }
    return (
        <div className="form-group">
            {show_count && <div className="count">{getLength()}/{props.maxLength}</div>}
            {props.label &&
                <label>{props.label}</label>}
            <textarea
                className="form-control form-textarea"
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                error={props.error}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
                onChange={props.onChange}
                minLength={props.minLength}
                maxLength={props.maxLength}
                disabled={props.disabled}
                background-color={props.bgColor}
                ref={props.txtRef}
            />
            {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
        </div>
    );
}

export default (KOTextarea);
