import React from "react";
import { Pagination } from "react-bootstrap";
import KOSelectBox from "./KOSelectBox";

const PaginateV2 = (props) => {

  const perPage = parseInt(props.paginationData.perPage);
  const currentPage = parseInt(props.paginationData.currentPage);
  const totalPages = parseInt(props.paginationData.totalPages);
  const pageRows = parseInt(props.pageRows);
  const totalRows = parseInt(props.paginationData.total);

  const prevPage = currentPage >= 1 ? currentPage - 1 : 1;
  const NextPage = currentPage >= 1 ? currentPage + 1 : 1;

  const getTransactionsIPPOptions = () => {
    return [
      { value: '10', label: '10' },
      { value: '20', label: '20' },
      { value: '50', label: '50' },
      { value: '75', label: '75' },
      { value: '100', label: '100' }
    ];
  };

  if (pageRows == 0) {
    return ("");
  }
  else {
    return (
      <div className="pagination-wrap">
        <div className="items-group">
          <div className="items-count">
            <div className="count-drop">
              <p>Items per page:</p>
              <KOSelectBox
                id="pagenumber"
                name="pagenumber"
                options={getTransactionsIPPOptions()}
                value={perPage}
                type="text"
                onChange={props.onChange}
              />
            </div>
            {(
              <p>{"Showing " + (perPage * (currentPage - 1) + 1) + " - " + (parseInt(perPage * (currentPage - 1)) + parseInt(pageRows)) + " of " + totalRows + (totalRows == 1 ? ' item.' : ' items.')} </p>)}
          </div>
        </div>
        {
          totalPages > 1 ?
            <div className="pagination-nav">
              <p>Page {currentPage}</p>
              <Pagination>
                {prevPage >= 1 ? <Pagination.Prev onClick={() => props.onClick(prevPage)} /> : <Pagination.Prev />}
                {NextPage <= totalPages ? <Pagination.Next onClick={() => props.onClick(NextPage)} /> : <Pagination.Next />}
              </Pagination>
            </div> : ""
        }
      </div>
    );
  }
}
export default PaginateV2;





