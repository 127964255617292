import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import KOSearchInput from "./KOSearchInput";
import KOSelectBox from "./KOSelectBox";
import KOPlayerCard from "./KOPlayerCard";
import PlayerStandings from "./PlayerStandings";
import useSiteState from '../appState/siteState/useSiteState';
import { store } from '../appState/store';
import Paginate from "../components/Paginate";

const PlayerPositionList = props => {

    const [addedPositions, setAddedPositions] = useState([])
    const [enablePlusIcon, setEnablePlusIcon] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [sortByvalue, setsortByvalue] = useState(props.value.SortBy);
    const [showLoader, setshowLoader] = useState(false);


    const filterState = props.value;
    const exampleArray = props.exampleArray;
    const siteState = useSiteState();
    let PlayerCardPlacingId = siteState.data.Lobby.PlayerCardPlacingId;
  

    useEffect(() =>{    
        if (siteState.data.Lobby.PlayerCardPlacingId >= 0 && props.selectedPlayers && selectedIndex == -1) {
            let positions = props.selectedPlayers.map((row) => {
                return row.player_card_id;
            });
        
            if (positions.indexOf(siteState.data.Lobby.PlayerCardPlacingId) > -1)
                setEnablePlusIcon(false)
            else{
                setSelectedIndex(positions.indexOf(siteState.data.Lobby.PlayerCardPlacingId))
                setEnablePlusIcon(true)
            }
                
        }
    }, [props.selectedPlayers,siteState.data.Lobby.PlayerCardPlacingId ]);


    const siteData = store.getState().site
    const current_season_week = props.current_season_week;

    let playerPosition = siteData.Lobby.PlayerPosition;
    let palyerPositionClass = 'quarterback';
    let setPlayerPosition;
    if (playerPosition === "QB") {
        setPlayerPosition = "QUARTERBACK"
        palyerPositionClass = 'quarterback';
    } else if (playerPosition === "RB") {
        setPlayerPosition = "RUNNING BACK"
        palyerPositionClass = 'runningback';
    } else if (playerPosition === "WR") {
        setPlayerPosition = "WIDE RECEIVER"
        palyerPositionClass = 'widereceiver';
    } else if (playerPosition === "TE") {
        setPlayerPosition = "TIGHT END"
        palyerPositionClass = 'tightend';
    } else if (playerPosition === "FL") {
        setPlayerPosition = "FLEX PLAYER"
        palyerPositionClass = 'flexplayer';
    } else if (playerPosition === "K") {
        setPlayerPosition = "KICKER"
        palyerPositionClass = 'kicker';
    } else if (playerPosition === "DF") {
        setPlayerPosition = "DEFENSE/SPECIAL"
        palyerPositionClass = 'defence';
    }

    let playerListPosition = "";
    let showQuarterBack = false;
    //var showLoader = siteData.Lobby.showPlayerLoader;
    if (siteData.Lobby.PlayerListByPosition) {
        playerListPosition = siteData.Lobby.PlayerListByPosition;
        showQuarterBack = true;
    }

    if (siteState.data.showPositionList === false) {
        showQuarterBack = siteState.data.showPositionList;
    }

    const resetSearchBox = (values) => {
        props.onChange({ year: props.year, position: siteState.data.Lobby.PlayerPosition, searchData: '', placingPositionId: siteState.data.Lobby.PlayerCardPlacingId });
        filterState.SearchData = '';
    }

    const callNextPosition = (position) => {
        props.callNextPositionOnSetup(position)
    }
    const getDefaultQuarterBackPlayer = (playerList) => {
        if (playerList && playerList.length > 0) {
            return playerList.map((item, index) =>
                <Col md={4} key={item.player_id}>

                    <KOPlayerCard
                        enablePlusIcon={enablePlusIcon}
                        callNextPosition={callNextPosition}
                        resetSearchBox={resetSearchBox}
                        className={item.className ? item.className : palyerPositionClass}
                        figure={item.player_img_url ? item.player_img_url : item.team_logo_url}
                        playername={item.player_full_name ? item.player_full_name : item.team_name}
                        playernumber={item.player_number ? item.player_number : item.team_id}
                        team={item.team_key ? item.team_key : item.team_key}
                        position={item.player_position ? item.player_position : "DF"}
                        lineupno={props.lineUpNumber ? props.lineUpNumber : "1"}
                        lineupwk={props.lineUpWeek ? props.lineUpWeek : "1"}
                        playerId={item.player_id ? item.player_id : item.team_id}
                        teamId={item.team_id ? item.team_id : item.team_id}
                        uniqueId={item.player_id ? item.player_id : item.team_id}
                        positionPlaceId={PlayerCardPlacingId}
                        current_season_week={current_season_week}
                        is_added={item.is_added ? item.is_added : 0} //added in another week
                        isAlreadyPlayedInweek={item.played_in_week ? item.played_in_week : 0}
                        current_week={item.current_week ? item.current_week : 0}
                        is_player_locked={item.is_player_locked ? item.is_player_locked : 0}
                        is_team_locked={item.is_team_locked ? item.is_team_locked : 0}
                        is_player_injured={item.is_player_injured ? item.is_player_injured : 0}
                        player_season_score={item.player_season_score ? item.player_season_score : item.player_season_score}
                        is_player_news_avail={item.is_player_news_avail ? item.is_player_news_avail : 0}
                        byeweek={item.byeweek ? item.byeweek : 0}
                        addPlayersToLineup = {addPlayersToLineup}
                        lineUpPlayers ={props.lineUpPlayers}
                        removePlayersFromLineup = {props.removePlayersFromLineup}
                        team_id={item.team_id ? item.team_id : item.team_id}
                        removedLineupPlayers ={props.removedLineupPlayers}
                        lineUpPlayersScores = {props.lineUpPlayersScores}
                    />
                </Col>
            );

        }
        else {
            return <></>;
        }
    };

    const getPlayerPositionByClick = (playerListPosition) => {

        if (playerListPosition) {
            return playerListPosition.map((item, index) =>
                <Col md={4} key={'koPlayer' + index}>
                    <KOPlayerCard
                        enablePlusIcon={enablePlusIcon}
                        callNextPosition={callNextPosition}
                        resetSearchBox={resetSearchBox}
                        className={item.className ? item.className : palyerPositionClass}
                        figure={item.player_img_url ? item.player_img_url : item.team_logo_url}
                        playername={item.player_full_name ? item.player_full_name : item.team_name}
                        playernumber={item.player_number ? item.player_number : item.team_id}
                        team={item.team_key ? item.team_key : item.team_key}
                        position={item.player_position ? item.player_position : "DF"}
                        lineupno={props.lineUpNumber ? props.lineUpNumber : "1"}
                        lineupwk={props.lineUpWeek ? props.lineUpWeek : "1"}
                        playerId={item.player_id ? item.player_id : item.team_id}
                        teamId={item.team_id ? item.team_id : item.team_id}
                        uniqueId={item.player_id ? item.player_id : item.team_id}
                        positionPlaceId={PlayerCardPlacingId}
                        current_season_week={current_season_week}
                        is_added={item.is_added ? item.is_added : 0} //added in another week
                        isAlreadyPlayedInweek={item.played_in_week ? item.played_in_week : 0}
                        current_week={item.current_week ? item.current_week : 0}
                        is_player_locked={item.is_player_locked ? item.is_player_locked : 0}
                        is_team_locked={item.is_team_locked ? item.is_team_locked : 0}
                        is_player_injured={item.is_player_injured ? item.is_player_injured : 0}
                        player_season_score={item.player_season_score ? item.player_season_score : 0}
                        is_player_news_avail={item.is_player_news_avail ? item.is_player_news_avail : 0}
                        byeweek={item.byeweek ? item.byeweek : 0}
                        addPlayersToLineup = {addPlayersToLineup}
                        lineUpPlayers ={props.lineUpPlayers}
                        team_id={item.team_id ? item.team_id : item.team_id}
                        removePlayersFromLineup = {props.removePlayersFromLineup}
                        removedLineupPlayers ={props.removedLineupPlayers}
                        lineUpPlayersScores = {props.lineUpPlayersScores}
                    />
                </Col>
            );

        }
        else {
            return <></>;

        }
    };


    const onChange = (values) => {
        if (props.onChange) {
            siteState.data.Lobby.PlayerSearchFilterOptions.SearchData = values.SearchData;
            props.onChange({ year: props.year, position: siteState.data.Lobby.PlayerPosition, searchData: values.SearchData, placingPositionId: siteState.data.Lobby.PlayerCardPlacingId });
        }
    }
    let positionname = 'Quarterback';
    switch (siteData.Lobby.PlayerPositionName) {
        case 'QB':
        case 'Quarterback':
            positionname = 'Quarterback';
            break;

        case 'RB':
        case 'RUNNING BACK':
            positionname = 'RUNNING BACK';
            break;

        case 'WR':
        case 'WIDE RECEIVER':
            positionname = 'WIDE BACK';
            break;

        case 'TE':
        case 'TIGHT END':
            positionname = 'TIGHT END';
            break;

        case 'FP':
        case 'FLEX PLAYER':
            positionname = 'FLEX PLAYER';
            break;

        case 'K':
        case 'KICKER':
            positionname = 'KICKER';
            break;

        case 'DF':
            positionname = 'DEFENSE/SPECIAL';
            break;
    }

    const PageNumberChange = (page) => {
        props.PageNumberChange(page);
    }

    const ytdsortOptions = [
        { value: 'name', label: 'Player Name' },
        { value: 'ytdpoint', label: 'YTD Points' },
        { value: 'weeklypoint', label: 'Last Week Points' }
    ];

    const sortPlayers = (e) => {
        if (props.onChange) {
            siteState.data.Lobby.PlayerSearchSortOptions.SortBy = e.target.value;
            props.sortPlayers(e.target.value)
        }
    }

    const addPlayersToLineup = (playerData) => {
        props.addPlayersToLineup(playerData)
        setshowLoader(false)
    }

    return (
        <>
            <div className="player-list-container">
                <div className="position-title">
                    <h4>{setPlayerPosition == "DEFENSE/SPECIAL" ? 'DST' : setPlayerPosition}</h4>
                    <div className="input-field">
                    <KOSelectBox
                        id="sortoptiontype"
                        name="sortoptiontype"
                        label="Sort"
                        type="text"
                        options={ytdsortOptions}
                        onChange={(e) => { sortPlayers(e); }}
                        value={siteState.data.Lobby.PlayerSearchSortOptions.SortBy}
                    />
                        <KOSearchInput
                            id="searchfield"
                            placeholder="Search"
                            name="search"
                            type="text"
                            value={filterState.SearchData}
                            onChange={(e) => { filterState.SearchData = e.target.value; onChange(filterState); }}
                        />
                    </div>
                </div>
                <div className="row">

                    {
                        showQuarterBack ? getDefaultQuarterBackPlayer(props.quarterBackPlayer) : getPlayerPositionByClick(props.quarterBackPlayer)
                    }
                    {showLoader && <div className="col-md-12"><div className="playercard" style={{ background: "#fff" }}><div className="loader-wrap"><div className="loader"></div></div></div></div>}
                    {!props.quarterBackPlayer && !showLoader && <div className="col-md-12"><div className="playercard"><span style={{ color: "red" }}>No records to display.</span></div></div>}
                    {(siteData.Lobby.PlayerPosition !== 'DF' && siteData.Lobby.PlayerPosition !== 'DEFENSE/SPECIAL' && props.quarterBackPlayer && props.PaginationData) ? <Paginate paginationData={props.PaginationData} pageRows={props.quarterBackPlayer.length} onClick={PageNumberChange} /> : ""}
                </div>
            </div>
            <PlayerStandings />

        </>

    );
}

export default (PlayerPositionList);
