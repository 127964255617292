import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Button, Breadcrumb, Offcanvas, Figure, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import backarrow from '../assets/back-arrow.svg';
import bellicon from '../assets/notification-icon.svg';
import userpic from '../assets/user-default.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/ko-logo.svg';
import HornIcon1 from "../assets/icons/hornicon1";
import '../components/innerheader.scss';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";
import { store } from '../appState/store';
import { useDate } from '../components/DateDisplay';
import * as commonService from "../services/commonService";
import * as notificationService from "../services/notificationService"
import Moment from 'react-moment';
import useSiteState from "../appState/siteState/useSiteState";
import * as userController from "../controller/userController";
import { Markup } from 'interweave';
import { useGoogleLogout } from 'react-google-login'

const InnerHeader = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	
	const { date, time } = useDate();

	const navigate = useNavigate();
	const location = useLocation();
	const user = useUserState();
	const siteState = useSiteState();
	const userData = store.getState().user
	const paymentProcessedStatus = user.data.paymentProcessed;
	const initialPageParams = {
		page: 1
	}

	let firstName = "";
	let lastName = "";
	let userName = "";
	let profileImageValue = null;
	userName = userData.user.user_name;
	profileImageValue = userData.user.profile_img_path;
	let wallet_amount = userData.user.wallet_amount;
	const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
	const GOOGLE_SRC = 'https://apis.google.com/js/api.js';

	const { googleSignOut, loaded } = useGoogleLogout({
		GOOGLE_SRC
	  })
 	 
	const signOut = async () => {
		await user.action.logout();
		sessionStorage.removeItem('contestId');
		sessionStorage.removeItem('lineupsArray');
		sessionStorage.removeItem('vistedSiteLobby');
		googleSignOut()
		window.location.href = pageURLs.root;
		//navigate(pageURLs.login);

	};

	const handleShow = async() => {
		setShow(true);
		await getNotifications(userData.user.user_id);
	}

	const [showConfirmModal, setConfirmModal] = useState(false);
	const [showLoader, setshowLoader] = useState(false);
	const [firstNameValue, setFirstNameValue] = useState(firstName);
	const [userNameValue, setUserNameValue] = useState(userName);
	const ConfirmModalClose = () => setConfirmModal(false);
	
	const [showLogoutConfirmModal, setLogoutConfirmModal] = useState(false);
	const LogoutConfirmModalClose = () => setLogoutConfirmModal(false);
	const LogoutConfirmModalShow = () => setLogoutConfirmModal(true);

	const [balanceAvailable, setBalanceAvailable] = useState('Y');
	const [leagueAmount, setLeagueAmount] = useState('0.00');

	const [walletAmount, setWalletAmount] = useState(wallet_amount);
	const prevBalance = useRef("");
	const [notifications, setNotifications] = useState([]);
	const [unreadNotificationStatus, setUnreadNotificationStatus] = useState(0);
	const [unreadMessagesValue, setUnreadMessagesValue] = useState(false);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
	const [walletSplitUpData, setwalletSplitUpData] = useState({
		'depositedAmount' : 0,
		'prizeMoney' : 0,
		'promoFund' : 0,
		'withdrawalBalance' : 0,
		'withdrawanFunds' : 0
	});
	const [showSplitUpLoader, setSplitUpLoader] = useState(false);
	const [splitUpScreenOpenStatus, setSplitUpScreenOpenStatus] = useState(0);

	const [showVerificationProcessModal, setshowVerificationProcessModal] = useState(false);
    const [notverifiedMessage, setnotverifiedMessage] = useState("Complete verification for creating league");
    const [verificationBtnTitle, setverificationBtnTitle] = useState("Continue with Verification");
	useEffect(() => {
		if (userData.user.usergame_amount)
			setLeagueAmount(userData.user.usergame_amount)
	}, [userData.user.usergame_amount])

	const isCreateLeagueDisabled = () => {
		if (location.pathname === pageURLs.createleague) {
			return true;
		} else {
			return false;
		}
	}

	const bcItemClick = async (e, url) => {
		if(localStorage.getItem('changeInLineup') === 'true' && checkLineupPage()){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                await siteState.action.SiteGamesCleared();
				siteState.data.Lobby.ContestList = [];
				e.preventDefault();
				navigate(url);
            }
        } else {
			await siteState.action.SiteGamesCleared();
			siteState.data.Lobby.ContestList = [];
			e.preventDefault();
			navigate(url);
		}
	}

	const notificationMarkAllAsRead = async () => {
		let response = await notificationService.markAllAsRead();
		if (response.errorCode === 200) {
			await getNotifications();
			setUnreadNotificationStatus(response.data.unreadNotificationStatus)
		}
	}

	const notificationMarkAsRead = async (notification_id) => {
		let response = await notificationService.markAsRead(notification_id);
		if (response.errorCode === 200) {
			await getNotifications();
			setUnreadNotificationStatus(response.data.unreadNotificationStatus);
		}
	}

	const getNotifications = async () => {
		setshowLoader(true)
		let response = await notificationService.getNotifications(userData.user);
		setshowLoader(false)
		if (response.errorCode === 200) {
			setNotifications(response.data.notificationList);
			setUnreadNotificationStatus(response.data.unreadNotificationStatus)
		}
	}

	const goToPreviousPath = () => {
		if(localStorage.getItem('changeInLineup') === 'true' && checkLineupPage()){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
				navigate(-1)
            }
        } else {
			navigate(-1)
		}
	}

	useEffect(async () => {
		prevBalance.current = wallet_amount;
		if (paymentProcessedStatus) {
			await user.action.getUserData();
			if (prevBalance.current != walletAmount) {
				await user.action.setPaymentProcessedStatus(false);
			}
		}
		
		await siteState.action.getUnreadMessages()
			.then(response => {
				if (response.success) {					
					setUnreadMessagesValue(siteState.data.Lobby.getUnreadMessages.data.isUnreadMsg ? siteState.data.Lobby.getUnreadMessages.data.isUnreadMsg : false);
					setUnreadNotificationStatus(siteState.data.Lobby.getUnreadMessages.data.unreadNotificationStatus);
				};
			})
			.catch(err => {
				console.log("Error", err);
			});
	}, [wallet_amount,location.pathname]);

	useEffect(() => {
		if (leagueAmount >= 0 && wallet_amount >= 0) {
			if (parseFloat(wallet_amount) - parseFloat(leagueAmount) < 0) {
				setBalanceAvailable('N')
			}
		}
	}, [wallet_amount, leagueAmount]);

	
    
	if(!location.pathname.toLowerCase().includes('createlineup') && !location.pathname.toLowerCase().includes('usercreatelineup')) {
		localStorage.removeItem('changeInLineup')
	}

	const checkLineupPage = () => {
		let lineupPage = false;
		if(location.pathname.toLowerCase().includes('createlineup') || location.pathname.toLowerCase().includes('usercreatelineup')) {
			lineupPage = true;
		}
		return lineupPage;
	}
	const popover = (
		<Popover id="popover-deposit" >
		  <Popover.Body>
		  		{showSplitUpLoader && <div className="loader-wrap"><div className="loader"></div></div>}
		 		<p>Deposited By You - <span>{commonService.toCurrency(walletSplitUpData.depositedAmount)}</span></p>
				<p>Your Winnings - <span>{commonService.toCurrency(walletSplitUpData.prizeMoney)}</span></p>
				<p>Knockout Bucks - <span>{commonService.toCurrency(walletSplitUpData.promoFund)}</span></p>
				{/* <p>Withdrawan Funds - <span>{commonService.toCurrency(walletSplitUpData.withdrawanFunds)}</span></p> */}
				<a href={pageURLs.myaccountinfo+'/transactionhistory'}>Transaction History</a>
				<div className="available-balance">
					<p className="title">Withdrawable:</p>
					<p className="amount">{commonService.toCurrency(walletSplitUpData.withdrawalBalance)}</p>
				</div>
		  </Popover.Body>
		</Popover>
	);

	const showWalletSplitup = async () => {
		
		let currentSplitOpenStatus = splitUpScreenOpenStatus;
		let newSplitUpOpenStatus = (!currentSplitOpenStatus) ? 1 : 0;
		setSplitUpScreenOpenStatus(newSplitUpOpenStatus);
		if(newSplitUpOpenStatus) { //while closing the screen we dont need to call API 
			setSplitUpLoader(true)
			await userController.fetchWalletSplitup()
			.then(function(response){
				if(response.errorCode == 200) {
					setwalletSplitUpData((walletSplitUpData) => ({
						...walletSplitUpData,
						depositedAmount: response.data.deposited_amount,
						prizeMoney: response.data.prize_amount,
						promoFund: response.data.promo_fund,
						withdrawalBalance: response.data.fund_for_withdrawal,
						withdrawanFunds: response.data.withdrawan_fund
					}));
					wallet_amount = response.data.wallet_amount;
				}
				setSplitUpLoader(false)
			})
		}
	}

	const resetOverLay = async () => {
		setSplitUpScreenOpenStatus(0)
	}

	const closeVerification = (e) => {
        setshowVerificationProcessModal(false);
    }

	const ConfirmModalShowPre = () => {
		if(localStorage.getItem('changeInLineup') === 'true' && checkLineupPage()){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                ConfirmModalShow()
            }
        } else {
            ConfirmModalShow()
        }	
	}

	const ConfirmModalShow = () => {
		if(user.data.user.user_status_id != 1) {
			setVerificationProcessModal()
		} else {
			setConfirmModal(true);
		}
	}
    const setVerificationProcessModal = (e) => {
        if(user.data.user.user_status_id != 1) { //Not verified users
            if(user.data.user.user_status_id == 4 && (user.data.user.tsevo_status_id == 1 || user.data.user.tsevo_status_id == 2)) { //Verification failed or not started the verification
                setnotverifiedMessage('Complete verification for creating league')
            } else if((user.data.user.user_status_id == 4 || user.data.user.user_status_id == 5) && user.data.user.tsevo_status_id == 3) {
                setnotverifiedMessage('Identity verification was unsuccessful. Please try again. If you continue to encounter difficulties, please reach out to a Knockout representative at admin@fantasysportsknockout.com for assistance.');
            } else if(user.data.user.user_status_id == 5) {
                setnotverifiedMessage("Your identity verification is still in progress. Please be patient - in rare cases, verification can take up to 24 hours. If it has been 24 hours or longer, please contact Knockout customer care at admin@fantasysportsknockout.com.");
                setverificationBtnTitle("Retry verification")
            }
            setshowVerificationProcessModal(true)
            return false;
        }
    }
	const continueVerification = (e) => {
        navigate(pageURLs.Verificationstart);
    }

	const pageRedirect = async (page) => {
        if(localStorage.getItem('changeInLineup') === 'true' && checkLineupPage()){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                navigate(page);
            }
        } else {
            navigate(page);
        }
    }
	return (
		<>
			<div className="main-header">
				<div className="title-logo-field">
					<div className="logo"><Link to={pageURLs.lobby}><img src={logo} alt="KO Logo" /></Link></div>
					{(props.currentPage === pageURLs.lobby) &&
						<div className="title-sec">
							<div className="title-data" id="welcomeDiv">
								<h4>Hi, {userNameValue}</h4>
								<p>{time} EST | {date} </p>
							</div>
						</div>
					}

					{(props.currentPage === pageURLs.mycontests) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>My Contests</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>My Contests</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.myaccountinfo) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>My Account Info</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>My Account Info</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.joincontest) &&
						<div className="title-sec">
							<Link to="#" className="goback" onClick={goToPreviousPath}><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Contest Details</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Contest Details</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}

					{(props.currentPage === pageURLs.usergamejoincontest) &&
						<div className="title-sec">
							<Link to="#" className="goback" onClick={goToPreviousPath}><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>User Contest Details</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>User Contest Details</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{((props.currentPage === pageURLs.usergamecreatelineup) || props.currentPage === pageURLs.createlineup) ?
						<div className="title-sec">
							<Link to="#" className="goback" onClick={goToPreviousPath}><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Create Lineup for User</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>create Lineup for User</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div> : ''
					}

					{(props.currentPage === pageURLs.createleague) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Create a League</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Create a League</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.editleague) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Edit League</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Edit League</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.leaguedetails) &&
						<div className="title-sec">
							<Link to={pageURLs.manageleague} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>League Details</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.manageleague); }}>Manage League</Breadcrumb.Item>
									<Breadcrumb.Item active>League Details</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.messageboard) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Message Board</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Message Board</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
					{(props.currentPage === pageURLs.payment) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Contest Details</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Contest Details</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}

					{(props.currentPage === pageURLs.verificationstart) &&
						<div className="title-sec">
							<Link to={pageURLs.lobby} className="goback"><img src={backarrow} alt="KO" /></Link>
							<div className="title-data">
								<h4>Complete My Profile</h4>
								<Breadcrumb>
									<Breadcrumb.Item href="#" onClick={(e) => { bcItemClick(e, pageURLs.lobby); }}>Lobby</Breadcrumb.Item>
									<Breadcrumb.Item active>Complete My Profile</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
					}
				</div>
				<div className="profile-details">
					<div className="deposit create-leauge-btn">

						<div className="amount">
							<Link to={pageURLs.messageboard}>{!unreadMessagesValue ? "" : <span className="dot"></span>}<HornIcon1 /></Link>
						</div>

						{(isCreateLeagueDisabled()) ?
							<Button variant="primary" disabled onClick={ConfirmModalShowPre}>Create League (${leagueAmount})</Button> :
							<Button variant="primary" onClick={ConfirmModalShowPre}>Create League (${leagueAmount})</Button>
						}

					</div>


					<div className="deposit" id="depositSummary" onClick={(e) => { showWalletSplitup(); }}>
						<div className="amount">
							<OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose onExited={resetOverLay}>
								<Button variant="link">{commonService.toCurrency(parseFloat(wallet_amount))}</Button>
							</OverlayTrigger>
							
						</div>
						<Button variant="primary" onClick={() => { pageRedirect(pageURLs.payment); }}>Deposit/Withdraw</Button>
					</div>
					<div className="notification" id="siteNotifications">
						<Button variant="light" onClick={handleShow} className="btn-drop"><span className={unreadNotificationStatus === 0 ? "" : "dot"}></span><img src={bellicon} alt="KO" /></Button>
						<Offcanvas show={show} onHide={handleClose} placement="end" className="notification-container">
							<Offcanvas.Header closeButton>
								<Offcanvas.Title>Notifications</Offcanvas.Title>
								<Button variant="primary" disabled={unreadNotificationStatus === 0} onClick={() => { unreadNotificationStatus !== 0 && notificationMarkAllAsRead() }}>Mark all as read</Button>
							</Offcanvas.Header>
							<Offcanvas.Body>
								{showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
								{(notifications && notifications.length === 0 && !showLoader) ? <p> No records to display.</p> :
									<div className="btn-sec">
										
									</div>}
								{notifications && notifications.length > 0 &&
									notifications
										.map((items, index) => (
											<div key={'notification' + index} className={items.is_read === 1 ? "notification-block read" : "notification-block"} onClick={() => { items.is_read === 0 && notificationMarkAsRead(items.nfl_notification_id) }}>
												<div className="bell"></div>
												<div className={"activity"}>
													<p><Markup content={items.notification_msg}/></p>
													<span>{items.invited_by} <Moment format='h:mm a | MMMM DD, YYYY'>{items.added_on}</Moment></span>
												</div>
											</div>
										))}
							</Offcanvas.Body>
						</Offcanvas>
					</div>
					{/* <div className="profile-username" id="welcomeDiv">
						<h5>{userNameValue}</h5>
					</div> */}

					<div className="user-dropdown" id="profileSummary">
						<Dropdown>
							<Dropdown.Toggle id="user-dropdown" className="btn-drop">
								<figure><img src={(profileImageValue) ? profileImageValue : userpic} alt="KO" /></figure>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item  onClick={(e) => { bcItemClick(e, pageURLs.myaccountinfo); }}>My Account Info</Dropdown.Item>
								<Dropdown.Item onClick={(e) => { bcItemClick(e, pageURLs.myaccountinfo+'/responsiblegaming'); }}  >Responsible Gaming</Dropdown.Item>
								<Dropdown.Item onClick={(e) => { bcItemClick(e, pageURLs.mycontests); }}>My Contests</Dropdown.Item>
								<Dropdown.Item onClick={LogoutConfirmModalShow}>Logout</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<Modal show={showConfirmModal} onHide={ConfirmModalClose} className="modal-predict">
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h4 className="modal-title">Are you sure you want to create this user contest?</h4>
						<p>By clicking yes you agree to the Terms and Conditions{leagueAmount ? ' and the entry fee of' : ""} {leagueAmount ? <span className="value">${leagueAmount}</span> : ''}.</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => { navigate(pageURLs.createleague); }}>Yes</Button>
						<Button variant="link" onClick={ConfirmModalClose}>Cancel</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showLogoutConfirmModal} onHide={LogoutConfirmModalClose} className="modal-predict">
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h4 className="modal-title">Are you sure you want to Logout?</h4>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="primary" onClick={signOut}>Yes</Button>
						<Button variant="link" onClick={LogoutConfirmModalClose}>Cancel</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showVerificationProcessModal} onHide={closeVerification} className="modal-coupon">
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h4 className="modal-title">Verification</h4>
						<p>{notverifiedMessage}</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={continueVerification}>{verificationBtnTitle}</Button>
						<Button variant="link" onClick={closeVerification}>Maybe later</Button>
					</Modal.Footer>
            	</Modal>


			</div>

		</>
	);

};

export default InnerHeader;
