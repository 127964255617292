import React, { useState, useEffect, useRef } from "react";
import { Form, Button , Modal  } from "react-bootstrap";
import { Link, useNavigate} from 'react-router-dom';
import Header from "../components/Header";
import KOInputButtonGroup from "../components/KOInputButtonGroup";
import Copyright from '../components/Copyright';
import googleicon from '../assets/icon-google.svg';
import KOPasswordinputgroup from "../components/KOPasswordinputgroup";
import KOCheckbox from "../components/KOCheckbox";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import { emailValidation, passwordValidation, usernameValidation} from "../services/commonService";
import useUserState from "../appState/userState/useUserState";
import {store} from '../appState/store';
import GoogleLogin from 'react-google-login';
import { TermsAndConditions  } from '../utils/CommonHelper';
import { referralCouponCheck} from "../services/userService";



const Signup = (props) => {
    const userState = store.getState().user;
   

    let loginCheckEmail = localStorage.getItem("loginCheckUser") ? localStorage.getItem("loginCheckUser") : '';
    let userNameFieldEditStatus =  false;
    let emailFieldEditStatus =  false;
    
    let firstName = "";
    let lastName = "";
    let userName = "";
    let email = "";
    if (emailValidation(loginCheckEmail) === false) {
        userName = loginCheckEmail
        userNameFieldEditStatus = true;
    } else {
        email = loginCheckEmail;
        emailFieldEditStatus = true;
    }
    if(userState.email) {
        firstName =userState.first_name;
        lastName =userState.last_name;
        userName =userState.user_name;
        email = userState.email;
    }

    const refEmail = useRef(null);
    const refUsername = useRef(null);
    const refPassword = useRef(null);
    const refCouponcode = useRef(null);
    const [emailValue, setEmailValue] = useState(email);
    const [emailError, setEmailError] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [firstnameValue, setFirstnameValue] = useState(firstName);
    const [firstnameError, setFirstnameError] = useState("");
    const [lastnameValue, setLastnameValue] = useState(lastName);
    const [lastnameError, setLastnameError] = useState("");
    const [usernameValue, setUsernameValue] = useState(userName);
    const [usernameError, setUsernameError] = useState("");
    const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState(false);
    const [termsAndConditionsCheckboxError, setTermsAndConditionsCheckboxError] = useState("");
    const [confirmAgeCheckbox, setConfirmAgeCheckbox] = useState(true);
    const [receiveUpdateCheckbox, setReceiveUpdateCheckbox] = useState(true);
    const [confirmAgeCheckboxError, setConfirmAgeCheckboxError] = useState("");
    const [signUpError, setsignUpError] = useState('');
    const navigate = useNavigate();
    const user = useUserState();
    const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const [googleLoginError, setgoogleloginError] = useState('');
    
    const [redeemBtnStyle, setRedeemBtnStyle] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [redeemBtnDisabled, setRedeemBtnDisabled] = useState(true);
    const [couponcodeError, setCouponcodeError] = useState("");
    const [showCouponSuccessModal, setShowCouponSuccessModal] = useState(false);
    const [couponSuccessMessage, setcouponSuccessMessage] = useState('');
    const [showCouponRemoveBtn , setCouponRemoveBtn] = useState(false);
    const [couponCodeFldDisable , setCouponCodeFldDisable] = useState(false);
    const [showRemoveCouponModal, SetRemoveCouponModal] = useState(false);
    const [emailFieldDisabledStatus, SetEmailFieldDisabledStatus] = useState(emailFieldEditStatus);
    const [userNameFieldDisabledStatus, SetuserNameFieldDisabledStatus] = useState(userNameFieldEditStatus);
    
    localStorage.removeItem('registerPassword');
    localStorage.setItem('receiveUpdate',  1);
    useEffect(() => {
        sessionStorage.removeItem('duplicateentry');
        
    }, []);

    //validate signup form
    const validate = () => {
        let isValid = true;
        let focusSet = false;
        // setFirstnameError("");
        // if (firstnameValue.trim() === "") {
        //     isValid = false;
        //     setFirstnameError("Enter First Name.");
        //     if (!focusSet) {
        //         refFirstname.current.focus();
        //         focusSet = true;
        //     }

        // }
        // setLastnameError("");
        // if (lastnameValue.trim() === "") {
        //     isValid = false;
        //     setLastnameError("Enter Last Name.");
        //     if (!focusSet) {
        //         refLastname.current.focus();
        //         focusSet = true;
        //     }
        // }

        setEmailError("");
        if (emailValue.trim() === "") {
            isValid = false;
            setEmailError("Enter Email.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }
        else if (emailValidation(emailValue) === false) {
            isValid = false;
            setEmailError("Enter a valid Email address.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }

        setUsernameError("");
        let usernameErrorMsg = usernameValidation(usernameValue);
        if (usernameErrorMsg !== "") {
            isValid = false;
            setUsernameError(usernameErrorMsg);
            if (!focusSet) {
                refUsername.current.focus();
                focusSet = true;
            }
        }

        setPasswordError("");
        let passwordErrorMsg = passwordValidation(passwordValue);
        if (passwordErrorMsg !== "") {
            isValid = false;
            setPasswordError(passwordErrorMsg);
            if (!focusSet) {
                refPassword.current.focus();
                focusSet = true;
            }
        }

        setTermsAndConditionsCheckboxError("")
        if(termsAndConditionsCheckbox === false) {
            isValid = false;
            setTermsAndConditionsCheckboxError("Agree to the Terms and Conditions to continue.")
        }

        setConfirmAgeCheckboxError("")
        if(confirmAgeCheckbox === false) {
            isValid = false;
            setConfirmAgeCheckboxError("Confirm your Age to continue.")
        }

        return isValid;
    };

    const handleFirstnameChange = (e) => {
        setFirstnameError("");
        setFirstnameValue(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmailError("");
        setEmailValue(e.target.value);
        if(showCouponRemoveBtn) {
            couponRemove(); // validated coupon code need to remove while changing the email address
        }
    };

    const handlePasswordChange = (e) => {
        setPasswordError("");
        setPasswordValue(e.target.value);
    };

    const handleLastnameChange = (e) => {
        setLastnameError("");
        setLastnameValue(e.target.value);
    };

    const handleUsernameChange = (e) => {
        setUsernameError("");
        setUsernameValue(e.target.value);
    };

    const handleTermsAndConditionsCheckbox = (e) => {
        setTermsAndConditionsCheckboxError("")
        setTermsAndConditionsCheckbox(e.target.checked);
    };

    const handleConfirmAgeCheckbox = (e) => {
        setConfirmAgeCheckboxError("")
        setConfirmAgeCheckbox(e.target.checked);
    };

    const handleReceiveUpdateCheckbox = (e) => {
        setReceiveUpdateCheckbox(e.target.checked);
        if(e.target.checked) {
            localStorage.setItem('receiveUpdate',  1);
        } else {
            localStorage.setItem('receiveUpdate',  0);
        }
    };

    const signUp = async () => {
        let isValid = validate();
        const requestPayload ={
            firstName :firstnameValue,
            lastName : lastnameValue,
            email : emailValue.toLowerCase(),
            password : passwordValue,
            userName : usernameValue,
            couponCode : couponCode,
            isMailOpted: receiveUpdateCheckbox=== true? 1:0
        }
        let registeredUser
        if(isValid){
            await user.action.registration(requestPayload);
            registeredUser = store.getState().user;
            if (registeredUser.success) {
                localStorage.removeItem("loginCheckUser")
                localStorage.setItem('registerPassword',passwordValue);
                navigate(pageURLs.otpverification);
            }
            setsignUpError(registeredUser.statusMessage)
        }
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            signUp();
        }
    };

    const responseGoogle = async(response) => {
        let googleuserDetails;
        let isMailOpted = (receiveUpdateCheckbox=== true) ? 1 : 0 ;
        await user.action.googleLogin(response.profileObj.email, response.accessToken,  1, response.tokenId,response.profileObj.googleId, isMailOpted);
        googleuserDetails = store.getState().user
        if (googleuserDetails.statusCode === 200 && (googleuserDetails.user.user_status_id == 4 || googleuserDetails.user.user_status_id == 5)) {
            localStorage.removeItem("loginCheckUser")
            navigate(pageURLs.signupstep1);
        } else if (googleuserDetails.statusCode === 200 && googleuserDetails.user.user_status_id == 1) {
            navigate(pageURLs.lobby);
        }else {
            setsignUpError(googleuserDetails.statusMessage);
        }
    }
    
    // const fbLogin = async (response) => {
    //     if(response){
    //         let isMailOpted = (receiveUpdateCheckbox=== true) ? 1 : 0 ;
    //         await user.action.fbLogin(response.email, response.accessToken, 1, response.id, isMailOpted);
    //         let userData = store.getState().user;

    //         if(userData.statusCode === 200 && userData.user.email && userData.user.user_status_id == 1) {
    //             navigate(pageURLs.lobby)
    //         }else if(userData.statusCode === 200 && !userData.user.email && (userData.user.user_status_id == 4 || userData.user.user_status_id == 5)) {
    //             navigate(pageURLs.emailrequest)
    //         }else if(userData.statusCode === 200 && userData.user.email && (userData.user.user_status_id == 4 || userData.user.user_status_id == 5)) {
    //             navigate(pageURLs.signupstep1);
    //         }else if(userData.statusCode !== 200) {
    //             setsignUpError(userData.statusMessage);
    //         }
            
    //     }
    //     else {
    //         setloginError("Could not login. Please try again later.");
    //     }
    // }

    

    const handleCouponCodeChange = (e) => {
        setCouponcodeError("");
        setCouponCode(e.target.value);
    };

    useEffect(() => {
        let btnStyle = null;
        setRedeemBtnDisabled(true)
        if(couponCode && emailValue){
            btnStyle = {
                opacity: 'unset',
            };
            setRedeemBtnDisabled(false)
        }
        setRedeemBtnStyle(btnStyle)
    }, [couponCode, emailValue]);

    const couponCheck = async () => {
        
        let isValid = validateCouponCheck();
        const requestPayload ={
            couponCode : couponCode,
            email : emailValue
        }
        if(isValid){
            await referralCouponCheck(requestPayload)
            .then(function (response){
                if(response.errorCode == 200) {
                    if(response.data.validCoupon === true) {
                        setShowCouponSuccessModal(true);
                        setcouponSuccessMessage(response.data.message)
                        setCouponRemoveBtn(true)
                        setCouponCodeFldDisable(true)
                        let btnStyle = {
                            opacity: 'unset',
                            background : "red"
                        };
                        setRedeemBtnStyle(btnStyle)
                    } else{
                        setCouponcodeError(response.data.message)
                        setCouponRemoveBtn(false)
                        setCouponCodeFldDisable(false)
                    }
                } else {
                    setCouponcodeError(response.message)
                    setCouponRemoveBtn(false)
                    setCouponCodeFldDisable(false)
                }
            })
        }
    };

    const validateCouponCheck = () => {
        let isValid = true;
        let focusSet = false;
        setCouponcodeError("");
        if (couponCode.trim() === "") {
            isValid = false;
            setCouponcodeError("Enter coupon code.");
            if (!focusSet) {
                refCouponcode.current.focus();
                focusSet = true;
            }
        }
        setEmailError("");
        if (emailValue.trim() === "") {
            isValid = false;
            setEmailError("Enter Email.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }
        else if (emailValidation(emailValue) === false) {
            isValid = false;
            setEmailError("Enter a valid Email address.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }
        return isValid;
    };

    const closeCouponSuccessModal = () => {
        setShowCouponSuccessModal(false);
    }

    const couponRemove = () => {
        let btnStyle = null;
        setRedeemBtnDisabled(false)
        setRedeemBtnStyle(btnStyle)
        setCouponRemoveBtn(false)
        setCouponCodeFldDisable(false)
        setCouponcodeError("");
        setCouponCode('')
        SetRemoveCouponModal(false);
    }

    const closeCouponRemoveModal = () => {
        SetRemoveCouponModal(false);
    }

    const showCouponRemoveModal = () => {
        SetRemoveCouponModal(true);
    }



    const editUsername = () => {
        SetEmailFieldDisabledStatus(false);
        SetuserNameFieldDisabledStatus(false);
    }
    return (
        <>
            <div className="signin-bg"></div>
            <div className="signin-main-wrap">
                <Header/>
                <div className="signin-container">
                    <h4>Let's Get Started!</h4>
                    {signUpError && (
                        <span style={{color: "red"}}>{signUpError}</span>
                        )}
                    <Form className="">
                        {/* <KOInput
                            id="firstname"
                            label="First Name"
                            name="firstname"
                            type="text"
                            onChange={(e) => { handleFirstnameChange(e); }}
                            value={firstnameValue}
                            placeholder="Enter your first name"
                            maxLength="60"
                            txtRef={refFirstname}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            errorMessage={firstnameError}
                        />
                        <KOInput
                            id="lastname"
                            label="Last Name"
                            name="lastname"
                            type="text"
                            onChange={(e) => { handleLastnameChange(e); }}
                            value={lastnameValue}
                            placeholder="Enter your last name"
                            maxLength="60"
                            txtRef={refLastname}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            errorMessage={lastnameError}
                        /> */}
                        <KOInputButtonGroup
                            id="email"
                            label="Email"
                            name="email"
                            type="text"
                            onChange={(e) => { handleEmailChange(e); }}
                            value={emailValue}
                            placeholder="Enter your email"
                            maxLength="80"
                            txtRef={refEmail}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            errorMessage={emailError}
                            btnLabel={'Edit'}
                            btnClick = {editUsername}
                        />

                        <KOInputButtonGroup
                            id="redeemcoupon"
                            label="Referral Coupon (Optional)"
                            name="redeemcoupon"
                            type="text"
                            placeholder="Enter coupon code"
                            btnstyle={redeemBtnStyle}
                            onChange={(e) => { handleCouponCodeChange(e); }}
                            value={couponCode}
                            btnDisabled={redeemBtnDisabled}
                            txtRef={refCouponcode}
                            errorMessage={couponcodeError}
                            btnClick={couponCheck}
                            disabled={couponCodeFldDisable}
                            showRemoveBtn={showCouponRemoveBtn}
                            maxLength="8"
                            removeBtnClick={showCouponRemoveModal}
                            showBtn={true}
                        />
                        
                        <KOInputButtonGroup
                            id="username"
                            label="Username"
                            name="username"
                            type="text"
                            onChange={(e) => { handleUsernameChange(e); }}
                            value={usernameValue}
                            placeholder="Enter your username"
                            maxLength="60"
                            txtRef={refUsername}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            errorMessage={usernameError}
                            //showBtn = {userNameFieldDisabledStatus}
                            //btnLabel={'Edit'}
                            //btnClick = {editUsername}
                         //   disabled={userNameFieldDisabledStatus}
                        />
                        <div className="note">
                            <ul>
                                <li>Should contain letters and numbers only</li>
                                <li>Should not start with a number</li>
                            </ul>
                        </div>
                        <KOPasswordinputgroup
                            id="password"
                            label="Password"
                            name="password"
                            maxLength="60"
                            onChange={(e) => { handlePasswordChange(e); }}
                            value={passwordValue}
                            txtRef={refPassword}
                            onKeyDown={(e) => enterKeySubmit(e)}
                            errorMessage={passwordError}
                        />
                        <div className="note">
                            <ul>
                                <li>Enter at least eight characters</li>
                                <li>Include at least one uppercase letter</li>
                                <li>Include at least one lowercase letter</li>
                                <li>Include at least one special symbol</li>
                                <li>Include at least one number</li>
                            </ul>
                        </div>
                         <div className="confirm-check-list">
                          <div className="checkgroup">
                                <KOCheckbox
                                    name={"terms"}
                                    label={"I agree to the Fantasy Sports Knockout"}
                                    id={"termscheck"}
                                    onKeyDown={(e) => enterKeySubmit(e)}
                                    onChange={(e)=>{handleTermsAndConditionsCheckbox(e)}}
                                />
                                 {TermsAndConditions()}
                                { termsAndConditionsCheckboxError && termsAndConditionsCheckboxError !== '' &&
                                    <div className="form-group">
                                        <span className='error-message'>{termsAndConditionsCheckboxError}</span>
                                    </div>
                                }
                            </div>
                            <div className="checkgroup">
                                <KOCheckbox
                                    name={"confirmage"}
                                    label={"I confirm that I am at least 18 years of age and I live in the United States."}
                                    id={"confirmage"}
                                    onKeyDown={(e) => enterKeySubmit(e)}
                                    onChange={(e)=>{handleConfirmAgeCheckbox(e)}}
                                    checked={confirmAgeCheckbox}
                                    errorMessage={confirmAgeCheckboxError}
                                /> 
                            </div>
                            <KOCheckbox
                                name={"receiveupdate"}
                                label={"I want to receive updates and promotions through email and or text (Optional)."}
                                id={"receiveupdate"}
                                checked={receiveUpdateCheckbox}
                                onKeyDown={(e) => enterKeySubmit(e)}
                                onChange={(e)=>{handleReceiveUpdateCheckbox(e)}}
                            />
                        </div> 
                        
                    </Form>
                    {/* {!geoLocationAvailable && <div style={{color: "red"}}>Your browser does not support Geolocation</div>} */}
                    
                    <div className="account-create d-flex align-items-center justify-content-between">
                    <Button variant="primary" onClick={signUp}>Create Account</Button>
                        <p>Already have an account? <Link to={pageURLs.root} title="Sign In" >Sign In</Link></p>

                    </div>
                    <div className="social-signin">
                        <h6>Or Sign In with</h6>
                        <div className="btn-sec">
                        <GoogleLogin
                                clientId={GOOGLE_APP_ID}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                jsSrc="https://apis.google.com/js/api.js"
                                scope="profile email"
                                errorMessage={googleLoginError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick} ><img src={googleicon} alt="KO"/>Google</Button>
                                )}
                               
                           />
                            {/* <FacebookLogin
                                appId={FB_APP_ID}
                                autoLoad={false}
                                fields="name,email"
                                scope="public_profile"
                                callback={fbLogin}
                                errorMessage={signUpError}
                                render={renderProps => (
                                    <Button variant="outline-primary" onClick={renderProps.onClick}><img src={fbicon} alt="KO"/>Facebook</Button>
                                )}
                            /> */}
                        </div>
                    </div>
                    
                    {/* <p style={{textAlign:'center'}}>By creating an account, you agree to our {TermsAndConditions()} and also confirm that you are a resident of the United States and are at least 18 years old.</p> */}
                    
                    <Modal show={showCouponSuccessModal} onHide={closeCouponSuccessModal} className="modal-coupon">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                        <h4 className="modal-title">{couponSuccessMessage}</h4>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showRemoveCouponModal} onHide={closeCouponRemoveModal} className="modal-coupon">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Are you sure you remove this coupon code?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeCouponRemoveModal}>Cancel</Button>
                        <Button variant="primary" onClick={couponRemove}>Yes</Button>
                    </Modal.Footer>
                </Modal>
                </div>
            </div>
            <div className="signin-copyright"><Copyright/></div>
        </>
    );

};

export default Signup;
