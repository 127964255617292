import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import KOOTPInput from "../components/KOOTPInput";
import Copyright from "../components/Copyright";
import "../styles/signin.scss";
import { pageURLs } from "../appData/pageURLs";
import { store } from "../appState/store";
import * as userController from "../controller/userController";

const OTPVerification = (props) => {
  const navigate = useNavigate();
  const [otpValue, setOtpValue] = useState([]);
  const userDetails = store.getState().user;
  const validateOTP = () => {
    let isValid = false;
    let OTP = "";
    try {
      OTP = otpValue.replace(/,/g, "");
      //check OTP has six digits
      if (/^\d{6}$/gm.test(OTP)) {
        isValid = true;
      }
    } catch (err) {}
    return { isValid, OTP };
  };
  const emailValidation = async () => {
    let response;
    const email = userDetails.email;
    if (!email) {
      alert("This email is not registered in the application.");
      navigate(pageURLs.signup);
    } else if (validateOTP().isValid) {
      response = await userController.emailValidation(validateOTP().OTP, email);
    } else {
      alert("Enter a valid OTP.");
    }
    if (response.success) {
      navigate(pageURLs.emailverified);
    } else {
      alert(response.message);
    }
  };

  const resendOTP = async () => {
    let response;
    if (userDetails.email) {
      response = await userController.resendOTP(userDetails.email);
    }
    alert(response.message);
  };

  const enterKeySubmit = (e) => {
    if (e.keyCode === 13) {
      emailValidation();
    }
  };

  return (
    <>
      <div className="signin-bg"></div>
      <div className="signin-main-wrap">
        <Header />
        <div className="signin-container request-wrap otp-verify">
          <div className="title-sec">
            <h4>Welcome {userDetails.first_name}</h4>
            <h5>Let's verify your email address</h5>
            <p>
              We have sent a code to {userDetails.email}. Please check your
              inbox and enter the 6-digit code found in the email below to
              verify your email address.
            </p>
          </div>
          <KOOTPInput
            onChange={(val) => {
              setOtpValue(val);
            }}
            onKeyDown={(e) => enterKeySubmit(e)}
          />
          <div className="account-create">
            <Button variant="primary" onClick={emailValidation}>
              Verify Email Address
            </Button>
            <Link onClick={resendOTP} to="#">
              Resend OTP
            </Link>
          </div>
          <div className="email-verify-link">
            <Link to="/signup">Entered a wrong email address?</Link>
          </div>
        </div>
      </div>
      <div className="signin-copyright">
        <Copyright />
      </div>
    </>
  );
};

export default OTPVerification;
