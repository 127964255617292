import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
import { Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import useSiteState from '../appState/siteState/useSiteState';
import * as userGameService from  '../services/userGamesService';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip"{...props}>Any TD (other than passing TDs) scored by an active player</Tooltip>
);

const UserRulesAndScoring = (props) => {
    const siteState = useSiteState();
    const [rulesAndScoringData, setRulesAndScoringData] = useState('');
    const contestData = props.contestData;
    const lineuStrategy = contestData.lineuStrategy;
    let contestId = props.contestId;

    useEffect(async () => {
        await userGameService.rulesAndScoring(contestId)
        .then(function(response){
            if(response.errorCode == 200) {
                setRulesAndScoringData(response.data)
            } else {
                setRulesAndScoringData([])
            }
                
        })
    }, []);
    


    return (
        <div className="lineupscore-container">
            <div className="user-lineup-formation">
                <div className="formation-block">
                    {lineuStrategy.total_qb ? <div className="player-count quarterback">
                        <div className="position"><h5>QUARTERBACK</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_qb}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_rb ? <div className="player-count runningback">
                        <div className="position"><h5>RUNNING BACK</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_rb}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_wr ? <div className="player-count widereceiver">
                        <div className="position"><h5>WIDE RECEIVER</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_wr}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_te ? <div className="player-count tightend">
                        <div className="position"><h5>TIGHT END</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_te}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_fl ? <div className="player-count flexplayer">
                        <div className="position"><h5>Flex PLAYER</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_fl}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_ki ? <div className="player-count kicker">
                        <div className="position"><h5>KICKER</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_ki}</h1></div>
                    </div> : ''}
                    {lineuStrategy.total_df ? <div className="player-count dst">
                        <div className="position"><h5>DST</h5></div>
                        <div className="count"><h1>{lineuStrategy.total_df}</h1></div>
                    </div> : ''}
                </div>
                {/* <div className="mt-1 text-end">
                    <a href="#" className="view">View Elimination Formula</a>
                </div> */}
            </div>
            <div className="scoring-info-wrap">
                <div className="score-data">
                    <h5>Custom Lineups and Scoring Detail</h5>
                    <div className="score-card-block">
                        <div className="card-wrap">
                            <div className="score-card">
                                <div className="score"><p>Passing Yards</p><p className="value">{rulesAndScoringData.off_yard_pass_pts ? rulesAndScoringData.off_yard_pass_pts.replace(/\.00$/,'') : 0}</p></div>
                                <div className="score"><p>Passing TDs</p><p className="value">{rulesAndScoringData.off_td_pass_pts ? rulesAndScoringData.off_td_pass_pts.replace(/\.00$/,'') : 0}</p></div>
                                <div className="score">
                                    <p>All Other TDs 
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                            <i className="ic-info"></i>
                                        </OverlayTrigger>
                                    </p>
                                    <p className="value">{rulesAndScoringData.off_td_all_other_pts ? rulesAndScoringData.off_td_all_other_pts.replace(/\.00$/,'') : 0}</p>
                                </div>
                                <div className="score"><p>All Turnovers</p><p className="value">{rulesAndScoringData.off_td_all_turnover_pts ? rulesAndScoringData.off_td_all_turnover_pts.replace(/\.00$/,'')  : 0}</p></div>
                            </div>
                        </div>
                        <div className="card-wrap">
                            <div className="score-card">
                                <div className="score"><p>Rushing Yards</p><p className="value">{rulesAndScoringData.off_yard_receive_pts ?  rulesAndScoringData.off_yard_receive_pts.replace(/\.00$/,'') : 0}</p></div>
                                <div className="score"><p>Receiving Yards</p><p className="value">{rulesAndScoringData.off_yard_receive_pts ?  rulesAndScoringData.off_yard_receive_pts.replace(/\.00$/,'') : 0}</p></div>
                                <div className="score"><p>Receptions</p><p className="value">{rulesAndScoringData.off_td_reception_pts ?  rulesAndScoringData.off_td_reception_pts.replace(/\.00$/,'') : 0}</p></div>
                                <div className="score"><p>All 2-Pt Conversions</p><p className="value">{rulesAndScoringData.off_td_2pt_conv_pts ?  rulesAndScoringData.off_td_2pt_conv_pts.replace(/\.00$/,'') : 0}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="score-data">
                    <div className="score-card-block">
                        <div className="card-wrap">
                            <h5>Kickers</h5>
                            <div className="score-card bg-blue">
                            {rulesAndScoringData.is_field_goal_on === 1 ? <div className="score"><p>FGs &lt;40</p><p className="value">{rulesAndScoringData.field_goal_0_39 ? rulesAndScoringData.field_goal_0_39.replace(/\.00$/,'') : 0 }</p></div> : ''}
                            {rulesAndScoringData.is_field_goal_on === 1 ?    <div className="score"><p>FGs 40-49</p><p className="value">{rulesAndScoringData.field_goal_40_49 ? rulesAndScoringData.field_goal_40_49.replace(/\.00$/,'') : 0}</p></div> : ''}
                            {rulesAndScoringData.is_field_goal_on === 1 ?    <div className="score"><p>FGs 50+</p><p className="value">{rulesAndScoringData.field_goal_50_99 ? rulesAndScoringData.field_goal_50_99.replace(/\.00$/,'') : 0}</p></div> : ''}
                                <div className="score"><p>Pats</p><p className="value">{rulesAndScoringData.kic_extra_pts ? rulesAndScoringData.kic_extra_pts.replace(/\.00$/,'') : 0}</p></div>
                            </div>
                        </div>
                        <div className="card-wrap">
                            <h5>DST</h5>
                            <div className="score-card bg-blue">
                                <div className="score"><p>All TDS</p><p className="value">{rulesAndScoringData.def_td_all_pts ? rulesAndScoringData.def_td_all_pts.replace(/\.00$/,''): 0 }</p></div>
                                <div className="score"><p>All Turnovers</p><p className="value">{rulesAndScoringData.def_td_all_turnover_pts ? rulesAndScoringData.def_td_all_turnover_pts.replace(/\.00$/,''): 0 }</p></div>
                                <div className="score"><p>Sacks</p><p className="value">{rulesAndScoringData.def_sack_pts ? rulesAndScoringData.def_sack_pts.replace(/\.00$/,''): 0 }</p></div>
                                <div className="score"><p>Safeties</p><p className="value">{rulesAndScoringData.def_safety_pts ? rulesAndScoringData.def_safety_pts.replace(/\.00$/,'') : 0 }</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserRulesAndScoring;
