import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import KOSelectBox from "../components/KOSelectBox";
import KOInput from "../components/KOInput";
import KORadiobutton from "../components/KORadiobutton";
import KOInputPts from "../components/KOInputPts";
import * as contestService from "../services/contestService";
import * as userGamesController from "../controller/userGamesController";
import { useNavigate } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";
import * as commonService from "../services/commonService";

const CreateLeagueStep3 = (props) => {
    let userSiteGameSubmittedData = props.UserSiteGameData;
    const editAction = props.editAction;
    let off_yard_pass_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_yard_pass_pts) ? userSiteGameSubmittedData[2].off_yard_pass_pts : 0;
    let off_yard_receive_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_yard_receive_pts) ? userSiteGameSubmittedData[2].off_yard_receive_pts : 0;
    let off_td_pass_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_td_pass_pts) ? userSiteGameSubmittedData[2].off_td_pass_pts : 0;
    let off_td_all_other_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_td_all_other_pts) ? userSiteGameSubmittedData[2].off_td_all_other_pts : 0;
    let off_td_all_turnover_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_td_all_turnover_pts) ? userSiteGameSubmittedData[2].off_td_all_turnover_pts : 0;
    let off_td_reception_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_td_reception_pts) ? userSiteGameSubmittedData[2].off_td_reception_pts : 0;
    let off_td_2pt_conv_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].off_td_2pt_conv_pts) ? userSiteGameSubmittedData[2].off_td_2pt_conv_pts : 0;
    let def_td_all_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].def_td_all_pts) ? userSiteGameSubmittedData[2].def_td_all_pts : 0;
    let def_td_all_turnover_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].def_td_all_turnover_pts) ? userSiteGameSubmittedData[2].def_td_all_turnover_pts : 0;
    let def_sack_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].def_sack_pts) ? userSiteGameSubmittedData[2].def_sack_pts : 0;
    let def_safety_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].def_safety_pts) ? userSiteGameSubmittedData[2].def_safety_pts : 0;
    let def_1pt_safety_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].def_1pt_safety_pts) ? userSiteGameSubmittedData[2].def_1pt_safety_pts : 0;
    let kic_extra_pts = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].kic_extra_pts) ? userSiteGameSubmittedData[2].kic_extra_pts : 0;
    let is_points_against_on = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].is_points_against_on) ? userSiteGameSubmittedData[2].is_points_against_on : false;
    let is_yards_against_on = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].is_yards_against_on) ? userSiteGameSubmittedData[2].is_yards_against_on : false;
    let is_field_goal_on = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && (userSiteGameSubmittedData[2].is_field_goal_on === true || userSiteGameSubmittedData[2].is_field_goal_on === false)) ? userSiteGameSubmittedData[2].is_field_goal_on : true;
    
    
    let pointAgainstPointArray = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].pointAgainstPointArray) ? userSiteGameSubmittedData[2].pointAgainstPointArray : [];
    let yardAgainstPointArray = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].yardAgainstPointArray) ? userSiteGameSubmittedData[2].yardAgainstPointArray : [];
    let kickerFieldGoalPointArray = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].kickerFieldGoalPointArray) ? userSiteGameSubmittedData[2].kickerFieldGoalPointArray : [];
    let is_custom_score_rule = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].is_custom_score_rule) ? userSiteGameSubmittedData[2].is_custom_score_rule : 0;
    let rulename = (is_custom_score_rule === 1) ? 'customscorerule' : 'standardscorerule';
    let standardRuleStatus = (!is_custom_score_rule) ? true : false;
    let isEditMode = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[2] && userSiteGameSubmittedData[2].pointAgainstPointArray && userSiteGameSubmittedData[2].pointAgainstPointArray.length) ? true : false;
    let loaderDisplay = (isEditMode) ? false : true;
    const maxImumValue = (process.env.REACT_LEAGUE_CREATION_MAXIMUM_POINT) ? process.env.REACT_LEAGUE_CREATION_MAXIMUM_POINT : 99;
    const [scoringRulesRadioValue, setScoringRulesRadioValue] = useState(rulename);
    const [flagStandardRule, setFalgStandadrRule] = useState(standardRuleStatus);
    const [offYardPassPts, setOffYardPassPts] = useState(off_yard_pass_pts);
    const [offYardRreceivePts, setOffYardRreceivePts] = useState(off_yard_receive_pts);
    const [offTdPassPts, setOffTdPassPts] = useState(off_td_pass_pts);
    const [offTdAllOtherPts, setOffTdAllOtherPts] = useState(off_td_all_other_pts);
    const [offTdAlTurnoverPts, setOffTdAlTurnoverPts] = useState(off_td_all_turnover_pts);
    const [offTdReceptionPts, setOffTdReceptionPts] = useState(off_td_reception_pts);
    const [offTd2ptConvPts, setOffTd2ptConvPts] = useState(off_td_2pt_conv_pts);
    const [defTdAllPts, setDefTdAllPts] = useState(def_td_all_pts);
    const [defTdAllTurnoverPts, setDefTdAllTurnoverPts] = useState(def_td_all_turnover_pts);
    const [defSackPts, setDefSackPts] = useState(def_sack_pts);
    const [defSafetyPts, setDefSafetyPts] = useState(def_safety_pts);
    const [def1ptSafetyPts, setDef1ptSafetyPts] = useState(def_1pt_safety_pts);
    const [kicExtraPts, setKicExtraPts] = useState(kic_extra_pts);
    const [kicFieldGoal1, setKicFieldGoal1] = useState(0);
    const [kicFieldGoal2, setKicFieldGoal2] = useState(0);
    const [kicFieldGoal3, setKicFieldGoal3] = useState(0);
    const [showDefaultLoader, setDefaultLoader] = useState(false);
    const [maximumSelectionOption, setMaximumSelectionOption] = useState([]);
    const [pointsAgainstOn, setPointsAgainstOn] = useState(is_points_against_on);
    const [yardsAgainstOn, setYardsAgainstOn] = useState(is_yards_against_on);
   
    const [fieldGoalOn, setFieldGoalOn] = useState(is_field_goal_on);
    let show_custom_options = false;
    
    const default_points_against_data = (pointAgainstPointArray.length) ? [...pointAgainstPointArray] : [
        { index: 0, minValue: 1, maxValue: maxImumValue, pointValue: 0, maximumOptions: [] }
    ];
    const default_yard_against_data = (yardAgainstPointArray.length) ? yardAgainstPointArray : [
        { index: 0, minValue: 1, maxValue: maxImumValue, pointValue: 0, maximumOptions: [] }
    ];
    const standard_default_kicker_field_data = [
        { index: 0, minValue: 0, maxValue: 39, pointValue: 3, maximumOptions: commonService.maxpointOptions(39) },
        { index: 1, minValue: 40, maxValue: 49, pointValue: 4, maximumOptions: commonService.maxpointOptions(49) },
        { index: 2, minValue: 50, maxValue: maxImumValue, pointValue: 5, maximumOptions: commonService.maxpointOptions(maxImumValue) }
    ];
    const default_kicker_field_goal_data = (kickerFieldGoalPointArray.length && is_custom_score_rule == 1) ? kickerFieldGoalPointArray : [
        { index: 0, minValue: 0, maxValue: 39, pointValue: 3, maximumOptions: commonService.maxpointOptions(39) },
        { index: 1, minValue: 40, maxValue: 49, pointValue: 4, maximumOptions: commonService.maxpointOptions(49) },
        { index: 2, minValue: 50, maxValue: maxImumValue, pointValue: 5, maximumOptions: commonService.maxpointOptions(maxImumValue) }
    ];
    
    const [pointAgainstOptionsArray, setPointAgainstOptionsArray] = useState(default_points_against_data);
    const [yardAgainstOptionsArray, setYardAgainstOptionsArray] = useState(default_yard_against_data);
    const [kickerFieldGoalOptionsArray, setkickerFieldGoalOptionsArray] = useState(default_kicker_field_goal_data);
    const [saveError, setSaveError] = useState("");
    const navigate = useNavigate();
    const contest_id = props.contest_id;
    if (!parseInt(contest_id)) {
        navigate(pageURLs.manageleague)
    }


    const pStyle = {
        fontSize: '13.5px',
    };

    const saveLeagueStep3 = async () => {
        setSaveError("");
        setDefaultLoader(true);
        let requestPayload = {
            "step": 3,
            "lg_contest_id": parseInt(contest_id),
            "is_custom_score_rule": (scoringRulesRadioValue == 'standardscorerule') ? 0 : 1,
            "off_yard_pass_pts": offYardPassPts,
            "off_yard_receive_pts": offYardRreceivePts,
            "off_td_pass_pts": offTdPassPts,
            "off_td_all_other_pts": offTdAllOtherPts,
            "off_td_all_turnover_pts": offTdAlTurnoverPts,
            "off_td_reception_pts": offTdReceptionPts,
            "off_td_2pt_conv_pts": offTd2ptConvPts,
            "def_td_all_pts": defTdAllPts,
            "def_td_all_turnover_pts": defTdAllTurnoverPts,
            "def_sack_pts": defSackPts,
            "def_safety_pts": defSafetyPts,
            "def_1pt_safety_pts": def1ptSafetyPts,
            "kic_extra_pts": kicExtraPts,
            "is_points_against_on": pointsAgainstOn,
            "is_yards_against_on": yardsAgainstOn,
            "is_field_goal_on": fieldGoalOn,
            "pointAgainstPointArray": (show_custom_options) ? pointAgainstOptionsArray : [],
            "yardAgainstPointArray": (show_custom_options) ? yardAgainstOptionsArray : [],
            "kickerFieldGoalPointArray": kickerFieldGoalOptionsArray,
            "editAction": editAction
        };
        let result = await userGamesController.saveUserGames(requestPayload);
        if (result.errorCode == 200) {
            props.updateLeagueContestId(result.data.lg_contest_id);
            props.updateFullUserGameData(requestPayload, 2);
            setDefaultLoader(false);
            props.changeActiveStepValue();
        } else {
            setSaveError(result.message);
            setDefaultLoader(false);
        }
    };

    const handleScoreRuleTypeChange = (e) => {
        setScoringRulesRadioValue(e.target.name);
        let standardLineUpRuleArray = sessionStorage.getItem('standardLineUpStandardRule');
        standardLineUpRuleArray = JSON.parse(standardLineUpRuleArray);
        if (e.target.name === 'standardscorerule') {
            setFalgStandadrRule(true);
            setFieldGoalOn(true);
            setkickerFieldGoalOptionsArray(standard_default_kicker_field_data);
        } else {
            setFalgStandadrRule(false);
        }
        setOffYardPassPts(standardLineUpRuleArray[0].off_yard_pass_pts);
        setOffYardRreceivePts(standardLineUpRuleArray[0].off_yard_receive_pts);
        setOffTdPassPts(standardLineUpRuleArray[0].off_td_pass_pts);
        setOffTdAllOtherPts(standardLineUpRuleArray[0].off_td_all_other_pts);
        setOffTdAlTurnoverPts(standardLineUpRuleArray[0].off_td_all_turnover_pts);
        setOffTdReceptionPts(standardLineUpRuleArray[0].off_td_reception_pts);
        setOffTd2ptConvPts(standardLineUpRuleArray[0].off_td_2pt_conv_pts);
        setDefTdAllPts(standardLineUpRuleArray[0].def_td_all_pts);
        setDefTdAllTurnoverPts(standardLineUpRuleArray[0].def_td_all_turnover_pts);
        setDefSackPts(standardLineUpRuleArray[0].def_sack_pts);
        setDefSafetyPts(standardLineUpRuleArray[0].def_safety_pts);
        setDef1ptSafetyPts(standardLineUpRuleArray[0].def_1pt_safety_pts);
        setKicExtraPts(standardLineUpRuleArray[0].kic_extra_pts);
        setKicFieldGoal1(standardLineUpRuleArray[0].kic_field_goal_1);
        setKicFieldGoal2(standardLineUpRuleArray[0].kic_field_goal_2);
        setKicFieldGoal3(standardLineUpRuleArray[0].kic_field_goal_3);
        setPointAgainstOptionsArray(default_points_against_data);
        setYardAgainstOptionsArray(default_yard_against_data);
        setkickerFieldGoalOptionsArray(default_kicker_field_goal_data);
        props.setScoringRuleType(e.target.name);
    }

    useEffect(async () => {
        let standardLineUpRuleArray = sessionStorage.getItem('standardLineUpStandardRule');
        if (!isEditMode || !standardLineUpRuleArray.length) {
            await contestService.getStandardScoringRules().then(async function (reponse) {
                if (reponse.data.length) {
                    if (is_custom_score_rule == false) {
                        setOffYardPassPts(reponse.data[0].off_yard_pass_pts);
                        setOffYardRreceivePts(reponse.data[0].off_yard_receive_pts);
                        setOffTdPassPts(reponse.data[0].off_td_pass_pts);
                        setOffTdAllOtherPts(reponse.data[0].off_td_all_other_pts);
                        setOffTdAlTurnoverPts(reponse.data[0].off_td_all_turnover_pts);
                        setOffTdReceptionPts(reponse.data[0].off_td_reception_pts);
                        setOffTd2ptConvPts(reponse.data[0].off_td_2pt_conv_pts);
                        setDefTdAllPts(reponse.data[0].def_td_all_pts);
                        setDefTdAllTurnoverPts(reponse.data[0].def_td_all_turnover_pts);
                        setDefSackPts(reponse.data[0].def_sack_pts);
                        setDefSafetyPts(reponse.data[0].def_safety_pts);
                        setDef1ptSafetyPts(reponse.data[0].def_1pt_safety_pts);
                        setKicExtraPts(reponse.data[0].kic_extra_pts);
                        setKicFieldGoal1(reponse.data[0].kic_field_goal_1);
                        setKicFieldGoal2(reponse.data[0].kic_field_goal_2);
                        setKicFieldGoal3(reponse.data[0].kic_field_goal_3);
                    }
                    sessionStorage.setItem('standardLineUpStandardRule', JSON.stringify(reponse.data));
                } else {
                    sessionStorage.setItem('standardLineUpStandardRule', []);
                }
                setDefaultLoader(false);
            })
        }
        props.setScoringRuleType(scoringRulesRadioValue);
        let maximumOptions = commonService.maxpointOptions(0);
        setMaximumSelectionOption(maximumOptions)
    }, []);

    const handleScoreChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        switch (name) {
            case 'passing':
                setOffYardPassPts(value);
                break;
            case 'receiving':
                setOffYardRreceivePts(value);
                break;
            case 'touchdownpassing':
                setOffTdPassPts(value);
                break;
            case 'allscored':
                setOffTdAllOtherPts(value);
                break;
            case 'turnover':
                setOffTdAlTurnoverPts(value);
                break;
            case 'receptions':
                setOffTdReceptionPts(value);
                break;
            case 'conversion':
                setOffTd2ptConvPts(value);
                break;
            case 'defensetouchdown':
                setDefTdAllPts(value);
                break;
            case 'defenseallturnover':
                setDefTdAllTurnoverPts(value);
                break;
            case 'defsacks':
                setDefSackPts(value);
                break;
            case 'defsafety':
                setDefSafetyPts(value);
                break;
            case 'def1ptsafety':
                setDef1ptSafetyPts(value);
                break;
            case 'kickerextrapoints':
                setKicExtraPts(value);
                break;
        }
    }
    const setPointsAgainstData = async (e) => {
        setPointsAgainstOn(e.target.checked);
    }

    const setYardsAgainstData = async (e) => {
        setYardsAgainstOn(e.target.checked);
    }

    const setFieldGoalData = async (e) => {
        setFieldGoalOn(e.target.checked);
    }
    //SHow next option data
    const setNextPointOptions = async (index, e, type) => {
        let currentOptionsArray = {};
        if (type === 'pointsagainst') {
            currentOptionsArray = [...pointAgainstOptionsArray];
        } else if (type === 'yardagainst') {
            currentOptionsArray = [...yardAgainstOptionsArray];
        } else if (type === 'kickerfieldgoal') {
            currentOptionsArray = [...kickerFieldGoalOptionsArray];
        }
        let selectedValue = parseInt(e.target.value);
        let changedIndex = parseInt(index);
        let currentTotalLength = currentOptionsArray.length;
        for (var i = currentTotalLength - 1; i >= 0; i--) { //If any changes in middle of data will reset entire data after that index
            if (i > changedIndex) {
                currentOptionsArray.splice(i, 1);
            }
        }
        currentOptionsArray[index]['maxValue'] = selectedValue;
        if (selectedValue < maxImumValue) {
            let newRowMin = selectedValue + 1;
            let maxStartFrom = parseInt(newRowMin) + 1;
            maxStartFrom = (maxStartFrom > maxImumValue) ? maxStartFrom - 1 : maxStartFrom;
            let maximumOptions = commonService.maxpointOptions(maxStartFrom);
            let newArray = [{ index: changedIndex + 1, minValue: newRowMin, maxValue: maxImumValue, pointValue: 0, maximumOptions: maximumOptions }];
            currentOptionsArray.push(...newArray);
        }
        if (type === 'pointsagainst') {
            setPointAgainstOptionsArray(currentOptionsArray)
        } else if (type === 'yardagainst') {
            setYardAgainstOptionsArray(currentOptionsArray)
        } else if (type === 'kickerfieldgoal') {
            setkickerFieldGoalOptionsArray(currentOptionsArray)
        }
    }
    //set the point
    const setPointsValue = async (index, e, type) => {
        let pointValue = e.target.value;
        let currentOptionsArray = [];
        if (type === 'pointsagainst') {
            currentOptionsArray = [...pointAgainstOptionsArray];
        } else if (type === 'yardagainst') {
            currentOptionsArray = [...yardAgainstOptionsArray];
        } else if (type === 'kickerfieldgoal') {
            currentOptionsArray = [...kickerFieldGoalOptionsArray];
        }
        currentOptionsArray[index]['pointValue'] = pointValue;
        if (type === 'pointsagainst') {
            setPointAgainstOptionsArray(currentOptionsArray);
        } else if (type === 'yardagainst') {
            setYardAgainstOptionsArray(currentOptionsArray)
        } else if (type === 'kickerfieldgoal') {
            setkickerFieldGoalOptionsArray(currentOptionsArray)
        }
    }

    const createPointsOptions = (index = 0, minValue = 0, maxValue = 0, pointValue = 0, maximumOptions = [], type = 'pointsagainst', disabledConstant) => {
        return <div className="score-field" key={type + '_' + index}>
            <p style={pStyle}>{minValue} min.-</p>
            <KOSelectBox
                id="maxpointselect"
                name="maxpointselect"
                type="number"
                options={(maximumOptions.length) ? maximumOptions : maximumSelectionOption}
                // disabled = {(disabledConstant && scoringRulesRadioValue !== 'standardscorerule') ? false : true}
                disabled={true}
                value={maxValue}
                onChange={function (e) { setNextPointOptions(index, e, type) }}
            />
            <KOInputPts
                id="maxpoint"
                name="maxpoint"
                type="number"
                disabled={(disabledConstant && scoringRulesRadioValue !== 'standardscorerule') ? false : true}
                value={pointValue}
                onChange={function (e) { setPointsValue(index, e, type) }}
            />
        </div>
    }
    
    return (
        <>
            <div className="field-container score-container" >
                <h4>Scoring rules for your League</h4>
                {showDefaultLoader && <div className="form-field lineup-form"><div className="loader-wrap"><div className="loader"></div></div></div>}
                <div className="form-field lineup-form">
                    <div className="form-group postion-radio-group">
                               <KORadiobutton
                                   name="standardscorerule"
                                   label="Standard Scoring Rules"
                                   id="standardscorerule"
                                   checked={scoringRulesRadioValue === 'standardscorerule'}
                                   onChange={handleScoreRuleTypeChange}
                               />
                               <KORadiobutton
                                   name="customscorerule"
                                   label="Custom Scoring Rules"
                                   id="customscorerule"
                                   checked={scoringRulesRadioValue === 'customscorerule'}
                                   onChange={handleScoreRuleTypeChange}
                               />
                           </div>       
                    <div className="score-rule">
                        <h3>OFFENSE</h3>
                        <h6>Each Yard</h6>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="passing"
                                name="passing"
                                label="Passing"
                                type="number"
                                disabled={flagStandardRule}
                                value={offYardPassPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="receiving"
                                name="receiving"
                                label="Receiving/Rushing"
                                type="number"
                                disabled={flagStandardRule}
                                value={offYardRreceivePts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        <h6>Touchdowns</h6>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="touchdownpassing"
                                name="touchdownpassing"
                                label="Passing"
                                type="number"
                                disabled={flagStandardRule}
                                value={offTdPassPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="allscored"
                                name="allscored"
                                label="All Other TD's scored"
                                type="number"
                                disabled={flagStandardRule}
                                value={offTdAllOtherPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="turnover"
                                name="turnover"
                                label="All Turnovers"
                                type="number"
                                disabled={flagStandardRule}
                                value={offTdAlTurnoverPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="receptions"
                                name="receptions"
                                label="Receptions (each)"
                                type="number"
                                disabled={flagStandardRule}
                                value={offTdReceptionPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="conversion"
                                name="conversion"
                                label="2 pt conversion (all types)"
                                type="number"
                                disabled={flagStandardRule}
                                value={offTd2ptConvPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        <h3>KICKER</h3>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="kickerextrapoints"
                                name="kickerextrapoints"
                                label="Extra Points"
                                type="number"
                                disabled={flagStandardRule}
                                value={kicExtraPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        <h3>DST</h3>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="defensetouchdown"
                                name="defensetouchdown"
                                label="All Touchdowns"
                                type="number"
                                disabled={flagStandardRule}
                                value={defTdAllPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="defenseallturnover"
                                name="defenseallturnover"
                                label="All Turnovers"
                                type="number"
                                disabled={flagStandardRule}
                                value={defTdAllTurnoverPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="defsacks"
                                name="defsacks"
                                label="Sacks"
                                type="number"
                                disabled={flagStandardRule}
                                value={defSackPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        <div className="multiple-select-box">
                            <KOInputPts
                                id="defsafety"
                                name="defsafety"
                                label="Safety"
                                type="number"
                                disabled={flagStandardRule}
                                value={defSafetyPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                            <KOInputPts
                                id="def1ptsafety"
                                name="def1ptsafety"
                                label="1-pt safety"
                                type="number"
                                disabled={flagStandardRule}
                                value={def1ptSafetyPts}
                                onChange={handleScoreChange}
                                step='0.01'
                            />
                        </div>
                        {(!flagStandardRule && show_custom_options) &&
                            <div className="score-switch">
                                <h6>Points Against</h6>
                                <Form.Check
                                    type="switch"
                                    id="general-switch"
                                    checked={(pointsAgainstOn) ? true : false}
                                    onChange={(e) => { setPointsAgainstData(e); }}
                                />
                            </div>}
                        {(!flagStandardRule && show_custom_options) &&
                            <div className="max-score-wrap">
                                {
                                    pointAgainstOptionsArray.map((item, index) => (
                                        createPointsOptions(index, item.minValue, item.maxValue, item.pointValue, item.maximumOptions, 'pointsagainst', pointsAgainstOn)
                                    ))
                                }
                            </div>}

                        {(!flagStandardRule && show_custom_options) &&
                            <div className="score-switch">
                                <h6>Yard Against</h6>
                                <Form.Check
                                    type="switch"
                                    id="general-switch"
                                    checked={(yardsAgainstOn) ? true : false}
                                    onChange={(e) => { setYardsAgainstData(e); }}
                                />
                            </div>}
                        {(!flagStandardRule && show_custom_options) &&
                            <div className="max-score-wrap">
                                {
                                    yardAgainstOptionsArray.map((item, index) => (
                                        createPointsOptions(index, item.minValue, item.maxValue, item.pointValue, item.maximumOptions, 'yardagainst', yardsAgainstOn)
                                    ))
                                }
                            </div>}

                        <div className="score-switch">
                            <h6>Field Goal {fieldGoalOn}</h6>
                            <Form.Check
                                type="switch"
                                id="general-switch"
                                checked={(fieldGoalOn) ? true : false}
                                disabled={flagStandardRule}
                                onChange={(e) => { setFieldGoalData(e); }}
                            />
                        </div>
                        <div className="max-score-wrap">
                            {
                                kickerFieldGoalOptionsArray.map((item, index) => (
                                    createPointsOptions(index, item.minValue, item.maxValue, item.pointValue, item.maximumOptions, 'kickerfieldgoal', fieldGoalOn)
                                ))
                            }
                        </div>
                    </div>
                    <div className="form-action"><Button variant="primary" onClick={saveLeagueStep3} >Save and Continue</Button></div>
                </div>
            </div>
        </>
    );

};

export default CreateLeagueStep3;