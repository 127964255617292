export const pageURLs = Object.freeze({
    root: '/',

    counter: '/counter',
    counterNew: '/counternew',
    contestTest: '/contesttest',

    login: '/login',
    forgotpassword: '/forgotpassword',
    signup: '/signup',
    emailrequest: '/emailrequest',
    otpverification: '/otpverification',
    signupstep1: '/signupstep1',
    signupstep2: '/signupstep2',
    signupstep3: '/signupstep3',
    signupstep4: '/signupstep4',
    signupstep5: '/signupstep5',
    lobby: '/lobby',
    manageleague: '/manageleague',
    joinedupcoming: '/joinedupcoming',
    joinedlive: '/joinedlive',
    joinedhistory: '/joinedhistory',
    mycontests: '/mycontests',
    myaccountinfo: '/myaccountinfo',
    joincontest: '/joincontest',
    usergamejoincontest: '/usergamejoincontest',
    createlineup: '/createlineup',
    usergamecreatelineup: '/usergamecreatelineup',
    usergameviewleaders: '/viewleaders',
    usergamestandings: '/standings',
    createleague: '/createleague',
    leaguedetails: '/leaguedetails',
    messageboard: '/messageboard',
    payment: '/payment',
    verifyaddress: '/verifyaddress',
    checkaddressstatus: '/checkaddressstatus',
    editleague: '/editleague',
    withdrawfund: '/withdrawfund',
    contestdetails: '/contestdetails',
    emailverified: '/emailverified',
    Verificationstart: '/verificationstart',
    verifyIdentity: '/verifyidentity',
    verificationfailed: '/verificationfailed',
    allcontest: '/allcontest',
    alluserleague: '/alluserleague',
});
