import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import Header from "../components/Header";
import { Link } from 'react-router-dom';
import Copyright from '../components/Copyright';
import { Stepper } from "react-form-stepper";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {   TermsAndConditions  } from '../utils/CommonHelper';

const SignupStep2 = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        sessionStorage.removeItem('duplicateentry');
    }, []);

	return (
		<>
			<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container request-wrap verification-wrap">
					<div className="title-sec">
                        <h4>You're almost there</h4>
                        <div className="stepper">
                            <Stepper
                                steps={[{ label: 'Step 1' }, { label: 'Step 2' }]}
                                activeStep={1}
                            />
                        </div>
                        <h5>Now let's verify your identity</h5>
                        <p>All users must be verified in order to participate in contests on Fantasy Sports Knockout.</p>
                    </div>
					
					<div className="account-create">
						<Button variant="primary" onClick={() => { navigate(pageURLs.verifyaddress); }}>Continue with Verification</Button>
                        <Link to={pageURLs.signupstep5}>Skip for now</Link>
					</div>
                    <div className="terms-note">
                        <p>Please note that in order to play in a paid contest, you must be physically located in an eligible state. You can find the list of 
                            eligible states in our  {TermsAndConditions()}. Unverified users may not play in any contests.</p>
                    </div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default SignupStep2;
