import React, { useState, useEffect } from "react";
import useCountdown from "../appState/counterState/useCountdown";

const LiveTimer = (props) => {

    const [contestDisplayStatus, setContestDisplayStatus] = useState(1);
 
    let contestStatus = '';
    if (props.Status == 1) {
        contestStatus = 'Live';
    } else if (props.Status == 2) {
        contestStatus = 'Live';
    } else if (props.Status == 3) {
        contestStatus = 'Upcoming';
    } else if (props.Status == 4 || props.Status == 7) {
        contestStatus = 'Expired';
    } else if (props.Status == 5) {
        contestStatus = 'Past';
    }
    let hours = 0;
    let minute = 0;
    let second = 0;
    if (props.countDownDate) {

        hours = (props.countDownDate.hours > 0) ? props.countDownDate.hours : hours;
        minute = (props.countDownDate.minutes > 0) ? props.countDownDate.minutes : minute;
        second = (props.countDownDate.seconds > 0) ? props.countDownDate.seconds : second;
    }

    const changeContestStatus = (status) => {
        setContestDisplayStatus(status);
        if (props.contestStatusChanged)
            props.contestStatusChanged(status);
    }

    return (
        <>{
        //JSON.stringify(props.countDownDate)}==props.Status=={props.Status}==={contestDisplayStatus}===={hours}---{minute}----{second}
}
            {props.Status === 3 ?
                <div className={contestDisplayStatus == 1 ? "live-timer" :"live-timer"}>
                    {contestDisplayStatus == 1 ? <span>Live in : </span> : <span>Live</span>}
                    <div className="countdown">{contestDisplayStatus == 1 ? <UpcomingContestTimer hour={hours} minute={minute} second={second} changeContestStatus={changeContestStatus} /> : ""}</div>
                </div> :
                <div className="live-timer">
                    <span>{contestStatus}</span>
                </div>
            }
        </>
    );
};


const UpcomingContestTimer = (props) => {
    const [hour, setHour] = useState(props.hour);
    const [minute, setMinute] = useState(props.minute);
    const [second, setSecond] = useState(props.second);

    useEffect(() => {
        if (hour == 0 && minute == 0 && second == 0) {
            props.changeContestStatus(2);
        } else {
            props.changeContestStatus(1);
        }
    }, [hour, minute, second])

    const getTime = () => {
        return { hour: props.hour, minute: props.minute, second: props.second };
    }

    useCountdown((data) => {
        setHour(data.hour);
        setMinute(data.minute);
        setSecond(data.second);
        
    }, getTime()    );

    if(hour > 24){
        const  days = Math.floor(hour / (24));
        const remainingHours = hour % 24; 
        return <>{days}d {remainingHours}h {minute}m {second}s</>;  
     } else {
         return <>{hour}h {minute}m {second}s</>;
     }
};

export default LiveTimer;
