import React, { useState, useEffect, useRef } from "react";
import KOInput from "./KOInput";
import * as commonService from "../services/commonService";

const KOOTPInput = props => {

    const [arrValue, set_arrValue] = useState([ "", "", "", "", "", "" ]);
    const arrRefInput = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        arrRefInput[0].current.focus();
    }, []);

    const isValidValue = (value) => {
        let result = false;
        value = !isNaN(value) ? parseInt(value) : value;
        if (value === "") {
            result = true;
        } else if (commonService.isNumber(value)) {
            result = true;
        }
        return result;
    }

    const handleValueChange = (pos, value) => {
        if (!isValidValue(value)){ 
            let stateCopy = arrValue.slice();
            stateCopy[pos] = '';
            set_arrValue(stateCopy);
            return;
        }
        let lastPos;
        let stateCopy = arrValue.slice();
        if (value.length < 1) {
            stateCopy[pos] = value;
            lastPos = pos - 1;
        } else {
            let inputArr = value.split("");
            let i;
            for (i = 0; i < inputArr.length; i++) {
                if (pos + i < stateCopy.length) {
                    stateCopy[pos + i] = inputArr[i];
                    lastPos = pos + i;
                }
                else break;
            }
        }
        if (lastPos < 0) {
            arrRefInput[0].current.focus();
        } else if (lastPos > 5) {
            arrRefInput[5].current.focus();
        } else {
            arrRefInput[lastPos].current.focus();
        }
        set_arrValue(stateCopy);
        props.onChange(stateCopy.toString());
    };

    return (
        <>
        <div className="otp-wrap">
            <div className="otp-col">
                <KOInput id="otp1" name="otp1" type="text" value={arrValue[0]} placeholder="-" onChange={(e) => handleValueChange(0, e.target.value)} txtRef={arrRefInput[0]} />
            </div>
            <div className="otp-col">
                <KOInput id="otp2" name="otp2" type="text" value={arrValue[1]} placeholder="-" onChange={(e) => handleValueChange(1, e.target.value)} txtRef={arrRefInput[1]} />
            </div>
            <div className="otp-col">
                <KOInput id="otp3" name="otp3" type="text" value={arrValue[2]} placeholder="-" onChange={(e) => handleValueChange(2, e.target.value)} txtRef={arrRefInput[2]} />
            </div>
            <div className="otp-col">
                <KOInput id="otp4" name="otp4" type="text" value={arrValue[3]} placeholder="-" onChange={(e) => handleValueChange(3, e.target.value)} txtRef={arrRefInput[3]} />
            </div>
            <div className="otp-col">
                <KOInput id="otp5" name="otp5" type="text" value={arrValue[4]} placeholder="-" onChange={(e) => handleValueChange(4, e.target.value)} txtRef={arrRefInput[4]} />
            </div>
            <div className="otp-col">
                <KOInput id="otp6" name="otp6" type="text" value={arrValue[5]} placeholder="-" onChange={(e) => handleValueChange(5, e.target.value)} onKeyDown={props.onKeyDown} txtRef={arrRefInput[5]} />
            </div>
        </div>
        <div className="form-group">
        {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
        </div>
        </>
    );
}

export default (KOOTPInput);