import React, { useState, useEffect } from 'react';
import useSiteState from '../appState/siteState/useSiteState';

function ContestTest() {

    const siteState = useSiteState();

    const change = () => {

        
        if (siteState.data.IsUserGame) {
            siteState.action.changeToSiteGame();
        } else {
            siteState.action.changeToUserGame();
        }
    }

    // useEffect(() => {
    //     siteState.action.changeToSiteGame();
    // }, []);

    // View: the UI definition
    return (
        <div>
            Value: {siteState.data.IsUserGame ? "UserGame" : "SiteGame"} {siteState.data.ContestList.length}
            <br />
            <button onClick={change}>Change</button>
        </div>
    );
}

export default ContestTest;
