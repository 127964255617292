import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import useSiteState from '../appState/siteState/useSiteState';
import TickIcon from "../assets/icons/tickicon";
import { store } from '../appState/store';


const AlertToastMessages = (props) => {
   
    const siteState = useSiteState();
    const siteData = store.getState().site

    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastMessageDelay, setToastMessageDelay] = useState(5000);


    useEffect(() => {
        if (props.toastMessage) {
            setShowToastSuccess(true);
            setToastSuccessMessage(props.toastMessage);
            
            setTimeout(function () {
                setShowToastSuccess(false)
                setToastSuccessMessage('')
                props.resetAlert('');
            }, toastMessageDelay)
        }
    }, [props.toastMessage])

    return (
        <>
            {toastSuccessMessage ? <ToastContainer position="top-end">
                <Toast className="toast-alert" onClose={() => setShowToastSuccess(false)} show={showToastSuccess} delay={toastMessageDelay} autohide>
                    <Toast.Header>
                    </Toast.Header>
                    <Toast.Body>{props.toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer> : '' }
        </>

    );
};

export default AlertToastMessages;
