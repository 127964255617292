import React from "react";


const ChevrontoggleIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.35" height="13.871" viewBox="0 0 8.35 13.871">
            <path id="Path_10413" data-name="Path 10413" d="M-1405.5,1172.544l6.229,6.229L-1405.5,1185" transform="translate(1406.207 -1171.837)" fill="none" stroke="#0883c6" stroke-width="2" />
        </svg>

    );

}

export default ChevrontoggleIcon;