import { useState, useEffect, useRef } from "react";
import useInterval from "./useInterval";

function useCountdown(callback, time) {
    const savedCallback = useRef();
    const [hour, setHour] = useState(time.hour);
    const [minute, setMinute] = useState(time.minute);
    const [second, setSecond] = useState(time.second);
    const [isRunning, setIsRunning] = useState(true);

    const countDown = () => {
        if (second > 0) {
            setSecond(second - 1);
        } else if (minute > 0) {
            setSecond(59);
            setMinute(minute - 1);
        } else if (hour > 0) {
            setSecond(59);
            setMinute(59);
            setHour(hour - 1);
        } else {
            setHour(0);
            setMinute(0);
            setSecond(0);
            setIsRunning(false);
        }
    };

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (time === null) {
            setIsRunning(false);
        } else {
            setIsRunning(true);
        }
    }, [time]);

    useInterval(() => {
            countDown();
            savedCallback.current({hour, minute, second});
        },
        isRunning ? 1000 : null,
        // passing null stops the interval
    );
}

export default useCountdown;
