import React from 'react';
import { Form } from 'react-bootstrap';

const KORadiobutton = props => {
  return (
    <>
    <Form.Check 
        name={props.name?props.name:""}
		type="radio"
        id={props.id}
        label={props.label} 
        onChange={props.onChange}
        checked={props.checked}
        value={props.value}
        disabled={props.disabled}
    />
    { props.errorMessage && props.errorMessage !== '' &&
        <div className="form-group">
            <span className='error-message'>{props.errorMessage}</span>
        </div>
    }
    </>
  );
}

export default (KORadiobutton);
