import React from 'react';

const Viewbtn = props => {

	return (
		<button
			type={props.type}
			className="btn view-btn"
			disabled={props.disabled}
            onClick={props.onClick}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="14.637" height="10.942" viewBox="0 0 14.637 10.942">
				<g id="eye" transform="translate(1 1)">
					<path id="Path_10104" data-name="Path 10104" d="M1,8.471S3.3,4,7.318,4s6.318,4.471,6.318,4.471-2.3,4.471-6.318,4.471S1,8.471,1,8.471Z" transform="translate(-1 -4)" fill="none" stroke="#00001C" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
					<ellipse id="Ellipse_25" data-name="Ellipse 25" cx="1.723" cy="1.677" rx="1.723" ry="1.677" transform="translate(4.475 2.794)" fill="none" stroke="#00001C" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
				</g>
			</svg>

		</button>
	);
}

export default (Viewbtn);
