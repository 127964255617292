import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Offcanvas, Form, Modal } from "react-bootstrap";
import TsevoUserGamePayment from "../components/TsevoUserGamePayment";
import * as commonService from "../services/commonService";
import * as userGamesController from "../controller/userGamesController";
import { useNavigate } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";
import * as transactionController from "../controller/transactionController"
import * as userController from "../controller/userController"
import useGeoLocation from "../utils/useGeoLocation";
import { checkIpFormat } from '../utils/CommonHelper';


const CreateLeagueStepPayment = (props) => {
    const location = useGeoLocation();

    const user = useUserState();
    const editAction = props.editAction;
	const [show4, setShow4] = useState(true);
    const [inviteEmail, setInviteEmail] = useState("");
    const [invitedEmailList, setInvitedEmailList] = useState([]);
    const [inviteEmailAdd, setInviteEmailAdd] = useState([]);
    const [inviteEmailError, setInviteEmailError] = useState("");
    const [paymentSession, setPaymentSession] = useState("");
    const [paymentProcessFlag, SetPaymentProcessFlag] = useState(false);
    const [showLoader, SetShowLoader] = useState(true);
    const [stepState, setStepState] = useState("");
    const [showDeleteEmailConfirmModal, setDeleteEmailConfirmModal] = useState(false);
    const deleteEmailConfirmModalClose = () => setDeleteEmailConfirmModal(false);
    const deleteEmailConfirmModalShow = () => setDeleteEmailConfirmModal(true);
    const [paymentError, SetPaymentError] = useState('');
    const [cordinates, setCordinates] = useState({});
    const [showLocationRequiredPopup, setLocationRequiredPopup] = useState(false);
    const [tsevoReconfirmationMessage, settsevoReconfirmationMessage] = useState('Allow the browser to access your location and complete the payment?');
    const [tsevoRefreshMessage, settsevoRefreshMessage] = useState('Permission to access your location is required to complete the payment. Please allow the browser to access your location. After that please click refresh button.');

    const [geoLocationAvailable, setGeoLocationAvailable] = useState(false);
    const [geoLocationEnabled, setGeoLocationEnabled] = useState(false);
    const [showLocationDisabledMsg, setLocationDisabledMsg] = useState(false);
    const [paymentSessionURL, setPaymentSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);

    const paymentprocess = "PaymentProcess";
    const refInviteEmail = useRef(null);
    const contest_id = props.contest_id;
    const navigate = useNavigate();
    let isGeolocationAvailable = false;
    let isGeolocationEnabled = false;
    let coords = '';
    //For payment purpose we need to access geo location
    // const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp } =
    //     useGeolocated({
    //         positionOptions: {
    //             enableHighAccuracy: false,
    //         },
    //         userDecisionTimeout: 5000,
    //         userDecisionTimeout: Infinity,
    //         watchLocationPermissionChange : true
    // });


    const makePayment = async (cordinatesData) => {
        SetPaymentProcessFlag(true)
        SetShowLoader(true)
        SetPaymentError('');
        await userController.getUserIp()
            .then(async function(ipdata) {
                let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                if(customerIp) {
                    await transactionController.makeUserGamePayment(customerIp, cordinatesData, contest_id)
                        .then(async function(orderData) {
                        if(orderData.errorCode == 200) {
                            let sessionID = orderData.data.sessionID;
                            let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                            if (sessionID !== null) {
                                setPaymentSession(sessionID);
                                setPaymentSessionURL(orderData?.data?.sessionUrl)
                                let isIpv4 = checkIpFormat(customerIp);
                                setisIpisV4(isIpv4)
                                setStepState(paymentprocess);
                                SetShowLoader(false)
                            } else {
                                SetPaymentProcessFlag(false)
                                SetShowLoader(false)
                            }
                        } else {
                            let is_state_legal = orderData.data.isleagalState ? orderData.data.isleagalState : 0;
                            SetPaymentProcessFlag(false)
                            SetShowLoader(false)
                            SetPaymentError(orderData.message);
                        }
                    })
                } else {
                    SetPaymentProcessFlag(false)
                    SetShowLoader(false)
                    SetPaymentError("Could not process the request. Please try again later.");
                }
        })
    }

    const showPaymentProcessModal = async () => {
        //props.changeActiveStepValue();
        props.setPaymentDone(true)
    }

    const enableLocationPopup = () => {
        setLocationRequiredPopup(false);
    }

    useEffect(() => {
        if(location.loaded && !location.errorFlag && !paymentSession) {
            Locationsuccess(location.coordinates)
        } else  if(location.loaded && location.errorFlag && !paymentSession) {
            Locationerror()
        }
    }, [location]);
	
    const cancelLocationRequiredPopup = () => {
        SetPaymentProcessFlag(false)
        SetShowLoader(false);
        setLocationRequiredPopup(false);
        setLocationDisabledMsg(true)
    }
      const Locationsuccess = async (cordinatesData) => {
       // console.log(gotPosition)
        setGeoLocationAvailable(true);
        setGeoLocationEnabled(true);
        setLocationDisabledMsg(false)
        setCordinates(cordinatesData);
        makePayment(cordinatesData)
      
    };
      
    const Locationerror = async (err) => {
        setLocationRequiredPopup(true);
    };

    const ForceLocationerror = async (err) => {
        SetPaymentProcessFlag(false)
        SetShowLoader(false);
        setLocationDisabledMsg(true)
    };

    const refreshTheloCationCheck = () => {
        SetShowLoader(true);
    }
	return (
		<>
            <div className="field-container payment-container" style={{ display: show4 ? "block" : "none" }} >
                <div className="invite-details">
                    <h4>Payment</h4>
                        <div className="payment-data">
                            {/* {(showLocationDisabledMsg && !showLoader) ? <div style={{color: "red"}}>{tsevoRefreshMessage} </div> : ''} */}
                            
                           {!paymentError && <div className="tsevo-container">
                                {(stepState === paymentprocess) &&
                                    <>
                                        <TsevoUserGamePayment sessionID={paymentSession} tsevoSessionURL = {paymentSessionURL} isIpv4= {isIpisV4}/>
                                    </>
                                }
                                {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                <div class="form-action"><button type="button" class="btn btn-primary hide" id="paymentSuccessProcessed" onClick={showPaymentProcessModal}>Invite friends</button></div>
                            </div> }
                            {paymentError && <span style={{color: "red"}}>{paymentError}</span>}
                            {paymentError && <div class="form-action"><button type="button" class="btn btn-primary " onClick={makePayment}>Try again</button></div>}
                        </div>
                </div>
            </div>

            <Modal show={showLocationRequiredPopup} onHide={cancelLocationRequiredPopup} className="modal-predict tsevo-flow">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">{tsevoReconfirmationMessage}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={enableLocationPopup}>Allow</Button>
                        <Button variant="primary" onClick={cancelLocationRequiredPopup}>Don't Allow</Button>
                    </Modal.Footer>
                </Modal>
		</>
	);

};

export default CreateLeagueStepPayment;