import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import PlusIcon from '../assets/icons/plusicon';
import MinusIcon from '../assets/icons/minusicon';
import LockedIcon from '../assets/icons/lockedicon';
import PlayerIcon from '../assets/icons/playericon';
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import useUserState from "../appState/userState/useUserState";
import { store } from '../appState/store';
import PlayerInjured from '../assets/icons/playerinjured';
import PlayerNews from "../assets/icons/playernews";
import PlayerStats from '../components/PlayerStats';
import ByeWeek from '../assets/icons/ByeWeek';


const UsergameKOPlayerCard = (props) => {

  const { year, time } = useDate();
  const siteState = useSiteState();
  const userState = useUserState();
  const [showplayermodal, setPlayerModal] = useState(false);
  const [showPlusIcon, setShowPlusIcon] = useState(true);
  const [cardPlayers, setCardPlayers] = useState([]);
  const [allcardPlayers, setAllCardPlayers] = useState([]);
  const [showLineUpRemovalconfirmmodal, setShowLineUpRemovalconfirmmodal] = useState(false);
  const [index, setIndex] = useState(1);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);

  const [playersInLineUp, setPlayersInLineUp] = useState([]);
  const [missingPosition, setMissingPosition] = useState(-1);


  const handleConfirmModalClose = () => setShowLineUpRemovalconfirmmodal(false);
  const userId = userState.data.user.user_id;
  const current_season_week = props.current_season_week;


  useEffect(() => {

    if (playersInLineUp.length > 0) {
      let currentPositions = [];
      let defaultPositions = [];

      for (let k = 0; k < playersInLineUp.length; k++) {
        currentPositions.push(playersInLineUp[k].player_card_id);
        defaultPositions.push(k)
      }
      currentPositions.sort();
      let newPosition = 0;
      for (let k = 0; k < defaultPositions.length; k++) {
        if (currentPositions[k] != defaultPositions[k]) {
          newPosition = k;
          break;
        } else {
          newPosition = k + 1;
        }
      }
      if (defaultPositions.length == 10 || defaultPositions.length == 0)
        newPosition = 0;

      setMissingPosition(newPosition)
    }
  }, [playersInLineUp])

  useEffect(() => {
    if (missingPosition > -1) {
      props.callNextPosition(missingPosition)
    }
  }, [missingPosition])


  const addedPlayersIds = [];
  const lockedPlayersIds = [];


  props.lineUpPlayers.map(function (item, index) {
    addedPlayersIds.push(item.player_id);
    if (item.is_locked) {
      lockedPlayersIds.push(item.player_id);
    }
  })

  var addedTeamKeys = props.lineUpPlayers.filter(function (el) {
    return (el.team_key && el.player_position === 'DF')
  }).map(function(obj) { return obj.team_key; });

  var removedPlayersId = props.removedLineupPlayers.filter(function (el) {
    return (el.player_id > 0 && el.player_position !== 'DF')
  }).map(function(obj) { return obj.player_id; });

  var removedTeams = props.removedLineupPlayers.filter(function (el) {
    return (el.team_key && el.is_team === 1)
  }).map(function(obj) { return obj.team_key; });

  const [showLoader, setLoader] = useState(false);
  const handlePlayerModalClose = () => {
    setPlayerModal(false);
  }

  const handlePlayerModalShow = () => {
    setPlayerModal(true);
  }

  const handlePlayerPositionBackAdd = async (e) => {
    setLoader(true);
    setShowPlusIcon(false);
    let lineUpNumber = props.lineupno;
    let lineUpWeek = props.lineupwk;
    let playerPosition = props.position;
    let contestId = sessionStorage.getItem('contestId');
    let teamId = props.teamId;
    let playerId = props.playerId;
    let flag = 1;
    let is_team;
    //props.resetSearchBox()
    sessionStorage.setItem('defaultCard', false);
    siteState.action.storeLineUpDefault(false);
    let playerDetails;
    let placingPosition;
    placingPosition = props.positionPlaceId;
    let playerCardResponse = [];
    let default_player_card = JSON.parse(sessionStorage.getItem('default_player_card'));

    
    props.addPlayersToLineup(props)
    setLoader(false);
  }

  const handlePlayerPositionBackMinus = (e) => {
    setSelectedPlayerData(props)
    setShowLineUpRemovalconfirmmodal(true)
  }

  const removeSelectedPlayer = async () => {
    let contestId = sessionStorage.getItem('contestId');
    setShowLineUpRemovalconfirmmodal(false);
    let playerDataArray = {
      player_id: selectedPlayerData.playerId,
      player_position: selectedPlayerData.position,
      player_card_id: props.positionPlaceId,
      lg_contest_id: contestId,
      lineup_week: selectedPlayerData.lineupwk,
      lineup_number: selectedPlayerData.lineupno,
      team_id : (selectedPlayerData.position == 'DF') ? selectedPlayerData.teamId : 0

    }
    let result = await props.removePlayersFromLineup(playerDataArray);
    props.updatePlayersCardDisplay();
  }

  let ContestStatus;
  if (siteState.data.Lobby.ContestList) {
    ContestStatus = siteState.data.Lobby.ContestList.map((row) => {
      return row.ContestStatus;
    });
    ContestStatus = ContestStatus ? ContestStatus[0] : 0;
  }

  let is_added = props.is_added ? props.is_added : 0;
  let inLineUp = addedPlayersIds.includes(props.playerId) ? true : false;
  let isLockedPlayer = lockedPlayersIds.includes(props.playerId) ? true : false;
  let isLineupLocked = (current_season_week > props.lineupwk || props.isAlreadyPlayedInweek) ? true : false;
  if (props.lineupwk == props.current_week && (props.is_player_locked == 1 || props.is_team_locked == 1)) {
    isLineupLocked = true;
  }
  //Same team cant add more than one time
  if(props.position == 'DF' && addedTeamKeys.includes(props.team)) {
    inLineUp = true;
  }
  //Player already in lineup but temporary removed from local data (For handling temporary removed data)
  if(props.position !== 'DF' && is_added && removedPlayersId.includes(props.playerId)) {
    is_added = false;
  }
  if(props.position === 'DF' && is_added && removedTeams.includes(props.team)) {
    is_added = false;
  }

  

  let is_player_injured = (props.is_player_injured) ? true : false;
  let is_player_out = (props.is_player_injured == 1 || props.is_player_injured == 6 || props.is_player_injured == 0) ? false : true; // Injury status =1('QUESTIONABLE'), 6 = 'DOUBTFUL' in all other cases player cant able to add to card
  let show_byweek = false;
  if(props.byeweek == props.lineupwk) {
    show_byweek = true;
  }

  return (

    <div className={"playercard" + " " + (props.className) + ((inLineUp || is_added) ? ' selected' : '')} id={props.uniqueId}>
      <div className="profile-img">
      <figure>{props.playername ? <img src={props.figure} onClick={handlePlayerModalShow}/> : <img src={props.figure} />}</figure>
        {(props.is_player_news_avail) ? <span onClick={handlePlayerModalShow} className="news-icon"><PlayerNews onClick={handlePlayerModalShow}/></span> : ''}
      </div>
      <div className='player-info'>
      <div className='name'><h5>{props.playername}</h5>{props.position && <h6>{props.position}</h6>}</div>
        <div className='team'><p>Team {props.team}</p><span></span></div>
          <>
            {is_player_injured ? <PlayerInjured injuryStatus = {props.is_player_injured}/> : ""}
          </>
          <>
            {show_byweek ? <ByeWeek /> : ""}
          </>
        <div className='player-activity' >
            <div className="count">{props.player_season_score ? props.player_season_score.replace(/\.00$/,'') : 0}</div>
            {(!inLineUp && !isLockedPlayer && !isLineupLocked && !is_added) && (ContestStatus != 5) ?
              <>
                {(!is_player_out && !show_byweek) ? <Button variant="outline-primary" disabled={props.enablePlusIcon ? false : true} className='btn-action' id={props.positionPlaceId} onClick={handlePlayerPositionBackAdd}>
                  <PlusIcon />
                </Button> : '' }
              </>
              :
              (!isLockedPlayer && !isLineupLocked && inLineUp) && (ContestStatus != 5) ?
                <>
                  <Button variant="outline-primary" className='btn-action' id={props.positionPlaceId} onClick={handlePlayerPositionBackMinus}>
                    <MinusIcon />
                  </Button>
                </> : ''
            } 

          {/* If line up locked or player is locked or player is added any other week of contest and not added in current week we are showing lock icon */}
          {((!isLockedPlayer && !isLineupLocked && !inLineUp && is_added) || ((isLockedPlayer || isLineupLocked) && inLineUp)) &&
            <div >
              <LockedIcon />
            </div>
          }
          {/* If line up locked  but player is not added in seelcted week we are showing lock icon along with player icon */}
          {(!isLockedPlayer && isLineupLocked && !inLineUp && !is_added) && <div >
            <PlayerIcon /><LockedIcon />
          </div>}
          {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
        </div>

      </div>
      {showplayermodal && <PlayerStats showplayermodal = {showplayermodal} handlePlayerModalClose = {handlePlayerModalClose} playerData ={props}/> }

      <Modal show={showLineUpRemovalconfirmmodal} onHide={handleConfirmModalClose} className="modal-predict">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="modal-title">Are you sure you want to remove Player?</h4>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={removeSelectedPlayer}>Yes</Button>
          <Button variant="link" onClick={handleConfirmModalClose}>Cancel</Button>
          
        </Modal.Footer>
      </Modal>
    </div>

  );
}

export default (UsergameKOPlayerCard);
