import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";
import { TermsAndConditions, Privacy, FaqLink, HowToPlayLink, ContactLink, EligibilityLink } from '../utils/CommonHelper';

const Copyright = (props) => {
  return (
    <div className="copyright">
      {/* <div className="footer-menu">
        {Privacy()}
        {TermsAndConditions()}
        {HowToPlayLink()}
        {FaqLink({ 'label': 'FAQs' })}
        {ContactLink()}
        {EligibilityLink()}
      </div> */}
      
      Copyright © {new Date().getFullYear() } Fantasy Sports Knockout
    </div>
  );
};

export default Copyright;
