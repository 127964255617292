import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function saveUserGames(payLoad) {
    let editAction = payLoad.editAction ? payLoad.editAction : false;
    if(editAction) {
        return await apiService.apiCall(endPoints.UPDATE_USER_SITE_GAMES, 'PUT', payLoad);
    } else {
        return await apiService.apiCall(endPoints.CREATE_USER_SITE_GAMES, 'POST', payLoad);
    }
}

export async function removeUserGames(leagueContestId) {
    return await apiService.apiCall(endPoints.DELETE_USER_SITE_GAMES+'/'+leagueContestId, 'DELETE');
}

export async function fetchLineUpStrategy(leagueContestId) {
    return await apiService.apiCall(endPoints.FETCH_USERGAME_LINEUP_STRATEGY+'/'+leagueContestId, 'GET');
}

export async function fetchInvitedEmails(leagueContestId) {
    return await apiService.apiCall(endPoints.USER_GAME_INVITEED_EMAIL_LIST+'/'+leagueContestId, 'GET');
}
export async function getRecievedMessages(messageListPageParams) {
    return await apiService.apiCall(endPoints.GET_RECIEVED_MESSAGES+'?page='+ messageListPageParams.page,'GET');
}
export async function readLeagueMessage(contestId, readOn) {
    return await apiService.apiCall(endPoints.READ_LEAUGE_MESSAGE, 'POST',
        {
            lg_contest_id: contestId,
            read_on: readOn
        });
}
export async function getSendMessages(messageListPageParams) {
    return await apiService.apiCall(endPoints.GET_SENT_MESSAGES+'?page='+ messageListPageParams.page,'GET');
}
export async function sendLeaugeMessage(contestId, message) {
    return await apiService.apiCall(endPoints.SEND_LEAUGE_MESSAGE, 'POST',
        {
            league_id: contestId,
            message_content: message
        });
}
export async function getUnreadMessages() {
    return await apiService.apiCall(endPoints.GET_UNREAD_MESSAGE,'GET');
}
export async function rulesAndScoring(contestId) {
    return await apiService.apiCall(endPoints.USER_GAMES_RULES_AND_SCORING, 'POST',
        {
            contest_id: contestId,
        });
}