import React from "react";


const HidePasswordactiveIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.404" height="18.828" viewBox="0 0 22.404 18.828">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_1770" data-name="Rectangle 1770" width="22.404" height="18.828" transform="translate(0 0)" fill="none" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </clipPath>
            </defs>
            <g id="Group_16332" data-name="Group 16332" transform="translate(0 0)">
                <g id="Group_16331" data-name="Group 16331" clip-path="url(#clip-path)">
                    <path id="Path_10389" data-name="Path 10389" d="M1.2,10.414s4-6,10-6,10,6,10,6A17.069,17.069,0,0,1,19.34,12.6c-1.486,1.527-4.43,3.818-8.138,3.818C5.2,16.414,1.2,10.414,1.2,10.414Z" fill="none" stroke="#555" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
                    <circle id="Ellipse_59" data-name="Ellipse 59" cx="4" cy="4" r="4" transform="translate(7.202 4.414)" fill="none" stroke="#555" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
                    <line id="Line_74" data-name="Line 74" y1="16" x2="16" transform="translate(2.202 1.414)" fill="none" stroke="#555" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                </g>
            </g>
        </svg>

    );

}

export default HidePasswordactiveIcon;