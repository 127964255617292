import React from "react";
import { sortOrderOptions } from "../../appData/sortOrderOptions";


const SortingIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10">
            { (props.sortOrder === sortOrderOptions.None) &&
                <g id="Group_11345" data-name="Group 11345" transform="translate(-969 -177)">
                    <path id="Polygon_1" data-name="Polygon 1" d="M4,0,8,4H0Z" transform="translate(969 177)" fill="#FCFEFF" />
                    <path id="Polygon_2" data-name="Polygon 2" d="M4,0,8,4H0Z" transform="translate(977 187) rotate(180)" fill="#FCFEFF" />
                </g>
            }
            { (props.sortOrder === sortOrderOptions.Ascending) &&
                <g id="Group_11345" data-name="Group 11345" transform="translate(-969 -177)">
                    <path id="Polygon_1" data-name="Polygon 1" d="M4,0,8,4H0Z" transform="translate(969 177)" fill="#FCFEFF" />
                </g>
            }
            { (props.sortOrder === sortOrderOptions.Descending) &&
                <g id="Group_11345" data-name="Group 11345" transform="translate(-969 -177)">
                    <path id="Polygon_2" data-name="Polygon 2" d="M4,0,8,4H0Z" transform="translate(977 187) rotate(180)" fill="#FCFEFF" />
                </g>
            }
        </svg>
    );
}

export default SortingIcon;