import React, { useEffect } from 'react';

const commonUrls = {
    FaqUrl: "https://www.fantasysportsknockout.com/info/faqs/",
    ContactUrl: "https://www.fantasysportsknockout.com/info/contact-us",
    PrivacyUrl: "https://www.fantasysportsknockout.com/info/privacy-policy",
    Play: "https://www.fantasysportsknockout.com/info/how-to-play",
    TermsUrl: "https://www.fantasysportsknockout.com/info/terms-conditions",
    EligibilityUrl: "https://www.fantasysportsknockout.com/info/eligibility",
}

const FaqUrl = (params = []) => {
    return (commonUrls.FaqUrl);
};

const ContactUrl = (params = []) => {
    return (commonUrls.ContactUrl);
};

const HowToPlay = (params = []) => {
    return (commonUrls.Play);
};

const FaqLink = (params = []) => {
    return (<a href={commonUrls.FaqUrl} target="_blank">{params && params.label && params.label != '' ? params.label : 'FAQs page'}</a>);
};

const TermsAndConditions = (params = []) => {
    return (<a href={commonUrls.TermsUrl} target="_blank">Terms &amp; Conditions</a>);
};

const Privacy = (params = []) => {
    return (<a href={commonUrls.PrivacyUrl} target="_blank">Privacy</a>);
};

const HowToPlayLink = (params = []) => {
    return (<a href={commonUrls.Play} target="_blank">How To Play</a>);
};

const ContactLink = (params = []) => {
    return (<a href={commonUrls.ContactUrl} target="_blank">Contact</a>);
};

const EligibilityLink = (params = []) => {
    return (<a href={commonUrls.EligibilityUrl} target="_blank">Eligibility</a>);
};

const TermsAndConditionsIdentity = (params = []) => {
    return (<a href={commonUrls.TermsUrl} target="_blank">Terms of Services</a>);
};

const checkIpFormat = (ip) => {
    let ipv4 = true;
    if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ip)) {
        ipv4 = true
    } else if (/^[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7}$/.test(ip)) {
        ipv4 = false
    } 

    return ipv4;
}
export { FaqLink, TermsAndConditions, Privacy, FaqUrl, ContactUrl, HowToPlay, HowToPlayLink, ContactLink, EligibilityLink, TermsAndConditionsIdentity, checkIpFormat };