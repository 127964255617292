import React from 'react';
// import Moment from 'react-moment';
import moment from "moment-timezone"

const PlayerGameStatus = props => {
    let statusLabel = '';
    if(props.gameData.is_over ) {
        statusLabel = 'Final';
    } else if(props.gameData.is_overtime) {
        statusLabel = 'OT';
    } else if(props.gameData.is_inprogress) {
        statusLabel = 'Live';
    }
    let quater_label = props.gameData.quater_desc;
   // console.log(props.gameData)

    const setPlayerTeamClass = (schedule_team, player_team) => {
        let className = 'status-label';
        if(schedule_team == player_team) {
            className += ' clr-label';
        }
        return className;
    }

    const displayGameTime = (date) => {
        try {
            return moment.tz(date.toString(), "YYYY-MM-DDTh:mm:ss","America/New_York").format('h:mm A z')
        }catch (error) {
            return "-";
        }
    }
	return (
        <>
            {props.gameData.playername ? <div className="status-info-sec">
                {(props.gameData.byeweek ==  props.gameData.lineup_week)? <div className="live-label">{'Bye Week'}</div> : ''}
                {props.gameData.has_started ? <div className="live-label">{statusLabel}</div> : ''}
                {!props.gameData.game_key ? <div className="live-label"></div> : ''}
                {(props.gameData.game_key && !props.gameData.has_started) ? <div className="week-game-status" >
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.home_team, props.gameData.team_key)}>{props.gameData.home_team}</div><div className="status-info"> {displayGameTime(props.gameData.schedule_date)} </div></div>
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.away_team, props.gameData.team_key)}>{props.gameData.away_team}</div><div className="status-info">  {moment.utc(props.gameData.schedule_date).format('MM/DD/YY')}</div></div>
                </div> : '' }
                
                {(props.gameData.game_key && props.gameData.has_started && props.gameData.is_inprogress) ? <div className="week-game-status" >
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.home_team, props.gameData.team_key)}>{props.gameData.home_team}</div><div className="status-info">{props.gameData.home_score}</div><div className="status-info">{quater_label}</div></div>
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.away_team, props.gameData.team_key)}>{props.gameData.away_team}</div><div className="status-info">{props.gameData.away_score}</div><div className="status-info">{props.gameData.time_remaining && props.gameData.time_remaining}</div></div>
                </div> : '' }
                
                {(props.gameData.game_key && props.gameData.has_started && props.gameData.is_over) ? <div className="week-game-status" >
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.home_team, props.gameData.team_key)}>{props.gameData.home_team}</div><div className="status-info">{props.gameData.home_score}</div><div className="status-info">F</div></div>
                    <div className="status-group"><div className={setPlayerTeamClass(props.gameData.away_team, props.gameData.team_key)}>{props.gameData.away_team}</div><div className="status-info">{props.gameData.away_score}</div><div className="status-info"></div></div>
                </div> : '' }

                {(props.type === 'lineupSetup') ? <div className='position'  >{props.position}</div> : <div className='position' >{props.position}</div>
                }
            </div> : 
            <div className="status-info-sec">
                {(props.type === 'lineupSetup') ? <div className='position'  >{props.position}</div> : <div className='position' >{props.position}</div>}
            </div> }
        </>
	);
}

export default (PlayerGameStatus);
