import * as downloadService from "./downloadService";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'


export async function getPlayerStats(playerId) {
    let options = { };
    return await apiService.apiCall(endPoints.PLAYER_SEASON_STATS_API+"/"+playerId, 'GET', options);
}

export async function geTeamStats(team_key) {
    let options = { };
    return await apiService.apiCall(endPoints.TEAM_SEASON_STATS_API+"/1/"+team_key, 'GET', options);
}
