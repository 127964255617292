import React from "react";


const HornIcon = props => {

    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="21.856" height="19.274" viewBox="0 0 21.856 19.274">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_1748" data-name="Rectangle 1748" width="21.856" height="19.274" fill="#1abcff" stroke="#1abcff" stroke-width="1"/>
          </clipPath>
        </defs>
        <g id="Group_16224" data-name="Group 16224" transform="translate(0 0)">
          <g id="Group_16223" data-name="Group 16223" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Path_10374" data-name="Path 10374" d="M5,11.839l1.672,6.112a1.1,1.1,0,0,0,1.939.356,1.067,1.067,0,0,0,.185-.959L7.285,11.839l.942-.019h2.34l8.122,3.744a.818.818,0,0,0,.5.185c1.751,0,2.137-5.911,2.137-7.374S20.947,1,19.192,1a.818.818,0,0,0-.5.185L10.557,4.908H4.5a3.462,3.462,0,0,0-.415,6.9Z" transform="translate(-0.237 -0.237)" fill="#1abcff" stroke="#1abcff" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_10375" data-name="Path 10375" d="M13.521,6.12a21.423,21.423,0,0,1,.682,5.551,21.316,21.316,0,0,1-.682,5.264" transform="translate(-3.201 -3.197)" fill="#070c2b" stroke="#070c2b" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </g>
      </svg>
      


    );

}

export default HornIcon;