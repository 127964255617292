import * as contestService from "../services/contestService";

export const leaveTheContest = async (contestData, isUserGame = false) => {
  const response = await contestService.leaveTheContest(contestData, isUserGame)
  return response;
}

export const checkRefundStatus = async (contestData) => {
  const response = await contestService.checkRefundStatus(contestData)
  return response;
}


