import React from "react";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

const TESVOIdentity = (props) => {
    let addressEdit = (props.addressEdit) ? props.addressEdit : false;
    // let tsevo_app_url = (process.env.REACT_APP_TSEVO_URL) ? process.env.REACT_APP_TSEVO_URL : 'https://ws.gidx-service.com/';
    // if(props.isIpv4 === false) {
    //     tsevo_app_url = (process.env.REACT_APP_TSEVO_IPV6_URL) ? process.env.REACT_APP_TSEVO_IPV6_URL : 'https://ws-ipv6.gidx-service.com/';
    // }
    return (
        <>
            <Helmet>
                {/* <meta charset="utf-8" /> */}
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                {/* <script src={ tsevo_app_url+'v3.0/WebSession/Registration?sessionid=' + props.sessionID } data-tsevo-script-tag data-gidx-session-id={ props.sessionID } type='text/javascript'></script> */}
                {parse(decodeURIComponent(props.tsevoSessionURL?.replace(/\+/g, '%20')).replace("<div data-gidx-script-loading='true'>Loading...</div>", ""))}

                {addressEdit && <script src="/js/gidxAddressEdit.js" type="text/javascript" />}
                {!addressEdit && <script src="/js/gidxSupport.js" type="text/javascript" />}
            </Helmet>
            <div data-gidx-script-loading='true'><div className="loader-wrap"><div className="loader"></div></div></div>
            <div id="DepositAmountDisplay"></div>
            <div id="GIDX_ServiceContainer"></div>
        </>
    );

};

export default TESVOIdentity;
