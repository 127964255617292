import React from "react";


const MinusIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="3.75" viewBox="0 0 15 3.75">
            <rect id="remove" data-name="Rectangle 1571" width="3.75" height="15" transform="translate(0 3.75) rotate(-90)" fill="#fff"/>
        </svg>


    );

}

export default MinusIcon;