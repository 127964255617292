import React, { useState, useEffect} from 'react';
import * as commonService from "../services/commonService";
import Paginate from "../components/Paginate";


const NewsList = props => {
    const [newsList, setnewsList] = useState([]);
    const [showLoader, setLoader] = useState(false);
    const [newsPageData, setNewsPageData] = useState([]);
    const initialPageParams = {
        page : 1
    }
    const [newsPageParams, setNewsPageParams] = useState(initialPageParams);

    useEffect( async () => {
        setLoader(true)
        await commonService.newsList(newsPageParams.page)
        .then(async function (response) {
            setLoader(false)
            if(response.errorCode == 200) {
                setnewsList(response.data.newsData);
                setNewsPageData({
                    perPage: response.data.perPage ? response.data.perPage : 5,
                    totalPages: response.data.totalPages ? response.data.totalPages : 0,
                    total: response.data.total ? response.data.total : 0,
                    currentPage: response.data.currentPage ? response.data.currentPage : 0,
                    errorCode: response.errorCode ? response.errorCode : 0,
                });
            } else {
                setnewsList([]);
            }
        })
    }, [newsPageParams])

    const pageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setNewsPageParams(pageparams);
    }
    return (
        <>
            {(newsList.length > 0) && <div className="player-news">
                {newsList.map((news) => {  
                    return ( 
                        <>
                            <h4>{news.news_title}</h4>
                            <p>{news.news_content}</p>
                        </>
                        
                    ) 
                })}
            </div>}
            {newsPageData ? <Paginate paginationData={newsPageData} pageRows={newsList.length} onClick={pageNumberChange} /> : ""}
            {(!newsList.length && !showLoader) ? <div className="player-news"><span style={{ color: "red" }}>No records to display.</span></div> : ''}
            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
        </>
    );
  
}

export default (NewsList);
