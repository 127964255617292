
import * as siteAction from "./siteAction";
import * as contestService from "../../services/contestService";
import * as userGamesService from "../../services/userGamesService";
import { contestTypes } from "../../appData/contestTypes";
import { lobbyFilterOptions } from "../../appData/lobbyFilterOptions";
import { sortOrderOptions } from "../../appData/sortOrderOptions";
import PlayerPositionList from "../../components/PlayerPositionList";
import { store } from "../../appState/store";

const initialState = {
    IsUserGame: false,
    statusCode: '',
    success: '',
    statusMessage: '',
    Lobby: {
        showNoRecordsFound: true,
        IsFirstLoad: true,
        IsJoinedOnly: true,
        IsUserLeague: false,
        FilterOption: lobbyFilterOptions.All,
        ContestFilterOptions: {
            searchText: "",
            isStandard: false,
            isKOPlus: false,
            isGuaranteed: false,
            isMultipleEntry: false,
            isFree: false,
            joinFeeFrom: -1,
            joinFeeTo: -1,
            startOn: null
        },
        ContestSortOptions: {
            entries: sortOrderOptions.None,
            prize: sortOrderOptions.None,
            fees: sortOrderOptions.None
        },
        ContestList: [],
        FilteredContestList: [],
        WeeklyEliminationData: {

            firstWeek :  0,
            firstWeekCount : 0,

            secondWeekRangeStart : 0,
            secondWeekRangeEnd :  0,
            secondWeekRangeCount : 0,

            thirdWeekRangeStart :0,
            thirdWeekRangeEnd :0,
            thirdWeekRangeCount :0
        },

        LobbyViewType: 'list',
        PlayerList: [],
        PlayerListByPosition: [],
        QuarterBackOptions: {
            playername: "",
            playernumber: "",
            team: "",
            position: ""
        },
        JoinContestAddLineUp: {},
        JoinContestAddTieBreakerScore: {},
        JoinUserContest: {},
        contestDetails: [],
        deleteResponseData: [],
        positionPlayerCardView: [],
        positionPlayerCardViewForUsergame: [],
        PlayerSearchFilterOptions: {
            SearchData: ""
        },
        PlayerSearchSortOptions: {
            SortBy : "name"
        },
        PlayerFilterList: [],
        PlayerPosition: 'QB',
        PlayerCardPlacingId: 0,
        playerByIdData: [],
        getAllPlayersList: [],
        getAllPlayersListForUsergame: [],
        showPlayerLoader: true,
        PlayerPositionName: 'Quarterback',
        PlayerSearchFilterOptionsForUsergame: {
            SearchData: ""
        },
        PlayerSearchSortOptionsForUsergame: {
            SortBy : "name"
        },
        PlayerCardPlacingIdForUsegame: 0,
        PlayerListForUsergame: [],
        PlayerFilterListForUsergame: [],
        PlayerListByPositionForUsergame: [],
        totalCount: 0,
        totalPublishedCount: 0,
        totalUnPublishedCount: 0,
        totalLiveCount: 0,
        totalCompletedCount: 0,
        PlayerPaginationData: [],
        getPlayersWeeklyScores: [],
        currentContestData: [],
    },
    ActiveMenu: "",
    SelectedPlayerCardIndex: 0,
    SelectedPlayerCardIndexForUsergame: 0,
    SiteCurrentWeek: 1,
    KnockoutPlusEnabled : 0
};

function siteReducer(state = initialState, action) {
    let newState;
    let isManageLeague;
    let contestListing;
    let totalCount;
    let totalPublishedCount;
    let totalUnPublishedCount;
    let totalLiveCount;
    let totalCompletedCount;
    switch (action.type) {

        case siteAction.Type.SiteGamesCleared:
            newState = { ...state };
             // newState.IsUserGame = newState.IsUserGame ? true : false;
            isManageLeague = (action.payload.isManageLeague) ? action.payload.isManageLeague : false;
            contestListing = [];
            newState.Lobby.FilterOption = lobbyFilterOptions.All;
            newState.Lobby.IsFirstLoad = false;
            newState.Lobby.ContestList = contestListing;
            newState.Lobby.FilteredContestList = contestListing;
            newState.Lobby.showNoRecordsFound = false;
            newState.Lobby.PaginationData = action.payload.PaginationData;
            newState.Lobby.positionPlayerCardView = [];
            newState.Lobby.totalCount = (isManageLeague) ? action.payload.totalCount : 0;
            newState.Lobby.totalPublishedCount = (isManageLeague) ? action.payload.totalPublishedCount : 0;
            newState.Lobby.totalUnPublishedCount = (isManageLeague) ? action.payload.totalUnPublishedCount : 0;
            newState.Lobby.totalLiveCount = (isManageLeague) ? action.payload.totalLiveCount : 0;
            newState.Lobby.totalCompletedCount = (isManageLeague) ? action.payload.totalCompletedCount : 0;
            newState.Lobby.PlayerCardPlacingId = 0;
            newState.Lobby.PlayerPosition = 'QB';
            newState.Lobby.PlayerPositionName = 'Quarterback';
            break;
        case siteAction.Type.SiteGames:
            newState = { ...state };
            // newState.IsUserGame = newState.IsUserGame ? true : false;
            isManageLeague = (action.payload.isManageLeague) ? action.payload.isManageLeague : false;
            contestListing = (!isManageLeague) ? transformContestList(action.payload.ContestList) : (action.payload.ContestList.length) ? transformMangeLeagueList(action.payload.ContestList) : [];
            newState.Lobby.IsFirstLoad = false;
            newState.Lobby.ContestList = contestListing;
            newState.Lobby.FilteredContestList = contestListing;
            newState.Lobby.showNoRecordsFound = true;
             newState.Lobby.PaginationData = action.payload.PaginationData;
            newState.Lobby.positionPlayerCardView = [];
            newState.Lobby.totalCount = (isManageLeague) ? action.payload.totalCount : 0;
            newState.Lobby.totalPublishedCount = (isManageLeague) ? action.payload.totalPublishedCount : 0;
            newState.Lobby.totalUnPublishedCount = (isManageLeague) ? action.payload.totalUnPublishedCount : 0;
            newState.Lobby.totalLiveCount = (isManageLeague) ? action.payload.totalLiveCount : 0;
            newState.Lobby.totalCompletedCount = (isManageLeague) ? action.payload.totalCompletedCount : 0;
            newState.Lobby.PlayerCardPlacingId = 0;
            newState.Lobby.PlayerPosition = 'QB';
            newState.Lobby.PlayerPositionName = 'Quarterback';
            newState.Lobby.FilterOption = lobbyFilterOptions.All;
            break;
        case siteAction.Type.UserGames:
            newState = { ...state };
            // newState.IsUserGame = newState.IsUserGame ? true : false;
            newState.Lobby.IsFirstLoad = false;
            newState.Lobby.ContestList = transformContestList(action.payload.ContestList);
            newState.Lobby.PaginationData = action.payload.PaginationData;
            newState.Lobby.FilteredContestList = transformContestList(action.payload.ContestList);
            newState.Lobby.PlayerCardPlacingId = 0;
            newState.Lobby.PlayerPosition = 'QB';
            newState.Lobby.PlayerPositionName = 'Quarterback';
            newState.Lobby.FilterOption = lobbyFilterOptions.All;
            break;
        case siteAction.Type.LobbyFilterOptionsChange:
            newState = { ...state };
            isManageLeague = (action.payload.isManageLeague) ? action.payload.isManageLeague : false;
            newState.Lobby.ContestFilterOptions = { ...action.payload.FilterOptions };
            if (state.Lobby.IsJoinedOnly) {
                newState.Lobby.FilterOption = state.Lobby.FilterOption;
            }
            contestListing = (!isManageLeague) ? transformContestList(action.payload.ContestList) : (action.payload.ContestList.length) ? transformMangeLeagueList(action.payload.ContestList) : [];

            newState.Lobby.ContestList = contestListing;
            newState.Lobby.FilteredContestList = contestListing;
            newState.Lobby.showNoRecordsFound = true;
            newState.Lobby.totalCount = (isManageLeague) ? action.payload.totalCount : 0;
            newState.Lobby.totalPublishedCount = (isManageLeague) ? action.payload.totalPublishedCount : 0;
            newState.Lobby.totalUnPublishedCount = (isManageLeague) ? action.payload.totalUnPublishedCount : 0;
            newState.Lobby.totalLiveCount = (isManageLeague) ? action.payload.totalLiveCount : 0;
            newState.Lobby.totalCompletedCount = (isManageLeague) ? action.payload.totalCompletedCount : 0;
            newState.Lobby.PaginationData = action.payload.PaginationData;
            newState.IsUserGame = action.payload.respData && action.payload.respData.isUserGame ? action.payload.respData.isUserGame : false;
            newState.SiteCurrentWeek = action.payload.respData && action.payload.respData.current_week ? action.payload.respData.current_week : 1;
            sessionStorage.setItem('currentActiveWeek', newState.SiteCurrentWeek)
            newState.Lobby.PlayerCardPlacingId = 0;
            newState.Lobby.PlayerPosition = 'QB';
            newState.Lobby.PlayerPositionName = 'Quarterback';
            newState.KnockoutPlusEnabled = action.payload.respData && action.payload.respData.knockout_plus_enabled ? action.payload.respData.knockout_plus_enabled : 0;
            break;
        case siteAction.Type.PlayerFilterOptionsChange:
          //  console.log("Action",action.payload.sortBy)
            newState = { ...state };
            newState.Lobby.PlayerSearchFilterOptions = { ...action.payload.SearchOptions };
           // newState.Lobby.PlayerSearchSortOptions.SortBy = action.payload.sortBy;
            newState.Lobby.PlayerCardPlacingId = action.payload.PlayerList.data.player_card_id;
            newState.Lobby.PlayerList = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerFilterList = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerListByPosition = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerPaginationData = action.payload.PaginationData;
            newState.Lobby.showPlayerLoader = false;
            newState.IsUserGame = action.payload.respData && action.payload.respData.isUserGame ? action.payload.respData.isUserGame : false;
            newState.SiteCurrentWeek = action.payload.respData && action.payload.respData.current_week ? action.payload.respData.current_week : 1;
            sessionStorage.setItem('currentActiveWeek', newState.SiteCurrentWeek)
            newState.KnockoutPlusEnabled = action.payload.respData && action.payload.respData.knockout_plus_enabled ? action.payload.respData.knockout_plus_enabled : 0;
            break;
        case siteAction.Type.LobbySortOptionsChange:
            newState = { ...state };
            newState.Lobby.ContestSortOptions = { ...action.payload.SortOptions };
            break;
        case siteAction.Type.setSearchText:
            newState = { ...state };
            newState.Lobby.PlayerSearchFilterOptions.SearchData = '';
            break;
        case siteAction.Type.LobbyPageOptionsChange:
            newState = { ...state };
            isManageLeague = (action.payload.isManageLeague) ? action.payload.isManageLeague : false;
            newState.Lobby.IsJoinedOnly = action.payload.IsJoinedOnly;
            newState.Lobby.FilterOption = action.payload.FilterOption;
            contestListing = (!isManageLeague) ? transformContestList(action.payload.ContestList) : (action.payload.ContestList.length) ? transformMangeLeagueList(action.payload.ContestList) : [];
            newState.Lobby.ContestList = contestListing;
            newState.Lobby.FilteredContestList = contestListing;
            newState.Lobby.showNoRecordsFound = true;
            newState.Lobby.totalCount = (isManageLeague) ? action.payload.totalCount : 0;
            newState.Lobby.totalPublishedCount = (isManageLeague) ? action.payload.totalPublishedCount : 0;
            newState.Lobby.totalUnPublishedCount = (isManageLeague) ? action.payload.totalUnPublishedCount : 0;
            newState.Lobby.totalLiveCount = (isManageLeague) ? action.payload.totalLiveCount : 0;
            newState.Lobby.totalCompletedCount = (isManageLeague) ? action.payload.totalCompletedCount : 0;
            newState.Lobby.PaginationData = action.payload.PaginationData;
            newState.IsUserGame = action.payload.respData && action.payload.respData.isUserGame ? action.payload.respData.isUserGame : false;
            newState.SiteCurrentWeek = action.payload.respData && action.payload.respData.current_week ? action.payload.respData.current_week : 1;
            sessionStorage.setItem('currentActiveWeek', newState.SiteCurrentWeek)
            newState.Lobby.PlayerCardPlacingId = 0;
            newState.Lobby.PlayerPosition = 'QB';
            newState.Lobby.PlayerPositionName = 'Quarterback';
            newState.KnockoutPlusEnabled = action.payload.respData && action.payload.respData.knockout_plus_enabled ? action.payload.respData.knockout_plus_enabled : 0;
            break;
        case siteAction.Type.LobbyPageViewChange:
            newState = { ...state };
            newState.Lobby.LobbyViewType = action.payload.viewMode;
            break;
        case siteAction.Type.JoinContestLineUpPlayerList:
            newState = { ...state };
            newState.Lobby.PlayerCardPlacingId = action.payload.PlayerList.data.player_card_id
            newState.Lobby.PlayerList = transformPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerFilterList = transformPlayerList(action.payload.PlayerList.data.playersData);
            //newState.Lobby.showPlayerLoader = (action.payload.PlayerList.errorCode == 200) ? false : true;
            newState.Lobby.PlayerPaginationData = action.payload.PaginationData;
            newState.IsUserGame = action.payload.respData && action.payload.respData.isUserGame ? action.payload.respData.isUserGame : false;
            newState.Lobby.showPlayerLoader = false;
            break;

        case siteAction.Type.SeasonLeaderboardService:
            newState = { ...state };
            newState.Lobby.SeasonLeaderboardService = action.payload
            newState.Lobby.showPlayerLoader = false;
            break;

        case siteAction.Type.GetSeasonStats:
            newState = { ...state };
            newState.Lobby.seasonStatsResponse = action.payload.response
            newState.Lobby.showPlayerLoader = false;
            break;


        case siteAction.Type.SeasonStandingsResponse:
            newState = { ...state };
            newState.Lobby.seasonStandingsResponse = action.payload;
            break;

        case siteAction.Type.JoinContestLineUpPlayerListByPosition:
            newState = { ...state };
            newState.Lobby.PlayerCardPlacingId = action.payload.PlayerListByPosition.player_card_id
            newState.Lobby.PlayerListByPosition = transformPlayerListByPosition(action.payload.PlayerListByPosition.playersData);
            newState.Lobby.PlayerFilterList = transformPlayerListByPosition(action.payload.PlayerListByPosition.playersData);
            // newState.Lobby.showPlayerLoader = (action.payload.PlayerListByPosition.errorCode == 200) ? false : true;
            newState.Lobby.PlayerPaginationData = action.payload.PaginationData;
            newState.Lobby.LineupsPerWeek = action.payload.lineupsPerWeek;
            newState.IsUserGame = action.payload.respData && action.payload.respData.isUserGame ? action.payload.respData.isUserGame : false;
            newState.Lobby.showPlayerLoader = false;
            break;

        case siteAction.Type.JointContestAddLineUps:
            newState = { ...state };
            newState.statusCode = action.payload.errorCode;
            newState.Lobby.JoinContestAddLineUp = action.payload.addLineUpData;
            newState.addLineUpData = action.payload.addLineUpData;
            newState.success = action.payload.success;
            newState.statusMessage = action.payload.message;
            return newState;
        case siteAction.Type.JointContestAddTieBreakerScore:
            newState = { ...state };
            newState.statusCode = action.payload.errorCode;
            newState.Lobby.JoinContestAddTieBreakerScore = action.payload.joinContestData;
            newState.joinContestData = action.payload.joinContestData;
            newState.success = action.payload.success;
            newState.statusMessage = action.payload.message;
            return newState;

        case siteAction.Type.UserContestJoin:
            newState = { ...state };
            newState.statusCode = action.payload.errorCode;
            newState.Lobby.JoinUserContest = action.payload.userJoinContestData;
            newState.userJoinContestData = action.payload.userJoinContestData;
            newState.success = action.payload.success;
            newState.statusMessage = action.payload.message;
            return newState;

        case siteAction.Type.showContestId:
            newState = { ...state };
            newState.contestId = action.payload.contestId;
            return newState;
        case siteAction.Type.showLineUpCard:
            newState = { ...state };
            newState.DefaultLineUpCardView = action.payload.value;
            return newState;
        case siteAction.Type.GetJoinContestData:
            newState = { ...state };
            newState.contestDetails = action.payload.contestDetails;
            return newState;
        case siteAction.Type.PositionPlayerCardViewLineUp:
            newState = { ...state };
            newState.Lobby.errorCode = (action.payload.viewdata.errorCode == 200) ? true : false;
            newState.Lobby.statusCode = action.payload.viewdata.errorCode;
            newState.Lobby.success = action.payload.viewdata.success
            newState.Lobby.statusMessage = action.payload.viewdata.message;
            newState.Lobby.deleteResponseData = action.payload.viewdata;
            newState.Lobby.positionPlayerCardView = (action.payload.viewdata.errorCode == 200) ? transformPlayerListLineUP(action.payload.viewdata.data.playersData) : transformPlayerListLineUP(action.payload.viewdata.data.playersData);
            newState.Lobby.getAllPlayersList = (action.payload.viewdata.errorCode == 200) ? transformPlayerListLineUP(action.payload.viewdata.data.playersData) : transformPlayerListLineUP(action.payload.viewdata.data.playersData);
            return newState;

        case siteAction.Type.PositionPlayerCardViewLineUpForUsergame:
            newState = { ...state };
            newState.Lobby.errorCode = (action.payload.viewdata.errorCode == 200) ? true : false;
            newState.Lobby.statusCode = action.payload.viewdata.errorCode;
            newState.Lobby.success = action.payload.viewdata.success
            newState.Lobby.statusMessage = action.payload.viewdata.message;
            newState.Lobby.positionPlayerCardView = (action.payload.viewdata.errorCode == 200) ? transformPlayerListLineUP(action.payload.viewdata.data.playersData) : transformPlayerListLineUP(action.payload.viewdata.data.playersData);
            return newState;

        case siteAction.Type.showPositionList:
            newState = { ...state };
            newState.showPositionList = action.payload.value;
            return newState;
        case siteAction.Type.PlusDeleteIcon:
            newState = { ...state };
            newState.PlusDeleteIcon = action.payload.value;
            newState.Lobby.showPlayerLoader = false;
            return newState;
        case siteAction.Type.JointContestDeleteLineUp:
            newState = { ...state };
            newState.deleteData = action.payload.deleteData;
            return newState;
        case siteAction.Type.playerById:
            newState = { ...state };
            newState.Lobby.playerByIdData = action.payload.value;
            return newState;
        case siteAction.Type.getAllPlayersList:
            newState = { ...state };
            newState.Lobby.getAllPlayersList = transformgetAllPlayersList(action.payload.viewdata);
            newState.Lobby.positionPlayerCardView = transformPlayerListLineUP(action.payload.viewdata);
            newState.Lobby.LineUpTeamPlayer = transformPlayerListLineUP(action.payload.viewdata);
            newState.Lobby.getPlayersWeeklyScores = action.payload.scoresData;
            newState.Lobby.showPlayerLoader = false;
            break;
        case siteAction.Type.GetAllPlayersListForUsergame:
            newState = { ...state };
            newState.Lobby.getAllPlayersListForUsergame = transformgetAllPlayersList(action.payload.viewdata);
            newState.Lobby.positionPlayerCardView = transformPlayerListLineUP(action.payload.viewdata);
            newState.Lobby.LineUpTeamPlayer = transformPlayerListLineUP(action.payload.viewdata);
            newState.Lobby.getPlayersWeeklyScores = action.payload.scoresData;
            newState.Lobby.showPlayerLoader = false;
            break;
        case siteAction.Type.JoinContestLineUpTeamPlayerList:
            newState = { ...state };
            newState.Lobby.PlayerCardPlacingId = action.payload.TeamPlayer.data.player_card_id;
            newState.Lobby.LineUpTeamPlayer = transformLineUpTeamPlayers(action.payload.TeamPlayer.data.playersData);
            newState.Lobby.PlayerFilterList = transformLineUpTeamPlayers(action.payload.TeamPlayer.data.playersData);
            newState.Lobby.showPlayerLoader = false;
            break;
        case siteAction.Type.playerPosition:
            newState = { ...state };
            newState.Lobby.PlayerPosition = action.payload.position;
            newState.Lobby.showPlayerLoader = true;
            newState.Lobby.PlayerPositionName = action.payload.position;
            break;
        case siteAction.Type.getLineUpNumber:
            newState = { ...state };
            newState.Lobby.getLineUpNumber = action.payload.getLineUpNumber;
            break;
        case siteAction.Type.LeftSideActiveMenu:
            newState = { ...state };
            newState.ActiveMenu = action.payload.pageName;
            break;
        case siteAction.Type.RemoveAllLineUpPlayers:
            newState = { ...state };
            newState.Lobby.positionPlayerCardView = [];
            newState.Lobby.LineUpTeamPlayer = [];
            break;
        case siteAction.Type.UpdateSelectedPlayerCard:
            newState = { ...state };
            newState.SelectedPlayerCardIndex = action.payload.cardIndex;
            break;

        case siteAction.Type.WinnersResponse:
            newState = { ...state };
            newState.Lobby.winnersResponse = action.payload;
            break;

        case siteAction.Type.UpdateSelectedPlayerCardForUsergame:
            newState = { ...state };
            newState.SelectedPlayerCardIndexForUsergame = action.payload.cardIndex;
            break;
        case siteAction.Type.RemoveAllLineUpPlayersForUsergame:
            newState = { ...state };
            newState.Lobby.positionPlayerCardViewForUsergame = [];
            newState.Lobby.LineUpTeamPlayerForUsergame = [];
            break;
        case siteAction.Type.PlayerFilterOptionsChangeForUsergame:
            newState = { ...state };
            newState.Lobby.PlayerSearchFilterOptionsForUsergame = { ...action.payload.SearchOptions };
            newState.Lobby.PlayerCardPlacingIdForUsegame = action.payload.PlayerList.data.player_card_id;
            newState.Lobby.PlayerListForUsergame = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerFilterListForUsergame = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerListByPositionForUsergame = transformFilterPlayerList(action.payload.PlayerList.data.playersData);
            break;
        case siteAction.Type.getAllContestantStanding:
            newState = { ...state };
            newState.Lobby.getAllContestantStanding = action.payload.viewdata;
            break;
        case siteAction.Type.getseasonStandingList:
            newState = { ...state };
            newState.Lobby.getseasonStandingList = action.payload.viewdata;
            break;
        case siteAction.Type.JoinContestLineUpTeamPlayerFilterList:
            newState = { ...state };
            newState.Lobby.PlayerSearchFilterOptions = { ...action.payload.SearchOptions };
            newState.Lobby.PlayerCardPlacingId = action.payload.PlayerList.data.player_card_id;
            newState.Lobby.LineUpTeamPlayer = transformLineUpTeamPlayers(action.payload.PlayerList.data.playersData);
            newState.Lobby.PlayerFilterList = transformLineUpTeamPlayers(action.payload.PlayerList.data.playersData);
            newState.Lobby.showPlayerLoader = false;
            break;
        case siteAction.Type.MessageBoardUnreadMessages:
            newState = { ...state };
            newState.Lobby.getUnreadMessages = action.payload.viewdata;
            break;
        case siteAction.Type.RulesAndScoringData:
            newState = { ...state };
            newState.Lobby.rulesAndScoringData = action.payload.viewdata;
            break;
        default:
            newState = { ...state };
            break;
    }
    return newState;
}

function isFilterEnabled(contestFilterOptions) {
    let filterEnabled = false;
    if ((contestFilterOptions.searchText.length > 0) ||
        contestFilterOptions.isStandard ||
        contestFilterOptions.isKOPlus ||
        contestFilterOptions.isGuaranteed ||
        contestFilterOptions.isMultipleEntry ||
        contestFilterOptions.isFree ||
        (contestFilterOptions.joinFeeFrom >= 0 && contestFilterOptions.joinFeeTo >= 0) ||
        contestFilterOptions.startOn
    ) {
        filterEnabled = true;
    }
    return filterEnabled;
}

function filterContestList(contestList, contestFilterOptions) {
    let i;
    let canAdd;
    let result = [];
    let filterEnabled = isFilterEnabled(contestFilterOptions);
    if (filterEnabled) {
        for (i = 0; i < contestList.length; i++) {
            canAdd = false;
            if (contestFilterOptions.searchText.length > 0) {
                if (contestList[i].Name.trim().toLowerCase().includes(contestFilterOptions.searchText.trim().toLowerCase())) {
                    canAdd = true;
                }
            }
            if (contestFilterOptions.isStandard && contestList[i].Type === contestTypes.Standard) {
                canAdd = true;
            }
            if (contestFilterOptions.isKOPlus && contestList[i].Type === contestTypes.KnockoutPlus) {
                canAdd = true;
            }
            if (contestFilterOptions.isGuaranteed && contestList[i].IsGuaranteed) {
                canAdd = true;
            }
            if (contestFilterOptions.isMultipleEntry && contestList[i].IsMultiple) {
                canAdd = true;
            }
            if (contestFilterOptions.isFree && contestList[i].EntryFee === 0) {
                canAdd = true;
            }
            if (!(contestFilterOptions.joinFeeFrom === -1 && contestFilterOptions.joinFeeTo === -1)) {
                if (contestList[i].EntryFee >= contestFilterOptions.joinFeeFrom &&
                    contestList[i].EntryFee <= contestFilterOptions.joinFeeTo) {
                    canAdd = true;
                }
            }
            // TODO Filter StartTime
            if (canAdd) {
                result.push(contestList[i]);
            }
        }
    } else {
        result = contestList;
    }
    return result;
}

function sortContestList(contestList, sortOptions) {
    contestList.sort(function (a, b) {
        let result = 0;
        if (sortOptions.entries !== sortOrderOptions.None) {
            if (sortOptions.entries === sortOrderOptions.Ascending) {
                result = a.EntryCount - b.EntryCount;
            } else if (sortOptions.entries === sortOrderOptions.Descending) {
                result = b.EntryCount - a.EntryCount;
            }
        } else if (sortOptions.prize !== sortOrderOptions.None) {
            if (sortOptions.prize === sortOrderOptions.Ascending) {
                result = a.Prize - b.Prize;
            } else if (sortOptions.prize === sortOrderOptions.Descending) {
                result = b.Prize - a.Prize;
            }
        } else if (sortOptions.fees !== sortOrderOptions.None) {
            if (sortOptions.fees === sortOrderOptions.Ascending) {
                result = a.EntryFee - b.EntryFee;
            } else if (sortOptions.fees === sortOrderOptions.Descending) {
                result = b.EntryFee - a.EntryFee;
            }
        } else {
            let x = a.Name.toLowerCase();
            let y = b.Name.toLowerCase();
            if (x < y) { result = -1; }
            if (x > y) { result = 1; }
        }
        return result;
    });
    return contestList;
}

function getContestStatus(status) {
    let contestStatus = ''
    for (let key in lobbyFilterOptions) {
        if (lobbyFilterOptions[key].value === 1) {
            contestStatus = lobbyFilterOptions[key].label
            break;
        }
    }
    return contestStatus
}

function transformContestList(contestList) {
    let result = contestList.map((contest) => {
        const newContest = {
            ...contest,
            Type: contest.TypeId === 1 ? "Standard" : "Knockoutplus",
            IsGuaranteed: contest.GuaranteedStatus === 'G' ? true : false,
            IsMultiple: contest.MultipleEntryStatus === 'M' ? true : false,
            IsLive: true,
            IsJoined: contest.si_contest_user_id !== null ? true : false,
            IsWinner: false,
            Status: getContestStatus(contest.ContestStatus),
            IsPublished: true
        }
        return newContest
    })
    return result
}


function transformFilterPlayerList(playerList) {
    let result = playerList.map((player) => {
        const newPlayer = {
            ...player,
        }
        return newPlayer
    })
    return result
}


function transformPlayerList(playerList) {
    let result = playerList.map((player) => {
        const newPlayer = {
            ...player,
        }
        return newPlayer
    })
    return result
}

function transformPlayerListLineUP(playerListByPosition) {

    if (playerListByPosition) {
        let result = playerListByPosition.map((playerList) => {
            const newPlayerList = {
                ...playerList,
            }
            return newPlayerList
        })
        return result
    } else {
        return  []
    }
}

function transformgetAllPlayersList(playerListByPosition) {
    let playerListByPositionData = [];
    playerListByPositionData = (playerListByPosition.data) ? playerListByPosition.data : playerListByPosition;
    let playerListByPositionDataArray = [...playerListByPositionData];
    let result = playerListByPositionDataArray.map((playerList) => {
        const dbPlayerList = {
            ...playerList,
        }
        return dbPlayerList
    })
    return result
}

function transformLineUpTeamPlayers(teamPlayer) {
    let result = teamPlayer.map((playerList) => {
        const teamPlayerList = {
            ...playerList,
        }
        return teamPlayerList
    })
    return result
}



function transformPlayerListByPosition(playerListByPosition) {
    let result = playerListByPosition.map((playerList) => {
        const newPlayerList = {
            ...playerList,
        }
        return newPlayerList
    })
    return result
}

function createOptionsAPI(sortoptions, isJoinedOnly, contestFilterOptions, lobbyFilterOption, isUserLeague, page = 1) {

    let userDetails = store.getState().user;

    let options = {
        "listing_type": isJoinedOnly ? "joined" : "notjoined",
        "isUserLeague": isUserLeague,
        'page' : page
        // "filter_by" : "all”,
        // "search_category" : ["S","K","G","M","F"],
        // “entry_fee_from” : ,
        // “entry_fee_to” : ,
        // “search_key” : ,
        // “search_contest_date” : 
    }
    if (lobbyFilterOption)
        options.filter_by = lobbyFilterOption.label.toLowerCase()
    if (sortoptions) {
        if (sortoptions.entries != "None") {
            options.orderBy = "max_users";
            if (sortoptions.entries == "Ascending")
                options.orderByType = "ASC";
            if (sortoptions.entries == "Descending")
                options.orderByType = "DESC";
        }
        else if (sortoptions.prize != "None") {
            options.orderBy = "total_prize_amt";
            if (sortoptions.prize == "Ascending")
                options.orderByType = "ASC";
            if (sortoptions.prize == "Descending")
                options.orderByType = "DESC";
        }
        else if (sortoptions.fees != "None") {
            options.orderBy = "entry_fee";
            if (sortoptions.fees == "Ascending")
                options.orderByType = "ASC";
            if (sortoptions.fees == "Descending")
                options.orderByType = "DESC";
        }
    }

    if (contestFilterOptions) {
        if (contestFilterOptions.searchText)
            options.search_key = contestFilterOptions.searchText
        if (contestFilterOptions.joinFeeFrom >= 0)
            options.entry_fee_from = contestFilterOptions.joinFeeFrom
        if (contestFilterOptions.joinFeeTo >= 0)
            options.entry_fee_to = contestFilterOptions.joinFeeTo
        if (contestFilterOptions.startOn)
            options.search_contest_date = contestFilterOptions.startOn

        let searchCategory = []
        if (contestFilterOptions.isStandard)
            searchCategory.push("S")
        if (contestFilterOptions.isKOPlus)
            searchCategory.push("K")
        if (contestFilterOptions.isGuaranteed)
            searchCategory.push("G")
        if (contestFilterOptions.isMultipleEntry)
            searchCategory.push("M")
        if (contestFilterOptions.isFree)
            searchCategory.push("F")

        if (searchCategory.length)
            options.search_category = searchCategory
    }
    return options
}

export function changeToSiteGame(sortoptions, contestFilterOptions, lobbyFilterOption, isUserLeague, isManageLeague, page) {
    return async (dispatch, getState) => {
        const options = createOptionsAPI(sortoptions, true, contestFilterOptions, lobbyFilterOption, isUserLeague, page)
        options.page = page ? page : 1;
        const GameData = (isManageLeague === true) ? await contestService.getMyCreatedContests(options) : await contestService.getSiteContests(options);
        if (GameData.success) {
            let contestList = (GameData.data.result) ? GameData.data.result : [];
            let totalCount = (GameData.data.totalCreatedCount) ? GameData.data.totalCreatedCount : 0;
            let totalPublishedCount = (GameData.data.totalPublishedCount) ? GameData.data.totalPublishedCount : 0;
            let totalUnPublishedCount = (GameData.data.totalUnPublishedCount) ? GameData.data.totalUnPublishedCount : 0;
            let totalLiveCount = (GameData.data.totalLiveCount) ? GameData.data.totalLiveCount : 0;
            let totalCompletedCount = (GameData.data.totalCompletedCount) ? GameData.data.totalCompletedCount : 0;
            let current_week = GameData.data.current_week ? GameData.data.current_week : 1;
            let knockout_plus_enabled = GameData.data.knockout_plus_enabled ? GameData.data.knockout_plus_enabled : 0;
            const PaginationData = {
                perPage: GameData.data.perPage ? GameData.data.perPage : 5,
                totalPages: GameData.data.totalPages ? GameData.data.totalPages : 0,
                total: GameData.data.total ? GameData.data.total : 0,
                currentPage: GameData.data.currentPage ? GameData.data.currentPage : 0,
                errorCode: GameData.errorCode ? GameData.errorCode : 0,
            }
            //dispatch(siteAction.ChangeToSiteGame(contestList, isManageLeague, totalCount, totalPublishedCount, totalUnPublishedCount, totalLiveCount, totalCompletedCount, PaginationData));
            const respData = {
                isUserGame: isUserLeague ? isUserLeague : false,
                current_week: current_week,
                knockout_plus_enabled : knockout_plus_enabled
            };
            dispatch(siteAction.ChangeToSiteGame(contestList, isManageLeague, totalCount, totalPublishedCount, totalUnPublishedCount, totalLiveCount, totalCompletedCount, PaginationData, respData));
        }
    }
};

export function SiteGamesCleared(isUserLeague, isManageLeague) {
    return async (dispatch, getState) => {


        let contestList = [];
        let totalCount = 0;
        let totalPublishedCount = 0;
        let totalUnPublishedCount = 0;
        let totalLiveCount = 0;
        let totalCompletedCount = 0;
        let current_week = 1;
        const PaginationData = {
        }

        const respData = {
            isUserGame: isUserLeague ? isUserLeague : false,
            current_week: current_week

        };
        dispatch(siteAction.ResetSiteGame(contestList, isManageLeague, totalCount, totalPublishedCount, totalUnPublishedCount, totalLiveCount, totalCompletedCount, PaginationData, respData));

    }
};

export function changeToUserGame(sortoptions, contestFilterOptions, lobbyFilterOption, isUserLeague) {
    return async (dispatch, getState) => {
        const options = createOptionsAPI(sortoptions, true, contestFilterOptions, lobbyFilterOption, isUserLeague)
        const GameData = await contestService.getUserContests(options);
        if (GameData.success) {
            let current_week = GameData.data.current_week ? GameData.data.current_week : 1;
            let knockout_plus_enabled = GameData.data.knockout_plus_enabled ? GameData.data.knockout_plus_enabled : 0
            const PaginationData = {
                perPage: GameData.data.perPage ? GameData.data.perPage : 5,
                totalPages: GameData.data.totalPages ? GameData.data.totalPages : 0,
                total: GameData.data.total ? GameData.data.total : 0,
                currentPage: GameData.data.currentPage ? GameData.data.currentPage : 0,
                errorCode: GameData.errorCode ? GameData.errorCode : 0,
            }
            //dispatch(siteAction.ChangeToUserGame(GameData.data.result, PaginationData));
            const respData = {
                isUserGame: isUserLeague ? isUserLeague : false,
                current_week: current_week,
                knockout_plus_enabled : knockout_plus_enabled
            };
            dispatch(siteAction.ChangeToUserGame(GameData.data.result, PaginationData, respData));
        }
    }
};

export function changeLobbyFilter(sortoptions, isJoinedOnly, filterOptions, lobbyFilterOption, isUserLeague, isManageLeague = false) {

    let pagePathName = window.location.pathname;
    if (pagePathName.indexOf('lobby') === 1 || pagePathName.indexOf('joinedupcoming') === 1 || pagePathName.indexOf('joinedlive') === 1 || pagePathName.indexOf('joinedhistory') === 1 || pagePathName.indexOf('manageleague') === 1) {
        isJoinedOnly = 'joined';
    }


    return async (dispatch, getState) => {
        const options = createOptionsAPI(sortoptions, isJoinedOnly, filterOptions, lobbyFilterOption, isUserLeague, 1)
       
            const GameData = (isManageLeague === true) ? await contestService.getMyCreatedContests(options) : await contestService.getSiteContests(options);
        let contestList = (GameData.data.result) ? GameData.data.result : [];
        let totalCount = (GameData.data.totalCreatedCount) ? GameData.data.totalCreatedCount : 0;
        let totalPublishedCount = (GameData.data.totalPublishedCount) ? GameData.data.totalPublishedCount : 0;
        let totalUnPublishedCount = (GameData.data.totalUnPublishedCount) ? GameData.data.totalUnPublishedCount : 0;
        let totalLiveCount = (GameData.data.totalLiveCount) ? GameData.data.totalLiveCount : 0;
        let totalCompletedCount = (GameData.data.totalCompletedCount) ? GameData.data.totalCompletedCount : 0;
        let current_week = GameData.data.current_week ? GameData.data.current_week : 1;
        let knockout_plus_enabled = GameData.data.knockout_plus_enabled ? GameData.data.knockout_plus_enabled : 0;
        const PaginationData = {
            perPage: GameData.data.perPage ? GameData.data.perPage : 5,
            totalPages: GameData.data.totalPages ? GameData.data.totalPages : 0,
            total: GameData.data.total ? GameData.data.total : 0,
            currentPage: GameData.data.currentPage ? GameData.data.currentPage : 0,
            errorCode: GameData.errorCode ? GameData.errorCode : 0,
        }
        const respData = {
            isUserGame: isUserLeague ? isUserLeague : false,
            current_week: current_week,
            knockout_plus_enabled : knockout_plus_enabled
        };
        dispatch(siteAction.changeLobbyFilterOptions(sortoptions, isJoinedOnly, filterOptions, contestList, isManageLeague, totalCount, totalPublishedCount, totalUnPublishedCount, totalLiveCount, totalCompletedCount, PaginationData, respData));

    }
};

export function changePlayerFilter(year, position, searchData, placingPositionId, lineUpWeek, contestId, isUserGame, lineup_number, sortBy = 'name') {
    return async (dispatch, getState) => {
        const playerData = await contestService.getSiteLineUpPlayerListByPosition(year, position, searchData, placingPositionId, 1, lineUpWeek, contestId, isUserGame, lineup_number,sortBy);
        const PaginationData = {
            perPage: playerData.data.perPage ? playerData.data.perPage : 10,
            totalPages: playerData.data.totalPages ? playerData.data.totalPages : 1,
            total: playerData.data.total ? playerData.data.total : 1,
            currentPage: playerData.data.currentPage ? playerData.data.currentPage : 1,
            errorCode: playerData.errorCode ? playerData.errorCode : 1,
        }
        const respData = {
            isUserGame: isUserGame ? isUserGame : false
        };
        dispatch(siteAction.changePlayerFilterOptions(searchData, playerData, PaginationData, respData, sortBy));
    }
};

export function changeLobbySort(sortOptions) {
    return async (dispatch, getState) => {
        dispatch(siteAction.changeLobbySortOptions(sortOptions));
    }
};

export function changeLobbyPageFilter(sortoptions, isJoinedOnly, filterOption, contestFilterOptions, isUserLeague, isManageLeague, page, pagePathRefreshed) {

    return async (dispatch, getState) => {
        const options = createOptionsAPI(sortoptions, isJoinedOnly, contestFilterOptions, filterOption, isUserLeague, page)
        if (pagePathRefreshed == 1)
            options.search_key = ""

        const GameData = (isManageLeague === true) ? await contestService.getMyCreatedContests(options) : await contestService.getSiteContests(options);
        let contestList = (GameData.data.result) ? GameData.data.result : [];
        let totalCount = (GameData.data.totalCreatedCount) ? GameData.data.totalCreatedCount : 0;
        let totalPublishedCount = (GameData.data.totalPublishedCount) ? GameData.data.totalPublishedCount : 0;
        let totalUnPublishedCount = (GameData.data.totalUnPublishedCount) ? GameData.data.totalUnPublishedCount : 0;
        let totalLiveCount = (GameData.data.totalLiveCount) ? GameData.data.totalLiveCount : 0;
        let totalCompletedCount = (GameData.data.totalCompletedCount) ? GameData.data.totalCompletedCount : 0;
        let current_week = (GameData.data.current_week) ? GameData.data.current_week : 0;
        let knockout_plus_enabled = GameData.data.knockout_plus_enabled ? GameData.data.knockout_plus_enabled : 0;

        const PaginationData = {
            perPage: GameData.data.perPage ? GameData.data.perPage : 10,
            totalPages: GameData.data.totalPages ? GameData.data.totalPages : 1,
            total: GameData.data.total ? GameData.data.total : 1,
            currentPage: GameData.data.currentPage ? GameData.data.currentPage : 1,
            errorCode: GameData.errorCode ? GameData.errorCode : 1,
        }
        const respData = {
            isUserGame: isUserLeague ? isUserLeague : false,
            current_week: current_week,
            knockout_plus_enabled : knockout_plus_enabled
        };
        dispatch(siteAction.changeLobbyPageOption(isJoinedOnly, filterOption, contestList, isManageLeague, totalCount, totalPublishedCount, totalUnPublishedCount, totalLiveCount, totalCompletedCount, PaginationData, respData));
    }
};

export function changeLobbyView(viewMode) {
    return async (dispatch, getState) => {
        dispatch(siteAction.changeLobbyView(viewMode));
    }
};

export function lineUpPlayerList(year, searchData, positionId, page, lineUpWeek, contestId, isUserGame, lineup_number = 1, sortBy = 'name') {
    return async (dispatch, getState) => {
        const PlayerData = await contestService.getSitePlayerList(year, searchData, positionId, page, lineUpWeek, contestId, isUserGame, lineup_number, sortBy);
        const PaginationData = {
            perPage: PlayerData.data.perPage ? PlayerData.data.perPage : 10,
            totalPages: PlayerData.data.totalPages ? PlayerData.data.totalPages : 1,
            total: PlayerData.data.total ? PlayerData.data.total : 1,
            currentPage: PlayerData.data.currentPage ? PlayerData.data.currentPage : 1,
            errorCode: PlayerData.errorCode ? PlayerData.errorCode : 1,
        }
        const respData = {
            isUserGame: isUserGame ? isUserGame : false
        };
        dispatch(siteAction.LineUpPlayerList(PlayerData, PaginationData, respData));
    }
};

export function lineUpPlayerListByPosition(year, position, searchData, positionId, page, lineup_week, contestId, isUserGame, lineup_number = 1, sortBy = "name") {
    return async (dispatch, getState) => {
        const playerData = await contestService.getSiteLineUpPlayerListByPosition(year, position, searchData, positionId, page, lineup_week, contestId, isUserGame, lineup_number, sortBy);
        const PaginationData = {
            perPage: playerData.data.perPage ? playerData.data.perPage : 10,
            totalPages: playerData.data.totalPages ? playerData.data.totalPages : 1,
            total: playerData.data.total ? playerData.data.total : 1,
            currentPage: playerData.data.currentPage ? playerData.data.currentPage : 1,
            errorCode: playerData.errorCode ? playerData.errorCode : 1,
        }
        const respData = {
            isUserGame: isUserGame ? isUserGame : false
        };
        const lineupsPerWeek = playerData.data.lineupsPerWeek;
        dispatch(siteAction.LineUpPlayerListByPosition(playerData.data, PaginationData, respData, lineupsPerWeek,sortBy));
    }
};

export function addLineUps(tiebreakerScore, contestId, year, lineUpWeek) {
    return async (dispatch, getState) => {
        const response = await contestService.postAddLineUps(tiebreakerScore, contestId, year, lineUpWeek);
        dispatch(siteAction.AddLineUps(response));
    }
};

export function addTieBreakerScore(tiebreakerScore, joinContestId, year, lineUpWk) {
    return async (dispatch, getState) => {
        const response = await contestService.postTieBreakerScore(tiebreakerScore, joinContestId, year, lineUpWk)
        dispatch(siteAction.AddTieBreakerScore(response));
    }
};


export function getJoinContestById(contestId) {
    return async (dispatch, getState) => {
        const response = await contestService.getJoinContestById(contestId)
        if (response.success) {
            dispatch(siteAction.GetJoinContestById(response));
        }
    }
};

export function joinUserContest(predictedTieScore, joinContestId, year, lineUpWk) {
    return async (dispatch, getState) => {
        const response = await contestService.JoinUserContest(predictedTieScore, joinContestId, year, lineUpWk)
        if (response.success) {
            dispatch(siteAction.JoinUserContest(response));
        }
    }
};

export function removeLineup(contestId, lineUpNumber, lineUpWeek) {
    return async (dispatch, getState) => {
        const response = await contestService.removeLineup(contestId, lineUpNumber, lineUpWeek);
        if (response.success) {
            dispatch(siteAction.DeleteLineUp(response));
        } else {
            return response
        }
    }
};

export function storeContestId(contestId) {
    return async (dispatch, getState) => {
        dispatch(siteAction.storeContestId(contestId));
    }
};

export function storeLineUpDefault(value) {
    return async (dispatch, getState) => {
        dispatch(siteAction.storeLineUpDefault(value));
    }
};

export function lineUpPositionPlayerCardView(teamId, lineUpNumber, contestId, playerPosition, playerId, lineUpWeek, isteam, placingPosition, year, sortBy) {
    return async (dispatch, getState) => {
        const playerData = await contestService.postLineUpPositionPlayerCardView(teamId, lineUpNumber, contestId, playerPosition, playerId, lineUpWeek, isteam, placingPosition, year, sortBy);
        if(playerData.errorCode == 200) {
            playerData.message = '';
        }
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
    }
};

export function getAllPlayersList(contestId, lineUpNumber, lineUpWk) {
    return async (dispatch, getState) => {
        const playerData = await contestService.getAllPlayersList(contestId, lineUpNumber, lineUpWk);
        let lineupData = playerData.data.lineupData ? playerData.data.lineupData : playerData;
        let scoresData = playerData.data.scoresData ? playerData.data.scoresData : [];
        if (playerData.data.scoresData.length > 0) {
            scoresData = playerData.data.scoresData[0];
        }
        dispatch(siteAction.getAllPlayersList(lineupData, scoresData));
    }
};

export function isShowPositionList(value) {
    return async (dispatch, getState) => {
        dispatch(siteAction.isShowPositionList(value));
    }
};
export function siteReducerSetSearchText(value) {

    return async (dispatch, getState) => {
        dispatch(siteAction.siteActionSetSearchText(value));
    }
};
export function showPlusDeleteIcon(value) {
    return async (dispatch, getState) => {
        dispatch(siteAction.showPlusDeleteIcon(value));
    }
};
export function playerPositionUpdate(position) {
    return async (dispatch, getState) => {
        dispatch(siteAction.playerPositionUpdate(position));
    }
};

export function lineUpPlayerListById(playerId) {
    return async (dispatch, getState) => {
        const response = await contestService.lineUpPlayerListById(playerId);
        dispatch(siteAction.lineUpPlayerListById(response));
    }
};

export function lineUpTeamPlayerList(year, searchData, id, isUserGame, contest_id, week, lineup_number) {
    return async (dispatch, getState) => {
        const playerData = await contestService.lineUpTeamPlayerList(year, searchData, id, isUserGame, contest_id, week, lineup_number);
        dispatch(siteAction.lineUpTeamPlayerList(playerData));
    }
};

export function setLeftSideMenu(page) {
    return async (dispatch, getState) => {
        dispatch(siteAction.setLeftSideMenu(page));
    }
};

export function removeLineupPlayers(payload) {
    return async (dispatch, getState) => {
        const playerData = await contestService.removeLineupPlayers(payload);
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
        return playerData;
    }
}

export function removeAllLineUpPlayers() {
    return async (dispatch, getState) => {
        dispatch(siteAction.removeAllLineUpPlayers());
    }
};

export function updateSelectedPlayerCard(cardIndex) {
    return async (dispatch, getState) => {
        dispatch(siteAction.updateSelectedPlayerCard(cardIndex));
    }
};

export function getSeasonStandingsResponse(params, isUserLeage) {
    return async (dispatch, getState) => {
        const seasonStandingssData = await contestService.getSeasonStandingsResponse(params, isUserLeage);
        dispatch(siteAction.getSeasonStandingsResponse(seasonStandingssData));
    }
};
export function getSeasonLeaderboardReducer(params, isUserLegue) {
    return async (dispatch, getState) => {
        const seasonLeaderboard = await contestService.getSeasonLeaderboardService(params, isUserLegue);
        dispatch(siteAction.seasonLeaderboardService(seasonLeaderboard));
    }
};

export function getSeasonStatsResponse(seasonStatsParams, staus_user_id) {
    return async (dispatch, getState) => {
        const response = await contestService.getSeasonStatsResponse(seasonStatsParams, staus_user_id);
        dispatch(siteAction.getSeasonStatsResponse(response));
    }
};

export function getWinnersResponse(params) {
    return async (dispatch, getState) => {
        const winnersData = await contestService.getWinnersResponse(params);
        dispatch(siteAction.getWinnersResponse(winnersData));
    }

};
export function updateSelectedPlayerCardForUsergame(cardIndex) {
    return async (dispatch, getState) => {
        dispatch(siteAction.updateSelectedPlayerCardForUsergame(cardIndex));
    }
};

export function removeAllLineUpPlayersForUsergame() {
    return async (dispatch, getState) => {
        dispatch(siteAction.removeAllLineUpPlayersForUsergame());
    }
};

export function changePlayerFilterForUsergame(year, position, searchData, placingPositionId) {
    return async (dispatch, getState) => {
        const playerData = await contestService.getSiteLineUpPlayerListByPosition(year, position, searchData, placingPositionId);
        const PaginationData = {
            perPage: playerData.data.perPage ? playerData.data.perPage : 10,
            totalPages: playerData.data.totalPages ? playerData.data.totalPages : 1,
            total: playerData.data.total ? playerData.data.total : 1,
            currentPage: playerData.data.currentPage ? playerData.data.currentPage : 1,
            errorCode: playerData.errorCode ? playerData.errorCode : 1,
        }
        const respData = {
            // isUserGame: isUserLeague ? isUserLeague : false
        };
        dispatch(siteAction.changePlayerFilterOptionsForUserGame(searchData, playerData, PaginationData, respData));
    }
};

export function addLineUpForUsergame(contestId, year, predictedTieScore, lineUpWeek) {
    return async (dispatch, getState) => {
        const response = await contestService.postAddLineUpsDetailsForUsergame(contestId, year, predictedTieScore, lineUpWeek);
        dispatch(siteAction.addLineUpForUsergame(response));
    }
};

export function removeLineupForUsergame(contestId, lineUpNumber, lineUpWeek) {
    return async (dispatch, getState) => {
        const response = await contestService.removeUsergameLineup(contestId, lineUpNumber, lineUpWeek);
        if (response.success) {
            dispatch(siteAction.removeLineupForUsergame(response));
        }
    }
};

export function getAllPlayersListForUsergame(lineUpNumber, contestId, lineUpWk) {
    return async (dispatch, getState) => {
        const playerData = await contestService.getAllPlayersListForUsergame(lineUpNumber, contestId, lineUpWk);
        let lineupData = playerData.data.lineupData ? playerData.data.lineupData : playerData;
        let scoresData = playerData.data.scoresData ? playerData.data.scoresData : [];
        if (playerData.data.scoresData.length > 0) {
            scoresData = playerData.data.scoresData[0];
        }
        dispatch(siteAction.getAllPlayersListForUsergame(lineupData, scoresData));
    }
};

export function saveUsergameLineUpPositionPlayerCardView(teamId, playerId, lineUpNumber, playerPosition, contestId, lineUpWeek, isteam, playerCardId, year,sortBy) {
    return async (dispatch, getState) => {
        const playerData = await contestService.saveUsergameLineUpPositionPlayerCardView(teamId, playerId, lineUpNumber, playerPosition, contestId, lineUpWeek, isteam, playerCardId, year, sortBy);
        if(playerData.errorCode == 200) {
            playerData.message = '';
        }
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
    }
};

export function removeUsergameLineupPlayers(payload) {
    return async (dispatch, getState) => {
        const playerData = await contestService.removeUsergameLineupPlayers(payload);
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
        return playerData;
    }
}

function transformMangeLeagueList(contestList) {
    let result = contestList.map((contest) => {
        const newContest = {
            ...contest,
            Type: contest.contest_type_id === 1 ? "Standard" : "Knockoutplus",
            IsGuaranteed: contest.guarantee_category === 'G' ? true : false,
            IsMultiple: contest.lup_entrylevel_category === 'M' ? true : false,
            IsLive: true,
            IsJoined: contest.si_contest_user_id !== null ? true : false,
            IsWinner: false,
            StartTime:contest.StartTime,
            Status: getContestStatus(contest.ContestStatus),
            Name: contest.contest_name,
            Id: contest.lg_contest_id,
            IsPublished: contest.contest_status_id === 8 ? false : true,
            ContestStatus: contest.contest_status_id,
            EntryCountMax: contest.max_users,
            EntryCount: contest.total_lineups
        }
        return newContest
    })
    return result
}

export function changeTeamSearchFilter(year, searchData, placingPositionId, isUserGame, contest_id, lineUpWeek, lineup_number, SortBy = '') {
    return async (dispatch, getState) => {
        const playerData = await contestService.lineUpTeamPlayerList(year, searchData, placingPositionId, isUserGame, contest_id, lineUpWeek, lineup_number, SortBy);
        dispatch(siteAction.changeTeamFilterOptions(searchData, playerData));
    }
};

export function getUnreadMessages() {
    return async (dispatch, getState) => {
        const unreadMessages = await userGamesService.getUnreadMessages();
        dispatch(siteAction.getUnreadMessages(unreadMessages));
        return unreadMessages;
    }
};

export function rulesAndScoring(contestId) {
    return async (dispatch, getState) => {
        const rulesAndScoring = await userGamesService.rulesAndScoring(contestId);
        dispatch(siteAction.rulesAndScoring(rulesAndScoring));
    }
};

export function clearLineUp(payload) {
    return async (dispatch, getState) => {
        const playerData = await contestService.clearLineUp(payload);
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
        return playerData;
    }
}

export function copyLineUp(playerData) {
    return async (dispatch, getState) => {
        dispatch(siteAction.LineUpPositionPlayerCardView(playerData));
        return playerData;
    }
}

export default siteReducer;
