import React from "react";


const SiteGamesIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.383" height="16.501" viewBox="0 0 15.383 16.501">
            <g id="Group_1" data-name="Group 1" transform="translate(-37.047 -152.974)">
                <path id="Path_10097" data-name="Path 10097" d="M16.044,12.094V6.326a1.441,1.441,0,0,0-.725-1.247L10.246,2.2A1.456,1.456,0,0,0,8.8,2.2L3.725,5.079A1.441,1.441,0,0,0,3,6.326v5.768a1.441,1.441,0,0,0,.725,1.247L8.8,16.225a1.456,1.456,0,0,0,1.449,0l5.073-2.884A1.441,1.441,0,0,0,16.044,12.094Z" transform="translate(35.217 151.998)" fill="none" stroke="#00001c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_10098" data-name="Path 10098" d="M3.27,6.96,9.6,10.6,15.922,6.96" transform="translate(35.142 150.614)" fill="none" stroke="#00001c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_10099" data-name="Path 10099" d="M12,19.267V12" transform="translate(32.738 149.208)" fill="none" stroke="#00001c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>



    );

}

export default SiteGamesIcon;