import React, { useState, useEffect, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import SortingIcon from "../assets/icons/sorting";
import Viewbtn from "../components/Viewbtn";
import Paginate from "../components/Paginate";

import TrophyIcon from "../assets/icons/trophy";
import "../components/contestlist.scss";
import * as commonService from "../services/commonService";
import useCountdown from "../appState/counterState/useCountdown";
import { pageURLs } from "../appData/pageURLs";
import { NavLink, useNavigate } from "react-router-dom";
import { sortOrderOptions } from "../appData/sortOrderOptions";
import useSiteState from '../appState/siteState/useSiteState';
import LiveTimer from "./LiveTimer";
import moment from "moment";
const ContentList = (props) => {
    const siteState = useSiteState();
    const sortState = props.sortState;
    setIsJoinedOnly(props.viewURL);

    const sortChange = (type) => {
        switch (type) {
            case "Entries":
                switch (sortState.entries) {
                    case sortOrderOptions.None:
                        sortState.entries = sortOrderOptions.Ascending;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    case sortOrderOptions.Ascending:
                        sortState.entries = sortOrderOptions.Descending;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    case sortOrderOptions.Descending:
                        sortState.entries = sortOrderOptions.Ascending;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    default:
                        throw new Error("Not Implemented");
                }
                onSortChange(sortState);
                break;
            case "Prize":
                switch (sortState.prize) {
                    case sortOrderOptions.None:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.Ascending;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    case sortOrderOptions.Ascending:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.Descending;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    case sortOrderOptions.Descending:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.Ascending;
                        sortState.fees = sortOrderOptions.None;
                        break;
                    default:
                        throw new Error("Not Implemented");
                }
                onSortChange(sortState);
                break;
            case "EntryFee":
                switch (sortState.fees) {
                    case sortOrderOptions.None:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.Ascending;
                        break;
                    case sortOrderOptions.Ascending:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.Descending;
                        break;
                    case sortOrderOptions.Descending:
                        sortState.entries = sortOrderOptions.None;
                        sortState.prize = sortOrderOptions.None;
                        sortState.fees = sortOrderOptions.Ascending;
                        break;
                    default:
                        throw new Error("Not Implemented");
                }
                onSortChange(sortState);
                break;
            default:
                throw new Error("Not Implemented");
        }
    }

    const onSortChange = (values) => {
        setIsJoinedOnly(props.viewURL);
        if (props.onSortChange) {
            props.onSortChange(values);
        }
    }

    function setIsJoinedOnly(viewUrl) {
        if (props.viewURL == pageURLs.allcontest || props.viewURL == pageURLs.manageleague || props.viewURL == pageURLs.alluserleague ) {
            siteState.data.Lobby.IsJoinedOnly = false;
        }
        else if (props.viewURL == pageURLs.lobby || props.viewURL == pageURLs.joinedupcoming || props.viewURL == pageURLs.joinedlive || props.viewURL == pageURLs.joinedhistory) {
            siteState.data.Lobby.IsJoinedOnly = true;
        }
    }

    const getContests = (contestList) => {
        if (contestList) {
            return contestList.map((item) => (
                <ContestListItem item={item} key={Math.random()}  isUserGame={props.isUserGame} showManage={props.showManage} />
            ));
        }
        else {
            return <></>;
        }
    };

    const pageNumberChange = (page) => {
        props.pageChange(page);
    }

    return (
        <>
            <Table responsive className="contest-list-table">
                <thead>
                    {props.contestData.length > 0 ?
                        <tr id="lobbyListHeading">
                            <th>&nbsp;</th>
                            <th width="22%">&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>Categories</th>
                            <th className="sort" onClick={() => { sortChange("Entries"); }}>Entries <SortingIcon sortOrder={sortState.entries} /></th>
                            {!props.isUserGame ? <th className="sort" onClick={() => { sortChange("Prize"); }}>Prize <SortingIcon sortOrder={sortState.prize} /></th> : ""}
                            {!props.isUserGame ? <th className="sort" onClick={() => { sortChange("EntryFee"); }}>Entry Fee <SortingIcon sortOrder={sortState.fees} /></th> : ""}
                            {props.showManage && <th className="text-right">Payment Status</th>}
                            <th className="text-right" width="16%">Status</th>
                            
                        </tr> : <tr><p>No records to display.</p></tr>
                    }

                </thead>
                <tbody>
                    {getContests(props.contestData)}
                </tbody>
            </Table>
            {props.paginationData ? <Paginate paginationData={props.paginationData} pageRows={props.contestData.length} onClick={pageNumberChange} /> : ""}
        </>
    );

};

const getPublishStatus = (isUserGame, showManage, isPublished) => {
    if (isUserGame && showManage) {
        if (isPublished) {
            return <p className="status-info publish">Status <span>Published</span></p>;
        } else {
            return <p className="status-info unpublish">Status <span>Unpublished</span></p>;
        }
    } else {
        return <></>;
    }
}

const ContestListItem = (props) => {


    const [enableJoinContest, setEnableJoinContest] = useState(true);

    const contestStatusChanged = (contestDisplayStatus) => {
        if (contestDisplayStatus == 2)
            setEnableJoinContest(false)
        else
            setEnableJoinContest(true)
    }
    const siteState = useSiteState();
    const navigate = useNavigate();


    //let contestStartDate = commonService.convertUTCDateToLocalDate(new Date(props.item.StartTime));
    let dtCurrent = new Date(props.item.StartTime);
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    let dtEst = new Date(estDate);
    let countDownDate = commonService.getTimeDifference(dtEst);
    var details_link = "";
    if (props.isUserGame) {
        details_link = pageURLs.usergamejoincontest + '/' + props.item.Id;
    }
    else {
        details_link = pageURLs.joincontest + '/' + props.item.Id;
    }

    if (props.item.contest_user_status_id != 3 && props.item.si_contest_user_id > 0 && !props.isUserGame) {
        details_link = pageURLs.createlineup + '/' + props.item.Id;
    }
    else if (props.item.contest_user_status_id != 3 && props.item.si_contest_user_id > 0 && props.isUserGame) {
        details_link = pageURLs.usergamecreatelineup + '/' + props.item.Id;
    }

    //manage league
    if (props.isUserGame && props.showManage) {
        details_link = pageURLs.leaguedetails + '/' + props.item.Id;
        if (!props.item.IsPublished) {
            details_link = pageURLs.editleague + '/' + props.item.Id;
        }
    }

    const [liveTimer, setLiveTimer] = useState(1)

    useEffect(() => {

        const intervalId = setInterval(() => {

            setLiveTimer(Math.floor(Math.random() * 10));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [liveTimer])

    let paymentStatus = 0;
    if(props.showManage) {
        paymentStatus = (props.item.transaction_status_id == 1) ? 1 : 0;
    }
    return (
        <tr className={(props.item.ContestStatus === 4) ? "disabled" : ""}>
            <td> 
                {/* {props.item.contest_user_status_id == 1 && props.item.si_contest_user_id > 0 && <span className="joined">joined</span>} */}
                {props.item.contest_user_status_id == 2 && props.item.si_contest_user_id > 0 && <span className="eliminated-label">eliminated</span>}

                {props.showManage ?
                    <Button variant="outline-primary" className="manage-btn" onClick={() => { navigate(details_link); }}>Manage</Button> :
                    <NavLink to={{ pathname: `${details_link}` }} >
                        <Viewbtn />
                    </NavLink>
                }
            </td>
            <td >

                {props.item.ContestStatus == 2 && <p className="gamelive"><span className="ripple"></span>GAME LIVE</p>}
                <p>
                    {props.isUserGame ? <NavLink to={{ pathname: details_link }} >
                        {props.item.Name}
                    </NavLink>
                        : <NavLink to={{ pathname: details_link }} >
                            {props.item.Name}
                        </NavLink>}

                </p>
                {(props.isUserGame && !props.showManage && props.item.InvitedBy && props.item.InvitedBy.length > 0) &&
                    <p className="status-info">Invited by <span>{props.item.InvitedBy}</span></p>
                }
                {getPublishStatus(props.isUserGame, props.showManage, props.item.IsPublished)}
            </td>
            <td align="right">
                {props.item.IsWinner && <i className="trophy-icon"></i>}
                {(props.item.Type === "Standard") && <span className="bg-label bg-standard">Standard</span>}
                {(props.item.Type === "Knockoutplus") && <span className="bg-label bg-knockout">Knockout Plus</span>}
            </td>
            <td>
                {props.item.IsMultiple && <span className="badge bg-m">M</span>}
                {!props.item.IsMultiple && <span className="badge bg-m">S</span>}
                {props.item.IsGuaranteed && <span className="badge bg-g">G</span>}
                {props.item && props.item.EntryFeeStatus =='F' && <span className="badge bg-f">F</span>}
          </td>
            <td>{props.item.EntryCount}/{props.item.EntryCountMax}</td>
            {!props.isUserGame ? <td>{(props.item.Prize) ? commonService.toCurrency(props.item.Prize) : '0.00'}</td> : ""}
            {!props.isUserGame ? <td>{(props.item.EntryFee) ? commonService.toCurrency(props.item.EntryFee) : '0.00'}</td> : ""}
            {(props.showManage && paymentStatus) ? <td align="right" className="clr-active"><div className="con-status">Paid</div></td> : ''}
            {(props.showManage && !paymentStatus) ? <td align="right" className="clr-expire"><div className="con-status">Not Paid</div></td> : ''}
            {(props.item.ContestStatus === 2) && <td align="right" className="clr-active"><div className="con-status">Live Contest</div></td>}

            {(props.item.ContestStatus === 3) && <td align="right"><div className="con-status">
                {
                    <UpcomingContestTimer hour={(countDownDate.hours) ? countDownDate.hours : 0} minute={(countDownDate.minutes) ? countDownDate.minutes : 0} second={(countDownDate.seconds) ? countDownDate.seconds : 0} />
                }

            </div></td>}
            {(props.item.ContestStatus === 4 || props.item.ContestStatus === 7) && <td align="right" className="clr-expire"><div className="con-status">Expired</div></td>}
            {(props.item.ContestStatus === 5) && <td align="right" className="clr-complete"><div className="con-status">Past</div></td>}

                
            {(props.item.ContestStatus === 8 && props.showManage) && <td align="right" className="clr-complete"><div className="con-status">Unpublished</div></td>}

        </tr>
    );
};

const UpcomingContestTimer = (props) => {
    let countDownHours = (props.hour > 0) ? props.hour : 0;
    let countDownMinutes = (props.minute > 0) ? props.minute : 0;
    let countDownSeconds = (props.second > 0) ? props.second : 0;
    const [hour, setHour] = useState(countDownHours);
    const [minute, setMinute] = useState(countDownMinutes);
    const [second, setSecond] = useState(countDownSeconds);

    const getTime = () => {
        return { hour: countDownHours, minute: countDownMinutes, second: countDownSeconds };
    }

    useCountdown((data) => {
        setHour(countDownHours);
        setMinute(countDownMinutes);
        setSecond(countDownSeconds);
    }, getTime()
    );

    if(hour > 24){
       const  days = Math.floor(hour / (24));
       const remainingHours = hour % 24; 
       return <>{days}d {remainingHours}h {minute}m {second}s</>;  
    } else {
        return <>{hour}h {minute}m {second}s</>;
    }
    
    
};

export default ContentList;
