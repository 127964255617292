import React, { useState, useEffect, useRef } from "react";
import { Button, Accordion, Tab, Nav, Offcanvas, ToastContainer, Toast } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import InnerPageWrap from "../components/InnerPageWrap";
import HornIcon from "../assets/icons/hornicon";
import PlusIcon from "../assets/icons/plusicon";
import KOTextarea from "../components/KOTextarea";
import TickIcon from "../assets/icons/tickicon";
import "../styles/messageboard.scss";
import { pageURLs } from "../appData/pageURLs";
import Moment from 'react-moment';
import useSiteState from '../appState/siteState/useSiteState';
import * as userGamesController from "../controller/userGamesController";
import Paginate from "../components/Paginate";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Markup } from 'interweave';

const MessageBoard = (props) => {
const [showusernamesec, setUsernamesec] = useState(false);
const handleCloseUsernamesec = () => setUsernamesec(false);
const handleShowUsernamesec = () => setUsernamesec(true);
const [showtoast, setShowToast] = useState(false);
const [recievedMessages, setRecievedMessages] = useState([]);
const [sentMessages, setSentMessages] = useState([]);
const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
const [receivedleaugeDeailsPageData, setReceivedLeaugeDeailsPageData] = useState([]);
const [sendleaugeDeailsPageData, setSendleaugeDeailsPageData] = useState([]);
const [contestName, setContestName] = useState('');
const [contestId, setContestId] = useState('');
const [createMessageError, setCreateMessageError] = useState('');
const initialPageParams = {
    page : 1
}
const [recievedMessagesPageParams, setRecievedMessagesPageParams] = useState(initialPageParams);
const [sendMessagesPageParams, setSendMessagesPageParams] = useState(initialPageParams);

const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

const  [convertedContent, setConvertedContent] = useState('');

const siteState = useSiteState();

    const getRecievedMessages = async () => {
        await userGamesController.getRecievedMessages(recievedMessagesPageParams)
            .then(response => {
                if (response.success) {
                    setUnreadMessagesCount(response.data.total_unread_msgs)
                    setRecievedMessages(response.data.data);
                    setReceivedLeaugeDeailsPageData({
                        perPage: response.data.perPage ? response.data.perPage : 5,
                        totalPages: response.data.totalPages ? response.data.totalPages : 0,
                        total: response.data.total ? response.data.total : 0,
                        currentPage: response.data.currentPage ? response.data.currentPage : 0,
                        errorCode: response.errorCode ? response.errorCode : 0,
                    });
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }
    const RecievedMessagesPageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setRecievedMessagesPageParams(pageparams);
    }
    
    const sendMessagesPageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setSendMessagesPageParams(pageparams);
    }

    const getSendMessages = async () => {
        await userGamesController.getSendMessages(sendMessagesPageParams)
            .then(response => {
                if (response.success) {
                    setSentMessages(response.data.result);
                    setSendleaugeDeailsPageData({
                        perPage: response.data.perPage ? response.data.perPage : 0,
                        totalPages: response.data.totalPages ? response.data.totalPages : 0,
                        total: response.data.total ? response.data.total : 0,
                        currentPage: response.data.currentPage ? response.data.currentPage : 0,
                        errorCode: response.errorCode ? response.errorCode : 0,
                    });
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }

    useEffect(() => {
        getRecievedMessages();
    }, [])

    const readMessage = (contestId, unreadMsgs) => {
        let dateobj = new Date();
        let msgReadDate = dateobj.toISOString();
        if (unreadMessagesCount !== 0) {
            userGamesController.readLeagueMessage(contestId, msgReadDate)
                .then(response => {
                    if (response.success) {
                        getRecievedMessages();
                    };
                })
                .catch(err => {
                    console.log("Error", err);
                });
        }
    }

    const sentMessage = async () => {
        let isValid = true;
        if ((convertedContent.replace(/(<([^>]+)>)/ig, '')).trim() === "") {
            isValid = false;
            setCreateMessageError("Enter message")
        }
        if (isValid) {
            let response = await userGamesController.sendLeaugeMessage(contestId, convertedContent);
            if (response.errorCode === 200) {
                getRecievedMessages();
                getSendMessages();
                setShowToast(true);
                setCreateMessageError('')
                setEditorState('');
                setConvertedContent('');
            }
        }
    };

    const handleEditorChange = (state) => {
        setCreateMessageError('');
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }

	return (
        <>
            <InnerPageWrap>
                <div className="koinner-sec">
                    <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.messageboard} unreadMessagesCount={unreadMessagesCount} /></div>
                    <div className="kodata-content">
                        <div className="messageboard-container">
                            <div className="message-tab">
                                <Tab.Container id="messageboard-tab" defaultActiveKey="recieved">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="recieved">Received {unreadMessagesCount !== "" && unreadMessagesCount !== 0 ? <span className="count">{unreadMessagesCount}</span>:""}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="sent" onClick={()=>getSendMessages()}>Sent</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="recieved">
                                            <div className="message-title">
                                                <h6>Contest Message Received</h6>
                                                <p>Messages received from Joined Contest Admin</p>
                                            </div>
                                            {recievedMessages
                                                .map((items) => (
                                                    <Accordion defaultActiveKey="0" onClick={() => readMessage(items.lg_contest_id,items.unread_msg_count)}className="message-accordion">
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>
                                                            <div className="icon-horn"><HornIcon/>{items.total_unread_msgs > 0 ? <span className="dot"></span> :""}</div>
                                                                {items.contest_name}
                                                                <p>Invited by <span>{items.invited_by}</span></p>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {items.messages
                                                                .map((messages) => (
                                                                    <div className="content-block" >
                                                                        <p><Markup content={messages.message}/></p>
                                                                        <span>From : {messages.msg_from} |</span>
                                                                        <span><Moment format=' h:mm a | MMM D, YYYY'>{messages.edited_on}</Moment></span>
                                                                    </div>
                                                                ))}
                                                            </Accordion.Body>                                                        
                                                        </Accordion.Item>                                                        
                                                    </Accordion>
                                                ))}
                                            <Paginate
                                                paginationData={receivedleaugeDeailsPageData}
                                                pageRows={receivedleaugeDeailsPageData.total}
                                                onClick={RecievedMessagesPageNumberChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="sent">
                                            <div className="message-title">
                                                <h6>League Messages Sent</h6>
                                                <p>Messages sent to your league participants</p>
                                            </div>
                                            {sentMessages
                                                .map((items) => (
                                                    <Accordion defaultActiveKey="0" className="message-accordion">
                                                        <Accordion.Item eventKey="1">
                                                            <Button variant="outline-primary" className="create-btn" onClick={() =>{handleShowUsernamesec(); setContestName(items.contest_name); setContestId(items.lg_contest_id)}}><PlusIcon /> Create Message</Button>
                                                            <Accordion.Header>
                                                            <div className="icon-horn"><HornIcon/></div>
                                                                {items.contest_name}
                                                                <p>Status <span>{items.is_published === 1 ? "published":"unpublished"}</span></p>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {items.messages
                                                                .map((messages) => (
                                                                    <div className="content-block" >
                                                                        <p><Markup content={messages.message}/></p>
                                                                        <span><Moment format=' h:mm a | MMM D, YYYY'>{messages.sent_on}</Moment></span>
                                                                    </div>
                                                                ))}
                                                               
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                ))}
                                                 <Paginate
                                                paginationData={sendleaugeDeailsPageData}
                                                pageRows={sendleaugeDeailsPageData.total}
                                                onClick={sendMessagesPageNumberChange}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                <Offcanvas show={showusernamesec} onHide={handleCloseUsernamesec} placement="end" className="custom-sidebar change-username">
                                    <Offcanvas.Header closeButton></Offcanvas.Header>
                                    <Offcanvas.Body>
                                    <ToastContainer position="top-end">
                                        <Toast className="toast-success" onClose={() => setShowToast(false)} show={showtoast} delay={3000} >
                                                <Toast.Body>
                                                    <TickIcon />
                                                    <div>
                                                        <h6>Message Sent</h6>
                                                        Your message has been sent to all entries successfully.
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                        </ToastContainer>
                                        <h5>Create a Message</h5>
                                        <div className="league-name">
                                            <p>League Name</p>
                                            <h5>{contestName}</h5>
                                        </div>
                                        <div className="sidebar-body">
                                            <Editor
                                                editorState={editorState}
                                                initialEditorState={editorState}
                                                onEditorStateChange={handleEditorChange}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                placeholder="Enter your message"                                                                                                                                           
                                                toolbar={{
                                                    options: [ 'emoji','inline'],
                                                    inline: {
                                                        inDropdown: false,
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                }}
                                            />    
                                            <div className="alert-message">
                                                {createMessageError}
                                            </div>                                        
                                            <div className="btn-sec"><Button variant="primary" onClick={() => sentMessage()}>Send Message</Button> </div>
                                        </div>                                        
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </div>
                        </div>
                    </div>
                </div>
            </InnerPageWrap>
        </>
    );

    };

    export default MessageBoard;
