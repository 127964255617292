import React from 'react';

const KOSearchInput = props => {
  return (
      <div className="form-group">
          <div className="inputgroup searchinput" id={props.containerId}>
              <span className='searchicon'></span>
              <input
                  className={props.class ? `form-control ${props.class}` : "form-control"}
                  id={props.id}
                  name={props.name}
                  type={props.type}
                  value={props.value}
                  onChange={props.onChange}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  maxLength={props.maxLength}
              />
          </div>
      </div>
  );
}

export default (KOSearchInput);
