import React, { useState, useEffect, useRef } from "react";
import { Accordion, Tooltip, OverlayTrigger } from "react-bootstrap";
import gameformation from '../assets/game-formation.svg';

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip"{...props}>Any TD (other than passing TDs) scored by an active player</Tooltip>
);

const RulesAndScoring = (props) => {

    const [contestData, setContestData] = useState(props.contestData);
    const [defaultAccordion, setDefaultAccordion] = useState(props.defaultAccordion);
    
    return (
        <div className="lineupscore-container">
            <h4>All games offered by Fantasy Sports Knockout use standardized lineups and scoring</h4>
            <div className="game-formation-wrap"><img src={gameformation} /></div>
            <div className="scoring-info-wrap">
                <div className="score-data">
                    <h5>all offensive players utilize the same scoring system</h5>
                    <div className="score-card-block">
                        <div className="card-wrap">
                            <div className="score-card">
                                <div className="score"><p>Passing Yards</p><p className="value">.04</p></div>
                                <div className="score"><p>Passing TDs</p><p className="value">4</p></div>
                                <div className="score">
                                    <p>All Other TDs 
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                            <i className="ic-info"></i>
                                        </OverlayTrigger>
                                    </p>
                                    <p className="value">6</p>
                                </div>
                                <div className="score"><p>All Turnovers</p><p className="value">-2</p></div>
                            </div>
                        </div>
                        <div className="card-wrap">
                            <div className="score-card">
                                <div className="score"><p>Rushing Yards</p><p className="value">0.1</p></div>
                                <div className="score"><p>Receiving Yards</p><p className="value">0.1</p></div>
                                <div className="score"><p>Receptions</p><p className="value">1</p></div>
                                <div className="score"><p>All 2-Pt Conversions</p><p className="value">2</p></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="score-data">
                    <div className="score-card-block">
                        <div className="card-wrap">
                            <h5>Kickers</h5>
                            <div className="score-card bg-blue">
                                <div className="score"><p>FGs &lt;40</p><p className="value">3</p></div>
                                <div className="score"><p>FGs 40-49</p><p className="value">4</p></div>
                                <div className="score"><p>FGs 50+</p><p className="value">5</p></div>
                                <div className="score"><p>Pats</p><p className="value">1</p></div>
                            </div>
                        </div>
                        <div className="card-wrap">
                            <h5>DST</h5>
                            <div className="score-card bg-blue">
                                <div className="score"><p>All TDS</p><p className="value">6</p></div>
                                <div className="score"><p>All Turnovers</p><p className="value">6</p></div>
                                <div className="score"><p>Sacks</p><p className="value">1</p></div>
                                <div className="score"><p>Safeties</p><p className="value">2</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        
        <Accordion defaultActiveKey={defaultAccordion} className="rules-accordion" style={{display: "none"}}>
            {contestData && contestData.contestDetails[0].contest_type_id == 2 && <Accordion.Item eventKey="0">
                <Accordion.Header>Knockout Plus Variant</Accordion.Header>
                <Accordion.Body>
                    <p>Just like in our standard contests, there is only one winner in the elimination contest. That winner is the Champion of the Knockout Plus. However, additional prizes are awarded to:</p>
                    <p>Weekly High Scores (criteria varies by contest)</p>
                    <p>Season Long Points Totals (number of winners varies by contest)</p>
                    <div className="play-basics">
                        <h5>Fantasy Sports Knockout uses the following standard scoring:</h5>
                        <div className="scoring-data">
                            <div className="player-position">
                                <h5>OFFENSE</h5>
                                <h6>Each Yard:</h6>
                                <p>Passing: 0.04 points</p>
                                <p>Receiving/Rushing: 0.1 points</p>
                                <h6>Touchdowns:</h6>
                                <p>Passing: 4 points</p>
                                <p>ALL other TDs scored by a player (including receiving, rushing, special teams and turnover recoveries): 6 points</p>
                                <p>All Turnovers (fumbles lost, interceptions thrown): -2 points</p>
                                <p>Receptions: 1 point each</p>
                                <p>2-point conversion (all types): 2 points</p>
                            </div>
                            <div className="player-position special">
                                <h5>DST</h5>
                                <p>All Touchdowns: 6 points</p>
                                <p>All Turnovers (fumbles recovered, interceptions): 2 points</p>
                                <p>Sacks: 1 point</p>
                                <p>Safety: 2 points</p>
                                <p>1-point Safety: 1 point</p>
                            </div>
                            <div className="player-position">
                                <h5>KICKER</h5>
                                <p>Extra Points: 1 point</p>
                                <p>Field Goal 0-39: 3 points</p>
                                <p>Field Goal 40-49: 4 points</p>
                                <p>Field Goal 50+: 5 points</p>
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            }
            <Accordion.Item eventKey="1">
                <Accordion.Header>Weekly Elimination Formula</Accordion.Header>
                <Accordion.Body>
                    <p>Example Only</p>
                    <div className="tags-sec">
                        <span className="tag">100 player contest with 13 weeks</span><span className="tag">1st Half: 6 weeks - 2nd Half: 7 weeks</span>
                        <span className="tag">Alive for 2nd half: 8</span><span className="tag">Eliminated 1st half: 92</span><span className="tag">92 / 6 =15.3, rounded down to 15</span>
                        <span className="tag">15 (per week) x 6 (weeks) = 90</span><span className="tag">92 - 90 = 2</span>
                        <span className="tag">Two (2) additional eliminated in week 1 (17)</span>
                    </div>
                    <p>For the final half of the contest, we will eliminate one (1) user per week. For the first half of the contest, we will eliminate all other users. We determine the number of weeks in each half by dividing the number of weeks in the contest by 2.
                        If the contest contains an odd number of weeks, the second half will contain the higher number of weeks. Subtract the number of weeks in the second half and add one to determine how many users will be alive for the second half.
                        Subtract the number from the previous example from the total number of users in the contest to determine how many will be eliminated in the first half. Divide the sum of users that will be eliminated in the first half equally
                        amongst the weeks in the first half. In most cases, this will not be a round number. Therefore, round the calculated result down, and eliminate the remainder in Week 1.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Scoring Rules</Accordion.Header>
                <Accordion.Body>
                <div className="scoring-data rules-card-data">
                    <div className="player-position">
                        <h4>OFFENSIVE SCORE</h4>
                        <div className="info-block">
                            <h5>Passing Scoring rules</h5>
                            <p>For each passing yard: 0.04 points</p>
                            <p>For each passing TD: 4 points</p>
                        </div>
                        <div className="info-block">
                            <h5>Rushing Scoring rule</h5>
                            <p>For each rushing yard: 0.1 point </p>
                        </div>
                        <div className="info-block">
                            <h5>Reception Scoring rules</h5>
                            <p>For each receiving yard: 0.1 point</p>
                            <p>For each reception: 1 point</p>
                        </div>
                        <div className="info-block">
                            <h5>All other TDS (rushing, reception, turn over recoveries): 6 points</h5>
                            <h5>Two point conversion: 2 points</h5><h5>All turnovers: -2 points</h5>
                        </div>
                    </div>
                    <div className="player-position kicker-card"><h4>KICKER</h4><p>Extra points: 1 point</p>
                        <p>Field Goal 0-39: 3 points</p>
                        <p>Field Goal 40-49: 4 points</p><p>Field Goal 50+:  5 points</p>
                    </div>
                    <div className="player-position "><h4>DST</h4>
                        <p>All TDS: 6 points</p>
                        <p>All turnovers: 2 points</p>
                        <p>Sacks: 1 point</p><p>Safety: 2 points</p>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Lineup Requirements</Accordion.Header>
                <Accordion.Body>
                    <p>QB - 1, WR - 3, RB - 2, FLEX - 1, K - 1, TE - 1, DST - 1</p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
      
        
    )
}

export default RulesAndScoring;
