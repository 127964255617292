
export const Type = Object.freeze({
    SiteGames: 'Site_SiteGames',
    SiteGamesCleared: 'Site_SiteGamesCleared',
    UserGames: 'Site_UserGames',
    LobbyFilterOptionsChange: 'Site_LobbyFilterOptionsChange',
    LobbySortOptionsChange: 'Site_LobbySortOptionsChange',
    LobbyPageOptionsChange: 'Site_LobbyPageOptionsChange',
    LobbyPageViewChange: 'Site_LobbyPageViewChange',
    JoinContestLineUpPlayerList: 'Site_JoinContestLineUpPlayerList',
    JoinContestLineUpPlayerListByPosition: 'Site_JoinContestLineUpPlayerListByPosition',
    JointContestAddLineUps: 'Site_JointContestAddLineUps',
    JointContestAddTieBreakerScore: 'Site_JointContestAddTieBreakerScore',
    UserContestJoin: 'Site_UserContestJoin',
    GetJoinContestData: 'Site_GetJoinContestData',
    JointContestDeleteLineUp: 'Site_JointContestDeleteLineUp',
    showContestId: 'Site_showContestId',
    PositionPlayerCardViewLineUp: 'Site_PositionPlayerCardViewLineUp',
    setSearchText: 'setSearchText',

    showPositionList: 'Site_showPositionList',
    PlayerFilterOptionsChange: 'Site_PlayerFilterOptionsChange',
    showLineUpCard: 'Site_showLineUpCard',
    playerById: 'Site_playerById',
    PlusDeleteIcon: 'Site_PlusDeleteIcon',
    getAllPlayersList: 'Site_getAllPlayersList',
    JoinContestLineUpTeamPlayerList: 'Site_JoinContestLineUpTeamPlayerList',
    playerPosition: 'Site_playerPosition',
    getLineUpNumber: 'Site_getLineUpNumber',
    LeftSideActiveMenu: 'Site_Active_Menu',
    RemoveLineupPlayers: 'RemoveLineupPlayers',
    RemoveAllLineUpPlayers: 'RemoveAllLineUpPlayers',
    UpdateSelectedPlayerCard: 'UpdateSelectedPlayerCard',
    UpdateSelectedPlayerCardForUsergame: 'UpdateSelectedPlayerCardForUsergame',
    GetSeasonStats: 'GetSeasonStats',
    WinnersResponse: 'WinnersResponse',
    SeasonStandingsResponse: 'SeasonStandingsResponse',
    SeasonLeaderboardService: 'SeasonLeaderboardService',
    RemoveAllLineUpPlayersForUsergame: 'RemoveAllLineUpPlayersForUsergame',
    PlayerFilterOptionsChangeForUsergame: 'Site_PlayerFilterOptionsChangeForUsergame',
    PositionPlayerCardViewLineUpForUsergame: 'Site_PositionPlayerCardViewLineUpForUsergame',
    GetAllPlayersListForUsergame: 'Site_GetAllPlayersListForUsergame',
    JoinContestLineUpTeamPlayerFilterList: 'Site_JoinContestLineUpTeamPlayerFilterList',
    // MessageBoardRecievedMessages : 'MessageBoardRecievedMessages',
    MessageBoardUnreadMessages : 'MessageBoardUnreadMessages',
    RulesAndScoringData : 'RulesAndScoringData'
});

export const ChangeToSiteGame = (ContestList, isManageLeague = false, totalCount = 0, totalPublishedCount = 0, totalUnPublishedCount = 0, totalLiveCount = 0, totalCompletedCount = 0, PaginationData, respData) => {
    return {
        type: Type.SiteGames,
        payload: { ContestList: ContestList, PaginationData: PaginationData, respData: respData, isManageLeague: isManageLeague, totalCount: totalCount, totalPublishedCount: totalPublishedCount, totalUnPublishedCount: totalUnPublishedCount, totalLiveCount: totalLiveCount, totalCompletedCount: totalCompletedCount }
    };
}
export const ResetSiteGame = (ContestList, isManageLeague = false, totalCount = 0, totalPublishedCount = 0, totalUnPublishedCount = 0, totalLiveCount = 0, totalCompletedCount = 0, PaginationData, respData) => {
     return {
        type: Type.SiteGamesCleared,
        payload: { ContestList: ContestList, PaginationData: PaginationData, respData: respData, isManageLeague: isManageLeague, totalCount: totalCount, totalPublishedCount: totalPublishedCount, totalUnPublishedCount: totalUnPublishedCount, totalLiveCount: totalLiveCount, totalCompletedCount: totalCompletedCount }
    };
}
export const ChangeToUserGame = (ContestList, PaginationData, respData, isManageLeague = false, totalCount = 0, totalPublishedCount = 0, totalUnPublishedCount = 0, totalLiveCount = 0, totalCompletedCount = 0) => {
    return {
        type: Type.UserGames,
        payload: { ContestList: ContestList, PaginationData: PaginationData, respData: respData, isManageLeague: isManageLeague, totalCount: totalCount, totalPublishedCount: totalPublishedCount, totalUnPublishedCount: totalUnPublishedCount, totalLiveCount: totalLiveCount, totalCompletedCount: totalCompletedCount }
    };
}

export const changeLobbyFilterOptions = (sortoptions, isJoinedOnly, FilterOptions, ContestList, isManageLeague = false, totalCount = 0, totalPublishedCount = 0, totalUnPublishedCount = 0, totalLiveCount = 0, totalCompletedCount = 0, PaginationData, respData) => {
    return {
        type: Type.LobbyFilterOptionsChange,
        payload: { sortoptions, isJoinedOnly, FilterOptions, ContestList: ContestList, isManageLeague: isManageLeague, totalCount: totalCount, totalPublishedCount: totalPublishedCount, totalUnPublishedCount: totalUnPublishedCount, totalLiveCount: totalLiveCount, totalCompletedCount: totalCompletedCount, PaginationData: PaginationData, respData: respData }
    };
}

export const changePlayerFilterOptions = (SearchOptions, PlayerList, PaginationData, respData, sortBy) => {
    return {
        type: Type.PlayerFilterOptionsChange,
        payload: { SearchOptions, PlayerList: PlayerList, PaginationData: PaginationData, respData: respData , sortBy}
    };
}


export const changeLobbySortOptions = (SortOptions) => {
    return {
        type: Type.LobbySortOptionsChange,
        payload: { SortOptions }
    };
}

export const changeLobbyPageOption = (IsJoinedOnly, FilterOption, ContestList, isManageLeague = false, totalCount = 0, totalPublishedCount = 0, totalUnPublishedCount = 0, totalLiveCount = 0, totalCompletedCount = 0, PaginationData, respData) => {
    return {
        type: Type.LobbyPageOptionsChange,
        payload: { IsJoinedOnly, FilterOption, ContestList: ContestList, isManageLeague: isManageLeague, totalCount: totalCount, totalPublishedCount: totalPublishedCount, totalUnPublishedCount: totalUnPublishedCount, totalLiveCount: totalLiveCount, totalCompletedCount: totalCompletedCount, PaginationData: PaginationData, respData: respData }
    };
}

export const changeLobbyView = (viewMode) => {
    return {
        type: Type.LobbyPageViewChange,
        payload: { viewMode }
    };
}

export const LineUpPlayerList = (PlayerList, PaginationData, respData) => {
    return {
        type: Type.JoinContestLineUpPlayerList,
        payload: { PlayerList: PlayerList, PaginationData: PaginationData, respData: respData }
    };
}

export const LineUpPlayerListByPosition = (PlayerListByPosition, PaginationData, respData, lineupsPerWeek=[], sortBy = 'name') => {
    return {
        type: Type.JoinContestLineUpPlayerListByPosition,
        payload: { PlayerListByPosition, PaginationData: PaginationData, respData: respData, lineupsPerWeek, sortBy }
    };
}

export const AddLineUps = (addLineUpData) => {
    return {
        type: Type.JointContestAddLineUps,
        payload: { addLineUpData }
    };
}

export const AddTieBreakerScore = (joinContestData) => {
    return {
        type: Type.JointContestAddTieBreakerScore,
        payload: { joinContestData }
    };
}

export const JoinUserContest = (userJoinContestData) => {
    return {
        type: Type.UserContestJoin,
        payload: { userJoinContestData }
    };
}

export const GetJoinContestById = (contestDetails) => {
    return {
        type: Type.GetJoinContestData,
        payload: { contestDetails: contestDetails }
    };
}

export const DeleteLineUp = (deleteData) => {
    return {
        type: Type.JointContestDeleteLineUp,
        payload: { deleteData }
    };
}

export const storeContestId = (contestId) => {
    return {
        type: Type.showContestId,
        payload: { contestId }
    };
}

export const LineUpPositionPlayerCardView = (viewdata) => {
    return {
        type: Type.PositionPlayerCardViewLineUp,
        payload: { viewdata }
    };
}

export const isShowPositionList = (value) => {
    return {
        type: Type.showPositionList,
        payload: { value }
    };
}
export const siteActionSetSearchText = (value) => {
    
    return {
        type: Type.setSearchText,
        payload: { value }
    };
}
export const showPlusDeleteIcon = (value) => {
    return {
        type: Type.PlusDeleteIcon,
        payload: { value }
    };
}

export const storeLineUpDefault = (value) => {
    return {
        type: Type.showLineUpCard,
        payload: { value }
    };
}

export const lineUpPlayerListById = (value) => {
    return {
        type: Type.playerById,
        payload: { value }
    };
}

export const getAllPlayersList = (viewdata, scoresData) => {
    return {
        type: Type.getAllPlayersList,
        payload: { viewdata, scoresData }
    };
}

export const lineUpTeamPlayerList = (TeamPlayer) => {
    return {
        type: Type.JoinContestLineUpTeamPlayerList,
        payload: { TeamPlayer }
    };
}


export const playerPositionUpdate = (position) => {
    return {
        type: Type.playerPosition,
        payload: { position }
    };
}

export const setLeftSideMenu = (pageName) => {
    return {
        type: Type.LeftSideActiveMenu,
        payload: { pageName }
    };
}


export const removeLineupPlayers = (data) => {
    return {
        type: Type.RemoveLineupPlayers,
        payload: { data }
    };
}

export const removeAllLineUpPlayers = () => {
    return {
        type: Type.RemoveAllLineUpPlayers
    };
}

export const updateSelectedPlayerCard = (cardIndex) => {
    return {
        type: Type.UpdateSelectedPlayerCard,
        payload: { cardIndex }
    };
}

export const getSeasonStandingsResponse = (seasonStandings) => {
    return {
        type: Type.SeasonStandingsResponse,
        payload: { data: seasonStandings.data }
    };
}

export const seasonLeaderboardService = (seasonLeaderboard) => {
    return {
        type: Type.SeasonLeaderboardService,
       
            payload: { data: seasonLeaderboard .data }
    };
}

export const getSeasonStatsResponse = (response) => {
    return {
        type: Type.GetSeasonStats,
        payload: { response: response }
    };
}
export const getWinnersResponse = (winnersData) => {
    return {
        type: Type.WinnersResponse,
        payload: { data: winnersData.data }
    };
}

export const updateSelectedPlayerCardForUsergame = (cardIndex) => {
    return {
        type: Type.UpdateSelectedPlayerCard,
        payload: { cardIndex }
    };
}

export const removeAllLineUpPlayersForUsergame = () => {
    return {
        type: Type.RemoveAllLineUpPlayersForUsergame
    };
}

export const changePlayerFilterOptionsForUserGame = (SearchOptions, PlayerList, PaginationData, respData) => {
    return {
        type: Type.PlayerFilterOptionsChangeForUsergame,
        payload: { SearchOptions, PlayerList: PlayerList, PaginationData: PaginationData, respData: respData }
    };
}

export const addLineUpForUsergame = (addLineUpData) => {
    return {
        type: Type.JointContestAddLineUps,
        payload: { addLineUpData }
    };
}

export const removeLineupForUsergame = (deleteData) => {
    return {
        type: Type.JointContestDeleteLineUp,
        payload: { deleteData }
    };
}

export const getAllPlayersListForUsergame = (viewdata, scoresData) => {
    return {
        type: Type.GetAllPlayersListForUsergame,
        payload: { viewdata , scoresData }
    };
}

export const saveUsergameLineUpPositionPlayerCardView = (viewdata) => {
    return {
        type: Type.PositionPlayerCardViewLineUp,
        payload: { viewdata }
    };
}

export const removeUsergameLineupPlayers = (viewdata) => {
    return {
        type: Type.PositionPlayerCardViewLineUp,
        payload: { viewdata }
    };
}

export const changeTeamFilterOptions = (SearchOptions, PlayerList) => {
    return {
        type: Type.JoinContestLineUpTeamPlayerFilterList,
        payload: { SearchOptions, PlayerList: PlayerList }
    };
}
// export const getRecievedMessages = (viewdata) => {
//     return {
//         type: Type.MessageBoardRecievedMessages,
//         payload: { viewdata }
//     };
// }
export const getUnreadMessages = (viewdata) => {
    return {
        type: Type.MessageBoardUnreadMessages,
        payload: { viewdata }
    };
}
export const rulesAndScoring = (viewdata) => {
    return {
        type: Type.RulesAndScoringData,
        payload: { viewdata }
    };
}

