import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import Header from "../components/Header";
import Copyright from '../components/Copyright';
import userdp from '../assets/user-dp.svg';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";

const EmailVerified = (props) => {
    const navigate = useNavigate();

	const user = useUserState();
	const userData = store.getState().user 

	let firstName = "";
	let lastName = "";
	if(userData.first_name) {
        firstName = userData.first_name;
        lastName = userData.last_name;
	}

	const [firstnameValue, setFirstnameValue] = useState(firstName);

	const signIn = async () => {
		let social_media_login = false;
		if (typeof userData.user !== 'undefined' && (userData.user.login_mode_id === 2 || userData.user.login_mode_id ===3)) {
			social_media_login = true;
		}
		if(!social_media_login){
			let password = localStorage.getItem('registerPassword');
			await user.action.login(userData.email, password);
			let userDetails = store.getState().user
			if (userDetails.success) {
				navigate(pageURLs.lobby);
			} else {
				navigate(pageURLs.root);
			}
		}else{
			if (userData.success) {
				navigate(pageURLs.lobby);
			} else {
				navigate(pageURLs.root);
			}

			
		}
    };


	return (
		<>
			<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container request-wrap success-wrap">
                    <div className="dp-outer">
                        <div className="dp-block"><img src={userdp} alt="KO"/></div>
                        <div className="alert success"></div>
                    </div>
					<div className="title-sec">
                        <h4>Congratulations</h4>
                        <p>Your email id has been verified successfully. </p>
                    </div>
					<div className="account-create text-center">
						<Button variant="primary" onClick={signIn}>View Lobby</Button>
					</div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default EmailVerified;
