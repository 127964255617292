import axios from 'axios'
import {store} from '../appState/store';

export async function apiCall(url, method, data, userId = null) {
    const userData = store.getState().user;
    let userSession = (userData) ? userData : null;
    let accessToken = (userSession.user && userSession.user.accessToken) ? userSession.user.accessToken  : null;
    const headers = (userId) ? 
      {
        'Content-Type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'authorization' : `Bearer ${accessToken}`,
        'X-UID-PLAYLIST' : userId,
        //"Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/',
        "Access-Control-Allow-Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/'
      } : 
      {
        'Content-Type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'authorization' : `Bearer ${accessToken}`,
       // "Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/',
        "Access-Control-Allow-Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/'
      };
      const response =  await axios({
        url,
        method,
        params: (method === 'GET' && data) ? data : {},
        data,
        headers,
      })
      return response.data;
}

export async function postTsevoData(url, postData) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData)
  });
  let data = null;
  if (response.ok) {
    data = await response.json();
  }
  return { success: response.ok, status: response.status, data: data };
}

export async function fetchIp(url) {
  const response = await axios({
    url
  }).catch(async function (error) {
    return false;
  })
  return response;
}

export async function fecthTesvoData(url) {
  const data = await axios.get(url)
    .then(async function (apiData) {
      return apiData.data;
    })
    .catch(async function (error) {
      return false;
    })

  return data;
}

export async function imageUploadApi(url, method, data) {
  const userData = store.getState().user;
  let userSession = (userData) ? userData : null;
  let accessToken = (userSession.user && userSession.user.accessToken) ? userSession.user.accessToken : null;
  const headers = {
      "Content-Type": "multipart/form-data",
      'api_key': process.env.REACT_APP_API_KEY,
      'authorization' : `Bearer ${accessToken}`,
     // "Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/',
      "Access-Control-Allow-Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/'
    }
    const response =  await axios({
      url,
      method,
      data,
      headers,
    })
    return response.data;
}



export async function fetchLiveData(url , postData) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData)
  });
    let data = null;
    if (response.ok) {
      data = await response.json();
    }
    return data;
}