import * as downloadService from "./downloadService";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function getStateList(userName, password) {
    return await apiService.apiCall(endPoints.STATE_LIST_API_URL, 'GET');
}

export async function getStates() {
    return await downloadService.downloadData('/data/dummyData/states.json');
}
