import React, { useState, useEffect } from "react";
import playerpic1 from '../assets/player-benjamin.png';
import playerpic3 from '../assets/player-henry.png';
import playerpic7 from '../assets/player-norman.png';
import lineupimg6 from '../assets/player-william.png';
import lineupimg8 from '../assets/player-darrel.png';
import lineupimg9 from '../assets/player-daniel.png';
import lineupimg10 from '../assets/teamx.png';
import { NavItem } from "react-bootstrap";
import userpic from '../assets/user-default.png';
import PlayerInjured from "../assets/icons/playerinjured";

const PlayerStandings = props => {
  let className = '';
  if(props.playerposition == 'QB') {
    className = 'quarterback';
  } else if(props.playerposition == 'RB'){
    className = 'runningback';
  } else if(props.playerposition == 'WR'){
    className = 'widereceiver';
  } else if(props.playerposition == 'TE'){
    className = 'tightend';
  } else if(props.playerposition == 'FL'){
    className = 'flexplayer';
  } else if(props.playerposition == 'K'){
    className = 'kicker';
  } else if(props.playerposition == 'DF'){
    className = 'defence';
  }
  let player_score = (props.playerposition== 'DF') ? props.team_score : props.score;
  let total_dst_turnover = parseInt(props.interceptions)+parseInt(props.fumble_recovered)+parseInt(props.fumbles_lost);
  let totalttd = parseInt(props.passing_interceptions_touchdown)+parseInt(props.turnover_touchdown);
  let totaloffttd = parseInt(props.passing_interceptions)+parseInt(props.fumbles_lost);

  const gameScore =  (opponent_team_key, team_key, away_team, home_team, away_score, home_score) => { 
    let gameScore = '';
    if(opponent_team_key == away_team) {
      gameScore = away_score+' - '+home_score;
    } else if(opponent_team_key == home_team) {
      gameScore = home_score+' - '+away_score;
    }
    return gameScore;
  }
  return (
    <div className="player-standings">

      <div className={"standing-card " + className}>
        <div className="player-info">
          <div className="position">{props.playerposition == 'DF' ? 'DST' : props.playerposition}</div>
          <figure>{props.player_pic && props.player_pic != "" ? <img src={props.player_pic} alt="KO" /> : <img src={userpic} alt="KO" />}</figure>
          <div className="name">{props.playername}</div>
          {props.is_player_injured && <div className="injury-update"><PlayerInjured injuryStatus = {props.is_player_injured}/></div>}
        </div>
        <div className="standing-stats">
          <div className="stat-value game-value">
            <div className="game-group">
              <label>Game</label><p>
              {props.opponent_team_key}
              {props.team_key && props.opponent_team_key &&props.opponent_team_key != "" ? ' @ ' : ''}
              {props.team_key} 
              </p>
            </div>
            <div className="live-group">
              {props.is_canceled == 1 && <><label>Cancelled</label></> }
              {(props.has_started == 1 && props.is_inprogress == 1) && <><label>LIVE</label><p>{gameScore(props.opponent_team_key,props.team_key, props.away_team, props.home_team, props.away_score, props.home_score) }</p></> }
              {(props.has_started == 1 && props.is_over == 1) && <><label>Final</label><p>{gameScore(props.opponent_team_key,props.team_key, props.away_team, props.home_team, props.away_score, props.home_score) }</p></> }
            </div>
          </div>

          <div className="stat-value">
            <label>Score</label><p>{player_score}</p>
          </div> 
          {props.playerposition == 'QB' &&  <div className="stat-value">
            <label>PaYDS</label><p>{props.PaYDS > 0 ? props.PaYDS.replace(/\.00$/,'') : "0"}</p>
          </div>}
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' || props.playerposition == 'QB' ) && <div className="stat-value">
            <label>PaTD</label><p>{props.PaTD > 0 ? props.PaTD.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' || props.playerposition == 'QB' ) && <div className="stat-value">
            <label>TO</label><p>{totaloffttd > 0 ? totaloffttd : "0"}</p>
          </div> }
          

          {props.playerposition == 'QB' && <div className="stat-value">
            <label>RuYDS</label><p>{props.RuYDS > 0 ? props.RuYDS.replace(/\.00$/,'') : "0"}</p>
          </div> }
         
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB'  ) && <div className="stat-value">
            <label>RuYDS</label><p>{props.RuYDS > 0 ? props.RuYDS.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' ) &&  <div className="stat-value">
            <label>RECYDS</label><p>{props.receiving_yards > 0 ? props.receiving_yards.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' ) && <div className="stat-value">
            <label>REC</label><p>{props.receptions > 0 ? props.receptions.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' || props.playerposition == 'QB') && <div className="stat-value">
            <label>RuTD</label><p>{props.RuTD > 0 ? props.RuTD.replace(/\.00$/,'') : "0"}</p>
          </div>}
          
          {(props.playerposition == 'WR' || props.playerposition == 'TE' || props.playerposition == 'RB' || props.playerposition == 'QB' ) && <div className="stat-value">
            <label>RECTD</label><p>{props.receiving_touchdown > 0 ? props.receiving_touchdown.replace(/\.00$/,'') : "0"}</p>
          </div>}

          {props.playerposition == 'K' && <div className="stat-value">
            <label>FGM</label><p>{props.field_goalsmade > 0 ? props.field_goalsmade.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'K' && <div className="stat-value">
            <label>0-39</label><p>{props.zero_to_thirtynine_fg > 0 ? props.zero_to_thirtynine_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'K' && <div className="stat-value">
            <label>40-49</label><p>{props.fourty_to_fourtynine_fg > 0 ? props.fourty_to_fourtynine_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'K' && <div className="stat-value">
            <label>50+</label><p>{props.fifty_plus_fg > 0 ? props.fifty_plus_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'K' && <div className="stat-value">
            <label>EPM</label><p>{props.extra_pointsmade > 0 ? props.extra_pointsmade.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'DF' && <div className="stat-value">
            <label>TTD</label><p>{totalttd > 0 ? totalttd : "0"}</p>
          </div> }
          {props.playerposition == 'DF' && <div className="stat-value">
            <label>TO</label><p>{total_dst_turnover > 0 ? total_dst_turnover : "0"}</p>
          </div> }
          
          {props.playerposition == 'DF' && <div className="stat-value">
            <label>SACKS</label><p>{props.sacks > 0 ? props.sacks.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {props.playerposition == 'DF' && <div className="stat-value">
            <label>SAFETY</label><p>{props.safety > 0 ? props.safety.replace(/\.00$/,'') : "0"}</p>
          </div> }
          
        </div>
      </div>

    </div>
  );
}

export default (PlayerStandings);
