import React from "react";


const UserGamesIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.556" height="15.5" viewBox="0 0 13.556 15.5">
            <g id="user" transform="translate(-3 -1.5)">
                <path id="Path_10372" data-name="Path 10372" d="M15.556,19.333V17.889A2.889,2.889,0,0,0,12.667,15H6.889A2.889,2.889,0,0,0,4,17.889v1.444" transform="translate(0 -3.333)" fill="none" stroke="#00001c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <circle id="Ellipse_54" data-name="Ellipse 54" cx="3" cy="3" r="3" transform="translate(6.778 2.5)" fill="none" stroke="#00001c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>



    );

}

export default UserGamesIcon;