import React, { useState, useEffect, useRef } from "react";
import { Form, Button ,Modal} from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import Copyright from '../components/Copyright';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {store} from '../appState/store';
import * as userController  from "../controller/userController"
import TESVOIdentity from "../components/TESVOIdentity";
import * as endPoints from '../constants/endPoints'
import useGeoLocation from "../utils/useGeoLocation";
import { checkIpFormat } from '../utils/CommonHelper';


const VerifyAddress = (props) => {
    const location = useGeoLocation();

    const userDetails = store.getState().user 
    const [identitySession, setIdentitySession] = useState("");
    const [stepState, setStepState] = useState("");
    const [cordinates, setCordinates] = useState([]);
    const [showLocationRequiredPopup, setLocationRequiredPopup] = useState(false);
    const [tsevoReconfirmationMessage, settsevoReconfirmationMessage] = useState('Allow the browser to access your location and verify your identity?');
    const [geoLocationAvailable, setGeoLocationAvailable] = useState(false);
    const [geoLocationEnabled, setGeoLocationEnabled] = useState(false);
    const [tsevoSessionURL, setTsevoSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);
    const navigate = useNavigate();
    const identityCheck = "IdentityCheck";
    let email = '';

    if (typeof userDetails.user !== 'undefined' && (userDetails.user.login_mode_id == 2 || userDetails.user.login_mode_id ==3)) {
        email = userDetails.user.email;
    } else if(userDetails.email) {
        email = userDetails.email;
    }
    

  
    if(!email || typeof email === 'undefined') {
        navigate(pageURLs.signup);
    }
    

    const tsevoVerification = async (cordinatesData) => {
        if(typeof cordinatesData !== 'undefined' && cordinatesData.latitude ) {
            await userController.getUserIp()
            .then(async function(ipdata) {
                let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
            // let cords = JSON.parse(localStorage.getItem('coords'));
               
                let response = await userController.createIdentitySession(customerIp,email, cordinatesData);
                if (response.data && response.data.sessionID != null) {
                    setIdentitySession(response.data.sessionID);
                    setStepState(identityCheck);
                    let isIpv4 = checkIpFormat(customerIp);
                    setisIpisV4(isIpv4)
                    setTsevoSessionURL(response?.data?.sessionUrl)
                } else {
                    navigate(pageURLs.signupstep5);
                }
            }).catch(err => {
                navigate(pageURLs.signupstep5);
            });
        }
    }

    
    const enableLocationPopup = () => {
        setLocationRequiredPopup(false)
        //let locationData =  navigator.geolocation.getCurrentPosition(locationSuccess, ForceLocationerror);
    }

    useEffect(() => {
        if(location.loaded && !location.errorFlag && !identitySession) {
            tsevoVerification(location.coordinates)
        } else  if(location.loaded && location.errorFlag && !identitySession) {
            Locationerror()
        }
    }, [location]);
	
    const cancelLocationRequiredPopup = () => {
        navigate(pageURLs.signupstep5);
    }
    
    const Locationerror = async (err) => {
        setLocationRequiredPopup(true);
    };

    return (
        <>
            <div className="signin-bg"></div>
            <div className="signin-main-wrap">
                <Header/>
                    <div className="signin-container verification-wrap">
                        <h4>Let's Get Started!</h4>
                        { (stepState !== identityCheck) &&
                            <div className="loader-wrap"><div className="loader"></div></div>
                        }
                        <div className="tsevo-container">
                                { (stepState === identityCheck) &&
                                <>
                                <TESVOIdentity sessionID={identitySession} tsevoSessionURL = {tsevoSessionURL} isIpv4= {isIpisV4}/>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <Modal show={showLocationRequiredPopup} onHide={cancelLocationRequiredPopup} className="modal-predict tsevo-flow">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">{tsevoReconfirmationMessage}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={enableLocationPopup}>Allow</Button>
                        <Button variant="primary" onClick={cancelLocationRequiredPopup}>Don't Allow</Button>
                    </Modal.Footer>
                </Modal>
            
            <div className="signin-copyright"><Copyright/></div>
        </>
    );

};

export default VerifyAddress;