import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import KOInput from "../components/KOInput";
import Copyright from '../components/Copyright';
import KOCheckbox from "../components/KOCheckbox";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {emailValidation} from "../services/commonService";
import {store} from '../appState/store';
import * as userController  from "../controller/userController"
import {  TermsAndConditions  } from '../utils/CommonHelper';

const SignupStep3 = (props) => {

    const userDetails = store.getState().user 

    let firstName = "";
    let lastName = "";
    let email = "";

    if (typeof userDetails.user !== 'undefined' && (userDetails.user.login_mode_id == 2 || userDetails.user.login_mode_id ==3)) {
        firstName = userDetails.user.firstname;
        lastName = userDetails.user.lastname;
        email = userDetails.user.email;
    }else if(userDetails.first_name) {
        firstName = userDetails.first_name;
        lastName = userDetails.last_name;
        email = userDetails.email;
    }

    const refFirstname = useRef(null);
    const refLastname = useRef(null);
    const refEmail = useRef(null);
    const refConfirmEmail = useRef(null);
    const [firstnameValue, setFirstnameValue] = useState(firstName);
    const [firstnameError, setFirstnameError] = useState("");
    const [lastnameValue, setLastnameValue] = useState(lastName);
    const [lastnameError, setLastnameError] = useState("");
    const [emailValue, setEmailValue] = useState(email);
    const [emailError, setEmailError] = useState("");
    const [confirmEmailValue, setConfirmEmailValue] = useState("");
    const [confirmEmailError, setConfirmEmailError] = useState("");
    const [termsAndConditionsCheckbox, setTermsAndConditionsCheckbox] = useState(false);
    const [termsAndConditionsCheckboxError, setTermsAndConditionsCheckboxError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        refFirstname.current.focus();
    }, []);
    
    const validate = () => {
        let isValid = true;
        let focusSet = false;

        setFirstnameError("");
        if (firstnameValue === "") {
            isValid = false;
            setFirstnameError("Enter First Name.");
            if (!focusSet) {
                refFirstname.current.focus();
                focusSet = true;
            }
        }

        setLastnameError("");
        if (lastnameValue === "") {
            isValid = false;
            setLastnameError("Enter Last Name.");
            if (!focusSet) {
                refLastname.current.focus();
                focusSet = true;
            }
        }

        setEmailError("");
        if (emailValue === "") {
            isValid = false;
            setEmailError("Enter Email.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }
        else if (emailValidation(emailValue) === false) {
            isValid = false;
            setEmailError("Enter a valid Email.");
            if (!focusSet) {
                refEmail.current.focus();
                focusSet = true;
            }
        }

        setConfirmEmailError("");
        if (emailValue !== "" && confirmEmailValue === "") {
            isValid = false;
            setConfirmEmailError("Enter again to Confirm Email.");
            if (!focusSet) {
                refConfirmEmail.current.focus();
                focusSet = true;
            }
        }
        if (confirmEmailValue !== "" && emailValue !== confirmEmailValue) {
            isValid = false;
            setConfirmEmailError("New Email and Confirm Email entries must be the same.");
            if (!focusSet) {
                refConfirmEmail.current.focus();
                focusSet = true;
            }
        }

        setTermsAndConditionsCheckboxError("")
        if (termsAndConditionsCheckbox === false) {
            isValid = false;
            setTermsAndConditionsCheckboxError("Agree to the Terms and Conditions to continue.");
        }

        return isValid;
    };

    const handleFirstnameChange = (e) => {
        setFirstnameError("");
        setFirstnameValue(e.target.value);
    };

    const handleLastnameChange = (e) => {
        setLastnameError("");
        setLastnameValue(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmailError("");
        setEmailValue(e.target.value);
    };

    const handleConfirmEmailChange = (e) => {
        setConfirmEmailError("");
        setConfirmEmailValue(e.target.value);
    };

    const handleTermsAndConditionsCheckbox = (e) => {
        setTermsAndConditionsCheckboxError("");
        setTermsAndConditionsCheckbox(e.target.checked);
    };

    const signUpStep3 = async () => {
        let isValid = validate();
        if (isValid) {
            let user_email = userDetails.email;
            if (typeof userDetails.user !== 'undefined' && (userDetails.user.login_mode_id == 2 || userDetails.user.login_mode_id ==3)) {
                user_email = userDetails.user.email;
            }
            let response = await userController.confirmUserIdentity(firstnameValue,lastnameValue,user_email, 1);
            navigate(pageURLs.signupstep4);
        }
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            signUpStep3();
        }
    };

    return (
        <>
            <div className="signin-bg"></div>
            <div className="signin-main-wrap">
                <Header/>
                <div className="signin-container verification-wrap">
                    <h4>Let's Get Started!</h4>
                    <Form className="">
                        <KOInput
                            id="firstname"
                            label="First Name"
                            name="firstname"
                            type="text"
                            onChange={(e) => { handleFirstnameChange(e); }}
                            value={firstnameValue}
                            placeholder="Enter your first name"
                            maxLength="60"
                            txtRef={refFirstname}
                            errorMessage={firstnameError}
                        />
                        <KOInput
                            id="lastname"
                            label="Last Name"
                            name="lastname"
                            type="text"
                            onChange={(e) => { handleLastnameChange(e); }}
                            value={lastnameValue}
                            placeholder="Enter your last name"
                            maxLength="60"
                            txtRef={refLastname}
                            errorMessage={lastnameError}
                        />
                        <KOInput
                            id="email"
                            label="Email"
                            name="email"
                            type="email"
                            onChange={(e) => { handleEmailChange(e); }}
                            value={emailValue}
                            placeholder="Enter your email address"
                            maxLength="80"
                            txtRef={refEmail}
                            errorMessage={emailError}
                            disabled ="true"
                        />
                        <KOInput
                            id="email"
                            label="Confirm Email"
                            name="email"
                            type="email"
                            onChange={(e) => { handleConfirmEmailChange(e); }}
                            value={confirmEmailValue}
                            placeholder="Re-enter email address"
                            maxLength="80"
                            txtRef={refConfirmEmail}
                            errorMessage={confirmEmailError}
                        />
                        <div className="confirm-check-list">
                            <div className="checkgroup">
                                <KOCheckbox
                                    name={"terms"}
                                    label={"I agree to the"}
                                    id={"termscheck"}
                                    onChange={(e)=>{handleTermsAndConditionsCheckbox(e)}}
                                    onKeyDown={(e) => enterKeySubmit(e)}
                                />
                                 {TermsAndConditions()}
                                { termsAndConditionsCheckboxError && termsAndConditionsCheckboxError !== '' &&
                                    <div className="form-group">
                                        <span className='error-message'>{termsAndConditionsCheckboxError}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </Form>
                    <div className="account-create">
                        <Button variant="primary" onClick={signUpStep3}>View Account Information</Button>
                        <Link to={pageURLs.signupstep5}>Skip Verification</Link>

                    </div>
                </div>
            </div>
            <div className="signin-copyright"><Copyright/></div>
        </>
    );

};

export default SignupStep3;
