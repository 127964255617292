import * as downloadService from "./downloadService";
import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function login(userName, password) {
    return await apiService.apiCall(endPoints.LOGIN_API_URL, 'POST', {
        email: userName,
        password: password,
        deviceType: 1
    });
}

export async function logout() {
    return await apiService.apiCall(endPoints.USER_LOGOUT_API_URL, 'POST', {
        deviceType: 1
    });
}

export async function forgotPassword(email) {
    return downloadService.successResponse();
}

export async function emailValidation(OTP, email) {
    return await apiService.apiCall(endPoints.EMAIL_VERIFICATION_API_URL, 'POST', {
        email: email,
        otp: OTP
    });
}
export async function resendOTP(email) {
    return await apiService.apiCall(endPoints.RESEND_OPT_API_URL, 'POST', {
        email: email
    });
}

export async function generateForgetPasswordOtp(email) {
    return await apiService.apiCall(endPoints.FORGET_PASSWORD_OTP_API_URL, 'POST', {
        email: email
    });
}
export async function resetPassword(payload) {
    const { email, otp, password } = payload;
    return await apiService.apiCall(endPoints.RESET_PASSWORD_API_URL, 'POST', {
        email: email,
        otp: otp,
        password: password
    });
}

export async function signUp(userDetails) {
    const { firstName, lastName, email, password, userName , couponCode,isMailOpted} = userDetails;
    return await apiService.apiCall(endPoints.SIGNUP_API_URL, 'POST', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        user_name: userName,
        login_mode_id: 1,
        couponCode : couponCode,
        is_mail_opted : isMailOpted
    });
}

export async function userAddress(addressDetails) {
    const { email, address, city, state, zipcode, country } = addressDetails;
    return await apiService.apiCall(endPoints.ADD_ADDRESS_API_URL, 'POST', {
        email: email,
        address: address,
        city: city,
        state: state,
        zip_code: zipcode,
        country: country
    });
}

export async function emailRequest(email) {
    return downloadService.successResponse();
}

export async function verifyOTP(otp) {
    return downloadService.successResponse();
}


export async function confirmUserIdentity(firstname, lastname, email, verification_status) {
    return await apiService.apiCall(endPoints.USER_IDENTITY_CONFIRM_API_URL, 'POST', {
        firstname: firstname,
        lastname: lastname,
        email: email,
        verification_status: verification_status
    });
}



export async function fbLogin(email, accessToken, deviceType, fbId, is_mail_opted = 0) {
   // let is_mail_opted = (localStorage.getItem('receiveUpdate')) ? localStorage.getItem('receiveUpdate') : 0;
    return await apiService.apiCall(endPoints.FB_LOGIN_API_URL, 'POST',{
        email: email,
        accessToken: accessToken,
        deviceType : deviceType,
        fbID: fbId,
        is_mail_opted: is_mail_opted
    });
}

export async function googleLogin(email, accessToken, deviceType, idToken, googleId, is_mail_opted = 0) {
   // let is_mail_opted = (localStorage.getItem('receiveUpdate')) ? localStorage.getItem('receiveUpdate') : 0;
    return await apiService.apiCall(endPoints.GOOGLE_LOGIN_API_URL, 'POST', {
        email: email,
        accessToken: accessToken,
        deviceType: deviceType,
        idToken: idToken,
        googleID: googleId,
        is_mail_opted: is_mail_opted
    });
}

export async function checkIdentityFromTsevo(requestData) {
    let requestUrl = "https://api.gidx-service.in/v3.0/api/WebReg/CreateSession";
    return await apiService.postTsevoData(requestUrl, requestData);
}

export async function getUserDetailsByEmail(email, update_tesvo_customer_id) {
    return await apiService.apiCall(endPoints.USER_DETAIS_BY_EMAIL, 'POST', {
        email: email,
        update_tesvo_customer_id: update_tesvo_customer_id
    });
}

export async function getUserIp() {
    return await apiService.fetchIp('https://geolocation-db.com/json/');
}

export async function checkUserProfileStausTsevo(email) {
    return await apiService.apiCall(endPoints.FETCH_USER_IDENTIVERIFICATION_STATUS, 'POST', {
        email: email
    });
}


export async function saveTsevoSessionData(tsevoCustomerId, tsevo_session_id) {
    return await apiService.apiCall(endPoints.TSEVO_SESSION_SAVE_URL, 'POST',{       
        tsevo_session_id : tsevo_session_id,
        tsevo_customer_id : tsevoCustomerId
    });
}

export async function updateAddress(addressDetails) {        

    const { address, city, state, zipcode, country, customerIp } = addressDetails;
    return await apiService.apiCall(endPoints.UPDATE_ADDRESS_API_URL, 'PUT', {
        address: address,
        city: city,
        state: state,
        zip_code: zipcode,
        country: country,
        customerIp: customerIp
    });
}

export async function updatePassword(details) {
    const { oldpassword, password, confirmpassword } = details;    
    return await apiService.apiCall(endPoints.UPDATE_PASSWORD_API_URL, 'POST', {        
        oldpasword: oldpassword,
        password: password,
        confirmpassword : confirmpassword
    });
}

export async function verifyUserEmail(details) {
    const { email } = details;
    return await apiService.apiCall(endPoints.VERIFY_USER_EMAIL_API_URL, 'POST', {        
        email: email,
    });
}

export async function updateUsername(userName) {    
    const { username } = userName;    
    return await apiService.apiCall(endPoints.UPDATE_USERNAME_API_URL, 'POST', {       
        user_name: username
    });
}

export async function verifyEmailChangeOTP(details) {
    const { otp } = details;
    return await apiService.apiCall(endPoints.VERIFY_USER_EMAIL_OTP_API_URL, 'POST', {        
        otp: otp,
    });
}

export async function updateUserEmail(details) {
    const { email, otp } = details;
    return await apiService.apiCall(endPoints.UPDATE_USER_EMAIL_API_URL, 'POST', {        
        email: email,
        otp : otp
    });
}

export async function getUserData(details) {
    return await apiService.apiCall(endPoints.GET_USER_DETAILS_BY_USERID_API_URL, 'POST', {
        userId: details,
    });
}

export async function updateUserProfilePic(bodyFormData, userId) {
    return await apiService.imageUploadApi(endPoints.UPDATE_USER_PROFILE_PIC_API_URL, 'POST', bodyFormData
    );
}

export async function updateUserPreferences(details) {
    return await apiService.apiCall(endPoints.UPDATE_USER_PREFERENCES_API_URL, 'POST', details);
}

export async function getUserPreferences() {
    return await apiService.apiCall(endPoints.GET_USER_PREFERENCES_API_URL, 'POST', {        
    });
}

export async function fetchuserProfilePresignedUrl(details) {
    return await apiService.apiCall(endPoints.FETCH_S3_USER_PROFILE_IMAGE_PRESIGNED_URL, 'POST', details);
}

export async function updateUserProfile(details) {
    return await apiService.apiCall(endPoints.UPDATE_USER_PROFILE_PIC_API_URL, 'POST', details);
}

export async function checkLocationFromTsevo(customerIp, coords) {
    let accuracy = coords.accuracy ? coords.accuracy : null;
    let altitude = coords.altitude ? coords.altitude : null;
    let latitude = coords.latitude ? coords.latitude : null;
    let longitude = coords.longitude ? coords.longitude : null;
    let speed    = coords.speed ? coords.speed : null;
    return await apiService.apiCall(endPoints.FETCH_USER_LOCATION_STATUS, 'POST', {
        customerIp: customerIp,
        time : new Date(),
        accuracy : accuracy,
        altitude : altitude,
        latitude : latitude,
        longitude : longitude,
        speed : speed
    });
}

export async function createIdentitySession(customerIp, email, coords) {
    let accuracy = coords.accuracy ? coords.accuracy : null;
    let altitude = coords.altitude ? coords.altitude : null;
    let latitude = coords.latitude ? coords.latitude : null;
    let longitude = coords.longitude ? coords.longitude : null;
    let speed    = coords.speed ? coords.speed : null;
    return await apiService.apiCall(endPoints.FETCH_USER_IDENTIVERIFICATION_SESSION, 'POST', {
        customerIp: customerIp,
        email: email,
        time : new Date(),
        accuracy : accuracy,
        altitude : altitude,
        latitude : latitude,
        longitude : longitude,
        speed : speed
    });
}
export async function inviteFriends(emailqueue) {
    return await apiService.apiCall(endPoints.INVITE_FRIENDS_API_URL, 'POST', {
        emails: emailqueue.inviteEmailAdd
    });
}

export async function referralCouponCheck(payLoad) {
    return await apiService.apiCall(endPoints.REFERRAL_COUPON_CHECK_API, 'POST', payLoad);
}

export async function fundAddCouponCheck(payLoad) {
    return await apiService.apiCall(endPoints.FUNDADD_COUPON_CHECK_API, 'POST', payLoad);
}

export async function fetchWalletSplitup() {
    return await apiService.apiCall(endPoints.WALLET_SPLITUP_FETCH_API, 'GET', {});
}

export async function userSelfBlocking() {
    return await apiService.apiCall(endPoints.SELF_EXCLUSION_API_URL, 'GET', {});
}

export async function getUserIpFromApi64() {
    return await apiService.fetchIp('https://api64.ipify.org/?format=json');
}

export async function getUserIpFromApi() {
    return await apiService.fetchIp('https://api.ipify.org?format=json');
}
export async function setContestLimits(payLoad) {
    const { weekly_joinlimit, monthly_joinlimit, season_joinlimit, weekly_spentlimit, monthly_spentlimit, season_spentlimit} = payLoad;
    return await apiService.apiCall(endPoints.SET_CONTEST_LIMIT_API_URL, 'POST', {        
        weekly_joinlimit: weekly_joinlimit,
        monthly_joinlimit: monthly_joinlimit,
        season_joinlimit: season_joinlimit,
        weekly_spentlimit: weekly_spentlimit,
        monthly_spentlimit: monthly_spentlimit,
        season_spentlimit: season_spentlimit,
    });
}

export async function getContestLimits() {
    return await apiService.apiCall(endPoints.GET_CONTEST_LIMIT_API_URL, 'GET', {});
}

export async function userCheck(userData) {
    const { user_name } = userData;
    return await apiService.apiCall(endPoints.USER_EXIST_CHECK_API_URL, 'POST', {
        user_name: user_name
    });
}

export async function updateUserNames(details) {
    return await apiService.apiCall(endPoints.SAVE_USER_DETAILS, 'PUT', details);
}