import React, { useState, useEffect } from "react";
import { Button , Modal} from "react-bootstrap";
import KOSelectBox from "./KOSelectBox";
const CopyClearLineup = (props) => {
   
    const [lineuplistoptions, setlineuplistoptions] = useState([]);  
    const [lineupForCopy, setLineupForCopy] = useState('');
    const [lineupSelectionError, setlineupSelectionError] = useState(null);
    const [showCopyLineUpConfirmModal, setCopyLineUpConfirmModal] = useState(false);
    const [disableActionBtns, setDisableActionBtns] = useState(false);

    useEffect(() => {
        setlineuplistoptions([])
        let copyLineUpData = [];
        setLineupForCopy('')
        for(let l=0; l<props.lineUps.length ; l++) {
            if(props.lineUps[l].lineup_number != props.lineUpNumber) {
                let lineupOpt = { value: props.lineUps[l].lineup_number, label: 'Lineup '+props.lineUps[l].lineup_number };
                copyLineUpData.push(lineupOpt)
            }
        }
        setlineuplistoptions(copyLineUpData)
    }, [props.lineUpNumber, props.lineUps])  

    const selectLineupForCopy = (e) => {
        setlineupSelectionError("");
        let { value } = e.target;
        setLineupForCopy(value);
    };
    const copyLineupConfirm = (e) => {
        if(!lineupForCopy) {
            setlineupSelectionError("Select lineup")
        } else{
            setCopyLineUpConfirmModal(true)
        }
    }
    const handleCopyLineupModalClose = () => setCopyLineUpConfirmModal(false);

    const copyLineUp = (e) => {
        setCopyLineUpConfirmModal(false);
        props.copyLineUp(lineupForCopy);
        setLineupForCopy('')
    }

    useEffect(() => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            setDisableActionBtns(true)
        } else{
            setDisableActionBtns(false)
        }

    }, [localStorage.getItem('changeInLineup')]) 
    return (
        <>
            {((!props.isWeekCompleted && lineuplistoptions.length > 0) || props.showClearLineUp) && <div className="copy-lineup-sec">
                {(!props.isWeekCompleted && lineuplistoptions.length > 0)&& <div className="copy-lineup-group">
                    <KOSelectBox
                        id="copylineup"
                        name="copylineup"
                        label="Copy Lineup"
                        type="text"
                        options={lineuplistoptions}
                        onChange={(e) => { selectLineupForCopy(e); }}
                        value ={lineupForCopy}
                        errorMessage={lineupSelectionError}
                    />
                    <Button className="btn-success" onClick={copyLineupConfirm} disabled={disableActionBtns}>Ok</Button>
                </div>}
                {props.showClearLineUp && [2,3].includes(props.contestStatus) && <Button className="btn-red btn-clear" disabled={disableActionBtns}  onClick={props.showClearLineUpConfirmation} >Clear  Lineup</Button> }
            </div>}

            <Modal show={showCopyLineUpConfirmModal} onHide={handleCopyLineupModalClose} className="modal-predict">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="modal-title">Are you sure you want to copy this lineup?</h4>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary"  onClick={copyLineUp}>Yes</Button>
                    <Button variant="link" onClick={handleCopyLineupModalClose}>Cancel</Button>
                    
                </Modal.Footer>
            </Modal> 
        </>
    );

};

export default CopyClearLineup;
