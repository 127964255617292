import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { statsPlayerRow, playerPosition } from '../utils/StatsHelper';
import PlayerStats from '../components/PlayerStats';


const UserSeasonStats = (props) => {

    //  const [selectedPlayerPositionTab, setSelectedPlayerPositionTab] = useState("");
    

    const [totalScore, setTotalScore] = useState(0);
    const [teams, setTeams] = useState({});
    const [playersStatus, sePlayersStatus] = useState({});
    const [teamMaster, setTeamMaster] = useState([]);
    const [weekAndPlayers, setWeekAndPlayers] = useState([]);
    const [weekNumbers, setWeekNumbers] = useState([]);
    let default_player_card = JSON.parse(sessionStorage.getItem('default_player_card'));
    const [playerDetailModal, setPlayerDetailModal] = useState(false);
    const [playerData, setPlayerData] = useState([]);
    
    let position = (default_player_card && default_player_card.length) ? default_player_card[0].positionshortname : 'QB';
    const [selectedPlayerPositionTab, setSelectedPlayerPositionTab] = useState(position);
    /*
        useEffect(() => {
            if (props.position && props.position != "")
                setSelectedPlayerPositionTab(props.position)
        }, [props.position])
    
        */


    
        useEffect(() => {
            if (position != "")
            props.changePlayerPositionClick(position)
        }, [position])
    

    useEffect(() => {
        if (selectedPlayerPositionTab != "") {
           // console.log(selectedPlayerPositionTab)
            props.changePlayerPositionClick(selectedPlayerPositionTab);
            sePlayersStatus({})
        }
    }, [selectedPlayerPositionTab])

    useEffect(() => {
        if (props.seasonStatsResponse) {
            if (props.seasonStatsResponse.teams)
                setTeams(props.seasonStatsResponse.teams)

            if (props.seasonStatsResponse.playersStatus)
                sePlayersStatus(props.seasonStatsResponse.playersStatus)

        }
    }, [props.seasonStatsResponse])

    useEffect(() => {

        if (teams && teams.length > 0) {
            for (let i = 0; i < teams.length; i++) {
                teamMaster[teams[i].team_key] = teams[i].team_key
            }
        }
        setTeamMaster(teamMaster);
    }, [teams])

    useEffect(() => {
        // splitting players by weeks
        let scores = 0;
        let wkNumbers = [];
        if (playersStatus && playersStatus.length > 0) {
            playersStatus.forEach((row) => {

                if (wkNumbers.indexOf(row.lineup_week))
                    wkNumbers.push(row.lineup_week)
                else
                    wkNumbers.push('row.lineup_week')

                weekAndPlayers[row.nfl_week] = row;
                scores += parseFloat(row.totalScore)
            });

            setTotalScore(scores)
            setWeekAndPlayers(weekAndPlayers)
            setWeekNumbers(wkNumbers);
        }

    }, [playersStatus])

    // from utils
    const playerPos = playerPosition(1);

    const handlePlayerModalClose = () => {
        setPlayerDetailModal(false);
    }
    
    const handlePlayerModalShow = (data) => {
        setPlayerDetailModal(true);
        setPlayerData(data)
    }

    const playerInfo = (playersStatus, position) => {

        const distinctWeeks = [...new Set(playersStatus.map(item => item.lineup_week))];
        let playerRow = [];
        for (let k = 0; k < distinctWeeks.length; k++) {

            playerRow.push(
                <div className="week-list" key={'playPos' + k}>
                    <h5 key={'h5playPos' + (k)}>Week {distinctWeeks[k]}</h5>
                    {
                        playersStatus.map((player) => {
                            return (
                                player.lineup_week == distinctWeeks[k] ? <div className="player-standings">
                                    {statsPlayerRow(player, position, teamMaster, handlePlayerModalShow)}
                                </div> : ""
                            )
                        })
                    }
                </div>
            );
        }
        return (playerRow)
    }
    // step 2
    const displayPositionTabs = (playerPos) => {
        const tabLists = playerPos.map((position, indx) => {
            return <Tab eventKey={position.code} title={position.title} key={'TabeventKey' + indx}>
                <div className="seasonstats-data" key={'pos' + indx}>
                    <div className="stats-title">
                        <h4>{position.h4}</h4>
                        <p>TOTAL SCORE: <span>{parseFloat(totalScore) ? parseFloat(totalScore).toFixed(2) : '0.00'}</span></p>
                    </div>
                    {playersStatus && playersStatus.length > 0 ? playerInfo(playersStatus, position) : ""}
                    {(playerDetailModal) && playerData && position.title === playerData.position &&   <PlayerStats showplayermodal = {playerDetailModal} handlePlayerModalClose = {handlePlayerModalClose} playerData ={playerData}/>}
                </div>
            </Tab>
        });

        return tabLists;
    };

    // step 1

    return (
        <Tabs
            defaultActiveKey="quarterback"
            transition={false}
            id="stats-tab"
            className="seasonstats-tab"
            activeKey={selectedPlayerPositionTab}
            onSelect={(k) => setSelectedPlayerPositionTab(k)}
        >
            {playerPos ? displayPositionTabs(playerPos) : ""}
        </Tabs>
    );
}

export default UserSeasonStats;
