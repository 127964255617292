import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Offcanvas, Modal } from "react-bootstrap";
import KOInput from "../components/KOInput";
import KOSelectBox from "../components/KOSelectBox";
import KORadiobutton from "../components/KORadiobutton";
import { useNavigate } from 'react-router-dom';
import * as userGamesController from "../controller/userGamesController";
import * as contestService from "../services/contestService";
import useUserState from "../appState/userState/useUserState";
import useSiteState from '../appState/siteState/useSiteState';
import { pageURLs } from "../appData/pageURLs";
const CreateLeagueStep1 = (props) => {
    let userSiteGameSubmittedData = props.UserSiteGameData;
    let totalJoinedCount = props.totalJoinedCount;
    const navigate = useNavigate();
    //console.log(userSiteGameSubmittedData)
    const editAction = props.editAction ? props.editAction : false;

    let isJoinedOtherUsers = (props.isJoinedOtherUsers) ? props.isJoinedOtherUsers : false;
    const user = useUserState();
    const siteState = useSiteState();
    let contest_name = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].contest_name) ? userSiteGameSubmittedData[0].contest_name : "";
    let contest_type = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].contest_type) ? userSiteGameSubmittedData[0].contest_type : "S";
    let contest_start_week = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].contest_start_week) ? userSiteGameSubmittedData[0].contest_start_week : "";
    let contest_end_week = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].contest_end_week) ? userSiteGameSubmittedData[0].contest_end_week : "";
    let min_users = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].min_users) ? userSiteGameSubmittedData[0].min_users : "";
    let max_users = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].max_users) ? userSiteGameSubmittedData[0].max_users : "";
    let lup_entrylevel_category = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].lup_entrylevel_category) ? userSiteGameSubmittedData[0].lup_entrylevel_category : 'S';
    let max_lineup_per_user = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].max_lineup_per_user) ? userSiteGameSubmittedData[0].max_lineup_per_user : 1;
    let total_weekly_winners = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].total_weekly_winners) ? userSiteGameSubmittedData[0].total_weekly_winners : "";
    let total_season_winners = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].total_season_winners) ? userSiteGameSubmittedData[0].total_season_winners : "";
    let disableLineUpSelection = (lup_entrylevel_category == 'S') ? true : false;
    let isEditMode = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[0] && userSiteGameSubmittedData[0].contest_type) ? true : false;
    const refLeagueName = useRef(null);
    const refEndWeek = useRef(null);
    const refStartWeek = useRef(null);
    const refWeekLeaders = useRef(null);
    const refSeasonLeaders = useRef(null);
    const refMaxweek = useRef(null);
    const refLeagueType = useRef(null);
    const [entryTypeValue, setEntryTypeValue] = useState(lup_entrylevel_category);
    const [startWeekValue, setStartWeekValue] = useState(contest_start_week);
    const [endWeekValue, setEndWeekValue] = useState(contest_end_week);
    const [leagueNameValue, setLeagueNameValue] = useState(contest_name);
    const [leagueNameError, setLeagueNameError] = useState("");
    const [endWeekError, setEndWeekError] = useState("");
    const [weekLeadersError, setWeekLeadersError] = useState("");
    const [weekLeaderValue, setWeekLeaderValue] = useState(total_weekly_winners);
    const [seasonLeadersError, setSeasonLeadersError] = useState("");
    const [seasonLeaderValue, setSeasonLeaderValue] = useState(total_season_winners);
    const [disableMultiEntryCheckBox, setDisableMultiEntryCheckBox] = useState(disableLineUpSelection);
    const [maxUsers, setMaxUserValue] = useState(max_users);
    const [maxWeekError, setMaxWeekError] = useState("");
    const [leaguetype, setLeaguetype] = useState(contest_type);
    const [leagueTypeError, setLeagueTypeError] = useState("");
    const [lineUpNumber, setLineUpNumber] = useState(max_lineup_per_user);
    const [startWeekError, setStartWeekError] = useState("");
    const [leagueCreationError, setLeagueCreationError] = useState("");
    const [showLoader, setLoader] = useState(false);
    const [leagutypeOptions, setLeagutypeOptions] = useState([
        { value: 'S', label: 'Standard Knockout' }
    ]);
    const contest_id = props.contest_id;
    const [geoLocationAvailable, setGeoLocationAvailable] = useState(false);
    const [geoLocationEnabled, setGeoLocationEnabled] = useState(false);

    let lineUpDataArray = [];
    for (var i = 1; i <= 5; i++) {
        lineUpDataArray.push(i)
    }
    const [lineUpData, setLineUpData] = useState(lineUpDataArray);
    const [minUsers, setMinUsers] = useState(min_users);

    // const leagutypeOptions = [
    //     { value: 'S', label: 'Standard Knockout' },
    //     { value: 'K', label: 'Knockout Plus' }
    // ];


    const [startWeekOptions, setStartWeekOptions] = useState([]);
    const [endWeekOptions, setEndWeekOptions] = useState([]);
    const [startWeekTime, setStartWeekTime] = useState([]);
    const [showVerificationProcessModal, setshowVerificationProcessModal] = useState(false);
    const [notverifiedMessage, setnotverifiedMessage] = useState("Complete verification for creating league");
    const [verificationBtnTitle, setverificationBtnTitle] = useState("Continue with Verification");
    let weekStartDateData = [];
    let weekStartDates = [];
    let weekEndDates = [];
    let locale = 'en';

    useEffect(async () => {
        refLeagueName.current.focus();
        let weekStartDatesVal = JSON.parse(sessionStorage.getItem('weekStartDates'));
        let weekEndDatesVal = JSON.parse(sessionStorage.getItem('weekEndDates'));
        let weekStartDateDataVal = JSON.parse(sessionStorage.getItem('weekStartDateData'));
            await contestService.getWeekDuration().then(function (reponse) {
                let weekData = reponse.data.weeks;
                let week_started = reponse.data.weekStarted;
                let next_week = Number(reponse.data.current_week)+1
                next_week = (next_week > 18) ? 0 : next_week;
                let activeWeek = (week_started == true) ?  next_week : reponse.data.current_week;
                if(reponse.data.current_week == 18 && week_started) {
                    activeWeek = 19;
                }
                for (let i = 0; i < weekData.length; i++) {
                    let start_date = new Date(weekData[i].StartWeekDate);
                    let year = start_date.getFullYear();
                    let date = `${start_date.toLocaleDateString(locale, { month: 'short' })} ${start_date.getDate()}, ${year}\n\n`;
                    if((activeWeek <= weekData[i].nfl_week) || (isEditMode && contest_start_week <= weekData[i].nfl_week)){
                        let startWeekOption = { 'value': weekData[i].nfl_week, label: `Week ` + weekData[i].nfl_week + ` - ` + date };
                        weekStartDates.push(startWeekOption);
                        weekStartDateData[weekData[i].nfl_week] = new Date(weekData[i].schedule_date);

                        let end_date = new Date(weekData[i].EndOfWeekDate);
                        let end_year = end_date.getFullYear();
                        let endFullDate = `${end_date.toLocaleDateString(locale, { month: 'short' })} ${end_date.getDate()}, ${year}\n\n`;
                        let endeekOption = { 'value': weekData[i].nfl_week, label: `Week ` + weekData[i].nfl_week + ` - ` + endFullDate };
                        weekEndDates.push(endeekOption)
                    }

                }
                setStartWeekOptions(weekStartDates);
                setEndWeekOptions(weekEndDates);
                setStartWeekTime(weekStartDateData);
                if(reponse.data.knockout_plus_enabled === 1) {
                    let existingLeagueOptions = [...leagutypeOptions];
                    existingLeagueOptions.push({ value: 'K', label: 'Knockout Plus' });
                    setLeagutypeOptions(existingLeagueOptions)
                }
                //sessionStorage.setItem('weekStartDates', JSON.stringify(weekStartDates));
               // sessionStorage.setItem('weekEndDates', JSON.stringify(weekEndDates));
               // sessionStorage.setItem('weekStartDateData', JSON.stringify(weekStartDateData));
                props.resetSteps();
            })
        
    }, []);

    const lineupcountOptions = () => {
        return lineUpData.map((item) => (
            { value: item, label: item }
        ));
    };
    const saveLeagueStep1 = async () => {
        if(user.data.user.user_status_id !=1) {
            setVerificationProcessModal()
            return false;
        }
        let isValid = validate();
        if (isValid) {
            setLoader(true);
            let contest_start_time = startWeekTime[startWeekValue];
            let requestPayload = {
                "step": 1,
                "lg_contest_id": parseInt(contest_id),
                "contest_name": leagueNameValue.trim(),
                "contest_type": leaguetype,
                "contest_start_time": contest_start_time,
                //"contest_end_time" : "2022-12-13",
                "contest_start_week": startWeekValue,
                "contest_end_week": endWeekValue,
                "min_users": minUsers,
                "max_users": maxUsers,
                "lup_entrylevel_category": entryTypeValue,
                "max_lineup_per_user": lineUpNumber,
                "total_weekly_winners": weekLeaderValue,
                "total_season_winners": seasonLeaderValue,
                "editAction": editAction
            };
            let result = await userGamesController.saveUserGames(requestPayload);
            if (result.errorCode == 200) {
                setLoader(false);
                props.updateLeagueContestId(result.data.lg_contest_id);
                props.updateUserLeaguename(leagueNameValue);
                props.updateFullUserGameData(requestPayload, 0);
                props.changeActiveStepValue();
                user.action.getUserData();
            } else {
                setLeagueCreationError(result.message);
                setLoader(false);
            }
        }
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        let defaultLineupNumber = 1;
        setEntryTypeValue(value)
        if (value == 'S') {
            setDisableMultiEntryCheckBox(true)
        } else {
            setDisableMultiEntryCheckBox(false)
        }
        setLineUpNumber(defaultLineupNumber)
        props.setLeagueCategory(value, defaultLineupNumber)
    }

    const handleNumberChange = (e) => {
        setMaxWeekError("");
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setMaxUserValue(value);
        props.setEntriesData(value);
    }

    const validate = () => {
        let isValid = true;
        let focusSet = false;
        let maxEntryValidation = false;

        setLeagueNameError("");
        setEndWeekError("");
        setLeagueTypeError("");
        setStartWeekError("");
        setWeekLeadersError("");
        setSeasonLeadersError("");
        setMaxWeekError("");

        if (leagueNameValue.trim() === "") {
            isValid = false;
            setLeagueNameError("Enter Name of the League.");
            if (!focusSet) {
                refLeagueName.current.focus();
                focusSet = true;
            }
        }

        if (leaguetype === "") {
            isValid = false;
            setLeagueTypeError("Select League Type.");
            if (!focusSet) {
                refLeagueType.current.focus();
                focusSet = true;
            }
        }

        if (startWeekValue === "") {
            isValid = false;
            setStartWeekError("Select Start Week.");
            if (!focusSet) {
                refStartWeek.current.focus();
                focusSet = true;
            }
        }

        if (endWeekValue === "") {
            isValid = false;
            setEndWeekError("Select End Week.");
            if (!focusSet) {
                refEndWeek.current.focus();
                focusSet = true;
            }
        }

        if (parseInt(endWeekValue) < parseInt(startWeekValue)) {
            isValid = false;
            setEndWeekError("End Week should be later than or the same as Start Week.");
            if (!focusSet) {
                refEndWeek.current.focus();
                focusSet = true;
            }
        }

        if ((weekLeaderValue === "" || !weekLeaderValue) && leaguetype == 'K') {
            isValid = false;
            setWeekLeadersError("Weekly Leaders should be greater than 0.");
            if (!focusSet) {
                refWeekLeaders.current.focus();
                focusSet = true;
            }

        }
        if ((weekLeaderValue) && isNaN(weekLeaderValue) && leaguetype == 'K') {
            isValid = false;
            setWeekLeadersError("Enter a valid number of Weekly Leaders.");
            if (!focusSet) {
                refWeekLeaders.current.focus();
                focusSet = true;
            }

        }

        if ((seasonLeaderValue === "" || !seasonLeaderValue) && leaguetype == 'K') {
            isValid = false;
            setSeasonLeadersError("Season Leaders should be greater than 0.");
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }

        }
        if (seasonLeaderValue && isNaN(seasonLeaderValue) && leaguetype == 'K') {
            isValid = false;
            setSeasonLeadersError("Enter a valid number of Season Leaders.");
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }

        }

        if (maxUsers === "") {
            isValid = false;
            setMaxWeekError("Enter maximum entry.");
            maxEntryValidation = true;
            if (!focusSet) {
                refMaxweek.current.focus();
                focusSet = true;
            }
        }
        if ((parseInt(maxUsers) > 100 || parseInt(maxUsers) < 0) && !maxEntryValidation) {
            isValid = false;
            setMaxWeekError("Maximum entry should be less than or equal to 100.");
            maxEntryValidation = true;
            if (!focusSet) {
                refMaxweek.current.focus();
                focusSet = true;
            }
        }

        if (maxUsers && isNaN(maxUsers) && !maxEntryValidation) {
            isValid = false;
            setMaxWeekError("Enter a valid Maximum entry number.");
            maxEntryValidation = true;
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }
        }
        if (maxUsers < minUsers && !maxEntryValidation) {
            isValid = false;
            setMaxWeekError("Maximum number of Entries should be greater than or equal to Minimum number of Entries.");
            maxEntryValidation = true;
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }
        }
        //console.log(parseInt(totalJoinedCount), maxUsers)
        if(parseInt(totalJoinedCount) > maxUsers && editAction && !maxEntryValidation) {
            isValid = false;
            setMaxWeekError("The participants limit cannot be reduced to this number as more users have already joined the contest.");
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }
        }
        if (seasonLeaderValue > maxUsers && leaguetype == 'K') {
            isValid = false;
            setSeasonLeadersError("Season Scores Leaders should be less than or equal to Maximum number of Entries.");
            if (!focusSet) {
                refSeasonLeaders.current.focus();
                focusSet = true;
            }
        }

        if (weekLeaderValue > maxUsers && leaguetype == 'K') {
            isValid = false;
            setWeekLeadersError("Weekly Leaders should be less than or equal to Maximum number of Entries.");
            if (!focusSet) {
                refWeekLeaders.current.focus();
                focusSet = true;
            }
        }
        return isValid;
    }

    const handleLeaguenameChange = (e) => {
        //console.log('===handleLeaguenameChange====')
        setLeagueNameError("");
        setLeagueNameValue(e.target.value);
        setLeaguetype('S');
        props.showLeagueTypeName('S', weekLeaderValue, seasonLeaderValue)

    };

    const handleLeagueWeekChange = (e) => {
        setEndWeekError("");
        setStartWeekError("");
        let endWeekSelectedValue = document.querySelector("#endweek").value ? document.querySelector("#endweek").value : '';
        let startWeekSelectedValue = document.querySelector("#startweek").value ? document.querySelector("#startweek").value : 0;
        setEndWeekValue(endWeekSelectedValue);
        setStartWeekValue(startWeekSelectedValue);

        if (endWeekSelectedValue != '' && parseInt(endWeekSelectedValue) < parseInt(startWeekSelectedValue)) {
            setEndWeekError("End Week should be later than or the same as Start Week.");
            setMinUsers(0)
            setMaxUserValue(0)
            props.setEntriesData(0);
        } else {
            let minUsersData = parseInt(endWeekSelectedValue) - parseInt(startWeekSelectedValue);
            minUsersData = (minUsersData >= 0) ? minUsersData + 2 : 1;
            setMinUsers(minUsersData);
            setMaxUserValue(minUsersData)
            props.setEntriesData(minUsersData);
            setMaxWeekError("");
            props.showLeagueDuration(startWeekSelectedValue, endWeekSelectedValue);
        }
    };

    const handleWeekLeaderChange = (e) => {
        setWeekLeadersError("");
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setWeekLeaderValue(value);
        props.showLeagueTypeName(leaguetype, value, seasonLeaderValue)
    };

    const handleSeasonLeaderChange = (e) => {
        setSeasonLeadersError("");
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setSeasonLeaderValue(value);
        props.showLeagueTypeName(leaguetype, weekLeaderValue, value)
    };

    const leadersSettingsDivClass = () => {
        let divClassName = 'multiple-select-box';
        if (leaguetype != 'K') {
            divClassName = 'hide';
        }
        return divClassName;
    }
   
    const handleLeagueType = (e) => {
        setLeagueTypeError("");
        setLeaguetype(e.target.value);
        if(e.target.value == "") {
            setLeagueTypeError("Please select league type");
            return false;
        }
        props.showLeagueTypeName(e.target.value, weekLeaderValue, seasonLeaderValue)
    };

    const changeLineUpNumbers = (e) => {
        let { value } = e.target;
        setLineUpNumber(value);
        props.setLeagueCategory(entryTypeValue, value)
    };

    useEffect(() => {
       // console.log(navigator.geolocation)
             let locationData =  navigator.geolocation.getCurrentPosition(LocationAcesssuccess, LocationAcesserror);
        
     }, []);

     const LocationAcesssuccess = async (gotPosition) => {
        //console.log(gotPosition)
         setGeoLocationAvailable(true);
         setGeoLocationEnabled(true)
         
        
       
     };
       
     const LocationAcesserror = async (err) => {
        setGeoLocationAvailable(false);
        setGeoLocationEnabled(false)
     };

     const continueVerification = (e) => {
        navigate(pageURLs.Verificationstart);
    }

    const closeVerification = (e) => {
        setshowVerificationProcessModal(false);
        navigate(pageURLs.lobby);
    }
    

    useEffect(async () => {
        setVerificationProcessModal()
    }, [user.data.user.user_status_id]);  

    const setVerificationProcessModal = (e) => {
        if(user.data.user.user_status_id != 1) { //Not verified users
            if(user.data.user.user_status_id == 4 || (user.data.user.user_status_id == 5 && user.data.user.tsevo_status_id == 3)) { //Verification failed or not started the verification
                setnotverifiedMessage('Complete verification for creating league')
            } else if(user.data.user.user_status_id == 5 && user.data.user.tsevo_status_id == 2) {
                setnotverifiedMessage("Your identity verification is still in progress. Please be patient - in rare cases, verification can take up to 24 hours. If it has been 24 hours or longer, please contact Knockout customer care at admin@fantasysportsknockout.com.");
                setverificationBtnTitle("Retry verification")
            }
            setshowVerificationProcessModal(true)
            return false;
        }
    }

    return (

        <>
            <div className="field-container">
                <h4>Enter the details of your League</h4>
                {leagueCreationError && (
                    <span style={{ color: "red" }}>{leagueCreationError}</span>
                )}
                {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                <div className="form-field">
                    <KOInput
                        id="leaguename"
                        placeholder="Enter the name of the League"
                        label="Name of the League"
                        name="leaguename"
                        type="text"
                        txtRef={refLeagueName}
                        errorMessage={leagueNameError}
                        onChange={(e) => { handleLeaguenameChange(e); }}
                        value={leagueNameValue}
                    />
                  {

                    <KOSelectBox
                        id="leaguetype"
                        name="leaguetype"
                        label="League Type"
                        type="text"
                        options={leagutypeOptions}
                        onChange={(e) => { handleLeagueType(e); }}
                        errorMessage={leagueTypeError}
                        value={leaguetype}
                        txtRef={refLeagueType}
                        
                    />
                    
                    }
                    <div className={leadersSettingsDivClass()}>
                        <KOInput
                            id="weekleaders"
                            name="weekleaders"
                            label="Weekly Leaders"
                            type="number"
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            txtRef={refWeekLeaders}
                            errorMessage={weekLeadersError}
                            onChange={(e) => { handleWeekLeaderChange(e); }}
                            minValue="0"
                            maxValue="100"
                            value={weekLeaderValue}
                        />
                        <KOInput
                            id="scoreleaders"
                            name="scoreleaders"
                            label="Season Scores Leaders"
                            type="number"
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            txtRef={refSeasonLeaders}
                            errorMessage={seasonLeadersError}
                            onChange={(e) => { handleSeasonLeaderChange(e); }}
                            minValue="0"
                            maxValue="100"
                            value={seasonLeaderValue}
                        />
                    </div>
                    <h6>League Duration</h6>
                    <div className="multiple-select-box">
                        <KOSelectBox
                            id="startweek"
                            name="startweek"
                            label="Start Week"
                            type="text"
                            options={startWeekOptions}
                            onChange={(e) => { handleLeagueWeekChange(e); }}
                            value={startWeekValue}
                            errorMessage={startWeekError}
                            txtRef={refStartWeek}
                            disabled ={isJoinedOtherUsers ? true : false}
                        />
                        <KOSelectBox
                            id="endweek"
                            name="endweek"
                            label="End Week"
                            type="text"
                            options={endWeekOptions}
                            onChange={(e) => { handleLeagueWeekChange(e); }}
                            value={endWeekValue}
                            errorMessage={endWeekError}
                            txtRef={refEndWeek}
                            disabled ={isJoinedOtherUsers ? true : false}
                        />
                    </div>
                    <h6>No. of Entries</h6>
                    <div className="multiple-select-box">
                        <KOInput
                            id="minUsers"
                            name="minUsers"
                            label="Min. (No. of weeks +1)"
                            type="text"
                            readonly="readonly"
                            value={minUsers}

                        />
                        <KOInput
                            id="maxUsers"
                            name="maxUsers"
                            label="Max (100)"
                            type="number"
                            step="1"
                            onChange={(e) => { handleNumberChange(e); }}
                            minValue="0"
                            maxValue="100"
                            errorMessage={maxWeekError}
                            txtRef={refMaxweek}
                            value={maxUsers}
                        />
                    </div>
                    <h6>League Category</h6>
                    <div className="multiple-select-box">
                        <div className="form-group radio-field">
                            <KORadiobutton
                                name="leagueEntry"
                                label="Single Entry"
                                id="singleentry"
                                value="S"
                                checked={entryTypeValue == 'S'}
                                onChange={handleRadioChange}
                            />
                            <KORadiobutton
                                name="leagueEntry"
                                label="Multiple Entries"
                                id="multipleentry"
                                value="M"
                                checked={entryTypeValue == 'M'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <KOSelectBox
                            id="lineupcount"
                            name="lineupcount"
                            label="No. of Lineups"
                            type="text"
                            options={lineupcountOptions()}
                            disabled={disableMultiEntryCheckBox}
                            value={lineUpNumber}
                            onChange={changeLineUpNumbers}
                        />
                    </div>
                    <div className="form-action">
                    {/* {!geoLocationAvailable && <div style={{color: "red"}}>Your browser does not support Geolocation</div>} */}
                    {!geoLocationEnabled && <div style={{color: "red"}}>Geolocation is required for creating the league</div>}
                    {(geoLocationAvailable && geoLocationEnabled) ? <Button variant="primary" onClick={saveLeagueStep1} >Save and Continue</Button> : ''}
                    
                    </div>
                </div>
            </div>
            <Modal show={showVerificationProcessModal} onHide={closeVerification} className="modal-coupon">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="modal-title">Verification</h4>
                    <p>{notverifiedMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={continueVerification}>{verificationBtnTitle}</Button>
                    <Button variant="link" onClick={closeVerification}>Maybe later</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

};

export default CreateLeagueStep1;