import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Offcanvas, Form, Modal } from "react-bootstrap";
import KOInput from "../components/KOInput";
import KORadiobutton from "../components/KORadiobutton";
import PlusIcon from '../assets/icons/plusicon';
import MinusIcon from '../assets/icons/minusicon';
import * as contestService from "../services/contestService";
import useSiteState from '../appState/siteState/useSiteState';
import * as userGamesController from "../controller/userGamesController";
import { useNavigate } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";

const CreateLeagueStep2 = (props) => {
    let userSiteGameSubmittedData = props.UserSiteGameData;
    let isJoinedOtherUsers = (props.isJoinedOtherUsers) ? props.isJoinedOtherUsers : false;

    const editAction = props.editAction;
    let selectedPositionArray = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[1] && userSiteGameSubmittedData[1].selectedPositionArray) ? userSiteGameSubmittedData[1].selectedPositionArray : [];
    let NonSelectedPositionArray = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[1] && userSiteGameSubmittedData[1].NonSelectedPositionArray) ? userSiteGameSubmittedData[1].NonSelectedPositionArray : [];
    let is_custom_position = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[1] && userSiteGameSubmittedData[1].is_custom_position) ? userSiteGameSubmittedData[1].is_custom_position : 0;
    let positionName = (is_custom_position == 1) ? 'customposition' : 'standardposition';
    let isEditMode = (userSiteGameSubmittedData.length && userSiteGameSubmittedData[1] && userSiteGameSubmittedData[1].selectedPositionArray) ? true : false;
    let loaderDisplay = (isEditMode) ? false : true;
    var default_selected_items = [
        { title: 'Quarterback', key: 'quarterback', value: '' },
        { title: 'Running Back', key: 'runningback', value: '' },
        { title: 'Wide Receiver', key: 'widereceiver', value: '' },
        { title: 'Tight End', key: 'tightend', value: '' },
        { title: 'Flex (RB/WR/TE)', key: 'flex', value: '' },
        { title: 'Kicker', key: 'kicker', value: '' },
        { title: 'DST', key: 'defense', value: '' }
    ];
    var standard_selected_items = [
        { title: 'Quarterback', key: 'quarterback', value: '' },
        { title: 'Running Back', key: 'runningback', value: '' },
        { title: 'Wide Receiver', key: 'widereceiver', value: '' },
        { title: 'Tight End', key: 'tightend', value: '' },
        { title: 'Flex (RB/WR/TE)', key: 'flex', value: '' },
        { title: 'Kicker', key: 'kicker', value: '' },
        { title: 'DST', key: 'defense', value: '' }
    ];
    default_selected_items = (selectedPositionArray.length) ? selectedPositionArray : default_selected_items;
    var default_non_selected_items = (NonSelectedPositionArray.length) ? NonSelectedPositionArray : [];
    let disabledSelectionVal = (is_custom_position) ? false : true;
    if(isJoinedOtherUsers) {
        disabledSelectionVal = true;
    }

    const siteState = useSiteState();
    const navigate = useNavigate();

    const [positionsRadioValue, setPositionsRadioValue] = useState(positionName);
    const [standardLineUpPositions, setStandardLineUpPositions] = useState(default_selected_items);
    const [CustomeLineUpPositions, setCustomeLineUpPositions] = useState(default_selected_items);
    const [defaultNonSelectedLineUpPositions, setDefaultNonSelectedLineUpPositions] = useState(default_non_selected_items);
    const [showDeletePositionConfirmModal, setShowDeletePositionConfirmModal] = useState(false);
    const [positionSelectedForRemove, setPositionSelectedForRemove] = useState('');
    const deletePositionConfirmModalClose = () => setShowDeletePositionConfirmModal(false);
    const [showAddPositionConfirmModal, setShowAddPositionConfirmModal] = useState(false);
    const [positionSelectedForAdd, setpositionSelectedForAdd] = useState('');
    const addPositionConfirmModalClose = () => setShowAddPositionConfirmModal(false);
    const [showDefaultLoader, setDefaultLoader] = useState(false);
    const [disabledSelection, setDisabledSelection] = useState(disabledSelectionVal);
    const [playerPositionSetupError, setPlayerPositionSetupError] = useState("");
    const contest_id = props.contest_id;
    if (!parseInt(contest_id)) {
        navigate(pageURLs.manageleague)
    }


    useEffect(async () => {
        let standardPositionInSession = JSON.parse(sessionStorage.getItem('standardLineUpRule'));
        if (!isEditMode || !standardPositionInSession) {
            await contestService.StandardLineUpPlan().then(function (reponse) {
                let StandardLineUpData = reponse.data;
                //assinging default std value
                standard_selected_items.map((item, index) => {
                    switch (item.key) {
                        case 'quarterback':
                            item.value = StandardLineUpData[0].total_qb;
                            break;
                        case 'runningback':
                            item.value = StandardLineUpData[0].total_rb;
                            break;
                        case 'widereceiver':
                            item.value = StandardLineUpData[0].total_wr;
                            break;
                        case 'tightend':
                            item.value = StandardLineUpData[0].total_te;
                            break;
                        case 'flex':
                            item.value = StandardLineUpData[0].total_fl;
                            break;
                        case 'kicker':
                            item.value = StandardLineUpData[0].total_ki;
                            break;
                        case 'defense':
                            item.value = StandardLineUpData[0].total_df;
                            break;
                    }
                });
                sessionStorage.setItem('standardLineUpRule', JSON.stringify(standard_selected_items));
                setCustomeLineUpPositions(standard_selected_items);
                setStandardLineUpPositions(standard_selected_items);
                setDefaultLoader(false);
            })
            props.setLineUpStrategy(positionsRadioValue, standard_selected_items);
        }
    }, []);

    //Confirm position delete action
    const deletePositionConfirmModalShow = (position) => {
        setPositionSelectedForRemove(position)
        setShowDeletePositionConfirmModal(true)
    }
    //Confirm position add action
    const addPositionConfirmModalShow = (position) => {
        setpositionSelectedForAdd(position)
        setShowAddPositionConfirmModal(true)
    }
    //Saving data to Databse
    const saveLeagueLineupStrategy = async () => {
        setPlayerPositionSetupError("");
        let selectedPositionData = [...CustomeLineUpPositions];
        let nonSelectedPositionData = [...defaultNonSelectedLineUpPositions];
        let CurrentTotal = 0;
        let total_qb = 0;
        let total_rb = 0;
        let total_wr = 0;
        let total_te = 0;
        let total_fl = 0;
        let total_ki = 0;
        let total_df = 0;
        selectedPositionData.map((item, index) => {
            CurrentTotal += parseInt(item.value);
            switch (item.key) {
                case 'quarterback':
                    total_qb = item.value;
                    break;
                case 'runningback':
                    total_rb = item.value;
                    break;
                case 'widereceiver':
                    total_wr = item.value;
                    break;
                case 'tightend':
                    total_te = item.value;
                    break;
                case 'flex':
                    total_fl = item.value;
                    break;
                case 'kicker':
                    total_ki = item.value;
                    break;
                case 'defense':
                    total_df = item.value;
                    break;
            }
        });
       // console.log(CurrentTotal)
        //Maximum 10 position can add
        if (CurrentTotal > 10) {
            setPlayerPositionSetupError("A maximum of 10 players can only be set.");
            return false;
        } else if (CurrentTotal < 1) {
            setPlayerPositionSetupError("Set at least one player to continue.");
            return false;
        } else {
            setDefaultLoader(true);
            let requestPayload = {
                "step": 2,
                "lg_contest_id": parseInt(contest_id),
                "is_custom_position": (positionsRadioValue == 'standardposition') ? 0 : 1,
                "total_qb": total_qb,
                "total_rb": total_rb,
                "total_wr": total_wr,
                "total_te": total_te,
                "total_fl": total_fl,
                "total_ki": total_ki,
                "total_df": total_df,
                "editAction": editAction
            };
            let result = await userGamesController.saveUserGames(requestPayload);
            if (result.errorCode == 200) {
                requestPayload.selectedPositionArray = selectedPositionData;
                requestPayload.NonSelectedPositionArray = nonSelectedPositionData;
                props.updateLeagueContestId(result.data.lg_contest_id);
                props.updateFullUserGameData(requestPayload, 1);
                setDefaultLoader(false);
                props.changeActiveStepValue();
            } else {
                setPlayerPositionSetupError(result.message);
                props.updateFullUserGameData(requestPayload, 3);
                setDefaultLoader(false);
                navigate(pageURLs.manageleague)
            }
        }
    };

    //Line up strategy change action
    const handleRadioChange = (e) => {
        setPlayerPositionSetupError("");
        setPositionsRadioValue(e.target.name);
        let standardLineUpArray = sessionStorage.getItem('standardLineUpRule');
        if (e.target.name == 'standardposition') {
            setDisabledSelection(true);
            setCustomeLineUpPositions(JSON.parse(standardLineUpArray));
            setDefaultNonSelectedLineUpPositions([]);
        } else {
            setDisabledSelection(false);
        }
        props.setLineUpStrategy(e.target.name, JSON.parse(standardLineUpArray));
    };
    //Removing position from list
    const removePositionFromList = (positionType) => {
        setShowDeletePositionConfirmModal(false)
        let newPositionArray = [...CustomeLineUpPositions];
        let newNonSelectedPositionArray = [...defaultNonSelectedLineUpPositions];
        let removedArray = [];
        for (var i = newPositionArray.length - 1; i >= 0; i--) {
            if (newPositionArray[i]['key'] === positionType) {
                removedArray = newPositionArray.splice(i, 1);
                removedArray[0].value = 0;
            }
        }
        setCustomeLineUpPositions(newPositionArray);
        if (removedArray) {
            newNonSelectedPositionArray.push(...removedArray)
            setDefaultNonSelectedLineUpPositions(newNonSelectedPositionArray)
        }
        props.setLineUpStrategy(positionsRadioValue, newPositionArray);
    }

    //Add a position back to list from unseelcted listing
    const addPositionToList = (positionType) => {
        setShowAddPositionConfirmModal(false)
        let newNonSelectedPositionArray = [...defaultNonSelectedLineUpPositions];
        let newPositionArray = [...CustomeLineUpPositions];
        let removedArray = [];
        for (var i = newNonSelectedPositionArray.length - 1; i >= 0; i--) {
            if (newNonSelectedPositionArray[i]['key'] === positionType) {
                removedArray = newNonSelectedPositionArray.splice(i, 1);
                removedArray[0].value = 0;
            }
        }
        setDefaultNonSelectedLineUpPositions(newNonSelectedPositionArray);
        if (removedArray) {
            newPositionArray.push(...removedArray)
            setCustomeLineUpPositions(newPositionArray)
            props.setLineUpStrategy(positionsRadioValue, newPositionArray);
        }
    }

    //Check total number of positions added
    const checkTotalAddedPositionNumbers = (key, e) => {
        setPlayerPositionSetupError("");
        let value = e.target.value;
        let index = e.target.id;
        let currentData = [...CustomeLineUpPositions];
        let CurrentTotal = 0;
        let changedPositionIndex = 0;
        currentData.map((item, index) => {
            if (key === item.key) {
                changedPositionIndex = index;
                CurrentTotal += parseInt(value);
            } else {
                CurrentTotal += parseInt(item.value);
            }
        });

        if (parseInt(CurrentTotal) <= 10) {
            currentData[changedPositionIndex].value = value;
            if (parseInt(value) <= 0) {
                deletePositionConfirmModalShow(index);
                return false
            }
            setCustomeLineUpPositions(currentData)
            props.setLineUpStrategy(positionsRadioValue, currentData);
        } else {
            setPlayerPositionSetupError("A maximum of 10 players can only be set.");
            return false;
        }
    }

    //Show selected fields
    const createSelectedFields = () => {
        return CustomeLineUpPositions.map((item, index) => (
            <div className="position-select-field" key={index}>
                <KOInput
                    id={item.key}
                    name={item.key}
                    label={item.title}
                    type="number"
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={item.value}
                    minValue="0"
                    maxValue="10"
                    key={index}
                    onChange={function (e) { checkTotalAddedPositionNumbers(item.key, e) }}
                    data-index={item.key}
                    disabled={disabledSelection ? true : false}
                />
                {(positionsRadioValue != 'standardposition' && !isJoinedOtherUsers)  ? <Button variant="light" className="addon" onClick={function () { deletePositionConfirmModalShow(item.key) }}><MinusIcon /></Button> : ''}
            </div>
        ));
    }

    //Show un selected fields
    const createUnselectedSelectedFields = () => {
        return defaultNonSelectedLineUpPositions.map((item, index) => (
            <div className="position-select-field" key={'unselected_' + index}>
                <KOInput
                    id={item.key}
                    name={item.key}
                    label={item.title}
                    type="number"
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value="0"
                    readonly="true"
                />
                {( positionsRadioValue != 'standardposition' && !isJoinedOtherUsers)  ? <Button variant="light" className="addon" onClick={function () { addPositionConfirmModalShow(item.key) }}><PlusIcon /></Button> : ''}
            </div>
        ));
    }
    return (
        <>
            <div className="field-container">
                <h4>Format for your League</h4>
                {playerPositionSetupError && (
                    <span style={{ color: "red", paddingBottom: "10px" }}>{playerPositionSetupError}</span>
                )}
                <div className="form-field lineup-form">
                    {
                                            
                          <div className="form-group postion-radio-group">
                              <KORadiobutton
                                  name="standardposition"
                                  label="Standard Positions"
                                  id="standardposition"
                                  checked={positionsRadioValue === 'standardposition'}
                                  onChange={handleRadioChange}
                                  disabled = {isJoinedOtherUsers ? true : false}
                              />
                              <KORadiobutton
                                  name="customposition"
                                  label="Custom Positions"
                                  id="customposition"
                                  checked={positionsRadioValue === 'customposition'}
                                  onChange={handleRadioChange}
                                  disabled = {isJoinedOtherUsers ? true : false}
                              />
                          </div>
                          
                    }
                    {showDefaultLoader && <div className="position-select-field"><div className="loader-wrap"><div className="loader"></div></div></div>}
                    {createSelectedFields()}
                    {defaultNonSelectedLineUpPositions.length > 0 &&
                        <hr></hr>}
                    {createUnselectedSelectedFields()}
                    <div className="form-action">
                        <Button variant="primary" onClick={saveLeagueLineupStrategy} >Save and Continue</Button></div>
                </div>

                <Modal show={showDeletePositionConfirmModal} onHide={deletePositionConfirmModalClose} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Are you sure you want to remove this position from the list?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => removePositionFromList(positionSelectedForRemove)}>Yes</Button>
                        <Button variant="link" onClick={deletePositionConfirmModalClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAddPositionConfirmModal} onHide={addPositionConfirmModalClose} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Are you sure you want to add this position to the list?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant="primary" onClick={() => addPositionToList(positionSelectedForAdd)}>Yes</Button>
                        <Button variant="link" onClick={addPositionConfirmModalClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );

};

export default CreateLeagueStep2;