import React, { useState, useEffect} from 'react';
import {Table} from "react-bootstrap";
const KickerStats = props => {
    const seasonWeeks = props.seasonWeeks;
    const gameLogs = props.gameLogs;
    const totalStats = props.totalStats;
    const gameStats = () => {
        let gameStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            gameStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                        <td>{ thisWeekLog.extra_pointsmade ? thisWeekLog.extra_pointsmade.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.extra_points_attempted ? thisWeekLog.extra_points_attempted.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.field_goals_attempted ? thisWeekLog.field_goals_attempted.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.field_goalsmade ? thisWeekLog.field_goalsmade.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.zero_to_thirtynine_fg ? thisWeekLog.zero_to_thirtynine_fg.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.fourty_to_fourtynine_fg ? thisWeekLog.fourty_to_fourtynine_fg.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.fifty_plus_fg ? thisWeekLog.fifty_plus_fg.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                    </tr>
                :
                <tr>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                    </tr>
            );
        }
        return gameStatsRow;
    }

    //Score stats
    const scoreStats = () => {
        let scoreStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            scoreStatsRow.push(
                (thisWeekLog && !isgameCancelled) ?<tr>
                        <td>{thisWeekLog.player_week_score ? thisWeekLog.player_week_score.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                    </tr>:
                <tr>
                    <td>--</td>
                </tr>
            );
        }
        return scoreStatsRow;
    }
    return (
        <>
            <td colSpan={4}>
            <Table className="stat-table">
            <thead>
                <tr>
                <th>XPM</th>
                <th>XPA</th>
                <th>FGA</th>
                <th>FGM</th>
                <th>FGM &lt; 40</th>
                <th>FGM 40-49</th>
                <th>FGM 50+</th>
                
                </tr>
            </thead>
            <tbody>
                {gameStats()}
                <tr>
                    <th>{ totalStats.totalXPM ? Number(totalStats.totalXPM).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalXPA ? Number(totalStats.totalXPA).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalFGA ? Number(totalStats.totalFGA).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalFGM ? Number(totalStats.totalFGM).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalFourtyFGM ? Number(totalStats.totalFourtyFGM).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalFourtytoFourtyNineFGM ? Number(totalStats.totalFourtytoFourtyNineFGM).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalFiftyPlusFGM ? Number(totalStats.totalFiftyPlusFGM).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                </tr>
            </tbody>
            </Table>
        </td>

       
        <td>
            <Table className="stat-table">
            <thead>
                <tr>
                <th>Score</th>
                </tr>
            </thead>
            <tbody>
                {scoreStats()}
                <tr>
                    <th>{ totalStats.totalPoints ? Number.parseFloat(totalStats.totalPoints).toFixed(2).replace(/\.00$/,'') : 0  }</th>
                </tr>
            </tbody>
            </Table>
        </td>
    </>
);
  
}

export default (KickerStats);
