import React from 'react';

const KOSelectBox = props => {

    const getOptions = (options, value) => {
        if (options) {
            return options.map((item) => (
                (item.value === value) ?
                <option selected key={item.value} value={item.value}>{item.label}</option> :
                <option key={item.value} value={item.value}>{item.label}</option>
            ));
        }
        else {
            return <option value="" disabled selected hidden>Select</option>;
        }
    };

    return (
        <div className="form-group">
            <label>{props.label}</label>
            <select className="form-select" id={props.id} ref={props.ref} value={props.value} onChange={props.onChange} disabled={props.disabled} ref={props.txtRef}>
                { getOptions() }
                { getOptions(props.options, props.value) }
            </select>
            {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
        </div>
    );
}

export default (KOSelectBox);
