import React, { useState, useEffect, useRef } from "react";
import {Badge} from "react-bootstrap";
import * as contestService from "../services/contestService";
import userpic from '../assets/user-default.png';
import Paginate from "../components/Paginate";
import useUserState from "../appState/userState/useUserState";


const SeasonRankings = (props) => {
    const constest_id = props.constest_id;
    const isuserGame = props.isuserGame;
    const initialPageParams = {
        page : 1
    }
    const userState = useUserState();
    const userId = userState.data.user.user_id;
    const [rankPageParams, setRankPageParams] = useState(initialPageParams);
    const [seasonRankings, setSeasonRankings] = useState([]);
    const [rankingPageData, setRankingPageData] = useState([]);
    const [showLoader, setLoader] = useState(false);
    const [totalLineupCount, setTotalLineupCount] = useState(0);
    const [seasonOwnRankings, setSeasonOwnRankings] = useState([]);
    const [totalResults, setTotalResults] = useState(0);


    useEffect(async () => {
        fetchSeasonRankings(props.constest_id, rankPageParams.page, totalLineupCount)
    }, [props.constest_id, rankPageParams]);

    const fetchSeasonRankings = async (constest_id, pageNumber,totalLineups) => {
        setLoader(true)
        let request = {
            "si_contest_id" : constest_id,
            "page" : pageNumber,
            "userTotalLineUpCount" : totalLineups
        }
        let type = (!isuserGame) ? 1 : 2;
        await contestService.getSeasonLeaderboardService(request, type)
        .then(function(response){
            setLoader(false)
            if(response.errorCode == 200) {
                setSeasonRankings(response.data.usersLeaderBoard);
                setSeasonOwnRankings(response.data.currentUserRanks)
                setRankingPageData({
                    perPage: response.data.perPage ? response.data.perPage : 24,
                    totalPages: response.data.totalPages ? response.data.totalPages : 0,
                    total: response.data.total ? response.data.total : 0,
                    currentPage: response.data.currentPage ? response.data.currentPage : 0,
                    errorCode: response.errorCode ? response.errorCode : 0,
                });
                let totalCurrentUserCnt = (response.data.currentUserRanks) ? response.data.currentUserRanks.length : 0;
                let totalRanksCnt = (response.data.usersLeaderBoard) ? response.data.usersLeaderBoard.length : 0;
                if(!totalCurrentUserCnt) totalCurrentUserCnt = 0;
                if(!totalRanksCnt) totalRanksCnt = 0;
                let totolResultsCount = Number(totalCurrentUserCnt) + Number(totalRanksCnt)
                setTotalResults(totolResultsCount)
                setTotalLineupCount(response.data.userTotalLineUpCount)
            } else {
                setSeasonRankings([]);
                setRankingPageData([])
                setTotalResults(0)
                setTotalLineupCount(0)
                setSeasonOwnRankings([])
            }
        })
    }

    const pageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setRankPageParams(pageparams);
    }
    return (
        <><div className="contestant-standings">
            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}

            {seasonOwnRankings.length  ?
			seasonOwnRankings
			.map((items, index) => ( 
                <div className={'contestant-card' +' '+ ((items.user_id == userId) ? 'my-card-selected' :'')}>
                    <div className="contestant-info">
                        <div className="profile-img">
                            <figure ><img src={items.profile_image ? items.profile_image : userpic} /></figure>
                            {(items.user_id == userId) ? <span className="you-label">You</span> : ''}
                        </div>
                        <div className="user-info">
                            {/* <div className='name'>Marquez Stevenson <span className="level-badge experienced">E</span></div> */}
                            <div className='name'>{(items.user_full_name) ? items.user_full_name : items.user_name} {items.experience_level==2 ? <span className="level-badge experienced">E</span> : ''} {items.experience_level==1 ? <span className="level-badge beginners">B</span> : ''}</div>
                            <div className='lineup'>Lineup {items.lineup_number}</div>
                            <div className="tiebreaker">Tiebreaker <span>{items.predicted_tie_score}</span></div>
                        </div>
                    </div>
                    <div className="point-rank">
                        <div className='point'><p><span>Pts</span>{items.Score}</p></div>
                        {(items.in_progress) ? <Badge bg="success">IN PROGRESS</Badge> : ''}
                        <div className="rank-holder">
                            <span>RANK</span>
                            <div className='rank'>{items.User_Rank}</div>
                        </div>
                    </div>
                </div>  
            )) : ''}
            
            {seasonRankings.length  ?
			seasonRankings
			.map((items, index) => ( 
                <div className={'contestant-card' +' '+ ((items.user_id == userId) ? 'my-card-selected' :'')}>
                    <div className="contestant-info">
                        <div className="profile-img">
                            <figure ><img src={items.profile_image ? items.profile_image : userpic} /></figure>
                            {(items.user_id == userId) ? <span className="you-label">You</span> : ''}
                        </div>
                        <div className="user-info">
                            {/* <div className='name'>Marquez Stevenson <span className="level-badge experienced">E</span></div> */}
                            <div className='name'>{(items.user_full_name) ? items.user_full_name : items.user_name} {items.experience_level==1 ? <span className="level-badge beginners">B</span> : ''} {items.experience_level==2 ? <span className="level-badge experienced">E</span> : ''}</div>
                            <div className='lineup'>Lineup {items.lineup_number}</div>
                            <div className="tiebreaker">Tiebreaker <span>{items.predicted_tie_score}</span></div>
                        </div>
                    </div>
                    <div className="point-rank">
                        <div className='point'><p><span>Pts</span>{items.Score}</p></div>
                        {(items.in_progress) ? <Badge bg="success">IN PROGRESS</Badge> : ''}
                        <div className="rank-holder">
                            <span>RANK</span>
                            <div className='rank'>{items.User_Rank}</div>
                        </div>
                    </div>
                </div>  
            )) : ''}
            
        </div>
        {(!seasonOwnRankings.length && !seasonRankings.length && !showLoader) ? <div class="vertical-center-position"><div class="row"><span style={{ color: "red" }}>No records to display.</span></div></div> : ""}

        {rankingPageData ? <Paginate paginationData={rankingPageData} pageRows={totalResults} onClick={pageNumberChange} /> : ""}
        </>
    );
};

export default SeasonRankings;
