import React, { useState, useEffect } from "react";
import KOContestantCard from "../components/KOContestantCard";
import SimpleBar from 'simplebar-react';
import { store } from '../appState/store';
import userpic from '../assets/user-default.png';
import * as commonService from "../services/commonService";

const SeasonStatsContestantStanding = (props) => {

    const userData = store.getState().user
    const [seasonUserStandings, setSeasonUserStandings] = useState([]);
    const [userContestStanding, setUserContestStanding] = useState([]);
    const [userStanding, setUserStanding] = useState([]);
    const [standingsUserId, setStandingsUserId] = useState(0);
    const [selectedLIneup, setSelectedLIneup] = useState(0);
    let maxHeights = '485px';


    if (props.seasonStandingsLeaderBoradResponse && props.seasonStandingsLeaderBoradResponse.data && props.seasonStandingsLeaderBoradResponse.data.playersStatus) {
        if (props.seasonStandingsLeaderBoradResponse.data.playersStatus.length > 4)
            maxHeights = ((props.seasonStandingsLeaderBoradResponse.data.playersStatus.length * 100) + 'px')

    }

    useEffect(() => {
        // all users
        if (props.seasonStandingsLeaderBoradResponse) {
            let leaderBoard = props.seasonStandingsLeaderBoradResponse.data.usersLeaderBoard;
            let leaderBoardData = (leaderBoard && leaderBoard.length) ? commonService.setRanks(leaderBoard) : [];
            setSeasonUserStandings(leaderBoardData)
        }

        // current user
        setUserContestStanding(userData.user);
    }, [props.seasonStandingsLeaderBoradResponse])

    useEffect(() => {
        if (userContestStanding) {
            // checking current user
            const logedinUser = seasonUserStandings && seasonUserStandings.filter((data) => { return data.user_id == userContestStanding.user_id });
            // current user
            if (logedinUser)
                setUserStanding(logedinUser[0]);
        }
    }, [userContestStanding, seasonUserStandings])



    const updateSeasonStatsUser = async (user_id, lineup_number, lineup_id) => {
        setStandingsUserId(user_id)
        setSelectedLIneup(lineup_number+'_'+user_id);
        props.updateSeasonStatsUser(user_id, lineup_number);
    }


    let logged_user_lineup = (userStanding && userStanding.lineup_number) ? userStanding.lineup_number+'_'+userData.user.user_id : 0;
    return (
        <div className="contestant-standings">
            {userStanding ?
                <KOContestantCard
                    onClick={() => updateSeasonStatsUser(userContestStanding.user_id, userStanding.lineup_number, userStanding.lineup_number)}
                    className={((userStanding.lineup_number+'_'+userContestStanding.user_id === selectedLIneup || !selectedLIneup) ? "mycard" : "")}
                    figure={userStanding.profile_image ? userStanding.profile_image : userpic}
                    pointType={props.pointType}
                    username={userStanding.user_full_name}
                    lineup={userStanding.lineup_number}
                    rank={userStanding.User_Rank}
                    ytdpoints={userStanding.Score ? userStanding.Score : '0'}
                    type="SeasonStandings"
                    loggedInUserId={userData.user.user_id}
                    user_id={userData.user.user_id}
                /> : ""}
            <SimpleBar style={{ maxHeight: maxHeights }}> 

                {seasonUserStandings && seasonUserStandings.length > 0 && userStanding &&
                    seasonUserStandings
                        .filter(data => data.lineup_number+'_'+data.user_id != userStanding.lineup_number+'_'+userData.user.user_id)
                        .map((items, index) => (

                            <KOContestantCard
                                onClick={() => updateSeasonStatsUser(items.user_id, items.lineup_number, items.lineup_number)}
                                className={((items.lineup_number+'_'+items.user_id == selectedLIneup) ? "mycard " : "")}
                                type="SeasonStandings"
                                figure={items.profile_image ? items.profile_image : userpic}
                                username={items.user_full_name}
                                lineup={items.lineup_number}
                                rank={items.User_Rank}
                                // rank={items.rank ? items.rank : items.User_Rank ? items.User_Rank : '1'}
                                ytdpoints={items.Score ? items.Score : '0'}
                                loggedInUserId={userData.user.user_id}
                                user_id={items.user_id}
                            />
                        ))}
            </SimpleBar>

        </div>
    );
}

export default (SeasonStatsContestantStanding);
