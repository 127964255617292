import React , { useState, useEffect} from 'react';
import { Accordion, Table } from "react-bootstrap";
import * as commonService from "../services/commonService";

const DepthChart = props => {

    const [depthChart, setDepthChart] = useState([]);
    const [depthChartCount, setDepthChartCount] = useState(0);
    const [showLoader, setLoader] = useState(false);
    const depthChartWeek = props.week;

    useEffect( async () => {
        setLoader(true)
        await commonService.getTeamDepthChart(depthChartWeek)
        .then(async function (response) {
            setLoader(false)
            if(response.errorCode == 200) {
                setDepthChartCount(response.data.depthChartData.length)
                await depthChartDataPopulate(response.data.depthChartData)
            } else {
                setDepthChart([]);
                setDepthChartCount(0)
            }
        })
    }, [])

    const depthChartDataPopulate = async (depthChartData) => {
        let dpcData = [];
        for (let k = 0; k < depthChartData.length; k++) {
            let team_key = depthChartData[k].team_key;
            if (!dpcData[team_key]){ 
                dpcData[team_key] = []; 
            }
            dpcData[team_key].push(depthChartData[k])
        }
        setDepthChart(dpcData);
    }

    const depthChartPlayers = (dpDetails) => {
        const positions = {"QB" : 'QB','RB' : 'RB','WR1' : 'WR', 'WR2' : 'WR', 'WR3' : 'WR', 'TE' : 'TE', 'K' : 'PK'};
        let playerDataRow = [];
        let playerRanks = [];
        //Sorting by ranks
        dpDetails = dpDetails.sort(function(a,b){
            return a.rw_rank - b. rw_rank;
        });
        //Creating array based on player postions
        for (let i = 0; i< dpDetails.length ;i++) {
            let rw_player_position = dpDetails[i].rw_player_position;
            if(rw_player_position == 'K') { // Kicker need to show as PK in depth chart
                rw_player_position = 'PK';
            }
            if (! playerRanks[rw_player_position]){
                playerRanks[rw_player_position] = [];
            }
            playerRanks[rw_player_position].push(dpDetails[i]);
        }

        for (const [key, value] of Object.entries(positions)) { 
            playerDataRow.push(
                <tr>
                    <td>{value}</td>
                    {(key == 'QB' || key == 'RB' || key == 'K' || key == 'TE') ? 
                        <><td>{(playerRanks[value] && playerRanks[value][0]) ? playerRanks[value][0].rw_player_first_name+' '+playerRanks[value][0].rw_player_last_name : ' - '}</td> 
                        <td>{(playerRanks[value] && playerRanks[value][1]) ? playerRanks[value][1].rw_player_first_name+' '+playerRanks[value][1].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][2]) ? playerRanks[value][2].rw_player_first_name+' '+playerRanks[value][2].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][3]) ? playerRanks[value][3].rw_player_first_name+' '+playerRanks[value][3].rw_player_last_name : ' - '}</td>
                        </> 
                        : ''}
                    {(key == 'WR1') ?  
                        <><td>{(playerRanks[value] && playerRanks[value][0]) ? playerRanks[value][0].rw_player_first_name+' '+playerRanks[value][0].rw_player_last_name : ' - '}</td>  
                        <td>{(playerRanks[value] && playerRanks[value][3]) ? playerRanks[value][3].rw_player_first_name+' '+playerRanks[value][3].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][6]) ? playerRanks[value][6].rw_player_first_name+' '+playerRanks[value][6].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][9]) ? playerRanks[value][9].rw_player_first_name+' '+playerRanks[value][9].rw_player_last_name : ' - '}</td></>
                        : ''  }
                    {(key == 'WR2') ?  
                        <><td>{(playerRanks[value] && playerRanks[value][1]) ? playerRanks[value][1].rw_player_first_name+' '+playerRanks[value][1].rw_player_last_name : ' - '}</td>  
                        <td>{(playerRanks[value] && playerRanks[value][4]) ? playerRanks[value][4].rw_player_first_name+' '+playerRanks[value][4].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][7]) ? playerRanks[value][7].rw_player_first_name+' '+playerRanks[value][7].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][10]) ? playerRanks[value][10].rw_player_first_name+' '+playerRanks[value][10].rw_player_last_name : ' - '}</td></>
                        : ''  }
                    {(key == 'WR3') ?  
                        <><td>{(playerRanks[value] && playerRanks[value][2]) ? playerRanks[value][2].rw_player_first_name+' '+playerRanks[value][2].rw_player_last_name : ' - '}</td>  
                        <td>{(playerRanks[value] && playerRanks[value][5]) ? playerRanks[value][5].rw_player_first_name+' '+playerRanks[value][5].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][8]) ? playerRanks[value][8].rw_player_first_name+' '+playerRanks[value][8].rw_player_last_name : ' - '}</td>
                        <td>{(playerRanks[value] && playerRanks[value][11]) ? playerRanks[value][11].rw_player_first_name+' '+playerRanks[value][11].rw_player_last_name : ' - '}</td></>
                        : ''  }
                </tr>
            );
        }
        return playerDataRow;
    }

    const depthChartTeams = (depthChartDetails) => {
        let teamData = [];
        let k =0;
        for (const [key, dpDetails] of Object.entries(depthChartDetails)) { 
            teamData.push(
                    <Accordion.Item eventKey={"team_"+k}>
                    <Accordion.Header>{dpDetails[0].team_full_name}</Accordion.Header>
                    <Accordion.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>POS</th>
                                    <th>STARTER</th>
                                    <th>2ND</th>
                                    <th>3RD</th>
                                    <th>4TH</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                depthChartPlayers(dpDetails)
                            }
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            );
            k++;
        }
        return teamData;
    }
  return (
    <>
      <Accordion defaultActiveKey="dp_team_0" className="report-accordion">
        {(depthChartCount) ? depthChartTeams(depthChart) : ''}
        {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
      </Accordion>
      {(!depthChartCount && !showLoader) ? <div className="playercard"><span style={{ color: "red" }}>No data to display.</span></div> : ''}
    </>
  );
}

export default (DepthChart);
