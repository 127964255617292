import React, { useState, useEffect, useRef, useMemo , useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import LiveTimer from "./LiveTimer";
import KOInput from "../components/KOInput";
import SimpleBar from 'simplebar-react';
import * as commonService from "../services/commonService";
import { pageURLs } from "../appData/pageURLs";
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import useUserState from "../appState/userState/useUserState";
import { store } from '../appState/store';
import * as contestService from "../services/contestService";
import * as userGamesController from "../controller/userGamesController";
import { FaqLink, TermsAndConditions  } from '../utils/CommonHelper';
import EliminatedWeekly from "./EliminatedWeekly";
import moment from "moment"
import debounce from "lodash/debounce";

const UsergameContestDetails = (props) => {
    let { id } = useParams();     
    let contestId = parseInt(id);

    if (!contestId) {
        navigate(pageURLs.lobby);
    }


    const [showPredictModal, setPredictModal] = useState(false);
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [isValidScore, setIsValidScore] = useState(false);
    const [tiebreakerScore, setTiebreakerScore] = useState("");
    const [availableScores, setAvailableScores] = useState([]);
    const [joinStep, setJoinStep] = useState(0);
    const { monthDate, time, year } = useDate();
    const [joinContestId, setJoinContestId] = useState(contestId);
    const [tieScoreError, setTieScoreError] = useState("");
    const [activeScore, setSctiveScore] = useState("");
    const [showLeagueDeleteConfirmModal, setShowLeagueDeleteConfirmModal] = useState(false);
    const [showCurrentScoreCheckingLoder, setCurrentScoreCheckingLoder] = useState(false);
    const [existingTiebreakerScore, setExistingTiebreakerScore] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [enableJoinContest, setEnableJoinContest] = useState(true);

    const userData = store.getState().user
    let wallet_amount = userData.user.wallet_amount;

    const [balanceAvailable, setBalanceAvailable] = useState('Y');
    const [registerFee, setRegisterFee] = useState(0);
    const [walletAmount, setWalletAmount] = useState(wallet_amount);

    const deleteConfirmModalClose = () => setShowLeagueDeleteConfirmModal(false);

    const navigate = useNavigate();
    const siteState = useSiteState();
    const userState = useUserState();
    const userId = userState.data.user.user_id;
    let user_status = userState.data.user.user_status_id;
    const siteData = store.getState().site;

    let resTotalLineup = "";
    let resJoinedOn = "";
    let resContestUserStatusId = "";
    const refTBS = useRef(null);
    localStorage.removeItem("lineupsArray");
    useEffect(async () => {
        await siteState.action.removeAllLineUpPlayers();
        siteState.action.storeContestId(contestId);
        setActiveMenu();
    }, []);

    useEffect(() => {
        let lgAmount = props.contestData.contestDetails && props.contestData.contestDetails.register_fee > 0 ? props.contestData.contestDetails.register_fee : 0;
        setRegisterFee(lgAmount);
        siteState.data.Lobby.currentContestData = props.contestData.contestDetails;
    }, [props.contestData]);

    useEffect(() => {
        if (registerFee >= 0 && wallet_amount >= 0) {
            if (parseFloat(wallet_amount) - parseFloat(registerFee) < 0) {
                setBalanceAvailable('N')
            }
        }
    }, [wallet_amount, registerFee]);

    const nextJoinStep = async () => {
        if (joinStep == 0) {
            setCurrentScoreCheckingLoder(true)
            let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 2);
            setExistingTiebreakerScore(currentTieBreakerScores)
            setCurrentScoreCheckingLoder(false)
        }
        switch (joinStep) {
            case 0:
                sessionStorage.removeItem('lineupsArray');
                setTiebreakerScore("");
                setPredictModal(true);
                setConfirmModal(false);
                setJoinStep(joinStep + 1);
                break;
            case 1:
                let selectedTieBreaker = parseFloat(tiebreakerScore).toFixed(2);
                if (existingTiebreakerScore.includes(selectedTieBreaker)) {
                    setTieScoreError("This tiebreaker score has already been entered. Please enter another score.");
                } else if (commonService.isNumber(tiebreakerScore)) {
                    setTieScoreError('')
                    setPredictModal(false);
                    setConfirmModal(true);
                    setJoinStep(joinStep + 1);
                }
                break;
            case 2:
                let siteDetails;
                let lineUpWk = 1;
                let message = "";
                let response = await contestService.JoinUserContest(parseFloat(tiebreakerScore), joinContestId, year, lineUpWk);

                siteDetails = store.getState().site;
                sessionStorage.setItem('contestId', contestId);
                sessionStorage.removeItem('contestJoinedAction');
                if (response.errorCode === 200) {
                    message = response.message;
                    sessionStorage.setItem('contestJoinedAction', true);
                    navigate(pageURLs.usergamecreatelineup + "/" + contestId);
                } else {
                    message = response.message;
                    alert(message);
                }
                break;
            default:
                cancelJoinStep();
                break;
        }
    }

    const setFocus = () => {
        refTBS.current.focus();
    }

    const cancelJoinStep = () => {
        setPredictModal(false);
        setConfirmModal(false);
        setJoinStep(0);
        setAvailableScores([]);
    }

    const cancelJoinPreviousStep = () => {
        setPredictModal(true);
        setConfirmModal(false);
        setJoinStep(1);
    }

    const closeContestJoinSteps = () => {
        setPredictModal(false);
        setConfirmModal(false);
        setJoinStep(0);
        setAvailableScores([]);
    }

    const [Flag_ShowCollapse, setFlag_ShowCollapse] = useState(false);

    const getCollapseClasses = () => {
        let result = "contest-details";
        if (Flag_ShowCollapse) {
            result += " collapsed";
        } else {
            result += " collapsable";
        }
        return result;
    }

    const handleScoreChange = (e) => {
        let scoreValue = e.target.value;
        let scoreValueArray = scoreValue.split(".");
        if (scoreValueArray.length > 2) {
            scoreValue = scoreValueArray[0] + '.' + scoreValueArray[1];
            scoreValue = parseFloat(scoreValue);
        }
        if (scoreValue === "") {
            setTiebreakerScore(scoreValue);
            setAvailableScores([]);
            setScoreList(0, 1);
        } else if (!commonService.isNumber(scoreValue)) {
            setAvailableScores([]);
            if (parseFloat(scoreValue).toFixed(2)) {
                if (!isNaN(scoreValue) && commonService.isFloat(parseFloat(scoreValue).toFixed(2))) {
                    setScoreList(scoreValue, 100.12);
                    var res = scoreValue.split(".");
                    setTiebreakerScore(scoreValue);
                } else {
                    setTiebreakerScore("");
                }
            }
        } else {
            if (scoreValue < 1000) {
                setTiebreakerScore(scoreValue);
                setScoreList(scoreValue, 100.12);
            }
        }
        setTieScoreError('')
    }
  
    /*
     * Calculate the weekly halfs 
     */
    const getWeeklyHalves = (totalWeeks) =>  {
        let halfs = (totalWeeks > 1) ? (totalWeeks/2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf" : firstHalf,
            "secondHalf" : secondHalf
        }
    } 
    const getNumberOfEliminationPerWeek = (totalWeeks , totalContestants ) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf/halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1)? firstWeekSubEliminatorCalculator: 0;
        return {
            'playersAliveSecondHalf' : numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf' : numPlayersEliminatedFirsHalf,
            'firstWeekElimination' : firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek' : firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks' : halves.firstHalf,
            'secondHalfWeeks' : halves.secondHalf,
            'minimumUsersRequired' : totalWeeks+1
        }
    }  

    //Weekly elimination formula

    const calculate = (totalContestants , totalWeeks ) => {
        if (totalContestants === 0 || totalWeeks === 0) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }
    const setScoreList = async (start, selected) => {
        let i;
        let scoreList = []
        let startVal = start;
        let displayVal;
        let selectedVal = parseFloat(selected).toFixed(2);
        debouncedSendRequest(start);
        for (i = 0; i < 18; i++) {
            startVal = parseFloat(startVal) + 0.01;
            displayVal = parseFloat(startVal).toFixed(2);
            if (displayVal === selectedVal) {
                scoreList.push({ "val": displayVal, "isActive": true });
            } else {
                scoreList.push({ "val": displayVal, "isActive": false });
            }
        }
        setAvailableScores(scoreList);
    };

    //Debouncing for avoiding multiple api calls
    const checkTieScore = useCallback(async (selectedVal) => {
        let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 2, selectedVal);
        setExistingTiebreakerScore(currentTieBreakerScores)
    }, []);

    const debouncedSendRequest = useMemo(() => {
    return debounce(checkTieScore, 500);
    }, [checkTieScore]);
    //Weekly elimination formula
    let totalWeeks = props.contestData.contestDetails.duration_in_weeks;
    let maxParticipants = props.contestData.contestDetails.max_users;
    let weekDivident = totalWeeks / 2;
    let firstHalfWeekCount = Math.floor(weekDivident);//First half weeks number
    let secondHalfWeekCount = Math.ceil(weekDivident);//Second half weeks number
    let secondPartWeekStartFrom = 2;
    let thirdPartWeekStartFrom = parseInt(firstHalfWeekCount) + 1;
    thirdPartWeekStartFrom = parseInt(props.contestData.contestDetails.contest_start_week) == 1 ?thirdPartWeekStartFrom : ( parseInt(thirdPartWeekStartFrom+props.contestData.contestDetails.contest_start_week) - 1);
    let secondHalfParticipants = secondHalfWeekCount + 1;
    const weeklyEliminationSplit = calculate( maxParticipants,totalWeeks);

    let secondHalfEliminatorCount = secondHalfWeekCount;
    let firstHalfParticipants = parseInt(maxParticipants) - parseInt(secondHalfParticipants);
    let firsthalfWeeklyElimintorCount = Math.floor(parseInt(firstHalfParticipants) / parseInt(firstHalfWeekCount));
    //---------------------------
    firsthalfWeeklyElimintorCount = firsthalfWeeklyElimintorCount ? firsthalfWeeklyElimintorCount : 1;
    //---------------------------
    let balanceElimintorInFirstWeek = parseInt(firsthalfWeeklyElimintorCount) * parseInt(firstHalfWeekCount);
    let balanceElimintorCount = parseInt(firstHalfParticipants) - parseInt(balanceElimintorInFirstWeek);
    let firstWeekEliminatorCount = parseInt(firsthalfWeeklyElimintorCount) + parseInt(balanceElimintorCount);
    let firsthalfSecondSetWeeklyElimintorCount = parseInt(firsthalfWeeklyElimintorCount) * (parseInt(firstHalfWeekCount) - 1);
    const locale = 'en-US';
    let dateCurrent = new Date(props.contestData.contestDetails.contest_start_time);
    let estNewDate = dateCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    // const contestStartDate = commonService.convertUTCDateToLocalDate(new Date(estNewDate));
    // //const contestStartDate = commonService.convertUTCDateToLocalDate(new Date(props.contestData.contestDetails.contest_start_time));
    // const contestDate = contestStartDate.getDate();
    // const contestMonth = contestStartDate.toLocaleDateString(locale, { month: 'long', timeZone: 'UTC' });
    // const contesttime = contestStartDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: 'UTC' });
    let contestDate;
    if(props.contestData) {
        contestDate = moment.utc(props.contestData.contestDetails.contest_start_time).format('MMMM DD | h:mm A');
    }
    let dtCurrent = new Date(props.contestData.contestDetails.contest_start_time);
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    let dtEst = new Date(estDate);
    const countDownDate = commonService.getTimeDifference(dtEst);

    const handleSelectAvaliableScore = (e) => {
        setTiebreakerScore(e.target.getAttribute('name'));
        setSctiveScore(e.target.getAttribute('name'));
    }
    const setActiveMenu = async () => {
        if (!props.contestData.contestUserJoinData[0] || !props.contestData.contestUserJoinData[0].lg_contest_id || props.contestData.contestUserJoinData[0].contest_user_status_id == 3 || !props.contestData.contestUserJoinData[0].contest_user_status_id) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (props.contestData.contestDetails.contest_status_id === 3 && props.contestData.contestUserJoinData[0].contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (props.contestData.contestDetails.contest_status_id === 2 && props.contestData.contestUserJoinData[0].contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if ((props.contestData.contestDetails.contest_status_id === 4 || props.contestData.contestDetails.contest_status_id === 5) && props.contestData.contestUserJoinData[0].contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        }
    }

    const viewLineUpPage = (e) => {
        navigate(pageURLs.usergamecreatelineup + '/' + contestId);
    }

    const edituserLeague = (e) => {
        navigate(pageURLs.editleague + '/' + contestId);
    }

    //Remove league
    const removeLeague = async () => {
        if (parseInt(contestId)) {
            setShowLoader(true)
            let result = await userGamesController.removeUserGames(contestId);
            if (result.errorCode == 200) {
                userState.action.getUserData();
                navigate(pageURLs.manageleague)
            } else {
                setShowLoader(false);
                alert(result.message)
            }
        }
    }

    //Show remove league confirmation popup
    const handleDeleteLeauge = () => {
        setShowLeagueDeleteConfirmModal(true)
    };

    const closeScoreCheckingModal = () => {
        setCurrentScoreCheckingLoder(false);
    }

    const contestStatusChanged = (contestDisplayStatus) => {
        if (contestDisplayStatus == 2)
            setEnableJoinContest(false)
        else
            setEnableJoinContest(true)
    }

    return (
        <div className={getCollapseClasses()}>
            <Button className="toggle-arrow m-show" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}><i className="ic-chevron"></i></Button>
            <div className="general-info">
                <div className="title-sec">
                    <h3>{props.contestData.contestDetails.contest_name}</h3>
                    <div className="title-group">
                        {(props.contestData.contestDetails.contest_type_id === 1) && <span className="bg-label bg-standard">Standard</span>}
                        {(props.contestData.contestDetails.contest_type_id === 2) && <span className="bg-label bg-knockout">Knockout Plus</span>}
                        <div className="m-show"><LiveTimer contestStatusChanged={contestStatusChanged} Status={props.contestData.contestDetails.contest_status_id} /></div>
                    </div>
                </div>
                <div className="info-data">
                    <div className="form-group">
                        <label>Entries</label>
                        <p>{props.contestData.contestDetails.total_lineups}/{props.contestData.contestDetails.max_users}</p>
                    </div>
                 {

                 /*
                 
                    <div className="form-group">
                        <label>Categories</label>
                        {props.contestData.contestDetails.lup_entrylevel_category == 'M' && <span className="badge bg-m">M</span>}
                        {props.contestData.contestDetails.lup_entrylevel_category == 'S' && <span className="badge bg-m">S</span>}
                        {props.contestData.contestDetails.guarantee_category == 'G' && <span className="badge bg-g">G</span>}
                    </div>

                    */
                    }
                    <div className="form-group">
                        <label>  Duration</label>
                        <p>Week {props.contestData.contestDetails.contest_start_week} - {props.contestData.contestDetails.contest_end_week}</p>
                    </div>
                    <div className="form-group">
                        <label>   Max Lineups</label>
                        <p>{props.contestData.contestDetails.max_lineup_per_user}</p>
                    </div>
                    {(props.contestData.contestDetails.total_weekly_winners > 0 && props.contestData.contestDetails.contest_type_id === 2) && <div className="form-group">
                        <label>Weekly Winners</label>
                        <p>{props.contestData.contestDetails.total_weekly_winners}</p>
                    </div>}
                    {(props.contestData.contestDetails.total_season_winners > 0 && props.contestData.contestDetails.contest_type_id === 2) && <div className="form-group">
                        <label>Season Sc. Winners</label>
                        <p>{props.contestData.contestDetails.total_season_winners}</p>
                    </div> }
                </div>
                <div className="info-data game-info-data">
                    <div className="form-group">
                        <label>League Scoring</label>
                        <p>{props.contestData.contestDetails.is_custom_score_rule ? 'Custom Scoring Rules' : 'Standard Scoring Rules'} </p>
                    </div>
                    <div className="form-group">
                        <label>League Strategy</label>
                        <p>{props.contestData.contestDetails.is_custom_position ? 'Custom Positions' : 'Standard Positions'} </p>
                    </div>
                    <div className="game-formation">
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_qb}</span>QB</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_rb}</span>RB</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_wr}</span>WR</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_te}</span>TE</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_fl}</span>FLEX</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_ki}</span>K</p></div>
                        <div className="game-position"><p><span>{props.contestData.contestDetails.total_df}</span>DST</p></div>
                    </div>
                </div>
                <div className="note">
                    <p>Eliminated Weekly*:</p>
                    <ul>
                {

                /* <li>Week {props.contestData.contestDetails.contest_start_week}: {weeklyEliminationSplit.data.firstWeekElimination}

{weeklyEliminationSplit.data.firstWeekElimination > 1 ? ' Users' : ' User'}</li>

{weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 0 && <li>Week {props.contestData.contestDetails.contest_start_week? parseInt(props.contestData.contestDetails.contest_start_week) +1 : 2}  {firstHalfWeekCount == 2 ? "" : ' - ' + (thirdPartWeekStartFrom - 1)}: {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek} {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 1 ? ' Users' : ' User'}</li>}

{weeklyEliminationSplit.data.secondHalfWeeks > 0 && <li>Week {thirdPartWeekStartFrom}  {thirdPartWeekStartFrom == props.contestData.contestDetails.contest_end_week ? "" : ' - ' + props.contestData.contestDetails.contest_end_week}: 1 User</li>}
*/
                }
                   <EliminatedWeekly lineupWeekNumber='4' />
                    </ul>
                   
                </div>
                {(props.contestData.contestDetails.max_users <= props.contestData.contestDetails.total_lineups && props.contestData.contestDetails.contest_status_id == 3 && (!props.contestData.contestUserJoinData[0] || !props.contestData.contestUserJoinData[0].lg_contest_id || props.contestData.contestUserJoinData[0].contest_user_status_id == 3 || !props.contestData.contestUserJoinData[0].contest_user_status_id)) ? <div style={{color: "red"}} className="form-group">You are not allowed to join the contest, this contest is already full</div> : ''}

            </div>

            <div className="time-info">
                <div className="d-show"><LiveTimer contestStatusChanged={contestStatusChanged} Status={props.contestData.contestDetails.contest_status_id} countDownDate={countDownDate} /></div>
                <p>{contestDate} EST</p>



                {( props.contestData.contestDetails.contest_status_id === 3 && (!props.contestData.contestUserJoinData[0] || !props.contestData.contestUserJoinData[0].lg_contest_id || props.contestData.contestUserJoinData[0].contest_user_status_id == 3 || !props.contestData.contestUserJoinData[0].contest_user_status_id) && props.contestData.contestDetails.max_users > props.contestData.contestDetails.total_lineups) ?
                    <Button variant="success" onClick={nextJoinStep} disabled={enableJoinContest ? '' : 'disabled'}>Join Contest</Button> : null
                }

                {(props.contestData.contestUserJoinData[0] && props.contestData.contestUserJoinData[0].lg_contest_id && [1,2].includes(props.contestData.contestUserJoinData[0].contest_user_status_id)) ?
                    <Button variant="success" onClick={viewLineUpPage}>Manage Line Up</Button> : null
                }

                {(userId == props.contestData.contestDetails.owner_id && props.contestData.contestDetails.contest_status_id === 3) && <Button variant="success" onClick={edituserLeague} style={{ marginTop: "10px" }}>Edit League</Button>}
                {(userId == props.contestData.contestDetails.owner_id && props.contestData.contestDetails.contest_status_id === 3) && <Button variant="danger" onClick={handleDeleteLeauge} style={{ marginTop: "10px", width: "100%", fontSize: "12px" }}>Delete League</Button>}

                <Modal show={showPredictModal} onHide={cancelJoinStep} className="modal-predict" onShow={setFocus}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Predict a Final Tiebreaker</h4>
                        <div className="score-selector">
                            <div className="inputgroup">
                                <KOInput
                                    id="score"
                                    placeholder="Enter the Tiebreaker score"
                                    name="score"
                                    type="text"
                                    maxLength={6}
                                    value={tiebreakerScore}
                                    errorMessage={tieScoreError}
                                    txtRef={refTBS}
                                    onChange={(e) => { handleScoreChange(e); }}
                                />
                                {isValidScore && <span className="ic-tick"></span>}
                            </div>
                            {(availableScores.length > 0) &&
                                <div className="score-list">
                                    <ul className="scroll-custom">
                                            {availableScores.map((item, index) => (
                                                (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                    <li key={'1sBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                    <li key={'2sBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                        (item.val === activeScore ? " active" : "")
                                                    }>{item.val}</li>
                                            ))}
                                    </ul>
                                    {/* <SimpleBar>
                                        <ul>
                                            {availableScores.map((item, index) => (
                                                (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                    <li key={'1UserscoreSBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                    <li key={'2UserscoreSBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                        (item.val === activeScore ? " active" : "")
                                                    }>{item.val}</li>
                                            ))}
                                        </ul>
                                    </SimpleBar> */}
                                </div>
                            }
                        </div>
                        <p className="info">The unique game play of Fantasy Sports Knockout requires tiebreakers to determine which users are eliminated when there is a
                            tie (or multiple ties) in a given week. There are several tiebreakers based on the performance of the user's players prior
                            to this final one. Your score prediction is made once at the beginning of the season and is yours for the season. Please
                            note that the intent is to break ties when your entry can potentially be eliminated and it is not intended to be a correct
                            prediction - you hope to never need it! Check our {FaqLink()} for
                            more information about tiebreakers.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant="primary" onClick={nextJoinStep}>Continue</Button>
                        <Button variant="link" onClick={cancelJoinStep}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfirmModal} onHide={closeContestJoinSteps} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Are you sure you want to join this contest?</h4>
                        <p>By clicking Yes, you agree to the {TermsAndConditions()} </p>
                    </Modal.Body>
                    <Modal.Footer>
                       
                        <Button variant="primary" onClick={nextJoinStep}>Yes</Button>
                        <Button variant="link" onClick={cancelJoinPreviousStep}>Back</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showLeagueDeleteConfirmModal} onHide={deleteConfirmModalClose} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Do you want to delete this league?</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant="primary" onClick={() => removeLeague()} >Yes</Button>
                        <Button variant="link" onClick={deleteConfirmModalClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showCurrentScoreCheckingLoder} onHide={closeScoreCheckingModal} className="modal-predict">
                    <Modal.Body>
                        <div className="score-selector">
                            <div className="loader-wrap"><div className="loader"></div></div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default UsergameContestDetails;
