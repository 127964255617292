import React, { useEffect, useState } from "react";
import { } from "react-bootstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/ko-logo.svg';
import Select from 'react-select';
import SiteGamesIcon from '../assets/icons/sitegames';
import UserGamesIcon from '../assets/icons/usergames';
import '../components/sidemenubar.scss';
import { pageURLs } from "../appData/pageURLs";
import useSiteState from '../appState/siteState/useSiteState';
 
const SideMenubar = (props) => {
    const siteState = useSiteState();

    const statusOptions = [
        { value: "sitegames", label: "Site Games", icon: <SiteGamesIcon /> },
        { value: "usergames", label: "User Games", icon: <UserGamesIcon /> }
    ];

    useEffect(() => {
        if (sessionStorage.getItem('IsUserGame') != null) {
             if (sessionStorage.getItem('IsUserGame') == 'false' || sessionStorage.getItem('IsUserGame') == false) {
                 siteState.data.IsUserGame = false;
            } else { 
                siteState.data.IsUserGame = true;
            }
        }else {
            siteState.data.IsUserGame = false;
        }
    }, []);

    const [IsUserGame, setIsUserGame] = useState(siteState.data.IsUserGame );
    const [propsIsUserGame, setPropsIsUserGame] = useState(props.IsUserGame);

    useEffect(() => {
             setIsUserGame(siteState.data.IsUserGame);
            setPropsIsUserGame(siteState.data.IsUserGame) 
    }, [siteState.data.IsUserGame])
 

    const customSingleValue = ({ data }) => (
        <div className="input-select">
            <div className="input-select__single-value">
                {data.icon && <span className="input-select__icon">{data.icon}</span>}
                <span>{data.label}</span>
            </div>
        </div>
    );

    return (
        <>
            <div className="sidemenubar">
                <div className="logo"><Link to={pageURLs.lobby}><img src={logo} alt="KO Logo" /> </Link> </div>
                <div className="switch-dropdown" id="contestTypeSelection">

                 
                    <Select
                        defaultValue={IsUserGame ? statusOptions[1] : statusOptions[0]}
                        options={statusOptions}
                        components={{ SingleValue: customSingleValue }}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {e.icon}
                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                        )}
                        value={IsUserGame ? statusOptions[1] : statusOptions[0]}
                        onChange={(e) => { props.changeGameState(!propsIsUserGame, e); }}
                    />
                </div>
                <ul className="nav-menu" id="contestMenus">
                    <SideMenuLink to={pageURLs.lobby} label="Lobby" />
                    {propsIsUserGame ?
                       <> 
                        <SideMenuLink to={pageURLs.alluserleague} label="User Leagues" />
                        <SideMenuLink to={pageURLs.manageleague} label="Manage League" />
                        </> : ''
                    }
                    {!propsIsUserGame ?
                        <> 
                        <SideMenuLink to={pageURLs.allcontest} label="Paid Leagues" />
                        </> : ''
                    }
                    {/* <SideMenuLink to={pageURLs.joinedupcoming} label="My Upcoming Contests" />
                    <SideMenuLink to={pageURLs.joinedlive} label="My Live Contests" />
                    <SideMenuLink to={pageURLs.joinedhistory} label="My Contest History" /> */}
                </ul>
            </div>
        </>
    );

};

const SideMenuLink = (props) => {
    const siteState = useSiteState();
    const location = useLocation();
    const navigate = useNavigate();
    let activeMenu = siteState.data.ActiveMenu

    const getClasses = (pageURL) => {
        let viewURL = location.pathname;
        if (pageURL == '/lobby' && activeMenu == 'lobby') {
            return "menu-item active";
        } else if (pageURL == '/joinedupcoming' && activeMenu == 'joinedupcoming') {
            return "menu-item active";
        } else if (pageURL == '/joinedlive' && activeMenu == 'joinedlive') {
            return "menu-item active";
        } else if (pageURL == '/joinedhistory' && activeMenu == 'joinedhistory') {
            return "menu-item active";
        } else if (pageURL == '/manageleague' && activeMenu == 'manageleague') {
            return "menu-item active";
        } else if (pageURL == '/allcontest' && activeMenu == 'allcontest') {
            return "menu-item active";
        } else if (pageURL == '/alluserleague' && activeMenu == 'alluserleague') {
            return "menu-item active";
        } else {
            return "menu-item";
        }
    }

    const pageRedirect = async (page) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                navigate(page);
            }
        } else {
            navigate(page);
        }
    }

    return (
        <li key={'3sideB'}  className={getClasses(props.to)}>
            <a href="javascript:void(0)" onClick={() => { return pageRedirect(props.to) }}>{props.label}</a>
            {/* <Link to={props.to} onClick={() => { return false }}>{props.label}</Link> */}
            </li>
    );
}

export default SideMenubar;
