import React, { useState, useEffect, useRef } from "react";
import { Form, Button ,Modal} from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import * as userController  from "../controller/userController"
import TESVOIdentity from "../components/TESVOIdentity";
import useGeoLocation from "../utils/useGeoLocation";
import useUserState from "../appState/userState/useUserState";
import { checkIpFormat } from '../utils/CommonHelper';

const VerifyIdentity = (props) => {
    const location = useGeoLocation();
    const userState = useUserState();
    const [identitySession, setIdentitySession] = useState("");
    const [stepState, setStepState] = useState("");
    const [cordinates, setCordinates] = useState([]);
    const [showLocationRequiredPopup, setLocationRequiredPopup] = useState(false);
    const [tsevoReconfirmationMessage, settsevoReconfirmationMessage] = useState('Allow the browser to access your location and verify your identity?');
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
    const [tsevoSessionURL, setTsevoSessionURL] = useState("");
    const [isIpisV4, setisIpisV4] = useState(false);
    const navigate = useNavigate();
    const identityCheck = "IdentityCheck";
    let email = userState.data.user.email;

    useEffect( async () => {
        let userData = await userController.getUserData();
        if(userData.data.user_status_id === 1) {
            navigate(pageURLs.lobby);
        }
       
        if (typeof userData.data.user !== 'undefined' && (userData.data.user.login_mode_id == 2 || userData.data.user.login_mode_id ==3)) {
            email = userState.data.user.email;
        }
      
        if (typeof userData.data.user !== 'undefined' && (userData.data.user.user_full_name === "" || userData.data.user.user_full_name === null)) {
           navigate(pageURLs.Verificationstart);
        }
    }, []);
   
    

    if(!email || typeof email === 'undefined') {
        navigate(pageURLs.lobby);
    }

    const tsevoVerification = async (cordinatesData) => {
        if(typeof cordinatesData !== 'undefined' && cordinatesData.latitude ) {
            await userController.getUserIp()
            .then(async function(ipdata) {
                let customerIp = (ipdata.data) ? ipdata.data.IPv4 : null;
                let response = await userController.createIdentitySession(customerIp,email, cordinatesData);
                if (response.data && response.data.sessionID != null) {
                    setIdentitySession(response.data.sessionID);
                    setStepState(identityCheck);
                    let isIpv4 = checkIpFormat(customerIp);
                    setisIpisV4(isIpv4)
                    setTsevoSessionURL(response?.data?.sessionUrl)
                } else {
                   navigate(pageURLs.verificationfailed);
                }
            }).catch(err => {
               navigate(pageURLs.verificationfailed);
            });
        }
    }

    const enableLocationPopup = () => {
        setLocationRequiredPopup(false)
    }

    useEffect(() => {
        if(location.loaded && !location.errorFlag && !identitySession) {
            tsevoVerification(location.coordinates)
        } else  if(location.loaded && location.errorFlag && !identitySession) {
            Locationerror()
        }
    }, [location]);
	
    const cancelLocationRequiredPopup = () => {
        navigate(pageURLs.verificationfailed);
    }
    
    const Locationerror = async (err) => {
        setLocationRequiredPopup(true);
    };

    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.verificationstart} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft verification-container">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>

                        <h5>Let's Get Started!</h5>
                        <div className="form-block">
                            { (stepState !== identityCheck) &&
                                <div className="loader-wrap"><div className="loader"></div></div>
                            }
                            <div className="tsevo-container">
                                    { (stepState === identityCheck) &&
                                    <>
                                    <TESVOIdentity sessionID={identitySession} tsevoSessionURL = {tsevoSessionURL} isIpv4= {isIpisV4}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                   
                </div>

                <Modal show={showLocationRequiredPopup} onHide={cancelLocationRequiredPopup} className="modal-predict tsevo-flow">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">{tsevoReconfirmationMessage}</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={enableLocationPopup}>Allow</Button>
                        <Button variant="primary" onClick={cancelLocationRequiredPopup}>Don't Allow</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </InnerPageWrap>
    );
};

export default VerifyIdentity;
