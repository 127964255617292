import React from "react";


const GridIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="grid-path" data-name="Union 3" d="M-1192,459a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Zm1-2h5v-5h-5Zm-12,2a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Zm1-2h5v-5h-5Zm10-9a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Zm1-2h5v-5h-5Zm-12,2a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v7a1,1,0,0,1-1,1Zm1-2h5v-5h-5Z" transform="translate(1204 -439)" fill="#555555"/>
        </svg>

    );

}

export default GridIcon;