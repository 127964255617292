import React, { useState, useEffect, useRef } from "react";
import { Button, Tab, Tabs, Offcanvas, ToastContainer, Toast, Table } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import UsergameContestDetails from "../components/UsergameContestDetails";
import InnerPageWrap from "../components/InnerPageWrap";
import KOSearchInput from "../components/KOSearchInput";
import KOTextarea from "../components/KOTextarea";
import TickIcon from "../assets/icons/tickicon";
import Paginate from "../components/Paginate";
import "../styles/joincontest.scss";
import { pageURLs } from "../appData/pageURLs";
import { useParams, useNavigate } from 'react-router-dom'
import * as contestService from "../services/contestService";
import { store } from '../appState/store';
import userpic from '../assets/user-default.png';

const LeagueDetails = (props) => {
    const userData = store.getState().user
    const [showLeagueMessageSec, setLeagueMessagesec] = useState(false);
    const handleShowMessagesec = () => setLeagueMessagesec(true);
    const [showtoast, setShowToast] = useState(false);
    const [contestData, setContestData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [leagueInviteeList, setLeagueInviteeList] = useState([]);
    const [inviteeListLoader, setinviteeListLoader] = useState(true);
    const [leagueParticipantsList, setLeagueParticipantsList] = useState([]);
    const [participantListLoader, setParticipantListLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState('participants');
    const [searchedData, setSearchedData] = useState('');
    const [leagueMessage, setLeagueMessage] = useState("");
    const [leagueMessageError, setLeagueMessageError] = useState("");
    const refLeagueMessage = useRef(null);
    const userId = userData.user.user_id;
    const [InviteePaginationData, setInviteePaginationData] = useState([]);
    const [ParticipantsPaginationData, setParticipantsPaginationData] = useState([]);
    const [inviteePageNumber, setInviteePageNumber] = useState(1);
    const [participantsPageNumber, setParticipantsPageNumber] = useState(1);
    const [totalInvitationCount, settotalInvitationCount] = useState(0);
    const [totalParticipantsCount, settotalParticipantsCount] = useState(0);
    const navigate = useNavigate();
    let { id } = useParams()
    id = parseInt(id);

    useEffect(async () => {
        await contestService.getUsergameContest(id)
            .then(async function (reponse) {
                if (reponse.errorCode == 200) {
                    if (reponse.data.contestDetails) {
                        setContestData(reponse.data);
                        await updatePlayersCardDisplay(reponse.data.lineuStrategy)
                        //Fetching invitee list
                        let searchOptions = {
                            'search_key': searchedData ? encodeURI(searchedData) : null,
                            'page': 1
                        }
                        await setInviteeList(searchOptions);
                        await setParticipantList(searchOptions);
                    } else {
                        navigate(pageURLs.manageleague)
                    }
                } else {
                    navigate(pageURLs.manageleague)
                }

            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);

    // Tab changes
    const changeTabs = (tabItem) => {
        setSelectedTab(tabItem);
    }

    //Searching the participants/invitee
    const searchParticipantsList = async (e) => {
        setSearchedData(e.target.value)
        let searchOptions = {
            'search_key': e.target.value ? encodeURI(e.target.value) : null,
            'page': 1
        }
        if (selectedTab == 'participants') {
            await setParticipantList(searchOptions)
        } else {
            await setInviteeList(searchOptions)
        }
    }

    //Common function for fetching Invitee List API
    const setInviteeList = async (searchOptions = []) => {
        setinviteeListLoader(true);
        setLeagueInviteeList([]);
        await contestService.getUsergameInviteeList(id, searchOptions)
            .then(inviteeResponse => {
                if (inviteeResponse.errorCode == 200) {
                    setLeagueInviteeList(inviteeResponse.data.result);
                    const InviteePaginationDataArray = {
                        perPage: inviteeResponse.data.perPage ? inviteeResponse.data.perPage : 5,
                        totalPages: inviteeResponse.data.totalPages ? inviteeResponse.data.totalPages : 0,
                        total: inviteeResponse.data.total ? inviteeResponse.data.total : 0,
                        currentPage: inviteeResponse.data.currentPage ? inviteeResponse.data.currentPage : 0,
                        errorCode: inviteeResponse.errorCode ? inviteeResponse.errorCode : 0,
                    }
                    setInviteePaginationData(InviteePaginationDataArray);
                    settotalInvitationCount(InviteePaginationDataArray.total)
                } else {
                    setLeagueInviteeList([]);
                    settotalInvitationCount(0)
                }
            });
        setinviteeListLoader(false);
    }

    //Common function for fetching Participation List API
    const setParticipantList = async (searchOptions = []) => {
        setParticipantListLoader(true);
        setLeagueParticipantsList([]);
        settotalParticipantsCount(0)
        await contestService.getUsergameParticipantsList(id, searchOptions)
            .then(inviteeResponse => {
                if (inviteeResponse.errorCode == 200) {
                    setLeagueParticipantsList(inviteeResponse.data.result);
                    const ParticipantsPaginationDataArray = {
                        perPage: inviteeResponse.data.perPage ? inviteeResponse.data.perPage : 5,
                        totalPages: inviteeResponse.data.totalPages ? inviteeResponse.data.totalPages : 0,
                        total: inviteeResponse.data.total ? inviteeResponse.data.total : 0,
                        currentPage: inviteeResponse.data.currentPage ? inviteeResponse.data.currentPage : 0,
                        errorCode: inviteeResponse.errorCode ? inviteeResponse.errorCode : 0,
                    }
                    setParticipantsPaginationData(ParticipantsPaginationDataArray);
                    settotalParticipantsCount(ParticipantsPaginationDataArray.total)
                } else {
                    setLeagueParticipantsList([]);
                }
            });
        setParticipantListLoader(false);
    }


    //Sending league messages
    const sendLeagueMessage = async (e) => {
        let isValid = true;
        let focusSet = false;
        setLeagueMessageError("");
        if (leagueMessage.trim() === "") {
            isValid = false;
            setLeagueMessageError("Enter your message.");
            if (!focusSet) {
                refLeagueMessage.current.focus();
                focusSet = true;
            }
        }

        if (isValid) {
            await contestService.sendNotificationMessage({
                league_id: id, message_content: leagueMessage
            })
                .then(inviteeResponse => {
                    if (inviteeResponse.errorCode == 200) {
                        setLeagueMessage('');
                        handleCloseMessagesec();
                        setShowToast(true)
                    }
                });
        }
    }

    const getParticipanstCotinerClass = (participantUser, contest_user_status_id) => {
        let result = "standing-card participant-card ";
        if (participantUser === userId) {
            result += " my-position";
        }
        if (contest_user_status_id === 2) {
            result += " contest-eliminate";
        }
        return result;
    }

    const getRandomNum = (x, y) => {
        return Math.floor(x + (y - x) * Math.random());

    }
    const getStandings = (contestId, userID, userType) => {
        navigate(pageURLs.usergamecreatelineup + '/' + contestId+'/standings' )
    }

    const updatePlayersCardDisplay = async (lineupStrategy) => {
        let playersCard = [];
        let index = 1;
        let maximumLineUpCard = 0;
        if (lineupStrategy) {

            let total_qb = parseInt(lineupStrategy.total_qb);
            let total_rb = parseInt(lineupStrategy.total_rb);
            let total_wr = parseInt(lineupStrategy.total_wr);
            let total_te = parseInt(lineupStrategy.total_te);
            let total_fl = parseInt(lineupStrategy.total_fl);
            let total_ki = parseInt(lineupStrategy.total_ki);
            let total_df = parseInt(lineupStrategy.total_df);

            maximumLineUpCard = total_qb + total_rb + total_wr + total_te + total_fl + total_ki + total_df;
            for (let i = 0; i < total_qb; i++) {

                let qbList = { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: index, placePosition: "quarterback" + index , positionshortname : 'QB'}
                index++;
                playersCard.push(qbList);
            }

            for (let i = 0; i < total_rb; i++) {

                let rbList = { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: index, placePosition: "runningback" + index , positionshortname : 'RB' }
                index++;
                playersCard.push(rbList);
            }

            for (let i = 0; i < total_wr; i++) {
                let wrList = { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: index, placePosition: "widereceiver" + index, positionshortname : 'WR' }
                index++;
                playersCard.push(wrList);
            }

            for (let i = 0; i < total_te; i++) {
                let teList = { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: index, placePosition: "tightend" + index , positionshortname : 'TE'}
                index++;
                playersCard.push(teList);
            }

            for (let i = 0; i < total_fl; i++) {
                let flList = { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: index, placePosition: "flexplayer" + index, positionshortname : 'FL' }
                index++;
                playersCard.push(flList);
            }

            for (let i = 0; i < total_ki; i++) {
                let kiList = { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: index, placePosition: "kicker" + index , positionshortname : 'K'}
                index++;
                playersCard.push(kiList);
            }

            for (let i = 0; i < total_df; i++) {
                let dfList = { className: "defence", playername: "", figure: "", score: "", position: "DST", dataIndex: index, placePosition: "defence" + index, positionshortname : 'DF' }
                index++;
                playersCard.push(dfList);
            }
            sessionStorage.setItem('default_player_card', JSON.stringify(playersCard));
            
        } else {
            maximumLineUpCard = 10;
            playersCard = [
                { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: "1", placePosition: "quarterback1" },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "2", placePosition: "runningback1" },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "3", placePosition: "runningback2" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "4", placePosition: "widereceiver1" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "5", placePosition: "widereceiver2" },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "6", placePosition: "widereceiver3" },
                { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: "7", placePosition: "tightend1" },
                { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: "8", placePosition: "flexplayer1" },
                { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: "9", placePosition: "kicker1" },
                { className: "defence", playername: "", figure: "", score: "", position: " DST", dataIndex: "10", placePosition: "defence1" }
            ];
            sessionStorage.setItem('default_player_card', JSON.stringify(playersCard));
        }

    }

    //Invitee list display
    const inviteeListing = () => {
        return (
            <div className="standing-card ">
                <Table responsive>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Full Name</th>
                            <th>Username</th>
                            <th>Email Id</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            leagueInviteeList.map((item, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="player-info">
                                                <figure>{item.profile_img_path && item.profile_img_path != "" ? <img src={item.profile_img_path} alt={item.invitee_email} /> : <img src={userpic} alt={item.invitee_email} />}</figure>
                                            </div>
                                        </td>
                                        <td>
                                            <div  className="info-data">
                                                <div className="name">{(item.user_full_name) ? item.user_full_name : '-'}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="info-data">
                                                <p>{item.user_name ? item.user_name : '-'}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="info-data">
                                                <p>{item.invitee_email ? item.invitee_email : '-'}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="standing-stats" >
                                                {(item.contest_user_status_id == 1 || item.contest_user_status_id == 2) ?<div className="standing-stats"><Button variant="primary" className="btn-standing" style={{ margin: "5px" }} >Joined</Button></div> : ''}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        );
    }

    //Participants list display
    const participantsListing = () => {
        return leagueParticipantsList.map((item, index) => (
            <div className={getParticipanstCotinerClass(item.user_id, item.contest_user_status_id)}>
                <div className="player-info">
                    <figure>{item.profile_img_path ? <img src={item.profile_img_path} alt={item.invitee_email} /> : <img src={userpic} alt={item.invitee_email} />}</figure>
                    <div className="name">
                        {userId == item.user_id && <span class="you-label">You</span>}
                        {(item.user_full_name) ? item.user_full_name : ''}
                    </div>
                </div>
                <div className="standing-stats"><Button variant="primary" className="btn-standing" onClick={() => getStandings(id, item.user_id, (userId == item.user_id ? true : false))}>Standings</Button></div>
            </div>
        ));
    }

    const participantPageNumberChange = async (page) => {
        setParticipantsPageNumber(page);
        let searchOptions = {
            'search_key': searchedData ? encodeURI(searchedData) : null,
            'page': page
        }
        await setParticipantList(searchOptions);
    }

    const inviteePageNumberChange = async (page) => {
        setInviteePageNumber(page)
        let searchOptions = {
            'search_key': searchedData ? encodeURI(searchedData) : null,
            'page': page
        }
        await setInviteeList(searchOptions);
    }
    const redirectToViewLeaders = (e) => {
        navigate(pageURLs.usergamecreatelineup + '/' + id+'/standings' )
    }
    
    const handleCloseMessagesec = () => {
        setLeagueMessagesec(false);
        setLeagueMessageError("");
    }
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.leaguedetails} /></div>
                <div className="kodata-content">
                    <div className="joincontest-container">
                        <div className="contest-detail-wrap">
                            {contestData && <UsergameContestDetails contestData={contestData} />}
                        </div>
                        <div className="league-entry-wrap">
                            <div className="entry-head">
                                <h6>Entries</h6>
                                <div className="title-action">
                                    <Button variant="outline-primary" onClick={redirectToViewLeaders}>View Leaders</Button>
                                    <Button variant="outline-primary" onClick={handleShowMessagesec}><i className="ic-horn"></i></Button>
                                </div>
                            </div>
                            <div className="participant-tab-wrap">
                                <div className="searchbox">
                                    <KOSearchInput
                                        id="searchfield"
                                        placeholder="Search Participants"
                                        name="search"
                                        type="text"
                                        value={searchedData}
                                        onChange={(k) => searchParticipantsList(k)}
                                    />
                                </div>
                                <Tabs
                                    defaultActiveKey="participants"
                                    transition={false}
                                    id="entry-tab"
                                    className="participant-tab"
                                    onSelect={(k) => changeTabs(k)}
                                >
                                    <Tab eventKey="participants" title={"Participants (" + totalParticipantsCount + ")"} >
                                        <div className="league-entry-data">
                                            <div className="player-standings">
                                                {participantsListing()}
                                                {leagueParticipantsList.length ? <Paginate paginationData={ParticipantsPaginationData} pageRows={leagueParticipantsList.length} onClick={participantPageNumberChange} /> : ""}
                                                {participantListLoader && <div className="standing-card quarterback"><div className="loader-wrap"><div className="loader"></div></div></div>}
                                                {(!participantListLoader && !leagueParticipantsList.length) && <div class="vertical-center-position"><div class="row"><span style={{ color: "red" }}>No records to display.</span></div></div>}
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="invited" title={"Invited (" + totalInvitationCount + ")"}>
                                        <div className="league-entry-data">
                                            <div className="player-standings">
                                                {inviteeListing()}
                                                {leagueInviteeList.length ? <Paginate paginationData={InviteePaginationData} pageRows={leagueInviteeList.length} onClick={inviteePageNumberChange} /> : ""}
                                                {inviteeListLoader && <div className="standing-card quarterback"><div className="loader-wrap"><div className="loader"></div></div></div>}
                                                {(!inviteeListLoader && !leagueInviteeList.length) && <div class="vertical-center-position"><div class="row"><span style={{ color: "red" }}>No records to display.</span></div></div>}

                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        {contestData && <Offcanvas show={showLeagueMessageSec} onHide={handleCloseMessagesec} placement="end" className="custom-sidebar change-username">
                            <Offcanvas.Header closeButton></Offcanvas.Header>
                            <Offcanvas.Body>
                                <h5>Create a Message</h5>
                                <div className="league-name">
                                    <p>League Name</p>
                                    <h5>{contestData.contestDetails.contest_name}</h5>
                                </div>
                                <div className="sidebar-body">
                                    <KOTextarea
                                        id="reason"
                                        label="Message Content"
                                        name="reason"
                                        type="text"
                                        placeholder="Enter your message"
                                        maxLength="500"
                                        value={leagueMessage}
                                        txtRef={refLeagueMessage}
                                        onChange={(e) => { setLeagueMessage(e.target.value); }}
                                        errorMessage={leagueMessageError}
                                    />
                                    <div className="btn-sec">
                                        {/* <Button variant="primary" onClick={() => setShowToast(true)}>Send Message</Button> */}
                                        <Button variant="primary" onClick={() => { sendLeagueMessage() }}>Send Message</Button>
                                    </div>
                                </div>

                            </Offcanvas.Body>
                        </Offcanvas>}

                        <ToastContainer position="top-end">
                            <Toast className="toast-success" onClose={() => setShowToast(false)} show={showtoast} delay={3000} autohide>
                                <Toast.Header>
                                    <p>Message Sent</p>
                                    <TickIcon />
                                </Toast.Header>
                                <Toast.Body>Your message has been sent to all entries successfully.</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );
};

export default LeagueDetails;
