import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Offcanvas, Form, Modal } from "react-bootstrap";
import KOInput from "../components/KOInput";
import * as commonService from "../services/commonService";
import * as userGamesController from "../controller/userGamesController";
import { useNavigate } from 'react-router-dom';
import { pageURLs } from "../appData/pageURLs";
import useUserState from "../appState/userState/useUserState";
import SimpleBar from 'simplebar-react';
import { FaqLink, TermsAndConditions } from '../utils/CommonHelper';
import {store} from '../appState/store';


const CreateLeagueStep4 = (props) => {
    const user = useUserState();
    const userData = store.getState().user
    const editAction = props.editAction;
	const [show4, setShow4] = useState(true);
    const [inviteEmail, setInviteEmail] = useState("");
    const [invitedEmailList, setInvitedEmailList] = useState([]);
    const [inviteEmailAdd, setInviteEmailAdd] = useState([]);
    const [inviteEmailError, setInviteEmailError] = useState("");
    const [showDeleteEmailConfirmModal, setDeleteEmailConfirmModal] = useState(false);
    const [deleteEmailIndexValue, setDeleteEmailIndexValue] = useState('');
    const [defaultLineupExist, setDefaultLineupExist] = useState(false);
    const deleteEmailConfirmModalClose = () => setDeleteEmailConfirmModal(false);
    const deleteEmailConfirmModalShow = () => setDeleteEmailConfirmModal(true);
    const [tiebreakerScore, setTiebreakerScore] = useState("");
    const [availableScores, setAvailableScores] = useState([]);
    const [showPredictModal, setPredictModal] = useState(false);
    const [tieScoreError, setTieScoreError] = useState("");
    const [activeScore, setSctiveScore] = useState("");
    const [existingTiebreakerScore, setExistingTiebreakerScore] = useState([]);
    const [isValidScore, setIsValidScore] = useState(false);
    const refInviteEmail = useRef(null);
    const contest_id = props.contest_id;
    const navigate = useNavigate();
    const refTBS = useRef(null);
    const [disableInviteButton, SetdisableInviteButton] = useState(false);
    const [showLoader, SetShowLoader] = useState(false);

    useEffect( async() => {
        let response = await userGamesController.fetchInvitedEmails(contest_id);
        if(response.errorCode === 200 && response.data.invitedEmails) {
            if(response.data.invitedEmails.length) {
                setInvitedEmailList(response.data.invitedEmails)
            } else {
                setInvitedEmailList([])
            }
            if(response.data.defaultLineUpCreated > 0) {
                setDefaultLineupExist(true)
            } else {
                setDefaultLineupExist(false)
            }
        } else {
            setInvitedEmailList([]);
            setDefaultLineupExist(false)
        }
    },[])
    
    const addInviteMailQueue = async() => {
        let isValid = true;
        setInviteEmailError("");
        if (inviteEmail === "") {
            isValid = false;
            setInviteEmailError("Please enter email.");
            refInviteEmail.current.focus();
        } else if (!commonService.emailValidation(inviteEmail)) {
            isValid = false;
            setInviteEmailError("Invalid email address")
            refInviteEmail.current.focus();
        } else if (inviteEmailAdd.length >= process.env.REACT_APP_INVITE_FRIENDS_EMAIL_LIMIT) {
            isValid = false;
            setInviteEmailError(`Only ${process.env.REACT_APP_INVITE_FRIENDS_EMAIL_LIMIT} invites can be send at a time.`);
            refInviteEmail.current.focus();
        } else if (inviteEmailAdd.indexOf(inviteEmail) !== -1) {
            isValid = false;
            setInviteEmailError("This email address is already added");
            refInviteEmail.current.focus();
        } else if(invitedEmailList.includes(inviteEmail)){
            isValid = false;
            setInviteEmailError("You have already invited this user");
            refInviteEmail.current.focus();
        }else if(inviteEmail === userData.user.email){
            isValid = false;
            setInviteEmailError("This is your registered email address. Please enter your invitee's email address.");
            refInviteEmail.current.focus();
        }
        if (isValid) {
            setInviteEmailAdd(prevState => [...prevState, inviteEmail])
            setInviteEmail("")
        }
        return isValid;
    };
    const deleteInviteEmail = async(index) => {
        let newInvitedArray = [...inviteEmailAdd]
        newInvitedArray.splice(index,1);
        setInviteEmailAdd(newInvitedArray);
        deleteEmailConfirmModalClose();
    };

    const handleInviteFriendsEmailChange = (e) => {
        setInviteEmailError("");
        setInviteEmail(e.target.value.toLowerCase())
    };

    const inviteFriendsEmail = async () => {
        setTieScoreError('')
        let requestPayload = {
            "step": 4,
            "lg_contest_id": parseInt(contest_id),
            "emails": inviteEmailAdd,
            "editAction": editAction,
            "predicted_tie_score" : tiebreakerScore
        };
        
        // if(!defaultLineupExist ) {
        //     if(!commonService.isNumber(tiebreakerScore)) {
        //         setTieScoreError('Enter valid tiebreaker score')
        //         return false;
        //     }
        // }
        
        if (inviteEmailAdd.length !== 0 || editAction) {
            setInviteEmailError('');
            SetdisableInviteButton(true);
            SetShowLoader(true)
            let response = await userGamesController.saveUserGames(requestPayload);
            if (response.errorCode === 200) {
                setPredictModal(false)
                SetShowLoader(false)
                setShow4(false);
                props.sendInviteSuccess();
                props.updateLeagueContestId(0)
                props.updateFullUserGameData(requestPayload, 3);
                user.action.getUserData();
            } else {
                setInviteEmailError(response.message);
                SetShowLoader(false)
            }
        }
        else setInviteEmailError("Enter at least one email address for sending the invitation.")
    };

    const setFocus = () => {
        refTBS.current.focus();
    }

    const cancelInviteStep = () => {
        setPredictModal(false);
        setAvailableScores([]);
    }

    const handleScoreChange = (e) => {
        let scoreValue = e.target.value;
        let scoreValueArray = scoreValue.split(".");
        if (scoreValueArray.length > 2) {
            scoreValue = scoreValueArray[0] + '.' + scoreValueArray[1];
            scoreValue = parseFloat(scoreValue);
        }
        if (scoreValue === "") {
            setTiebreakerScore(scoreValue);
            setAvailableScores([]);
        } else if (!commonService.isNumber(scoreValue)) {
            setAvailableScores([]);
            if (parseFloat(scoreValue).toFixed(2)) {
                if (!isNaN(scoreValue) && commonService.isFloat(parseFloat(scoreValue).toFixed(2))) {
                    setScoreList(scoreValue, 100.12);
                    var res = scoreValue.split(".");
                    setTiebreakerScore(scoreValue);
                } else {
                    setTiebreakerScore("");
                }
            }
        } else {
            if (scoreValue < 1000) {
                setTiebreakerScore(scoreValue);
                setScoreList(scoreValue, 100.12);
            }
        }
        setTieScoreError('')
    }

    const setScoreList = (start, selected) => {
        let i;
        let scoreList = []
        let startVal = start;
        let displayVal;
        let selectedVal = parseFloat(selected).toFixed(2);
        for (i = 0; i < 18; i++) {
            startVal = parseFloat(startVal) + 0.01;
            displayVal = parseFloat(startVal).toFixed(2);
            if (displayVal === selectedVal) {
                scoreList.push({ "val": displayVal, "isActive": true });
            } else {
                scoreList.push({ "val": displayVal, "isActive": false });
            }
        }
        setAvailableScores(scoreList);
    };

    const handleSelectAvaliableScore = (e) => {
        setTiebreakerScore(e.target.getAttribute('name'));
        setSctiveScore(e.target.getAttribute('name'));
    }
    const predictedScorePopup = (e) => {
        if (inviteEmailAdd.length !== 0 || editAction) {
            setPredictModal(true)
        }  else {
            setInviteEmailError("Enter at least one email address for sending the invitation.")
        }
    }
    
	return (
		<>
            <div className="field-container invite-container" style={{ display: show4 ? "block" : "none" }} >
                <div className="invite-details">
                    <h4>Invite your friends</h4>
                    <div className="email-invite" >
                        <label>Email address</label>
                         {showLoader ? <div className="loader-wrap"><div className="loader"></div></div> : ''}
                        <div className="email-group" style={{height:"50px"}}>
                        <KOInput
                            id="email"
                            placeholder="Enter the email address"
                            onChange={(e) => { handleInviteFriendsEmailChange(e); }}
                            value={inviteEmail}
                            txtRef={refInviteEmail}
                            name="email"
                            type="text"
                            errorMessage={inviteEmailError}
                        />
                        <Button variant="primary" onClick={addInviteMailQueue} id="addInvite">Add to Queue</Button>
                        </div>
                    </div>
                    {inviteEmailAdd.length > 0 && <h5>Invitation Queue ({inviteEmailAdd.length})</h5>}
                    <div className="invite-queue">
                        {inviteEmailAdd.length > 0 &&
                        inviteEmailAdd.map((items, index) => (
                            <div className="member-mail" id ={"deleteEmail_div_"+index} key={items}>
                                <p >{items}</p>
                                <Button variant="light"  onClick={()=>(deleteEmailConfirmModalShow(),setDeleteEmailIndexValue(index))} id="removeInvite"><i className="ic-delete"></i></Button>
                                <Modal show={showDeleteEmailConfirmModal} onHide={deleteEmailConfirmModalClose} className="modal-predict">
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <h4 className="modal-title">Do you want to remove the email address from the queue?</h4>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={() => deleteInviteEmail(deleteEmailIndexValue)}>Yes</Button>
                                        <Button variant="link" onClick={deleteEmailConfirmModalClose}>Cancel</Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        ))}

                        {(invitedEmailList.length > 0) && <h5 style={{paddingTop: "15px"}}>Invited List ({invitedEmailList.length})</h5>}
                        {(invitedEmailList.length > 0) &&
                            invitedEmailList.map((items, index) => (
                                <div className="member-mail" key={"invited_item_"+items}>
                                    <p >{items}</p>
                                </div> 
                        ))}
                           
                        {defaultLineupExist ? <Button variant="primary" onClick={inviteFriendsEmail} disabled={!disableInviteButton ? '' : 'disabled'}>Invite and Publish</Button> : <Button variant="primary" onClick={inviteFriendsEmail}>Invite and Publish</Button>}


                        <Modal show={showPredictModal} onHide={cancelInviteStep} className="modal-predict" onShow={setFocus}>
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <h4 className="modal-title">Predict a Final Tiebreaker</h4>
                                    {showLoader ? <div className="loader-wrap"><div className="loader"></div></div> : ''}
                                    <div className="score-selector">
                                        <div className="inputgroup">
                                            <KOInput
                                                id="score"
                                                placeholder="Enter the Tiebreaker Score"
                                                name="score"
                                                type="text"
                                                maxLength={6}
                                                value={tiebreakerScore}
                                                errorMessage={tieScoreError}
                                                txtRef={refTBS}
                                                onChange={(e) => { handleScoreChange(e); }}
                                            />
                                            {isValidScore && <span className="ic-tick"></span>}
                                        </div>
                                        {(availableScores.length > 0) &&
                                            <div className="score-list">
                                                <SimpleBar>
                                                    <ul>
                                                        {availableScores.map((item, index) => (
                                                            (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                                <li key={'1sBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                                <li key={'2sBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                                    (item.val === activeScore ? " active" : "")
                                                                }>{item.val}</li>
                                                        ))}
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        }
                                    </div>
                                    <p className="info">The unique game play of Fantasy Sports Knockout requires tiebreakers to determine which users are eliminated when there is a
                                        tie (or multiple ties) in a given week. There are several tiebreakers based on the performance of the user's players prior
                                        to this final one. Your score prediction is made once at the beginning of the season and is yours for the season. Please
                                        note that the intent is to break ties when your entry can potentially be eliminated and it is not intended to be a correct
                                        prediction - you hope to never need it! Check our {FaqLink()} for
                                        more information about tiebreakers.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-primary" onClick={cancelInviteStep}>Cancel</Button>
                                    <Button variant="primary" onClick={inviteFriendsEmail} disabled={!disableInviteButton ? '' : 'disabled'}>Continue</Button>
                                </Modal.Footer>
                            </Modal>
                    </div>
                </div>
            </div>
		</>
	);

};

export default CreateLeagueStep4;