import * as userService from "../services/userService";


export const userSignUp = async (userDetails) => {
  const response = await userService.signUp(userDetails)
  return response;
}

export const userAddress = async (addressDetails) => {
  const response = await userService.userAddress(addressDetails)
  return response;
}

export const generateForgetPasswordOtp = async (email) => {
  const response = await userService.generateForgetPasswordOtp(email)
  return response;
}
export const emailValidation = async (OTP, email) => {
  const response = await userService.emailValidation(OTP, email)
  return response;
}

export const resendOTP = async (email) => {
  const response = await userService.resendOTP(email)
  return response;
}

export const resetPassword = async (payload) => {
  const response = await userService.resetPassword(payload)
  return response;
}

export const confirmUserIdentity = async (firstname, lastname, email, verification_status) => {
  const response = await userService.confirmUserIdentity(firstname, lastname, email, verification_status)
  return response;
}

export const checkIdentityFromTsevo = async (requestData) => {
  const response = await userService.checkIdentityFromTsevo(requestData)
  return response;
}

export const getUserDetailsByEmail = async (email, update_tesvo_customer_id = false) => {
  const response = await userService.getUserDetailsByEmail(email, update_tesvo_customer_id)
  return response;
}

// export const getUserIp = async () => {
//   let response;
//   try{
//     response = await userService.getUserIp();
//     if(response === false || response.data.IPv4 == 'Not found') {
//       if(response === false){
//         response = {"data" : {}};
//       }
//       let ipV64Reponse = await userService.getUserIpFromApi64();
//       if(!ipV64Reponse.data.ip) {
//         let ipVReponse = await userService.getUserIpFromApi();
//         if(ipVReponse.data.ip) {
//           response.data.IPv4 = ipVReponse.data.ip;
//         }
//       } else {
//         response.data.IPv4 = ipV64Reponse.data.ip;
//       }
//     } else if(!response.data) {
//       let ipV64Reponse = await userService.getUserIpFromApi64();
//       if(!ipV64Reponse.data.ip) {
//         let ipVReponse = await userService.getUserIpFromApi();
//         if(ipVReponse.data.ip) {
//           response.data.IPv4 = ipVReponse.data.ip;
//         }
//       } else {
//         response.data.IPv4 = ipV64Reponse.data.ip;
//       }
//     }
//   } catch (err) {
//   }
//   return response;
// }

export const checkUserProfileStausTsevo = async (email) => {
  const response = await userService.checkUserProfileStausTsevo(email)
  return response;
} 

export const saveTsevoSessionData = async (tsevoCustomerId, sessionID) => {
  const response = await userService.saveTsevoSessionData(tsevoCustomerId, sessionID)
  return response;
}


export const updateUsername = async (payload) => {
  const response = await userService.updateUsername(payload)
  return response;
}

export const updateAddress = async (addressDetails) => {
  const response = await userService.updateAddress(addressDetails)
  return response;
}
export const inviteFriends = async (payload) => {
  const response = await userService.inviteFriends(payload)
  return response;
}

export const updatePassword = async (payload) => {
  const response = await userService.updatePassword(payload)
  return response;
}

export const verifyUserEmail = async (payload) => {
  const response = await userService.verifyUserEmail(payload)
  return response;
}

export const verifyEmailChangeOTP = async (payload) => {
  const response = await userService.verifyEmailChangeOTP(payload)
  return response;
}
export const updateUserEmail = async (payload) => {
  const response = await userService.updateUserEmail(payload)
  return response;
}
export const updateUserProfilePic = async (payload, userId) => {
  const response = await userService.updateUserProfilePic(payload, userId)
}

export const updateUserPreferences = async (payload) => {
  const response = await userService.updateUserPreferences(payload)
  return response;
}

export const checkLocationFromTsevo = async (customerIp, locationData) => {
  const response = await userService.checkLocationFromTsevo(customerIp, locationData)
  return response;
}

export const createIdentitySession = async (customerIp, email, coords) => {
  let latitude = coords.latitude ? coords.latitude : null;
  let longitude = coords.longitude ? coords.longitude : null;
  if(latitude || longitude) {
    const response = await userService.createIdentitySession(customerIp, email, coords)
    return response;
  }
}

export const fetchWalletSplitup = async () => {
  const response = await userService.fetchWalletSplitup()
  return response;
}


export const  setContestLimits = async (payload) => {
  const response = await userService.setContestLimits(payload)
  return response;
}

export const getContestLimits = async () => {
  const response = await userService.getContestLimits()
  return response;
}

export const userCheck = async (userData) => {
  const response = await userService.userCheck(userData)
  return response;
}

export const updateUserNames = async (userData) => {
  const response = await userService.updateUserNames(userData)
  return response;
}

export const getUserData = async () => {
  const response = await userService.getUserData()
  return response;
}


export const getUserIp = async () => {
  let response;
  try{
    response = await userService.getUserIpFromApi64();
    
    if(!response || !response?.data?.ip) {
      let ipVReponse = await userService.getUserIpFromApi();
      if(ipVReponse?.data?.ip) {
        response.data.IPv4 = ipVReponse.data.ip;
      }
    } else {
      response.data.IPv4 = response.data.ip;
    }
    
    if(!response || !response.data) {
      response = await userService.getUserIp();
      if(response === false || response.data.IPv4 == 'Not found') {
        if(response === false){
          response = {"data" : {}};
        }
        
      }
    }
  } catch (err) {
  }
  //response.data.IPv4 = '2600:1001:a104:6849:3fbd:33dc:fd7d:83eb'
  return response;
}