import React from "react";


const ListIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
            <g id="list" transform="translate(-2 -5)">
                <path id="list-path" data-name="Path 10103" d="M21,7H8A1,1,0,0,1,8,5H21a1,1,0,0,1,0,2Z" fill="#555555" />
                <path id="list-path" data-name="Path 10104" d="M21,13H8a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" fill="#555555" />
                <path id="list-path" data-name="Path 10105" d="M21,19H8a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" fill="#555555" />
                <path id="list-path" data-name="Path 10106" d="M3.01,7H3A1,1,0,0,1,3,5h.01a1,1,0,0,1,0,2Z" fill="#555555" />
                <path id="list-path" data-name="Path 10107" d="M3.01,13H3a1,1,0,0,1,0-2h.01a1,1,0,0,1,0,2Z" fill="#555555" />
                <path id="list-path" data-name="Path 10108" d="M3.01,19H3a1,1,0,0,1,0-2h.01a1,1,0,0,1,0,2Z" fill="#555555" />
            </g>
        </svg>
    );

}

export default ListIcon;