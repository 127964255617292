import React from 'react';
import { Button  } from "react-bootstrap";

const KOInputButtonGroup = props => {
  return (
    <div className="form-group"> 
        <div className="inputgroup btn-input">
            <label>{props.label}</label>
            <input
                className={props.class ? `form-control ${props.class}` : "form-control"}
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                error={props.error}
                autoComplete={props.autoComplete}
                onChange={props.onChange}
                onBlur={props.onBlur}
                minLength={props.minLength}
                maxLength={props.maxLength}
                disabled={props.disabled}
                background-color={props.bgColor}
                ref={props.txtRef}
                step = {props.step}
            />
            {(!props.showRemoveBtn && props.showBtn) && <Button variant="secondary" style={props.btnstyle} disabled={props.btnDisabled} onClick={props.btnClick}>{props.btnLabel ? props.btnLabel : 'Redeem'}</Button> }
            {(props.showRemoveBtn && props.showBtn) && <Button variant="secondary" style={props.btnstyle}  onClick={props.removeBtnClick}>Remove</Button> }
        </div>
      {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
    </div>
  );
}

export default (KOInputButtonGroup);
