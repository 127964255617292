import React, { useState, useEffect} from 'react';
import {Table} from "react-bootstrap";
const OffensePlayerStats = props => {
    const seasonWeeks = props.seasonWeeks;
    const gameLogs = props.gameLogs;
    const player_position = props.player_position;
    const totalStats = props.totalStats;
    //Passing stats
    const passingTypeStats = () => {
        let passingStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            passingStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                        <td>{ thisWeekLog.passing_completions ? thisWeekLog.passing_completions.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{thisWeekLog.passing_attempts ? thisWeekLog.passing_attempts.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                        <td>{thisWeekLog.passing_comp_perc ? thisWeekLog.passing_comp_perc.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                        <td>{thisWeekLog.passing_yards ? thisWeekLog.passing_yards.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                        <td>{thisWeekLog.passing_touchdown ? thisWeekLog.passing_touchdown.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                        <td>{thisWeekLog.passing_interceptions ? thisWeekLog.passing_interceptions.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                        <td>{thisWeekLog.two_point_pass ? thisWeekLog.two_point_pass.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                </tr>
                 : 
                <tr>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                </tr>
            );
        }
        return passingStatsRow;
    }

    //Rushing stats
    const rushingTypeStats = () => {
        let rushingStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            rushingStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                        <td>{ thisWeekLog.rushing_attempts ? thisWeekLog.rushing_attempts.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.rushing_yards ? thisWeekLog.rushing_yards.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.rushing_touchdown ? thisWeekLog.rushing_touchdown.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{thisWeekLog.two_point_conv_runs ? thisWeekLog.two_point_conv_runs.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                    </tr>
                    : 
                    <tr>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
            );
        }
        return rushingStatsRow;
    }

    //RECEIVING stats
    const receivingTypeStats = () => {
        let receivingStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            receivingStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                        <td>{ thisWeekLog.receiving_targets ? thisWeekLog.receiving_targets.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.receptions ? thisWeekLog.receptions.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.receiving_yards ? thisWeekLog.receiving_yards.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{ thisWeekLog.receiving_touchdown ? thisWeekLog.receiving_touchdown.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                        <td>{thisWeekLog.two_point_conv_receptions ? thisWeekLog.two_point_conv_receptions.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                    </tr>
                : 
                <tr>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
            );
        }
        return receivingStatsRow;
    }

    //TD stats
    const tdTypeStats = () => {
        let tdStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            tdStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                    <td>{thisWeekLog.all_other_touchdown ? thisWeekLog.all_other_touchdown.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                </tr> :
                <tr>
                    <td>--</td>
                </tr>

            );
        }
        return tdStatsRow;
    }

    //TD stats
    const flStats = () => {
        let tdStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            tdStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                    <td>{thisWeekLog.fumbles_lost ? thisWeekLog.fumbles_lost.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                </tr> :
                <tr>
                    <td>--</td>
                </tr>

            );
        }
        return tdStatsRow;
    }

    //Score stats
    const scoreStats = () => {
        let scoreStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            scoreStatsRow.push(
                (thisWeekLog && !isgameCancelled) ?<tr>
                        <td>{thisWeekLog.player_week_score ? thisWeekLog.player_week_score.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                    </tr>:
                <tr>
                    <td>--</td>
                </tr>
            );
        }
        return scoreStatsRow;
    }
    return (
            <>
                <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>Comp</th>
                    <th>Att</th>
                    <th>Comp %</th>
                    <th>Yards</th>
                    <th>TDs</th>
                    <th>INTs</th>
                    <th>2 pt</th>
                    </tr>
                </thead>
                <tbody>
                    {passingTypeStats()}
                    
                    <tr>
                        <th>{ totalStats.totalPassingComp ? Number(totalStats.totalPassingComp).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingAtt ? Number(totalStats.totalPassingAtt).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingCompPerc ? Number(totalStats.totalPassingCompPerc).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingYards ? Number(totalStats.totalPassingYards).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingTds ? Number(totalStats.totalPassingTds).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingInts ? Number(totalStats.totalPassingInts).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalPassingTwoPoint ? Number(totalStats.totalPassingTwoPoint).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>
                </tbody>
                </Table>
            </td>

            <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>Att</th>
                    <th>Yards</th>
                    <th>TDs</th>
                    <th>2 pt</th>
                    </tr>
                </thead>
                <tbody>
                    {rushingTypeStats()}
                    
                    <tr>
                        <th>{ totalStats.totalRushingAtt ? Number(totalStats.totalRushingAtt).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalRushingYards ? Number(totalStats.totalRushingYards).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalRushingTds ? Number(totalStats.totalRushingTds).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalRushingTwoPoint ? Number(totalStats.totalRushingTwoPoint).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>
                </tbody>
                </Table>
            </td>
            <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>Targets</th>
                    <th>Rec</th>
                    <th>Yards</th>
                    <th>TDs</th>
                    <th>2 pt</th>
                    </tr>
                </thead>
                <tbody>
                    {receivingTypeStats()} 
                    
                    <tr>
                        <th>{ totalStats.totalReceivingTargets ? Number(totalStats.totalReceivingTargets).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalReceivingRec ? Number(totalStats.totalReceivingRec).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalReceivingYards ? Number(totalStats.totalReceivingYards).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalReceivingTds ? Number(totalStats.totalReceivingTds).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                        <th>{ totalStats.totalReceivingTwoPoint ? Number(totalStats.totalReceivingTwoPoint).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>                 
                </tbody>
                </Table>
            </td>
            <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>FL</th>
                    </tr>
                </thead>
                <tbody>
                    {flStats()}
                    
                    <tr>
                        <th>{ totalStats.totalFLs ? Number(totalStats.totalFLs).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>
                </tbody>
                </Table>
            </td>
            <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>All Other TDs</th>
                    </tr>
                </thead>
                <tbody>
                    {tdTypeStats()}
                    
                    <tr>
                        <th>{ totalStats.totalOtherTds ? Number(totalStats.totalOtherTds).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>
                </tbody>
                </Table>
            </td>
            <td>
                <Table className="stat-table">
                <thead>
                    <tr>
                    <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {scoreStats()}
                    
                    <tr>
                        <th>{ totalStats.totalPoints ? Number.parseFloat(totalStats.totalPoints).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    </tr>
                </tbody>
                </Table>
            </td>
        </>
    );
  
}

export default (OffensePlayerStats);
