import React, { useState, useEffect } from "react";
const KOLineupCard = props => {

  return (
    <div className={"lineupcard" + " " + (props.className)}>
        <figure>{props.figure ? <img src={props.figure}/> : ''}</figure>
        <div className='name'>{props.playername}</div>
        <p>SCORE</p>
        <div className='score'>{props.score}</div>
        <div className='position'>{props.position}</div>
    </div>
  );
}

export default (KOLineupCard);
