import React from "react";


const HamburgerIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
            <g id="Group_16376" data-name="Group 16376" transform="translate(0 -29.5)">
                <line id="Line_78" data-name="Line 78" x2="16" transform="translate(0 30.5)" fill="none" stroke="#fff" stroke-width="2" />
                <line id="Line_79" data-name="Line 79" x2="16" transform="translate(0 36.5)" fill="none" stroke="#fff" stroke-width="2" />
                <line id="Line_80" data-name="Line 80" x2="16" transform="translate(0 42.5)" fill="none" stroke="#fff" stroke-width="2" />
            </g>
        </svg>

    );

}

export default HamburgerIcon;