import React, { useState, useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import KOInput from "../components/KOInput";
import Copyright from '../components/Copyright';
import KOSelectBox from "../components/KOSelectBox";
import KOTextarea from "../components/KOTextarea";
import { Stepper } from "react-form-stepper";
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import * as stateService from "../services/stateService";
import * as userController from "../controller/userController"
import {store} from '../appState/store';
import * as commonService from "../services/commonService";
import { useGeolocated } from "react-geolocated";


const SignupStep1 = (props) => {
    const navigate = useNavigate();

    const [stateData, setStateData] = useState([]);
    const [addressText, setAddressText] = useState("");
    const [addressError, setAddressError] = useState("");
    const [cityValue, setCityValue] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [stateError, setStateError] = useState("");
    const [zipCodeValue, setZipCodeValue] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [geoLocationAvailable, setGeoLocationAvailable] = useState(false);
    const [geoLocationEnabled, setGeoLocationEnabled] = useState(false);
    const refAddress = useRef(null);

    const { coords, isGeolocationAvailable, isGeolocationEnabled, timestamp } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });
   // localStorage.setItem(coords, coords)

    const handleAddresschange = (e) => {
        setAddressError("");
        setAddressText(e.target.value);
    };

    const handleCityChange = (e) => {
        setCityError("");
        setCityValue(e.target.value);
    };

    const handleStateChange = (e) => {
        setStateError("");
        setStateValue(e.target.value);
    };

    const handleZipChange = (e) => {
        setZipCodeError("");
        setZipCodeValue(e.target.value);
    };

    useEffect(() => {
        sessionStorage.removeItem('duplicateentry');
        refAddress.current.focus();
        stateService.getStateList()
            .then(response => {
                if (response.success) {
                    setStateData(response.data);
                }
            })
            .catch(err => {
                console.log("Error", err);
            });
    }, []);

    const validate =   () => {
        let isValid = true;
        let focusSet = false;

        setAddressError("");
        if (addressText.trim() === "") {
            isValid = false;
            setAddressError("Enter Address.");
        }

        setCityError("");
        if (cityValue.trim() === "") {
            isValid = false;
            setCityError("Enter City.");
        }
        setStateError("");
        if (stateValue.trim() === "") {
            isValid = false;
            setStateError("Select State.");
        }
        setZipCodeError("");
        let zipcode_space_error = (zipCodeValue) ? commonService.validateSpace(zipCodeValue.trim()) : '';
        if (zipCodeValue.trim() === "") {
            isValid = false;
            setZipCodeError("Enter Zip Code.");
        } else if(zipcode_space_error) {
            isValid = false;
            setZipCodeError("Zip Code should not contain space.");
        }
        return isValid;
    };
    const userAddress = async () => {
        let isValid = validate();
        let userDetails = store.getState().user;
        let user_email = userDetails.email;
        if (typeof userDetails.user !== 'undefined' && (userDetails.user.login_mode_id == 2 || userDetails.user.login_mode_id ==3)) {
            user_email = userDetails.user.email;
        }
        const requestPayload ={
            email :user_email,
            address : addressText,
            city : cityValue,
            state : stateValue,
            zipcode : zipCodeValue,
            country : "US"
        }
        let userAddress
        if(isValid){
            await userController.userAddress(requestPayload);
            userAddress = store.getState().user;
           // console.log(userAddress)
           // if (userAddress.errorCode == 200) {
                navigate(pageURLs.signupstep2);
           // }
        }
        //setsignUpError(registeredUser.message)
    };

    const getStateOptions = () => {
        return stateData.map((item) => (
            { value: item.state_code, label: item.state_name }
        ));
    };

    const enterKeySubmit = (e) => {
        if (e.keyCode === 13) {
            navigate(pageURLs.signupstep2);
        }
    };

    useEffect(() => {
        setGeoLocationAvailable(isGeolocationAvailable);
        setGeoLocationEnabled(isGeolocationEnabled);
    }, [isGeolocationAvailable, isGeolocationEnabled]);

	return (
		<>
			<div className="signin-bg"></div>
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container verification-wrap">
					<div className="title-sec">
                        <h4>Address Verification</h4>
                        <div className="stepper">
                            <Stepper
                                steps={[{ label: 'Step 1' }, { label: 'Step 2' }]}
                                activeStep={0}
                            />
                        </div>
                    </div>
					<Form className="">
                        <KOTextarea
                            id="address"
                            label="Address"
                            name="address"
                            type="text"
                            placeholder="Enter your street address"
                            maxLength="50"
                            onChange={(e) => { handleAddresschange(e); }}
                            value={addressText}
                            errorMessage={addressError}
                            txtRef={refAddress}
                        />
                        <KOInput
							id="city"
							label="City"
							name="city"
							type="text"
                            placeholder="Enter your city"
                            maxLength="50"
                            onChange={(e) => { handleCityChange(e); }}
                            value={cityValue}
                            errorMessage={cityError}
						/>
                        <KOSelectBox
                            id="state"
                            name="state"
                            label="State"
                            options={getStateOptions()}
                            type="text"
                            onChange={(e) => { handleStateChange(e); }}
                            errorMessage={stateError}
                            value={stateValue}
                            
                        />
                        <KOInput
							id="zipcode"
							label="ZIP Code"
							name="zipcode"
							type="text"
                            placeholder="Enter your zipcode"
                            maxLength="6"
                            onKeyDown={(e) => enterKeySubmit(e)}
                            onChange={(e) => { handleZipChange(e); }}
                            value={zipCodeValue}
                            errorMessage={zipCodeError}
						/>
					</Form>
                    
					<div className="account-create">
						<Button variant="primary" onClick={userAddress}>Save and Continue</Button>
					</div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default SignupStep1;
