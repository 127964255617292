import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import KOInput from "../components/KOInput";
import Copyright from '../components/Copyright';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import { emailValidation, passwordValidation } from "../services/commonService";
import * as userController  from "../controller/userController";
import KOPasswordinputgroup from "../components/KOPasswordinputgroup";


const Forgotpassword = (props) => {
	const navigate = useNavigate();
	const [popUpVisible, setPopUp] = useState(false);
	const [emailValue, setEmailValue] = useState("");
	const [emailError, setEmailError] = useState("");
	const [otpValue, setOtpValue] = useState("");
	const [otpError, setOtpError] = useState("");
	const [passwordValue, setPasswordValue] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [resetPasswordError, setResetPasswordError] = useState("");
	const [resetRequestPasswordError, setResetRequestPasswordError] = useState("");
	const refEmail = useRef(null);
	const refPassword = useRef(null);
	const refOtp = useRef(null);
	
	useEffect(() => {
		refEmail.current.focus();
	}, []);

	const handleModalPopup = async () => {
		const isValid = validate('Reset');
		if(isValid) {
			const response = await userController.generateForgetPasswordOtp(emailValue);
			if(response.success){
				setPopUp(!popUpVisible)
			}
			else{
				setResetRequestPasswordError(response.message)
			}
		}
	};

	const closePopUp = () => {
		setPopUp(false)
	};
	
	const resetPassword = async () => {
		const isValid = validate();
		if(isValid) {
			const requestPayload ={
				email :emailValue.toLowerCase(),
				otp : otpValue,
				password : passwordValue
			}
			const response = await userController.resetPassword(requestPayload);
			if(response.success) {
				navigate(pageURLs.root)
			} else {
				setResetPasswordError(response.message);
			}
		}
	};

	const handleEmailChange = (e) => {
        setEmailError("");
		setResetPasswordError("");
		setResetRequestPasswordError("")
        setEmailValue(e.target.value);
    };

	const handleOTPchange = (e) => {
        setOtpError("");
		setResetPasswordError("");
		setResetRequestPasswordError("")
        setOtpValue(e.target.value);
    };

	const handlePasswordChange = (e) => {
        setPasswordError("");
		setResetPasswordError("");
		setResetRequestPasswordError("")
        setPasswordValue(e.target.value);
    };

	const validate = (section) => {
        let isValid = true;
		let focusSet = false;
		let passwordErrorMsg = passwordValidation(passwordValue);
		if(section === 'Reset') {
			setEmailError("");
			if (emailValue === "") {
				isValid = false;
				setEmailError("Enter Email.");
			} else if (emailValidation(emailValue) === false) {
				isValid = false;
				setEmailError("Enter a valid Email.");
			}
		} else {
			if (otpValue === "") {
				isValid = false;
				setOtpError("Enter a valid OTP.");
				if (!focusSet) {
					refOtp.current.focus();
					focusSet = true;
				}
			}
			if (passwordErrorMsg !== "") {
				isValid = false;
				setPasswordError(passwordErrorMsg);
				if (!focusSet) {
					refPassword.current.focus();
					focusSet = true;
				}
			}
		}
        return isValid;
    };

	const enterKeySubmit = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			handleModalPopup();
		}
	};

	const setFocus=()=>{      
        refOtp.current.focus();
    }

	return (
		<>
			<div className="signin-bg"></div>
              <Modal show={popUpVisible} onHide={closePopUp} className="modal-signin" onShow={setFocus}>  
               <Modal.Header closeButton></Modal.Header> 
                <Modal.Body> 
				<div className="title-sec">
                        <h4>Reset Password</h4>
						{resetPasswordError && (
                        	<span style={{color: "red"}}>{resetPasswordError}</span>
                        )}
                    </div>
					<Form className="">
                        <KOInput
                            id="otp"
                            label="OTP"
                            name="otp"
                            type="number"
                            onChange={(e) => { handleOTPchange(e); }}
                            value={otpValue}
                            placeholder="Enter OTP"
                            maxLength="60"
                            errorMessage={otpError}
							txtRef={refOtp}
                            onKeyDown={(e) => enterKeySubmit(e)}
                        />
                        <KOPasswordinputgroup
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            onChange={(e) => { handlePasswordChange(e); }}
                            value={passwordValue}
                            placeholder="Enter Password"
                            maxLength="60"
                            errorMessage={passwordError}
							txtRef={refPassword}
                            onKeyDown={(e) => enterKeySubmit(e)}
                        />
				     </Form>
					</Modal.Body>  
                 <Modal.Footer>  
                <Button onClick={resetPassword}>Save</Button>  
                </Modal.Footer>  
              </Modal>  
			<div className="signin-main-wrap">
				<Header/>
				<div className="signin-container request-wrap forgot-wrap">
					<div className="title-sec">
                        <h4>Forgot Password?</h4>
                        <p>We've got you covered! Enter your email address and we'll send you instructions to reset your password.</p>
						{resetRequestPasswordError && (
                        	<span style={{color: "red"}}>{resetRequestPasswordError}</span>
                        )}
                    </div>
					<Form className="">
						<KOInput
							id="email"
							label="Email"
							name="email"
							type="text"
							placeholder="Enter your email"
							maxLength="80"
							onChange={(e) => { handleEmailChange(e); }}
							value={emailValue}
							errorMessage={emailError}
							onKeyDown={(e) => enterKeySubmit(e)}
							txtRef={refEmail}
						/>
					</Form>
					<div className="account-create">
						<Button variant="primary" onClick={handleModalPopup}>Reset Password</Button>
						<Link to={pageURLs.root}>Back to Sign In</Link>
						
					</div>
				</div>
			</div>
			<div className="signin-copyright"><Copyright/></div>
		</>
	);

};

export default Forgotpassword;
