import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Fade } from "react-bootstrap";
import InnerHeader from "../components/InnerHeader";
import contestlogo from '../assets/NFL-logo.svg';
import ListIcon from '../assets/icons/listicon';
import GridIcon from '../assets/icons/gridicon';
import FiltertoggleIcon from "../assets/icons/filtertoggle";
import closeicon from '../assets/close-icon.svg';
import ContentList from "../components/ContestList";
import ContestGrid from "../components/ContestGrid";
import LobbyFilter from "../components/LobbyFilter";
import InnerPageWrap from "../components/InnerPageWrap";
import Slider from "react-slick";
import "../styles/lobby.scss";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import SlideImg from "../assets/slider-ad.png"
import { pageURLs } from "../appData/pageURLs";
import { lobbyFilterOptions } from "../appData/lobbyFilterOptions";
import useSiteState from '../appState/siteState/useSiteState';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import * as contestService from "../services/contestService";
import { useImageSize } from 'react-image-size';

function Lobby(props) {

    const [Flag_ShowContestFilter, setFlag_ShowContestFilter] = useState(false);
    const [pagePathNameState, setPagePathNameState] = useState('');
    const [previousFilterOption, setPreviousFilterOption] = useState('ALL');
    const [posters, setPosters] = useState([]);

    const navigate = useNavigate();
    const [changedPage, setChangedPage] = useState(1);
    const siteState = useSiteState();
    localStorage.removeItem('registerPassword');
    let isManageGames = (props.viewURL === pageURLs.manageleague) ? true : false;
    if (isManageGames && !siteState.data.IsUserGame) {
        navigate(pageURLs.lobby)
    }
    sessionStorage.removeItem('default_player_card');

    const [pageTitle, setPageTitle] = useState("")
    const [pageChangedStatus, setPageChangedStatus] = useState(false);
    const [pageSubHeading, setPageSubHeading] = useState("")
    const pagePathName = window.location.pathname;
    useEffect(() => {
        if (pagePathName != pagePathNameState)
            setPagePathNameState(pagePathName)
        if (pagePathName.indexOf('lobby') === 1 && !siteState.data.IsUserGame) {
            setPageTitle('Active Leagues');
            setPageSubHeading("Contests you have joined")
        }
        else if (pagePathName.indexOf('allcontest') === 1) {
            setPageTitle('Upcoming Paid Leagues');
            setPageSubHeading("Paid Leagues created by Fantasy Sports Knockout")
        }
        else if (pagePathName.indexOf('lobby') === 1 && siteState.data.IsUserGame) {
            setPageTitle('Active Leagues');
            setPageSubHeading("Leagues you have joined")
        }
        else if (pagePathName.indexOf('alluserleague') === 1) {
            setPageTitle('Upcoming User Leagues');
            setPageSubHeading("League invites from other users")
        }        
        else    if (pagePathName.indexOf('manageleague') === 1) {
            setPageTitle('Manage Leagues');
            setPageSubHeading("Leagues created by you")
        
        }else
            setPageTitle('');

    }, [pagePathName]);



    useEffect(async () => {
        await siteState.action.removeAllLineUpPlayers();

        await contestService.getPosters().then(
            posterResponse => {
            if (posterResponse.errorCode == 200) {
                setPosters(posterResponse.data.posters);
            } else {
                setPosters([]);
            }
        });
    }, []);


    useEffect(async () => {
        
        switch (props.viewURL) {

            case pageURLs.lobby:
                setChangedPage(1)
                siteState.data.Lobby.FilterOption = lobbyFilterOptions.All;
                var filter_option = lobbyFilterOptions.Joined;
                
                await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, 1, pagePathName != pagePathNameState ? 1 : 2);
                siteState.action.setLeftSideMenu('lobby');
                siteState.data.Lobby.IsJoinedOnly = true;
            
                if (pagePathName != pagePathNameState) 
                    siteState.data.Lobby.ContestFilterOptions.searchText = '';
                if(siteState.data.Lobby.FilteredContestList.length === 0 && sessionStorage.getItem('vistedSiteLobby') != 1) {
                    sessionStorage.setItem('vistedSiteLobby', 1);
                    navigate(pageURLs.allcontest)
                }
                break;
            case pageURLs.manageleague:
                setChangedPage(1)
                setPreviousFilterOption('ALL')
                siteState.data.Lobby.FilterOption = lobbyFilterOptions.All;
                var filter_option = lobbyFilterOptions.All;
                await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, true, 1, pagePathName != pagePathNameState ? 1 : 2);
                siteState.data.Lobby.IsJoinedOnly = false;
                siteState.action.setLeftSideMenu('manageleague');

                if (pagePathName != pagePathNameState)
                    siteState.data.Lobby.ContestFilterOptions.searchText = '';
                break;
            // case pageURLs.joinedupcoming:
            //     setPreviousFilterOption('ALL')
            //     var filter_option = lobbyFilterOptions.Upcoming;
            //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
            //     siteState.data.Lobby.IsJoinedOnly = true;
            //     siteState.action.setLeftSideMenu('joinedupcoming');

            //     if (pagePathName != pagePathNameState)
            //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
            //     break;
            // case pageURLs.joinedlive:
            //     setPreviousFilterOption('ALL')
            //     var filter_option = lobbyFilterOptions.Live;
            //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
            //     siteState.data.Lobby.IsJoinedOnly = true;
            //     siteState.action.setLeftSideMenu('joinedlive');

            //     if (pagePathName != pagePathNameState)
            //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
            //     break;
            // case pageURLs.joinedhistory:
            //     setPreviousFilterOption('ALL')
            //     var filter_option = lobbyFilterOptions.Completed;
            //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
            //     siteState.data.Lobby.IsJoinedOnly = true;
            //     siteState.action.setLeftSideMenu('joinedhistory');
                
            //     if (pagePathName != pagePathNameState)
            //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
            //     break;

            case pageURLs.allcontest:
                setChangedPage(1)
                setPreviousFilterOption('ALL')
                var filter_option = lobbyFilterOptions.All;
                await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, 1, pagePathName != pagePathNameState ? 1 : 2);
                siteState.data.Lobby.IsJoinedOnly = false;
                siteState.action.setLeftSideMenu('allcontest');
                
                if (pagePathName != pagePathNameState)
                    siteState.data.Lobby.ContestFilterOptions.searchText = '';
                break;

            case pageURLs.alluserleague:
                setChangedPage(1)
                setPreviousFilterOption('ALL')
                var filter_option = lobbyFilterOptions.All;
                await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, 1, pagePathName != pagePathNameState ? 1 : 2);
                siteState.data.Lobby.IsJoinedOnly = false;
                siteState.action.setLeftSideMenu('alluserleague');
                
                if (pagePathName != pagePathNameState)
                    siteState.data.Lobby.ContestFilterOptions.searchText = '';
                break;
            default:
                break;
        }
    }, [props.viewURL]);


    useEffect(async () => {
        if(pageChangedStatus) {
           
            switch (props.viewURL) {

                case pageURLs.lobby:

                    siteState.data.Lobby.FilterOption = (siteState.data.Lobby.FilterOption.label) ? siteState.data.Lobby.FilterOption : lobbyFilterOptions.All;
                    var filter_option = (siteState.data.Lobby.FilterOption.label) ? siteState.data.Lobby.FilterOption  : lobbyFilterOptions.All;
                    await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                    siteState.action.setLeftSideMenu('lobby');
                    siteState.data.Lobby.IsJoinedOnly = true;

                    if (pagePathName != pagePathNameState) 
                        siteState.data.Lobby.ContestFilterOptions.searchText = '';

                    break;
                case pageURLs.manageleague:
                    setPreviousFilterOption('ALL')
                    siteState.data.Lobby.FilterOption = (siteState.data.Lobby.FilterOption.label) ? siteState.data.Lobby.FilterOption : lobbyFilterOptions.All;
                    var filter_option = (siteState.data.Lobby.FilterOption.label) ? siteState.data.Lobby.FilterOption  : lobbyFilterOptions.All;
                    await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, true, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                    siteState.data.Lobby.IsJoinedOnly = false;
                    siteState.action.setLeftSideMenu('manageleague');

                    if (pagePathName != pagePathNameState)
                        siteState.data.Lobby.ContestFilterOptions.searchText = '';
                    break;
                // case pageURLs.joinedupcoming:
                //     setPreviousFilterOption('ALL')
                //     var filter_option = lobbyFilterOptions.Upcoming;
                //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                //     siteState.data.Lobby.IsJoinedOnly = true;
                //     siteState.action.setLeftSideMenu('joinedupcoming');

                //     if (pagePathName != pagePathNameState)
                //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
                //     break;
                // case pageURLs.joinedlive:
                //     setPreviousFilterOption('ALL')
                //     var filter_option = lobbyFilterOptions.Live;
                //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                //     siteState.data.Lobby.IsJoinedOnly = true;
                //     siteState.action.setLeftSideMenu('joinedlive');

                //     if (pagePathName != pagePathNameState)
                //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
                //     break;
                // case pageURLs.joinedhistory:
                //     setPreviousFilterOption('ALL')
                //     var filter_option = lobbyFilterOptions.Completed;
                //     await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, true, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                //     siteState.data.Lobby.IsJoinedOnly = true;
                //     siteState.action.setLeftSideMenu('joinedhistory');
                    
                //     if (pagePathName != pagePathNameState)
                //         siteState.data.Lobby.ContestFilterOptions.searchText = '';
                //     break;

                case pageURLs.allcontest:
                    setPreviousFilterOption('ALL')
                    var filter_option = lobbyFilterOptions.All;
                    await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                    siteState.data.Lobby.IsJoinedOnly = true;
                    siteState.action.setLeftSideMenu('allcontest');
                    
                    if (pagePathName != pagePathNameState)
                        siteState.data.Lobby.ContestFilterOptions.searchText = '';
                    break;
                
                case pageURLs.alluserleague:
                    setPreviousFilterOption('ALL')
                    var filter_option = lobbyFilterOptions.All;
                    await siteState.action.changeLobbyPageFilter(siteState.data.Lobby.ContestSortOptions, false, filter_option, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, false, changedPage, pagePathName != pagePathNameState ? 1 : 2);
                    siteState.data.Lobby.IsJoinedOnly = true;
                    siteState.action.setLeftSideMenu('alluserleague');
                    
                    if (pagePathName != pagePathNameState)
                        siteState.data.Lobby.ContestFilterOptions.searchText = '';
                    break;
                default:
                    break;
            }
        }
    }, [ changedPage, pageChangedStatus]);

    const getContestFilterClasses = () => {
        let result = "contest-filter";
        if (Flag_ShowContestFilter) {
            result += " show-filter";
        } else {
            result += " hide-filter";
        }
        return result;
    }



    const listClickHandler = (e) => {
        var listBtn = document.querySelector(".list");
        var gridBtn = document.querySelector(".grid");
        var gridView = document.querySelector(".contestgrid-wrap");
        var listView = document.querySelector(".contestlist-wrap")

        if (listBtn.classList.contains("active")) {
            // listBtn.classList.remove("active")
        } else {
            listBtn.classList.add("active")
            gridBtn.classList.remove("active")
            listView.classList.remove("hide")
            gridView.classList.add("hide")

        }
        siteState.action.changeLobbyView('list');
    }

    const gridClickHandler = (e) => {
        var gridBtn = document.querySelector(".grid");
        var listBtn = document.querySelector(".list");
        var gridView = document.querySelector(".contestgrid-wrap");
        var listView = document.querySelector(".contestlist-wrap")

        if (gridBtn.classList.contains("active")) {
            // gridBtn.classList.remove("active")
        } else {
            gridBtn.classList.add("active")
            listBtn.classList.remove("active")
            gridView.classList.remove("hide")
            listView.classList.add("hide")

        }
        siteState.action.changeLobbyView('grid');
    }

    

   
    var grid_button_class = 'grid';
    var list_button_class = 'list';
    var grid_view_class = 'contestgrid-wrap';
    var list_view_class = 'contestlist-wrap';
    if (siteState.data.Lobby.LobbyViewType == 'grid') {
        grid_button_class += " active";
        list_view_class += " hide";
    } else {
        list_button_class += " active";
        grid_view_class += " hide";
    }
    // change page number
    const pageChange = (page) => {
        setPageChangedStatus(true)
        setChangedPage(page)
    }

    var slicksettings = {
        dots: posters.length > 2 ,
        infinite: posters.length > 2,
        speed: 500,
        slidesToShow: (posters.length > 1) ? 2 : 1,
        slidesToScroll: 2,
        autoplay: (posters.length > 2 ) ? true : false , 
        speed: 2000, 
        pauseOnHover: true, 
        className: "center",
        centerMode: (posters.length > 2 ) ? true : false ,
        infinite: (posters.length > 2 ) ? true : false ,
        centerPadding: "10px",
        variableWidth : true,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1, 
                centerPadding: "0",
                centerMode: false,
              }
            }
          ]
      };

      const getPosters = () => {
        if (posters.length) {
            return posters.map((item) => (
                <PosterItem item={item} key={Math.random()}  />
            ));
        }
        else {
            return <></>;
        }
    };
    return (
        <InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.lobby} /></div>
                <div className="kodata-content">
                    <div className="contest-list">
                        {posters.length ? <div className="ad-slider">
                            <Slider {...slicksettings}>

                                {getPosters()}
                            </Slider>
                        </div> : ''}
                        {(siteState.data.IsUserGame && isManageGames) && <div className="league-dashboard-info">
                            <div className="total">
                                <h6>Leagues</h6><h1>{siteState.data.Lobby.totalCount}</h1>
                            </div>
                            <div className="league-stat">
                                <div className="data">
                                    <p>Published <span>{siteState.data.Lobby.totalPublishedCount}</span></p>
                                    <p>Unpublished <span>{siteState.data.Lobby.totalUnPublishedCount}</span></p>
                                </div>
                                <div className="line"></div>
                                <div className="data">
                                    <p>Live <span>{siteState.data.Lobby.totalLiveCount}</span></p>
                                    <p>Completed <span>{siteState.data.Lobby.totalCompletedCount}</span></p>
                                </div>
                            </div>
                        </div>}

                        <div className="contest-header-sec">
                            <div className="contest-title">
                                <img src={contestlogo} alt="KO" />
                                <div className="title-data">
                                    <h6>{pageTitle}</h6>
                                    <p>{pageSubHeading}</p>
                                </div>
                            </div>
                            <div className="title-actions">
                               {/* {(props.viewURL == pageURLs.lobby || props.viewURL == pageURLs.manageleague) ? <div className={getStatusFilterClasses()} id="lobbyStatusFilter">
                                    <Select
                                        options={getFilterOptions()}
                                        values={getFilterOptionValue()}
                                        searchable={false}
                                        onClick={setIsJoinedOnly()}
                                        disabled={siteState.data.Lobby.IsJoinedOnly}
                                        onChange={(e) => { changeFilter(e[0]); }}
                                    />
                                </div> : ''} */}
                                <ButtonGroup aria-label="switch button" id="lobbyViewChange">
                                    <Button className={list_button_class} onClick={listClickHandler}><ListIcon /></Button>
                                    <Button className={grid_button_class} onClick={gridClickHandler}><GridIcon /></Button>
                                </ButtonGroup>
                                <div className="filter-toggler">
                                    <Button onClick={() => { setFlag_ShowContestFilter(!Flag_ShowContestFilter); }}><FiltertoggleIcon /></Button>
                                </div>
                            </div>
                        </div>
                        <div className={siteState.data.Lobby.FilteredContestList.length > 0 ? list_view_class : 'hide'}>
                            <ContentList
                                pageChange={pageChange}
                                paginationData={siteState.data.Lobby.PaginationData}
                                contestData={siteState.data.Lobby.FilteredContestList}
                                isUserGame={siteState.data.IsUserGame}
                                showManage={(props.viewURL === pageURLs.manageleague)}
                                viewURL={props.viewURL}
                                sortState={siteState.data.Lobby.ContestSortOptions}
                                onSortChange={(e) => { siteState.action.changeLobbyPageFilter(e, siteState.data.Lobby.IsJoinedOnly, siteState.data.Lobby.FilterOption, siteState.data.Lobby.ContestFilterOptions, siteState.data.IsUserGame, isManageGames, changedPage, pagePathName != pagePathNameState ? 1 : 2) }} 
                            />
                        </div>
                        <div className={siteState.data.Lobby.FilteredContestList.length > 0 ? grid_view_class : "vertical-center-position"}>
                            <ContestGrid
                                pageChange={pageChange}
                                paginationData={siteState.data.Lobby.PaginationData}
                                contestData={siteState.data.Lobby.FilteredContestList}
                                isUserGame={siteState.data.IsUserGame}
                                showManage={(props.viewURL === pageURLs.manageleague)}
                            />
                        </div>
                    </div>
                    <div className={getContestFilterClasses()}>
                        <Button className="btn-close" onClick={() => { setFlag_ShowContestFilter(false); }}><img src={closeicon} alt="KO" /></Button>
                        <div className="week-title">Current Week: Week <span>{siteState.data.SiteCurrentWeek} </span> of 18</div>
                        <LobbyFilter value={siteState.data.Lobby.ContestFilterOptions} isManageGames={isManageGames} pagePathRefreshed={(pagePathName != pagePathNameState) ? 1 : 2} KnockoutPlusEnabled = {siteState.data.KnockoutPlusEnabled} manageLeague={(props.viewURL === pageURLs.manageleague)} />
                    </div>
                </div>
            </div>
        </InnerPageWrap>
    );


};

const PosterItem = (props) => {
    var poster_property_id = props.item.poster_property_id;
    var details_link = "";
    if(poster_property_id == 2) {
        details_link = pageURLs.joincontest + '/' + props.item.contest_id;
    }
    const [dimensions, { loading, error }] = useImageSize(props.item.poster_img_path);

    return (
        <div style={{ width: dimensions?.width }}>
            {poster_property_id == 2 ? <NavLink to={{ pathname: `${details_link}` }} >
                <img src={props.item.poster_img_path} alt={props.item.poster_name} className="slider-link"/> 
            </NavLink> : <img src={props.item.poster_img_path} alt={props.item.poster_name} /> }
        </div>
    )
}

export default Lobby;



