import React, { useState, useEffect, useRef , useMemo , useCallback} from "react";
import { Button, Dropdown, Modal, ToastContainer, Toast, Tab, Tabs } from "react-bootstrap";
import KOInput from "../components/KOInput";
import * as commonService from "../services/commonService";
import SimpleBar from 'simplebar-react';
import KOLineupCard from "./KOLineupCard";
import PlayerPositionList from "./PlayerPositionList";
import useSiteState from '../appState/siteState/useSiteState';
import useUserState from "../appState/userState/useUserState";
import { useDate } from '../components/DateDisplay';
import DeletePlayerToastMessage from '../components/DeletePlayerToastMessage';
import { store } from '../appState/store';
import * as contestService from "../services/contestService";
import { useParams,useNavigate } from 'react-router-dom';
import { TermsAndConditions } from '../utils/CommonHelper';
import InjuryReport from "./InjuryReport";
import DepthChart from "./DepthChart";
import CopyClearLineup from "../components/CopyClearLineup";
import AlertToastMessages from "./AlertToastMessages";
import NewsList from "./NewsList";
import debounce from "lodash/debounce";
import TickIcon from "../assets/icons/tickicon";
import { pageURLs } from "../appData/pageURLs";


const LineupSetup = (props) => {
    const { year } = useDate();
    const siteState = useSiteState();
    const userState = useUserState();
    const siteData = store.getState().site
    const playerList = siteState.data.Lobby.PlayerList;
    const lineUpNumberData = siteState.data.addLineUpData;
    const navigate = useNavigate();
    const maxPosition  = 10;

    const [newPosition, setNewPosition] = useState(-1);
    const [lineUpNumber, setLineUpNumber] = useState(1);   //1
    const [playerPageNumber, SetplayerPageNumber] = useState(1);
    const [updateOnAddPlayer, setupdateOnAddPlayer] = useState(-1);
    const [defaultCardList, setDefaultCardList] = useState([]);
    const [lineupId, setLineupId] = useState('');
    const [maximumParticiationReached, setMaximumParticiationReached] = useState(false);
    let dtCurrent = new Date();
    const [currentTime, setCurrentTime] = useState(dtCurrent);
    const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
    const [showClearLineUp, setShowClearLineUp] = useState(false);
    const [toastMessage, setToastMessage] = useState(false);
    const [sortData, setPlayerSortData] = useState('name');
    const [selectedTab, setSelectedTab] = useState('players');
    const [showLoaderModal, setLoaderModal] = useState(false);
    const [leaveContestConfirmMsg, setLeaveContestConfirmMsg] = useState('Please confirm that you wish to remove this lineup');
    const [showRemoveLineupErrorModal, setRemoveLineupErrorModal] = useState(false);
    const [RemoveLineupErrorMsg, setRemoveLineupErrorMsg] = useState();
    const [selectedLineupStatus, setSelectedLineupStatus] = useState(1);
    const [selectedLineupRank, setSelectedLineupRank] = useState(0);
    const [SelectedPlayerCardIndex, setSelectedPlayerCardIndex] = useState(0);
    const [lineUpPlayers, setLineUpPlayers] = useState([]);
    const [removedLineupPlayers, setRemovedLineupPlayers] = useState([]);
    const [changedLineup, setChangedLineup] = useState(false);
    const [lineUpPlayersScores, setLineUpPlayersScores] = useState([]);
    const [finishStatus, setfinishStatus] = useState(false);

    let { contestid } = useParams()
    let contestId = parseInt(contestid);

    const current_season_week = (props.currentSeasonData.current_week) ? parseInt(props.currentSeasonData.current_week) : 1;

    let defaultPlayerList = [];

    const playersPositions = {
        0 : "QB",
        1 : "RB",
        2 : "RB",
        3 : "WR",
        4 : "WR",
        5 : "WR",
        6 : "TE",
        7 : "FL",
        8 : "K",
        9 : "DF"
    }

  

    defaultPlayerList = [
        { code: 'QB', className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback" },
        { code: 'RB', className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK" },
        { code: 'RB', className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK" },
        { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER" },
        { code: 'TE', className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END" },
        { code: 'FL', className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER" },
        { code: 'K', className: "kicker", playername: "", figure: "", score: "", position: "KICKER" },
        { code: 'DF', className: "defence", playername: "", figure: "", score: "", position: " DST" }
    ];

    useEffect(() => {

        if (lineUpPlayers) {
            defaultPlayerList = [
                { code: 'QB', className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: "1", placePosition: "quarterback1" },
                { code: 'RB', className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "2", placePosition: "runningback1" },
                { code: 'RB', className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "3", placePosition: "runningback2" },
                { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "4", placePosition: "widereceiver1" },
                { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "5", placePosition: "widereceiver2" },
                { code: 'WR', className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "6", placePosition: "widereceiver3" },
                { code: 'TE', className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: "7", placePosition: "tightend1" },
                { code: 'FL', className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: "8", placePosition: "flexplayer1" },
                { code: 'K', className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: "9", placePosition: "kicker1" },
                { code: 'DF', className: "defence", playername: "", figure: "", score: "", position: " DST", dataIndex: "10", placePosition: "defence1" }
            ];

            let getPlayerContestList;
            getPlayerContestList = lineUpPlayers;
            const updatedAreas = [...defaultPlayerList];

            updatedAreas && updatedAreas.map((item, index) => {
                let arrayIndex = index;
                getPlayerContestList.map((playeritem, index) => {

                    let playerIndex = index;
                    if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "QB") {
                        let length_QB = playeritem.fsk_position_abr.length;
                        item.className = "quarterback"
                        item.playername = playeritem.player_full_name;
                        item.figure = playeritem.player_img_url;
                        item.score = "0.00";
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "RB") {
                        let length_RB = playeritem.fsk_position_abr.length;
                        for (i = 1; i <= length_RB; i++) {
                            item.className = "runningback"
                            item.playername = playeritem.player_full_name;
                            item.figure = playeritem.player_img_url;
                            item.score = "0.00";
                        }
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "WR") {
                        let length_WR = playeritem.fsk_position_abr.length;
                        for (i = 1; i <= length_WR; i++) {
                            item.className = "widereceiver"
                            item.playername = playeritem.player_full_name;
                            item.figure = playeritem.player_img_url;
                            item.score = "0.00";
                        }
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "TE") {
                        item.className = "tightend"
                        item.playername = playeritem.player_full_name;
                        item.figure = playeritem.player_img_url;
                        item.score = "0.00";
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "FL") {
                        item.className = "flexplayer"
                        item.playername = playeritem.player_full_name;
                        item.figure = playeritem.player_img_url;
                        item.score = "0.00";
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "K") {
                        item.className = "kicker"
                        item.playername = playeritem.player_full_name;
                        item.figure = playeritem.player_img_url;
                        item.score = "0.00";
                    } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "DF") {
                        item.className = "defence"
                        item.playername = playeritem.player_full_name;
                        item.figure = playeritem.player_img_url;
                        item.score = "0.00";
                    }
                });
            });
        } else {
            defaultPlayerList = [
                { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: "1", placePosition: "quarterback1", uniqueId: 0 },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "2", placePosition: "runningback1", uniqueId: 0 },
                { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: "3", placePosition: "runningback2", uniqueId: 0 },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "4", placePosition: "widereceiver1", uniqueId: 0 },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "5", placePosition: "widereceiver2", uniqueId: 0 },
                { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: "6", placePosition: "widereceiver3", uniqueId: 0 },
                { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: "7", placePosition: "tightend1", uniqueId: 0 },
                { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: "8", placePosition: "flexplayer1", uniqueId: 0 },
                { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: "9", placePosition: "kicker1", uniqueId: 0 },
                { className: "defence", playername: "", figure: "", score: "", position: " DST", dataIndex: "10", placePosition: "defence1", uniqueId: 0 }
            ];

        }
    }, [lineUpPlayers]);


    useEffect(() => {
        if (defaultCardList.length == 0)
            setDefaultCardList(defaultPlayerList);
    }, [defaultPlayerList])

    const callNextPositionOnSetup = async (positions) => {

        let id = positions;
        let lineup_number = lineUpNumber;
        let lineup_week = lineUpWeek;
        let playerPosition = positions;


        SetplayerPageNumber(1)
        let pageNumber = 1;

        await siteState.action.updateSelectedPlayerCard(positions);

        sessionStorage.setItem('defaultCard', false);
        siteState.action.storeLineUpDefault(false);

        let placingPosition;

        const nextCardPosition = defaultCardList[positions];

        placingPosition = positions;
        playerPosition = nextCardPosition.code;
        position = nextCardPosition.position;

        siteState.action.playerPositionUpdate(position);
        if (position === "Quarterback" && id === 0) {
            let position = "QB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "RUNNING BACK" && id === 1) {
            position = "RB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "RUNNING BACK" && id === 2) {
            position = "RB";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 3) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 4) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "WIDE RECEIVER" && id === 5) {
            position = "WR";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "TIGHT END" && id === 6) {
            position = "TE";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "FLEX PLAYER" && id === 7) {
            position = "FL";
            await siteState.action.lineUpPlayerList(year, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else if (position === "KICKER" && id === 8) {
            position = "K";
            await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, id, pageNumber, lineup_week, contestId, false, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        } else {
            if (id === 9)
                position = "DF";
            await siteState.action.lineUpTeamPlayerList(year, siteState.data.Lobby.SearchData, id, 1, contestId, lineup_week, lineup_number, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
            siteState.action.playerPositionUpdate(position);

        }
        setSelectedPlayerCardIndex(id)
        siteState.action.showPlusDeleteIcon(true);
    }

    let showCard;
    let responselength = lineUpPlayers.length;

    if (responselength > 0) {
        showCard = true;
    }

    if (siteState.data.DefaultLineUpCardView === false) {
        showCard = false;
    }
    if (!lineUpPlayers) {
        showCard = false;
    }

    const userId = userState.data.user.user_id;

    let totalLineUp = sessionStorage.getItem('lineupsArray') ? JSON.parse(sessionStorage.getItem('lineupsArray')).length + parseInt(1) : 2;
    let lineUpNo = [];
    let lineUpWk = current_season_week;
    let position = "QB";

    let quarterBackPlayer;

    if (siteState.data.Lobby.PlayerPosition) {

        if (siteState.data.Lobby.PlayerPosition === "QB") {
            quarterBackPlayer = siteState.data.Lobby.PlayerListByPosition;
        } else if (siteState.data.Lobby.PlayerPosition === "RB") {
            quarterBackPlayer = siteState.data.Lobby.PlayerListByPosition;
        } else if (siteState.data.Lobby.PlayerPosition === "WR") {
            quarterBackPlayer = siteState.data.Lobby.PlayerListByPosition;
        } else if (siteState.data.Lobby.PlayerPosition === "K") {
            quarterBackPlayer = siteState.data.Lobby.PlayerListByPosition;
        } else if (siteState.data.Lobby.PlayerPosition === "TE") {
            quarterBackPlayer = siteState.data.Lobby.PlayerListByPosition;
        } else if (siteState.data.Lobby.PlayerPosition === "FL") {
            quarterBackPlayer = siteState.data.Lobby.PlayerList;
        } else if (siteState.data.Lobby.PlayerPosition === "DF") {
            quarterBackPlayer = siteState.data.Lobby.LineUpTeamPlayer;
        }
    }

    const [showdeletemodal, setDeleteModal] = useState(false);
    const [showClearLineUpConfirmModal, setshowClearLineUpConfirmModal] = useState(false);
    const handleShowModal = async (e) => {
        setlineUpForRemoval(e)
        if(entryFee) {
            setLoaderModal(true);
            let contestData = {
                'contest_id' : props.contestData.contestDetails[0].si_contest_id,
                'lineup_number' : e,
                'action' : 'removelineup'
            }
            await contestService.checkRefundStatus(contestData)
            .then(function(response){
                setLoaderModal(false)
                if(response.errorCode == 200) {
                    setLeaveContestConfirmMsg(response.message)
                    setDeleteModal(true)
                } else {
                    setDeleteModal(true)
                }
            })
        } else {
            setDeleteModal(true);
        }
    }

    const hideLoderModal = () => {
        setLoaderModal(false)
    }
    const handleDeleteModalClose = () => setDeleteModal(false);
    const handleClearLineupModalClose = () => setshowClearLineUpConfirmModal(false);

    let liveTotal = (props.apiPlayerScoreData.total) ? props.apiPlayerScoreData.total : 0;
    if (liveTotal > 0) {
        liveTotal = liveTotal.toFixed(2)
    }

    const [showPredictModal, setPredictModal] = useState(false);
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [isValidScore, setIsValidScore] = useState(false);
    const [tiebreakerScore, setTiebreakerScore] = useState("");
    const [availableScores, setAvailableScores] = useState([]);
    const [joinStep, setJoinStep] = useState(0);
    const [lineUps, setLineUps] = useState(lineUpNo); //pass lineup array 1,2,3...
    const [nextLineUps, setNextLineUPs] = useState(totalLineUp);  //pass totalcount lineup
    const [lineUpSelected, setLineUpSelected] = useState(true);
    const [changeLineUpNumberAfterWeek, setChangeLineUpNumberAfterWeek] = useState(1);
    const [changeWeekClassName, setChangeWeekClassName] = useState('selected');
    const [showDropdownToggle, setShowDropdownToggle] = useState(false)
    const [lineUpEliminate, setLineUpEliminate] = useState(0);
    const [isLocked, setIsLocked] = useState(1);
    const [countLineUp, setCountLineUp] = useState(totalLineUp);
    const [key, setKey] = useState();
    const [addLineUpError, setAddLineUpeError] = useState("");
    const [activeScore, setSctiveScore] = useState("");
    const totalWeek = (props.totalWeek) ? props.totalWeek : 0;
    const entryFee = (props.entryFee) ? props.entryFee : 0;
    const contestStartWeek = (props.contestStartWeek) ? props.contestStartWeek : 0;
    const contestEndWeek = (props.contestEndWeek) ? props.contestEndWeek : 0;
    const max_lineup_per_user = (props.max_lineup_per_user) ? props.max_lineup_per_user : 0;
    const [showLineupAddButton, setLineUpAddButton] = useState(false);
    const [maxContestLineUpPerUser, setmaxContestLineUpPerUser] = useState(max_lineup_per_user);
    const [lineUpForRemoval, setlineUpForRemoval] = useState(0);
    const [showAutoLineUpLabel, setAutoLineUpLabel] = useState(false);
    const [existingTiebreakerScore, setExistingTiebreakerScore] = useState([]);
    const [tieScoreError, setTieScoreError] = useState("");
    const [showDataProcessingLoader, setShowDataProcessingLoader] = useState(false);
    const [searchData, SetSearchData] = useState(null);
    const [totalLineUpScore, SetTotalLineUpScore] = useState(liveTotal);
    const [isWeekCompleted, SetWeekCompleted] = useState(true);
    const [isLineupEliminated, SetisLineupEliminated] = useState(false);

    const [totalContestLineUp, setTotalContestLineUp] = useState(props.totalContestLineUp);
    const [apiPlayerScoreDataLineupSetup, setApiPlayerScoreDataLineupSetup] = useState([]);
    
    //---------------
    const [lineUpWeekInfo, setLineUpWeekInfo] = useState([]);
    const [lineUpIsActive, setLineUpIsActive] = useState(true);
  
    //--------------------------------

    // let playerCardId = 0;
    let playerCardId = siteState.data.Lobby.PlayerCardPlacingId ? siteState.data.Lobby.PlayerCardPlacingId : 0;

    const refTB = useRef(null);
    let setWeek = [];
    let loop = 0;
    for (var i = contestStartWeek; i <= contestEndWeek; i++) {
        setWeek.push(i)
        if (loop == 0 && (current_season_week < contestStartWeek || contestEndWeek < current_season_week)) lineUpWk = (current_season_week < contestStartWeek) ? contestStartWeek : contestEndWeek;
        loop++;
    }
    const [lineUpWeek, setLineUpWeek] = useState(lineUpWk);   // 1

    const [walletAmount, setWalletAmount] = useState(0);
    useEffect(() => {
        if (userState && userState.data && userState.data.user && userState.data.user.wallet_amount) {
            setWalletAmount(userState.data.user.wallet_amount)
        }
    }, [userState.data.user.wallet_amount])

    useEffect(async () => {
        await fetchLineupPlayers(contestid, lineUpNumber, lineUpWeek);
        if (siteState.data.Lobby.PlayerCardPlacingId != 9 ) {
            await updateLineupLockedStatus(lineUpWk);
            await setWeeklyLineUpNumbers(lineUpWeek, true).then(async function (response) {
                if([2,3].includes(props.contestData.contestDetails[0].contest_status_id)) {
                    await siteState.action.lineUpPlayerListByPosition(year, position, siteState.data.Lobby.SearchData, playerCardId, 1, lineUpWeek, contestId, false, response);
                }
                //await updateTotalLineUpScore();
            })

        } else if (siteState.data.Lobby.PlayerCardPlacingId == 9 ) {
            if([2,3].includes(props.contestData.contestDetails[0].contest_status_id)) {
                await siteState.action.lineUpTeamPlayerList(year, siteState.data.Lobby.SearchData, siteState.data.Lobby.PlayerCardPlacingId, 1, contestId, lineUpWeek, lineUpNumber);
            }
            siteState.action.playerPositionUpdate("DF");
        }
    }, []);

    //------------
    const [currentLineupId, setCurrentLineupId] = useState(0);

    useEffect(() => {
        if (lineUps, lineUpNumber, lineUpWeek) {
            const selectedLineup = lineUps && lineUps.filter((row) => { return row.lineup_week == lineUpWeek && row.lineup_number == lineUpNumber })

            if (selectedLineup && selectedLineup[0]) {
                setCurrentLineupId(selectedLineup[0].lineup_id)
            }
        }
    }, [lineUps, lineUpNumber, lineUpWeek])

    useEffect(() => {
        if (currentLineupId > 0) {
            props.getPlayerScoresOfNewLineUp(lineUpNumber, lineUpWeek, currentLineupId)
        }
    }, [currentLineupId]);

    useEffect(() => {
        if (siteState.data.Lobby && lineUpPlayersScores && (props.apiPlayerScoreData.length == 0 || props.apiPlayerScoreData.total == 0)) {
            setApiPlayerScoreDataLineupSetup(lineUpPlayersScores)
        }
    }, [lineUpPlayersScores]);

    useEffect(() => {
        if (props.apiPlayerScoreData && props.apiPlayerScoreData.total) {
            if (apiPlayerScoreDataLineupSetup && apiPlayerScoreDataLineupSetup.total) {
                let liveTotal = apiPlayerScoreDataLineupSetup.total.toFixed(2);
                SetTotalLineUpScore(liveTotal);
            } else {
                let liveTotal = props.apiPlayerScoreData.total;
                if (liveTotal > 0) {
                    liveTotal = liveTotal.toFixed(2)
                }
                SetTotalLineUpScore(liveTotal);
            }
            //if(props.apiPlayerScoreData.total>0)
            setApiPlayerScoreDataLineupSetup(props.apiPlayerScoreData);
        }
    }, [props.apiPlayerScoreData]);
    //--------------------

    const setFocus = () => {
        refTB.current.focus();
    }

    //-------------------------------

    useEffect(() => {
        if (siteState.data.Lobby.LineupsPerWeek) {
            setLineUpWeekInfo(siteState.data.Lobby.LineupsPerWeek)
        }
    }, [siteState.data.Lobby.LineupsPerWeek]);


    useEffect(() => {

        if (selectedLineupStatus == 2 && selectedLineupRank == 0 && props.contestData.contestDetails[0].contest_type_id == 1) {
            setLineUpIsActive(false)
        } else {
            setLineUpIsActive(true)
        }
    }, [selectedLineupStatus, lineUpNumber, lineUpWeek, selectedLineupRank]);


    //----------------------------------------------------------------
    useEffect(() => {
        if (parseInt(totalContestLineUp) >= parseInt(props.contestData.contestDetails[0].max_users)) {
            setMaximumParticiationReached(false)
        } else {
            setMaximumParticiationReached(true)
        }
    }, [totalContestLineUp])

    const nextJoinStepConfirm = async (e) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                resetLineupModificationStates()
                nextJoinStep()
            }
        } else {
            nextJoinStep()
        }
    }

    const nextJoinStep = async () => {
        
        if (joinStep == 0) {
            let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 1, 0);
            setExistingTiebreakerScore(currentTieBreakerScores)
        }
        switch (joinStep) {
            case 0:
                setPredictModal(true);
                setConfirmModal(false);
                setJoinStep(joinStep + 1);
                setTieScoreError('')
                break;
            case 1:
                let selectedTieBreaker = parseFloat(tiebreakerScore).toFixed(2);
                if (existingTiebreakerScore.includes(selectedTieBreaker)) {
                    setTieScoreError("This tiebreaker score has already been entered. Please enter another score.");
                } else if (commonService.isNumber(tiebreakerScore)) {
                    setTieScoreError('')
                    setPredictModal(false);
                    setConfirmModal(true);
                    setJoinStep(joinStep + 1);
                    setAddLineUpeError('');
                }
                break;
            case 2:
                setTieScoreError('')
                setPredictModal(false);
                setConfirmModal(false);
                setLineUpAddButton(false);
                setNextLineUPs(prevActiveStep => prevActiveStep + 1);
                setLineUpSelected(true);
                setJoinStep(0);
                setTiebreakerScore("");
                // TODO Call Join API

                let siteDetails;
                await siteState.action.removeAllLineUpPlayers();
                await siteState.action.updateSelectedPlayerCard(0);
                await siteState.action.addLineUp(tiebreakerScore, contestId, year, lineUpWeek);
                let next_lineup = lineUpNumber + 1;
                sessionStorage.setItem('lineupsArray', JSON.stringify([...lineUps, nextLineUps]));
                siteDetails = store.getState().site;
                if (siteDetails.Lobby.JoinContestAddLineUp.errorCode === 200) {
                    setShowDropdownToggle(true);
                    setLineUpPlayers([])
                    setLineUpPlayersScores([])
                    setRemovedLineupPlayers([])
                    props.addLineup();
                } else {
                    let message = siteDetails.addLineUpData.message;
                    setShowDropdownToggle(false);
                    cancelJoinStep();
                    alert(message);
                }
                if (siteDetails.Lobby.JoinContestAddLineUp) {
                    setWeeklyLineUpNumbers(lineUpWeek, false)
                }
                setTotalContestLineUp(siteDetails.Lobby.JoinContestAddLineUp.data.contest_total_line_up);


                setAvailableScores([]);
                
                siteState.action.playerPositionUpdate('QB');
                await siteState.action.lineUpPlayerListByPosition(year, 'QB', siteState.data.Lobby.SearchData, 0, 1, lineUpWeek, contestId, false, next_lineup);
                break;
            default:
                cancelJoinStep();
                break;
        }
    }


    const cancelJoinStep = () => {
        setPredictModal(false);
        setConfirmModal(false);
        setJoinStep(0);
        setTiebreakerScore("");
        setAvailableScores([]);
    }

    const cancelPreviousJoinStep = () => {
        setPredictModal(true);
        setConfirmModal(false);
        setJoinStep(0);
    }

    const handleScoreChange = (e) => {
        let scoreValue = e.target.value;
        setTieScoreError('');
        let scoreValueArray = scoreValue.split(".");
        if (scoreValueArray.length > 2) {
            scoreValue = scoreValueArray[0] + '.' + scoreValueArray[1];
            scoreValue = parseFloat(scoreValue);
        }
        if (scoreValue === "") {
            setTiebreakerScore(scoreValue);
            setScoreList(0, 1);
            setAvailableScores([]);
        } else if (!commonService.isNumber(scoreValue)) {
            setAvailableScores([]);
            if (parseFloat(scoreValue).toFixed(2)) {
                if (!isNaN(scoreValue) && commonService.isFloat(parseFloat(scoreValue).toFixed(2))) {
                    setScoreList(scoreValue, 100.12);
                    var res = scoreValue.split(".");
                    setTiebreakerScore(scoreValue);
                } else {
                    setTiebreakerScore("");
                }
            }
        } else {
            if (scoreValue < 1000) {
                setTiebreakerScore(scoreValue);
                setScoreList(scoreValue, 100.12);
            }
        }
        setAddLineUpeError('')
    }

    const setScoreList = async (start, selected) => {
        let i;
        let scoreList = []
        let startVal = start;
        let displayVal;
        let selectedVal = parseFloat(selected).toFixed(2);
        debouncedSendRequest(start);
        for (i = 0; i < 18; i++) {
            startVal = parseFloat(startVal) + 0.01;
            displayVal = parseFloat(startVal).toFixed(2);
            if (displayVal === selectedVal) {
                scoreList.push({ "val": displayVal, "isActive": true });
            } else {
                scoreList.push({ "val": displayVal, "isActive": false });
            }
        }
        setAvailableScores(scoreList);
    };

    //Debouncing for avoiding multiple api calls
    const checkTieScore = useCallback(async (selectedVal) => {
        let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 1, selectedVal);
        setExistingTiebreakerScore(currentTieBreakerScores)
    }, []);

    const debouncedSendRequest = useMemo(() => {
    return debounce(checkTieScore, 500);
    }, [checkTieScore]);

    const removeLineup = async (e) => {
        const value = lineUpForRemoval;
        if (lineUpNumber === value) { //If removing the selected line up
            await siteState.action.removeAllLineUpPlayers();
            await siteState.action.updateSelectedPlayerCard(0);
        }
        setDeleteModal(false);
        let removedResult = await siteState.action.removeLineup(contestId, value, lineUpWeek);
        if(removedResult && !removedResult.success){
            setRemoveLineupErrorModal(true);
            setRemoveLineupErrorMsg(removedResult.message);
            return true;
        }
        setLineUpSelected(false);
        setLineUpEliminate(parseInt(value));
        let currentTotal = totalContestLineUp;
        userState.action.getUserData();
        setTotalContestLineUp(parseInt(currentTotal) - 1)
        SetTotalLineUpScore('0.00')
        setWeeklyLineUpNumbers(lineUpWeek, true).then(async function (response) {
            await fetchLineupPlayers(contestId, response, lineUpWeek);
            //await siteState.action.getAllPlayersList(contestId, response, lineUpWeek);
        })

    }

    const handleSelectAvaliableScore = (e) => {
        setTiebreakerScore(e.target.getAttribute('name'));
        setSctiveScore(e.target.getAttribute('name'));
    }

    const handleWeekPlayersConfirm = async (e) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                resetLineupModificationStates()
                handleWeekPlayers(e)
            }
        } else {
            handleWeekPlayers(e)
        }
    }


    const handleWeekPlayers = async (e) => {
        
        let value = e.target.value;
        if (lineUpWeek == value) {
            return true;
        }
        await siteState.action.removeAllLineUpPlayers();
        await siteState.action.updateSelectedPlayerCard(0);
        let name = e.target.getAttribute('name');

        setLineUpWeek(parseInt(value));
        setChangeWeekClassName('current');
        await setWeeklyLineUpNumbers(parseInt(value), true)
            .then(async function (response) {
                await updateLineupLockedStatus(value);
                await siteState.action.playerPositionUpdate('QB');
                await siteState.action.lineUpPlayerListByPosition(year, 'QB', siteState.data.Lobby.SearchData, 0, 1, value, contestId, false, response);
               // await siteState.action.getAllPlayersList(contestId, response, value);
                await fetchLineupPlayers(contestId, response, value);
              //  await updateTotalLineUpScore();

            })
    }
   
    const getLineUpPlayers = (ActivePlayerCardIndex) => {
        const updatedAreas = [...defaultPlayerList];
        const boxScore = (lineUpPlayersScores) ? lineUpPlayersScores : [];
        let lineUpScore = 0;
        const playerScores = (apiPlayerScoreDataLineupSetup.total) ? apiPlayerScoreDataLineupSetup : boxScore;
        updatedAreas.map((item, index) => {
            let arrayIndex = index;
            lineUpPlayers.map((playeritem, index) => {
                let playerIndex = index;
                let playername;
                let isPlayerLockedInMaster = 0;
                if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "QB") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.className = "quarterback"
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "RB") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "WR") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "FL") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "K") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "DF") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.team_key] ? parseFloat(playerScores[playeritem.team_key]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                } else if (arrayIndex === playeritem.player_card_id && playeritem.fsk_position_abr === "TE") {
                    isPlayerLockedInMaster = (playeritem.is_player_locked == 1 && playeritem.current_week == lineUpWeek) ? 1 : 0;
                    item.playername = playeritem.player_full_name;
                    item.figure = playeritem.player_img_url;
                    item.score = playerScores[playeritem.player_id] ? parseFloat(playerScores[playeritem.player_id]).toFixed(2) : "0.00";
                    item.uniqueId = playeritem.si_contest_user_wk_lup_player_id;
                    item.player_position = playeritem.player_position;
                    item.lineup_number = playeritem.lineup_number;
                    item.lineup_week = playeritem.lineup_week;
                    item.player_card_id = playeritem.player_card_id;
                    item.si_contest_id = playeritem.si_contest_id;
                    item.player_id = playeritem.player_id;
                    item.fsk_position_abr = playeritem.fsk_position_abr;
                    item.isLocked = (parseFloat(playerScores[playeritem.player_id]) || playeritem.is_locked || isWeekCompleted || isPlayerLockedInMaster) ? 1 : 0;
                    item.is_player_injured = (playeritem.is_player_injured) ? playeritem.is_player_injured : false;
                    item.away_score = (playeritem.away_score) ? playeritem.away_score : 0;
                    item.away_team = (playeritem.away_team) ? playeritem.away_team : null;
                    item.game_key = (playeritem.game_key) ? playeritem.game_key : null;
                    item.has_started = (playeritem.has_started) ? playeritem.has_started : 0;
                    item.home_score = (playeritem.home_score) ? playeritem.home_score : 0;
                    item.home_team = (playeritem.home_team) ? playeritem.home_team : null;
                    item.is_inprogress = (playeritem.is_inprogress) ? playeritem.is_inprogress : 0;
                    item.is_over = (playeritem.is_over) ? playeritem.is_over : 0;
                    item.is_overtime = (playeritem.is_overtime) ? playeritem.is_overtime : 0;
                    item.quater = (playeritem.quater) ? playeritem.quater : null;
                    item.schedule_date = (playeritem.schedule_date) ? playeritem.schedule_date : null;
                    item.time_remaining = (playeritem.time_remaining) ? playeritem.time_remaining : null;
                    item.team_key = (playeritem.team_key) ? playeritem.team_key : null;
                    item.quater_desc = (playeritem.quater_desc) ? playeritem.quater_desc : null;
                    item.is_player_news_avail = (playeritem.is_player_news_avail) ? playeritem.is_player_news_avail : 0;
                    item.byeweek = (playeritem.byeweek) ? playeritem.byeweek : 0;
                }
            });
        });
        let eliminatedLineUp = ((playerScores.isLineUpEliminated && props.contestData.contestDetails[0].contest_type_id == 1) ? ' disabled' : '');
        let eliminatedClassName = '';
        return updatedAreas ? updatedAreas.map((item, index) =>
            
            <KOLineupCard
                lineUps={lineUps}
                className={lineUpIsActive ? item.className + "" + eliminatedClassName : "deactive"}
                type="lineupSetup"
                figure={item.figure}
                playername={item.playername}
                score={item.score}
                position={item.position}
                dataIndex={index}
                placingPosition={item.className + index}
                uniqueId={item.uniqueId}
                player_position={(item.player_position) ? item.player_position : null}
                lineup_number={(item.lineup_number) ? item.lineup_number : lineUpNumber}
                lineup_week={(item.lineup_week) ? item.lineup_week : lineUpWeek}
                player_card_id={(item.player_card_id) ? item.player_card_id : 0}
                si_contest_id={(item.si_contest_id) ? item.si_contest_id : null}
                player_id={(item.player_id) ? item.player_id : null}
                isLocked={(item.isLocked) ? item.isLocked : 0}
                eliminatedLineUp={eliminatedLineUp}
                fsk_position_abr={(item.fsk_position_abr) ? item.fsk_position_abr : null}
                isWeekCompleted={isWeekCompleted}
                is_player_injured={(item.is_player_injured) ? item.is_player_injured : 0}
                contest_status_id = {props.contestData.contestDetails[0].contest_status_id ? props.contestData.contestDetails[0].contest_status_id : 2}
                contest_type_id = {props.contestData.contestDetails[0].contest_type_id ? props.contestData.contestDetails[0].contest_type_id : 1}
                away_score={item.away_score}
                away_team={item.away_team}
                game_key={item.game_key}
                has_started={item.has_started}
                home_score={item.home_score}
                home_team={item.home_team}
                is_inprogress={item.is_inprogress}
                is_over={item.is_over}
                is_overtime={item.is_overtime}
                quater={item.quater}
                schedule_date={item.schedule_date}
                time_remaining={item.time_remaining}
                team_key={item.team_key}
                quater_desc= {item.quater_desc}
                is_player_news_avail= {item.is_player_news_avail}
                playerId={(item.player_id) ? item.player_id : null}
                team={item.team_key}
                lineUpIsActive={lineUpIsActive}
                byeweek={item.byeweek}
                playersInLineup = {lineUpPlayers}
                SelectedPlayerCardIndex = {ActivePlayerCardIndex}
                updateSelectedCardIndex = {updateSelectedCardIndex}
                lineup_player_id={item.uniqueId}
                removePlayersFromLineup = {removePlayersFromLineup}
            />
        ) : []
    }

    const getAdditionalLineUpNumber = () => {

        return lineUps.map((lineUp, index) =>
            <li key={'liky' + index} className={setLineUpClass(lineUp.lineup_number, lineUp.contest_lineup_status_id, lineUp.is_auto_lineup)}
                id={lineUp.lineup_number}
                value={lineUp.lineup_number}
                data-index={index}
                data-linupid={lineUp.lineup_id}
                onClick={handleLineUpPlayersConfirm}
                data-isautolineup={lineUp.is_auto_lineup}
                data-lineupstatus={lineUp.contest_lineup_status_id}
                data-lineuprank={lineUp.week_user_rank}
                name={`lineup${lineUp.lineup_number}`} >{lineUp.lineup_number}</li>
        );
    }

    const handleLineUpPlayersConfirm = async (e) => {
        if(localStorage.getItem('changeInLineup') === 'true'){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                resetLineupModificationStates()
                handleLineUpPlayers(e)
            }
        } else {
            handleLineUpPlayers(e)
        }
    }

    const handleLineUpPlayers = async (e) => {
        let value = e.target.value;
        if (lineUpNumber == value) {
            return true;
        }
        let isautolineup = e.target.dataset.isautolineup;
        let linupid = e.target.dataset.linupid;
        if (isautolineup == 1) {
            setAutoLineUpLabel(true)
        } else {
            setAutoLineUpLabel(false)
        }
        let lineupstatus = e.target.dataset.lineupstatus;
        let lineuprank = e.target.dataset.lineuprank;
        await siteState.action.removeAllLineUpPlayers();
        await siteState.action.updateSelectedPlayerCard(0);
        setLineUpNumber(value);
        setLineupId(linupid);
        setSelectedLineupStatus(lineupstatus);
        setSelectedLineupRank(lineuprank)
        await updateLineupLockedStatus(lineUpWeek);
        await siteState.action.playerPositionUpdate('QB');
        await siteState.action.lineUpPlayerListByPosition(year, 'QB', siteState.data.Lobby.SearchData, 0, 1, lineUpWeek, contestId, false, value);
      //  await siteState.action.getAllPlayersList(contestId, value, lineUpWeek);
        await fetchLineupPlayers(contestId, value, lineUpWeek);
       // await updateTotalLineUpScore();

    }

    const setLineUpClass = (lineup_number, contest_lineup_status_id, is_auto_lineup) => {
        let lineUpClass = '';
        if (lineup_number === lineUpNumber) {
            lineUpClass += ' selected';
            if (is_auto_lineup == 1 && !showAutoLineUpLabel) {
                setAutoLineUpLabel(true)
            } else if (showAutoLineUpLabel && is_auto_lineup == 0) {
                setAutoLineUpLabel(false)
            }
        }
        if (contest_lineup_status_id === 2) {
            lineUpClass += ' eliminated';
        } else if (contest_lineup_status_id === 3) {
            lineUpClass += ' eliminated';
        }

        if (contest_lineup_status_id === 2 && props.contestData.contestDetails[0].contest_type_id == 1) {
            lineUpClass += ' disabled';
        }
        if (isWeekCompleted) {
            lineUpClass += ' locked';
        }
        return lineUpClass;
    }

    const setWeeklyLineUpNumbers = async (selectedWeek, updateLineUpNumber) => {
        let lineUpResult = await contestService.getWeeklyLineUps(contestId, selectedWeek);
        let resultArray = (lineUpResult.data) ? lineUpResult.data : null;
        let new_lineup_number = 0;
        setLineUps(resultArray);
        if (resultArray.length < max_lineup_per_user) {
            setLineUpAddButton(true)
        } else {
            setLineUpAddButton(false)
        }

        if (updateLineUpNumber && resultArray.length) {
            setLineUpNumber(resultArray[0].lineup_number);
            setLineupId(resultArray[0].lineup_id)
            new_lineup_number = resultArray[0].lineup_number;
            setSelectedLineupStatus(resultArray[0].contest_lineup_status_id);
            setSelectedLineupRank(resultArray[0].week_user_rank);
        } else if (updateLineUpNumber && !resultArray.length) {
            setLineUpNumber(0);
            setSelectedLineupStatus(1);
            setSelectedLineupRank(0)
        } else if (!updateLineUpNumber && resultArray.length) {
            var last_added_lineup = resultArray[resultArray.length - 1];
            setLineUpNumber(last_added_lineup.lineup_number);
            if (last_added_lineup.lineup_id) {
                setLineupId(last_added_lineup.lineup_id)
            }
            setSelectedLineupStatus(1)
            setSelectedLineupRank(0)
            new_lineup_number = last_added_lineup.lineup_number;
        }
        return new_lineup_number;
    }

    const searchPlayers = async (e) => {
        SetSearchData(e.searchData);
        
        (e.position === 'DF') ? siteState.action.changeTeamSearchFilter(e, 1, contestId, lineUpWeek, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy) : (e.position === 'FL') ? await siteState.action.lineUpPlayerList(year, e.searchData, siteState.data.Lobby.PlayerCardPlacingId, 1, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy) : siteState.action.changePlayerSearchFilter(e, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
    }
    const closeDataProcessingModal = () => {
        setShowDataProcessingLoader(false);
    }
    const PageNumberChange = async (page) => {
        SetplayerPageNumber(page);
        (siteState.data.Lobby.PlayerPosition == 'FL') ? await siteState.action.lineUpPlayerList(year, searchData, siteState.data.Lobby.PlayerCardPlacingId, page, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy) : await siteState.action.lineUpPlayerListByPosition(year, siteState.data.Lobby.PlayerPosition, searchData, siteState.data.Lobby.PlayerCardPlacingId, page, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
    }
    const updateTotalLineUpScore = async () => {
        const selectedPlayerIds = [];
        const selectedTeamIds = [];
        let lineUpScore = 0;
        let static_keys = ['contestid', 'contestid', 'totalpoints', 'lineupid', 'userid', 'memberid'];
        let playerScoreArray = (lineUpPlayersScores) ? lineUpPlayersScores : [];
        let selectdPlayers = (lineUpPlayers && lineUpPlayers.length) ? [...lineUpPlayers] : []
        playerScoreArray = (playerScoreArray[0]) ? playerScoreArray[0] : playerScoreArray;
       // console.log("here",lineUpPlayersScores)
        selectdPlayers.map((playeritem, index) => {

            if (playeritem.player_id && playeritem.fsk_position_abr !== "DF") {
                selectedPlayerIds.push(playeritem.player_id);
            } else if (playeritem.fsk_position_abr === "DF") {
                selectedTeamIds.push(playeritem.team_key);
            }
        })

        for (var key in playerScoreArray) {
            if (!static_keys.includes(key) && selectedPlayerIds.includes(parseInt(key))) {
                lineUpScore += parseFloat(playerScoreArray[key])
            } else if (!static_keys.includes(key) && selectedTeamIds.includes(key)) {
                lineUpScore += parseFloat(playerScoreArray[key])
            }
        }

        let liveTotal = (props.apiPlayerScoreData.total) ? props.apiPlayerScoreData.total : 0;
        if (liveTotal > 0) {
            lineUpScore = liveTotal
        }
        SetTotalLineUpScore(lineUpScore.toFixed(2))
    }

    const updateLineupLockedStatus = async (lineUpWk) => {
        let lockStatus = (lineUpWk < current_season_week) ? true : false;
        SetWeekCompleted(lockStatus);
    }
    const showInsufficientBalance = () => {
        setIsInsufficientBalance(true);
    }
    const handleInsufficientBalanceModalClose = () => setIsInsufficientBalance(false);

    useEffect(() => {
        props.updateLineupPlayers(lineUpPlayers)
    }, [lineUpPlayers])

    useEffect(async () => {
        if (currentTime && lineUpWeek == current_season_week && currentTime != dtCurrent) {
            if (siteState.data.Lobby.PlayerCardPlacingId != 9) {
               // await siteState.action.getAllPlayersList(contestId, lineUpNumber, lineUpWeek);
                await fetchLineupPlayers(contestId, lineUpNumber, lineUpWeek);
                //await updateTotalLineUpScore();
            } else {
                //await siteState.action.lineUpTeamPlayerList(year, siteState.data.Lobby.SearchData, siteState.data.Lobby.PlayerCardPlacingId, 1, contestId, lineUpWeek, lineUpNumber);
                siteState.action.playerPositionUpdate("DF");
            }

        }
    }, [currentTime]);

    useEffect(async () => {
        if (lineUpPlayersScores) {
            await updateTotalLineUpScore();
        }
    }, [lineUpPlayersScores]);



    useEffect(() => {
        const intervalId = setInterval(async () => {
            let currentDate = new Date();
            let diffTime = Math.abs(currentDate - currentTime);
            //       console.log("Display Card Timer", diffTime)
            if (diffTime > 600000) {
                let ctime = Math.floor(Date.now() / 1000);
                setCurrentTime(currentDate);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };

    }, [currentTime])

    const clearLineUp = async () => {
        setshowClearLineUpConfirmModal(false);
            let playerDataArray = {
            contest_id: contestId,
            lineup_week: lineUpWeek,
            lineup_number: lineUpNumber,
            isUserGames : false
        }
        let result = await siteState.action.clearLineUp(playerDataArray);
        setLineUpPlayers([])
        setRemovedLineupPlayers([])
        await fetchLineupPlayers(contestId, lineUpNumber, lineUpWeek);
        await siteState.action.updateSelectedPlayerCard(0);
        await siteState.action.playerPositionUpdate('QB');
        await siteState.action.lineUpPlayerListByPosition(year, 'QB', siteState.data.Lobby.SearchData, 0, 1, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
    }

    const showClearLineUpConfirmation = async () => {
        setshowClearLineUpConfirmModal(true)
    }

    useEffect(() => {
        if(isWeekCompleted) {
            setShowClearLineUp(false)
        } else {
            let showClearBtnflag = 0;
            lineUpPlayers.map((playeritem, index) => {
                if(playeritem.is_locked == 0){
                    showClearBtnflag = 1;
                }
            })
            if(showClearBtnflag) {
                setShowClearLineUp(true)
            } else {
                setShowClearLineUp(false)
            }
        }
    }, [lineUpPlayers, isWeekCompleted])

    const copyLineUp = async (lineup_number_from) => {
        setshowClearLineUpConfirmModal(false);
            let playerDataArray = {
            contest_id: contestId,
            lineup_week: lineUpWeek,
            lineup_number: lineUpNumber,
            isUserGames : false,
            lineup_number_from : Number(lineup_number_from)
        }
        let result = await contestService.copyLineUp(playerDataArray);
        await fetchLineupPlayers(contestId, lineUpNumber, lineUpWeek);
        //await siteState.action.copyLineUp(result);
        if(result.errorCode !=200) {
            setToastMessage(result.message)
        }
    }

    const resetAlert = async () => {
        setToastMessage('')
    }

    const sortPlayers = async (sortBy) => {
        let options = {
            'year' : year,
            'searchData' : searchData,
            'placingPositionId' : siteState.data.Lobby.PlayerCardPlacingId
        };
       (siteState.data.Lobby.PlayerPosition == 'DF') ? siteState.action.changeTeamSearchFilter(options, 1, contestId, lineUpWeek, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy) : (siteState.data.Lobby.PlayerPosition == 'FL') ? await siteState.action.lineUpPlayerList(year, searchData, siteState.data.Lobby.PlayerCardPlacingId, playerPageNumber, lineUpWeek, contestId, false, lineUpNumber,siteState.data.Lobby.PlayerSearchSortOptions.SortBy) : await siteState.action.lineUpPlayerListByPosition(year, siteState.data.Lobby.PlayerPosition, searchData, siteState.data.Lobby.PlayerCardPlacingId, playerPageNumber, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
    }

    //Tab Changes
    const changeTabs = async (selectedTab) => {
        setSelectedTab(selectedTab)
    }

    const hideRemoveLineupErrorMessage = async () => {
        setRemoveLineupErrorModal(false)
        setRemoveLineupErrorMsg('')
    }

    //add players to lineup
    const addPlayersToLineup = async (playerData) => {
        let existingLineupData = [...lineUpPlayers];
        var existingPlayers = existingLineupData.filter(function (el) {
            return (el.player_id > 0 && el.player_position !== 'DF')
        }).map(function(obj) { return obj.player_id; });

        var existingTeam = existingLineupData.filter(function (el) {
            return (el.team_key && el.player_position === 'DF')
        }).map(function(obj) { return obj.team_key; });

        if(playerData.position !== 'DF' && existingPlayers.includes(playerData.playerId)) {
            alert("This player has already been added to a lineup. Please choose another player.");
            return false;
        }

        if(existingTeam.includes(playerData.team)) {
            alert("This player has already been added to a lineup. Please choose another player.");
            return false;
        }
        //If wrong player selected for the position
        if(playersPositions[playerData.positionPlaceId] != 'FL' && playersPositions[playerData.positionPlaceId] != playerData.position) {
            alert("The selected player does not play for this position. Please select another player.");
            return false;
        }

        let playerArray = {
            "player_full_name" : playerData.playername,
            "player_img_url" : playerData.figure,
            "uniqueId" : 0,
            "player_position" : playerData.position,
            "lineup_number" : lineUpNumber,
            "lineup_week" : lineUpWeek,
            "player_card_id" : playerData.positionPlaceId,
            "si_contest_id" : contestId,
            "player_id" : ( playerData.position !== 'DF') ? playerData.playerId : 0,
            "isLocked" : playerData.is_player_locked,
            "team_key" :  playerData.team,
            "playerInLocal" : true,
            "fsk_position_abr" : playersPositions[playerData.positionPlaceId] ? playersPositions[playerData.positionPlaceId] : 'QB',
            "si_contest_user_wk_lup_player_id" : 0
        }
        await siteState.action.updateSelectedPlayerCard(playerData.positionPlaceId);
        existingLineupData.push(playerArray);
        var existing_filled_cards = existingLineupData.map(function(a) {return a.player_card_id;});
        let nextFillablePositionId = null;
        for(let i=0; i < maxPosition ; i++) {
            if(!existing_filled_cards.includes(i) && nextFillablePositionId === null) {
                nextFillablePositionId = i;
            }
        }
        if(nextFillablePositionId == null) {
            nextFillablePositionId = 0;
        }
        setLineUpPlayers(existingLineupData);
        setSelectedPlayerCardIndex(nextFillablePositionId)
        let nextPosition = playersPositions[nextFillablePositionId] ? playersPositions[nextFillablePositionId] : 'QB';
        SetplayerPageNumber(1)
        siteState.data.Lobby.PlayerSearchFilterOptions.SearchData = '';
        SetSearchData('')
        if(nextPosition === 'DF') {
            await siteState.action.lineUpTeamPlayerList(year, '', nextFillablePositionId, 1, contestId, lineUpWeek, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
        } else if(nextPosition === 'FL') {
            await siteState.action.lineUpPlayerList(year, '', nextFillablePositionId, 1, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
        } else {
            await siteState.action.lineUpPlayerListByPosition(year, nextPosition, '', nextFillablePositionId, 1, lineUpWeek, contestId, false, lineUpNumber, siteState.data.Lobby.PlayerSearchSortOptions.SortBy);
        }
        siteState.action.playerPositionUpdate(nextPosition);
        
        updateSaveLinupStatus() //Eanble/disable lineup button based on current status
        localStorage.setItem('changeInLineup', true)
        setChangedLineup(true)
    }

    useEffect(() => {
        getLineUpPlayers(SelectedPlayerCardIndex)
    }, [lineUpPlayers, SelectedPlayerCardIndex])

    const updateSelectedCardIndex = async (index) => {
        setSelectedPlayerCardIndex(index)
    }
    //Remove players from lineup
    const removePlayersFromLineup = async (removedPlayerData) => {
        let existingLineupData = [...lineUpPlayers];
        let existingRemovedPlayers = [...removedLineupPlayers];
        let player_card_id = removedPlayerData.player_card_id;
        for(let i= 0; i < existingLineupData.length ; i++) {
            if(existingLineupData[i].player_card_id == player_card_id && existingLineupData[i].si_contest_user_wk_lup_player_id > 0) {
                let removedPlayerData = {
                    "position" : existingLineupData[i].player_position,
                    "player_id" : existingLineupData[i].player_id,
                    "is_team" : existingLineupData[i].is_team,
                    "team_id" : existingLineupData[i].team_id,
                    "lineup_player_id" : existingLineupData[i].si_contest_user_wk_lup_player_id,
                    "team_key" : existingLineupData[i].team_key,
                    "player_card_id" : existingLineupData[i].player_card_id,
                }
                existingRemovedPlayers.push(removedPlayerData)
            }
        }
        var remainingPlayers = existingLineupData.filter(function (el) {
            return (el.player_card_id != player_card_id)
        })
        props.lineupSave(false)
        setLineUpPlayers(remainingPlayers);
        setRemovedLineupPlayers(existingRemovedPlayers);
        localStorage.setItem('changeInLineup',true)
        setChangedLineup(true)
        updateSaveLinupStatus() //Eanble/disable lineup button based on current status
        return true;
    }

    const fetchLineupPlayers = async (contestId, lineupNumber, lineupWeek) => {
        let lineupPlayersData = await contestService.getAllPlayersList(contestId, lineupNumber, lineupWeek);
        resetLineupModificationStates()
        setRemovedLineupPlayers([])
        if (lineupPlayersData.success && lineupPlayersData.errorCode == 200 && lineupPlayersData.data.lineupData.length) {
            setLineUpPlayers(lineupPlayersData.data.lineupData)
            setLineUpPlayersScores(lineupPlayersData.data.scoresData)
        } else {
            setLineUpPlayers([])
            setLineUpPlayersScores([])
        }
    }

    //Save line up
    useEffect( async () => {
        if(props.lineupSaveAction === true && changedLineup === true) {
            await saveLineupPlayersData()
        }
    }, [props.lineupSaveAction])

    //Save lineup function
    const saveLineupPlayersData = async () => {
        let existingLineupData = [...lineUpPlayers];
        let removedLineupData = [...removedLineupPlayers];
        let playersData = [];
        for(let p= 0; p < existingLineupData.length ; p++) {
            let tmpPlayerArray = {
                "position" : (existingLineupData[p].player_position === 'DF' || existingLineupData[p].fsk_position_abr === 'DF') ? 'DF' : existingLineupData[p].fsk_position_abr,
                "player_id" : existingLineupData[p].player_id,
                "is_team" : (existingLineupData[p].player_position === 'DF' || existingLineupData[p].fsk_position_abr === 'DF') ? 1 : 0,
                "team_id" :existingLineupData[p].team_id,
                "lineup_player_id" : (existingLineupData[p].playerInLocal !== undefined && existingLineupData[p].playerInLocal === true ) ? 0 : existingLineupData[p].si_contest_user_wk_lup_player_id,
                "team_key" :existingLineupData[p].team_key,
                "player_card_id" : (existingLineupData[p].playerInLocal !== undefined && existingLineupData[p].playerInLocal === true ) ? null : existingLineupData[p].player_card_id,
            }
            playersData.push(tmpPlayerArray)
        }
        if(playersData.length != maxPosition) {
            props.lineupSave(false)
            alert("Please add players for each position.")
            return false;
        }
        let request = {
            "lineup_number" :  lineUpNumber,
            "lineup_week" : lineUpWeek,
            "contest_id" : contestId,
            "players" : playersData,
            "removedPlayers" : removedLineupData
        }

        props.lineupSave(false)
        setShowDataProcessingLoader(true)

        let saveResult = await contestService.saveSiteContestLineupPlayers(request);
        setShowDataProcessingLoader(false)
        if(saveResult.success) {
            setRemovedLineupPlayers([])
           
            props.updateTostStatus(true, saveResult.message)
            props.updateSaveLinupStatus(false)
            resetLineupModificationStates()
           await fetchLineupPlayers(contestid, lineUpNumber, lineUpWeek)
        } else {
            alert(saveResult.message);
            return true;
        }
    }

    //Show lineup button
    const updateSaveLinupStatus = async () => {
        let enableBtn = false;
        let existingLineupData = [...lineUpPlayers];
        let availablePositionCount = parseInt(maxPosition) - 1;
        if(existingLineupData.length == availablePositionCount) {
            enableBtn = true
        }
        props.updateSaveLinupStatus(enableBtn)
    }
    const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
    };

    //browser refresh
    useEffect(() => {
        if(changedLineup) {
            window.addEventListener("beforeunload", unloadCallback);
            return () => window.removeEventListener("beforeunload", unloadCallback);
        }
    }, [changedLineup]);

    const resetLineupModificationStates = async () => {
        localStorage.removeItem('changeInLineup')
        setChangedLineup(false)
    }



    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            if (window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                resetLineupModificationStates()
                setfinishStatus(true)
                navigate(pageURLs.lobby);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setfinishStatus(false)
            }
        }
    }
    //Browser back button
    useEffect(() => {
    if(changedLineup) {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }
    }, [changedLineup]);

    

    return (
        <div className="lineup-setup-wrap">
            <div className="week-selector">
                <p>Week</p>
                <div className="week-scroll">
                    <ul className="count-info">
                        {
                            setWeek.map((week, index) => <li key={'key' + index} className={((lineUpWeek === week) ? 'current' : '') + '' + ((parseInt(week) < parseInt(current_season_week)) ? ' locked' : '')} name={`week${week}`} value={week} onClick={handleWeekPlayersConfirm}  >{parseInt(week)}</li>)
                        }

                    </ul>
                </div>
            </div>
            <div className="lineup-score-sec">
                <div className="week-count">
                    <p>WEEK {lineUpWeek} {showAutoLineUpLabel && <span className="lineup-label">Automatic lineup</span>}</p>{lineUpNumber > 0 && <h4>Lineup {lineUpNumber}</h4>}
                </div>
                <div className="lineup-btn-sec">
                    <div className="lineup-count">Lineup
                        <SimpleBar>
                            <div className="lineup-count-wrap">
                                <ul className="count-info">
                                    {
                                        getAdditionalLineUpNumber()
                                    }
                                </ul>
                            </div>
                        </SimpleBar>

                        {(props.contestData.contestDetails[0].contest_status_id == 3 && changedLineup === false) ? <Dropdown>
                            <Dropdown.Toggle id="lineup-dropdown" className="remove-drop"><i className="ic-ellipsis"></i></Dropdown.Toggle>
                            <Dropdown.Menu>
                                {

                                    lineUps.map((lineUp, index) => {

                                        if (props.contestData && props.contestData.contestDetails && ((props.contestData.contestDetails[0].contest_status_id != 3) || lineUps.length <= 1)) {
                                            return (<Dropdown.Item href="" disabled="true"   ><i className="ic-remove"></i>Remove Lineup {lineUp.lineup_number}</Dropdown.Item>)
                                        } else {
                                            return (
                                                <Dropdown.Item href="" tabindex={index} value={lineUp.lineup_number} onClick={() => handleShowModal(lineUp.lineup_number)} ><i className="ic-remove"></i>Remove Lineup {lineUp.lineup_number}</Dropdown.Item>
                                            )
                                        }
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown> : ''}

                        <Modal show={showdeletemodal} onHide={handleDeleteModalClose} className="modal-predict">
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <h4 className="modal-title">{leaveContestConfirmMsg}</h4>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" value={lineUpNumber} onClick={removeLineup}>Yes</Button>
                                <Button variant="link" onClick={handleDeleteModalClose}>Cancel</Button>
                                
                            </Modal.Footer>
                        </Modal> 

                        <Modal show={showLoaderModal} onHide={hideLoderModal} className="modal-predict">
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                            <h4 className="modal-title">Checking refund status</h4>
                            <div className="loader-wrap"><div className="loader"></div></div>
                            </Modal.Body>
                        </Modal>
                    </div>


                    {
                        (props.contestData.contestDetails[0].contest_status_id == 3) ? walletAmount < entryFee
                            ?
                            (showLineupAddButton && maximumParticiationReached && props.contestData && props.contestData.contestDetails && props.contestData.contestDetails[0].contest_status_id == 3) ? <Button variant="danger" className="add-btn"    onClick={showInsufficientBalance} >Add  Lineup {commonService.toCurrency(entryFee)}</Button> : '' :

                            (showLineupAddButton && maximumParticiationReached && props.contestData && props.contestData.contestDetails && props.contestData.contestDetails[0].contest_status_id == 3) && <Button variant="primary" className="add-btn" onClick={nextJoinStepConfirm} >Add Lineup {commonService.toCurrency(entryFee)}</Button> : ''
                    }


                </div>
                <div className="total-score">
                    <p>TOTAL SCORE</p><h4>{parseFloat(totalLineUpScore) ? parseFloat(totalLineUpScore).toFixed(2) : '0.00'}</h4>
                </div>
            </div>

            {(lineUps && [2,3].includes(props.contestData.contestDetails[0].contest_status_id)) ? <CopyClearLineup showClearLineUp={showClearLineUp} showClearLineUpConfirmation={showClearLineUpConfirmation} lineUps = {lineUps} lineUpNumber ={lineUpNumber} isWeekCompleted = {isWeekCompleted} copyLineUp ={copyLineUp} contestStatus = {props.contestData.contestDetails[0].contest_status_id}/> : '' }

            <div className="lineup-main-list">
                
                <div className="player-card-info">{getLineUpPlayers(SelectedPlayerCardIndex)}</div>
            </div>

            <Tabs
                transition={false}
                id="player-info-tab"
                className="lineup-create-tab player-info-tab" 
                onSelect={(k) => changeTabs(k)}   
            >
                <Tab eventKey="players" title="Players">
                        {
                        (lineUpIsActive && (props.contestData.contestDetails[0].contest_status_id == 2 || props.contestData.contestDetails[0].contest_status_id == 3)) ?
                            <PlayerPositionList
                                selectedPlayers={lineUpPlayers}
                                callNextPositionOnSetup={callNextPositionOnSetup}
                                playerList={playerList}
                                lineUpNumber={lineUpNumber}
                                lineUpWeek={lineUpWeek}
                                quarterBackPlayer={quarterBackPlayer}
                                year={year}
                                value={siteState.data.Lobby.PlayerSearchFilterOptions}
                                onChange={(e) => { searchPlayers(e) }}
                                PaginationData={siteState.data.Lobby.PlayerPaginationData}
                                PageNumberChange={PageNumberChange}
                                current_season_week={current_season_week}
                                sortPlayers = {sortPlayers}
                                addPlayersToLineup = {addPlayersToLineup}
                                SelectedPlayerCardIndex = {SelectedPlayerCardIndex}
                                lineUpPlayers = {lineUpPlayers}
                                removePlayersFromLineup = {removePlayersFromLineup}
                                removedLineupPlayers = {removedLineupPlayers}
                                lineUpPlayersScores = {lineUpPlayersScores}
                            /> : ""
                        }
                </Tab>
                <Tab eventKey="injury-report" title="Injury Report">
                    <div className="player-report-table">{selectedTab == 'injury-report' && <InjuryReport /> }</div>
                </Tab>
                <Tab eventKey="news" title="News">
                    {selectedTab == 'news' && <NewsList /> }
                </Tab>
                <Tab eventKey="depth-chart" title="Depth Charts">
                    {selectedTab == 'depth-chart' && <DepthChart week ={lineUpWeek}/> }
                </Tab>
                
            </Tabs>

            <Modal show={showPredictModal} onHide={cancelJoinStep} className="modal-predict" onShow={setFocus}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="modal-title">Enter your final tiebreaker for this additional lineup.</h4>
                    <div className="score-selector">
                        <div className="inputgroup">
                            <KOInput
                                id="score"
                                placeholder="Enter the Tiebreaker score"
                                name="score"
                                type="text"
                                maxLength={6}
                                value={tiebreakerScore}
                                errorMessage={tieScoreError}
                                txtRef={refTB}
                                onChange={(e) => { handleScoreChange(e); }}
                            />
                            {isValidScore && <span className="ic-tick"></span>}
                        </div>
                        {(availableScores.length > 0) &&
                            <div className="score-list">
                                    <ul className="scroll-custom">
                                            {availableScores.map((item, index) => (
                                                (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                    <li key={'1sBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                    <li key={'2sBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                        (item.val === activeScore ? " active" : "")
                                                    }>{item.val}</li>
                                            ))}
                                    </ul>
                                {/* <SimpleBar>
                                    <ul>
                                        {availableScores.map((item, index) => (
                                            (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                <li key={'1scoreSBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                <li key={'2scoreSBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                    (item.val === activeScore ? " active" : "")
                                                }>{item.val}</li>
                                        ))}
                                    </ul>
                                </SimpleBar> */}
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button variant="primary" onClick={nextJoinStep}>Continue</Button>
                    <Button variant="link" onClick={cancelJoinStep}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmModal} onHide={cancelJoinStep} className="modal-predict">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="modal-title">Please confirm that you wish to add an additional lineup</h4>
                    <p>By clicking Yes, you agree to the {TermsAndConditions()} and the
                        entry fee of <span className="value">{commonService.toCurrency(entryFee)}</span></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={nextJoinStep}>Yes</Button>
                    <Button variant="link" onClick={cancelPreviousJoinStep}>Back</Button>
                    
                </Modal.Footer>
            </Modal>
            <Modal show={showDataProcessingLoader} onHide={closeDataProcessingModal} className="modal-predict">
                <Modal.Body>
                    <div className="score-selector">
                        <div className="loader-wrap"><div className="loader"></div></div>
                    </div>
                </Modal.Body>
            </Modal>

            <DeletePlayerToastMessage />
            <AlertToastMessages toastMessage = {toastMessage} resetAlert = {resetAlert} />

            
           
            <Modal show={isInsufficientBalance} onHide={handleInsufficientBalanceModalClose} className="modal-predict">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <h4 className="modal-title">Add Lineup</h4>
                <p>You do not have sufficient amount in your wallet to add a lineup.</p> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleInsufficientBalanceModalClose}>OK</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showClearLineUpConfirmModal} onHide={handleClearLineupModalClose} className="modal-predict">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h4 className="modal-title">Are you sure you want to clear this lineup?</h4>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary"  onClick={clearLineUp}>Yes</Button>
                    <Button variant="link" onClick={handleClearLineupModalClose}>Cancel</Button>
                   
                </Modal.Footer>
            </Modal> 
           
            <Modal show={showRemoveLineupErrorModal} onHide={hideRemoveLineupErrorMessage} className="modal-predict">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <h4 className="modal-title">{RemoveLineupErrorMsg}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={hideRemoveLineupErrorMessage}>OK</Button>
                </Modal.Footer>
            </Modal>          
        </div>

    );
};

export default LineupSetup;
