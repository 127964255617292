import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>{props.injuryLabel}</Tooltip>
);
const PlayerInjured = props => {
    let injuryLabel = 'Questionable';
    if(props.injuryStatus == 1) {
      injuryLabel = 'QUESTIONABLE';
    } else if(props.injuryStatus == 2) {
      injuryLabel = 'OUT';
    } else if(props.injuryStatus == 3) {
      injuryLabel = 'PUP-R';
    } else if(props.injuryStatus == 4) {
      injuryLabel = 'NFI-R';
    } else if(props.injuryStatus == 5) {
      injuryLabel = 'OUT';
    } else if(props.injuryStatus == 6) {
      injuryLabel = 'DOUBTFUL';
    }
    return (
      <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip" {...props}>{injuryLabel}</Tooltip>}
      injuryLabel = {injuryLabel}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" className="player-injury">
        <g id="Group_16587" data-name="Group 16587" transform="translate(-473 -1135)">
          <g id="Group_16585" data-name="Group 16585" transform="translate(109 445)">
            <rect id="Rectangle_1828" data-name="Rectangle 1828" width="28" height="14" rx="7" transform="translate(364 690)" fill="#ff4545"/>
            <path id="Path_10453" data-name="Path 10453" d="M-7.8,0H-6.55V-7.645H-7.8ZM.237-7.656v5.7l-3.773-5.7H-4.79V0h1.254V-5.709L.237,0H1.491V-7.656ZM6.155-2.134c0,.638-.319,1.1-.99,1.1s-.979-.462-.99-1.1H2.909A2.1,2.1,0,0,0,5.165.077,2.1,2.1,0,0,0,7.42-2.134V-7.645H6.155Z" transform="translate(377.805 700.657)" fill="#fff"/>
          </g>
        </g>
      </svg>
    </OverlayTrigger>
      

        
    );

}

export default PlayerInjured;