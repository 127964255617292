
export const lobbyFilterOptions = Object.freeze({
    All: { label: "ALL", value: 0 },
    Live: { label: "LIVE", value: 1 },
    Upcoming: { label: "UPCOMING", value: 2 },
    Expired: { label: "EXPIRED", value: 3 },
    Completed: { label: "COMPLETED", value: 4 },
    Unpublished: { label: "UNPUBLISHED", value: 8 },
    Joined: { label: "Joined", value: 0 },
    NotJoined: { label: "NotJoined", value: 0 },
});