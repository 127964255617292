import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "react-form-stepper";
import ChangeMailStep1 from "../components/ChangeMailStep1";
import ChangeMailStep2 from "../components/ChangeMailStep2";
import ChangeMailStep3 from "../components/ChangeMailStep3";
import ChangeMailStep4 from "../components/ChangeMailStep4";

const ChangeMailStepsPopup = (props) => {
    const [activeStep, setActiveStep] = useState(0);   
    const [steps, setSteps] = useState([{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }, { label: 'Step 4' }]);  
    const [emailChangeOTP, setEmailChangeOTP] = useState('');
    const sendDataToParent = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    const modalCloseHandle =() => {
        props.handleCloseEmailsec();
    }
    const setOtpForEmailChange =(otp) => {
        setEmailChangeOTP(otp);
    }
    switch(activeStep) {
        case 0:
            return (
                <>
                    <div className="stepper">
                        <Stepper
                            steps={steps}
                            activeStep={activeStep}
                        />
                    </div>
                    <div className="sidebar-body">
                        <ChangeMailStep1 sendDataToParent = {sendDataToParent} />
                    </div>
                </>
            );
        case 1:
            return (
                <>
                    <div className="stepper">
                        <Stepper
                            steps={steps}
                            activeStep={activeStep}
                        />
                    </div>
                    <div className="sidebar-body">
                        <ChangeMailStep2 sendDataToParent = {sendDataToParent} setOtpForEmailChange = {setOtpForEmailChange}/>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <div className="stepper">
                        <Stepper
                            steps={steps}
                            activeStep={activeStep}
                        />
                    </div>
                    <div className="sidebar-body">
                        <ChangeMailStep3 sendDataToParent = {sendDataToParent} emailChangeOTP ={emailChangeOTP}/>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className="stepper">
                        <Stepper
                            steps={steps}
                            activeStep={activeStep}
                        />
                    </div>
                    <div className="sidebar-body">
                        <ChangeMailStep4 modalCloseHandle={modalCloseHandle} sendDataToParent = {sendDataToParent}/>
                    </div>
                </>
            );
    }

};

export default ChangeMailStepsPopup;