import React, { useState, useEffect, useRef } from "react";
import KOStandingLineupcard from '../components/KOStaningLineupCard';
import PlayerStandings from '../components/PlayerStandings';
import * as contestService from "../services/contestService";

const WeeklyUsersLineup = (props) => {
    const [contestLineupSelectedWeek, setContestLineupSelectedWeek] = useState(props.contestSelectedWeek); // Selected weeks from screen
    const [contestLineup, SetcontestLineup] = useState(props.selectedLineUp); //Selected line up from screen
    const contestId = props.constest_id;
    const selectedUserId = props.selectedUserId;
    const isuserGame = props.isuserGame;
    const [showLoader, setLoader] = useState(false);
    const [userLineUps, setUserLineUps] = useState([]); // user line ups data for showing line up selection
    const [selectedLineupData, setSelectedLineupData] = useState([]); //Seelected line up data from API
    const [playerWeeklyStatus, SetPlayerWeeklyStatus] = useState([]); //Line up player status for showing in bottom from API
    const [lineupPlayersScores, SetlineupPlayersScores] = useState([]); //Line up player scores from API
    const [lineupStrategy, SetLineupStrategy] = useState([]); //Line up strategy for user game from API
    const [lineupPlayers, SetlineupPlayers] = useState([]); //Line up players from API

    let playersCard = [
        { className: "quarterback", playername: "", figure: "", score: "0.00", position: "Quarterback", dataIndex: "0", placePosition: "QB" },
        { className: "runningback", playername: "", figure: "", score: "0.00", position: "RUNNING BACK", dataIndex: "1", placePosition: "RB" },
        { className: "runningback", playername: "", figure: "", score: "0.00", position: "RUNNING BACK", dataIndex: "2", placePosition: "RB" },
        { className: "widereceiver", playername: "", figure: "", score: "0.00", position: "WIDE RECEIVER", dataIndex: "3", placePosition: "WR" },
        { className: "widereceiver", playername: "", figure: "", score: "0.00", position: "WIDE RECEIVER", dataIndex: "4", placePosition: "WR" },
        { className: "widereceiver", playername: "", figure: "", score: "0.00", position: "WIDE RECEIVER", dataIndex: "5", placePosition: "WR" },
        { className: "tightend", playername: "", figure: "", score: "0.00", position: "TIGHT END", dataIndex: "6", placePosition: "TE" },
        { className: "flexplayer", playername: "", figure: "", score: "0.00", position: "FLEX PLAYER", dataIndex: "7", placePosition: "FL" },
        { className: "kicker", playername: "", figure: "", score: "0.00", position: "KICKER", dataIndex: "8", placePosition: "K" },
        { className: "defence", playername: "", figure: "", score: "0.00", position: " DEFENSE/SPECIAL", dataIndex: "9", placePosition: "DF" }
    ];

    const [siteGameLineupCard, setsiteGameLineupCard] = useState(playersCard); //Site game card dsiplay
    const [userGameLineupCard, setuserGameLineupCard] = useState([]); //User game card display

    //Fetching the weekly ranks
    useEffect(async () => {
        fetchLineupPlayers(contestLineup, contestLineupSelectedWeek)
    }, [contestLineup, contestLineupSelectedWeek]);

    //For user games we need to call card display function only after line up strategey array set
    useEffect(async () => {
        if(lineupStrategy && isuserGame && userGameLineupCard) {
            updateLineUpData(lineupPlayers, lineupPlayersScores);
        }
    }, [lineupStrategy, lineupPlayers, lineupPlayersScores ]);

    //Calling API for showing line up players
    const fetchLineupPlayers = async (lineup_number, week) => {
        setLoader(true)
        let request = {
            "si_contest_id" : contestId,
            "lineup_week" : week,
            "isUserGame" : isuserGame,
            "lineup_number" : lineup_number
        }
        await contestService.fetchWeeklyLineUp(request, selectedUserId)
        .then(function (response) {
            setLoader(false)
            if(response.errorCode == 200) {
                if(isuserGame) {
                    updatePlayersCardDisplay(response.data.lineuStrategy[0]);
                    SetLineupStrategy(response.data.lineuStrategy[0])
                }
                setsiteGameLineupCard(playersCard)
                setUserLineUps(response.data.lineups);
                updateSelectedLineupData(response.data.lineups);
                if(!isuserGame) {
                    updateLineUpData(response.data.lineUpPlayers, response.data.playerScoreData);
                }
                SetlineupPlayersScores(response.data.playerScoreData)
                SetlineupPlayers(response.data.lineUpPlayers)
                fetchPlayerStatus(week, lineup_number, selectedUserId)
                
            } else {
                setUserLineUps([]);
                setSelectedLineupData([])
                setsiteGameLineupCard(playersCard);
                SetPlayerWeeklyStatus([])
                SetlineupPlayersScores([])
                SetlineupPlayers([])
            }
        })
    }
    //Week selection
    const setLineupWeek = async (e) => {
        setContestLineupSelectedWeek(e.target.value)
    }
    //Line up number selection
    const setLineupNumber = async (e) => {
        SetcontestLineup(e.target.value)
    }
    // Updating selected line up data based on API response
    const updateSelectedLineupData = async (lineupdata) => {
        for(let i = 0 ; i <lineupdata.length ; i++) {
            if(lineupdata[i].lineup_number == contestLineup) {
                setSelectedLineupData(lineupdata[i])
            }
        }
    }
    //Appending players data based on API response
    const updateLineUpData = async (lineupPlayers, playerScoreData) => {
        let playerListByPositionDataArray = (isuserGame) ? [...userGameLineupCard] : [...playersCard];
        if(playerListByPositionDataArray.length) {
            for(let i = 0 ; i <lineupPlayers.length ; i++) {
                playerListByPositionDataArray[lineupPlayers[i].player_card_id].playername = lineupPlayers[i].player_full_name;
                playerListByPositionDataArray[lineupPlayers[i].player_card_id].figure = lineupPlayers[i].player_img_url;
                if(lineupPlayers[i].is_team) {
                    playerListByPositionDataArray[lineupPlayers[i].player_card_id].score = playerScoreData[lineupPlayers[i].team_key] ? playerScoreData[lineupPlayers[i].team_key] : '0.00';
                } else {
                    playerListByPositionDataArray[lineupPlayers[i].player_card_id].score = playerScoreData[lineupPlayers[i].player_id] ? playerScoreData[lineupPlayers[i].player_id] : '0.00';
                }
            }
            if(isuserGame) {
                setuserGameLineupCard(playerListByPositionDataArray)
            } else {
                setsiteGameLineupCard(playerListByPositionDataArray);
            }
        }
    }
    //Fetching players status
    const fetchPlayerStatus = async (lineUpWeek, lineupNumber, userId) => {
        await contestService.getWeeklyPlayersStatus(contestId, lineUpWeek, lineupNumber, userId, isuserGame)
        .then(function(result){
            if (result.errorCode == 200) {
                SetPlayerWeeklyStatus(result.data);
            } else {
                SetPlayerWeeklyStatus([])
            }
        })
    }
    //Updating user game custom card display
    const updatePlayersCardDisplay = async (lineupStrategy) => {
        let playersCard = [];
        let index = 0;
        if (lineupStrategy) {
            let total_qb = parseInt(lineupStrategy.total_qb);
            let total_rb = parseInt(lineupStrategy.total_rb);
            let total_wr = parseInt(lineupStrategy.total_wr);
            let total_te = parseInt(lineupStrategy.total_te);
            let total_fl = parseInt(lineupStrategy.total_fl);
            let total_ki = parseInt(lineupStrategy.total_ki);
            let total_df = parseInt(lineupStrategy.total_df);
            for (let i = 0; i < total_qb; i++) {
                let qbList = { className: "quarterback", playername: "", figure: "", score: "", position: "Quarterback", dataIndex: index, placePosition: "quarterback" + index, positionshortname : 'QB' }
                index++;
                playersCard.push(qbList);
            }

            for (let i = 0; i < total_rb; i++) {
                let rbList = { className: "runningback", playername: "", figure: "", score: "", position: "RUNNING BACK", dataIndex: index, placePosition: "runningback" + index, positionshortname : 'RB' }
                index++;
                playersCard.push(rbList);
            }

            for (let i = 0; i < total_wr; i++) {
                let wrList = { className: "widereceiver", playername: "", figure: "", score: "", position: "WIDE RECEIVER", dataIndex: index, placePosition: "widereceiver" + index, positionshortname : 'WR' }
                index++;
                playersCard.push(wrList);
            }

            for (let i = 0; i < total_te; i++) {
                let teList = { className: "tightend", playername: "", figure: "", score: "", position: "TIGHT END", dataIndex: index, placePosition: "tightend" + index, positionshortname : 'TE' }
                index++;
                playersCard.push(teList);
            }

            for (let i = 0; i < total_fl; i++) {
                let flList = { className: "flexplayer", playername: "", figure: "", score: "", position: "FLEX PLAYER", dataIndex: index, placePosition: "flexplayer" + index, positionshortname : 'FL' }
                index++;
                playersCard.push(flList);
            }

            for (let i = 0; i < total_ki; i++) {
                let kiList = { className: "kicker", playername: "", figure: "", score: "", position: "KICKER", dataIndex: index, placePosition: "kicker" + index, positionshortname : 'K' }
                index++;
                playersCard.push(kiList);
            }

            for (let i = 0; i < total_df; i++) {
                let dfList = { className: "defence", playername: "", figure: "", score: "", position: "DST", dataIndex: index, placePosition: "defence" + index, positionshortname : 'DF' }
                index++;
                playersCard.push(dfList);
            }
            setuserGameLineupCard(playersCard);
        }
    }

    return (
        <div className="lineup-container">
                    <div className="lineup-setup-wrap">
                        <div className="week-selector">
                            <p>Week</p>
                            <div className="week-scroll">
                                <ul className="count-info">
                                {
                                    props.weeks.map((week, index) => <li key={'liKey' + index} className={(contestLineupSelectedWeek === week ? 'selected' : '') + '' + ((parseInt(week) < parseInt(props.curentSeasonWeek)) ? ' locked' : '')} name={`week${week}`} value={week} onClick={setLineupWeek}>{week}</li>)
                                }
                                </ul>
                            </div>
                        </div>
                        <div className="lineup-score-sec">
                            <div className="week-count">
                                <p>WEEK {contestLineupSelectedWeek}</p>
                                <div className="line-tie">
                                    <h4>Lineup {contestLineup}</h4>
                                    <div className="vr"></div>
                                    <h4>Tiebreaker <span>{selectedLineupData.predicted_tie_score}</span></h4>
                                </div>
                            </div>
                            <div className="lineup-btn-sec">
                                <div className="lineup-count">
                                    Lineup
                                    <div className="lineup-count-wrap">
                                        <ul className="count-info">
                                            {
                                                userLineUps.map((lineupData, index) => <li key={'lineupkey' + index} className={((contestLineup === lineupData.lineup_number) ? 'selected' : '') + ' ' + ((lineupData.contest_lineup_status_id === 2) ? 'eliminated' : '') } name={`lineup_select${lineupData.lineup_number}`} value={lineupData.lineup_number} onClick={setLineupNumber}>{lineupData.lineup_number}</li>)
                                            }
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="total-score">
                                <div className='rank'>{selectedLineupData.week_user_rank ? selectedLineupData.week_user_rank : 0}<span>RANK</span></div>
                                <div className='point'><p><span>Pts</span>{selectedLineupData.lineupScore}</p></div>
                            </div>
                        </div>
                        <div className="lineup-main-list">
                            <div className="player-card-info">
                                {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                                {/* For displaying site games players */}
                                {!isuserGame  ?
									siteGameLineupCard
									.map((items, index) => (
                                    <KOStandingLineupcard
                                        className={items.className}
                                        figure={items.figure}
                                        playername={items.playername}
                                        score={items.score}
                                        position={items.position}
                                    /> 
                                )) : ''}
                                {/* For displaying user games players */}
                                {isuserGame  ?
									userGameLineupCard
									.map((items, index) => (
                                        <KOStandingLineupcard
                                            className={items.className}
                                            figure={items.figure}
                                            playername={items.playername}
                                            score={items.score}
                                            position={items.position}
                                        /> 
                                )) : ''}
                            </div>
                        </div>
                        <div className="player-stats-sec">
                        {playerWeeklyStatus.length > 0 &&
                        playerWeeklyStatus.map((items) => (
                            (items.player_id > 0 || items.player_position == 'DF') ?
                                <PlayerStandings
                                    playerposition={items.player_position}
                                    playername={items.player_full_name}
                                    game={items.game}
                                    PaYDS={items.passing_yards > 0 ? items.passing_yards : 0}
                                    PaTD={items.passing_touchdown > 0 ? items.passing_touchdown : 0}
                                    RuYDS={items.rushing_yards > 0 ? items.rushing_yards : 0}
                                    RuTD={items.rushing_touchdown > 0 ? items.rushing_touchdown : 0}
                                    figure={items.player_img_url}
                                    receptions={items.receptions > 0 ? items.receptions : 0}
                                    receiving_yards={items.receiving_yards > 0 ? items.receiving_yards : 0}
                                    receiving_touchdown={items.receiving_touchdown > 0 ? items.receiving_touchdown : 0}
                                    score={lineupPlayersScores[items.player_id] ? parseFloat(lineupPlayersScores[items.player_id]).toFixed(2) : 0}
                                    team_score={lineupPlayersScores[items.team_key] ? parseFloat(lineupPlayersScores[items.team_key]).toFixed(2) : 0}
                                    team_key={items.team_key}
                                    opponent_team_key={items.opponent_team_key}
                                    player_pic={items.player_img_url}
                                    player_id={items.player_id}
                                    total_turnovers={(items.total_turnovers) ? items.total_turnovers : '0'}
                                    fumbles_lost={(items.fumbles_lost) ? items.fumbles_lost : '0'}
                                    passing_interceptions={(items.passing_interceptions) ? items.passing_interceptions : '0'}
                                    turnover_touchdown={(items.turnover_touchdown) ? items.turnover_touchdown : '0'}
                                    sacks={(items.sacks) ? items.sacks : '0'}
                                    safety={(items.safety) ? items.safety : '0'}
                                    field_goalsmade={(items.field_goalsmade) ? items.field_goalsmade : '0'}
                                    zero_to_thirtynine_fg={(items.zero_to_thirtynine_fg) ? items.zero_to_thirtynine_fg : '0'}
                                    fourty_to_fourtynine_fg={(items.fourty_to_fourtynine_fg) ? items.fourty_to_fourtynine_fg : '0'}
                                    fifty_plus_fg={(items.fifty_plus_fg) ? items.fifty_plus_fg : ''}
                                    extra_pointsmade={(items.extra_pointsmade) ? items.extra_pointsmade : '0'}
                                    interceptions={(items.interceptions) ? items.interceptions : '0'}
                                    passing_interceptions_touchdown={(items.passing_interceptions_touchdown) ? items.passing_interceptions_touchdown : '0'}
                                    fumble_recovered =  {(items.fumble_recovered) ? items.fumble_recovered : '0'}
                                    is_inprogress={(items.is_inprogress) ? items.is_inprogress : 0}
                                    is_canceled={(items.is_canceled) ? items.is_canceled : 0}
                                    has_started={(items.has_started) ? items.has_started : 0}
                                    is_over={(items.is_over) ? items.is_over : 0}
                                    away_score={(items.away_score) ? items.away_score : 0}
                                    home_score={(items.home_score) ? items.home_score : 0}
                                    away_team={(items.away_team) ? items.away_team : ''}
                                    home_team={(items.home_team) ? items.home_team : ''}
                                /> : ''
                        ))
                    }
                        </div>
                    </div>
                </div>
    )
}

export default WeeklyUsersLineup;