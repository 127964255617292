import { useDispatch, useSelector } from "react-redux";
import * as siteReducer from "./siteReducer";

function useSiteState() {
    let dispatch = useDispatch();

    let changeToSiteGame = async (sortoptions, contestFilterOptions, lobbyFilterOption, isUserLeague, isManageGames, page) => {
        return dispatch(siteReducer.changeToSiteGame(sortoptions, contestFilterOptions, lobbyFilterOption, isUserLeague, isManageGames, page));
    }
    let SiteGamesCleared = async (   isUserLeague, isManageGames) => {
        return dispatch(siteReducer.SiteGamesCleared(  isUserLeague, isManageGames ));
    }
    let changeToUserGame = async (sortoptions, contestFilterOptions, lobbyFilterOption) => {
        return dispatch(siteReducer.changeToUserGame(sortoptions, contestFilterOptions, lobbyFilterOption, true));
    }

    let changeLobbyContestFilter = async (options, isJoinedOnly, sortoptions, isUserLeague, isManageGames) => {
        let { filterOptions, lobbyFilterOption } = options
        return dispatch(siteReducer.changeLobbyFilter(sortoptions, isJoinedOnly, filterOptions, lobbyFilterOption, isUserLeague, isManageGames));
    }

    let changePlayerSearchFilter = async (options, lineUpWeek, contestId = 0, isUserGame = false, lineup_number = 1, SortBy = '') => {
        let { year, position, searchData, placingPositionId } = options
        return dispatch(siteReducer.changePlayerFilter(year, position, searchData, placingPositionId, lineUpWeek, contestId, isUserGame, lineup_number, SortBy));
    }

    let changeLobbyContestSort = async (sortOptions) => {
        return dispatch(siteReducer.changeLobbySort(sortOptions));
    }

    let changeLobbyPageFilter = async (sortoptions, isJoinedOnly, filterOption, contestFilterOptions, isUserLeague, isManageLeague = false, changedPage, pagePathRefreshed) => {
        return dispatch(siteReducer.changeLobbyPageFilter(sortoptions, isJoinedOnly, filterOption, contestFilterOptions, isUserLeague, isManageLeague, changedPage, pagePathRefreshed));
    }

    let changeLobbyView = async (viewMode) => {
        return dispatch(siteReducer.changeLobbyView(viewMode));
    }


    let lineUpPlayerList = async (year, searchData, positionId, pageNumber, lineUpWeek, contestId, isUserGame, lineup_number = 1, sortBy) => {
        return dispatch(siteReducer.lineUpPlayerList(year, searchData, positionId, pageNumber, lineUpWeek, contestId, isUserGame, lineup_number, sortBy));
    }

    let addLineUp = async (tiebreakerScore, contestId, year, lineUpWeek) => {
        return dispatch(siteReducer.addLineUps(tiebreakerScore, contestId, year, lineUpWeek));
    }

    let addTieBreakerScore = async (tiebreakerScore, joinContestId, year, lineUpWk) => {
        return dispatch(siteReducer.addTieBreakerScore(tiebreakerScore, joinContestId, year, lineUpWk));

    }

    let joinUserContest = async (predictedTieScore, joinContestId, year, lineUpWk) => {
        return dispatch(siteReducer.joinUserContest(predictedTieScore, joinContestId, year, lineUpWk));
    }

    let getJoinContestData = async (contestId) => {
        return dispatch(siteReducer.getJoinContestById(contestId));
    }

    let removeLineup = async (contestId, lineUpNumber, lineUpWeek) => {
        return dispatch(siteReducer.removeLineup(contestId, lineUpNumber, lineUpWeek));
    }

    let storeContestId = async (contestId) => {
        return dispatch(siteReducer.storeContestId(contestId));
    }


    let storeLineUpDefault = async (value) => {
        return dispatch(siteReducer.storeLineUpDefault(value));
    }

    let lineUpPlayerListByPosition = async (year, position, searchData, positionId, page, lineup_week, contestId, isUserGame, lineup_number = 1, sortBy = 'name') => {
        return dispatch(siteReducer.lineUpPlayerListByPosition(year, position, searchData, positionId, page, lineup_week, contestId, isUserGame, lineup_number, sortBy));
    }

    let lineUpPositionPlayerCardView = async (teamId, lineUpNumber, contestId, playerPosition, playerId, lineUpWeek, isteam, placingPosition, year, sortBy = 'name') => {
        return dispatch(siteReducer.lineUpPositionPlayerCardView(teamId, lineUpNumber, contestId, playerPosition, playerId, lineUpWeek, isteam, placingPosition, year, sortBy));
    }

    let isShowPositionList = async (value) => {
        return dispatch(siteReducer.isShowPositionList(value));
    }
    let setSearchText = async (value) => {
        return dispatch(siteReducer.siteReducerSetSearchText(value));
    }
    let lineUpPlayerListById = async (playerId) => {
        return dispatch(siteReducer.lineUpPlayerListById(playerId));
    }

    let showPlusDeleteIcon = async (value) => {
        return dispatch(siteReducer.showPlusDeleteIcon(value));
    }

    let getAllPlayersList = async (contestId, lineUpNumber, lineUpWk) => {
        return dispatch(siteReducer.getAllPlayersList(contestId, lineUpNumber, lineUpWk));
    }

    let lineUpTeamPlayerList = async (year, searchData, id, isUserGame, contest_id, lineUpWk, lineup_number = 1, sortBy = 'name') => {
        return dispatch(siteReducer.lineUpTeamPlayerList(year, searchData, id, isUserGame, contest_id, lineUpWk, lineup_number, sortBy));
    }

    let playerPositionUpdate = async (position) => {
        return dispatch(siteReducer.playerPositionUpdate(position));
    }

    let setLeftSideMenu = async (page) => {
        return dispatch(siteReducer.setLeftSideMenu(page));
    }

    let removeAllLineUpPlayers = async () => {
        return dispatch(siteReducer.removeAllLineUpPlayers());
    }


    let removeLineupPlayers = async (payload) => {
        return dispatch(siteReducer.removeLineupPlayers(payload));
    }

    let updateSelectedPlayerCard = async (cardIndex) => {
        return dispatch(siteReducer.updateSelectedPlayerCard(cardIndex));
    }

    let getSeasonStatsResponse = async (seasonStatsParams, staus_user_id) => {
        return dispatch(siteReducer.getSeasonStatsResponse(seasonStatsParams, staus_user_id));
    }

    let getWinnersResponse = async (params) => {
        return dispatch(siteReducer.getWinnersResponse(params));
    }
    let getSeasonStandingsResponse = async (params, isUserLegue) => {
        return dispatch(siteReducer.getSeasonStandingsResponse(params, isUserLegue));
    }
    let getSeasonLeaderboard = async (params, isUserLegue) => {
        return dispatch(siteReducer.getSeasonLeaderboardReducer(params, isUserLegue));
    }
    let updateSelectedPlayerCardForUsergame = async (cardIndex) => {
        return dispatch(siteReducer.updateSelectedPlayerCardForUsergame(cardIndex));
    }

    let removeAllLineUpPlayersForUsergame = async () => {
        return dispatch(siteReducer.removeAllLineUpPlayersForUsergame());
    }

    let changePlayerSearchFilterForUsergame = async (options) => {
        let { year, position, searchData, placingPositionId } = options
        return dispatch(siteReducer.changePlayerFilterForUsergame(year, position, searchData, placingPositionId));
    }

    let addLineUpForUsergame = async (contestId, year, predictedTieScore, lineUpWeek) => {
        return dispatch(siteReducer.addLineUpForUsergame(contestId, year, predictedTieScore, lineUpWeek));
    }

    let removeLineupForUsergame = async (contestId, lineUpNumber, lineUpWeek) => {
        return dispatch(siteReducer.removeLineupForUsergame(contestId, lineUpNumber, lineUpWeek));
    }

    let getAllPlayersListForUsergame = async (lineUpNumber, contestId, lineUpWk) => {
        return dispatch(siteReducer.getAllPlayersListForUsergame(lineUpNumber, contestId, lineUpWk));
    }

    let saveUsergameLineUpPositionPlayerCardView = async (teamId, playerId, lineUpNumber, playerPosition, contestId, lineUpWeek, isteam, playerCardId, year, sortBy) => {
        return dispatch(siteReducer.saveUsergameLineUpPositionPlayerCardView(teamId, playerId, lineUpNumber, playerPosition, contestId, lineUpWeek, isteam, playerCardId, year, sortBy));
    }

    let removeUsergameLineupPlayers = async (payload) => {
        return dispatch(siteReducer.removeUsergameLineupPlayers(payload));
    }

    let changeTeamSearchFilter = async (options, isUserGame = 1, contest_id, lineUpWeek, lineup_number, sortBy) => {
        let { year, position, searchData, placingPositionId } = options
        return dispatch(siteReducer.changeTeamSearchFilter(year, searchData, placingPositionId, isUserGame, contest_id, lineUpWeek, lineup_number, sortBy));

    }

    let getUnreadMessages = async (payload) => {
        return dispatch(siteReducer.getUnreadMessages(payload));
    }

    let rulesAndScoring = async (payload) => {
        return dispatch(siteReducer.rulesAndScoring(payload));
    }

    let clearLineUp = async (payload) => {
        return dispatch(siteReducer.clearLineUp(payload));
    }

    let copyLineUp = async (payload) => {
        return dispatch(siteReducer.copyLineUp(payload));
    }


    let data = useSelector((state) => state.site);
    let action = {
        changeToSiteGame,
        SiteGamesCleared, 
        changeToUserGame,
        changeLobbyContestFilter,
        changeLobbyContestSort,
        changeLobbyPageFilter,
        changeLobbyView,
        lineUpPlayerList,
        addLineUp,
        addTieBreakerScore,
        joinUserContest,
        getJoinContestData,
        removeLineup,
        storeContestId,
        lineUpPlayerListByPosition,
        lineUpPositionPlayerCardView,
        isShowPositionList,
        setSearchText,
        changePlayerSearchFilter,
        storeLineUpDefault,
        lineUpPlayerListById,
        showPlusDeleteIcon,
        getAllPlayersList,
        lineUpTeamPlayerList,
        playerPositionUpdate,
        setLeftSideMenu,
        removeLineupPlayers,
        removeAllLineUpPlayers,
        updateSelectedPlayerCard,
        getWinnersResponse,
        getSeasonStandingsResponse,
        getSeasonLeaderboard,
        updateSelectedPlayerCardForUsergame,
        removeAllLineUpPlayersForUsergame,
        changePlayerSearchFilterForUsergame,
        addLineUpForUsergame,
        removeLineupForUsergame,
        getAllPlayersListForUsergame,
        saveUsergameLineUpPositionPlayerCardView,
        removeUsergameLineupPlayers,
        changeTeamSearchFilter,
        getSeasonStatsResponse,
        getUnreadMessages,
        rulesAndScoring,
        clearLineUp,
        copyLineUp
    };

    return { data, action };
}

export default useSiteState;
