import React from "react";


const HornIcon1 = props => {

    return (
        
      <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24.899" height="21.957" viewBox="0 0 24.899 21.957">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1748" data-name="Rectangle 1748" width="24.899" height="21.957" fill="none"/>
        </clipPath>
      </defs>
      <g id="Group_16224" data-name="Group 16224" transform="translate(0 0)">
        <g id="Group_16223" data-name="Group 16223" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_10374" data-name="Path 10374" d="M5.559,13.348l1.905,6.963a1.255,1.255,0,0,0,2.209.406,1.216,1.216,0,0,0,.211-1.093L8.16,13.348l1.073-.022H11.9l9.253,4.265a.931.931,0,0,0,.573.211c1.995,0,2.435-6.734,2.435-8.4S23.724,1,21.724,1a.931.931,0,0,0-.573.211L11.887,5.452H4.982a3.944,3.944,0,0,0-.473,7.864Z" transform="translate(-0.131 -0.131)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_10375" data-name="Path 10375" d="M13.521,6.12a10.363,10.363,0,0,1,0,7.874" transform="translate(-1.765 -0.799)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
    </svg>
    
      


    );

}

export default HornIcon1;