import React, { useState } from 'react';
import PasswordactiveIcon from '../assets/icons/password';
import HidePasswordactiveIcon from '../assets/icons/hidepassword';

const KOPasswordinputgroup = props => {
    const [flag_Password, setFlag_ShowPassword] = useState(false);
    return (
        <div className="form-group">
            <div className="inputgroup passwordinput">
                <label>{props.label}</label>
                <input
                    className={props.class ? `form-control ${props.class}` : "form-control"}
                    id={props.id}
                    name={props.name}
                    type={flag_Password ? "text" : "password"}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    ref={props.txtRef}
                    onKeyDown={props.onKeyDown}
                    autoCapitalize={props.autocapitalize}
                />
                <span className='icon passwordicon' onClick={() => { setFlag_ShowPassword(!flag_Password);}}>
                    { flag_Password ? <HidePasswordactiveIcon /> : <PasswordactiveIcon/> }
                </span>
            </div>
            {props.errorMessage && props.errorMessage !== '' && <span className='error-message'>{props.errorMessage}</span>}
        </div>
    );
}

export default (KOPasswordinputgroup);
