import React, { useState, useEffect, useRef, useMemo , useCallback} from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import LiveTimer from "./LiveTimer";
import KOInput from "../components/KOInput";
import SimpleBar from 'simplebar-react';
import * as commonService from "../services/commonService";
import { pageURLs } from "../appData/pageURLs";
import { useDate } from '../components/DateDisplay';
import useSiteState from '../appState/siteState/useSiteState';
import useUserState from "../appState/userState/useUserState";
import { store } from '../appState/store';
import * as contestService from "../services/contestService";
import { FaqLink, TermsAndConditions } from '../utils/CommonHelper';
import EliminatedWeekly from "./EliminatedWeekly";
import moment from "moment"
import debounce from "lodash/debounce";


const ContestDetails = (props) => {

    const user = useUserState();


    const [showPredictModal, setPredictModal] = useState(false);
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [isValidScore, setIsValidScore] = useState(false);
    const [tiebreakerScore, setTiebreakerScore] = useState("");
    const [availableScores, setAvailableScores] = useState([]);
    const [joinStep, setJoinStep] = useState(0);
    const { monthDate, time, year } = useDate();
    const [showLoader, setLoader] = useState(false);
    const [showLocationCheckingModal, setLocationCheckingModal] = useState(false);
    let { id } = useParams()
    let contestId = parseInt(id);
    const [joinContestId, setJoinContestId] = useState(contestId);
    const [tieScoreError, setTieScoreError] = useState("");
    const [activeScore, setSctiveScore] = useState("");
    const [existingTiebreakerScore, setExistingTiebreakerScore] = useState([]);
    const [enableJoinContest, setEnableJoinContest] = useState(true);
    const [locationData, setLocationData] = useState([]);
    const [locationEnabled, setLocationEnabled] = useState(true);
    const [contestStates, setContestStates] = useState(props.contestData.contestStates);
    const [isAvailableInAllStates, setIsAvailableInAllStates] = useState(props.contestData.contestDetails[0].is_available_in_all_states);
    const [showInsufficientFundModal, setshowInsufficientFundModal] = useState(false);
    const [showVerificationProcessModal, setshowVerificationProcessModal] = useState(false);
    const [notverifiedMessage, setnotverifiedMessage] = useState("Complete verification to join paid league");
    const [verificationBtnTitle, setverificationBtnTitle] = useState("Continue with Verification");
    const navigate = useNavigate();
    if (!contestId) {
        navigate(pageURLs.lobby);
    }

    const siteState = useSiteState();
    const userState = useUserState();
    const userId = userState.data.user.user_id;
    let user_status = userState.data.user.user_status_id;
    let is_state_legal = userState.data.user.is_state_legal;
    const siteData = store.getState().site;

    let resTotalLineup = "";
    let resJoinedOn = "";
    let resContestUserStatusId = "";
    const refTBS = useRef(null);
    localStorage.removeItem("lineupsArray");
    useEffect(async () => {
        await siteState.action.removeAllLineUpPlayers();
        siteState.action.storeContestId(contestId);
        setActiveMenu();
        if(props.contestData.contestDetails[0].entry_fee > 0) {
            let locationData =  navigator.geolocation.getCurrentPosition(success, error);
        } else {
            setLocationEnabled(true)
        }
    }, []);

    const nextJoinStep = async () => {
        await user.action.getUserData();
        if(userState.data.user.user_status_id != 1) { //Not verified users
            if(userState.data.user.user_status_id == 4  && (userState.data.user.tsevo_status_id == 1 || userState.data.user.tsevo_status_id == 2)) { //Verification failed or not started the verification
                setnotverifiedMessage('Complete verification to join paid league')
            } else if((userState.data.user.user_status_id == 4 || userState.data.user.user_status_id == 5) && userState.data.user.tsevo_status_id == 3) { //Verification failed or not started the verification
                setnotverifiedMessage('Identity verification was unsuccessful. Please try again. If you continue to encounter difficulties, please reach out to a Knockout representative at admin@fantasysportsknockout.com for assistance.')
                
            } else if(userState.data.user.user_status_id == 5) {
                setnotverifiedMessage("Your identity verification is still in progress. Please be patient - in rare cases, verification can take up to 24 hours. If it has been 24 hours or longer, please contact Knockout customer care at admin@fantasysportsknockout.com.");
                setverificationBtnTitle("Retry verification")
            }
            setshowVerificationProcessModal(true)
            return false;
        }
        if (joinStep == 0) {
            if(stateContestData.entry_fee > 0) {
               if(parseFloat(userState.data.user.wallet_amount) < parseFloat(stateContestData.entry_fee)) {
                    setshowInsufficientFundModal(true)
                    return false;
               }
            }
            //Limit contests to specific states
            if(isAvailableInAllStates == 0) {
                let user_state_code = userState.data.user.state_code;
                let contest_states = contestStates ? contestStates : [];
                let user_state_exist = false;
                for(let s =0 ; s < contest_states.length ; s++) {
                    if(contest_states[s].state_code == user_state_code) {
                        user_state_exist = true;
                    }
                }

                if(!user_state_exist) {
                    alert("This contest is not available in your State.");
                    return false;
                }
            }

            if(stateContestData.entry_fee > 0) {
                setLocationCheckingModal(true)
            }
           
            let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 1, 0);
            if(stateContestData.entry_fee) {
                if(locationEnabled) {
                    setExistingTiebreakerScore(currentTieBreakerScores);
                    
                    let legalState = (stateContestData.entry_fee) ? await checkLocationIndetity() : true;
                    setLocationCheckingModal(false);
                    if (!legalState) { //If user is not verified we need to show alert message immediately 
                        alert("Either your address verification is not complete yet or You are a resident of a state which is not eligible to play in contests that have entry fees or prizes. Please see our terms and conditions for further details. Consider creating or joining a user contest instead!.");
                        return false;
                    }
                } else {
                    setLocationCheckingModal(false);
                    alert("It seems like Geolocation, which is required for this page, is not enabled in your browser.");
                    return false;
                }
            } else {
                setExistingTiebreakerScore(currentTieBreakerScores);
               
            }
        }
        switch (joinStep) {
            case 0:
                sessionStorage.removeItem('lineupsArray');
                setTiebreakerScore("");
                setPredictModal(true);
                setConfirmModal(false);
                setJoinStep(joinStep + 1);
                break;
            case 1:
                let selectedTieBreaker = parseFloat(tiebreakerScore).toFixed(2);
                if (existingTiebreakerScore.includes(selectedTieBreaker)) {
                    setTieScoreError("This tiebreaker score has already been entered. Please enter another score.");
                } else if (commonService.isNumber(tiebreakerScore)) {
                    setTieScoreError('')
                    setPredictModal(false);
                    setConfirmModal(true);
                    setJoinStep(joinStep + 1);
                }
                break;
            case 2:
                let siteDetails;
                let lineUpWk = 1;
                setLoader(true)
                await siteState.action.addTieBreakerScore(parseFloat(tiebreakerScore), joinContestId, year, lineUpWk);
                
                siteDetails = store.getState().site;
                sessionStorage.setItem('contestId', contestId);
                sessionStorage.removeItem('contestJoinedAction');
                if (siteDetails.Lobby.JoinContestAddTieBreakerScore.errorCode === 200) {
                    let message = siteDetails.Lobby.JoinContestAddTieBreakerScore.message;
                    sessionStorage.setItem('contestJoinedAction', true);
                    navigate(pageURLs.createlineup + "/" + contestId);
                } else {
                    setLoader(false)
                    let message = siteDetails.Lobby.JoinContestAddTieBreakerScore.message;
                    let close_modal_popup = siteDetails.Lobby.JoinContestAddTieBreakerScore.data.close_modal_popup ? siteDetails.Lobby.JoinContestAddTieBreakerScore.data.close_modal_popup : false;
                    if(close_modal_popup) {
                        setPredictModal(false);
                        setConfirmModal(false);
                    }
                    alert(message);
                }
                break;
            default:
                cancelJoinStep();
                break;
        }
    }

    const setFocus = () => {
        refTBS.current.focus();
    }

    const cancelJoinStep = () => {
        setPredictModal(false);
        setConfirmModal(false);
        setJoinStep(0);
        setAvailableScores([]);
    }

    const cancelJoinPreviousStep = () => {
        setPredictModal(true);
        setConfirmModal(false);
        setJoinStep(1);
    }

    const closeContestJoinSteps = () => {
        setPredictModal(false);
        setConfirmModal(false);
        setJoinStep(0);
        setAvailableScores([]);
    }

    const [Flag_ShowCollapse, setFlag_ShowCollapse] = useState(false);

    const getCollapseClasses = () => {
        let result = "contest-details";
        if (Flag_ShowCollapse) {
            result += " collapsed";
        } else {
            result += " collapsable";
        }
        return result;
    }

    const handleScoreChange = (e) => {
        let scoreValue = e.target.value;
        let scoreValueArray = scoreValue.split(".");
        if (scoreValueArray.length > 2) {
            scoreValue = scoreValueArray[0] + '.' + scoreValueArray[1];
            scoreValue = parseFloat(scoreValue);
        }
        if (scoreValue === "") {
            setTiebreakerScore(scoreValue);
            setAvailableScores([]);
            setScoreList(0, 1);
        } else if (!commonService.isNumber(scoreValue)) {
            setAvailableScores([]);
            if (parseFloat(scoreValue).toFixed(2)) {
                if (!isNaN(scoreValue) && commonService.isFloat(parseFloat(scoreValue).toFixed(2))) {
                    setScoreList(scoreValue, 100.12);
                    var res = scoreValue.split(".");
                    setTiebreakerScore(scoreValue);
                } else {
                    setTiebreakerScore("");
                }
            }
        } else {
            if (scoreValue < 1000) {
                setTiebreakerScore(scoreValue);
                setScoreList(scoreValue, 100.12);
            }
        }
        setTieScoreError('');
    }

    const setScoreList = async (start, selected) => {
        let i;
        let scoreList = []
        let startVal = start;
        let displayVal;
        let selectedVal = parseFloat(selected).toFixed(2);
        debouncedSendRequest(start);

        for (i = 0; i < 18; i++) {
            startVal = parseFloat(startVal) + 0.01;
            displayVal = parseFloat(startVal).toFixed(2);
            if (displayVal === selectedVal) {
                scoreList.push({ "val": displayVal, "isActive": true });
            } else {
                scoreList.push({ "val": displayVal, "isActive": false });
            }
        }
        setAvailableScores(scoreList);
    };

    //Debouncing for avoiding multiple api calls
    const checkTieScore = useCallback(async (selectedVal) => {
        let currentTieBreakerScores = await contestService.getCurrentTieBreakerPointsInRange(contestId, 1, selectedVal);
        setExistingTiebreakerScore(currentTieBreakerScores)
    }, []);

    const debouncedSendRequest = useMemo(() => {
    return debounce(checkTieScore, 500);
    }, [checkTieScore]);

     // const debouncedSendRequest = debounce(checkTieScore, 1000);
    //Weekly elimination formula

    const [stateContestData, setStateContestData] = useState([]);
    useEffect(() => {
        if (props.contestData && props.contestData.contestDetails && props.contestData.contestDetails[0]) {
            siteState.data.Lobby.currentContestData = props.contestData.contestDetails[0];

            setStateContestData(props.contestData.contestDetails[0])

        } else {
            setStateContestData([]);
        }
    }, [props.contestData]);


    /*
     * Calculate the weekly halfs 
     */
    const getWeeklyHalves = (totalWeeks) =>  {
        let halfs = (totalWeeks > 1) ? (totalWeeks/2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf" : firstHalf,
            "secondHalf" : secondHalf
        }
    }

    const getNumberOfEliminationPerWeek = (totalWeeks , totalContestants ) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf/halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1)? firstWeekSubEliminatorCalculator: 0;
        return {
            'playersAliveSecondHalf' : numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf' : numPlayersEliminatedFirsHalf,
            'firstWeekElimination' : firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek' : firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks' : halves.firstHalf,
            'secondHalfWeeks' : halves.secondHalf,
            'minimumUsersRequired' : totalWeeks+1
        }
    }   

    const calculate = (totalContestants , totalWeeks ) => {
        if (totalContestants === 0 || totalWeeks === 0) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }

    const weeklyEliminationSplit = calculate( stateContestData.max_users,stateContestData.duration_in_weeks);
    //console.log("X", x);


    // let totalWeeks = 15;// stateContestData.duration_in_weeks;
    let totalWeeks = stateContestData.duration_in_weeks;
    let maxParticipants = stateContestData.max_users;
    //let maxParticipants =  2211;
    let weekDivident = totalWeeks / 2;
    let firstHalfWeekCount = Math.floor(weekDivident);//First half weeks number
    let secondHalfWeekCount = Math.ceil(weekDivident);//Second half weeks number
    let secondPartWeekStartFrom = 2;
    let thirdPartWeekStartFrom = parseInt(firstHalfWeekCount) + 1;
    thirdPartWeekStartFrom = stateContestData.contest_start_week == 1 ?thirdPartWeekStartFrom : (thirdPartWeekStartFrom+stateContestData.contest_start_week-1);
    let secondHalfParticipants = secondHalfWeekCount + 1;
    let secondHalfEliminatorCount = secondHalfWeekCount;
    let firstHalfParticipants = parseInt(maxParticipants) - parseInt(secondHalfParticipants);
    let firsthalfWeeklyElimintorCount = Math.floor(parseInt(firstHalfParticipants) / parseInt(firstHalfWeekCount));
    let balanceElimintorInFirstWeek = parseInt(firsthalfWeeklyElimintorCount) * parseInt(firstHalfWeekCount);
    let balanceElimintorCount = parseInt(firstHalfParticipants) - parseInt(balanceElimintorInFirstWeek);
    let firstWeekEliminatorCount = parseInt(firsthalfWeeklyElimintorCount) + parseInt(balanceElimintorCount);
    let firsthalfSecondSetWeeklyElimintorCount = parseInt(firsthalfWeeklyElimintorCount) * (parseInt(firstHalfWeekCount) - 1);
    if (totalWeeks == 1) {
        firstWeekEliminatorCount = parseInt(maxParticipants) - 1;
    }
    const locale = 'en-US';
    let dateCurrent = new Date(stateContestData.contest_start_time);
    let estNewDate = dateCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    // const contestStartDate = commonService.convertUTCDateToLocalDate(new Date(estNewDate));
    // const contestDate = contestStartDate.getDate();
    // const contestMonth = contestStartDate.toLocaleDateString(locale, { month: 'long', timeZone: "UTC" });
    // const contesttime = contestStartDate.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: "UTC" });
    let contestDate;
    if(stateContestData) {
        contestDate = moment.utc(stateContestData.contest_start_time).format('MMMM DD | h:mm A');
    }
    let dtCurrent = new Date(stateContestData.contest_start_time);
    let estDate = dtCurrent.toLocaleString('en-US', { timeZone: 'UTC' });
    let dtEst = new Date(estDate);
    const countDownDate = commonService.getTimeDifference(dtEst);

    const handleSelectAvaliableScore = (e) => {
        setTiebreakerScore(e.target.getAttribute('name'));
        setSctiveScore(e.target.getAttribute('name'));
    }

    const [stateContestUserData, setStateContestUserData] = useState([]);
    useEffect(() => {
        if (props.contestData && props.contestData.contestUserData && props.contestData.contestUserData[0]) {
            setStateContestUserData(props.contestData.contestUserData[0])
        } else {
            setStateContestUserData([]);
        }
    }, [props.contestData]);


    const setActiveMenu = async () => {
        if (!stateContestUserData || !stateContestUserData.si_contest_user_id || stateContestUserData.contest_user_status_id == 3 || !stateContestUserData.contest_user_status_id) {
            siteState.action.setLeftSideMenu('allcontest');
        } else if (stateContestData.contest_status_id === 3 && stateContestUserData.contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if (stateContestData.contest_status_id === 2 && stateContestUserData.contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        } else if ((stateContestData.contest_status_id === 4 || stateContestData.contest_status_id === 5) && stateContestUserData.contest_user_status_id != 3) {
            siteState.action.setLeftSideMenu('lobby');
        }
    }
    const viewLineUpPage = (e) => {
        navigate(pageURLs.createlineup + '/' + contestId);
    }

    const closeLocationCheckingModal = () => {
        setLocationCheckingModal(false);
    }

    const checkLocationIndetity = async () => {
        let result = await commonService.checkUserLocations(locationData);
        let is_state_legal = (result.data.is_state_legal) ? result.data.is_state_legal : 0
        let userResponse = await userState.action.SetUserStateLeagalData(is_state_legal);
        return is_state_legal;
    }


    const contestStatusChanged = (contestDisplayStatus) => {
        if (contestDisplayStatus == 2)
            setEnableJoinContest(false)
        else
            setEnableJoinContest(true)
    }

    const [totalContestLineUp, setTotalContestLineUp] = useState(0)
    useEffect(() => {

        // let contestLineUps = (props.totalContestLineUp >= 0) ? props.totalContestLineUp : stateContestData.total_lineups;
        setTotalContestLineUp(stateContestData.total_lineups);

    }, [props.totalContestLineUp, stateContestData])

    useEffect(() => {

        if (enableJoinContest && stateContestData.total_lineups == stateContestData.max_users)
            setEnableJoinContest(false)

    }, [stateContestData, enableJoinContest])

    const success = async (gotPosition) => {
        var uLat = gotPosition.coords.latitude;
        var uLon = gotPosition.coords.longitude;
        setLocationEnabled(true);
        setLocationData(gotPosition.coords)
      
    };
      
    const error = async (err) => {
        setLocationEnabled(false);
        setLocationData([])
    };

    const closeInsufficientFundModal = () => {
        setshowInsufficientFundModal(false);
    }

    const fundAddAction = (e) => {
        navigate(pageURLs.payment);
    }

    const continueVerification = (e) => {
        navigate(pageURLs.Verificationstart);
    }

    const closeVerification = (e) => {
        setshowVerificationProcessModal(false);
    }

    return (
        <div className={getCollapseClasses()}>
            <Button className="toggle-arrow m-show" onClick={() => { setFlag_ShowCollapse(!Flag_ShowCollapse); }}><i className="ic-chevron"></i></Button>
            <div className="general-info">
                <div className="title-sec">
                    <h3>{stateContestData.contest_name}</h3>
                    <div className="title-group">
                        {(stateContestData.contest_type_id === 1) && <span className="bg-label bg-standard">Standard</span>}
                        {(stateContestData.contest_type_id === 2) && <span className="bg-label bg-knockout">Knockout Plus</span>}
                        <div className="m-show"><LiveTimer contestStatusChanged={contestStatusChanged} Status={stateContestData.contest_status_id} countDownDate={countDownDate} /></div>
                    </div>
                </div>
                <div className="info-data">
                    <div className="form-group">
                        <label>Entry Fee</label>
                        <p>{commonService.toCurrency(stateContestData.entry_fee)}</p>
                    </div>
                    <div className="form-group">
                        <label>Prize</label>
                        <p>{commonService.toCurrency(stateContestData.total_prize_amt)}</p>
                    </div>
                    <div className="form-group">
                        <label>Entries</label>
                        <p>{stateContestData.total_lineups}/{stateContestData.max_users}</p>
                    </div>
                    {

                        /*  <div className="form-group">
                               <label>Categories</label>
                               {stateContestData.fee_entrytype_category != 'F' && <span className="badge bg-m">M</span>}
                               {stateContestData.guarantee_category == 'G' && <span className="badge bg-g">G</span>}
                           </div>
       
                           */
                    }
                    <div className="form-group">
                        <label>  Duration</label>
                        <p>Week {stateContestData.contest_start_week}     {stateContestData.contest_start_week != stateContestData.contest_end_week? ' - '+stateContestData.contest_end_week : ""}</p>
                    </div>
                    <div className="form-group">
                        <label>  Max Lineups</label>
                        <p>{stateContestData.max_lineup_per_user}</p>
                    </div>
                    {(stateContestData.total_weekly_winners > 0 && stateContestData.contest_type_id === 2) && <div className="form-group">
                        <label>Weekly Winners</label>
                        <p>{stateContestData.total_weekly_winners}</p>
                    </div>}
                    {(stateContestData.total_season_winners > 0 && stateContestData.contest_type_id === 2) && <div className="form-group">
                        <label>Season Sc. Winners</label>
                        <p>{stateContestData.total_season_winners}</p>
                    </div>}
                </div>
                <div className="info-data game-info-data">
                    <div className="form-group">
                        <label>League Scoring</label>
                        <p>Custom Scoring Rules</p>
                    </div>
                    <div className="form-group">
                        <label>League Strategy</label>
                        <p>Custom Positions</p>
                    </div>
                    <div className="game-formation">
                        <div className="game-position"><p><span>--</span>QB</p></div>
                        <div className="game-position"><p><span>--</span>RB</p></div>
                        <div className="game-position"><p><span>--</span>WR</p></div>
                        <div className="game-position"><p><span>--</span>FLEX</p></div>
                        <div className="game-position"><p><span>--</span>K</p></div>
                        <div className="game-position"><p><span>--</span>DST</p></div>
                    </div>
                </div>
                <div className="note">
                    <p>Eliminated Weekly*:</p>
                    <ul>

                        {
                            /*
                          <li>Week {stateContestData.contest_start_week}: {weeklyEliminationSplit.data.firstWeekElimination} 
                            
                            {weeklyEliminationSplit.data.firstWeekElimination > 1 ? ' Users ' : ' User '}</li>
        
                             {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 0 && <li>Week {stateContestData.contest_start_week? parseInt(stateContestData.contest_start_week)+1 : 2}  {firstHalfWeekCount == 2 ? "" : 
                             
                             stateContestData.contest_start_week==1? ' - '+ firstHalfWeekCount :' - '+ (thirdPartWeekStartFrom-1)
                             
                             }: {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek} {weeklyEliminationSplit.data.firstHalfEliminationOtherThanfirstWeek > 1 ? ' Users ' : ' User '}</li> }
        
                            {weeklyEliminationSplit.data.secondHalfWeeks > 0 && <li>Week {thirdPartWeekStartFrom}    {thirdPartWeekStartFrom ==stateContestData.contest_end_week? "" : ' - '+stateContestData.contest_end_week}: 1 User</li> }
         */
                        }
                        <EliminatedWeekly lineupWeekNumber='4' />
                    </ul>

                </div>

                {(parseInt(stateContestData.max_users) <= stateContestData.total_lineups && stateContestData.contest_status_id == 3 && (!stateContestUserData || !stateContestUserData.si_contest_user_id || stateContestUserData.contest_user_status_id == 3 || !stateContestUserData.contest_user_status_id) ) ? <div style={{color: "red"}} className="form-group">You are not allowed to join the contest, this contest is already full.</div> : ''}

               
            </div>

            <div className="time-info">
                <div className="d-show">
                    <LiveTimer contestStatusChanged={contestStatusChanged} Status={stateContestData.contest_status_id} countDownDate={countDownDate} /></div>
                <p>{contestDate} EST</p>


                {((stateContestData.contest_status_id === 3 && (!stateContestUserData || !stateContestUserData.si_contest_user_id || stateContestUserData.contest_user_status_id == 3 || !stateContestUserData.contest_user_status_id) && parseInt(stateContestData.max_users) > totalContestLineUp) && locationEnabled) ?
                    <Button variant="success" onClick={nextJoinStep} disabled={enableJoinContest ? '' : 'disabled'}>Join Contest ({commonService.toCurrency(stateContestData.entry_fee)})</Button> : null
                }
                {!locationEnabled && <div style={{color: "red"}}>Your browser does not support Geolocation</div>}


                {(stateContestUserData && stateContestUserData.si_contest_user_id && stateContestUserData.contest_user_status_id == 1) ?
                    <Button variant="success" onClick={viewLineUpPage}>Manage Line Up</Button> : null
                }

                <Modal show={showPredictModal} onHide={cancelJoinStep} className="modal-predict" onShow={setFocus}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Predict a Final Tiebreaker</h4>
                        <div className="score-selector">
                            <div className="inputgroup">
                                <KOInput
                                    id="score"
                                    placeholder="Enter the Tiebreaker Score"
                                    name="score"
                                    type="text"
                                    maxLength={6}
                                    value={tiebreakerScore}
                                    errorMessage={tieScoreError}
                                    txtRef={refTBS}
                                    onChange={(e) => { handleScoreChange(e); }}
                                />
                                {isValidScore && <span className="ic-tick"></span>}
                            </div>
                            {(availableScores.length > 0) &&
                                <div className="score-list">
                                    <ul className="scroll-custom">
                                            {availableScores.map((item, index) => (
                                                (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                    <li key={'1sBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                    <li key={'2sBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                        (item.val === activeScore ? " active" : "")
                                                    }>{item.val}</li>
                                            ))}
                                    </ul>
                                    {/* <SimpleBar>
                                        <ul>
                                            {availableScores.map((item, index) => (
                                                (item.isActive || existingTiebreakerScore.includes(item.val)) ?
                                                    <li key={'1sBkey' + index} className="active" name={item.val}>{item.val}</li> :
                                                    <li key={'2sBkey' + index} onClick={handleSelectAvaliableScore} name={item.val} className={
                                                        (item.val === activeScore ? " active" : "")
                                                    }>{item.val}</li>
                                            ))}
                                        </ul>
                                    </SimpleBar> */}
                                </div>
                            }
                        </div>
                        <p className="info">The unique game play of Fantasy Sports Knockout requires tiebreakers to determine which users are eliminated when there is a
                            tie (or multiple ties) in a given week. There are several tiebreakers based on the performance of the user's players prior
                            to this final one. Your score prediction is made once at the beginning of the season and is yours for the season. Please
                            note that the intent is to break ties when your entry can potentially be eliminated and it is not intended to be a correct
                            prediction - you hope to never need it! Check our {FaqLink()} for
                            more information about tiebreakers.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <Button variant="primary" onClick={nextJoinStep}>Continue</Button>
                        <Button variant="link" onClick={cancelJoinStep}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showConfirmModal} onHide={closeContestJoinSteps} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Are you sure you want to join this contest?</h4>
                        {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}

                        <p>By clicking Yes, you agree to the {TermsAndConditions()}  and the
                            entry fee of <span className="value">{commonService.toCurrency(stateContestData.entry_fee)}</span></p>
                    </Modal.Body>
                    <Modal.Footer>
                       
                        <Button variant="primary" onClick={nextJoinStep}>Yes</Button>
                        <Button variant="link" onClick={cancelJoinPreviousStep}>Back</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showLocationCheckingModal} onHide={closeLocationCheckingModal} className="modal-predict">
                    <Modal.Body>
                        <h4 className="modal-title">Checking your location</h4>
                        <div className="score-selector">
                            <div className="loader-wrap"><div className="loader"></div></div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showInsufficientFundModal} onHide={closeInsufficientFundModal} className="modal-predict">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Insufficient Funds</h4>

                        <p>Oops! Your wallet does not have enough balance to join paid leagues</p>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        
                        <Button variant="primary" onClick={fundAddAction}>Add Funds</Button>
                        <Button variant="link" onClick={closeInsufficientFundModal}>Maybe later</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showVerificationProcessModal} onHide={closeVerification} className="modal-coupon">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4 className="modal-title">Verification</h4>

                        <p>{notverifiedMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={continueVerification}>{verificationBtnTitle}</Button>
                        <Button variant="link" onClick={closeVerification}>Maybe later</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default ContestDetails;
