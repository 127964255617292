import React, { useState, useEffect} from 'react';
import {Table} from "react-bootstrap";
const TeamStats = props => {
    const seasonWeeks = props.seasonWeeks;
    const gameLogs = props.gameLogs;
    const totalStats = props.totalStats;
    const gameStats = () => {
        let gameStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            let total_dst_turnover = (thisWeekLog) ? parseInt(thisWeekLog.interceptions)+parseInt(thisWeekLog.fumble_recovered)+parseInt(thisWeekLog.fumbles_lost) : 0;
            let totalttd = (thisWeekLog) ? parseInt(thisWeekLog.passing_interceptions_touchdown)+parseInt(thisWeekLog.turnover_touchdown) : 0;
            gameStatsRow.push(
                (thisWeekLog && !isgameCancelled) ? <tr>
                    <td>{ totalttd ? totalttd : (thisWeekLog.has_started) ? 0 : '--' }</td>
                    <td>{ total_dst_turnover ? total_dst_turnover : (thisWeekLog.has_started) ? 0 : '--' }</td>
                    <td>{ thisWeekLog.sacks ? thisWeekLog.sacks.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                    <td>{ thisWeekLog.safety ? thisWeekLog.safety.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--' }</td>
                </tr> : 
                <tr>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
                
            );
        }
        return gameStatsRow;
    }

    //Score stats
    const scoreStats = () => {
        let scoreStatsRow = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let isgameCancelled = (thisWeekLog && thisWeekLog.is_schedule_canceled) ? thisWeekLog.is_schedule_canceled : 0;
            scoreStatsRow.push(
                (thisWeekLog && !isgameCancelled) ?<tr>
                        <td>{thisWeekLog.player_week_score ? thisWeekLog.player_week_score.replace(/\.00$/,'') : (thisWeekLog.has_started) ? 0 : '--'}</td>
                    </tr>:
                <tr>
                    <td>--</td>
                </tr>
            );
        }
        return scoreStatsRow;
    }
    return (
        <>
            <td colSpan={4}>
            <Table className="stat-table">
            <thead>
                <tr>
                <th>TDs</th>
                <th>TOs</th>
                <th>Sacks</th>
                <th>Safeties</th>
                
                </tr>
            </thead>
            <tbody>
                {gameStats()}  
                <tr>
                    <th>{ totalStats.totalDSTTds ? Number(totalStats.totalDSTTds).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalDSTTos ? Number(totalStats.totalDSTTos).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalDSTSacks ? Number(totalStats.totalDSTSacks).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                    <th>{ totalStats.totalDSTSafeties ? Number(totalStats.totalDSTSafeties).toFixed(2).replace(/\.00$/,'') : 0 }</th>
    
                </tr>              
            </tbody>
            </Table>
        </td>

       
        <td>
            <Table className="stat-table">
            <thead>
                <tr>
                <th>Score</th>
                </tr>
            </thead>
            <tbody>
                {scoreStats()}
                <tr>
                    <th>{ totalStats.totalPoints ? Number(totalStats.totalPoints).toFixed(2).replace(/\.00$/,'') : 0 }</th>
                </tr>       
            </tbody>
            </Table>
        </td>
    </>
);
  
}

export default (TeamStats);
