import React from "react";
import { Link } from "react-router-dom";

const Paginate = (props) => {

  const perPage = parseInt(props.paginationData.perPage);
  const currentPage = parseInt(props.paginationData.currentPage);
  const totalPages = parseInt(props.paginationData.totalPages);
  const pageRows = parseInt(props.pageRows);
  const totalRows = parseInt(props.paginationData.total);

  const prevPage = currentPage >= 1 ? currentPage - 1 : 1;
  const NextPage = currentPage >= 1 ? currentPage + 1 : 1;

  const displayPages = 5;
  const firstPage = 1;
  const leftAndRight = 3;

  let startPage = 1;
  let endPage = 1;
  let startMinus = 0;
  let extraPlus = 0;

  if (totalPages <= displayPages) {
    startPage = firstPage;
    endPage = totalPages;
  } else {
    //---- start page num start ----
    const start = currentPage - leftAndRight; // -1
    if (start < firstPage) {
      // less than 1
      startMinus = -1 * start;
    }
    startPage = start < firstPage ? firstPage : start;
    //---- start page num end ----

    //---- end page num start ----
    const end = currentPage + leftAndRight;
    endPage = end > totalPages ? totalPages : end;
    endPage = startMinus + endPage;
    //---- end page num end ----
  }

  const pageLi = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={"liKey" + i} className={i === currentPage ? "page-item active" : "page-item"}><button className="page-link" onClick={() => props.onClick(i)}>{i}</button>
        </li>
      );
    }
    return pages;
  };

  if (pageRows == 0) {
    return ("");
  }
  else {
    let records = totalRows == 1 ? ' record.' : ' records.';
    return (
      <div className="pagination-wrap">
        {perPage ? (
          <p>{"Showing " + (perPage * (currentPage - 1) + 1) + " to " + (parseInt(perPage * (currentPage - 1)) + parseInt(pageRows)) + " of " + totalRows + records} </p>) : ("")}
        <div></div>
        {
          totalPages > 1 ?
            <nav aria-label="Page navigation example">
              <ul className="pagination mb-0 justify-content-center">
                <li key="liFirstPage" className="arrow">
                  <button className="page-link " disabled={currentPage == startPage ? true : false} aria-label="First Page" onClick={() => props.onClick(startPage)} >{"<<"}</button>
                </li>

                <li key="liKeyPrev" className="page-item">
                  <button className="page-link" aria-label="Next Page" disabled={currentPage <= startPage ? true : false} onClick={() => props.onClick(prevPage)}
                  > {"<"}</button>
                </li>

                {pageLi()}

                <li key="liKeyNext" className="page-item">
                  <button className="page-link" disabled={NextPage > totalPages ? true : false} aria-label="Next Page" onClick={() => props.onClick(NextPage)} >{">"} </button>
                </li>

                <li key="liLastPage" className="arrow">
                  <button className="page-link " disabled={currentPage == totalPages ? true : false} aria-label="Last Page" onClick={() => props.onClick(totalPages)}  > {">>"} </button>
                </li>
              </ul>
            </nav> : ""
        }

      </div>
    );
  };
}
export default Paginate;
