import { counterActionType } from "./counterActionType";

export const Increment = () => {
    return {
        type: counterActionType.Increment
    };
}

export const Decrement = () => {
    return {
        type: counterActionType.Decrement
    };
}
