import React, { useEffect } from 'react';
import { Button } from "react-bootstrap";
import userpic from '../assets/user-default.png';

const statsPlayerRow = (player, position, teamMaster,handlePlayerModalShow) => {

  let total_dst_turnover = parseInt(player.interceptions)+parseInt(player.fumble_recovered)+parseInt(player.fumbles_lost_points
    );
  let totalttd = parseInt(player.passing_interceptions_touchdown)+parseInt(player.turnover_touchdown);
  let totaloffttd = parseInt(player.passing_interceptions_points)+parseInt(player.fumbles_lost_points);



  const data={
    playerId:player.player_id ? player.player_id : null,
    team: player.team_key,
    position:position?.title,
    className:position?.className
  }

    return (
        <div className={"standing-card stat-card " + position.className}>
            <div className="player-info">
                <figure>{player.player_img_url && player.player_img_url != '' ?<img src={player.player_img_url} alt="KO" /> : <img src={userpic} alt="KO" />}</figure>
                <div className="name">{player.player_full_name}</div>
            </div>
            <div className="standing-stats">
                <div className="stat-value game-value">
                    <div class="game-group">
                      <label>Game</label><p> 
                        {player.opponent_team_key ? player.opponent_team_key : ""} 
                        { player.team_key && player.opponent_team_key ? ' @ ' : "-"}
                        {player.team_key ? player.team_key : ""}
                        </p>
                    </div>
                </div>
                <div className="stat-value">
            <label>Score</label><p>{player.totalScore ? player.totalScore : '0'}</p>
          </div> 
          {/* {(position.code == 'QB' || player.player_position_abr == 'QB' ) &&  <div className="stat-value">
            <label>PaYDS</label><p>{player.passing_yards_points > 0 ? player.passing_yards_points.replace(/\.00$/,'') : "0"}</p>
          </div>}
          {(position.code == 'WR' || position.code == 'QB' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'QB' || player.player_position_abr == 'RB' ) && <div className="stat-value">
            <label>PaTD</label><p>{player.passing_touchdown_points > 0 ? player.passing_touchdown_points.replace(/\.00$/,'') : "0"}</p>
          </div> }

          {(position.code == 'WR' || position.code == 'QB' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'QB' || player.player_position_abr == 'RB' || player.player_position_abr == 'TE' || position.code == 'TE' ) && <div className="stat-value">
            <label>TO</label><p>{totaloffttd > 0 ? totaloffttd : "0"}</p>
          </div>}

          {(position.code == 'QB' || player.player_position_abr == 'QB' ) && <div className="stat-value">
            <label>RuYDS</label><p>{player.rushing_yards_points > 0 ? player.rushing_yards_points.replace(/\.00$/,'') : "0"}</p>
          </div> } */}
         
          {/* {(position.code == 'WR' || position.code == 'TE' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'TE' || player.player_position_abr == 'RB' ) && <div className="stat-value">
            <label>RuYDS</label><p>{player.rushing_yards_points > 0 ? player.rushing_yards_points.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'WR' || position.code == 'TE' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'TE' || player.player_position_abr == 'RB' ) &&  <div className="stat-value">
            <label>RECYDS</label><p>{player.receiving_yards_points > 0 ? player.receiving_yards_points.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'WR' || position.code == 'TE' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'TE' || player.player_position_abr == 'RB' ) && <div className="stat-value">
            <label>REC</label><p>{player.receptions_points > 0 ? player.receptions_points.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'WR' || position.code == 'QB' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'QB' || player.player_position_abr == 'RB' || player.player_position_abr == 'TE' || position.code == 'TE' ) && <div className="stat-value">
            <label>RuTD</label><p>{player.touchdown_points > 0 ? player.touchdown_points.replace(/\.00$/,'') : "0"}</p>
          </div>}
          
          {(position.code == 'WR' || position.code == 'QB' || position.code == 'RB' || player.player_position_abr == 'WR' || player.player_position_abr == 'QB' || player.player_position_abr == 'RB' || player.player_position_abr == 'TE' || position.code == 'TE') && <div className="stat-value">
            <label>RECTD</label><p>{player.receiving_touchdown > 0 ? player.receiving_touchdown.replace(/\.00$/,'') : "0"}</p>
          </div>}

          {(position.code == 'K' ||  player.player_position_abr == 'K') && <div className="stat-value">
            <label>FGM</label><p>{player.field_goalsmade > 0 ? player.field_goalsmade.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'K' ||  player.player_position_abr == 'K') && <div className="stat-value">
            <label>0-39</label><p>{player.zero_to_thirtynine_fg > 0 ? player.zero_to_thirtynine_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'K' ||  player.player_position_abr == 'K') && <div className="stat-value">
            <label>40-49</label><p>{player.fourty_to_fourtynine_fg > 0 ? player.fourty_to_fourtynine_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'K' ||  player.player_position_abr == 'K') && <div className="stat-value">
            <label>50+</label><p>{player.fifty_plus_fg > 0 ? player.fifty_plus_fg.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {(position.code == 'K' ||  player.player_position_abr == 'K') && <div className="stat-value">
            <label>EPM</label><p>{player.extra_pointsmade > 0 ? player.extra_pointsmade.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {position.code == 'DF' && <div className="stat-value">
            <label>TTD</label><p>{totalttd > 0 ? totalttd : "0"}</p>
          </div> }
          {position.code == 'DF' && <div className="stat-value">
            <label>TO</label><p>{total_dst_turnover > 0 ? total_dst_turnover : "0"}</p>
          </div> }
          {position.code == 'DF' && <div className="stat-value">
            <label>SACKS</label><p>{player.sacks > 0 ? player.sacks.replace(/\.00$/,'') : "0"}</p>
          </div> }
          {position.code == 'DF' && <div className="stat-value">
            <label>SAFETY</label><p>{player.safety > 0 ? player.safety.replace(/\.00$/,'') : "0"}</p>
          </div> } */}
          <Button variant="primary" onClick={() => handlePlayerModalShow(data)} className="stats-btn">Player Stats</Button>

            </div>
        </div>
    );
};

const playerPosition = (user_game) => {
    if(user_game) {
        let positions = [];
        let added_positions = [];
        let default_player_card = JSON.parse(sessionStorage.getItem('default_player_card'));
        let position_array = [];
        for(let i=0; i< default_player_card.length;i++) {
            position_array = [];
            if(default_player_card[i]['positionshortname'] == 'QB' && added_positions.includes('QB') == false ) {
                position_array =  { code: 'QB', title: 'Quarterback', h4: 'Quarterback', eventKey: 'quarterback', className: 'quarterback'}
                added_positions.push('QB');
            } else if(default_player_card[i]['positionshortname'] == 'RB' && added_positions.includes('RB') == false ) {
                position_array =  { code: 'RB', title: 'Running Back', h4: 'Running Back', eventKey: 'runningback', className: 'runningback', }
                added_positions.push('RB');
            } else if(default_player_card[i]['positionshortname'] == 'WR' && added_positions.includes('WR') == false ) {
                position_array =  { code: 'WR', title: 'Wide Receiver', h4: 'Wide receiver', eventKey: 'widereceivers', className: 'widereceiver', }
                added_positions.push('WR');
            } else if(default_player_card[i]['positionshortname'] == 'TE' && added_positions.includes('TE') == false ) {
                position_array =  { code: 'TE', title: 'Tight End', h4: 'Tight End', eventKey: 'tightend', className: 'tightend', }
                added_positions.push('TE');
            } else if(default_player_card[i]['positionshortname'] == 'FL' && added_positions.includes('FL') == false ) {
                position_array =  { code: 'FL', title: 'Flex Player', h4: 'Flex Player', eventKey: 'flexplayer', className: 'flexplayer', }
                added_positions.push('FL');
            } else if(default_player_card[i]['positionshortname'] == 'K' && added_positions.includes('K') == false ) {
                position_array =  { code: 'K', title: 'Kicker', h4: 'Kicker', eventKey: 'kicker', className: 'kicker', }
                added_positions.push('K');
            } else if(default_player_card[i]['positionshortname'] == 'DF' && added_positions.includes('DF') == false ) {
                position_array =  { code: 'DF', title: 'DST', h4: 'DST', eventKey: 'defence', className: 'defence', }
                added_positions.push('DF');
            }
            
            positions.push(position_array);
        }
        return positions;
    } else {
        return ([
            { code: 'QB', title: 'Quarterback', h4: 'Quarterback', eventKey: 'quarterback', className: 'quarterback', },
            { code: 'RB', title: 'Running Back', h4: 'Running Back', eventKey: 'runningback', className: 'runningback', },
            { code: 'WR', title: 'Wide Receiver', h4: 'Wide receiver', eventKey: 'widereceivers', className: 'widereceiver', },
            { code: 'TE', title: 'Tight End', h4: 'Tight End', eventKey: 'tightend', className: 'tightend', },
            { code: 'FL', title: 'Flex Player', h4: 'Flex Player', eventKey: 'flexplayer', className: 'flexplayer', },
            { code: 'K', title: 'Kicker', h4: 'Kicker', eventKey: 'kicker', className: 'kicker', },
            { code: 'DF', title: 'DST', h4: 'DST', eventKey: 'defence', className: 'defence', }
        ]);
    }
};

export { statsPlayerRow, playerPosition };