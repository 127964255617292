import React, { useState, useEffect } from "react";
import useSiteState from '../appState/siteState/useSiteState';

const EliminatedWeekly = props => {
    const siteState = useSiteState();
    const [eliminatedCount, setEliminatedCount] = useState(0);
    const [WeeklyEliminationData, setWeeklyEliminationData] = useState([]);


    useEffect(() => {

        if (siteState.data.Lobby.currentContestData ) {

            const currentContestData = siteState.data.Lobby.currentContestData;
            /* calculate(No: of weeks, No: of users, Start week, End week) */

            let lineupsToCheck = (currentContestData.contest_status_id == 2 ||currentContestData.contest_status_id == 5) ? currentContestData.total_lineups : currentContestData.max_users;

            const response = calculate(currentContestData.duration_in_weeks, lineupsToCheck, currentContestData.contest_start_week, currentContestData.contest_end_week);

            //const response = calculate(5, 4, 12, 16);//calculate(13, 500, 5, 17);

            if (response && response.data)
                setWeeklyEliminationData(response.data)
            else
                setWeeklyEliminationData([])

        }

    }, [props.lineupWeekNumber, siteState.data.Lobby.currentContestData]);



    //---------
    /*
            Calculate elimination per week 
  */

    const calculate = (totalWeeks, totalContestants, startweek = 1, endweek = 18) => {

        if (totalContestants === 0 || totalWeeks === 0 || (totalContestants <= totalWeeks)) {
            return { data: {}, message: "Invalid Input" }
        }
        let weeklyElimination = getNumberOfEliminationPerWeek(totalWeeks, totalContestants, startweek, endweek);
        return { data: weeklyElimination, message: "Weekly elimination logic processed successfully." };
    }

    /*
     * Calculate the weekly halfs 
     */
    const getWeeklyHalves = (totalWeeks) => {
        let halfs = (totalWeeks > 1) ? (totalWeeks / 2) : 1;
        let firstHalf = Math.floor(halfs);
        let secondHalf = totalWeeks - firstHalf;
        return {
            "firstHalf": firstHalf,
            "secondHalf": secondHalf
        }
    }

    /*
     * Calculate Elimination per week 
     */
    const getNumberOfEliminationPerWeek = (totalWeeks, totalContestants, startweek, endweek) => {
        let halves = getWeeklyHalves(totalWeeks)
        let numPlayersAliveSecondHalf = halves.secondHalf + 1;
        let numPlayersEliminatedFirsHalf = totalContestants - numPlayersAliveSecondHalf;
        let firstWeekSubEliminatorCalculator = Math.floor(numPlayersEliminatedFirsHalf / halves.firstHalf);
        let firstWeekEliminationDifference = numPlayersEliminatedFirsHalf - (firstWeekSubEliminatorCalculator * halves.firstHalf)
        let firstWeekElimination = firstWeekEliminationDifference + firstWeekSubEliminatorCalculator;

        let firstHalfEliminationOtherThanfirstWeek = (halves.firstHalf > 1) ? firstWeekSubEliminatorCalculator : 0;

        let midweekrange;
        let lastweekrange;

        /* Range Handler for week count 1 and 2 */
        midweekrange = (firstHalfEliminationOtherThanfirstWeek > 0) ? (halves.firstHalf > 1) ? startweek + 1 : startweek : 0;
        lastweekrange = (halves.secondHalf > 0) ? endweek : 0;

        /* Range Handler for week count 3 */
        midweekrange = (totalWeeks == 3) ? startweek + 1 : 0;

        /* Range Handler for week count GREATER than 3 */
        if (halves.firstHalf >= 2) {
            let midrangeStart = startweek + 1;
            midweekrange = (halves.firstHalf == 2) ? midrangeStart : midrangeStart + ' - ' + (endweek - halves.secondHalf);
        }

        if (halves.secondHalf >= 2) {
            let endweekValue = (endweek - halves.secondHalf) + 2;
            let endweekSame = (endweekValue == endweek) ? endweek : (endweek - halves.secondHalf) + 1 + ' - ' + endweek;
            lastweekrange = (halves.secondHalf >= 2) ? endweekSame : endweek;
        }

        return {
            'playersAliveSecondHalf': numPlayersAliveSecondHalf,
            'playersEliminatedFirstHalf': numPlayersEliminatedFirsHalf,
            'firstWeekElimination': firstWeekElimination,
            'firstHalfEliminationOtherThanfirstWeek': firstHalfEliminationOtherThanfirstWeek,
            'firstHalfWeeks': halves.firstHalf,
            'secondHalfWeeks': halves.secondHalf,
            'minimumUsersRequired': totalWeeks + 1,
            'startWeek': startweek,
            'endWeek': endweek,
            'firstWeek': startweek,
            'midWeekRange': midweekrange,
            'lastWeekRange': lastweekrange,
        }
    }


    return (
        <>
           

            {
                WeeklyEliminationData && WeeklyEliminationData.firstWeek ? <li>Week {WeeklyEliminationData.firstWeek}:  {WeeklyEliminationData.firstWeekElimination}
                    {WeeklyEliminationData.firstWeekElimination > 1 ? ' Users ' : ' User '}
                </li> : ""
            }

            {
                WeeklyEliminationData && WeeklyEliminationData.midWeekRange && !WeeklyEliminationData.firstHalfEliminationOtherThanfirstWeek ? <li>Week {WeeklyEliminationData.midWeekRange}:   1 User
                </li> : ""
            }

            {
                WeeklyEliminationData && WeeklyEliminationData.firstHalfEliminationOtherThanfirstWeek ? <li>Week {WeeklyEliminationData.midWeekRange}:  {WeeklyEliminationData.firstHalfEliminationOtherThanfirstWeek}
                    {WeeklyEliminationData.firstHalfEliminationOtherThanfirstWeek > 1 ? ' Users ' : ' User '}
                </li> : ""
            }

            {
                WeeklyEliminationData && WeeklyEliminationData.lastWeekRange ? <li>Week {WeeklyEliminationData.lastWeekRange}:  1 User</li> : ""
            }
        </>
    );

}

export default (EliminatedWeekly);
