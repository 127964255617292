import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>Elimination Zone</Tooltip>
  );

const KOContestantCard = props => {
    return (
        <>
            {props.showELiminationZoneFlag ? <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            >
            <div key={'KosC' + props.user_id} className={"contestant-card pl-selector " + " " + (props.className)} onClick={props.onClick}>
                <figure ><img src={props.figure}  /></figure>
                <div className="user-info">
                    <div className='name' >{props.username}</div>
                    <div className='lineup'> {props.user_id == props.loggedInUserId && <span className="you-label">You</span>}Lineup {props.lineup}</div>
                    <div className="standing">
                        <div className='rank'>Rank {props.rank}</div>
                        {props.type === "WeeklyStandings" && <div className='point'>Weekly Pts {props.ytdpoints ? props.ytdpoints : props.weeklypoints ? props.weeklypoints : '0.00'}</div>}
                        {props.type === "SeasonStandings" && <div className='point'>YTD Pts {props.ytdpoints?props.ytdpoints:'0.00'}</div>}
                    </div>
                    {/* <div className="standing">
                        <div className='rank'>Playing: 2</div>
                        <div className='point'>Left to Play: 4 </div>
                    </div> */}
                    {(props.type === "WeeklyStandings" && props.predicted_tie_score) && <div className="standing">
                    <div className='point'>Tiebreaker {props.predicted_tie_score ? props.predicted_tie_score : '0.00'}</div>
                    </div> }
                </div>
            </div>
            </OverlayTrigger> :
            <div key={'KosC' + props.user_id} className={"contestant-card pl-selector " + " " + (props.className)} onClick={props.onClick} >
            <figure ><img src={props.figure}  /></figure>
            <div className="user-info">
                <div className='name' >{props.username}</div>
                <div className='lineup'> {props.user_id == props.loggedInUserId && <span className="you-label">You</span>}Lineup {props.lineup}</div>
                <div className="standing">
                    <div className='rank'>Rank {props.rank}</div>
                    {props.type === "WeeklyStandings" && <div className='point'>Weekly Pts {props.ytdpoints ? props.ytdpoints : props.weeklypoints ? props.weeklypoints : '0.00'}</div>}
                    {props.type === "SeasonStandings" && <div className='point'>YTD Pts {props.ytdpoints?props.ytdpoints:'0.00'}</div>}
                </div>
                {/* <div className="standing">
                    <div className='rank'>Playing: 2</div>
                    <div className='point'>Left to Play: 4 </div>
                </div> */}
                {(props.type === "WeeklyStandings" && props.predicted_tie_score) && <div className="standing">
                <div className='point'>Tiebreaker {props.predicted_tie_score ? props.predicted_tie_score : '0.00'}</div>
                </div> }
            </div>
        </div> }
        </>
    );
}

export default (KOContestantCard);