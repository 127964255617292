import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './appState/store';
import ProtectedRoute from './components/ProtectedRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/common/main.scss';
import { pageURLs } from './appData/pageURLs';

import Login from './containers/Login';
import Signup from './containers/Signup';
import Forgotpassword from './containers/Forgotpassword';
import Emailrequest from './containers/Emailrequest';
import OTPVerification from './containers/OTPVerification';
import SignupStep1 from './containers/SignupStep1';
import SignupStep2 from './containers/SignupStep2';
import SignupStep3 from './containers/SignupStep3';
import SignupStep5 from './containers/SignupStep5';
import SignupStep4 from './containers/SignupStep4';
import Lobby from './containers/Lobby';
import MyContests from './containers/MyContests';
import MyAccountInfo from './containers/MyAccountInfo';
import JoinContest from './containers/JoinContest';
import UsergameJoinContest from './containers/UsergameJoinContest';
import CreateLineup from './containers/CreateLineup';
import UsergameCreateLineup from './containers/UsergameCreateLineup';
import CreateLeague from './containers/CreateLeague';
import LeagueDetails from './containers/LeagueDetails';
import CheckAddressVerificationStatus from './containers/CheckAddressVerificationStatus';
import UserCheck from './containers/UserCheck';
import EmailVerified from './containers/EmailVerified';

import Counter from './test/Counter'
import CounterNew from './test/CounterNew';
import ContestTest from './test/ContestTest';
import MessageBoard from './containers/MessageBoard';
import Payment from './containers/Payment';
import VerifyAddress from './containers/VerifyAddress';
import EditLeague from './containers/EditLeague';
import Withdrawfund from './containers/Withdrawfund';
import { gapi } from 'gapi-script';
import * as endPoints from './constants/endPoints'

// ----------refresh access token code start
import axios from 'axios';
import * as userAction from "./appState/userState/userAction";
import { UpdateUserAccessToken } from './components/UpdateUserAccessToken';

// ----------refresh access token code  end
import DisableDevtool from 'disable-devtool';
import ContestDetailView from './containers/ContestDetailView';
import Verificationstart from './containers/Verificationstart';
import VerifyIdentity from './containers/VerifyIdentity';
import VerificationFailed from './containers/VerificationFailed';

function App() {
    // ----------refresh access token code start
    const [reqInitiated, setReqInitiated] = useState(false)
    const [refreshedToken, setRefreshedToken] = useState("")
    useEffect(() => {
        let disable_dev_tool = (process.env.REACT_APP_DISABLE_DEV_TOOL >= 0) ? Number(process.env.REACT_APP_DISABLE_DEV_TOOL) : 1;
        //Disabling developer tool
        if(disable_dev_tool) {
            let APP_URL = (process.env.REACT_APP_REDIRECT_URL) ? process.env.REACT_APP_REDIRECT_URL : 'https://fantasysportsknockout.com/'
            let devtooldisableoptions = {
                "url" : APP_URL
            }
           DisableDevtool(devtooldisableoptions);
        }
        if (reqInitiated) {
            const userData = store.getState().user;
            let userSession = (userData) ? userData : null;
            let refreshToken = (userSession.user && userSession.user.refreshToken) ? userSession.user.refreshToken : null;

            let url = endPoints.REFRESH_TOKEN_URL ;
            let method = 'POST';
            let data = {};
            let headers = {
                'Content-Type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY,
                'x-refresh-token': (userSession.user && refreshToken) ? refreshToken : null,
               // "Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/',
        "Access-Control-Allow-Origin" : process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://app.fantasysportsknockout.com/'
            }
            axios({
                url, method, data, headers,
            }).then((refreshResponse) => {

                if (refreshResponse.data.errorCode == 200) {
                    userSession.user.accessToken = refreshResponse.data.data;
                    // update state

                    userAction.RefreshToken(userSession)
                    setReqInitiated(false);
                    setRefreshedToken(refreshResponse.data.data);

                } else if (refreshResponse.data.errorCode == 401) {
                    store.dispatch({
                        type: userAction.Type.RefreshToken,
                        payload: { status: 'Redirect to login' },
                    });
                }
            });
        }
    }, [reqInitiated])

    useEffect(() => {
        if (refreshedToken != "") {
            setRefreshedToken("");
        }

    }, [refreshedToken])
    // ==========axios.interceptors=========== start
    
    axios.interceptors.response.use(
        (res) => {
            if (res.status == 200) {
                if (reqInitiated) { }
                else {
                    if (res.data.errorCode === 401 || res.data.errorCode === 400) {
                        if (!reqInitiated) {
                            setReqInitiated(true)
                        }
                    } else if (res.data.errorCode === 500) {
                        window.location.href = pageURLs.root;
                    }
                }
                return res;
            }
        },
        (err) => {
            return Promise.reject(err);
        }
    );
    // ==========axios.interceptors=========== end

    const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: GOOGLE_APP_ID,
                scope: ''
            })
        };
        gapi.load('auth2', start);


        // usergames redirect fix
        const pagePathName = window.location.pathname;
        if (pagePathName.indexOf('/usergamejoincontest/') === 0) {
            sessionStorage.setItem('IsUserGame', 'true')
            sessionStorage.setItem('redirectUserTo', window.location.pathname.toString())
        } else {
            sessionStorage.removeItem('redirectUserTo')
        }
        // usergames redirect fix
        
    }, []);

    return (
        <div>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={pageURLs.root} element={<UserCheck />} />
                            <Route path={pageURLs.counter} element={<Counter />} />
                            <Route path={pageURLs.counterNew} element={<CounterNew />} />
                            <Route path={pageURLs.contestTest} element={<ContestTest />} />

                            <Route path={pageURLs.login} element={<Login />} />
                            <Route path={pageURLs.forgotpassword} element={<Forgotpassword />} />
                            <Route path={pageURLs.signup} element={<Signup />} />
                            <Route path={pageURLs.emailrequest} element={<Emailrequest />} />
                            <Route path={pageURLs.otpverification} element={<OTPVerification />} />
                            <Route path={pageURLs.signupstep1} element={<SignupStep1 />} />
                            <Route path={pageURLs.signupstep2} element={<SignupStep2 />} />
                            <Route path={pageURLs.signupstep3} element={<SignupStep3 />} />
                            <Route path={pageURLs.signupstep4} element={<SignupStep4 />} />
                            <Route path={pageURLs.signupstep5} element={<SignupStep5 />} />
                            <Route path={pageURLs.emailverified} element={<EmailVerified />} />
                            <Route path={`${pageURLs.contestdetails}/:id`} element={<ContestDetailView />} />
            
                            <Route path={pageURLs.lobby} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.lobby} />} />
                            </Route>
                            <Route path={pageURLs.manageleague} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.manageleague} />} />
                            </Route>
                            <Route path={pageURLs.joinedupcoming} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.joinedupcoming} />} />
                            </Route>
                            <Route path={pageURLs.joinedlive} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.joinedlive} />} />
                            </Route>
                            <Route path={pageURLs.joinedhistory} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.joinedhistory} />} />
                            </Route>
                            <Route path={pageURLs.mycontests} element={<ProtectedRoute />}>
                                <Route path={pageURLs.mycontests} element={<MyContests />} />
                            </Route>
                            <Route path={pageURLs.myaccountinfo} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.myaccountinfo}`} element={<MyAccountInfo />} />
                                <Route path={`${pageURLs.myaccountinfo}/:tab`} element={<MyAccountInfo />} />
                            </Route>
                            <Route path={`${pageURLs.joincontest}/:id`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.joincontest}/:id`} element={<JoinContest />} />
                            </Route>

                            <Route path={`${pageURLs.usergamejoincontest}/:id`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.usergamejoincontest}/:id`} element={<UsergameJoinContest />} />
                            </Route>


                            <Route path={`${pageURLs.createlineup}/:contestid`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.createlineup}/:contestid`} element={<CreateLineup />} />
                            </Route>

                            <Route path={`${pageURLs.usergamecreatelineup}/:contestid`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.usergamecreatelineup}/:contestid`} element={<UsergameCreateLineup />} />
                                <Route path={`${pageURLs.usergamecreatelineup}/:contestid/:tab`} element={<UsergameCreateLineup />} />
                            </Route>

                            <Route path={`${pageURLs.usergameviewleaders}/:contestid`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.usergameviewleaders}/:contestid`} element={<UsergameCreateLineup />} />
                            </Route>

                            <Route path={`${pageURLs.usergamestandings}/:contestid/:uId/:userType`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.usergamestandings}/:contestid/:uId/:userType`} element={<UsergameCreateLineup />} />
                            </Route>

                            <Route path={pageURLs.createleague} element={<ProtectedRoute />}>
                                <Route path={pageURLs.createleague} element={<CreateLeague />} />
                            </Route>
                            <Route path={`${pageURLs.leaguedetails}/:id`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.leaguedetails}/:id`} element={<LeagueDetails />} />
                            </Route>
                            <Route path={`${pageURLs.editleague}/:id`} element={<ProtectedRoute />}>
                                <Route path={`${pageURLs.editleague}/:id`} element={<EditLeague />} />
                            </Route>
                            <Route path={pageURLs.messageboard} element={<ProtectedRoute />}>
                                <Route path={pageURLs.messageboard} element={<MessageBoard />} />
                            </Route>
                            <Route path={pageURLs.payment} element={<ProtectedRoute />}>
                                <Route path={pageURLs.payment} element={<Payment />} />
                            </Route>
                            <Route path={pageURLs.verifyaddress} element={<VerifyAddress />} />
                            <Route path={pageURLs.checkaddressstatus} element={<ProtectedRoute />}>
                                <Route path={pageURLs.checkaddressstatus} element={<CheckAddressVerificationStatus />} />
                            </Route>
                            <Route path={pageURLs.withdrawfund} element={<ProtectedRoute />}>
                                <Route path={pageURLs.withdrawfund} element={<Withdrawfund />} />
                            </Route>
                            <Route path={pageURLs.Verificationstart} element={<ProtectedRoute />}>
                                <Route path={pageURLs.Verificationstart} element={<Verificationstart />} />
                            </Route>
                            <Route path={pageURLs.verifyIdentity} element={<ProtectedRoute />}>
                                <Route path={pageURLs.verifyIdentity} element={<VerifyIdentity />} />
                            </Route>

                            <Route path={pageURLs.verificationfailed} element={<ProtectedRoute />}>
                                <Route path={pageURLs.verificationfailed} element={<VerificationFailed />} />
                            </Route>

                            <Route path={pageURLs.allcontest} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.allcontest} />} />
                            </Route>

                            <Route path={pageURLs.alluserleague} element={<ProtectedRoute />}>
                                <Route path="" element={<Lobby viewURL={pageURLs.alluserleague} />} />
                            </Route>
                          
                            <Route path="*" element={
                                <main style={{ padding: "1rem" }}>
                                    <p>404 - There's nothing here!</p>
                                </main>
                            }
                            />
                        </Routes>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
