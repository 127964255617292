import * as apiService from "./apiService"
import * as endPoints from '../constants/endPoints'

export async function createTransactionRecord(payment_amount, transaction_action, customerIp, coords, paymentTypeValue = 'CC') {
    let accuracy = (coords && coords.accuracy) ? coords.accuracy : null;
    let altitude = (coords && coords.altitude) ? coords.altitude : null;
    let latitude = (coords && coords.latitude) ? coords.latitude : null;
    let longitude = (coords && coords.longitude) ? coords.longitude : null;
    let speed    = (coords && coords.speed) ? coords.speed : null;
    let payload = {
        "transaction_action" : transaction_action,
        "transaction_amount" : payment_amount,
        "customerIp" : customerIp,
        "time" : new Date(),
        "accuracy" : accuracy,
        "altitude" : altitude,
        "latitude" : latitude,
        "longitude" : longitude,
        "speed" : speed,
        "paymentType" : paymentTypeValue
    }
    let actionAPIUrl = (transaction_action == 'deposit') ? endPoints.TSEVO_PAYMENT_CREATION_URL : endPoints.TSEVO_PAYMENT_WITHDRAW_URL;
    return await apiService.apiCall(actionAPIUrl, 'POST', payload);
}
export async function getTransactionRecord(transHistoryPageParams) {
    return await apiService.apiCall(endPoints.TRANSACTION_HISTORY, 'POST', transHistoryPageParams);
}

export async function makeUserGamePayment(customerIp, coords, contest_id) {
    let accuracy = coords.accuracy ? coords.accuracy : null;
    let altitude = coords.altitude ? coords.altitude : null;
    let latitude = coords.latitude ? coords.latitude : null;
    let longitude = coords.longitude ? coords.longitude : null;
    let speed    = coords.speed ? coords.speed : null;
    
    let payload = {
        "customerIp" : customerIp,
        "time" : new Date(),
        "accuracy" : accuracy,
        "altitude" : altitude,
        "latitude" : latitude,
        "longitude" : longitude,
        "speed" : speed,
        "contest_id" : contest_id
    }
    let actionAPIUrl =  endPoints.USER_GAME_CREATE_PAYMENT;
    return await apiService.apiCall(actionAPIUrl, 'POST', payload);
}

export async function completePayment(payment_amount, transaction_action, orderId) {
    
    let payload = {
        "transaction_action" : transaction_action,
        "transaction_amount" : payment_amount,
        "order_id" : orderId
    }
    let actionAPIUrl = endPoints.TSEVO_PAYMENT_COMPLETION_URL ;
    return await apiService.apiCall(actionAPIUrl, 'POST', payload);
}