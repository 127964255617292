import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import KORadiobutton from "../components/KORadiobutton";
import WeeklyUsersLineup from '../components/WeeklyUsersLineup';
import WeeklyRankings from '../components/WeeklyRankings';
import SeasonRankings from '../components/SeasonRankings';

const StandingsSetup = (props) => {
    const isuserGame = props.isuserGame;
    const contestDetails =  (isuserGame) ? props.contestData.contestDetails : props.contestData.contestDetails[0];
    const contestStartWeek = contestDetails.contest_start_week;
    const contestEndWeek = contestDetails.contest_end_week;
    const contestCurrentWeek = contestDetails.contest_current_week;
    const constest_id = (isuserGame) ? contestDetails.lg_contest_id : contestDetails.si_contest_id;
    const constest_status = contestDetails.contest_status_id ;
    const curentSeasonWeek = props.currentSeasonData.current_week;
    
    const [show, setShow] = useState(false);
    const [standingType, setStandingType] = useState('W');
    let defaultSelectedWeek = curentSeasonWeek;
    if(curentSeasonWeek < contestStartWeek) {
        defaultSelectedWeek = contestStartWeek;
    } else if(contestEndWeek < contestStartWeek) {
        defaultSelectedWeek = contestEndWeek;
    }
    if(constest_status == 5) {
        defaultSelectedWeek = contestEndWeek;
    }

    const [contestSelectedWeek, setSelectedWeek] = useState(defaultSelectedWeek);
    const [seasonRankings, setSeasonRankings] = useState([]);
    const [selectedLineUp, setSelectedLineUp] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const handleClose = () => {
        setShow(false);
        setSelectedLineUp('');
        setSelectedUserId('')
    }
    const handleShow = (lineUpNumber, user_id) => {
        setShow(true);
        console.log(lineUpNumber)
        setSelectedLineUp(lineUpNumber)
        setSelectedUserId(user_id)
    }
    //Setting contest weeks
    let weeks = []
    for (var i = contestStartWeek; i <= contestEndWeek; i++) {
        weeks.push(i)
    }
    //Standing type selection
    const setStandingTypeData = (e) => {
        setStandingType(e.target.value);
        setSelectedWeek(contestCurrentWeek)
        handleClose()
    }
    //Week selection
    const setActiveWeek = async (e) => {
        setSelectedWeek(e.target.value)
    }

    return (
       <div className="standings-info-container">
            <div className="standings-radio-group">
                <KORadiobutton
                    name="weeklyradio"
                    label="Weekly"
                    id="weeklyradio"
                    value="W"
                    checked={standingType == 'W' ? true : false}
                    onChange={(e) => { setStandingTypeData(e); }}
                />
                <KORadiobutton
                    name="seasonradio"
                    label="Season"
                    id="seasonradio"
                    value="S"
                    checked={standingType == 'S' ? true : false}
                    onChange={(e) => { setStandingTypeData(e); }}
                />
            </div>
            <div className="contestant-standings-wrap">
                {standingType == 'W' ? <div className="lineup-container">
                    <div className="lineup-setup-wrap">
                        <div className="week-selector">
                            <p>Week</p>
                            <div className="week-scroll">
                                <ul className="count-info">
                                {
                                    weeks.map((week, index) => <li key={'liKey' + index} className={(contestSelectedWeek === week ? 'selected' : '') + '' + ((parseInt(week) < parseInt(curentSeasonWeek)) ? ' locked' : '')} name={`week${week}`} value={week} onClick={setActiveWeek}>{week}</li>)
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> : ''}
                {standingType == 'W' ? <p className="week-title">WEEK {contestSelectedWeek}</p> : ''}
                {standingType == 'W' ? <WeeklyRankings handleShow ={handleShow}  constest_id = {constest_id} isuserGame = {isuserGame} contestSelectedWeek = {contestSelectedWeek} curentSeasonWeek ={curentSeasonWeek} constest_status = {constest_status}/> : ''}
                {standingType == 'S' ? <SeasonRankings handleShow ={handleShow}  constest_id = {constest_id} isuserGame = {isuserGame} /> : ''}
            </div>
            {standingType == 'W' ? <Modal show={show} onHide={handleClose} className="modal-lineup">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>Lineup</Modal.Title>
                        {show ? <WeeklyUsersLineup weeks= {weeks} selectedLineUp= {selectedLineUp} selectedUserId = {selectedUserId} constest_id = {constest_id} contestSelectedWeek = {contestSelectedWeek} curentSeasonWeek = {curentSeasonWeek}  isuserGame = {isuserGame}/> : ''}
                </Modal.Body>
            </Modal> : ''}
            
       </div>
       

    );
};

export default StandingsSetup;
