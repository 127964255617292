import React, { useState, useEffect} from 'react';
import { Button, Modal, Table, Tab, Tabs } from "react-bootstrap";
import Moment from 'react-moment';
import * as commonService from "../services/commonService";
import * as playerStatsService from "../services/playerStatsService";
import Paginate from "../components/Paginate";
import OffensePlayerStats from "../components/OffensePlayerStats";
import TeamStats from "../components/TeamStats";
import KickerStats from "../components/KickerStats";
import moment from "moment-timezone"
const PlayerStats = props => {
    const [selectedTab, setSelectedTab] = useState('news');
    const [injuryReportLoaded, setInjuryReportLoaded] = useState(false);
    const [injuryReports, setinjuryReports] = useState([]);
    const [showLoader, setLoader] = useState(false);
    const [showGameLogLoader, setgameLogLoader] = useState(false);
    const [newsPageData, setNewsPageData] = useState([]);
    const [newsList, setnewsList] = useState([]);
    const [currentSeason, setCurrentSeason] = useState('');
    const [seasonWeeks, setSeasonWeeks] = useState([]);
    const [playerDetails, setplayerDetails] = useState([]);
    const [gameLogs, setGameLogs] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);
    let defaultTotalStats = {
        'totalPassingComp' : 0,
        'totalPassingAtt' : 0,
        'totalPassingCompPerc' : 0,
        'totalPassingYards' : 0,
        'totalPassingTds' : 0,
        'totalPassingInts' : 0,
        'totalFLs' : 0,
        'totalPassingTwoPoint' : 0,
        'totalRushingAtt' : 0,
        'totalRushingYards' : 0,
        'totalRushingTds' : 0,
        'totalRushingTwoPoint' : 0,
        'totalReceivingTargets' : 0,
        'totalReceivingRec' : 0,
        'totalReceivingYards' : 0,
        'totalReceivingTds' : 0,
        'totalReceivingTwoPoint' : 0,
        'totalOtherTds' : 0,
        'totalPoints' : 0,
        'totalXPM' : 0,
        'totalXPA' : 0,
        'totalFGA' : 0,
        'totalFGM' : 0,
        'totalFourtyFGM' : 0,
        'totalFourtytoFourtyNineFGM' : 0,
        'totalFiftyPlusFGM' : 0,
        'totalDSTTds' : 0,
        'totalDSTTos' : 0,
        'totalDSTSacks' : 0,
        'totalDSTSafeties' : 0,
        //summary
        'totalYards' : 0,
        'totalTds' : 0,
        'totalINTs' : 0,
        'totalFGPercentage' : 0,
        'totalXPPercentage' : 0,
        'totalLNG' : 0,
    };
    const [totalStats, setTotalStats] = useState(defaultTotalStats);
    const initialPageParams = {
        page : 1
    }
    const [newsPageParams, setNewsPageParams] = useState(initialPageParams);
    const [nextGameDetails, setNextGameDetails] = useState();
    const [teamRanks, setTeamRanks] = useState('');

    const playerId = props.playerData.playerId;
    const team_key = props.playerData.team;
    let position = props.playerData.position.toUpperCase().trim();
    if(position == 'KICKER') {
        position = 'K';
    } else if(position === 'DST') {
        position = 'DF';
    }
    //Tab Changes
    const changeTabs = async (selectedTab) => {
        setSelectedTab(selectedTab)
    }

    const pageNumberChange = async(page) => {
        let pageparams ={
            page:page
        }
        setNewsPageParams(pageparams);
        let request_player_id = (position != 'DF') ? playerId : 0;
        let is_team = (position == 'DF') ? 1 : 0;
        let request_team_key = (position == 'DF') ? team_key : '';
        setLoader(true)
        await commonService.newsList(page, request_player_id, is_team, request_team_key)
            .then(async function (response) {
                setLoader(false)
                if(response.errorCode == 200) {
                    setnewsList(response.data.newsData);
                    setNewsPageData({
                        perPage: response.data.perPage ? response.data.perPage : 5,
                        totalPages: response.data.totalPages ? response.data.totalPages : 0,
                        total: response.data.total ? response.data.total : 0,
                        currentPage: response.data.currentPage ? response.data.currentPage : 0,
                        errorCode: response.errorCode ? response.errorCode : 0,
                    });
                } else {
                    setnewsList([]);
                }

        })
    }

    useEffect(async () => {
        let weekRange = await commonService.numberrange(1,18,1);
        setgameLogLoader(true)
        setLoader(true)
        setTotalStats(defaultTotalStats);
        setSeasonWeeks(weekRange);
        if(position == 'DF') {
            await playerStatsService.geTeamStats(team_key)
                .then(async function (response) {
                    setgameLogLoader(false)
                    setLoader(false)
                    if(response.errorCode == 200) {
                        setnewsList(response.data.newsData);
                        setCurrentSeason(response.data.currentSeason);
                        setplayerDetails(response.data.playerDetails);
                        setNewsPageData({
                            perPage: response.data.perPage ? response.data.perPage : 5,
                            totalPages: response.data.totalPages ? response.data.totalPages : 0,
                            total: response.data.total ? response.data.total : 0,
                            currentPage: response.data.currentPage ? response.data.currentPage : 0,
                            errorCode: response.errorCode ? response.errorCode : 0,
                        });
                        
                        createGameLogArray(response.data.playerStats);
                        if(response.data.playerDetails.division_rank) {
                            let teamRank = await commonService.ordinal_suffix_of(response.data.playerDetails.division_rank);
                            setTeamRanks(teamRank)
                        }
                        setPageLoaded(true)
                    } else {
                        setnewsList([]);
                        setplayerDetails([]);
                        setTeamRanks('');
                        setPageLoaded(true)
                    }
            })
        } else {
            await playerStatsService.getPlayerStats(playerId)
                .then(async function (response) {
                    setgameLogLoader(false)
                    setLoader(false)
                    if(response.errorCode == 200) {
                        setnewsList(response.data.newsData);
                        setNewsPageData({
                            perPage: response.data.perPage ? response.data.perPage : 5,
                            totalPages: response.data.totalPages ? response.data.totalPages : 0,
                            total: response.data.total ? response.data.total : 0,
                            currentPage: response.data.currentPage ? response.data.currentPage : 0,
                            errorCode: response.errorCode ? response.errorCode : 0,
                        });
                        setCurrentSeason(response.data.currentSeason);
                        setplayerDetails(response.data.playerDetails);
                        createGameLogArray(response.data.playerStats, response.data.playerDetails.player_position)
                        setPageLoaded(true)
                    } else {
                        setnewsList([]);
                        setplayerDetails([]);
                        setGameLogs([])
                        setPageLoaded(true)
                    }
            })
        }

    },[playerId,team_key, position]);

    useEffect(async () => {
        if(selectedTab == 'injury-report' && !injuryReportLoaded) {
            setLoader(true)
            if(position == 'DF') {
                await commonService.getTeamInjuryReports(team_key)
                .then(async function (response) {
                    setLoader(false)
                    setInjuryReportLoaded(true)
                    if(response.errorCode == 200) {
                        setinjuryReports(response.data.inuryData);
                    } else {
                        setinjuryReports([]);
                    }
                })
            } else {
                await commonService.getInjuryReports(playerId)
                .then(async function (response) {
                    setLoader(false)
                    setInjuryReportLoaded(true)
                    if(response.errorCode == 200) {
                        setinjuryReports(response.data.inuryData);
                    } else {
                        setinjuryReports([]);
                    }
                })
            }
        }
    },[selectedTab]);

    const createWeekRows = (gameLogs) => {
        let weekRows = [];
        for (let i = 0; i< seasonWeeks.length ;i++) {
            let thisWeekLog = gameLogs[seasonWeeks[i]];
            let gameSymbol = '@';
            let winningStatus;
            let finalScore ;
            if(thisWeekLog && thisWeekLog.home_team == playerDetails.team_key) {
                gameSymbol = 'VS';
            }
            
            if(thisWeekLog) {
                if(thisWeekLog.home_team == playerDetails.team_key) {
                    if(thisWeekLog.home_score > thisWeekLog.away_score && thisWeekLog.is_over) { //Game completed
                        winningStatus = 'W';
                    } else if(thisWeekLog.home_score == thisWeekLog.away_score && thisWeekLog.is_over) { //Game completed
                        winningStatus = 'T';
                    } else if(thisWeekLog.is_over){ //Game completed
                        winningStatus = 'L';
                    } else if(thisWeekLog.is_inprogress){ //Game in progress
                        winningStatus = thisWeekLog.quater_desc;
                    }
                    finalScore = thisWeekLog.home_score+'-'+thisWeekLog.away_score;
                } else if(thisWeekLog.away_team == playerDetails.team_key ) {
                    if(thisWeekLog.away_score > thisWeekLog.home_score && thisWeekLog.is_over) { //Game completed
                        winningStatus = 'W';
                    } else if(thisWeekLog.away_score == thisWeekLog.home_score && thisWeekLog.is_over) { //Game completed
                        winningStatus = 'T';
                    } else if(thisWeekLog.is_over){ //Game completed
                        winningStatus = 'L';
                    } else if(thisWeekLog.is_inprogress){ //Game in progress
                        winningStatus = thisWeekLog.quater_desc;
                    }
                    finalScore = thisWeekLog.away_score+'-'+thisWeekLog.home_score;
                }
            }
            
            weekRows.push(
                (thisWeekLog) ? <tr>
                    <td className="number">{seasonWeeks[i]}</td>
                    {/* <td><Moment format='ddd, DD MMM'>{thisWeekLog.schedule_date}</Moment></td> */}
                    <td>{moment.utc(thisWeekLog.schedule_date).format('ddd, DD MMM')}</td>
                    <td>{gameSymbol} {thisWeekLog.opponent_team_name}</td>
                    {(thisWeekLog.has_started &&  !thisWeekLog.is_canceled ) ? <td>{winningStatus == 'W' && <span className="win">W </span> } 
                        {winningStatus == 'L' && <span className="lost">L </span> } 
                        {(winningStatus != 'W' && winningStatus != 'L') ? <span className="lost"> {winningStatus}&nbsp;</span> : ''}
                        {finalScore}
                    </td> : '' }
                    {(thisWeekLog.is_schedule_canceled ) ? <td> 
                        <span className="lost">Canceled</span>
                    </td> : '' }
                    {(!thisWeekLog.has_started &&  !thisWeekLog.is_schedule_canceled ) ? <td>
                        {/* <Moment format='H:mm A'>{thisWeekLog.schedule_date}</Moment> */}
                        {displayGameTime(thisWeekLog.schedule_date)} 
                        </td> : '' }
                </tr> : 
                <><tr>
                    <td className="number">{seasonWeeks[i]}</td>
                    <td colSpan={3}><span className="week">BYE Week</span></td>
                </tr></>
            );
        }
        return weekRows;
    }
    const playerPosition = (playerPosition) => {
        let positionName = '';
        if(playerPosition == 'QB') {
            positionName = 'Quarterback';
        } else if(playerPosition == 'RB') {
            positionName = 'Running Back';
        } else if(playerPosition == 'WR') {
            positionName = 'Wide Receiver';
        } else if(playerPosition == 'TE') {
            positionName = 'Tight End';
        } else if(playerPosition == 'K') {
            positionName = 'Kicker';
        }
        return positionName;
    }

    const createGameLogArray = (logs, player_position) => {
        let logArray = [];
        let existingStatsSummary = defaultTotalStats;
        let nextGameStatusRetrieved = 0;
        for (let i = 0; i< logs.length ;i++) {
            logArray[logs[i].nfl_week] = logs[i];
            let isgameCancelled = (logs[i] && logs[i].is_schedule_canceled) ? logs[i].is_schedule_canceled : 0;
            if(player_position != 'K' && position != 'DF' && !isgameCancelled) {
                existingStatsSummary.totalPassingComp += (logs[i] && logs[i].passing_completions) ? Number(logs[i].passing_completions) : 0;
                existingStatsSummary.totalPassingAtt += (logs[i] && logs[i].passing_attempts) ? Number(logs[i].passing_attempts) : 0;
                existingStatsSummary.totalPassingCompPerc += (logs[i] && logs[i].passing_comp_perc) ? Number(logs[i].passing_comp_perc) : 0;
                existingStatsSummary.totalPassingYards += (logs[i] && logs[i].passing_yards) ? Number(logs[i].passing_yards) : 0;
                existingStatsSummary.totalPassingTds += (logs[i] && logs[i].passing_touchdown) ? Number(logs[i].passing_touchdown) : 0;
                existingStatsSummary.totalPassingInts += (logs[i] && logs[i].passing_interceptions) ? Number(logs[i].passing_interceptions) : 0;
               // existingStatsSummary.totalPassingFL += (logs[i] && logs[i].fumbles_lost && player_position == 'QB') ? Number(logs[i].fumbles_lost) : 0;
                existingStatsSummary.totalPassingTwoPoint += (logs[i] && logs[i].two_point_pass) ? Number(logs[i].two_point_pass) : 0;
                existingStatsSummary.totalRushingAtt += (logs[i] && logs[i].rushing_attempts) ? Number(logs[i].rushing_attempts) : 0;
                existingStatsSummary.totalRushingYards += (logs[i] && logs[i].rushing_yards) ? Number(logs[i].rushing_yards) : 0;
                existingStatsSummary.totalRushingTds += (logs[i] && logs[i].rushing_touchdown) ? Number(logs[i].rushing_touchdown) : 0;
                //existingStatsSummary.totalRushingFLs += (logs[i] && logs[i].fumbles_lost && player_position == 'RB') ? Number(logs[i].fumbles_lost) : 0;
                existingStatsSummary.totalRushingTwoPoint += (logs[i] && logs[i].two_point_conv_runs) ? Number(logs[i].two_point_conv_runs) : 0;
                existingStatsSummary.totalReceivingTargets += (logs[i] && logs[i].receiving_targets) ? Number(logs[i].receiving_targets) : 0;
                existingStatsSummary.totalReceivingRec += (logs[i] && logs[i].receptions) ? Number(logs[i].receptions) : 0;
                existingStatsSummary.totalReceivingYards += (logs[i] && logs[i].receiving_yards) ? Number(logs[i].receiving_yards) : 0;
                existingStatsSummary.totalFLs += (logs[i] && logs[i].fumbles_lost ) ? Number(logs[i].fumbles_lost) : 0;
                existingStatsSummary.totalReceivingTwoPoint += (logs[i] && logs[i].two_point_conv_receptions) ? Number(logs[i].two_point_conv_receptions) : 0;
                existingStatsSummary.totalOtherTds += (logs[i] && logs[i].all_other_touchdown) ? Number(logs[i].all_other_touchdown) : 0;
                existingStatsSummary.totalReceivingTds += (logs[i] && logs[i].receiving_touchdown) ? Number(logs[i].receiving_touchdown) : 0;
            } else if(player_position == 'K' && position != 'DF' && !isgameCancelled) {
                existingStatsSummary.totalXPM += (logs[i] && logs[i].extra_pointsmade) ? Number(logs[i].extra_pointsmade) : 0;
                existingStatsSummary.totalXPA += (logs[i] && logs[i].extra_points_attempted) ? Number(logs[i].extra_points_attempted) : 0;
                existingStatsSummary.totalFGA += (logs[i] && logs[i].field_goals_attempted) ? Number(logs[i].field_goals_attempted) : 0;
                existingStatsSummary.totalFGM += (logs[i] && logs[i].field_goalsmade) ? Number(logs[i].field_goalsmade) : 0;
                existingStatsSummary.totalFourtyFGM += (logs[i] && logs[i].zero_to_thirtynine_fg) ? Number(logs[i].zero_to_thirtynine_fg) : 0;
                existingStatsSummary.totalFourtytoFourtyNineFGM += (logs[i] && logs[i].fourty_to_fourtynine_fg) ? Number(logs[i].fourty_to_fourtynine_fg) : 0;
                existingStatsSummary.totalFiftyPlusFGM += (logs[i] && logs[i].fifty_plus_fg) ? Number(logs[i].fifty_plus_fg) : 0;
                let field_goals_longest_made = (logs[i] && logs[i].field_goals_longest_made) ? Number(logs[i].field_goals_longest_made) : 0;
                if(Number(field_goals_longest_made) > existingStatsSummary.totalLNG) {
                    existingStatsSummary.totalLNG = field_goals_longest_made;
                }

                //existingStatsSummary.totalLNG = (logs[i] && logs[i].field_goals_longest_made) ? Number(logs[i].field_goals_longest_made) : 0;
            } else if(position == 'DF' && !isgameCancelled) {
                let dst_interceptions = (logs[i] && logs[i].interceptions) ? Number(logs[i].interceptions) : 0;
                let dst_fumble_recovered = (logs[i] && logs[i].fumble_recovered) ? Number(logs[i].fumble_recovered) : 0;
                let dst_fumbles_lost = (logs[i] && logs[i].fumbles_lost) ? Number(logs[i].fumbles_lost) : 0;
                let dst_passing_interceptions_touchdown = (logs[i] && logs[i].passing_interceptions_touchdown) ? Number(logs[i].passing_interceptions_touchdown) : 0;
                let dst_turnover_touchdown = (logs[i] && logs[i].turnover_touchdown) ? Number(logs[i].turnover_touchdown) : 0;
                let total_dst_turnover = Number(dst_interceptions) +  Number(dst_fumble_recovered) + Number(dst_fumbles_lost);
                let totalttd = Number(dst_passing_interceptions_touchdown) +  Number(dst_turnover_touchdown);
                existingStatsSummary.totalDSTTds += totalttd;
                existingStatsSummary.totalDSTTos += total_dst_turnover;
                existingStatsSummary.totalDSTSacks += (logs[i] && logs[i].sacks) ? Number(logs[i].sacks) : 0;
                existingStatsSummary.totalDSTSafeties += (logs[i] && logs[i].safety) ? Number(logs[i].safety) : 0;
            }
            if(!isgameCancelled) {
                existingStatsSummary.totalPoints += (logs[i] && logs[i].player_week_score) ?  Number(logs[i].player_week_score) : 0;
            }
           //Next Game Details
           if(!nextGameStatusRetrieved && logs[i] && !logs[i].is_game_started && !isgameCancelled) {
                setNextGameDetails(logs[i]);
                nextGameStatusRetrieved = 1;
           }
        }
        if(player_position != 'K' && position != 'DF') {
            existingStatsSummary.totalYards = Number(existingStatsSummary.totalPassingYards) + Number(existingStatsSummary.totalRushingYards) + Number(existingStatsSummary.totalReceivingYards);
            existingStatsSummary.totalTds = Number(existingStatsSummary.totalPassingTds) + Number(existingStatsSummary.totalRushingTds) + Number(existingStatsSummary.totalReceivingTds) + Number(existingStatsSummary.totalOtherTds);
            existingStatsSummary.totalINTs = Number(existingStatsSummary.totalPassingInts) ;
        } else if(player_position == 'K' && position != 'DF' ) {
            let FGPercentage = (Number(existingStatsSummary.totalFGM)/Number(existingStatsSummary.totalFGA))*100;
            existingStatsSummary.totalFGPercentage = (FGPercentage) ? FGPercentage : 0;
            let XPAPercentage = (Number(existingStatsSummary.totalXPM)/Number(existingStatsSummary.totalXPA))*100;
            existingStatsSummary.totalXPPercentage = (XPAPercentage) ? XPAPercentage : 0;
        } 
        setTotalStats(existingStatsSummary)
        setGameLogs(logArray);
    }

    const displayGameTime = (date) => {
        try {
            return moment.tz(date.toString(), "YYYY-MM-DDTh:mm:ss","America/New_York").format('h:mm A z')
        }catch (error) {
            return "-";
        }
    }
        
    return (
        <>
            {pageLoaded ? <Modal show={props.showplayermodal} onHide={props.handlePlayerModalClose} className="modal-playerdetail">
            {playerDetails.player_full_name && <Modal.Header closeButton>
            {playerDetails.player_full_name && <div className={"player-info" + " " + (props.playerData.className)} >
                {playerDetails.player_full_name ? <figure><img src={playerDetails.player_img_url} /></figure> : ''}
                <div className="detail">
                <div className='name'><h1>{playerDetails.player_full_name}</h1></div>
                {position != 'DF' && <div className="team">
                    <div className="pl-data"><img src={playerDetails.team_logo_url}/><p>{playerDetails.team_name}</p></div>
                    {playerDetails.player_number ? <div className="pl-data"><p>#{playerDetails.player_number}</p></div> : ''}
                    <div className="pl-data"><p>{playerPosition(playerDetails.player_position)} {playerDetails.player_rank ? '(Rank: '+playerDetails.player_rank+')' : '' }</p></div>
                </div>}
                {position == 'DF' && <div className="team">
                    <div className="pl-data"><p>{playerDetails.wins}-{playerDetails.losses}</p></div>
                    {playerDetails.division_rank && <div className="pl-data"><p>{teamRanks} In {playerDetails.conference} {playerDetails.division}</p></div>}
                </div>}
                {position != 'DF' && <div className="activity">STATUS {playerDetails.is_player_active == 1 ? <p><span className="status active"></span>Active</p> :  <p><span className="status deactive"></span>Inactive</p>}</div>}
                
                </div>
            </div>}
            {(playerDetails.player_full_name && position != 'DF') && <div className="season-stats">
                <div className="stats-title">{currentSeason} Season Stats</div>
                {(position != 'DF' && position != 'K') && <div className="stats-info">
                <div className="info-block"><p>YDS</p><h4>{ totalStats.totalYards ? Number(totalStats.totalYards) : 0 }</h4></div>
                <div className="info-block"><p>TD</p><h4>{ totalStats.totalTds ? Number(totalStats.totalTds) : 0 }</h4></div>
                <div className="info-block"><p>INT</p><h4>{ totalStats.totalINTs ? Number(totalStats.totalINTs) : 0 }</h4></div>
                </div> }
                {position == 'K' && <div className="stats-info">
                <div className="info-block"><p>FG%</p><h4>{ totalStats.totalFGPercentage ? Number(totalStats.totalFGPercentage).toFixed(1) : 0 }</h4></div>
                <div className="info-block"><p>XP%</p><h4>{ totalStats.totalXPPercentage ? Number(totalStats.totalXPPercentage).toFixed(1) : 0 }</h4></div>
                <div className="info-block"><p>LNG</p><h4>{ totalStats.totalLNG ? Number(totalStats.totalLNG) : 0 }</h4></div>
                <div className="info-block"><p>PTS</p><h4>{ totalStats.totalPoints ? Number.parseFloat(totalStats.totalPoints).toFixed(2).replace(/\.00$/,'') : 0 }</h4></div>
                </div> }
            </div>}

            {(playerDetails.player_full_name && nextGameDetails && position == 'DF') ? <div className="game-wrap">
                <h5>Next Game</h5>
                <div className="match-info">
                <div className="team-info first-team"><p>{(nextGameDetails.home_team == playerDetails.team_key) ? playerDetails.team_name : nextGameDetails.opponent_team_name} <span>{nextGameDetails.home_wins}-{nextGameDetails.home_loss}</span></p>{(nextGameDetails.home_team == playerDetails.team_key) ? <img src={playerDetails.team_logo_url}/> : <img src={nextGameDetails.opponent_team_logo_url}/>}</div>
                <div className="match-date"><p>
                    
                    {/* <Moment format='DD/M'>{nextGameDetails.schedule_date}</Moment></p><p><Moment format='H:mm A'>{nextGameDetails.schedule_date}</Moment> */}
                    {moment.utc(nextGameDetails.schedule_date).format('M/DD')} </p><p> {displayGameTime(nextGameDetails.schedule_date)} 
                    </p></div>
                <div className="team-info second-team">{(nextGameDetails.away_team == playerDetails.team_key) ? <img src={playerDetails.team_logo_url}/> : <img src={nextGameDetails.opponent_team_logo_url}/>}<p>{(nextGameDetails.away_team == playerDetails.team_key) ? playerDetails.team_name : nextGameDetails.opponent_team_name} <span>{nextGameDetails.away_wins}-{nextGameDetails.away_loss}</span></p></div>
                </div>
            </div> : ''}
            </Modal.Header> }
            <Modal.Body>
            {(nextGameDetails && position != 'DF') ? <div className="game-wrap">
                <h5>Next Game</h5>
                <div className="match-info">
                <div className="team-info first-team"><p>{(nextGameDetails.home_team == playerDetails.team_key) ? playerDetails.team_name : nextGameDetails.opponent_team_name} <span>{nextGameDetails.home_wins}-{nextGameDetails.home_loss}</span></p>{(nextGameDetails.home_team == playerDetails.team_key) ? <img src={playerDetails.team_logo_url}/> : <img src={nextGameDetails.opponent_team_logo_url}/>}</div>
                <div className="match-date">
                    
                    {/* <p><Moment format='DD/M'>{nextGameDetails.schedule_date}</Moment></p><p><Moment format='H:mm A'>{nextGameDetails.schedule_date}</Moment></p> */}
                    <p>{moment.utc(nextGameDetails.schedule_date).format('M/DD')} </p><p> {displayGameTime(nextGameDetails.schedule_date)} </p>
                    </div>
                <div className="team-info second-team">{(nextGameDetails.away_team == playerDetails.team_key) ? <img src={playerDetails.team_logo_url}/> : <img src={nextGameDetails.opponent_team_logo_url}/>}<p>{(nextGameDetails.away_team == playerDetails.team_key) ? playerDetails.team_name : nextGameDetails.opponent_team_name} <span>{nextGameDetails.away_wins}-{nextGameDetails.away_loss}</span></p></div>
                </div>
            </div> : ''}
            <div className="game-log-sec">
                <h5>Game Log</h5>
                <div className="log-table-wrap">
                <Table className="log-table" responsive>
                    {!showGameLogLoader ? <><thead>
                    {(position != 'DF' && position != 'K') && <tr>
                        <th>{currentSeason} Regular season</th>
                        <th>Passing</th>
                        <th>Rushing</th>
                        <th>Receiving</th>
                        <th>FL</th>
                        <th>Other</th>
                        <th>Points</th>
                    </tr> }
                    {(position == 'K') && <tr>
                        <th >{currentSeason} Regular season</th>
                        <th colSpan={4}></th>
                        <th>Points</th>
                    </tr>}
                    {(position == 'DF') && <tr>
                        <th >{currentSeason} Regular season</th>
                        <th colSpan={4}>DST</th>
                        <th>Points</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {gameLogs.length ? <tr>
                        <td>
                        <Table className="stat-table">
                            <thead>
                                <tr>
                                <th>WK</th>
                                <th>Date</th>
                                <th>Opponent</th>
                                <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createWeekRows(gameLogs)}
                                <tr>
                                    <th className="total" colspan="4">TOTAL</th>
                                </tr>
                            </tbody>
                            </Table>
                        </td>

                        {(position != 'DF' && position != 'K') && <OffensePlayerStats seasonWeeks = {seasonWeeks} gameLogs = {gameLogs} player_position = {playerDetails.player_position} totalStats = {totalStats}/>}
                        {(position == 'DF') && <TeamStats seasonWeeks = {seasonWeeks} gameLogs = {gameLogs} totalStats = {totalStats}/>}
                        {(position == 'K') && <KickerStats seasonWeeks = {seasonWeeks} gameLogs = {gameLogs} totalStats = {totalStats}/>}
                    </tr> : <tr><td colSpan={6} style={{padding:"10px"}}><span style={{ color: "red" }}>No records to display.</span></td></tr> }
                    {/* {(position != 'DF' && position != 'K' && gameLogs.length > 0) && <OffensePlayerStatsTotal totalStats = {totalStats}/>}
                    {(position == 'DF' && gameLogs.length > 0) && <TeamStatsTotal totalStats = {totalStats}/>}
                    {(position == 'K' && gameLogs.length > 0) && <KickerStatsTotal totalStats = {totalStats}/>} */}



                    </tbody></> : <div className="loader-wrap"><div className="loader"></div></div> }
                </Table>
                </div>
            </div>
            <Tabs
                    transition={false}
                    id="player-info-tab"
                    className="lineup-create-tab player-info-tab"  
                    onSelect={(k) => changeTabs(k)}     
                >
                    <Tab eventKey="news" title="News">
                        <>
                        {(newsList.length >0) && <div className="player-news">
                            {newsList.map((news) => {  
                                return ( 
                                    <>
                                        <h4>{news.news_title}</h4>
                                        <p>{news.news_content}</p>
                                    </>
                                    
                                ) 
                            })}
                        </div>}

                            {newsPageData ? <Paginate paginationData={newsPageData} pageRows={newsList.length} onClick={pageNumberChange} /> : ""}
                            {(!newsList.length && !showLoader) ? <div className="player-news"><span style={{ color: "red" }}>No records to display.</span></div> : ''}
                            {showLoader && <div className="loader-wrap"><div className="loader"></div></div>}
                        </>
                    </Tab> 
                    <Tab eventKey="injury-report" title="Injury Report">
                        {(injuryReports.length) ?  <div className="table-wrap">
                         <Table responsive>
                            <thead>
                                <tr>
                                    {(position === 'DF') ? <th>Name</th> : ''}
                                    {(position === 'DF') ? <th>POS</th> : ''}
                                    <th>Date </th>
                                    <th>Status</th>
                                    <th>Injury Type</th>
                                    <th>Side</th>
                                    <th>Return Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {injuryReports.map((injury) => { 
                                    return ( 
                                    <tr>
                                        {(position === 'DF') ? <td>{injury.player_full_name}</td> : ''}
                                        {(position === 'DF') ? <td>{injury.player_position}</td> : ''}
                                        <td><Moment format='DD-MMMM'>{injury.imported_date}</Moment></td>
                                        <td>{injury.player_injury_status}</td>
                                        <td>{injury.player_injury_type}</td>
                                        <td>{injury.player_injury_side}</td>
                                        <td>{injury.player_return_date ? <Moment format='MM-DD-YYYY'>{injury.player_return_date}</Moment> : '-'}</td>
                                    </tr> 
                                    ) }) }
                            </tbody>
                        </Table> 
                        </div>: ''} 
                        {(!injuryReports.length && !showLoader) ? <div className="table-wrap" style={{ border: "0px" }}><span style={{ color: "red" }}>No injury reports to display.</span></div> : ''}
                        <div className="status-notation">
                        <h5>Status Notations:</h5>
                        <div className="notation-sec">
                            <p><span>QUESTIONABLE: </span>Player is 50/50 to play</p>
                            <p><span>DOUBTFUL: </span>Player has 75% chance of NOT playing</p>
                            <p><span>OUT: </span>Player has been ruled out for the game</p>
                            <p><span>INACTIVE: </span>Player appears on team's official gameday inactive list</p>
                            <p><span>PUP-R: </span>Physically unable to perform list, during regular season</p>
                            <p><span>PUP-P: </span>Physically unable to perform list, during preseason</p>
                            <p><span>IR-R: </span>Injured reserve, but designated to return</p>
                            <p><span>Reserve-DNR: </span>Reserve Did Not Report</p>
                            <p><span>NFI-R: </span>Reserve Non-Football Injury</p>
                            <p><span>NFI-A: </span>Active Non-Football Injury</p>
                            <p><span>Reserve-CEL: </span>Reserve Commissioner Exempt List</p>
                            <p><span>Reserve-Sus: </span>Reserve Suspended</p>
                            <p><span>Reserve-Ret: </span>Reserve Retired</p>
                            <p><span>Reserve-Ex: </span>Roster Exemption</p>
                            <p><span>RESERVE-COVID-19: </span>Deals specifically with COVID-19 situations</p>
                            <p style={{border:"0px"}}><span>IR: </span>Injured Reserve</p>
                        </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal> : <div className="loader-wrap"><div className="loader"></div></div> }
        </>
    );
  
}

export default (PlayerStats);
