import * as userAction from "./userAction";
import * as userService from "../../services/userService";
import * as userController from "../../controller/userController";
const initialState = {
    IsLoggedIn: false,
    statusCode :'',
    success : '',
    statusMessage : '',
    user :{},
    paymentProcessed : false
};

function userReducer(state = initialState, action) {
    let newState;
   
    switch (action.type) {
        case userAction.Type.registration:
            newState = { ...state };
            newState.statusCode = action.payload.errorCode;
            newState = action.payload.data;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            return newState;
        case userAction.Type.LogIn:
            newState = { ...state };
            newState.IsLoggedIn = (action.payload.errorCode == 200) ? true : false;
            newState.statusCode = action.payload.errorCode;
            newState.user = action.payload.data;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            return newState;
        case userAction.Type.RefreshToken:
            newState = { ...state };
            newState.IsLoggedIn = (action.payload.errorCode == 200) ? true : false;
            newState.statusCode = action.payload.errorCode;
            newState.user = action.payload.data;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            return newState;
        case userAction.Type.FbLogIn:
            newState = { ...state };
            newState.IsLoggedIn = (action.payload.errorCode == 200) ? true : false;
            newState.statusCode = action.payload.errorCode;
            newState.user = action.payload.data;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            return newState;
        case userAction.Type.GoogleLogIn:
            newState = { ...state };
            newState.IsLoggedIn = (action.payload.errorCode == 200) ? true : false;
            newState.statusCode = action.payload.errorCode;
            newState.user = action.payload.data;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            return newState;
        case userAction.Type.GetUserData:
           
            newState = { ...state };
            newState.IsLoggedIn = (action.payload.errorCode == 200) ? true : false;
            newState.statusCode = action.payload.errorCode;
            newState.user.address = action.payload.data.address;
            newState.user.city = action.payload.data.city;
            newState.user.state_code = action.payload.data.state_code;
            newState.user.zip_code = action.payload.data.zip_code;
            newState.user.country_code = action.payload.data.country_code;
            newState.user.user_name = action.payload.data.user_name;
            newState.user.email = action.payload.data.email;
            newState.user.user_status_id = action.payload.data.user_status_id;
            newState.success = action.payload.success
            newState.statusMessage = action.payload.message;
            newState.user.profile_img_path = action.payload.data.profile_img_path;
            newState.user.tsevo_customer_id = action.payload.data.tsevo_customer_id;
            newState.user.wallet_amount = action.payload.data.wallet_amount;            
            newState.user.usergame_amount = action.payload.data.usergame_amount;   
            newState.user.fund_for_withdrawal = action.payload.data.fund_for_withdrawal;         
            newState.user.experience_level = action.payload.data.experience_level;   
            newState.user.first_name = action.payload.data.first_name;
            newState.user.last_name = action.payload.data.last_name;
            newState.user.user_full_name = action.payload.data.user_full_name;    
            newState.user.tsevo_status_id = action.payload.data.tsevo_status_id;     
         return newState;
        case userAction.Type.SetTemporaryProfilePic:
            newState = { ...state };
            newState.user.profile_img_path = action.payload;
            return newState;
        case userAction.Type.SetUserData:
            newState = { ...state };
            
            newState.user.address = (action.payload.address) ? action.payload.address : newState.user.address;
            newState.user.city = (action.payload.city) ? action.payload.city : newState.user.city;
            newState.user.state_code = (action.payload.state) ? action.payload.state : newState.user.state_code;
            newState.user.zip_code = (action.payload.zipcode) ? action.payload.zipcode : newState.user.zip_code;
            newState.user.country_code = (action.payload.country) ? action.payload.country : newState.user.country_code;
            newState.user.user_name = (action.payload.username) ? action.payload.username : newState.user.user_name;
            newState.user.email = (action.payload.email) ? action.payload.email : newState.user.email;
            newState.user.is_state_legal = (action.payload.is_state_legal >= 0 ) ? action.payload.is_state_legal : newState.user.is_state_legal;

            return newState;
        case userAction.Type.SetPaymentProcessedStatus:
            newState = { ...state };
            newState.paymentProcessed = action.payload;
            return newState;
        case userAction.Type.LogOut:
            newState = { ...initialState };
            return newState;
        case userAction.Type.SetUserStateLeagalData:
            newState = { ...state };
            newState.user.is_state_legal = action.payload;
            return newState;
        case userAction.Type.SetTsevoReverification:
            newState = { ...state };
            newState = action.payload;

            return newState;
        default:
            return state;
    }
}

export function userLogin(userName, password) {
    return async (dispatch, getState) => {
        const response = await userService.login(userName, password);
        dispatch(userAction.LogIn(response));
    }
};

export function userLogout() {
    return async (dispatch, getState) => {
        const response = await userService.logout();
        dispatch(userAction.LogOut());
    }
};

export function userRegistration(payload) {
    return async (dispatch, getState) => {
        const response = await userController.userSignUp(payload);
        dispatch(userAction.userRegistration(response));
    }
};

export function fbUserLogin(email, accessToken, deviceType, fbId, isMailOpted = 0) {
    return async (dispatch, getState) => {
        const response = await userService.fbLogin(email, accessToken, deviceType, fbId, isMailOpted);
        dispatch(userAction.fbLogIn(response));
    }
};

export function googleUserLogin(email, accessToken, deviceType, idToken, googleId, isMailOpted = 0) {
    return async (dispatch, getState) => {
        const response = await userService.googleLogin(email, accessToken, deviceType, idToken, googleId, isMailOpted);
        dispatch(userAction.googleLogIn(response));
    }
};

export function getUserData(payload) {
    return async (dispatch, getState) => {
        const response = await userService.getUserData(payload);
        dispatch(userAction.getUserData(response));
    }
};

export function SetTemporaryProfilePic(profileImage) {
    return async (dispatch, getState) => {
        dispatch(userAction.SetTemporaryProfilePic(profileImage));
    }
};

export function setUserData(payload) {
    return async (dispatch, getState) => {
        dispatch(userAction.setUserData(payload));
    }
};

export function setPaymentProcessedStatus(status) {
    return async (dispatch, getState) => {
        dispatch(userAction.setPaymentProcessedStatus(status));
    }
};

export function SetUserStateLeagalData(payload) {
    return async (dispatch, getState) => {
        dispatch(userAction.SetUserStateLeagalData(payload));
    }
};

export function SetTsevoReverificationData(payload) {
    return async (dispatch, getState) => {
        dispatch(userAction.SetTsevoReverificationData(payload));
    }
};

export default userReducer;