import React, { useState, useEffect, useRef } from "react";
import { useNavigate , useLocation } from 'react-router-dom';
import { Navbar, Nav, Button } from "react-bootstrap";
import logo from '../assets/ko-logo.svg';
import '../components/header.scss';
import { pageURLs } from "../appData/pageURLs";
import { HowToPlay, FaqUrl, ContactUrl   } from '../utils/CommonHelper';

const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    let signupFlowRoutes = ['/signin','/signup','/login'];
    if(!signupFlowRoutes.includes(location.pathname)) {
       localStorage.removeItem("loginCheckUser")
    }

	return (
		<>
            <Navbar collapseOnSelect expand="lg" className="login-header">
                    <Navbar.Brand href="#" onClick={() => { navigate(pageURLs.root); }}><img src={logo} alt="KO Logo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link href={HowToPlay()} target="_blank">How to Play</Nav.Link>
                            <Nav.Link eventKey={3} href={ContactUrl()} target="_blank">Contact Us</Nav.Link>
                            <Nav.Link eventKey={3} href={FaqUrl()} target="_blank">FAQs</Nav.Link>
                            <Button variant="secondary" onClick={() => { navigate(pageURLs.root); }}>Sign In</Button>
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
		</>
	);

};

export default Header;
