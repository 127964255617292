import { combineReducers } from 'redux';

import counterReducer from './counterState/counterReducer';
import userReducer from './userState/userReducer';
import siteReducer from './siteState/siteReducer';

const rootReducer = combineReducers({
    counter: counterReducer,
    user: userReducer,
    site: siteReducer
});

export default rootReducer;
