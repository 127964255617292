import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Header from "../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import Copyright from '../components/Copyright';
import userdp from '../assets/user-dp.svg';
import '../styles/signin.scss';
import { pageURLs } from "../appData/pageURLs";
import {store} from '../appState/store';
import useUserState from "../appState/userState/useUserState";
import { TermsAndConditions } from '../utils/CommonHelper';
import InnerHeader from "../components/InnerHeader";
import ChevrontoggleIcon from "../assets/icons/chevrontoggle";
import InnerPageWrap from "../components/InnerPageWrap";

const VerificationFailed = (props) => {
    const navigate = useNavigate();
	const user = useUserState();
	const userData = store.getState().user 
	let duplicateentry = sessionStorage.getItem('duplicateentry');
    let tsevoFailureMessage = sessionStorage.getItem('tsevoFailureMessage');
    sessionStorage.removeItem('duplicateentry');
    sessionStorage.removeItem('tsevoFailureMessage');
    const [Flag_ShowSideMenu, setFlag_ShowSideMenu] = useState(false);
	const viewLobby = async () => {
		navigate(pageURLs.lobby);
    };

	return (
		<>
			<InnerPageWrap>
            <div className="koinner-sec">
                <div className="koheader-wrap"><InnerHeader currentPage={pageURLs.payment} /></div>
                <div className="kodata-content">
                    <div className="koblock-left content-block-lft verification-container">
                        <div className="sidebar-toggler">
                            <Button onClick={() => { setFlag_ShowSideMenu(true); }}>
                                <ChevrontoggleIcon />
                            </Button>
                        </div>

                        <h5>Let's Get Started!</h5>
                        <div className="signin-container request-wrap success-wrap">
                                <div className="dp-outer">
                                    <div className="dp-block"><img src={userdp} alt="KO"/></div>
                                    <div className="alert warning"></div>
                                </div>
                                <div className="title-sec">
                                    <h4>Your identity has not been verified.</h4>
                                    <p>{tsevoFailureMessage ? tsevoFailureMessage : 'All users must be verified in order to participate in contests on Fantasy Sports Knockout.'  }</p>
                                </div>
					
                                <div className="account-create d-flex align-items-center justify-content-center">
                                    <Button variant="primary" onClick={viewLobby}>View Lobby</Button>
                                    <Link to={pageURLs.Verificationstart}>Try Again</Link>
                                </div>
                                <div className="terms-note">
                                    <p>Please note that in order to play in a paid contest, you must be physically located in an eligible state. You can find the list of our eligible states in our {TermsAndConditions()}.</p>
                                </div>
				            </div>
                    </div>
                   
                </div>

           
            </div>
        </InnerPageWrap>
		</>
	);

};

export default VerificationFailed;
