import React from "react";


const LockedIcon = props => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
            <path id="Union_2" data-name="Union 2" d="M2,18a2,2,0,0,1-2-2V10.571a2,2,0,0,1,2-2h.5V5.143A5.078,5.078,0,0,1,7.5,0a5.078,5.078,0,0,1,5,5.143V8.572H13a2,2,0,0,1,2,2V16a2,2,0,0,1-2,2Zm8.834-9.428V5.143A3.385,3.385,0,0,0,7.5,1.715,3.385,3.385,0,0,0,4.167,5.143V8.572Z" transform="translate(0.5 0.5)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
        </svg>



    );

}

export default LockedIcon;