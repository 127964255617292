import React, { useState, useEffect } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import Copyright from '../components/Copyright';
import SideMenubar from "../components/SideMenubar";
import HamburgerIcon from '../assets/icons/hamburger';
import useSiteState from '../appState/siteState/useSiteState';
import { useNavigate, useLocation } from "react-router-dom";
import { pageURLs } from "../appData/pageURLs";
import { lobbyFilterOptions } from "../appData/lobbyFilterOptions";


function InnerPageWrap(props) {
    localStorage.removeItem("loginCheckUser"); //Remove user check related data from session storage thats kept from login flow
    const siteState = useSiteState();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPushMenu, setShowPushMenu] = useState(false);
    let hideSideMenu = props.hideSideMenu ? props.hideSideMenu : false;
    useEffect(() => {
        if (sessionStorage.getItem('IsUserGame') != null) {
            if (sessionStorage.getItem('IsUserGame') == 'false' || sessionStorage.getItem('IsUserGame') == false) {
                siteState.data.IsUserGame = false;
                setUserGameSelected(false)
            } else {
                siteState.data.IsUserGame = true;
                setUserGameSelected(true)
            }
        }else {
            siteState.data.IsUserGame = false;
            setUserGameSelected(false)
        }
    }, []);

    const [userGameSelected, setUserGameSelected] = useState(siteState.data.IsUserGame);

    const changeGameStateConfirm = (isUserGame, event) => {
        if(localStorage.getItem('changeInLineup') === 'true' && checkLineupPage()){
            if(window.confirm("The changes may not be saved. Are you sure you want to proceed?")) {
                localStorage.removeItem('changeInLineup')
                changeGameState(isUserGame, event)
            }
        } else {
            changeGameState(isUserGame, event)
        }
    }

    const changeGameState = (isUserGame, event) => {
        siteState.data.Lobby.ContestFilterOptions.searchText = "";
        siteState.data.Lobby.ContestFilterOptions.isFree = false;
        siteState.data.Lobby.FilterOption = lobbyFilterOptions.Joined;
        let selected_value = event.value;
        if (selected_value === 'usergames') {
            siteState.data.IsUserGame = true;
            setUserGameSelected(true)
            sessionStorage.setItem('IsUserGame', true)
            siteState.action.changeToUserGame(siteState.data.Lobby.ContestSortOptions, siteState.data.Lobby.ContestFilterOptions, siteState.data.Lobby.FilterOption, true);
            navigate(pageURLs.lobby);

        } else if (selected_value === 'sitegames') {
            siteState.data.IsUserGame = false;
            setUserGameSelected(false)
            sessionStorage.setItem('IsUserGame', false)
            siteState.action.changeToSiteGame(siteState.data.Lobby.ContestSortOptions, siteState.data.Lobby.ContestFilterOptions, siteState.data.Lobby.FilterOption, false);
            navigate(pageURLs.lobby);
        }
    }


    const getUserGameClasses = () => {
        let result = "innerpage-container";
        if (userGameSelected) {
            result += " user-games-container";
        }
        return result;
    }

    const checkLineupPage = () => {
		let lineupPage = false;
		if(location.pathname.toLowerCase().includes('createlineup') || location.pathname.toLowerCase().includes('usercreatelineup')) {
			lineupPage = true;
		}
		return lineupPage;
	}

    return (
        <div className={getUserGameClasses()}>
        {!hideSideMenu && <>
            <Button variant="light" onClick={() => { setShowPushMenu(true); }} className="btn-pushmenu"><HamburgerIcon /></Button>

            <Offcanvas show={showPushMenu} onHide={() => { setShowPushMenu(false); }} placement="start" className="pushmenu">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>

                    <SideMenubar
                        IsUserGame={userGameSelected}
                        changeGameState={changeGameStateConfirm} />
                </Offcanvas.Body>
            </Offcanvas>
            <div className="sidebar-container">

                <SideMenubar
                    IsUserGame={userGameSelected}
                    changeGameState={changeGameStateConfirm} />
            </div></>}
            <div className="komain-wrap">
                {props.children}
            </div>
            <div className="inner-copyright"><Copyright /></div>
        </div>
    );
};

export default InnerPageWrap;
